import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import colors from '../HelperClasses/AppColor';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import {Gradient} from 'react-gradient';
import { ReactComponent as LockIcon } from '../assets/lockWhite.svg';


export default class SaveButton extends Component {
  static propTypes = {
    saveBtn: PropTypes.func,
    saveBtnTitle: PropTypes.string,
    showSaveBtn:PropTypes.bool,
    btnDeActive:PropTypes.bool,
    deActiveBtnAction:PropTypes.func,
  }
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    }
  }
  renderActiveBtn = () => {
    let saveTitle = this.props.saveBtnTitle == undefined ? 'Save' : this.props.saveBtnTitle;
    if (this.props.showSaveBtn == undefined || this.props.showSaveBtn == true) {
      return (
        <View>
          <View style={tableStyle.gradientViewBGStyle}>
            <Gradient
              gradients={colors.GradientColors} // required
              property="background"
              gradientType={'linear'}
              duration={2333000}
              angle="0deg">
              <TouchableOpacity onPress={this.props.saveBtn}>
                <Text style={tableStyle.saveBtnTxtStyle}>{saveTitle}</Text>
              </TouchableOpacity>
            </Gradient>
          </View>
        </View>
      );
    } else {
      return <View />
    }
  }
  renderDeActiveBtn = () => {
    let saveTitle = this.props.saveBtnTitle == undefined ? 'Save' : this.props.saveBtnTitle;
    if (this.props.showSaveBtn == undefined || this.props.showSaveBtn == true) {
      return (
        <View>
          <View style={tableStyle.gradientViewBGStyle}>
            <Gradient
              gradients={colors.GradientGrayColors} // required
              property="background"
              gradientType={'linear'}
              duration={2333000}
              angle="0deg">
              <TouchableOpacity style={{flexDirection:'row',alignItems:'center'}} onPress={this.props.deActiveBtnAction}>
                <LockIcon stroke={colors.AppWhite} style={{ height: 16, width: 16, marginLeft: 8 }} />
                <Text style={tableStyle.saveBtnTxtStyle}>{saveTitle}</Text>
              </TouchableOpacity>
            </Gradient>
          </View>
        </View>
      );
    } else {
      return <View />
    }
  }
  render() {
    if (this.props.btnDeActive == true) {
      return <this.renderDeActiveBtn />
    } else {
      return <this.renderActiveBtn />
    }
  }
}
