import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Dimensions,
  Platform,
  Alert,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import downArrow_Icon from '../../assets/downArrowIcon.png';
import {AppToast} from '../../HelperClasses/AppToast';
import hideIcon from '../../assets/hideEye.png';
import showIcon from '../../assets/showEye.png';

const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'payments';
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth  ?  true : false


// let AccountTypesArray = ['Standard', 'Express'];
export default class PayDunya extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      showDropDown: false,
      hideShowPublickey: false,
      hideShowSecretkey: false,
      masterKey: '',
      publicKey: '',
      privateKey: '',
      token: '',
      subscriptionMsg: '',
      switchValue: true,
    };
    this.saveButtonAction = this.saveButtonAction.bind(this);
  }

  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=payments', 'get','','');
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      // console.log('this.state.configs =>', configs);
      this.setState({
        masterKey: configs['paydunya_master_key'] || '',
        publicKey: configs['paydunya_public_key'] || '',
        privateKey: configs['paydunya_private_key'] || '',
        token: configs['paydunya_token'] || '',
      });
    }
    this.setState({dataLoad: true});
  };

  addOrUpdateAppDetailApi = async filesData => {
    // console.log('filesData =>', filesData);
    var configs = [];
    let valueArray = [
      this.state.masterKey,
      this.state.publicKey,
      this.state.privateKey,
      this.state.token,
    ];
    // var configs = [];
    for (let i = 0; i < keysArray.length; i++) {
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: true,
        };
        // console.log('fDict ==> ', fDict);
        configs.push(fDict);
      }
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
      '',
    );
    // console.log('configs', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.deleteKeysAPI(configs);
      // this.alertView('Uploaded successfully');
    } else {
      this.setState({dataLoad: true});
      this.alertView(responseJson);
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    let parms = deleteParms.toString();
    let urlParm = APPURL.URLPaths.config + '?key=' + parms;
    const responseJson = await networkService.networkCall(urlParm, 'delete', '', '');
    this.setState({dataLoad: true});
    this.alertView('Uploaded successfully');
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateAppDetailApi();
  }
  //MARK:-  UIs 
  render() {
    return (
      <View style={{width: itsMobileView ? '100%' : '70%', marginBottom: 50, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>PayDunya Integration</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plainTextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Master key</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter master key"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({masterKey: name})}
                value={this.state.masterKey}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Public key</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter public key"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({publicKey: name})}
                value={this.state.publicKey}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Private key</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter private key"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({privateKey: name})}
                value={this.state.privateKey}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Token</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter token"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({token: name})}
                value={this.state.token}
              />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

let keysArray = [
  'paydunya_master_key',
  'paydunya_public_key',
  'paydunya_private_key',
  'paydunya_token',
];
