import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  FlatList,
  Dimensions,
  Alert,
  Platform,
  Image,
  SafeAreaView,
  TouchableOpacity,
} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import PropTypes from 'prop-types';
import EmptyListUI from '../../Component/AppEmptyList';
import {AppToast} from '../../HelperClasses/AppToast';
import placeholder from '../../assets/pPlaceholder.png';
import HeaderView from '../HeaderView';
import { getThumbnailImage } from '../../HelperClasses/SingletonClass';
import ListingDetails from './../../MainListing/ListingDetail';
import AppConstants from '../../Constants/AppConstants';

const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

export default class ListingListUnderCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listingArray: [],
      dataLoad: false,
      haveData: false,
      selectedID:0,
      viewListingDetailBool:false,
    }
  }
  static propTypes = {
    CID: PropTypes.string,
    type: PropTypes.string,
    backBtnAction:PropTypes.any,
    categoryData:PropTypes.any,
  }
  componentDidMount() {
      this.loadListingApi()
  }
  //MARK:-  APIS Method 
  loadListingApi = async () => {
    this.setState({dataLoad: false})
    const responseJson = await networkService.networkCall(APPURL.URLPaths.listingsModerate + `?category_id=${this.props.CID.toString()}&page=1&per_page=100`,'get')
    console.log('responseJson', responseJson)
    this.setState({listingArray: []})
    if (responseJson['status'] == true) {
      var vTypeD = responseJson['data']['listings']
      for (let objc of vTypeD) {
        this.state.listingArray.push(objc)
      }
      this.state.haveData = this.state.listingArray.length == 0 ? true : false
    }
    this.setState({dataLoad: true})
  }
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast(title);
    } else {
      Alert.alert(title);
    }
  }
  
  editButtonAction = index => {
    let id_Index = this.state.variantTypeArray.findIndex(x => x.id === index)
    this.props.editBtn(this.state.variantTypeArray[id_Index])
  }
  viewListingDetail(id) {
    this.setState({ selectedID: id, viewListingDetailBool: true})
  }

 
  /*  UI   */
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    if (this.state.listingArray.length == 0) {
      if (this.state.haveData) {
        return <EmptyListUI />;
      }else {
        return <View />;
      }
    } else {
      var views = [];
      for (let i = 0; i < this.state.listingArray.length; i++) {
        let item = this.state.listingArray[i]
        views.push(
          <FlatList
            data={[
              item['id'],
              item['list_price']['formatted'],
              item['images'][0] || '',
              item['active'],
              item['offer_percent'] + '%',
              item['offer_price']['formatted'],
              item['status'],
            ]}
            style={{
              backgroundColor: 'white',
            }}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />,
        )
      }
      return views;
    }
  }

  columnCell = ({item, index}) => {
    if (index == 3) {
      let  val = item ? 'Active' : 'Inactive'
      return (
        <View style={styles.columnTitleViewStyle} >
          <Text style={item == true ? tableStyle.columnActiveBtnStyle : tableStyle.columnDeActiveBtnStyle}>
            {val}
          </Text>
        </View>
      );
    }else if (index == 6) {
      let val = 'Pending'
      if (item = 2) {
        val = 'Approved'
      }else if (item = 3){
        val = 'Rejected'
      }
      // let  val = item == 3 ? 'Active' : 'Inactive'
      return (
        <View style={styles.columnTitleViewStyle} >
          <Text style={item == 2 ? tableStyle.columnActiveBtnStyle : tableStyle.columnDeActiveBtnStyle}>
            {val}
          </Text>
        </View>
      );
    }  else if (index == 0) {
      let id_Index = this.state.listingArray.findIndex(x => x.id === item)
      let name = this.state.listingArray[id_Index]['title']
      return (
        <TouchableOpacity style={styles.columnTitleViewStyle} onPress={() =>this.viewListingDetail(item) }>
          <Text style={tableStyle.themeTitleTxtStyle}> {name}  </Text>
        </TouchableOpacity>
      );
    }  else if (index == 2) {
      let fileURl = getThumbnailImage(item)
      return (
        <View style={styles.columnTitleViewStyle}>
          <Image style={tableStyle.itemImageViewStyle} source={item.length == 0 ? placeholder : fileURl} />
        </View>
      )
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
        </View>
      );
    }
  }

  render() {
    if (this.state.viewListingDetailBool) {
      return (<ListingDetails backBtnAction={() => this.setState({viewListingDetailBool: false})} listingID={this.state.selectedID} />);
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <HeaderView
            title={`Listings under ${this.props.categoryData['name']}`}
            backBtn={this.props.backBtnAction}
            showSaveBtn={false}
            saveBtnTitle={'Save'}
            saveBtn={() => this.submitBtnAction()}
          />
          <View style={tableStyle.listContainerView}>
            <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
              <View>
                <View style={tableStyle.headerListContainer}>
                  <this.tableViewHeader />
                </View>
                <ScrollView >
                  <this.columnDataView />
                </ScrollView>
              </View>
            </ScrollView>
          </View>
          <Appload enable={this.state.dataLoad} />
        </SafeAreaView>
      )
    }
  }
}
const divideWidth = 7.45
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : windowWidth / divideWidth,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : windowWidth / divideWidth,
    borderBottomWidth: 1,
    borderBottomColor: colors.SimonGray,
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  iconStyle: {
    height: 15,
    width: 15,
  },
})

const tableHeaderString = [
  'Title',
  'List Price',
  'Image',
  'Status',
  'Offer Percent',
  'Offer Price',
  'Listing Status',
]