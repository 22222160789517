import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  SafeAreaView,
  Image,
  TextInput,
  Dimensions,
  Alert,
  Platform,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import searchIcon from '../assets/search.png';
import closeIcon from '../assets/closeIcon.png';
import whiteTick from '../assets/whiteTick.png';
import downArrow_Icon from '../assets/downArrowIcon.png';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import edit_Icon from '../assets/editIcon.png';
import deleteIcon from '../assets/delete_icon.png';
import settingGrayIcon from '../assets/settingGrayIcon.png';
import Listing from '../Settings/Listings/Listings';
import placeholder from '../assets/pPlaceholder.png';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import {Gradient} from 'react-gradient';
import EmptyListUI from '../Component/AppEmptyList';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import {AppToast} from '../HelperClasses/AppToast';
import AccountDetails from './AccountDetail';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import HelperLinkViews from '../Component/HelperLinkView';
import appMessage from '../Constants/AppConstantsMessage';
import { getThumbnailImage, thumbnailImage } from '../HelperClasses/SingletonClass';
import { ToastContainer } from 'react-toastify';
import listIcon from '../assets/listIcon.png';
import gridIcon from '../assets/gridIcon.png';
import kanbanIcon from '../assets/kanbanIcon.png';
import locationIcon from '../assets/locationIcon.png';

import selectedListIcon from '../assets/selectedListIcon.png';
import selectedGridIcon from '../assets/selectedGridIcon.png';
import selectedkanbanIcon from '../assets/selectedkanbanIcon.png';
import selectedlocationIcon from '../assets/selectedlocationIcon.png';

import Board from 'react-trello'
import GoogleMapReact from 'google-map-react';
import { checkUserRoleModule } from '../HelperClasses/SingletonClass';
import { MODULEKEYS } from '../Models/Enum';
import AppConstants from '../Constants/AppConstants';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../Models/MixPannelEnum';
import SegmentBtnView from '../Component/SegmentBtnView';
import ToolTipViewBtn from '../Component/ToolTipViewBtn';
import AppConstantsMessage from '../Constants/AppConstantsMessage';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import SearchView from '../Component/SearchView';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let fixedWidth = AppConstants.mobileMaxWidth
let itsMobileView = windowWidth < fixedWidth  ?  true : false

const APPURL = require('../Constants/URLConstants');
var pageNo = 1;
let PerPage = 25;
let laneHeight = windowHeight/1.5



export default class AccountList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      selectedStatusType: 'All',
      perPage:PerPage,
      selectedStatusID: 0,
      accountsArray: [],
      updateUI: false,
      dataLoad: false,
      stopPage: false,
      showApproveBool: false,
      viewAccountDetailBool: false,
      selectedID: 0,
      haveData:false,
      searchKey: '',
      typingTimeout: 0,
      addConfigs:false,
      totalRecords: 0,
      statusArray:[],
      showKanbanView:false,
      showGridView:false,
      showMapView: false,
      showSearchView:true,
      writeP:AppConstants.defaultWrite,
      deleteP:AppConstants.defaultDelete,
      moderationWrite:AppConstants.defaultWrite,
    };
  }
  componentDidMount() {
    this.getApi()
    this.mixPannelInfo()
  }
  mixPannelInfo(){
    addMixpanelEvent(MixpanelEvent.viewAccountList,{'page':pageNo})
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.ACCOUNTSMODERATION, res => {
      console.log('res', res);
      this.setState({ writeP: res[1],deleteP :res[2]})
    })
  }
  getApi() {
    pageNo = 1;
    this.loadAccountsApi();
  }
  //MARK:-  APIS Method 
  loadAccountsApi = async (background) => {
    this.setState({dataLoad: background ? true : false});
    let path = this.state.selectedStatusType == 'All' ? `${pageNo}&type=accounts` : `${pageNo}&type=accounts&status=${this.state.selectedStatusID}`;
    if (this.state.searchKey.length != 0){
      path = path + `&search_key=${this.state.searchKey}`
    }

    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.accountListings + `${path}&per_page=${this.state.perPage}`, 'get',  '', '');
    if (responseJson['status'] == true) {
      var accountD = responseJson['data']['accounts'];
      this.state.totalRecords = responseJson['data']['total_records'];
      this.setState({accountsArray: []});
      if (accountD.length != 0) {
        this.setState({stopPage: false});
        for (let i = 0; i < accountD.length; i++) {
          this.state.accountsArray.push(accountD[i])
        }
        this.statusdata(accountD)
      } else {
        pageNo = pageNo <= 1 ? 1 : pageNo - 1 
        this.setState({stopPage: true});
      }
      this.state.haveData = this.state.accountsArray.length == 0 ? true : false
    } else {
      AppToast(responseJson);
    }
    this.setState({dataLoad: true});
  }
  statusdata(data) {
    this.state.statusArray = []
    let ssArray = []
    let pendingArray = [];
    let approvedArray = [];
    let rejectArray = [];
    for (let i = 0; i < data.length; i++) {
      let item = data[i]
      var firstLine = item['description'].substring(0, 100);
      // console.log('firstLine',)
      let dic = {
        id: item['id'],
        title: item['name'],
        description: firstLine,
        draggable: true,

      }
      if (item['status'] == 1) {
        pendingArray.push(dic)
      }  else if (item['status'] == 2) {
        approvedArray.push(dic)
      }  else if (item['status'] == 3) {
        rejectArray.push(dic)
      }
    }
    let pendingDic = {
      id: 'lane1',
        title: 'Pending',
        label: '',
        cards: pendingArray,
        style:{backgroundColor :colors.SimonGray, maxHeight:laneHeight},
    }
    let approvedDic = {
      id: 'lane2',
        title: 'Approved',
        label: '',
        cards: approvedArray,
        style:{backgroundColor :colors.CGreen, maxHeight:laneHeight},
    }
    let rejectDic = {
      id: 'lane3',
        title: 'Rejected',
        label: '',
        cards: rejectArray,
        style:{backgroundColor :colors.lightRed,maxHeight:laneHeight},
    }
    ssArray.push(pendingDic)
    ssArray.push(approvedDic)
    ssArray.push(rejectDic)
    this.state.statusArray.push({lanes : ssArray});
    this.setState({updateUI: !this.state.updateUI});
  }
  aproveRejectAccountApi = async (id, type, background) => {
    this.setState({ dataLoad: background ? true : false });
    let fDict = {
      status: type,
    };
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.accounts + `${id}/moderate`, 'post', JSON.stringify({ account: fDict }), '')
    // console.log('res po', responseJson);
    this.setState({ dataLoad: true });
    if (responseJson['status'] == true) {
      let text = type == 2  ? MixPannelEnum.approve : MixPannelEnum.reject
      let key = `${text} ${MixPannelEnum.accounts}`
      addMixpanelEvent(MixpanelEvent.viewAccountList,{[key]:true})
      this.loadAccountsApi(background)
      if (background) {
      } else {
        this.alertView()
      }
    } else{
      this.alertView(responseJson) 
    }
  }
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  onSearchTextChange = text => {
    this.setState({ searchKey: text })
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      typingTimeout: setTimeout(function () {
        this.getApi()
      }.bind(this), 1000)
    })
  }
  onCancelText() {
    if (this.state.searchKey.length != 0) {
      this.state.searchKey = ''
      this.getApi()
    }
    this.setState({showSearchView: true})
  }
  //MARK:- Button Actions
  backBtnHandler() {
    this.setState({addConfigs: false, viewAccountDetailBool: false})
  }
  backBtnAction() {
    this.setState({viewAccountDetailBool: false})
    this.getApi()
  }
  viewAccountBtnAction(id) {
    this.setState({selectedID: id, viewAccountDetailBool: true})
  }
  nextPreviousBtnAction(index) {
    if (index == 1) {
      if (!this.state.stopPage) {
        pageNo = pageNo + 1;
        // console.log('this.am ==', pageNo);
        this.loadAccountsApi();
      }
    } else if (index == 2) {
      if (pageNo == 1) {
      } else {
        // console.log('comig here');
        pageNo = pageNo - 1;
        this.loadAccountsApi();
      }
    } else {
    this.setState({showApproveBool : !this.state.showApproveBool});
    }
    this.mixPannelInfo()
    this.setState({updateUI: !this.state.updateUI});
  }
  showEntriesDropDown() {
    this.setState({showDropDown: !this.state.showDropDown});
  }
  didSelectDropDown = (item, index) => {
    this.state.selectedStatusType = item;
    this.state.selectedStatusID = index;
    this.setState({showDropDown: false, selectedStatusType: item});
    pageNo = 1;
    this.loadAccountsApi();
  }
  didSelectPerPage = (item, index) => {
    this.state.perPage = item;
    this.setState({showDropDown: false});
    pageNo = 1;
    this.loadAccountsApi();
  }
  statusBtnAction() {
    // console.log();
  }
  editButtonAction = index => {
    this.setState({isEdit: true, commissionID: index});
    this.setState({addCommissionsBool: true});
  };
  approveRejectBtnAction = (id, type) => {
    let msg = `Do you want to ${type == 2 ? 'approve' : 'reject'} this Account ?`;
    if (Platform.OS === 'web') {
      const r = window.confirm(msg);
      if (r == true) {
        this.aproveRejectAccountApi(id, type);
      }
    }
  }
  onCardMoveAcrossLanes = (fromLaneId, toLaneId, cardId, index) => {
    // console.log('fromLaneId =>>>> ',fromLaneId,'toLaneId',toLaneId,cardId,'index ---',index)
    if (toLaneId == 'lane1') {
      this.aproveRejectAccountApi(cardId, 1,true);
    } else if (toLaneId == 'lane2') {
      this.aproveRejectAccountApi(cardId, 2,true);
    } else if (toLaneId == 'lane3') {
      this.aproveRejectAccountApi(cardId, 3,true);
    }
  }
  onCardClick = (cardId, metadata, laneId) => {
    this.setState({selectedID: cardId, viewAccountDetailBool: true})
  }

  /*  UI   */
  entriesDropDown = props => {
    if (this.state.showDropDown == true) {
      return (
        <View style={tableStyle.perPageContainerViewStyle}>
          <View style={{height:4}} />
          <FlatList
            data={['25', '50', '100']}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            keyExtractor={(item, index) => index + 989}
            key={'E'}
          />
        </View>
      );
    }
    return <View></View>;
  }
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity  onPress={() => this.didSelectPerPage(item, index)} style={{margin:3}}>
        <View style={[tableStyle.perPageDropDownViewStyle,{backgroundColor:item == this.state.perPage ? colors.BGBlueColor : ''}]}>
          <Image source={whiteTick} style={{height:16, width:item == this.state.perPage ? 16 : 0}}/>
          <Text style={CommonStyleSheet.dropdownCellWhiteTextStyle}>{item}</Text>
        </View>
      </TouchableOpacity>
    );
  }
  tableViewHeader = props => {
  const tableHeaderString = ['Name', 'Description', 'Image', 'Status', 'Account Status', 'Location'];
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{margin: 0}}
        keyExtractor={(item, index) => index + 89}
        key={'H'}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
      var views = [];
      for (let i = 0; i < this.state.accountsArray.length; i++) {
        views.push(
          <FlatList
            data={[
              this.state.accountsArray[i]['id'],
              this.state.accountsArray[i]['description'],
              this.state.accountsArray[i]['images'][0] || '',
              this.state.accountsArray[i]['active'] == true ? 'Active' : 'Inactive',
              this.state.accountsArray[i]['id'],
              this.state.accountsArray[i]['location'],
            ]}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            style={{borderBottomWidth: 1, borderBottomColor: colors.SimonGray,backgroundColor: 'white'}}
            keyExtractor={(item, index) => index + 2989}
            key={'C'}
          />,
        );
      }
      return views;
  };
  columnCell = ({item, index}) => {
    if (index == 3) {
      return (
        <View style={styles.columnTitleViewStyle} >
          <Text style={ item == 'Active' ? tableStyle.columnActiveBtnStyle : tableStyle.columnDeActiveBtnStyle }>
            {item}
          </Text>
        </View>
      );
    }else if (index == 4) {
      let id_Index = this.state.accountsArray.findIndex(x => x.id === item)
      let dict = this.state.accountsArray[id_Index]
      if (dict['status'] == 1) {
        return  (
          <View style={styles.columnTitleViewStyle}>
            <View style={{flexDirection: 'row'}}>
              <this.renderRejectView id={item} type={1} />
              <this.renderRejectView  id={item} type={2} />
            </View>
        </View>
        )
      } else {
        return (
          <View
            style={styles.columnTitleViewStyle}>
            <Text
              style={ dict['status'] == 2 ? tableStyle.columnActiveBtnStyle : tableStyle.columnDeActiveBtnStyle }>
              {dict['status'] == 2 ? 'Approved' : 'Rejected'}
            </Text>
          </View>
        )
      }
    } else if (index == 20) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{flexDirection: 'row'}}>
            <TouchableOpacity onPress={() => this.editButtonAction(item)}>
              <Image style={tableStyle.iconStyle}  resizeMode="center" source={edit_Icon} />
            </TouchableOpacity>
            <View style={{width: 10}} />
            <TouchableOpacity onPress={() => this.deleteCommissionAction(item)}>
              <Image style={tableStyle.iconStyle} resizeMode="center" source={deleteIcon} />
            </TouchableOpacity>
          </View>
        </View>
      );
    } else if (index == 0) {
      let id_Index = this.state.accountsArray.findIndex(x => x.id === item);
      let name =  this.state.accountsArray[id_Index]['name']
      return (
        <TouchableOpacity style={styles.columnTitleViewStyle} onPress={() => this.viewAccountBtnAction(item)}>
          <Text numberOfLines={1} style={tableStyle.mainTitleStyle}>
            {name}
          </Text>
        </TouchableOpacity>
      );
    } else if (index == 5) {
        return (
          <View style={styles.columnTitleViewStyle}>
            <Text numberOfLines={1} style={tableStyle.columnViewTitleStyle}>
              {item['formatted_address']}
            </Text>
          </View>
        );
    } else if (index == 2) {
      let fileURl =  getThumbnailImage(item);
      return (
        <View style={styles.columnTitleViewStyle}>
          <LazyLoadImage height={50} width={50}  resizeMode="cover"  effect="blur" src={item.length == 0 ? placeholder : fileURl}
            placeholderSrc={placeholder}
          />
        </View>
      );
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text numberOfLines={1} style={tableStyle.columnViewTitleStyle}>
            {item}
          </Text>
        </View>
      );
    }
  };
  renderRejectView = (id) => {
    return (
      <View style={styles.approveRejectViewStyle}>
        <View style={[tableStyle.secondButtonViewStyle,{borderColor:id['type'] == 1 ? colors.AppNewGreen : colors.AppRed}]}>
            <TouchableOpacity disabled={!this.state.moderationWrite}
              onPress={() => this.approveRejectBtnAction(id['id'], id['type'] == 1 ? 2 : 3)}>
              <Text style={[tableStyle.acceptBtnTextStyle,{color:id['type'] == 1 ? colors.AppNewGreen : colors.AppRed}]}>
                {id['type'] == 1 ? 'Approve' : 'Reject'}</Text>
            </TouchableOpacity>
        </View>
      </View>
    )
  }
  
  EmptyListRender = () => {
    if (this.state.accountsArray.length == 0) {
      if (this.state.haveData) {
        return (<View style={{height: '100%', justifyContent: 'center'}}>
          <EmptyListUI messageIcon={3} showImage ={true} titleString={appMessage.emptyAccountTitleString} subtitleString={appMessage.accountsMsgTextString}/>
          </View>)
      }else {
        return <View />;
      }
    } else {
      return <View />;
    }
  };
  configButton = () => {
    return (
      <View style={tableStyle.configurationBarViewStyle}>
        <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
          onPress={() => this.setState({ addConfigs: !this.state.addConfigs })}>
          <Image resizeMode={'cover'} style={{ width: 16, height: 16, marginLeft: 16 }} source={settingGrayIcon} />
          <Text style={[CommonStyleSheet.tabBtnViewStyle,{paddingLeft:3}]}>{' Manage'}</Text>
        </TouchableOpacity>
      </View>
    );
  }
  gradientButtons = name => {
    if (this.state.accountsArray.length != 0 || pageNo > 1) {
    if (name['id'] == 2 && pageNo == 1) {
      return <View />
    } else if (name['id'] == 1 && this.state.accountsArray.length == 0 && pageNo > 1) {
      return <View />
    }
    return (
      <View>
        <View style={tableStyle.grandientBGStyle}>
          <Gradient
            gradients={colors.GradientColors} // required
            property="background"
            gradientType={'linear'}
            duration={2333000}
            style={{height: 30, width: 100, borderRadius: 3, marginRight: 30}}
            angle="0deg">
            <TouchableOpacity
              style={tableStyle.nextPreviousViewStyle}
              onPress={() => this.nextPreviousBtnAction(name['id'])}>
              <Text style={CommonStyleSheet.NextBtnStyle}>{name['name']}</Text>
            </TouchableOpacity>
          </Gradient>
        </View>
      </View>
    );
    }else {
      return <View /> 
    }
  }
  renderPerView = () => {
    return <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', marginRight:20}}>
      <Text>Show rows</Text>
      <View style={{ flexDirection: 'column', zIndex: 9999}}>
        <TouchableOpacity
          onPress={() => this.showEntriesDropDown()}
          style={tableStyle.perPagedropDownView}>
          <Text> {this.state.perPage} </Text>
          <Image style={this.state.showDropDown == true ? appConfigStyle.upArraowIconStyle : appConfigStyle.downArraowIconStyle}
            resizeMode="contain"
            source={downArrow_Icon}
          />
        </TouchableOpacity>
        <this.entriesDropDown />
      </View>
    </View>
  }
  renderBottomView = () => {
    if (this.state.totalRecords > this.state.perPage) {
      return (<View style={tableStyle.bottomViewStyle}>
        <this.gradientButtons name={'Next'} id={1} />
        <this.gradientButtons name={'Previous'} id={2} />
        <this.renderPerView />
      </View>)
    } else {
      return <View />
    }
  }
  renderHeaderBtn = () => {
    let showList  = !this.state.showKanbanView && !this.state.showGridView && !this.state.showMapView
    return (<View style={{ flexDirection: 'row', marginRight: 10, zIndex: 100, marginTop: itsMobileView ? 10 : 5}}>
      <View style={styles.configurationBarViewStyle}>
        <View>
          <SegmentBtnView title={'List'} isSelected={showList} icon={showList ? selectedListIcon : listIcon} tapAction={() => this.setState({ showKanbanView: false, showGridView: false, showMapView: false })} />
        </View>
        <View style={{ marginLeft:20}}>
          <SegmentBtnView title={'Map'} isSelected={this.state.showMapView} icon={this.state.showMapView ? selectedlocationIcon : locationIcon}
            tapAction={() => this.setState({ showMapView: true, showGridView: false, showKanbanView: false })} />
        </View>
        <View style={{marginLeft:20}}>
          <SegmentBtnView title={'Board'} isSelected={this.state.showKanbanView} icon={this.state.showKanbanView ? selectedkanbanIcon : kanbanIcon} 
          tapAction={() => this.setState({ showKanbanView: true, showGridView: false,showMapView:false })} />
        </View>
        <View style={{marginLeft:20, marginRight:20}}>
          <SegmentBtnView title={'Grid'} isSelected={this.state.showGridView} icon={this.state.showGridView ? selectedGridIcon : gridIcon} 
          tapAction={() => this.setState({ showGridView: true, showKanbanView: false,showMapView:false})} />
        </View>
      </View>
    </View>)
  }
  renderKanbanBoard = () => {
    if (this.state.statusArray.length != 0) {
      return <View style={{ height: '90%' }}>
        <Board
          style={{ backgroundColor: 'white', height: '100%' }}
          laneStyle={{height: windowHeight/2}}
          data={this.state.statusArray[0]}
          cardDraggable={true}
          onCardMoveAcrossLanes={this.onCardMoveAcrossLanes}
          onCardClick={this.onCardClick}
          hideCardDeleteIcon={true} />
      </View>
    } else {
      return <View />
    }
  }
  renderGridElements = () => {
    var view = [];
    for (let a = 0; a <= this.state.accountsArray.length - 1; a++) {
      let item = this.state.accountsArray[a]
      let photo = item['images'][0] || ''
      let active = item['active']
      let fileURl = getThumbnailImage(photo)
      view.push(
        <TouchableOpacity style={tableStyle.gridContentViewsStyle} onPress={() => this.viewAccountBtnAction(item['id'])}>
          <Image style={tableStyle.gridImageViewStyle} source={photo.length == 0 ? placeholder : fileURl} />
          <View style={{ padding: 5 }}>
            <Text style={tableStyle.themeTitleTxtStyle} numberOfLines={1} >{item['name']}</Text>
          </View>
          <View style={{ padding: 5, flexDirection: 'row', justifyContent: 'space-between',alignItems: 'center' }}>
            <Text numberOfLines={1} style={tableStyle.gridPriceViewStyle}> {item['description']}</Text>
            <View>
              <Text style={active == 0 ? tableStyle.columnDeActiveBtnStyle : tableStyle.columnActiveBtnStyle}>
                {active ? 'Active' : 'Inactive'}
              </Text>
            </View>
          </View>
        </TouchableOpacity>,
      );
    }
    return view;
  }
  renderGridView = () => {
    return <ScrollView>
      <View style={tableStyle.gridViewStyle}>
        <this.renderGridElements />
      </View>
    </ScrollView>
  }
  renderListView = () => {
    return <View style={{ height: itsMobileView ? this.state.totalRecords > PerPage ? '85%' : '95%' : this.state.totalRecords > PerPage ? '92%' : '100%' }}>
      <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
        <View>
          <View style={tableStyle.headerListContainer}>
            <this.tableViewHeader />
          </View>
          <ScrollView style={styles.tableViewHeaderStyle}>
            <this.columnDataView />
          </ScrollView>
        </View>
      </ScrollView>
      <this.EmptyListRender />
    </View>
  }
  renderMapView = () => {
    var marker = []
    var lat = 0
    var lng = 0
    for (let objc of this.state.accountsArray) {
      let photo = objc['images'][0] || ''
      let fileURl =  getThumbnailImage(photo);
      if (objc['latitude']) {
        if (lat == 0){
          lat = objc['latitude']
          lng = objc['longitude']
        }
        marker.push(<TouchableOpacity lat={objc['latitude']} lng={objc['longitude']} onPress={() => this.viewAccountBtnAction(objc['id'])} >
          <Image style={{height:50,width:50,borderRadius:10}} source={photo.length == 0 ? placeholder : fileURl} />
        </TouchableOpacity>)
      }
    }
    return <View style={{flex:1 }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: AppConstants.googleMapAPIKey }}
        defaultCenter={{lat: lat, lng:lng }}
        defaultZoom={10}
        yesIWantToUseGoogleMapApiInternals>
       {marker}
      </GoogleMapReact>
    </View>
  }
  searchViewRender = () => {
    return <View>
      <SearchView
        onCancelText={() => this.onCancelText()}
        onSearchTextChange={text => this.onSearchTextChange(text)}
        searchKey={this.state.searchKey} />
    </View>
  }
  renderMainView = () => {
    if (this.state.showGridView) {
      addMixpanelEvent(MixpanelEvent.viewAccountList,{'type':MixPannelEnum.gridView})
      return <this.renderGridView />
    } else if (this.state.showKanbanView) {
      addMixpanelEvent(MixpanelEvent.viewAccountList,{'type':MixPannelEnum.kanbanView})
      return <this.renderKanbanBoard />
    } else if (this.state.showMapView) {
      addMixpanelEvent(MixpanelEvent.viewAccountList,{'type':MixPannelEnum.mapView})
      return <this.renderMapView />
    }else {
      addMixpanelEvent(MixpanelEvent.viewAccountList,{'type':MixPannelEnum.listView})
      return <this.renderListView />
    }
  }
  
  render() {
    if (this.state.viewAccountDetailBool){
      return (<AccountDetails backBtnAction={() => this.backBtnAction()} accountID={this.state.selectedID}/>);
    } else  if (this.state.addConfigs){
      return (<Listing titleString={'Accounts'} backBtnAction={() => this.setState({addConfigs: false})}  /> );
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <ToastContainer />
          <View style={tableStyle.headerViewstyle}>
            <View style={tableStyle.headerTitleContainerViewStyle}>
              <Text style={[tableStyle.titleTextStyle, { margin: 0 }]}>Accounts </Text>
              <ToolTipViewBtn title={AppConstantsMessage.accountsTitleToolTipMsg} description={AppConstantsMessage.accountsSubTitleToolTipMsg} />
            </View>
            <View style={{zIndex:10}}>
              <this.renderHeaderBtn />
            </View>
            <View style={{ flexDirection: 'row',marginTop:itsMobileView ? 5 : 0 }}>
              <View style={tableStyle.rightTopViewStyle}>
                <this.searchViewRender />
              </View>
              {/* <this.configButton /> */}
            </View>
          </View>
          <View style={tableStyle.mainView}>
            {this.renderMainView()}
            {this.renderBottomView()}
          </View>
          <HelperLinkViews  title={'Accounts'}/>
          <Appload enable={this.state.dataLoad} />
        </SafeAreaView>
      );
    }
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  subViewStyle: {
    backgroundColor: 'white',
    zIndex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    width: '70%',
  },
  searchViewStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchBarViewStyle: {
    borderColor: colors.SimonGray,
    borderRadius: 5,
    borderWidth: 1,
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft:5,
    marginTop:-5,
    height:30,
    width: itsMobileView ?  windowWidth/2 : 'auto',
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : windowWidth / 6.2,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    paddingLeft: 10,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : windowWidth / 6.2,
    justifyContent: 'flex-start',
    padding: 2,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  approveRejectViewStyle: {
    justifyContent: 'center',
    // padding: 5,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    alignSelf: 'center',
  },
  rightTopViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    alignItems: 'center',
    zIndex: 132,
    marginRight: 40,
    marginTop: 5
  },
  configurationBarViewStyle: {
    // alignItems: 'center',
    // flexDirection: 'row',
    marginRight: 10,
    flexWrap:'wrap',
    width: itsMobileView ? windowWidth/1.1 : windowWidth/3,
    alignItems: 'flex-start',
    justifyContent:itsMobileView ? 'flex-start' : 'center',
    flexDirection:'row',

  }
});
/*. 

flexWrap: 'wrap',
alignItems: 'flex-start',
justifyContent:'flex-start',
width: windowWidth < fixedWidth ? windowWidth - 10 : windowWidth < fixedWidth,
flexDirection:'column',

*/
