import React from 'react';
import { TouchableOpacity, View ,Text} from 'react-native';
import {useFilePicker,Validator } from 'use-file-picker';
import ConstantArray from '../Constants/ConstantArray';
import PropTypes from 'prop-types';


export default function FilePicker({title,fileType,style,data}){
 
  const [openFileSelector, { filesContent,clear }] = useFilePicker({
    accept:fileType == undefined ? ConstantArray.fileTypeArray : fileType,
    readAs: 'DataURL',
  })
  if (filesContent != 0){
    var addMediaArray = []
    filesContent.forEach((file) => {
      let base64 = file['content']
      let fileList = file['name']
      let base64ContentArray = base64.split(",")     
      let mimeType = base64ContentArray[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0]
      let dic = {
        'name': fileList,
        'base64': base64,
        'type':mimeType,
      }
      addMediaArray.push(dic)
    })
    data(addMediaArray)
    clear()
  }
  return (
    <View>
      <TouchableOpacity onPress={() => openFileSelector()}>
        <Text style={ style == undefined ? { color:title != undefined ? 'black' : 'white' }: style }> 
        {title != undefined ? title : 'Upload Media'}</Text>
      </TouchableOpacity>
    </View>
  )
}

