import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  Platform,
  Alert,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import Switch from "react-switch";

const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'payments';
let keys = ['enable_logs','enable_signup_first'];

export default class MoreScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusValue: false,
      dataLoad: false,
      logSwitchValue:false,
      signUpSwitchValue:false,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
  };
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config + `?key_group=${KeyGroup},general`,'get');
    console.log('responseJson ==> ', responseJson)
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({logSwitchValue: configs[keys[0] || false],
        signUpSwitchValue: configs[keys[1] || false],
        statusValue: configs['show_pending_balance'] || false});
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateChatApi = async () => {
    var configs = [];
    let values = [this.state.logSwitchValue,this.state.signUpSwitchValue];
    for (let k = 0; k < keys.length; k++ ) {
      let fDict = {
        key_group: 'general',
        key: keys[k],
        value: values[k],
        secured: false,
      };
      configs.push(fDict);
    }
    if (!AppConstants.appType) {
      let fDict = {
        key_group: KeyGroup,
        key: 'show_pending_balance',
        value: this.state.statusValue,
        secured: false,
      };
      configs.push(fDict);
    }
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config, 'post',
      JSON.stringify({ configs: configs }));
    this.setState({ dataLoad: true });
    if (responseJson['status'] == true) {
      this.alertView('uploaded');
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveBtnAction() {   
    this.setState({dataLoad: false});
    this.addOrUpdateChatApi();
  }
  //MARK:-  UI 
  renderHideBalance = () => {
    if (!AppConstants.appType) {
      return <View style={{ flexDirection: 'row', marginTop: -20 }}>
         <Switch
          onColor={colors.AppNewGreen}
          onChange={() => this.setState({ statusValue: !this.state.statusValue})}
          checked={this.state.statusValue}
          height={22}
          width={50}
        />
        <Text style={appConfigStyle.statusTxtStyle}>{'Hide balances & payouts in more section'}</Text>
      </View>
    } else {
      return <View />
    }
  }
  render() {
    return (
      <View style={{width: '70%', marginBottom: 130, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>More</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plainTextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{ flexDirection: 'column', flex: 1.5 }}>
            {this.renderHideBalance()}
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});
