import colors from '../HelperClasses/AppColor';
import { b1, t1, t2 } from './TextTitleStyleSheet';
// 'use strict';
var React = require('react-native');
var { StyleSheet, Dimensions } = React;
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let fixedWidth = 300;
const customWidth = 120


export default StyleSheet.create({
  previewFrameStyle: {
    borderWidth: 8,
    borderColor: colors.previewColor,
    width: fixedWidth,
    alignSelf: 'center',
    borderRadius: 40,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 5,
    backgroundColor: colors.BackgroundBlueColor,
    minHeight: 550,
    overflow: 'hidden',
    justifyContent:'space-between'
  },
  
  previewImageStyle: {
    width: fixedWidth-20,
    height: fixedWidth-20,
  },
  commonPreviewStyle: {
    padding: 10,
    paddingTop: 10,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    backgroundColor: 'white'
  },
  previewMainTitleStyle:{
    ...t1,
    color: 'white'
  },
  previewTitleStyle: {
    color: colors.blackColor,
    ...b1,
  },
  previewsubTitleStyle: {
    color: colors.Appgray,
    ...b1
  },
  bottomBtnViewStyle: {
    width: '100%',
    height: 50,
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 20,
  },
  applyBtnViewStyle: {
    borderRadius: 4,
    margin: 5,
    width: '50%',
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.AppNewGreen,
    marginRight:10,
    marginBottom:20,
  },
  commonViewStyle: {
    padding: 10,
    backgroundColor: 'white',
    borderWidth: 1, 
    borderColor: colors.SimonGray,
  },
  promoImageViewStyle:{
    width: 250, height: 120, margin: 10, borderRadius: 10 
  },
  horizontalCellItemStyle: {
    width: customWidth,
    margin: 10,
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 2,
    elevation: 2,
  },
  selectedImageStyle: {
    height: customWidth,
    width: customWidth,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  accountCellItemStyle: {
    backgroundColor: colors.AppNewGreen,
    height: 120,
    width: fixedWidth,
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  accountTitleTxtStyle: {
    ...t2,
    color: colors.AppWhite,
    marginTop: 5,
  },
  followContainerStyle: {
    backgroundColor: colors.AppTheme,
    paddingLeft: 10,
    paddingRight: 10,
    height: 25,
    borderRadius: 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
  roundView: {
    marginTop: 30,
    backgroundColor: colors.TransparentColor,
    height: 30,
    borderRadius: 25,
    flexDirection: "row",
    borderWidth: 1,
    borderColor: 'white',
    alignItems: 'center',
    width: fixedWidth-60,
  },
  txtFieldStyle: {
    ...b1,
    color: colors.AppWhite,
    textAlign: "left",
    paddingLeft: 10,
    width: "95%",
    marginLeft: 5,
    height: '100%',
  },
  loginBtnStyle: {
    backgroundColor: 'white',
    height: 30,
    borderRadius: 25,
    width: fixedWidth-60,    
    alignItems: 'center',
    justifyContent: 'center',
    marginTop:30,
    marginBottom:40,
  },
  btnTitleStyle: {
    ...b1,
    alignSelf: 'center',
    textAlign: 'center',
  },
})
