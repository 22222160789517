import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import colors from '../HelperClasses/AppColor';
import { Gradient } from 'react-gradient';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import TableCommonStyleSheet from '../StyleSheets/TableCommonStyleSheet';


const windowWidth = Dimensions.get('window').width;
let minHeight = 250
export default class CustomAlertView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descriptionHeight:minHeight,
      usedLines:1,
      txtValue:'',
    }
  }
  static propTypes = {
    titleValue: PropTypes.string,
    closeBtnAction: PropTypes.func,
    nextBtnAction: PropTypes.func,
    nextBtnTitle: PropTypes.string,
    showNextViewBool:PropTypes.bool,
    showNextButton:PropTypes.bool,
  };
  renderNextBtnView = () => {
    return <View style={[tableStyle.gradientViewBGStyle,{marginBottom:5}]}>
      <Gradient
        gradients={colors.GradientColors}
        property="background"
        gradientType={'linear'}
        duration={2333000}
        angle="0deg">
        <TouchableOpacity onPress={() => this.props.nextBtnAction()}>
          <Text style={tableStyle.saveBtnTxtStyle}>{ this.props.nextBtnTitle ?? 'Next'}</Text>
        </TouchableOpacity>
      </Gradient>
    </View>
  }
  renderCloseBtnView = () => {
    return <TouchableOpacity style={styles.closeBtnViewStyle} onPress={() => this.props.closeBtnAction() }>
      <Text style={CommonStyleSheet.commonBtnTxtStyle}>{'Close'}</Text>
    </TouchableOpacity>
  }
  render() {
    return <View style={[tableStyle.passwordViewStyle, { display: this.props.showNextViewBool ? 'flex' : 'none' }]}>
      <View style={{ backgroundColor: colors.AppWhite, height: 160, width: 350, borderRadius: 5 }}>
        <Text style={[CommonStyleSheet.plainTextStyle,{ textAlign: 'center',marginTop: 40}]}>
          {this.props.titleValue}
        </Text>
        <View style={{ flexDirection: 'row', flex: 1, marginTop: 20,justifyContent: 'center' }}>
          <View style={{ alignSelf: 'center' }}>
            {this.renderCloseBtnView()}
          </View>
          <View style={{ width: this.props.showNextButton == false ? 0 : 40 }} />
          <View style={{ alignSelf: 'center', marginRight: 10, display: this.props.showNextButton == false ? 'none' : 'flex' }}>
            {this.renderNextBtnView()}
          </View>
        </View>
      </View>
    </View>
  }
}

const styles = StyleSheet.create({
  closeBtnViewStyle: {
    borderWidth: 2,
    borderColor: colors.AppNewGreen,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    width: 100,
    height:37,
    marginLeft:20,
    marginTop:-6,
  }
})

