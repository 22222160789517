import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  TextInput,
  Dimensions,
  Platform,
  Alert,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import ReactFileReader from 'react-file-reader';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import {AppToast} from '../../HelperClasses/AppToast';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import AppConstants from '../../Constants/AppConstants';
import Switch from "react-switch";
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

const APPURL = require('../../Constants/URLConstants');
const windowheight = Dimensions.get('window').height;
let fixedHeight = 1700;
export default class HomepageAppearance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedImage: null,
      notificationIconImage: null,
      notificationIconUrl: '',
      appTitleIconImage: null,
      appTitleIconUrl: '',
      updateUI: false,
      pagerIndex: 0,
      register: '',
      appTitleHome: '',
      sellIconText: '',
      dataLoad: false,
      statusArray: [true, true, true, true, true, true],
      switchValue: true,
    };
  }
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=general', 'get', '','');
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        register: configs['registration_title'] || '',
        selectedImage: configs['sell_icon'] || '',
        notificationIconUrl: configs['notification_icon'] || '',
        notificationIconImage: configs['notification_icon'] || '',
        sellIconText: configs['sell_icon_text'] || '',
        appTitleHome: configs['app_title_home'] || '',
        appTitleIconUrl: configs['app_title_home_icon'] || '',
        appTitleIconImage: configs['app_title_home_icon'] || '',
        switchValue: configs['app_title_home_icon_enable'],
      });
      this.state.statusArray[3] = configs['invite_friends_collection_enabled'];
      this.state.statusArray[1] = configs['home_categories_enabled'];
      this.state.statusArray[4] = configs['home_review_reminders_enabled'];
      this.state.statusArray[2] = configs['home_promo_banners_enabled'];
    }
    this.setState({dataLoad: true});
  };
  uploadImageOnboarding = async () => {
    var imgParm = [];
    var uploadBase64 = [];

    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      if (fileName != null) {
        var dict = {
          name: this.state.selectedImage['fileList'][0]['name'],
          type: this.state.selectedImage['fileList'][0]['type'],
        };
        uploadBase64.push({
          file: this.state.selectedImage.base64,
          key:'sell_icon',
        });
        imgParm.push(dict);
      }
    }
    if (this.state.notificationIconImage != null) {
      let fileName = this.state.notificationIconImage.base64;
      if (fileName != null) {
        var dict = {
          name: this.state.notificationIconImage['fileList'][0]['name'],
          type: this.state.notificationIconImage['fileList'][0]['type'],
        };
        uploadBase64.push({
          file: this.state.notificationIconImage.base64,
          key:'notification_icon',
        });
        imgParm.push(dict);
      }
    }
    if (this.state.switchValue) {
      if (this.state.appTitleIconImage != null) {
        let fileName = this.state.appTitleIconImage.base64;
        if (fileName != null) {
          var dict = {
            name: this.state.appTitleIconImage['fileList'][0]['name'].replace(/ /g,''),
            type: this.state.appTitleIconImage['fileList'][0]['type'],
          };
          uploadBase64.push({
            file: this.state.appTitleIconImage.base64,
            key:'app_title_home_icon',
          });
          imgParm.push(dict);
        }
      }
    }
    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL, 'POST',  JSON.stringify({files: imgParm}),''
      );
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        // console.log('result',result);
        var uploadIncrement = 0;
        for (let i = 0; i < imgParm.length; i++) {
          // console.log('result',result);
          fetch(uploadBase64[i]['file']).then(async res => {
            const file_upload_res = await networkService.uploadFileWithSignedURL(
              result[i]['signedUrl'],
              imgParm[i]['type'],
              await res.blob(),
            );
            uploadIncrement++;
            if (uploadIncrement === uploadBase64.length) {
              var selectedImagePath = this.state.selectedImage != null ? this.state.selectedImage : '';
              for (let a = 0; a < uploadBase64.length; a++) {
                if (uploadBase64[a]['key'] == 'notification_icon') {
                  this.state.notificationIconUrl = result[a]['fileUri'];
                } else if (uploadBase64[a]['key'] == 'app_title_home_icon') {
                  this.state.appTitleIconUrl = result[a]['fileUri'];
                } else {
                  selectedImagePath = result[a]['fileUri']
                }
              }
              this.addOrUpdateWebsiteApi(selectedImagePath);
            }
          });
        }
      } else {
        this.alertView(responseJson);
      }
    } else {
      this.addOrUpdateWebsiteApi(
        this.state.selectedImage != null ? this.state.selectedImage : '',
      );
    }
  };
  addOrUpdateWebsiteApi = async imageAddess => {
    var configs = [];
    let valueArray = [
      this.state.register || '',
      imageAddess || '',
      this.state.sellIconText || '',
      this.state.appTitleHome || '',
      this.state.statusArray[3] || true,
      this.state.statusArray[1] || true,
      this.state.statusArray[4] || true,
      this.state.statusArray[2] || true,
      this.state.statusArray[5] || true,
      this.state.switchValue,
      this.state.appTitleIconUrl || '',
      this.state.notificationIconUrl || '',
    ]; 
    var configs = [];
    for (let i = 0; i < keyParameterArray.length; i++) {
      if (valueArray[i]) {
        if (valueArray[i].length != 0) {
          let fDict = {
            key_group: 'general',
            key: keyParameterArray[i],
            value: valueArray[i],
            secured: false,
          };
          configs.push(fDict);
        }
      }
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config, 'post', JSON.stringify({configs: configs}),
      '',
    );
    if (responseJson['status'] == true) {
      this.deleteKeysAPI(configs);
      //this.alertView('Uploaded successfully');
    } else {
      this.setState({dataLoad: true});
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    var deleteIndex = [];
    for (let q = 0; q < keyParameterArray.length; q++) {
      deleteParms.push(keyParameterArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = keyParameterArray.indexOf(name);
      // console.log('index', index);
      deleteIndex.push(index);
    }
    for (var i = deleteIndex.length - 1; i >= 0; i--) {
      deleteParms.splice(deleteIndex[i], 1);
    }
    // console.log('deleteParms', deleteParms);
    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms;
      const responseJson = await networkService.networkCall(
        urlParm,'delete','','');
      // console.log('configs', responseJson);
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    } else {
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    }
  };

  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }

  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    this.setState({dataLoad: false});
    this.uploadImageOnboarding();
  }
  switchSattusBtnAction(id) {
    this.state.statusArray[id] = !this.state.statusArray[id];
    this.setState({updateUI: !this.state.updateUI});
  }
  onPick = (image,index) => {
    console.log('image =>', index);
    if (index == 2) {
      this.setState({appTitleIconImage: image});
    } else if (index == 3) {
      this.setState({notificationIconImage: image});
    } else {
      this.setState({selectedImage: image});
    }
  };

  /*  UI   */

  imageViewPicker = ({id}) => {
    var msgTxt = 'Choose icon';
    if (Platform.OS === 'web') {
      return (
        <View style={appConfigStyle.dottedViewStyle}>
          <ReactFileReader
            fileTypes={['.png', '.jpg']}
            base64={true}
            style={appConfigStyle.dottedViewStyle}
            handleFiles={image => this.onPick(image, id)}>
            <View style={{height:5}} />
            <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
            <View style={{height:5}} />
          </ReactFileReader>
        </View>
      );
    } else {
      return (
        <TouchableOpacity style={appConfigStyle.dottedViewStyle}>
          <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
        </TouchableOpacity>
      );
    }
  };
  viewSelectedImages = props => {
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      return (
        <View style={appConfigStyle.selectImagesViewStyle}>
          <Image
            source={{
              uri:
                fileName != null
                  ? this.state.selectedImage.base64
                  : this.state.selectedImage,
            }}
            style={appConfigStyle.SelectedImageStyle}
          />
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderNotificationImages = props => {
    if (this.state.notificationIconImage != null) {
      let fileName = this.state.notificationIconImage.base64;
      return (
        <View style={appConfigStyle.selectImagesViewStyle}>
          <Image
            source={{
              uri:
                fileName != null
                  ? this.state.notificationIconImage.base64
                  : this.state.notificationIconImage,
            }}
            style={appConfigStyle.SelectedImageStyle}
          />
        </View>
      );
    } else {
      return <View />;
    }
  };
  showAppTitleImagePicker = () => {
    if (this.state.switchValue) {
      return (<View style={{ marginTop: 20 }}>
        <Text style={appConfigStyle.titleTxtStyle}>App Title Home Icon  
            <Text style={CommonStyleSheet.helperTextStyle}>{` (icon should be white and in png format)`} </Text>
        </Text>
        <this.imageViewPicker id={2}/>
        <View style={{ marginTop: 20, flexDirection: 'row' }}>
          <this.viewAppTitleImages />
        </View>
      </View>
      )
    } else {
      return <View />
    }
  }
  viewAppTitleImages = props => {
    if (this.state.appTitleIconImage != null) {
      let fileName = this.state.appTitleIconImage.base64;
      return (
        <View style={appConfigStyle.selectImagesViewStyle}>
          <Image
            source={{
              uri:
                fileName != null
                  ? this.state.appTitleIconImage.base64
                  : this.state.appTitleIconImage,
            }}
            style={appConfigStyle.SelectedImageStyle}
          />
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderSellIconImageView = () => {
    if (AppConstants.appType) {
    return <View />
    }else {
      return (<View style={{ marginTop: 16 }}>
        <Text style={appConfigStyle.titleTxtStyle}>Sell Icon</Text>
        <this.imageViewPicker />
        <View style={{ marginTop: 16, flexDirection: 'row' }}>
          <this.viewSelectedImages />
        </View>
      </View>)
    }
  }

  render() {
    return (
      <View style={styles.Container}>
        <View style={styles.scrollViewSTyle}>
          <View style={appConfigStyle.mainView}>
            <Text style={tableStyle.listTitleText}>Other appearance</Text>
            <Text style={appConfigStyle.subTitleStyle}>
              {AppConstants.plain2TextString}
            </Text>
            <View style={appConfigStyle.subViewStyle}>
              <View style={{flexDirection: 'column', flex: 1.5}}>
                <View style={{marginTop: 16}}>
                  <Text style={appConfigStyle.titleTxtStyle}> Registration text </Text>
                  <TextInput
                    style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                    value={this.state.register}
                    onChangeText={name => this.setState({register: name})}
                  />
                </View>
               <this.renderSellIconImageView />
                <View style={{marginTop: 16}}>
                  <Text style={appConfigStyle.titleTxtStyle}>Sell icon text</Text>
                  <TextInput
                    style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                    value={this.state.sellIconText}
                    onChangeText={name => this.setState({sellIconText: name})}
                  />
                </View>
                <View style={{marginTop: 16}}>
                  <Text style={appConfigStyle.titleTxtStyle}>Notification icon</Text>
                  <this.imageViewPicker  id={3}/>
                  <View style={{marginTop: 16, flexDirection: 'row'}}>
                    <this.renderNotificationImages />
                  </View>
                </View>
                <View style={{marginTop: 16}}>
                  <Text style={appConfigStyle.titleTxtStyle}>
                    App title in home screen
                  </Text>
                  <TextInput
                    value={this.state.appTitleHome}
                    style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                    onChangeText={name => this.setState({appTitleHome: name})}
                  />
                </View>
                <View style={{marginTop: 10}}>
                  <View style={{ flexDirection: 'row', marginTop: 20 }}>
                    <Switch
                      onColor={colors.AppNewGreen}
                      onChange={() => this.setState({ switchValue: !this.state.switchValue })}
                      checked={this.state.switchValue}
                      height={22}
                      width={50}
                    />
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                      <Text style={appConfigStyle.statusTxtStyle}>
                        {'Enable app title icon instead of text'}
                      </Text>
                    </View>
                  </View>
                </View>
                <this.showAppTitleImagePicker />
              </View>
              <View style={appConfigStyle.lineViewStyle} />
              <View style={{flexDirection: 'column', flex: 1}} />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
    //}
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
    height: '100%',
  },
});
const switchTitleStrings = [
  'Show Action Bar',
  'Show Category section',
  'Show Promo Banners',
  'Show Invite friends',
  'Show Review reminders',
  'Show Nearby Collection',
];
let keyParameterArray = [
  'registration_title',
  'sell_icon',
  'sell_icon_text',
  'app_title_home',
  'invite_friends_collection_enabled',
  'home_categories_enabled',
  'home_review_reminders_enabled',
  'home_promo_banners_enabled',
  'near_by_stores_collection_enabled',
  'app_title_home_icon_enable',
  'app_title_home_icon',
  'notification_icon'
];
