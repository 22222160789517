import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  SafeAreaView,
  Dimensions,
  Image,
  Linking,
} from 'react-native';
import PropTypes from 'prop-types';
import colors from '../HelperClasses/AppColor';
import tlogo from '../assets/tradlyicon.png';
import branchesIcon from '../assets/branchesIcon.png';
import driver from '../assets/driver.png';
import fleetsIcon from '../assets/fleetsIcon.png';
import settingIcon from './../assets/settingLightIcon.png';
import AppConstants from '../Constants/AppConstants';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import {getProductAccess, getQueryParam} from '../HelperClasses/SingletonClass';
import {ToastContainer} from 'react-toastify';
import {AppToast} from '../HelperClasses/AppToast';
import closeIcon from '../assets/closeBlackIcon.png';
import {ProjectTypeModel} from '../Models/ProjectTypeModel';
import {addMixpanelEvent, MixpanelEvent} from '../Models/MixPannelEnum';

const APPURL = require('../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;

let fixedWidth = 800;
let itsMobileView = windowWidth < fixedWidth ? true : false;

export default class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoad: true,
    };
  }
  static propTypes = {
    index: PropTypes.number,
    backBtn: PropTypes.func,
    showBackBtn: PropTypes.bool,
  };
  componentDidMount() {}
  clientTanantsApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.clients,
      'get',
      '',
      AppConstants.BearerForTantantsAndUserDetail,
    );
    if (responseJson['status'] == true) {
      this.setState({dataLoad: true});
      var configs = responseJson['data']['tenants'];
      let productAccess = configs[0]['product_access'];

      AppConstants.productAccessArray = productAccess;
      let projectT = ProjectTypeModel.delivery;
      let checkAccess = productAccess.includes(projectT);
      if (checkAccess) {
        AppConstants.projectType = projectT;
        if (AppConstants.projectType == ProjectTypeModel.delivery) {
          let host = `/?app=delivery`;
          Linking.openURL(host);
        } else {
          let host = `/?app=marketplace`;
          Linking.openURL(host);
        }
      }
      this.closeBtnAction();
    } else {
      this.setState({dataLoad: true});
    }
  };
  activateTenantAPI = async () => {
    let dic = {product: 'delivery'};
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.activateTenant,
      'post',
      JSON.stringify(dic),
    );
    if (responseJson['status'] == true) {
      this.clientTanantsApi();
    } else {
      this.setState({dataLoad: true});
      AppToast(responseJson);
    }
  };
  getStartBtnAction() {
    addMixpanelEvent(MixpanelEvent.fleetGetStarted);
    this.setState({dataLoad: false});
    this.activateTenantAPI();
  }
  termAndContionBtnAction() {
    window.open(AppConstants.termAndCondition, '_blank');
  }
  privacyPolicyBtnAction() {
    window.open(AppConstants.privacyURL, '_blank');
  }
  closeBtnAction() {
    // this.props.navigation.dispatch(StackActions.replace('SuperAdmin'))
  }
  //MARK:-  APIs Method 
  renderElement = id => {
    console.log('id', id);
    let title1 =
      id == 0
        ? 'Add Multiple locations (Stores, Warehouses, Storage Points)'
        : 'Assign drivers under fleet & stores';
    let title2 =
      id == 0
        ? 'Add varieties of fleet and their capacity'
        : 'Connect with your e-commerce system or manual orders ';
    let img = id == 0 ? branchesIcon : fleetsIcon;
    let img2 = id == 1 ? settingIcon : driver;
    let marginTop = id == 1 ? 30 : 10;

    var views = [];
    views.push(
      <View style={{width: '32%', alignItems: 'center'}}>
        <View style={{flexDirection: 'row', margin: 16}}>
          <Image source={img} style={{height: 30, width: 30}} />
          <View style={{width: 16}} />
          <Text style={{color: colors.Appgray, fontWeight: '400'}}>
            {title1}
          </Text>
        </View>
        <View style={{flexDirection: 'row', margin: 16, marginTop: marginTop}}>
          <Image source={img2} style={{height: 30, width: 30}} />
          <View style={{width: 16}} />
          <Text style={{color: colors.Appgray, fontWeight: '400'}}>
            {title2}
          </Text>
        </View>
      </View>,
    );
    return views;
  };

  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <ToastContainer />
        <View style={{flexDirection: 'row', flex: 1}}>
          <View
            style={{flex: 6, alignItems: 'center', justifyContent: 'center'}}>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Image
                style={{width: 36, height: 36, marginRight: 10}}
                source={tlogo}
              />
              <Text
                style={{
                  color: colors.AppTitleBlack,
                  fontSize: 20,
                  fontWeight: '800',
                }}>
                Tradly Fleets{' '}
              </Text>
            </View>
            <Text style={{color: colors.Appgray, marginTop: 10}}>
              Manage your delivery and pickup operations{' '}
            </Text>
            <View style={{padding: 20, alignItems: 'center', marginTop: 50}}>
              <Text
                style={{
                  color: colors.Appgray,
                  fontSize: 18,
                  fontWeight: '500',
                  textAlign: 'center',
                }}>
                {`${'It’s simple to manage your fleet operation & Last Mile Delivery'}`}
              </Text>
              <View style={styles.leftViewContainer}>
                {this.renderElement(0)}
                <View style={{width: 40}} />
                {this.renderElement(1)}
              </View>
            </View>
          </View>
          <View style={{flex: 3, justifyContent: 'center'}}>
            <View style={styles.startFreeTrailView}>
              <View style={{alignItems: 'center'}}>
                <Text
                  style={{
                    color: colors.AppTitleBlack,
                    fontSize: 20,
                    fontWeight: '600',
                  }}>
                  {AppConstants.userFullName}
                </Text>
                <Text style={{color: 'gray', fontSize: 14, marginTop: 5}}>
                  {AppConstants.userEmail}
                </Text>
              </View>
              <TouchableOpacity
                style={styles.startFreeTrailBtnStyle}
                onPress={() => this.getStartBtnAction()}>
                <Text style={{color: colors.AppWhite}}>GET STARTED</Text>
              </TouchableOpacity>
              <View
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  alignItems: 'center',
                  marginTop: 10,
                }}>
                <Text
                  style={{
                    fontSize: 12,
                    color: colors.AppTitleBlack,
                    alignItems: 'center',
                    textAlign: 'center',
                  }}>
                  No credit card required to get started. By signing up, I
                  accept the Tradly
                  <TouchableOpacity
                    onPress={() => this.termAndContionBtnAction()}>
                    <Text style={{color: colors.blueColor}}>
                      Terms{' '}
                      <Text style={{color: colors.AppTitleBlack}}> and </Text>
                      <TouchableOpacity
                        onPress={() => this.privacyPolicyBtnAction()}>
                        <Text>acknowledge the Privacy Policy.</Text>
                      </TouchableOpacity>
                    </Text>
                  </TouchableOpacity>
                </Text>
              </View>
            </View>
          </View>
          <TouchableOpacity
            style={{alignSelf: 'baseline', margin: 20}}
            onPress={() => this.closeBtnAction()}>
            <Image
              style={{height: 20, width: 20, marginTop: 2}}
              source={closeIcon}
            />
          </TouchableOpacity>
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  leftViewContainer: {
    flexDirection: 'row',
    marginTop: 40,
    width: '90%',
    height: 160,
    justifyContent: 'center',
  },
  startFreeTrailView: {
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 10,
    height: 240,
    width: 350,
    backgroundColor: colors.AppLightGreen,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    justifyContent: 'center',
  },
  startFreeTrailBtnStyle: {
    margin: 20,
    height: 40,
    backgroundColor: colors.AppNewGreen,
    borderRadius: 2,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 10,
  },
});
