import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Platform,
  Alert,
  TouchableOpacity,
  SafeAreaView,
  Dimensions,
  Image,
  Linking,
} from 'react-native';
import PropTypes from 'prop-types';

import colors from '../HelperClasses/AppColor';
import appStore_Icon from '../assets/appleLogo.png';
import playStore_Icon from '../assets/playStore.png';
import preview_Icon from '../assets/previewImageIcon.png';
import github_Icon from '../assets/githubIcon.png';
import domainIcon from '../assets/domainIcon.png';
import youtubeLogo from '../assets/youtubeLogo.png';
import logoName_Icon from '../assets/tradlylogo.png';
import butterFly from '../assets/butterFly.png';
import npmIcon from '../assets/npmIcon.png';
import templateIcon from '../assets/templateIcon.png';
import buyIcon from '../assets/buyIcon.png';
import tickIcon from '../assets/tickIcon.png';

import networkService from '../Network/NetworkManager';
import AppConstants from '../Constants/AppConstants';
import apiIcon from '../assets/apiIcon.png';
import RightViews from '../MainRoots/RightView';
import MobAppConfig from '../Settings/MobileAppConfig/MobileAppConfig';
import Listing from '../Settings/Listings/Listings';
import ShippingMethod from '../Settings/ShippingMethod/ShippingMethods';
import PaymentMethod from '../Settings/PaymentMethod/PaymentMethods';
import inspirationIcon from '../assets/inspirationIcon.png';
import whatsnewIcon from '../assets/whatsnewIcon.png';
import {
  addMixpanelEvent,
  MixpanelEvent,
  MixPannelEnum,
} from '../Models/MixPannelEnum';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import CategoryList from '../Settings/Listings/CategoryList';
import {TypeEnum} from '../Models/CommonEnum';

const APPConstants = require('../Constants/AppConstants');
const windowWidth = Dimensions.get('window').width;

let fixedWidth = 800;
let itsMobileView = windowWidth < fixedWidth ? true : false;

export default class PreviewButton extends Component {
  static propTypes = {
    id: PropTypes.any,
  };
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  linkBtnAction = async id => {
    this.setState({showHelpView: false});
    var pathURL =
      id == 1 ? APPConstants.appStoreLink : APPConstants.playStoreLink;
    pathURL = id == 3 ? AppConstants.githubLink : pathURL;
    if (id == 1) {
      this.mixpanelInfo(MixPannelEnum.appStore);
    }
    if (id == 2) {
      this.mixpanelInfo(MixPannelEnum.playStore);
    }
    if (id == 3) {
      this.mixpanelInfo(MixPannelEnum.cloneStartedKits);
    }
    if (id == 4) {
      this.mixpanelInfo(MixPannelEnum.youtubeTutorials);
      pathURL = AppConstants.youtubeLink;
    } else if (id == 5) {
      this.mixpanelInfo(MixPannelEnum.apiDocs);
      pathURL = AppConstants.apiDocs;
    } else if (id == 6) {
      let vlink = `https://vercel.com/new/clone?repository-url=https://github.com/TRADLY-PLATFORM/Butterflies&project-name=${this.state.tenantId}&repository-name=${this.state.tenantId}&demo-title=DemoStorefront&demo-description=Tradly%20starter%20kit%20to%20launch%20your%20marketplace%20&demo-url=https://sneakershub.vercel.app&demo-image=https://user-images.githubusercontent.com/61427976/133940948-09361d1c-08b3-43e3-b588-ac1dd3ff3e0c.png&envLink=https://tradly.app/docs/vercel&integration-ids=oac_ZjlyX04ie0YNeck9UjeAMfba&redirect-url=https://superadmin.tradly.app&developer-id=oac_ZjlyX04ie0YNeck9UjeAMfba`;
      pathURL = vlink;
    } else if (id == 7) {
      this.mixpanelInfo(MixPannelEnum.jsSDK);
      pathURL = AppConstants.npmPackage;
    } else if (id == 8) {
      this.mixpanelInfo(MixPannelEnum.changeWebTemplates);
      let path = AppConstants.changeWebTemplateURL;
      pathURL = path;
    } else if (id == 9) {
      if (AppConstants.domainKey.length != 0) {
        this.mixpanelInfo(MixPannelEnum.previewWebsite);
        let path = AppConstants.domainKey;
        pathURL = path;
      } else {
        pathURL = '';
      }
    } else if (id == 10) {
      this.mixpanelInfo(MixPannelEnum.changeDomain);
      pathURL = AppConstants.customDomainLink;
    } else if (id == 11) {
      this.mixpanelInfo(MixPannelEnum.buyAppTemplate);
      pathURL = AppConstants.mobileTemplateURL;
    } else if (id == 12) {
      this.mixpanelInfo(MixPannelEnum.clickResources);
      pathURL = AppConstants.growingPlatformsURL;
    } else if (id == 13) {
      this.mixpanelInfo(MixPannelEnum.clickWhatsNew);
      pathURL = AppConstants.whatsNewURL;
    }
    if (Platform.OS == 'web') {
      if (pathURL.length != 0) {
        window.open(pathURL, '_blank');
      } else {
        // Alert.alert(`No domain set yet`);
      }
    } else {
      const supported = await Linking.canOpenURL(pathURL);
      if (supported) {
        await Linking.openURL(pathURL, '_blank');
      } else {
        Alert.alert(`Don't know how to open this URL: ${pathURL}`);
      }
    }
  };
  mixpanelInfo(value) {
    addMixpanelEvent(MixpanelEvent.GetStarted, {Action: value});
  }
  renderPreviewButtons = () => {
    let id = this.props.id;
    var title = '';
    var buttonTitle = '';
    var buttonID = '';
    var imageIcon = domainIcon;
    var BGColor = colors.AppNewGreen;
    var txtColor = colors.AppNewGreen;
    var tickView = [];
    if (id == 1) {
      title = 'Web Templates';
      buttonTitle = 'Change Web Template';
      buttonID = 8;
      imageIcon = templateIcon;
      BGColor = colors.blackColor;
    } else if (id == 2) {
      title = 'Website Preview';
      buttonTitle = 'Preview Your Website';
      buttonID = 9;
    } else if (id == 3) {
      title = 'Custom Domain (Web Address)';
      buttonTitle = 'Manage Custom Domain (Web Address)';
      buttonID = 10;
      let isComplete = AppConstants.siteURL == '' ? false : true;
      tickView.push(
        <View
          style={[
            styles.tickViewStyle,
            {
              marginLeft: isComplete ? 10 : 0,
              display: isComplete ? 'flex' : 'none',
            },
          ]}>
          <Image
            style={{
              width: 12,
              height: 12,
              display: isComplete ? 'flex' : 'none',
            }}
            source={tickIcon}
          />
        </View>,
      );
    } else if (id == 4) {
      title = 'Mobile App Templates';
      buttonTitle = 'Buy Mobile App Templates';
      imageIcon = buyIcon;
      buttonID = 11;
      BGColor = colors.blackColor;
    }
    return (
      <View>
        <View style={styles.downloadBtnViewStyle}>
          <TouchableOpacity onPress={() => this.linkBtnAction(buttonID)}>
            <View style={[styles.lightBorderViewStyle]}>
              <Image
                style={[
                  styles.githubIconStyle,
                  {tintColor: colors.AppNewGreen},
                ]}
                resizeMode="contain"
                source={imageIcon}
              />
              <Text
                style={[
                  CommonStyleSheet.themeColorTextStyle,
                  {color: txtColor, padding: 5},
                ]}>
                {buttonTitle}
              </Text>
              {tickView}
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  render() {
    console.log('this.props.id', this.props.id);
    return (
      <View>
        <this.renderPreviewButtons />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  downloadBtnViewStyle: {
    flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginTop: 5,
  },
  lightBorderViewStyle: {
    height: 40,
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    borderRadius: 5,
    borderColor: colors.AppNewGreen,
    borderWidth: 1,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
  },
  tickViewStyle: {
    width: 24,
    height: 24,
    backgroundColor: colors.Appgreen,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
  },
  githubIconStyle: {
    width: 25,
    height: 25,
  },
});
