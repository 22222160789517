import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Dimensions,
  Platform,
  SafeAreaView,
  Alert,
  Image,
  TouchableOpacity,
  ScrollView,
} from 'react-native';

import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import AppConstants from '../../Constants/AppConstants';
import { AppToast } from '../../HelperClasses/AppToast';
import colors from '../../HelperClasses/AppColor';
import Switch from "react-switch";
import ReactFileReader from 'react-file-reader';
import PreviewStyleSheet from '../../StyleSheets/PreviewStyleSheet';
import categoryValueIcon from '../../assets/categoryValueIcon.png'//'../../assets/categoryValueIcon.png';
import chatAppIcon from '../../assets/chatAppIcon.png'
import homeAppIcon from '../../assets/homeAppIcon.png'
import moreAppIcon from '../../assets/moreAppIcon.png'
import socialFeedAppIcon from '../../assets/socialFeedAppIcon.png'
import placeholder from '../../assets/pPlaceholder.png';

import SettingStyleSheet from '../../StyleSheets/SettingStyleSheet';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import HelperVideoView from '../../Component/HelperVideoView';
import { previewStatusBarView } from '../../Component/CommonComponents';
import TextTitleStyleSheet from '../../StyleSheets/TextTitleStyleSheet';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';


const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;
let KeyGroup = 'general';
// const uPref = new userPref();

export default class HomePageCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeFollowTitle: '',
      nearByTitle: '',
      latestProductTitle: '',
      dataLoad: false,
      statusArray: [true, true, true, true, true, true, true],
      selectedImage: null,
      appTitleHome: '',
      sellIconText: '',
      appTitleIconImage: null,
      appTitleIconUrl: '',
      appHomeIconSwitch: false,

    };
  }
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=general,onboarding', 'get', '', '');
    // console.log('configs', responseJson);
    this.setState({ currencyArray: [] });
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        storeFollowTitle: configs['stores_to_follow_collection_title'] || '',
        nearByTitle: configs['near_by_stores_collection_title'] || '',
        latestProductTitle: configs['latest_products_collection_title'] || '',
        selectedPrimaryColor: configs['app_color_primary'] || '',
        selectedImage: configs['sell_icon'] || '',
        sellIconText: configs['sell_icon_text'] || '',
        appTitleHome: configs['app_title_home'] || '',
        appTitleIconUrl: configs['app_title_home_icon'] || '',
      });
      this.state.statusArray[0] = configs['near_by_stores_collection_enabled'] || true;
      this.state.statusArray[3] = configs['invite_friends_collection_enabled'] || true;
      this.state.statusArray[1] = configs['home_categories_enabled'] || true;
      this.state.statusArray[4] = configs['home_review_reminders_enabled'] || true;
      this.state.statusArray[2] = configs['home_promo_banners_enabled'] || true;
      this.state.statusArray[5] = configs['stores_to_follow_collection_enabled'] || true;
      if (AppConstants.appType) {
        this.state.statusArray.splice(0, 1);
      }
    }
    this.setState({ dataLoad: true });
  }
  uploadImageOnboarding = async () => {
    var imgParm = [];
    var uploadBase64 = [];

    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      if (fileName != null) {
        var dict = {
          name: this.state.selectedImage['fileList'][0]['name'],
          type: this.state.selectedImage['fileList'][0]['type'],
        };
        uploadBase64.push({
          file: this.state.selectedImage.base64,
          key: 'sell_icon',
        });
        imgParm.push(dict);
      }
    }
    if (this.state.switchValue) {
      if (this.state.appTitleIconImage != null) {
        let fileName = this.state.appTitleIconImage.base64;
        if (fileName != null) {
          var dict = {
            name: this.state.appTitleIconImage['fileList'][0]['name'].replace(/ /g, ''),
            type: this.state.appTitleIconImage['fileList'][0]['type'],
          };
          uploadBase64.push({
            file: this.state.appTitleIconImage.base64,
            key: 'app_title_home_icon',
          });
          imgParm.push(dict);
        }
      }
    }
    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL, 'POST', JSON.stringify({ files: imgParm }), ''
      );
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        var uploadIncrement = 0;
        for (let i = 0; i < imgParm.length; i++) {
          fetch(uploadBase64[i]['file']).then(async res => {
            const file_upload_res = await networkService.uploadFileWithSignedURL(
              result[i]['signedUrl'], imgParm[i]['type'], await res.blob())
            uploadIncrement++;
            if (uploadIncrement === uploadBase64.length) {
              var selectedImagePath = this.state.selectedImage != null ? this.state.selectedImage : '';
              for (let a = 0; a < uploadBase64.length; a++) {
                if (uploadBase64[a]['key'] == 'app_title_home_icon') {
                  this.state.appTitleIconUrl = result[a]['fileUri'];
                } else {
                  selectedImagePath = result[a]['fileUri']
                }
              }
              this.addOrUpdateAppDetailApi(selectedImagePath);
            }
          });
        }
      } else {
        this.alertView(responseJson);
      }
    } else {
      this.addOrUpdateAppDetailApi(this.state.selectedImage != null ? this.state.selectedImage : '')
    }
  }
  addOrUpdateAppDetailApi = async (sellIcon) => {
    var configs = []
    let valueArray = [
      this.state.appTitleHome || '',
      this.state.appHomeIconSwitch || false,
      this.state.appTitleIconUrl || '',
      sellIcon || '',
      this.state.sellIconText || '',
      this.state.storeFollowTitle || '',
      this.state.nearByTitle || '',
      this.state.latestProductTitle || '',
      this.state.statusArray[2] || false,
      this.state.statusArray[0] || false,
      this.state.statusArray[3] || false,
      this.state.statusArray[1] || false,
      this.state.statusArray[4] || false,
      this.state.statusArray[5] || false,
    ];
    // var configs = [];
    for (let i = 0; i < keyParameterArray.length; i++) {
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keyParameterArray[i],
          value: valueArray[i],
          secured: false,
        };
        // console.log('fDict ==> ', fDict);
        configs.push(fDict);
      }
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config, 'post', JSON.stringify({ configs: configs }), '');
    this.setState({ dataLoad: true });
    if (responseJson['status'] == true) {
      this.deleteKeysAPI(configs);
    } else {
      this.setState({ dataLoad: true });
      this.alertView(responseJson);
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    // console.log('deleteParms => ', deleteParms);
    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms;
      const responseJson = await networkService.networkCall(urlParm, 'delete', '', '');
      // console.log('configs', responseJson);
      this.setState({ dataLoad: true });
      this.alertView('Uploaded successfully');
    } else {
      this.setState({ dataLoad: true });
      this.alertView('Uploaded successfully');
    }
  };

  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }

  //MARK:-  Buttons Action Method 
  saveBtnAction() {
    this.setState({ dataLoad: false });
    this.uploadImageOnboarding();
  }
  switchSattusBtnAction(id) {
    this.state.statusArray[id] = !this.state.statusArray[id];
    this.setState({ updateUI: !this.state.updateUI });
  }
  onPick = (image, index) => {
    if (index == 2) {
      this.setState({ appTitleIconImage: image });
    } else {
      this.setState({ selectedImage: image });
    }
  }
  helperVideoBtnAction(){
    addMixpanelEvent(MixpanelEvent.howItWorks,{'Action' : MixPannelEnum.homePageCustomisation})
    window.open(AppConstants.homePageCustomisationHelperVideoLink, '_blank')
  }
  /*  UI   */
  renderHelperVideoBtn = () => {
    return <TouchableOpacity style={{borderRadius:5,marginTop:4,width:100,height:20,alignItems:'center',justifyContent:'center', backgroundColor:colors.AppYellow}}
       onPress={ () => this.helperVideoBtnAction()}>
        <Text style={CommonStyleSheet.subTitleStyle}>See how it works</Text>
     </TouchableOpacity>
   }
  renderAllSwitches = props => {
    var views = [];
    var switchTitleString = [...switchTitleStrings]
    if (AppConstants.appType) {
      switchTitleString.splice(0, 1);
    }
    for (let i = 0; i < switchTitleString.length; i++) {
      views.push(
        <View style={{ flexDirection: 'row', marginTop: 20 }}>
          <Switch
            onColor={colors.AppNewGreen}
            onChange={() => this.switchSattusBtnAction(i)}
            checked={this.state.statusArray[i]}
            height={22}
            width={50}
          />
          <Text style={appConfigStyle.statusTxtStyle}>{switchTitleString[i]}</Text>
        </View>,
      );
    }
    return views;
  }
  imageViewPicker = ({ id }) => {
    var msgTxt = 'Choose icon';
    if (Platform.OS === 'web') {
      return (
        <View style={appConfigStyle.dottedViewStyle}>
          <ReactFileReader
            fileTypes={['.png', '.jpg']}
            base64={true}
            style={appConfigStyle.dottedViewStyle}
            handleFiles={image => this.onPick(image, id)}>
            <View style={{ height: 5 }} />
            <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
            <View style={{ height: 5 }} />
          </ReactFileReader>
        </View>
      );
    } else {
      return (
        <TouchableOpacity style={appConfigStyle.dottedViewStyle}>
          <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
        </TouchableOpacity>
      );
    }
  };
  viewSelectedImages = props => {
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      return (
        <View style={appConfigStyle.selectImagesViewStyle}>
          <Image
            source={{ uri: fileName != null ? this.state.selectedImage.base64 : this.state.selectedImage }}
            style={appConfigStyle.SelectedImageStyle} />
        </View>
      );
    } else {
      return <View />;
    }
  }
  renderSellIconImageView = () => {
    // if (AppConstants.appType) {
    // return <View />
    // }else {
    return (<View>
      <View style={{ marginTop: 16 }}>
        <Text style={appConfigStyle.titleTxtStyle}>Sell icon text</Text>
        <TextInput
          style={[appConfigStyle.txtFieldStyle,{outline:0}]}
          placeholder={'Enter sell icon text'}
          value={this.state.sellIconText}
          onChangeText={name => this.setState({ sellIconText: name })}
        />
      </View>
      <View style={{ marginTop: 16 }}>
        <Text style={appConfigStyle.titleTxtStyle}>Sell Icon</Text>
        <this.imageViewPicker />
        <View style={{ marginTop: 16, flexDirection: 'row' }}>
          <this.viewSelectedImages />
        </View>
      </View>
    </View>)
    // }
  }
  showAppTitleImagePicker = () => {
    if (this.state.appHomeIconSwitch) {
      return (<View style={{ marginTop: 20 }}>
        <Text style={appConfigStyle.titleTxtStyle}>App Title Home Icon
          <Text style={CommonStyleSheet.helperTextStyle}>{` (icon should be white and in png format)`} </Text>
        </Text>
        <this.imageViewPicker id={2} />
        <View style={{ marginTop: 20, flexDirection: 'row' }}>
          <this.viewAppTitleImages />
        </View>
      </View>
      )
    } else {
      return <View />
    }
  }
  viewAppTitleImages = props => {
    if (this.state.appTitleIconImage != null) {
      let fileName = this.state.appTitleIconImage.base64;
      return (
        <View style={appConfigStyle.selectImagesViewStyle}>
          <Image source={{ uri: fileName != null ? this.state.appTitleIconImage.base64 : this.state.appTitleIconImage }}
            style={[appConfigStyle.SelectedImageStyle,{backgroundColor:colors.transparentBlackColor}]} resizeMode='center' />
        </View>
      );
    } else {
      return <View />;
    }
  }
  renderAppTitle = () => {
    return <View>
      <View style={{ marginTop: 16 }}>
        <Text style={appConfigStyle.titleTxtStyle}>App title in home screen</Text>
        <TextInput
          placeholder='Enter app title in home'
          value={this.state.appTitleHome}
          style={[appConfigStyle.txtFieldStyle,{outline:0}]}
          onChangeText={name => this.setState({ appTitleHome: name })}
        />
      </View>
      <View style={{ marginTop: 10 }}>
        <View style={{ flexDirection: 'row', marginTop: 20 }}>
          <Switch
            onColor={colors.AppNewGreen}
            onChange={() => this.setState({ appHomeIconSwitch: !this.state.appHomeIconSwitch })}
            checked={this.state.appHomeIconSwitch}
            height={22}
            width={50}
          />
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={appConfigStyle.statusTxtStyle}>
              {'Enable app title icon instead of text'}
            </Text>
          </View>
        </View>
      </View>
      <this.showAppTitleImagePicker />
    </View>
  }
  renderAccountPreview = ({ name }) => {
    var accountsView = []
    var accountItemView = []
    accountItemView.push(<View>
      <View style={PreviewStyleSheet.horizontalCellItemStyle}>
        <Image style={PreviewStyleSheet.selectedImageStyle} source={placeholder} />
        <View style={{ padding: 10 }}>
          <Text style={[CommonStyleSheet.subTitleStyle,{color:colors.AppTitleBlack}]} numberOfLines={1}>{'Account Name'}</Text>
          <View style={{ flexDirection: 'row', marginTop: 10 }}>
            <Image style={{ height: 25, width: 25, borderRadius: 12.5 }} source={placeholder} />
            <Text style={[CommonStyleSheet.staticTextStyle,{padding: 5 }]}>{`Username `}</Text>
          </View>
        </View>
      </View>
    </View>)
    accountsView.push(<View>
      <View style={[PreviewStyleSheet.accountCellItemStyle, { backgroundColor: this.state.selectedPrimaryColor }]}>
        <Text style={PreviewStyleSheet.accountTitleTxtStyle}>{name}</Text>
      </View>
      <View style={{ backgroundColor: colors.AppWhite, width: '100%', paddingBottom: 10, }}>
        <View style={{ marginTop: -80, flexDirection: 'row' }}>
          {accountItemView}
          {accountItemView}
        </View>
      </View>
    </View>)

    return <View>
      {accountsView}
    </View>
  }
  renderPreviewView = () => {
    var views = []
    var categoryViews = []
    var promoViews = []

    var listingsView = []
    var listingsItemView = []
    var tabbarView = []
    var sellIconName = ''
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      sellIconName = fileName != null ? this.state.selectedImage.base64 : this.state.selectedImage
    }
    var Iconaray = [homeAppIcon, socialFeedAppIcon, sellIconName, chatAppIcon, moreAppIcon]
    var textaray = ['Home', 'Feed', this.state.sellIconText, 'Chat', 'More']
    for (let a = 0; a < Iconaray.length; a++) {
      tabbarView.push(<View style={{ width: 55, height: 55, alignItems: 'center' }}>
        <Image source={Iconaray[a]} style={{ height: 16, width: 16 }} />
        <Text style={[CommonStyleSheet.staticTextStyle,{marginTop: 3 }]}>{textaray[a]}</Text>
      </View>)
    }
    let prom = AppConstants.appType ? this.state.statusArray[1] : this.state.statusArray[2]
    if (prom) {
      promoViews.push(<View>
        <Image source={placeholder} style={PreviewStyleSheet.promoImageViewStyle} />
      </View>)
    }

    if (this.state.appHomeIconSwitch) {
      if (this.state.appTitleIconImage != null) {
        let fileName = this.state.appTitleIconImage.base64;
        views.push(<Image resizeMode='center' source={{ uri: fileName != null ? this.state.appTitleIconImage.base64 : this.state.appTitleIconImage }} style={{ height: 30, width: 30 }} />)
      }
    } else {
      views.push(<Text style={[PreviewStyleSheet.previewMainTitleStyle]}>{this.state.appTitleHome}</Text>)
    }
    let cate = AppConstants.appType ? this.state.statusArray[0] : this.state.statusArray[1]
    if (cate) {
      categoryViews.push(<Image resizeMode='center' source={categoryValueIcon} style={{ height: 125, width: '100%' }} />)
    }

    listingsItemView.push(<View>
      <View style={PreviewStyleSheet.horizontalCellItemStyle}>
        <Image style={PreviewStyleSheet.selectedImageStyle} source={placeholder} />
        <View style={{ padding: 10 }}>
          <Text style={[CommonStyleSheet.subTitleStyle,{color:colors.AppTitleBlack}]} numberOfLines={1}>{'Product Name'}</Text>
          <View style={{ height: 5 }} />
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 3 }}>
            <View style={{ flexDirection: 'row', alignItems: 'center', width: '50%' }}>
              <Image style={{ height: 16, width: 16, borderRadius: 8 }} source={placeholder} />
              <Text numberOfLines={1} style={[CommonStyleSheet.subTitleStyle,{padding: 5, width: '70%' }]}>{'name'}</Text>
            </View>
            <View>
              <View style={[PreviewStyleSheet.followContainerStyle, { backgroundColor: this.state.selectedPrimaryColor,paddingLeft:5,paddingRight:5 }]}>
                <Text style={[CommonStyleSheet.subTitleStyle,{color: colors.AppWhite }]}>{'$ 550'}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>)
    listingsView.push(<View>
      <View style={[PreviewStyleSheet.accountCellItemStyle, { backgroundColor: this.state.selectedPrimaryColor }]}>
        <Text style={PreviewStyleSheet.accountTitleTxtStyle}>{this.state.latestProductTitle}</Text>
      </View>
      <View style={{ backgroundColor: colors.AppWhite, width: '100%', paddingBottom: 10, }}>
        <View style={{ marginTop: -80, flexDirection: 'row' }}>
          {listingsItemView}
          {listingsItemView}
        </View>
      </View>
    </View>)

    var collectionsView = []

    if (!AppConstants.appType) {
      if (this.state.statusArray[0]) {
        collectionsView.push(<View>
          {this.renderAccountPreview({ name: this.state.storeFollowTitle })}
          {listingsView}
          {this.renderAccountPreview({ name: this.state.nearByTitle })}
        </View>)
      }
    }

    return (<View style={{ width: 360, height: '100%', alignSelf: 'center' }}>
      <View style={{ alignSelf: 'center' }}>
        <Text style={tableStyle.listTitleText}>Preview</Text>
      </View>
      <View style={PreviewStyleSheet.previewFrameStyle}>
        <View style={{ justifyContent: 'space-between', flex: 1 }}>
          <View>
            <View style={{ backgroundColor: this.state.selectedPrimaryColor }}>
              {previewStatusBarView()}
            </View>
            <View style={{ padding: 16, height: 40, width: '100%', backgroundColor: this.state.selectedPrimaryColor, justifyContent: 'center' }}>
              {views}
            </View>
            {categoryViews}
            {promoViews}
            {collectionsView}
          </View>
          <View style={{ flexDirection: 'row', height: 44, width: '100%', borderTopColor: colors.SimonGray, borderTopWidth: 1, paddingTop: 5 }}>
            {tabbarView}
          </View>
        </View>
      </View>
      <View style={{ height: 40 }} />
    </View>
    )
  }
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={SettingStyleSheet.rightViewContainerStyle}>
          <View style={SettingStyleSheet.rightViewSTyle}>
            <ScrollView style={{ paddingRight: 10 }}>
              <Text style={tableStyle.listTitleText}>Default Collection Widgets</Text>
              <Text style={appConfigStyle.subTitleStyle}>
                {AppConstants.plainTextString}
                <HelperVideoView  helperVideoBtnAction={() => this.helperVideoBtnAction()}/>
              </Text>
              <View style={appConfigStyle.subViewStyle}>
                <View style={{ width: '100%' }}>
                  <this.renderAppTitle />
                  <View style={{ marginTop: 20 }}>
                    <Text style={appConfigStyle.titleTxtStyle}>Accounts to follow collection title</Text>
                    <TextInput
                      style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                      placeholder={'Enter accounts to follow collection title'}
                      onChangeText={name => this.setState({ storeFollowTitle: name })}
                      value={this.state.storeFollowTitle}
                    />
                  </View>
                  <View style={{ marginTop: 16 }}>
                    <Text style={appConfigStyle.titleTxtStyle}>Nearby accounts collection title </Text>
                    <TextInput
                      style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                      placeholder={'Enter near by accounts collection title'}
                      onChangeText={name => this.setState({ nearByTitle: name })}
                      value={this.state.nearByTitle}
                    />
                  </View>
                  <View style={{ marginTop: 16 }}>
                    <Text style={appConfigStyle.titleTxtStyle}>Latest listings collection title</Text>
                    <TextInput
                      style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                      placeholder={'Enter latest listings collection title'}
                      onChangeText={name => this.setState({ latestProductTitle: name })}
                      value={this.state.latestProductTitle}
                    />
                  </View>
                  <this.renderSellIconImageView />
                  <View style={{ marginTop: 20 }}>
                    <Text style={appConfigStyle.titleTxtStyle}>Select to enable appearance sections</Text>
                    <this.renderAllSwitches />
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
          <View style={{width:1, backgroundColor:colors.SimonGray}} />
          <View style={{flex: 1, marginTop:-10 }}>
            <ScrollView>
              <this.renderPreviewView />
            </ScrollView>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
    //}
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  infoIcon: {
    width: 15,
    height: 15,
    marginLeft: 5,
    top: 2,
  },
});

const keysArray = [
  'stores_to_follow_collection_title',
  'near_by_stores_collection_title',
  'latest_products_collection_title',
];
const switchTitleStrings = [
  'Show nearby collection',
  'Show category section',
  'Show promo banners',
  'Show invite friends',
  'Show review reminders',
  'Accounts to follow',
];
let keyParameterArray = [
  'app_title_home',
  'app_title_home_icon_enable',
  'app_title_home_icon',
  'sell_icon',
  'sell_icon_text',
  'stores_to_follow_collection_title',
  'near_by_stores_collection_title',
  'latest_products_collection_title',
  'near_by_stores_collection_enabled',
  'home_categories_enabled',
  'home_promo_banners_enabled',
  'invite_friends_collection_enabled',
  'home_review_reminders_enabled',
  'stores_to_follow_collection_enabled',
];
