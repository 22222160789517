import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  TextInput,
  Dimensions,
  Alert,
  Platform,
  Image,
  SafeAreaView,
} from 'react-native';
import edit_Icon from '../../assets/editIcon.png';
import deleteIcon from '../../assets/delete_icon.png';
import grayDeleteIcon from '../../assets/deleteGrayIcon.png';
import disableIcon from '../../assets/disableIcon.png';
import addIcon from '../../assets/add_icon.png';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import PropTypes from 'prop-types';
import EmptyListUI from '../../Component/AppEmptyList';
import { getThumbnailImage } from '../../HelperClasses/SingletonClass';
import dropIcon from '../../assets/dropIcon.png';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { checkUserRoleModule } from '../../HelperClasses/SingletonClass';
import { MODULEKEYS } from '../../Models/Enum';
import AppConstants from '../../Constants/AppConstants';
import {AppToast} from '../../HelperClasses/AppToast';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import viewIcon from '../../assets/viewIcon.png';
import SaveButton from '../../Component/SaveButton';
import SecondryButton from '../../Component/SecondryButton';
import { RestrictedFeatureEnum } from '../../Models/RestrictedFeatureEnum';
import SidePanView from '../../Component/SidePanView';
import ImportCategories from './ImportCategories';
import { ToastContainer } from 'react-toastify';
import AddCategory from './AddCategory';
import Translations from './Translation';
import { TranslateEnum } from '../../Models/TranslateEnum';
import SubscribePlan from '../../Component/SubscribePlan';
import placeholder from '../../assets/pPlaceholder.png';
import BackBtnView from '../../Component/BackBtnView';
import { TypeEnum } from '../../Models/CommonEnum';


const APPURL = require('./../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;;
var pageNo = 1;
let itsMobileView = windowWidth < fixedWidth  ?  true : false

export default class CategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      selectedEntriesCount: 10,
      ListingArray: [],
      dateLoad: false,
      isEdit: false,
      selectedCategoryIndex: 0,
      haveData:false,
      updateUI:false,
      writeP:AppConstants.defaultWrite,
      deleteP:AppConstants.defaultDelete,
      showImportView:false,
      addCategoryBool:false,
      selectedData:'',
      translationBool:false,
      currentType:'',
    }
    this.handleRLDDChange = this.handleRLDDChange.bind(this);
  }
  static propTypes = {
    editBtn: PropTypes.func,
    addSubCatBtn: PropTypes.func,
    type: PropTypes.string,
    viewListingsBtn: PropTypes.func,
    fromRootScreen:PropTypes.bool,
    showBackBtn:PropTypes.bool,
    backBtnAction:PropTypes.func,
  };
  componentDidMount() {
    this.loadListingApi()
    this.checkModule()
    addMixpanelEvent(`${this.props.type} ${MixpanelEvent.viewCategory}`,{'type': MixPannelEnum.listView})
  }
  componentDidUpdate(){
    if(this.state.currentType.length != 0){
      if (this.state.currentType != this.props.type){
        this.setState({dateLoad: false,showImportView:false,translationBool:false,addCategoryBool:false})
        this.loadListingApi()
      }
    }
  }
  checkModule = () => {
    let module = this.props.type == 'Listings' ? MODULEKEYS.LISTIINGSCATEGORY : MODULEKEYS.ACCOUNTSCATEGORY
    checkUserRoleModule(module, res => {
      this.setState({ writeP: res[1],deleteP :res[2]})
    })
  }
  //MARK:-  APIS Method 
  loadListingApi = async () => {
    let path = this.props.type == 'Listings' ? 'listings' : 'accounts'
    this.state.currentType = this.props.type
    const responseJson = await networkService.networkCall(APPURL.URLPaths.categories + path, 'get')
    // console.log('accounts', responseJson);
    this.setState({ListingArray: []});
    if (responseJson['status'] == true) {
      var listD = responseJson['data']['categories'];
      for (let i = 0; i < listD.length; i++) {
        // console.log('listD ==> ',listD[i]);
        this.manageData(listD[i], listD[i]['name']);
        let hierarchyD = listD[i]['sub_category'];
        if (hierarchyD.length != 0) {
          for (let j = 0; j < hierarchyD.length; j++) {
            this.manageData(hierarchyD[j], `${listD[i]['name']}-> ${hierarchyD[j]['name']}`);
            let hierarchy2 = hierarchyD[j]['sub_category'];
            if (hierarchy2.length != 0) {
              for (let k = 0; k < hierarchy2.length; k++) {
                this.manageData(hierarchy2[k], `${hierarchyD[j]['name']}--> ${hierarchy2[k]['name']}`);
                let hierarchy3 = hierarchy2[k]['sub_category'];
                if (hierarchy3.length != 0) {
                  for (let l = 0; l < hierarchy3.length; l++) {
                    this.manageData(hierarchy3[l], `${hierarchy2[k]['name']}---> ${hierarchy3[l]['name']}`);
                  }
                } 
              }
            } 
          }
        } 
      }
      this.state.haveData = this.state.ListingArray.length == 0 ? true : false
    }else {
      this.alertView(responseJson);
    }
    this.setState({dateLoad: true});
  };
  manageData(data, name) {
    var deleteEnable = true;
    if (data['sub_category']) {
      deleteEnable = data['sub_category'].length == 0 ? true : false;
      if (data['sub_category'].length !== 0) {
        deleteEnable = data['parent'] == 0 ? false : true;
      }
    }
    let hierarchyAry = data['hierarchy'];
    var hierarchyName = "";
    var level = 1;
    if (hierarchyAry.length > 0) {
      for (let a = 0; a < hierarchyAry.length; a++) {
        hierarchyName = `${hierarchyName}${hierarchyAry[a]['name']}`
        level = hierarchyAry[a]['level']
        if (a !== hierarchyAry.length - 1) {
          hierarchyName = hierarchyName + ' -> '
        }
      }
    }
    let catData = {
      id: data['id'],
      parent: data['parent'],
      name: data['name'],
      active:data['active'],
      image_path: data['image_path'],
      delete: deleteEnable,
      subCategory: data['name'],
      order_by: data['order_by'],
      slug: data['slug'],
      hierarchyName: hierarchyName,
      level:level,
    };
    this.state.ListingArray.push(catData);
  }
  deleteCategoryApi = async id => {
    this.setState({dateLoad: false});
    const responseJson = await networkService.networkCall(APPURL.URLPaths.addCategories + '/' + id, 'delete')
    this.setState({dateLoad: true});
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true)
      this.loadListingApi()
      this.alertView('delete')
    } else {
      this.mixpanelInfo(false)
      this.alertView(responseJson)
    }
  }
  exportApi = async () => {
    let dict = { 'type': this.props.type == 'Listings' ? 'listings' : 'accounts' }
    const responseJson = await networkService.networkCall(APPURL.URLPaths.categoriesExport, 'post', JSON.stringify(dict))
    if (responseJson['status'] == true) {
      let downloadLink = responseJson['data']['download_url']
      window.open(downloadLink)
    }
  }
  mixpanelInfo(resp){
    let properties = { success:resp }
    addMixpanelEvent(`${this.props.type} ${MixpanelEvent.deleteCategory}`,properties)
  }
  bulkAttributeAPI = async data => {
    var dict = [];
    var i = 1;
    for (let objc of data) {
      var doc = {
        'order_by' : i,
        'id' : objc['id']
      }
      dict.push(doc);
      i++;
    }
    var path = APPURL.URLPaths.addCategories + APPURL.URLPaths.bulk;
    const responseJson = await networkService.networkCall(
      path, 'PATCH', JSON.stringify({categories: dict}));
    // console.log('category', responseJson);
    if (responseJson['status'] == true) {
    } 
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast(title);
    } else {
      Alert.alert(title);
    }
  }
  statusBtnAction = index => {
    if (Platform.OS === 'web') {
      alert(index);
    } else {
      Alert.alert(index);
    }
  };
  editButtonAction = index => {
    let id_Index = this.state.ListingArray.findIndex(x => x.id === index);
    if (this.props.fromRootScreen) {
      this.setState({ addCategoryBool: !this.state.addCategoryBool, isEdit: true, selectedData: this.state.ListingArray[id_Index] });
    } else {
      this.props.editBtn(this.state.ListingArray[id_Index]);
    }
  }
  addCategoryBtnAction = index => {
    let id_Index = this.state.ListingArray.findIndex(x => x.id === index);
    this.props.addSubCatBtn(this.state.ListingArray[id_Index]);
  }
  viewListingsBtnAction = index => {
    let id_Index = this.state.ListingArray.findIndex(x => x.id === index);
    this.props.viewListingsBtn(this.state.ListingArray[id_Index]);
  }
  deleteCategoryAction = index => {
    // let id_Index = this.state.ListingArray.findIndex(x => x.id === index);
    // if (this.state.ListingArray[id_Index]['delete'] == true) {
      // console.log('index', index);
      if (Platform.OS === 'web') {
        const r = window.confirm('Existing listings will not open in website or app, you will see an error.');
        if (r == true) {
          this.deleteCategoryApi(index);
        }
      }
  };
  
  handleRLDDChange(reorderedItems) {
    this.state.ListingArray = reorderedItems;
    this.setState({updateUI: !this.state.updateUI});
    this.bulkAttributeAPI(reorderedItems)
  }
  exportBtnAction(){
    this.exportApi()
  }
  importBtnAction(){
    this.setState({showImportView: !this.state.showImportView})
  }
  checkRestriction() {
    let check = AppConstants.restrictedFeatureArray.includes(RestrictedFeatureEnum.bulkEditListings)
    return check
  }
  addBtnAction() {
      this.setState({ isEdit: false, addCategoryBool: !this.state.addCategoryBool })
  }
  backBtnBtn(){
    this.setState({ addCategoryBool: false })
    this.loadListingApi()
  }
  /*  UI   */
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    if (index == 0 ) {
      return (<View style={styles.tableViewTitleViewStyle}>
          <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
        </View>)
    }else {
      return (<View style={styles.firstTableViewTitleViewStyle}>
          <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
        </View>)
    }
  }
  columnDataView = props => {
    if (this.state.ListingArray.length == 0) {
      if (this.state.haveData) {
        return (<View style={{ height: windowHeight / 2 }}>
          <EmptyListUI showImage={true} />
        </View>)
      } else {
        return <View />
      }
    } else {
      var views = [];
      for (let i = 0; i < this.state.ListingArray.length; i++) {
        let item = this.state.ListingArray[i]
        views.push(
          <FlatList
            data={[
              item['id'],
              item['id'],
              item['image_path'] || '',
              item['order_by'] || '',
              item['parent'] == '0' ? 'Yes' : 'No',
              item['active'] == true ? 'Active' : 'Inactive',
              item['id'],
            ]}
            style={{ backgroundColor: 'white' }}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />,
        );
      }
      return (<View style={{ flexDirection: 'row', flex: 1 }}>
        <View style={{ display: this.state.writeP ? 'flex' : 'none' }}>
          <RLDD
            items={this.state.ListingArray}
            itemRenderer={this.renderDragDropView}
            onChange={this.handleRLDDChange} />
        </View>
        <View style={{ display: !this.state.writeP ? 'flex' : 'none', width: 22 }} />
        <View style={{ flexDirection: 'column' }}>
          {views}
        </View>
      </View>)
    }
  };
  renderDragDropView = (item, index) => {
    return (<View>
      <FlatList
        data={[1]}
        style={{ backgroundColor: 'white' }}
        horizontal={true}
        renderItem={this.renderDropdownCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
      />
    </View>)
  }
  renderDropdownCell = ({ item }) => {
    return (<View style={tableStyle.dragdropViewStyle}>
      <Image style={tableStyle.dragIconStyle} resizeMode="center" source={dropIcon} />
    </View>)
  }

  columnCell = ({item, index}) => {
    let id_Index = this.state.ListingArray.findIndex(x => x.id === item);
    if (index == 5) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={ item == 'Active' ? tableStyle.columnActiveBtnStyle : tableStyle.columnDeActiveBtnStyle}>
            {item}
          </Text>
        </View>
      )
    } else if (index == 4) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{marginLeft: 0}}>
            <Text style={item == 'No' ? tableStyle.columnDeActiveBtnStyle : tableStyle.columnActiveBtnStyle}>
              {item}
            </Text>
          </View>
        </View>
      )
    } else if (index == 2) {
      let fileUrl = getThumbnailImage(item);
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{ marginLeft:0 }}>
            <Image style={tableStyle.itemImageViewStyle}
              source={item.length == 0 ? placeholder : fileUrl}
            />
          </View>
        </View>
      )
    } else if (index == 6) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{ flexDirection: 'row', marginRight: 20 }}>
            {this.renderEditBtn(item)}
            <View style={{ width: 10 }} />
            <this.renderDeleteView item={item} />
            <View style={{ width: 10 }} />
            {this.renderAddCategoryBtn(item)}
            <View style={{ width: 10 }} />
            {this.renderViewListingBtn(item)}
          </View>
        </View>
      )
    } else if (index == 3) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{marginLeft: 0}}>
            <Text style={tableStyle.categoryColumnViewTitleStyle}>{item}</Text>
          </View>
        </View>
      )
    } else if (index == 1) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{marginLeft: 0}}>
            <Text style={tableStyle.categoryColumnViewTitleStyle}>{item}</Text>
          </View>
        </View>
      )
    }  else if (this.state.ListingArray[id_Index]['delete'] == true) {
      return (
        <View style={styles.firstColumnStyle}>
          <View>
            <Text style={tableStyle.categoryColumnViewTitleStyle}>{this.state.ListingArray[id_Index]['subCategory']}</Text>
            <Text style={tableStyle.cateSubTitle}>{this.state.ListingArray[id_Index]['hierarchyName']}</Text>
          </View>
        </View>
      )
    } else {
      return (
        <View style={styles.firstColumnStyle}>
          <Text style={tableStyle.categoryColumnViewTitleStyle}>{this.state.ListingArray[id_Index]['name']}</Text>
        </View>
      )
    }
  }
  renderEditBtn = (item) => {
    return <TouchableOpacity disabled={!this.state.writeP} onPress={() => this.editButtonAction(item)}>
      <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.writeP ? edit_Icon : disableIcon} />
    </TouchableOpacity>
  }
  renderAddCategoryBtn = (item) => {
    return <TouchableOpacity disabled={!this.state.writeP} onPress={() => this.addCategoryBtnAction(item)}>
      <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.writeP ? addIcon : disableIcon} />
    </TouchableOpacity>
  }
  renderViewListingBtn = (item) => {
    return <TouchableOpacity disabled={!this.state.writeP} onPress={() => this.viewListingsBtnAction(item)}>
      <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.writeP ? viewIcon : viewIcon} />
    </TouchableOpacity>
  }

  renderDeleteView = (item) => {
    let id = item['item'];
    let id_Index = this.state.ListingArray.findIndex(x => x.id === id);
    if (this.state.deleteP) {
      if (this.state.ListingArray[id_Index]['delete'] == true) {
        return (
          <TouchableOpacity onPress={() => this.deleteCategoryAction(id)}>
            <Image style={tableStyle.iconStyle} resizeMode="center" source={deleteIcon} />
          </TouchableOpacity>)
      } else {
        return (
          <View onPress={() => this.deleteCategoryAction(id)}>
            <Image style={tableStyle.iconStyle} resizeMode="center" source={grayDeleteIcon} />
          </View>)
      }
    } else {
      return (
        <View onPress={() => this.deleteCategoryAction(id)}>
          <Image style={tableStyle.iconStyle} resizeMode="center" source={disableIcon} />
        </View>)
    }
  }
  renderTranslateBtnView = () => {
    return (<View>
      <SecondryButton
        showBtn={true} btnTitle={'Translate'}
        deActiveBtnAction={() => this.setState({ showUpgrade: true })}
        btnAction={() => this.setState({translationBool:!this.state.translationBool})} />
    </View>)
  }
  renderImportExportBtnView = ({ id }) => {
    return <View>
      <SecondryButton
        showBtn={true}
        btnDeActive={this.checkRestriction()}
        btnTitle={id == 1 ? 'Import' : 'Export'}
        deActiveBtnAction={() => this.setState({ showUpgrade: true })}
        btnAction={() => id == 1 ? this.importBtnAction() : this.exportBtnAction()}
      />
    </View>
  }
  renderHeaderView = () => {
    return (<View style={[tableStyle.headerViewstyle,{display:this.props.fromRootScreen ? 'flex' : 'none'}]}>
      <View style={tableStyle.headerTitleContainerViewStyle}>
        <View style={{ display: this.props.showBackBtn ? 'flex' : 'none', marginRight:16 }}>
          <TouchableOpacity style={[tableStyle.backBtnBackViewStyle,{marginLeft:0}]} onPress={this.props.backBtnAction}>
            <BackBtnView />
          </TouchableOpacity>
        </View>
        <Text style={tableStyle.titleTextStyle}>Categories </Text>
      </View>
        <View style={{ flexDirection: 'row', marginTop: itsMobileView ? 5 : 0 }}>
          <this.renderImportExportBtnView id={1} />
          <this.renderImportExportBtnView id={2} />
          <this.renderTranslateBtnView />
          <SaveButton saveBtn={() => this.addBtnAction()} saveBtnTitle={'Add Category'} />
        </View>
    </View>)
  }
  renderSidePanView = () => {
    if (this.state.showImportView) {
      return <SidePanView
        dissmissView={() => this.setState({ showImportView: !this.state.showImportView })}
        showSidepanView={this.state.showImportView}
        title={'Import Categories'}
        customView={<View>
          <ToastContainer />
          <ImportCategories type={this.props.type} backBtnAction={() => this.backBtnBtn()}/>
        </View>}
      />
    } else { return <View /> }
  }
  renderMainListView = () => {
    return <View style={this.props.fromRootScreen ? tableStyle.containerMainView : tableStyle.listContainerView}>
      <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
        <View>
          <View style={{ height: 40, backgroundColor: colors.AppLightGreen, borderTopStartRadius: 5, borderTopEndRadius: 5, paddingLeft: 10 }}>
            <this.tableViewHeader />
          </View>
          <ScrollView style={{ backgroundColor: 'white' }}>
            <this.columnDataView />
          </ScrollView>
        </View>
      </ScrollView>
    </View>
  }
  renderUpgradePlanView = () => {
    return <TouchableOpacity style={[tableStyle.passwordViewStyle, { display: this.state.showUpgrade ? 'flex' : 'none' }]}
      onPress={() => this.setState({ showUpgrade: false })}>
      <SubscribePlan upgrade={true} />
    </TouchableOpacity>
  }
  render() {
    if (this.state.addCategoryBool) {
      return <View style={{ flexDirection: 'column' }}>
        <ToastContainer />
        <AddCategory
          type={this.props.type}
          isEdit={this.state.isEdit}
          categoryData={this.state.selectedData}
          closeBtnAction={() => this.setState({ addCategoryBool: false })}
          backBtnAction={() => this.backBtnBtn()} />
      </View>
    } else if (this.state.translationBool) {
      return (
        <View style={{ flexDirection: 'column' }}>
          <ToastContainer />
          <Translations
            titleString={this.props.type}
            translationTitle={'Category'}
            translationRef={TranslateEnum.category}
            backBtnAction={() => this.setState({ translationBool: false })} />
        </View>
      )
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <View style={{ zIndex: 101 }}>
            <this.renderSidePanView />
            <this.renderUpgradePlanView />
          </View>
          <View style={{ zIndex: 1 }}>
            <this.renderHeaderView />
            <this.renderMainListView />
            <Appload enable={this.state.dateLoad} />
          </View>
        </SafeAreaView>
      )
    }
    //}
  }
}

let constantWidth = windowWidth < fixedWidth ? windowWidth / 3 : windowWidth / 9.4;
let firstWidth = windowWidth / 3.2
const styles = StyleSheet.create({
  Container: {
    flex:1,
    backgroundColor: colors.AppLightGreen,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: firstWidth,
    marginLeft:25,
    height: 40,
  },
  firstTableViewTitleViewStyle: {
    padding: 0,
    width: constantWidth,
    height: 40,
  },
  columnTitleViewStyle: {
    width: constantWidth,
    borderBottomWidth: 1,
    borderBottomColor: colors.SimonGray,
    padding: 2,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: 12,
  },
  firstColumnStyle: {
    width:  firstWidth,
    borderBottomWidth: 1,
    borderBottomColor: colors.SimonGray,
    padding: 2,
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 0,
  }
});

const tableHeaderString = [
  'Name',
  'Category ID',
  'Image',
  'Order',
  'Parent',
  'Active',
  'Action',
];


