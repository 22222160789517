import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Platform,
  Alert,
  Dimensions,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
  Image,
  FlatList,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import HeaderView from '../HeaderView';
import hideIcon from '../../assets/hideEye.png';
import showIcon from '../../assets/showEye.png';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import { Gradient } from 'react-gradient';
import downArrow_Icon from '../../assets/downArrowIcon.png';
import { capitalizeFirstLetter } from '../../HelperClasses/SingletonClass';
import AppConstants from '../../Constants/AppConstants';


const APPURL = require('../../Constants/URLConstants')
let KeyGroup = 'shipping'
const windowWidth = Dimensions.get('window').width
const windowHeight =  Dimensions.get('window').height
let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth  ?  true : false


export default class StuartOnDemand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      clientID: '',
      clientSecret: '',
      webhookAuthenticationKey: '',
      hideClientID:false,
      hideClientSecret:false,
      hideWebhookAuthenticationKey:false,
      selectedEnvironment:environmentType[0],
    }
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi()
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config + '?key_group=' + KeyGroup, 'get')
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs']
      // console.log('this.state.configs =>', configs);
      if (configs['stuart_environment']){
        let envName = capitalizeFirstLetter(configs['stuart_environment'])
        this.state.selectedEnvironment = envName
      }
      this.setState({
        clientID: configs['stuart_oauth_client_id'] || '',
        clientSecret: configs['stuart_oauth_client_secret'] || '',
        webhookAuthenticationKey:configs['stuart_webhook_key'] || '',
      })
    }
    this.setState({dataLoad: true})
  }

  addOrUpdateAppDetailApi = async filesData => {
    // console.log('filesData =>', filesData);
    var configs = [];
    let valueArray = [
      this.state.selectedEnvironment.toLowerCase(),
      this.state.clientID,
      this.state.clientSecret,
      this.state.webhookAuthenticationKey,
    ]
    // var configs = [];
    for (let i = 0; i < keysArray.length; i++) {
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: false,
        };
        // console.log('fDict ==> ', fDict);
        configs.push(fDict);
      }
    }
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config,  'post',  JSON.stringify({configs: configs}))
    this.setState({dataLoad: true})
    if (responseJson['status'] == true) {
      this.deleteKeysAPI(configs);
    } else {
      this.deleteKeysAPI(configs)
      this.setState({dataLoad: true});
      this.alertView(responseJson);
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    let parms = deleteParms.toString();
    let urlParm = APPURL.URLPaths.config + '?key=' + parms;
    const responseJson = await networkService.networkCall(urlParm, 'delete', '', '');
    this.setState({dataLoad: true});
    this.alertView('Uploaded successfully');
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast()
    } else {
      Alert.alert(title)
    }
  }
  //MARK:-  Buttons Action Method 
  submitBtnAction() {
    this.setState({dataLoad: false})
    this.addOrUpdateAppDetailApi()
  }
  //MARK:-  UIs 
  renderSaveConfigBtn = () => {
    return <View>
      <View style={tableStyle.gradientViewBGStyle}>
        <Gradient
          gradients={colors.GradientColors} // required
          property="background"
          gradientType={'linear'}
          duration={2333000}
          angle="0deg">
          <TouchableOpacity onPress={() => this.submitBtnAction()}>
            <Text style={tableStyle.saveBtnTxtStyle}>{'Save'}</Text>
          </TouchableOpacity>
        </Gradient>
      </View>
    </View>
  }
  customDropDown = id => {
    if (this.state.showDropDown == true) {
      return (
        <View style={appConfigStyle.showDropDownViewStyle}>
          <FlatList
            data={environmentType}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={true}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({ item, index }) => {
    return (
      <TouchableOpacity
        onPress={() => this.setState({selectedEnvironment:item,showDropDown:false})}
        style={{ margin: 5, height: 30, marginBottom: 5 }}>
        <View style={{ width: '100%' }}>
          <Text style={CommonStyleSheet.dropdownCellItemTextStyle}>
            {item}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderValueLabel = id => {
    return (
      <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Text style={this.state.selectedEnvironment == '' ? [appConfigStyle.placeholderTxtStyle, { width: '100%' }] : [appConfigStyle.txtFieldStyleDropDown, { width: '100%' }]}>
          {this.state.selectedEnvironment == '' ? 'Select environment' : this.state.selectedEnvironment}
        </Text>
        <Image
          style={this.state.showDropDown == true ? appConfigStyle.upArraowIconStyle : appConfigStyle.downArraowIconStyle}
          resizeMode="contain"
          source={downArrow_Icon}
        />
      </View>
    );
  };
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        {/* <HeaderView
          title={'Stuart On Demand'}
          backBtn={this.props.backBtnAction}
          saveBtn={() => this.submitBtnAction()}
        /> */}
        <View >
          <ScrollView>
            <View style={commonStyle.sidepanmViewStyle}>
              <View style={{ flex: 1, padding: 10 }}>
                <View style={[commonStyle.configContainerViewStyle, { zIndex: 10010 }]}>
                  <Text style={commonStyle.titleNameStyle}>Shipping methods</Text>
                  <TouchableOpacity
                    style={[commonStyle.dropDownViewStyle, { width: '100%' }]}
                    onPress={() => this.setState({ showDropDown: !this.state.showDropDown })}>
                    <this.renderValueLabel id={0} />
                    <this.customDropDown id={0} />
                  </TouchableOpacity>
                </View>
                <View style={commonStyle.configContainerViewStyle}>
                  <Text style={[commonStyle.titleNameStyle]}>Client id</Text>
                  <View style={[CommonStyleSheet.customTxtFieldViewStyle, { width: '100%', marginTop: 0 }]}>
                    <TextInput
                      style={[CommonStyleSheet.customTxtFieldStyle, { outline: 0 }]}
                      placeholder="Enter client id"
                      placeholderTextColor={colors.placeholderColor}
                      onChangeText={name => this.setState({ clientID: name })}
                      value={this.state.clientID}
                      secureTextEntry={!this.state.hideClientID}
                    />
                    <TouchableOpacity onPress={() => this.setState({ hideClientID: !this.state.hideClientID })}>
                      <Image
                        style={{ width: 15, height: 15 }}
                        resizeMode="contain"
                        source={this.state.hideClientID ? showIcon : hideIcon}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={commonStyle.configContainerViewStyle}>
                  <Text style={commonStyle.titleNameStyle}>Client Secret</Text>
                  <View style={[CommonStyleSheet.customTxtFieldViewStyle, { width: '100%', marginTop: 0 }]}>
                    <TextInput
                      style={[CommonStyleSheet.customTxtFieldStyle, { outline: 0 }]}
                      placeholder="Enter client secret"
                      placeholderTextColor={colors.placeholderColor}
                      onChangeText={name => this.setState({ clientSecret: name })}
                      value={this.state.clientSecret}
                      secureTextEntry={!this.state.hideClientSecret}
                    />
                    <TouchableOpacity onPress={() => this.setState({ hideClientSecret: !this.state.hideClientSecret })}>
                      <Image
                        style={{ width: 15, height: 15 }}
                        resizeMode="contain"
                        source={this.state.hideClientSecret ? showIcon : hideIcon}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={commonStyle.configContainerViewStyle}>
                  <Text style={commonStyle.titleNameStyle}>Webhook Authentication Key</Text>
                  <View style={[CommonStyleSheet.customTxtFieldViewStyle, { width: '100%', marginTop: 0 }]}>
                    <TextInput
                      style={[CommonStyleSheet.customTxtFieldStyle, { outline: 0 }]}
                      placeholder="Enter webhook authentication key"
                      placeholderTextColor={colors.placeholderColor}
                      onChangeText={name => this.setState({ webhookAuthenticationKey: name })}
                      value={this.state.webhookAuthenticationKey}
                      secureTextEntry={!this.state.hideWebhookAuthenticationKey}
                    />
                    <TouchableOpacity onPress={() => this.setState({ hideWebhookAuthenticationKey: !this.state.hideWebhookAuthenticationKey })}>
                      <Image
                        style={{ width: 15, height: 15 }}
                        resizeMode="contain"
                        source={this.state.hideWebhookAuthenticationKey ? showIcon : hideIcon}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={{ alignItems: 'center', marginTop: 20 }}>
                  {this.renderSaveConfigBtn()}
                </View>
              </View>
            </View>
          </ScrollView>
          <Appload enable={this.state.dataLoad} />
        </View>
      </SafeAreaView>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  subViewStyle: {
    flexDirection: 'column',
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    zIndex: 1,
  },
});

let keysArray = [
  'stuart_environment',
  'stuart_oauth_client_id',
  'stuart_oauth_client_secret',
  'stuart_webhook_key',
]
let environmentType = ['Sandbox','Production'] 
