import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import colors from '../HelperClasses/AppColor';
import videoIcon from '../assets/videoIcon.png';
import {ReactComponent as MyLogo} from './../assets/videoSVG.svg';
import TextTitleStyleSheet from '../StyleSheets/TextTitleStyleSheet';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';

export default class HelperVideoView extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  static propTypes = {
    helperVideoBtnAction: PropTypes.func,
  };
  renderHelperVideoBtn = () => {
    return <TouchableOpacity style={styles.containerStyle}
       onPress={() => this.props.helperVideoBtnAction()}>
        <View style={{height:20,width:20 , borderRadius:10, backgroundColor:colors.AppYellow, alignItems:'center'}}>
          <MyLogo style={{height:26,width:26, marginRight:-2,marginTop:-2, marginLeft:-2, marginBottom:-2}} stroke={colors.AppWhite} />
        </View>
        <Text style={[CommonStyleSheet.subTitleStyle,{marginLeft:5}]}>See how it works</Text>
     </TouchableOpacity>
   }
  render() {
    return <View>
      {this.renderHelperVideoBtn()}
    </View>
  }
}

const styles = StyleSheet.create({
  containerStyle: {
    borderRadius: 5,
    marginTop: 4,
    width: 150,
    padding: 5,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.lightYellow,
    flexDirection: 'row',
    borderColor: colors.AppYellow,
    borderWidth:1
  }
})
