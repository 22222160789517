import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  SafeAreaView,
  Image,
  TextInput,
  Dimensions,
  Alert,
  Platform,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import searchIcon from '../assets/search.png';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import {Gradient} from 'react-gradient';
import EmptyListUI from '../Component/AppEmptyList';
import {AppToast} from '../HelperClasses/AppToast';
import {dateConversionFromTimeStamp, getThumbnailImage,checkUserRoleModule} from '../HelperClasses/SingletonClass';
import placeholder from '../assets/pPlaceholder.png';
import disableIcon from '../assets/disableIcon.png';
import appMessage from '../Constants/AppConstantsMessage';
import UserDetail from './UserDetail';
import { MODULEKEYS } from '../Models/Enum';
import AppConstants from '../Constants/AppConstants';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../Models/MixPannelEnum';
import AppConstantsMessage from '../Constants/AppConstantsMessage';
import ToolTipViewBtn from '../Component/ToolTipViewBtn';
import SearchView from '../Component/SearchView';

const APPURL = require('../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;
var pageNo = 1;

let itsMobileView = windowWidth < fixedWidth  ?  true : false

export default class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      selectedStatusType: 'All',
      selectedStatusID: 0,
      usersArray: [],
      updateUI: false,
      dataLoad: false,
      stopPage: false,
      showApproveBool: false,
      viewAccountDetailBool: false,
      selectedID: 0,
      haveData:false,
      searchKey: '',
      typingTimeout: 0,
      showUserDetail: false,
      userID: '',
      userDetailData:{},
      writeP:AppConstants.defaultWrite,
      deleteP:AppConstants.defaultDelete,
    };
  }
  componentDidMount() {
    addMixpanelEvent(MixpanelEvent.viewUser,{'type':MixPannelEnum.listView})
    this.getApi()
    this.checkModule()
    this.mixPannelInfo()
  }
  mixPannelInfo(){
    addMixpanelEvent(MixpanelEvent.viewUser,{'page':pageNo})
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.USERS, res => {
      this.setState({ writeP: res[1],deleteP :res[2]})
    })
  }
  getApi() {
    pageNo = 1;
    this.loadUsersApi();
  }
  //MARK:-  APIS Method 
  loadUsersApi = async () => {
    this.setState({dataLoad: false});
    var path = `?page=${pageNo}&per_page=10`
    if (this.state.searchKey.length != 0){
        path = path + `&search_key=${this.state.searchKey}`
    }
    const responseJson = await networkService.networkCall(APPURL.URLPaths.user + path,'get');
    if (responseJson['status'] == true) {
      var objD = responseJson['data']['users']
      console.log('objD', objD)
      this.setState({usersArray: []});
      if (objD.length != 0) {
        this.setState({stopPage: false});
        for (let i = 0; i < objD.length; i++) {
          this.state.usersArray.push(objD[i]);
        }
      } else {
        pageNo = pageNo - 1;
        this.setState({stopPage: true});
      }
      this.state.haveData = this.state.usersArray.length == 0 ? true : false
    }
    this.setState({dataLoad: true});
  };
  aproveRejectAccountApi = async (id, type) => {
    // console.log('data id =>',id,type);
    this.setState({dataLoad: false});
    let fDict = {
      status: type,
    };
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.accounts + `${id}/moderate`,
      'post',
      JSON.stringify({account: fDict}),
      '',
    );
    // console.log('res po', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.loadUsersApi();
      this.alertView('Success');
    }
  };
  blockUnblockUserApi = async (id, type) => {
    console.log(id, 'id', type);
    this.setState({dataLoad: false});
    let method = type == 1 ? 'POST' : 'DELETE'
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.user + `/${id}/block`,
      method,
      '',
      '',
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.getApi();
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:- Button Actions
  backBtnHandler() {
    this.setState({showUserDetail: false})
  }
  viewAccountBtnAction(id) {
    this.setState({selectedID: id, viewAccountDetailBool: true})
  }
  nextPreviousBtnAction(index) {
    if (index == 1) {
      if (!this.state.stopPage) {
        pageNo = pageNo + 1;
        this.loadUsersApi();
      }
    } else if (index == 2) {
      if (pageNo == 1) {
      } else {
        pageNo = pageNo - 1;
        this.loadUsersApi();
      }
    } else {
    this.setState({showApproveBool : !this.state.showApproveBool});
    }
    this.mixPannelInfo()
    this.setState({updateUI: !this.state.updateUI});
  }
  blockUnblockBtnAction = (id, type) => {
    console.log(id, 'id', type);
    let msg = `Do you want to ${type == 1 ? 'block' : 'unblock'} this user?`;
    if (Platform.OS === 'web') {
      const r = window.confirm(msg);
      if (r == true) {
        this.blockUnblockUserApi(id, type);
      }
    }
  }
  onSearchTextChange = text => {
    this.setState({ searchKey: text })
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      typingTimeout: setTimeout(function () {
        this.getApi()
      }.bind(this), 1000)
    })
  };
  viewUserDetailBtnAction = id => {
    let id_Index = this.state.usersArray.findIndex(x => x.id === id);
    let dict = this.state.usersArray[id_Index];
    this.setState({showUserDetail: true, userID: id,userDetailData:dict});
    // console.log('id=>? ', id);
  }
  onCancelText() {
    if (this.state.searchKey.length != 0) {
      this.state.searchKey = ''
      this.getApi()
    }
    this.setState({showSearchView: true})
  }
  /*  UI   */

  searchViewRender = () => {
    return <View>
      <SearchView
        onCancelText={() => this.onCancelText()}
        onSearchTextChange={text => this.onSearchTextChange(text)}
        searchKey={this.state.searchKey} />
    </View>
  }
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{margin: 0}}
        keyExtractor={(item, index) => index + 98999}
        key={'H'}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={index == 0 ?  styles.firstCellViewStyle : styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
      var views = [];
      for (let i = 0; i < this.state.usersArray.length; i++) {
        // console.log('this.state.usersArray', this.state.usersArray[i]['mobile'] );
        var mobileNo =  'N/A'
        if (this.state.usersArray[i]['mobile'] == {}){
        }
        views.push(
          <FlatList
            data={[
              this.state.usersArray[i]['id'],
              this.state.usersArray[i]['first_name'],
              this.state.usersArray[i]['last_name'],
              this.state.usersArray[i]['email'],
              mobileNo,
              this.state.usersArray[i]['profile_pic'],
              this.state.usersArray[i]['email_verified'],
              this.state.usersArray[i]['blocked'],
              this.state.usersArray[i]['unique_id'],
              dateConversionFromTimeStamp(this.state.usersArray[i]['created_at']),
              dateConversionFromTimeStamp(this.state.usersArray[i]['updated_at']),
            ]}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            style={{borderBottomWidth: 1, borderBottomColor: colors.SimonGray,backgroundColor: 'white'}}
            keyExtractor={(item, index) => index + 9989}
            key={'C'}
          />,
        );
      }
      return views;
  };
  columnCell = ({item, index}) => {
    if (index == 6) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={ item == true ? tableStyle.columnActiveBtnStyle : tableStyle.columnDeActiveBtnStyle }>
            {item ? 'Verified' : 'Non Verified'}
          </Text>
        </View>
      );
    } else if (index == 7) {
      return (
        <View
          style={styles.columnTitleViewStyle}>
          <Text style={ item != true ? tableStyle.columnActiveBtnStyle : tableStyle.columnDeActiveBtnStyle}>
            {item ? 'Yes' : 'No'}
          </Text>
        </View>
      );
    } else if (index == 5) {
      let fileURl =  getThumbnailImage(item);
      return (
        <View style={styles.columnTitleViewStyle}>
          <Image style={tableStyle.itemImageViewStyle} source={item.length == 0 ? placeholder : fileURl} />
        </View>
      );
    }else if (index == 8) {
      let id_Index = this.state.usersArray.findIndex(x => x.unique_id === item);
      let dict = this.state.usersArray[id_Index];
      return  (
        <View style={styles.columnTitleViewStyle}>
          <View style={{flexDirection: 'row'}}>
            <this.renderRejectView id={item} type={dict['blocked'] ? 2 : 1} />
          </View>
      </View>
      );
    } else if (index == 0) {
      return (
        <TouchableOpacity onPress={() => this.viewUserDetailBtnAction(item)} style={styles.firstCellViewStyle} >
          <Text numberOfLines={1} style={tableStyle.mainTitleStyle}>
            {item}
          </Text>
        </TouchableOpacity>
      );
    } 
     else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text numberOfLines={1} style={tableStyle.columnViewTitleStyle}>
            {item}
          </Text>
        </View>
      );
    }
  };
  renderRejectView = (id) => {
    if (this.state.writeP) {
    return (
      <View style={styles.approveRejectViewStyle}>
        <View style={[tableStyle.secondButtonViewStyle,{borderColor:id['type'] == 1 ? colors.AppNewGreen : colors.AppRed}]}>
          <TouchableOpacity style={tableStyle.nextPreviousViewStyle}
            onPress={() => this.blockUnblockBtnAction(id['id'], id['type'])}>
              <Text style={[tableStyle.acceptBtnTextStyle,{color:id['type'] == 1 ? colors.AppNewGreen : colors.AppRed}]}>
              {id['type'] == 1 ? ' Block ' : 'Unblock'}</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
    } else {
      return <View style={styles.columnTitleViewStyle}>
        <Image style={tableStyle.iconStyle} resizeMode="center" source={disableIcon} />
      </View>
    }
  }
  gradientButtons = name => {
    if (this.state.usersArray.length != 0 || pageNo > 1) {
    if (name['id'] == 2 && pageNo == 1) {
      return <View />
    } else if (name['id'] == 1 && this.state.usersArray.length == 0 && pageNo > 1) {
      return <View />
    }
    return (
      <View>
        <View style={tableStyle.grandientBGStyle}>
          <Gradient
            gradients={colors.GradientColors} // required
            property="background"
            gradientType={'linear'}
            duration={2333000}
            style={{height: 30, width: 100, borderRadius: 3, marginRight: 30}}
            angle="0deg">
            <TouchableOpacity
              style={tableStyle.nextPreviousViewStyle}
              onPress={() => this.nextPreviousBtnAction(name['id'])}>
              <Text style={{color: 'white'}}>{name['name']}</Text>
            </TouchableOpacity>
          </Gradient>
        </View>
      </View>
    );
    }else {
      return <View />
    }
  };
  EmptyListRender = () => {
    if (this.state.usersArray.length == 0) {
      if (this.state.haveData) {
        return <EmptyListUI showImage ={true} titleString={appMessage.emptyUserTitleString}  subtitleString={appMessage.usersMsgTextString}/>;
      }else {
        return <View />;
      }
    } else {
      return <View />;
    }

  }
  renderHeaderView = () => {
    return (<View style={tableStyle.headerViewstyle}>
      <View style={tableStyle.headerSubContainer}>
        <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 4,flex:1 }}>
          <Text style={tableStyle.titleTextStyle}>{'Users '}</Text>
          <ToolTipViewBtn title={AppConstantsMessage.usersTitleToopTipMsg} description={AppConstantsMessage.usersSubTitleToolTipMsg} />
        </View>
        <View style={{zIndex: 4,flex:1, alignItems:itsMobileView ? 'flex-start' : 'flex-end', marginTop: itsMobileView ? 10 : 0}} >
          <this.searchViewRender />
        </View>
      </View>
    </View>)
  }
  render() {
    if (this.state.showUserDetail) {
      return (<UserDetail backBtnAction={() => this.setState({showUserDetail: false})} 
        userID={this.state.userID}
        userDetailData={this.state.userDetailData}/>);
    } else {
    return (
      <SafeAreaView style={styles.Container}>
        <this.renderHeaderView />
        <View style={tableStyle.containerMainView}>
          <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
            <View>
            <View style={tableStyle.headerListContainer}>
              <this.tableViewHeader />
            </View>
            <ScrollView style={styles.tableViewHeaderStyle}>
              <this.columnDataView />
            </ScrollView>
            </View>
          </ScrollView>
          <this.EmptyListRender />
          <View style={tableStyle.bottomViewStyle}>
            <this.gradientButtons name={'Next'} id={1} />
            <this.gradientButtons name={'Previous'} id={2} />
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );}
  }
}
const commonWidth = 8.4;

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  headerViewstyle: {
    marginLeft: 10,
    flexDirection: 'row-reverse',
    alignItems: 'center',
    zIndex: 777,
  },
  searchBarViewStyle: {
    borderColor: colors.SimonGray,
    borderRadius: 5,
    borderWidth: 1,
    width: windowWidth < fixedWidth ? '100%' : '40%',
    height: 30,
    alignItems: 'center',
    flexDirection: 'row',
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : windowWidth / commonWidth,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
    paddingLeft: 10,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : windowWidth / commonWidth,
    justifyContent: 'flex-start',
    padding: 2,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  approveRejectViewStyle: {
    justifyContent: 'center',
    padding: 5,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    alignSelf: 'center',
    marginLeft:-8,
  },
  firstCellViewStyle: {
    width: 170,
    // padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    justifyContent: 'flex-start',
  },
});

const tableHeaderString = [
  'User ID',
  'First Name',
  'Last Name',
  'Email',
  'Phone No',
  'Profile',
  'Email Verfied',
  'Blocked',
  'Action',
  'Join Date',
  'Last Login',
];
