import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Platform,
  Text,
  Linking,
  Dimensions,
  TouchableOpacity,
  Image,
  Alert,
  TextInput,
  I18nManager,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import tlogo from '../assets/tradlyicon.png';
import help_Icon from '../assets/helpIcon.png';
import user_Icon from '../assets/userIcon.png';
import menu_Icon from '../assets/menuIcon.png';
import more_Iocn from '../assets/moreIocn.png';
import openLinkIcon from '../assets/openLinkIcon.png'
import logoutIcon from '../assets/logoutIcon.png'
import launchpadIcon from '../assets/launchpadIcon.png'
import billingIcon from '../assets/billingIcon.png'
import roleAccessIcon from '../assets/roleAccessIcon.png'
import inviteTeamIcon from '../assets/inviteTeamIcon.png'
import apiRefIcon from '../assets/apiRefIcon.png'
import communityIcon from '../assets/communityIcon.png'
import documentationIcon from '../assets/documentationIcon.png'

import inviteIcon from '../assets/inviteIcon.png';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import Switch from "react-switch";


import PropTypes from 'prop-types';
import AppConstants from '../Constants/AppConstants';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum, resetMixpanel } from '../Models/MixPannelEnum';
import { getCookiesValue, getValuesFromCookies, saveCookies } from '../HelperClasses/UserPrefrences';
import { LocalStrings } from '../LocalizedStrings/LocalStrings'
import LocalizedStrings from 'react-localization'
import { ProjectTypeModel } from '../Models/ProjectTypeModel';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import TextTitleStyleSheet from '../StyleSheets/TextTitleStyleSheet';
import TableCommonStyleSheet from '../StyleSheets/TableCommonStyleSheet';
import PreviewButton from './PreviewButton';
let strings = new LocalizedStrings(LocalStrings)

const APPConstants = require('../Constants/AppConstants');
const APPURL = require('../Constants/URLConstants')

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

export default class AppNavigationHeader extends Component {
  //Main View defined under this Class
  constructor() {
    super();
    this.state = {
      updateUI: false,
      showLogoutView: false,
      showHelpView: false,
      showInviteView: false,
      openMenu: true,
      envSwitch: false,
      email: '',
      name: '',
      moreOpen: false,
      appLanguage: false,
    };
  }
  static propTypes = {
    menuBtnAction: PropTypes.func,
    logoutBtnAction: PropTypes.func,
    QuickStartBtn: PropTypes.func,
    OpenMenuBar: PropTypes.bool,
    changeEvnBtn: PropTypes.func,
  };

  componentDidMount() {
    this.state.appLanguage = AppConstants.appLanguage == 'ar' ? true : false
    let evn = getCookiesValue(AppConstants.tradlyEVN)
    this.setState({ envSwitch: evn == 'sandbox' ? true : false })
    this.props.changeEvnBtn(this.state.envSwitch);

  }
  inviteMemberApi = async () => {
    var dict = {
      name: this.state.name,
      email: this.state.email,
    };
    const responseJson = await networkService.networkCall(APPURL.URLPaths.inviteMember, 'post', JSON.stringify({ sub_user: dict }));
    console.log('inviteMember responseJson', responseJson)
    if (responseJson['status'] == true) {
      this.setState({ name: '', email: '' });
    } else {
      alert(responseJson);
    }
  }
  mixpanelInfo(value) {
    addMixpanelEvent(MixpanelEvent.headerMenu, { 'Action': value })
  }
  //MARK:- Buttons
  inviteBtnAction() {
    if (this.state.name.length == 0) {
      alert(`Please fill name `);
    } else if (this.state.email.length == 0) {
      alert(`Please fill email `);
    } else {
      this.setState({ showInviteView: false })
      this.inviteMemberApi()
    }
  }
  helpBtnAction = async id => {
    this.setState({ showHelpView: false, showLogoutView: false });
    var pathURL = APPConstants.communityURL;
    if (id != 3) {
      if (id == 4) {
        pathURL = APPConstants.upgardePuffins
        this.mixpanelInfo(MixPannelEnum.upgrade)
      } else if (id == 5) {
        pathURL = APPConstants.apiRefrenceLink
        this.mixpanelInfo(MixPannelEnum.apiReference)
      } else if (id == 6) {
        pathURL = APPConstants.launchpadURL;
      } else if (id == 7) {
        pathURL = APPConstants.roleManagementLink;
      } else {
        this.mixpanelInfo(id == 1 ? MixPannelEnum.documentation : MixPannelEnum.communityURL)
        pathURL = id == 1 ? APPConstants.docsURL : APPConstants.supportURL;
      }
    } else {
      this.mixpanelInfo(MixPannelEnum.community)
    }
    if (Platform.OS == 'web') {
      window.open(pathURL, '_blank');
    } else {
      const supported = await Linking.canOpenURL(pathURL);
      if (supported) {
        await Linking.openURL(pathURL, '_blank');
      } else {
        Alert.alert(`Don't know how to open this URL: ${pathURL}`);
      }
    }
  };
  logoutBtnActions() {
    resetMixpanel()
    this.setState({ showLogoutView: false })
    this.props.logoutBtnAction()
  }
  menuBtnClassAction() {
    this.setState({ openMenu: !this.state.openMenu })
    this.props.menuBtnAction()
  }
  closeMenuBtnAction() {
    this.setState({ openMenu: true })
  }
  environmentBtnAction() {
    let evn = this.state.envSwitch ? 'production' : 'sandbox';
    this.mixpanelInfo(evn)
    saveCookies(AppConstants.tradlyEVN, evn)
    this.props.changeEvnBtn(!this.state.envSwitch);
    this.setState({ envSwitch: !this.state.envSwitch })
  }
  onMouseLeave() {
    this.setState({ showHelpView: false, showLogoutView: false, showInviteView: false })
  }
  languageBtnAction() {
    this.state.appLanguage = !this.state.appLanguage
    // Localized.strings.setLanguage(this.state.appLanguage ? 'ar' : 'en')
    saveCookies('appLanguage', this.state.appLanguage ? 'ar' : 'en')
    this.setState({ updateUI: !this.state.updateUI })
    window.location.reload(false)
  }
  //MARK:-  UIs
  menuButtonRender = () => {
    if (windowWidth < fixedWidth) {
      return (
        <TouchableOpacity onPress={() => this.menuBtnClassAction()}>
          <Image style={styles.menuBtnStyle} source={menu_Icon} resizeMode="contain" />
        </TouchableOpacity>
      );
    } else {
      return <View />;
    }
  };
  logoutView = () => {
    if (this.state.showLogoutView) {
      return (
        <View style={styles.logoutView} onMouseLeave={() => this.onMouseLeave()}>
          {/*  <TouchableOpacity onPress={() => this.helpBtnAction(6)} style={styles.openLinkStyle}>
            <Image source={launchpadIcon} style={styles.linkiconstyle} />
            <Text style={{ margin: 10,width:110 }}>Launchpad</Text>
            <Image source={openLinkIcon} style={styles.openLinkconstyle} />
          </TouchableOpacity> */}
          <TouchableOpacity onPress={() => this.helpBtnAction(7)} style={styles.openLinkStyle}>
            <Image source={roleAccessIcon} style={styles.iconStyle} />
            <Text numberOfLines={1} style={{ margin: 10, width: 130 }}>User Managements</Text>
            <Image source={openLinkIcon} style={styles.openLinkconstyle} />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => this.helpBtnAction(4)} style={styles.openLinkStyle}>
            <Image source={billingIcon} style={styles.iconStyle} />
            <Text style={{ margin: 10, width: 130 }}>Billing</Text>
            <Image source={openLinkIcon} style={styles.openLinkconstyle} />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => this.logoutBtnActions()} style={styles.openLinkStyle}>
            <Image source={logoutIcon} style={styles.iconStyle} />
            <Text style={{ margin: 10 }}>Logout</Text>
          </TouchableOpacity>
          <View>
            <Text style={{ margin: 10, color: colors.Appgray }}>{AppConstants.tenantName}</Text>
            <Text style={{ margin: 10, color: colors.Appgray }}>{AppConstants.userFullName}</Text>
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  }
  helpView = () => {
    if (this.state.showHelpView) {
      return (
        <View style={styles.logoutView} onMouseLeave={() => this.onMouseLeave()}>
          <TouchableOpacity onPress={() => this.helpBtnAction(1)} style={styles.openLinkStyle}>
            <Image source={documentationIcon} style={styles.iconStyle} />
            <Text style={{ margin: 10, width: 100 }}>Documentation</Text>
            <Image source={openLinkIcon} style={styles.openLinkconstyle} />
          </TouchableOpacity>
          {/* <TouchableOpacity onPress={() => this.helpBtnAction(2)}>
            <Text style={{margin: 10}}>Support Portal</Text>
          </TouchableOpacity> */}
          <TouchableOpacity onPress={() => this.helpBtnAction(3)} style={styles.openLinkStyle}>
            <Image source={communityIcon} style={styles.iconStyle} />
            <Text style={{ margin: 10, width: 100 }}>Community</Text>
            <Image source={openLinkIcon} style={styles.openLinkconstyle} />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => this.helpBtnAction(5)} style={styles.openLinkStyle}>
            <Image source={apiRefIcon} style={styles.iconStyle} />
            <Text style={{ margin: 10, width: 100 }}>API Reference</Text>
            <Image source={openLinkIcon} style={styles.openLinkconstyle} />
          </TouchableOpacity>
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderInviteMember = () => {
    if (this.state.showInviteView) {
      return (<View style={{ marginTop: -10, zIndex: 234239 }}>
        <View style={[styles.logoutView, { height: 300, width: windowWidth < fixedWidth ? 300 : 500 }]} >
          <View style={{ justifyContent: 'center', alignItems: 'center', width: '100%', padding: 10 }}>
            <Text style={CommonStyleSheet.inviteMemberTitleStyle}>Invite Team</Text>
            <View style={{height:10}}/>
            <Text style={CommonStyleSheet.inviteMemberSubTitleStyle}>Send invitation links to team members</Text>
            <TextInput
              style={[CommonStyleSheet.emailTextFieldStyle, { outline: 0 }]}
              placeholder="Name"
              placeholderTextColor={colors.placeholderColor}
              value={this.state.name}
              onChangeText={text => this.setState({ name: text })}
            />
            <TextInput
              style={[CommonStyleSheet.emailTextFieldStyle, { outline: 0, marginTop: 40 }]}
              placeholder="Email"
              placeholderTextColor={colors.placeholderColor}
              value={this.state.email}
              onChangeText={text => this.setState({ email: text })}
            />
            <View style={{ marginTop: 40, width: '90%', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
              <TouchableOpacity onPress={() => this.setState({ showInviteView: !this.state.showInviteView })}>
                <Text style={[CommonStyleSheet.commonBtnTxtStyle,{marginTop: 10 }]}>Close</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.inviteBtnStyle} onPress={() => this.inviteBtnAction()}>
                <Text style={CommonStyleSheet.applyNxtBtnStyle}>Invite</Text>
                <Image style={styles.inviteIconStyle} source={inviteIcon} resizeMode="contain" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
      );
    } else {
      return <View />;
    }
  };

  renderEnvironmentButtonView = () => {
    if (!APPConstants.isDevelopment) {
      return (<View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
        <Text style={CommonStyleSheet.testModeTextStyle}>Test Mode</Text>
        <Switch
          onColor={colors.AppNewGreen}
          onChange={() => this.environmentBtnAction()}
          checked={this.state.envSwitch}
          height={22}
          width={50}
        />
      </View>)
    } else {
      return <View />
    }
  }
  rendeMoreView = () => {
    if (windowWidth < fixedWidth) {
      if (this.state.moreOpen) {
        return (<View style={styles.moreViewContainerStyle}>
          {this.renderHeaderRightView()}
          <TouchableOpacity onPress={() => this.setState({ moreOpen: false })}>
            <Text style={[CommonStyleSheet.commonBtnTxtStyle,{marginRight: 10 }]}>Close</Text>
          </TouchableOpacity>
          <View style={{ height: 10 }} />
        </View>)
      } else {
        return (<View style={{ width: '100%', alignItems: 'flex-end', }}>
          <TouchableOpacity onPress={() => this.setState({ moreOpen: true })}>
            <Image style={styles.menuBtnStyle} source={more_Iocn} resizeMode="contain" />
          </TouchableOpacity>
        </View>)
      }
    } else {
      return <View style={{ flex: 1 }}>
        {this.renderHeaderRightView()}
      </View>
    }
  }
  renderLanguageButtonView = () => {
    let lang = this.state.appLanguage ? 'ar' : 'en'
    return (<View style={{
      display: AppConstants.projectType == ProjectTypeModel.delivery ? 'flex' : 'none',
      flexDirection: 'row', alignItems: 'center', marginLeft: 20
    }}>
      <Text style={CommonStyleSheet.testModeTextStyle}>{strings.language}({lang})</Text>
      <Switch
        onColor={colors.AppNewGreen}
        onChange={() => this.languageBtnAction()}
        checked={this.state.appLanguage}
        height={22}
        width={50}
      />
    </View>)
  }
  renderHeaderRightView = () => {
    return (<View style={styles.headerContentView}>
      {/* {this.renderLanguageButtonView()} */}
      <View style={{ width: 10, height: 10 }} />
      <View style={{marginTop:-5}}>
        <PreviewButton id={2}/>
      </View>
      {/* {this.renderEnvironmentButtonView()} */}
      <View style={{ width: 20, height: 10 }} />
      <TouchableOpacity style={styles.upgradeBtnStyle} onPress={() => this.helpBtnAction(4)}>
        <Text style={CommonStyleSheet.themeColorTextStyle}>Upgrade</Text>
      </TouchableOpacity>
      <View style={{ width: 20, height: 10 }} />
      <TouchableOpacity onPress={() => this.setState({ showInviteView: !this.state.showInviteView, showLogoutView: false, showHelpView: false })}>
        <Image resizeMode={'center'} style={{ height: 24, width: 24 }} source={inviteTeamIcon} />
      </TouchableOpacity>
      <this.renderInviteMember />
      <View style={{ width: 15, height: 1 }} />
      <View style={{ zIndex: 13432 }}>
        <TouchableOpacity onPress={() => this.setState({ showHelpView: true, showLogoutView: false })}>
          <Image resizeMode={'center'} style={{ height: 30, width: 30, borderRadius: 15 }} source={help_Icon} />
        </TouchableOpacity>
        <this.helpView />
      </View>
      <View style={{ width: 15, height: 10 }} />
      <View style={{ marginRight: 0, zIndex: 938 }}>
        <TouchableOpacity onPress={() => this.setState({ showLogoutView: !this.state.showLogoutView, showHelpView: false })}>
          <Image resizeMode={'center'} style={{ height: 30, width: 30, borderRadius: 15 }}
            source={AppConstants.userProfilePic.length == 0 ? user_Icon : { uri: AppConstants.userProfilePic }} />
        </TouchableOpacity>
        <this.logoutView />
      </View>
    </View>)
  }
  render() {
    return (
      <View style={{ flex: 1, height: '100%', }}>
        <View style={{ height: !this.state.envSwitch ? 0 : 20 }}>
          <View style={{ height: 1, width: '100%', backgroundColor: colors.orangeColor }} />
          <Text style={CommonStyleSheet.environmentTextStyle}>{!this.state.envSwitch ? '' : '    Sandbox    '}</Text>
        </View>
        <View style={styles.headerView}>
          {/* <View style={styles.headerSubView}>
            <this.menuButtonRender />
            <View style={{flexDirection: 'row', justifyContent: 'center', alignItems:'center'}}>
              <Image resizeMode={'contain'} style={styles.logoStyle} source={tlogo} />
            </View>
          </View> */}
          {this.rendeMoreView()}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  headerView: {
    backgroundColor: colors.AppLightGreen,
    justifyContent: 'space-between',
    flexDirection: 'row',
    height: 70,
    alignItems: 'center',
    flex: 1,
  },
  headerSubView: {
    margin: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerContentView: {
    margin: 10,
    flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
    alignItems: windowWidth < fixedWidth ? 'flex-end' : 'center',
    justifyContent: 'flex-end',
    flex: 1,
    zIndex: 1234,
  },
  logoutView: {
    // width: 80,
    backgroundColor: colors.AppWhite,
    position: 'absolute',
    top: 35,
    right: 10,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    alignItems: 'flex-start',
    borderColor: colors.SimonGray,
    borderWidth: 1,
    shadowColor: '#000000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 10,
    marginBottom: 20,
  },
  logoStyle: {
    width: 50,
    height: 50,
  },
  menuBtnStyle: {
    width: 30,
    height: 30,
    margin: 10,
    marginLeft: 0,
  },
  searchViewStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchBarViewStyle: {
    borderColor: colors.SimonGray,
    borderRadius: 10,
    borderWidth: 1,
    width: '50%',
    height: 35,
    alignItems: 'center',
    flexDirection: 'row',
  },
  environmentView: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 10,
    flex: 1,
  },
  inviteBtnStyle: {
    marginTop: 10,
    backgroundColor: colors.AppNewGreen,
    height: 30,
    width: 70,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  inviteIconStyle: {
    width: 16,
    height: 16,
    marginLeft: 5,
  },
  
  moreViewContainerStyle: {
    marginTop: 10,
    marginRight: 20,
    alignItems: 'flex-end',
    width: '100%',
    borderWidth: 1,
    borderColor: colors.SimonGray,
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 12,
  },
  openLinkStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  linkiconstyle: {
    height: 14,
    width: 14,
    marginLeft: 10,
    marginTop: 2
  },
  openLinkconstyle: {
    height: 14,
    width: 14,
    marginRight: 10,
  },
  iconStyle: {
    height: 18,
    width: 18,
    marginLeft: 10,
    marginTop: 2
  },
  upgradeBtnStyle: {
    backgroundColor: colors.PaidBGColor,
    padding: 6,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.PaidOutlineColor,
    height: 40,
  }
});
