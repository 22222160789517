import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Dimensions,
  Platform,
  Alert,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import ReactFileReader from 'react-file-reader';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import { AppToast } from '../../HelperClasses/AppToast';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import AppConstants from '../../Constants/AppConstants';
import Switch from "react-switch";
import PreviewStyleSheet from '../../StyleSheets/PreviewStyleSheet';
import { previewStatusBarView } from '../../Component/CommonComponents';
import SettingStyleSheet from '../../StyleSheets/SettingStyleSheet';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

const APPURL = require('../../Constants/URLConstants');
const windowheight = Dimensions.get('window').height;
let fixedHeight = 1700;
export default class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      register: '',
      signUpSwitchValue: false,
      dataLoad: false,
      selectedPrimaryColor: '',
      enableMobileValue:false,
      otpNote:'',
    };
  }
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config + '?key_group=general,onboarding', 'get');
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        register: configs['registration_title'] || '',
        otpNote: configs['otp_verification_note'] || '',
        selectedPrimaryColor: configs['app_color_primary'] || '',
        signUpSwitchValue: configs['enable_signup_first'] || false,
        enableMobileValue: configs['enable_mobile_in_email_signup'] || false,
      })
    }
    this.setState({ dataLoad: true });
  };

  addOrUpdateApi = async () => {
    var configs = [];
    let valueArray = [
      this.state.register || '',
      this.state.otpNote || '',
      this.state.signUpSwitchValue,
      this.state.enableMobileValue
    ]
    var configs = [];
    for (let i = 0; i < keyParameterArray.length; i++) {
      if (valueArray[i]) {
        if (valueArray[i].length != 0) {
          let fDict = {
            key_group: i == 1 ? 'onboarding': 'general',
            key: keyParameterArray[i],
            value: valueArray[i],
            secured: false,
          };
          configs.push(fDict);
        }
      }
    }
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config, 'post', JSON.stringify({ configs: configs }));
    if (responseJson['status'] == true) {
      this.deleteKeysAPI(configs);
    } else {
      this.setState({ dataLoad: true });
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    var deleteIndex = [];
    for (let q = 0; q < keyParameterArray.length; q++) {
      deleteParms.push(keyParameterArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = keyParameterArray.indexOf(name);
      // console.log('index', index);
      deleteIndex.push(index);
    }
    for (var i = deleteIndex.length - 1; i >= 0; i--) {
      deleteParms.splice(deleteIndex[i], 1);
    }
    if (deleteParms.length != 0) {
      let parms = deleteParms.toString()
      let urlParm = APPURL.URLPaths.config + '?key=' + parms
      const responseJson = await networkService.networkCall(urlParm, 'delete')
      this.setState({ dataLoad: true });
      this.alertView('Uploaded successfully');
    } else {
      this.setState({ dataLoad: true });
      this.alertView('Uploaded successfully');
    }
  };

  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }

  //MARK:-  Buttons Action Method 
  saveBtnAction() {
    this.setState({ dataLoad: false });
    this.addOrUpdateApi();
  }

  /*  UI   */
  renderSignUpView = () => {
    var feildsAry = this.state.signUpSwitchValue  ? ['First Name', 'Last Name', 'Email', 'Password', 'Confirm Password', 'DOB'] : ['Email', 'Password']
    if (this.state.signUpSwitchValue && this.state.enableMobileValue) {
      feildsAry.push('Mobile No')
    }
    var views = []
    for (let fld of feildsAry) {
      views.push(<View style={PreviewStyleSheet.roundView}>
        <TextInput
          style={PreviewStyleSheet.txtFieldStyle}
          placeholder={fld}
          secureTextEntry={true}
          editable={false}
          placeholderTextColor={'white'}
        />
      </View>)
    }
    views.push(<View style={PreviewStyleSheet.loginBtnStyle} onPress={() => this.sendBtnAction()}>
      <Text style={[PreviewStyleSheet.btnTitleStyle,{color:this.state.selectedPrimaryColor}]}>{this.state.signUpSwitchValue ? 'Create' : 'Login'}</Text>
    </View>)
    return views
  }
  renderPreviewView = () => {
    return (<View style={{ width: 320, height: '100%', alignSelf: 'center' }}>
      <View style={{ alignSelf: 'center' }}>
        <Text style={tableStyle.listTitleText}>Preview</Text>
      </View>
      <View style={[PreviewStyleSheet.previewFrameStyle, { backgroundColor: this.state.selectedPrimaryColor }]}>
        <View>
          {previewStatusBarView()}
          <View style={{ margin: 16, marginTop: 40, alignItems: 'center' }}>
            <Text style={PreviewStyleSheet.previewMainTitleStyle}>{this.state.register} </Text>
          </View>
          <View style={{ marginTop: 30, alignItems: 'center' }}>
            <Text style={[CommonStyleSheet.plainTextStyle,{color:colors.AppWhite}]}>
              {this.state.signUpSwitchValue ? 'Create your account' : 'Login to your account'}
            </Text>
            {this.renderSignUpView()}
          </View>
        </View>
      </View>
      <View style={{ height: 40 }} />
    </View>
    )
  }
  render() {
    return (
      <View style={styles.Container}>
        <View style={SettingStyleSheet.rightViewContainerStyle}>
          <View style={SettingStyleSheet.rightViewSTyle}>
            <Text style={tableStyle.listTitleText}>Registration</Text>
            <Text style={appConfigStyle.subTitleStyle}>
              {AppConstants.plain2TextString}
            </Text>
            <View style={appConfigStyle.subViewStyle}>
              <View style={{ width: '100%' }}>
                <View style={{ marginTop: 16 }}>
                  <Text style={appConfigStyle.titleTxtStyle}> Registration text </Text>
                  <TextInput
                    style={appConfigStyle.txtFieldStyle}
                    value={this.state.register}
                    onChangeText={name => this.setState({ register: name })}
                  />
                </View>
                <View style={{ marginTop: 16 }}>
                  <Text style={appConfigStyle.titleTxtStyle}>OTP Verification note</Text>
                  <TextInput
                    style={appConfigStyle.txtFieldStyle}
                    value={this.state.otpNote}
                    onChangeText={name => this.setState({ otpNote: name })}
                  />
                </View>
                <View style={{ flexDirection: 'row', marginTop: 30 }}>
                  <Switch
                    onColor={colors.AppNewGreen}
                    onChange={() => this.setState({ signUpSwitchValue: !this.state.signUpSwitchValue })}
                    checked={this.state.signUpSwitchValue}
                    height={22}
                    width={50}
                  />
                  <Text style={appConfigStyle.statusTxtStyle}>{'Get sign up first'}</Text>
                </View>
                <View style={{ flexDirection: 'row', marginTop: 30 }}>
                  <Switch
                    onColor={colors.AppNewGreen}
                    onChange={() => this.setState({ enableMobileValue: !this.state.enableMobileValue })}
                    checked={this.state.enableMobileValue}
                    height={22}
                    width={50}
                  />
                  <Text style={appConfigStyle.statusTxtStyle}>{'Mobile number in email signup'}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{width:1, backgroundColor:colors.SimonGray}} />
          <View style={{flex: 1, marginTop:-10 }}>
            <this.renderPreviewView />
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
    //}
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
    height: '100%',
  },
});
let keyParameterArray = [
  'registration_title',
  'otp_verification_note',
  'enable_signup_first',
  'enable_mobile_in_email_signup'
]
