import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function AppToast(type) {
  if (type == 'delete') {
    toast.error('🚀 Deleted', {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: true,
    });
  } else if (type == 'Copied') {
    toast.info('Copied ✅', {
      position: 'bottom-right',
      autoClose: 9000,
      hideProgressBar: true,
    });
  } else if (type !== undefined) {
    toast.info('⚠️ ' + type, {
      position: 'bottom-right',
      autoClose: 9000,
      hideProgressBar: true,
    });
  }  else {
    toast.success('🦄 Success!', {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: true,
    });
  }
}
