import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Platform,
  Alert,
  TouchableOpacity,
  SafeAreaView,
  Dimensions,
  Image,
  Linking,
  ImageBackground,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import commonStyle from '../StyleSheets/CreateTenantCommonStyleSheet';
import previewBG_Icon from '../assets/previewBackgroundIcon.png';
import appStore_Icon from '../assets/appStoreIcon.png';
import playStore_Icon from '../assets/playStoreIcon.png';
import preview_Icon from '../assets/previewImageIcon.png';
import github_Icon from '../assets/githubIcon.png';
import youtubeLogo from '../assets/youtubeLogo.png';
import logoName_Icon from '../assets/tradlylogo.png';
import networkService from '../Network/NetworkManager';
import {Gradient} from 'react-gradient';
import AppConstants from '../Constants/AppConstants';
import apiIcon from '../assets/apiIcon.png';
import RightViews from '../MainRoots/RightView';
import TableCommonStyleSheet from '../StyleSheets/TableCommonStyleSheet';
const APPURL = require('../Constants/URLConstants');
const APPConstants = require('../Constants/AppConstants');
const windowWidth = Dimensions.get('window').width;
const windowheight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;

export default class FinalScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      tenantId: '  ',
    };
  }
  componentDidMount() {
    this.clientTanantsApi();
  }
  //MARK:-  APIs Method 
  clientTanantsApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.clients,
      'get',
      '',
      APPConstants.BearerForTantantsAndUserDetail,
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['tenants'];
      this.setState({tenantId: ` ${configs[0]['id']} ` || '  '});
    }
    this.setState({dataLoad: true});
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  changeNavigation() {
    // this.props.navigation.dispatch(StackActions.replace('SuperAdmin'));
  }
  //MARK:-  Buttons Action Method 
  submitButtonAction() {}
  linkBtnAction = async id => {
    this.setState({showHelpView: false});
    var pathURL =
      id == 1 ? APPConstants.appStoreLink : APPConstants.playStoreLink;
    pathURL = id == 3 ? AppConstants.githubLink : pathURL;
    if (id == 4) {
      pathURL = AppConstants.youtubeLink;
    } else if (id == 5) {
      pathURL = AppConstants.apiDocs;
    }
    if (Platform.OS == 'web') {
      window.open(pathURL, '_blank');
    } else {
      const supported = await Linking.canOpenURL(pathURL);
      if (supported) {
        await Linking.openURL(pathURL, '_blank');
      } else {
        Alert.alert(`Don't know how to open this URL: ${pathURL}`);
      }
    }
  };

  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={styles.Container}>
          <ScrollView style={{height: windowheight - 100}}>
            <View style={{flex: 1, flexDirection: 'row'}}>
              <View style={styles.mainViewStyle}>
                <View style={commonStyle.leftViewStyle}>
                  <View style={styles.contentViewStyle}>
                    <Text style={styles.textTitleStyle}>
                      You can test your{'\n'}production prototype.{' '}
                    </Text>
                    <Text style={styles.textSubTitleStyle}>
                      Download the TradlyPlatform application and enter your app
                      name
                      <Text style={{color: colors.AppNewGreen}}>
                        {` "${this.state.tenantId}" `}
                      </Text>{' '}
                      in the first screen to check the appearance of your app.{' '}
                    </Text>
                    <View style={{height: 30}} />
                    <View style={styles.downloadBtnViewStyle}>
                      <TouchableOpacity onPress={() => this.linkBtnAction(1)}>
                        <Image
                          style={styles.storeIconStyle}
                          resizeMode="contain"
                          source={appStore_Icon}
                        />
                      </TouchableOpacity>
                      <TouchableOpacity onPress={() => this.linkBtnAction(2)}>
                        <Image
                          style={styles.playstoreIconStyle}
                          resizeMode="contain"
                          source={playStore_Icon}
                        />
                      </TouchableOpacity>
                      <TouchableOpacity onPress={() => this.linkBtnAction(3)}>
                        <View style={styles.githubBackViewStyle}>
                          <Image
                            style={styles.githubIconStyle}
                            resizeMode="contain"
                            source={github_Icon}
                          />
                          <Text style={TableCommonStyleSheet.saveBtnTxtStyle}>
                            {' '}
                            Clone Starter Kits
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View style={styles.downloadBtnViewStyle}>
                      <TouchableOpacity onPress={() => this.linkBtnAction(4)}>
                        <View style={styles.githubBackViewStyle}>
                          <Image
                            style={styles.youtubeIconStyle}
                            source={youtubeLogo}
                          />
                          <Text style={TableCommonStyleSheet.saveBtnTxtStyle}>
                            Step by Step video Tutorials
                          </Text>
                        </View>
                      </TouchableOpacity>
                      <View style={{width: 20}} />
                      <TouchableOpacity onPress={() => this.linkBtnAction(5)}>
                        <View style={styles.githubBackViewStyle}>
                          <Image
                            style={styles.githubIconStyle}
                            source={apiIcon}
                          />
                          <Text style={TableCommonStyleSheet.saveBtnTxtStyle}>
                            API Docs
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
                <View style={styles.rightViewStyle}>
                  <ImageBackground
                    style={styles.previewImageStyle}
                    resizeMode="center"
                    imageStyle={{
                      resizeMode: 'cover',
                      alignSelf: 'center',
                      height: 400,
                      width: '100%',
                    }}
                    source={previewBG_Icon}>
                    <Image
                      style={styles.nameLogoStyle}
                      resizeMode="contain"
                      source={logoName_Icon}
                    />
                    <Image
                      style={styles.loginViewStyle}
                      resizeMode="contain"
                      source={preview_Icon}
                    />
                  </ImageBackground>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  height: windowheight,
                  padding: 10,
                  backgroundColor: colors.BackgroundBlueColor,
                }}>
                <RightViews />
              </View>
            </View>
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  contentViewStyle: {
    marginRight: '5%',
    marginLeft: '10%',
    padding: 30,
    justifyContent: 'space-between',
    marginTop: 50,
  },
  rightViewStyle: {
    width: windowWidth < fixedWidth ? '80%' : '50%',
    height: windowWidth < fixedWidth ? 0 : windowheight,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: windowWidth < fixedWidth ? '' : 'center',
  },
  downloadBtnStyle: {
    marginTop: 40,
    backgroundColor: colors.AppNewGreen,
    height: 40,
    width: '100%',
    borderRadius: 5,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  previewImageStyle: {
    width: 300,
    height: windowWidth < fixedWidth ? windowheight / 1.1 : windowheight / 1.8,
    alignItems: 'center',
  },
  nameLogoStyle: {
    width: 100,
    height: 50,
    marginTop: 77,
    marginLeft: 85,
  },
  loginViewStyle: {
    width: '100%',
    height: 95,
    marginLeft: 85,
    marginTop: 28,
  },
  storeIconStyle: {
    width: 140,
    height: 80,
  },
  youtubeIconStyle: {
    width: 40,
    height: 60,
  },
  playstoreIconStyle: {
    width: 180,
    height: 90,
  },
  githubIconStyle: {
    width: 30,
    height: 30,
    borderRadius: 15,
  },
  githubBackViewStyle: {
    height: 46,
    backgroundColor: 'black',
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    borderRadius: 5,
  },
  downloadBtnViewStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent:'space-evenly',
    marginTop: 20,
    height: 70,
  },
  mainViewStyle: {
    flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
    flex: 4,
    backgroundColor: 'white',
  },
});
