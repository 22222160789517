import React, { Component } from 'react';
import {
  Linking
} from 'react-native';
import Moment from 'moment';
import AppConstants from '../Constants/AppConstants';
import { clearCookies, getCookiesValue, getValue } from './UserPrefrences';
import { ProjectTypeModel } from '../Models/ProjectTypeModel';
import slugify from 'slugify';

export function dateConversionFromTimeStamp(bDate) {
  let frmt = 'DD-MM-YYYY HH:mm A';
  var formatted = Moment.unix(bDate).format(frmt)
  return formatted;
}
export function dateConversionFromString(bDate) {
  // const editDate = bDate.slice(0, -1)
  // let frmt = 'DD-MM-YYYY HH:mm';
  // var formatted = Moment(editDate).format(frmt)
  return bDate
}
export function getThumbnailImage(file) {
  let filename = file.split('/').pop()
  let fileURl = file.replace(filename, AppConstants.thumbnailImage + filename)
  return fileURl
}

export function onHandleNumberFieldChange(text) {
  let regexp = /^[0-9\b]+$/
  let value = text;
  if (text == '' || regexp.test(value)) {
    return text
  } else {
    return ''
  }
}
export function onHandleDecimalNumberFieldChange(text) {
  let regexp = /^[0-9.\b]+$/
  let value = text;
  if (text == '' || regexp.test(value)) {
    return text
  } else {
    return ''
  }
}
export function getRandomColor() {
    const red = Math.floor(Math.random() * 256/2);
    const green = Math.floor(Math.random() * 256/2);
    const blue = Math.floor(Math.random() * 256/2);
    let color =  "rgb(" + red + ", " + green + ", " + blue + ", 0.15"  + ")"
    return color
}
export var checkUserRoleModule = function (moduleKey, callbackValue = Array) {
  var moduleArray = []
  getValue('modules', resp => {
    if (resp[0]) {
      moduleArray = resp[0]
      let indx = moduleArray.findIndex(x => x.module == moduleKey)
      if (indx != -1) {
        let ary = [moduleArray[indx][`role_read`], moduleArray[indx][`role_write`], moduleArray[indx][`role_delete`]]
        callbackValue(ary)
      }
    }
  })
}

export function getDateArray() {
  var today = new Date()
  var pweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
  var p15 = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 14)
  var pMonth = new Date(today.getFullYear(),today.getMonth(), today.getDate() - 30)
  var Month6 = new Date(today.getFullYear(), today.getMonth() - 6)
  var pyear = new Date(today.getFullYear(), today.getMonth() - 12)
  let frmt = 'YYYY-MM-DD'
  var todayD = Moment(today).format(frmt)
  var previousWeek = Moment(pweek).format(frmt)
  var days15 = Moment(p15).format(frmt)
  var previousMonth = Moment(pMonth).format(frmt)
  var months6 = Moment(Month6).format(frmt)
  var year1 = Moment(pyear).format(frmt)

  let dateAry = [todayD,previousWeek,days15,previousMonth,months6,year1]
  return dateAry
}

export function  getQueryParam(url){
  var regex = /[?&]([^=#]+)=([^&#]*)/g,
    params = {},
    match;
  while (match = regex.exec(url)) {
    params[match[1]] = match[2];
  }
  if (params['app']){
    let projectP = params['app']
    return projectP
  } else {
    return ProjectTypeModel.marketPlace
  }
}
export function getProductAccess(productAccess,url) {
  AppConstants.productAccessArray = productAccess
  let projectT = getQueryParam(url)
  let checkAccess = productAccess.includes(projectT)
  if (checkAccess) {
    AppConstants.projectType = projectT
  }
  return checkAccess
}
export function getTimeArray() {
  var arr = []
  for (let i = 1; i < 13; i++) {
    for (let j = 0; j < 4; j++) {
      let time = i < 10 ? `0${i}` : i
      arr.push(time + ":" + (j === 0 ? "00" : 15 * j))
    }
  }
  var timeary = []
  for (let a = 0; a < 2; a++) {
    for (let objc of arr) {
      let tm = `${objc} ${a == 0 ? 'am' : 'pm'}`
      timeary.push(tm)
    }
  }
  timeary.sort(function (a, b) {
    return new Date('1970/01/01 ' + a) - new Date('1970/01/01 ' + b)
  })
  return timeary
}
export function getTimeDifferenceArray(startT,Endt,slot) {
  let startTime = Moment(startT, "hh:mm a")
  let endTime = Moment(Endt, "hh:mm a")
  if (endTime.isBefore(startTime)) {
    endTime.add(1, 'day')
  }
  var timeStops = []
  while (startTime <= endTime) {
    let tt = new Moment(startTime).format('hh:mm a')
    startTime.add(slot, 'minutes')
    let tt2 = new Moment(startTime).format('hh:mm a')
    let finalTime = new Moment(startTime)
    if (finalTime <= endTime) {
      let dic = {'start' : tt,'end': tt2}
      timeStops.push(dic)
    }
  }
  return timeStops
}
export function convertTimeinto24Hrs(time) {
  const number = Moment(time, ["hh:mm a"]).format("HH:mm");
  return number
}
export function convertTimeinto12Hrs(time) {
  const number = Moment(time, ["HH:mm"]).format("hh:mm a");
  return number
}
export function changeDateFormat(bDate, format) {
  var ctime = new Date(bDate);
  var formatted = Moment(ctime).format(format);
  return formatted;
}
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function logout(){
  let evn =  AppConstants.isDebug ? "d" : "p"; 
  clearCookies(`ck_${evn}_a`)
  clearCookies(`ck_${evn}_r`)
  if ( AppConstants.isDebug ) {
    Linking.openURL(`${AppConstants.APPROOT}logout`)
  } else {
    Linking.openURL(AppConstants.APPLogout)
  }
}
function getBase64Image(img) {
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);
  var dataURL = canvas.toDataURL("image/png");
  return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}
export function convertFilesToBase64(acceptedFiles,multipleFiles){
  let ef = files
  let files = { base64: [], fileList: ef }
  acceptedFiles.forEach((file) => {
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
    // Do whatever you want with the file contents
      const binaryStr = reader.result
      files.base64.push(reader.result)
      console.log(binaryStr)
      return files
    }
    reader.readAsArrayBuffer(file)
  })

}