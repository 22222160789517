import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
  TextInput,
  Text,
  Dimensions,
  Alert,
  Platform,
  Image,
  SafeAreaView,
} from 'react-native';
import edit_Icon from '../../assets/editIcon.png';
import deleteIcon from '../../assets/delete_icon.png';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import PropTypes from 'prop-types';
import AddVairantTypeValue from './AddVairantTypeValues';
import EmptyListUI from '../../Component/AppEmptyList';
import addIcon from '../../assets/add_icon.png';
import viewIcon from '../../assets/viewIcon.png';
import disableIcon from '../../assets/disableIcon.png';
import { checkUserRoleModule } from '../../HelperClasses/SingletonClass';
import { MODULEKEYS } from '../../Models/Enum';
import AppConstants from '../../Constants/AppConstants';
import {AppToast} from '../../HelperClasses/AppToast';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import AppConstantsMessage from '../../Constants/AppConstantsMessage';
import SaveButton from '../../Component/SaveButton';
import SecondryButton from '../../Component/SecondryButton';
import { ToastContainer } from 'react-toastify';
import { TranslateEnum } from '../../Models/TranslateEnum';
import Translations from './Translation';
import AddVariantType from './AddVariantType';


const APPURL = require('./../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth  ?  true : false

export default class ListingVariantType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      variantTypeArray: [],
      dataLoad: false,
      isEdit: false,
      selectedVariantID: 0,
      addVariantTypeValueBool: false,
      selectedVariantName: '',
      haveData: false,
      selectedVariantData:{},
      writeP:AppConstants.defaultWrite,
      deleteP:AppConstants.defaultDelete,
      translationBool:false,
      addVariantBool:false,
      selectedData:'',
      justShowVariants:false,
    };
  }
  static propTypes = {
    editBtn: PropTypes.func,
    type: PropTypes.string,
    addListingVariantTypeData:PropTypes.any,
  }
  componentDidMount() {
    addMixpanelEvent(MixpanelEvent.viewVariantType,{'type':MixPannelEnum.listView})
    this.loadValueTypesApi()
    this.checkModule()
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.LISTIINGSVARIANTTYPES, res => {
      this.setState({ writeP: res[1],deleteP :res[2]})
    })
  }
  //MARK:-  APIS Method 
  loadValueTypesApi = async () => {
    this.setState({dataLoad: false})
    const responseJson = await networkService.networkCall(APPURL.URLPaths.addVariant,'get')
    this.setState({variantTypeArray: []});
    if (responseJson['status'] == true) {
      var vTypeD = responseJson['data']['variant_types'];
      for (let objc of vTypeD) {
        this.state.variantTypeArray.push(objc);
      }
      this.state.haveData = this.state.variantTypeArray.length == 0 ? true : false
    }
    this.setState({dataLoad: true});
  };
  deleteVariantTypeApi = async id => {
    this.setState({dataLoad: false})
    const responseJson = await networkService.networkCall(APPURL.URLPaths.addVariant + '/' + id, 'delete')
    this.setState({dataLoad: true})
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true)
      this.loadValueTypesApi()
      this.alertView('delete')
    }else {
      this.mixpanelInfo(false)
    }
  } 
  mixpanelInfo(resp){
    let properties = { success:resp }
    addMixpanelEvent(`${MixpanelEvent.deleteVariantType}`,properties)
  }
  alertView(title) {
    AppToast(title)
  }
  
  editButtonAction = index => {
    let id_Index = this.state.variantTypeArray.findIndex(x => x.id === index)
    if (this.props.fromRootScreen) {
      this.setState({ addVariantBool:true, isEdit: true, selectedData: this.state.variantTypeArray[id_Index] });
    }else{
      this.props.editBtn(this.state.variantTypeArray[id_Index])
    }
  }
 
  addVariantTypeValueBtnAction = (id,index) => {
    let id_Index = this.state.variantTypeArray.findIndex(x => x.id === id);
    this.setState({
      selectedVariantName: this.state.variantTypeArray[id_Index]['name'],
      selectedVariantID: this.state.variantTypeArray[id_Index]['id'],
      selectedVariantData: this.state.variantTypeArray[id_Index],
      // addVariantTypeValueBool: !this.state.addVariantTypeValueBool,
    })
   
    let valuesArry = this.state.variantTypeArray[id_Index]['values'] ?? []
    if (valuesArry.length == 0 && index == 2) {
      AppToast(AppConstantsMessage.viewListingVariantTypesMsg)
    } else {
      if (this.props.fromRootScreen) {
        this.setState({
          addVariantTypeValueBool: !this.state.addVariantTypeValueBool,
          justShowVariants:index == 2 ? true : false,
        })
      } else {
        var dic = {
          'selectedVariantName': this.state.variantTypeArray[id_Index]['name'],
          'selectedVariantID': this.state.variantTypeArray[id_Index]['id'],
          'selectedVariantData': this.state.variantTypeArray[id_Index],
          'justShowVariants': index == 2 ? true : false,
        }
        this.props.addListingVariantTypeData(dic)
      }
    }
  }
  deleteVariantAction = index => {
    let msg =  'Do you want to go delete this variant type?';
    if (Platform.OS === 'web') {
      const r = window.confirm(msg);
      if (r == true) {
        this.deleteVariantTypeApi(index);
      }
    }
  };
  backBtnAction() {
    this.loadValueTypesApi();
    this.setState({addVariantTypeValueBool: false,addVariantBool:false,justShowVariants:false})
  }
  addVariantTypeBtnAction() {
    this.setState({addVariantBool: !this.state.addVariantBool});
    this.setState({isEdit: false})
  }
  /*  UI   */
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    if (this.state.variantTypeArray.length == 0) {
      if (this.state.haveData) {
        return <EmptyListUI />;
      }else {
        return <View />;
      }
    } else {
      var views = [];
      for (let i = 0; i < this.state.variantTypeArray.length; i++) {
      
        views.push(
          <FlatList
            data={[
              this.state.variantTypeArray[i]['name'],
              this.state.variantTypeArray[i]['id'],
              this.state.variantTypeArray[i]['active'],
              this.state.variantTypeArray[i]['id'],
              this.state.variantTypeArray[i]['id'],
            ]}
            style={{
              backgroundColor: 'white',
            }}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />,
        );
      }
      return views;
    }
  };
  columnCell = ({item, index}) => {
    if (index == 2) {
      let  val = item ? 'Active' : 'Inactive';
      return (
        <View style={styles.columnTitleViewStyle} >
          <Text style={item == true ? tableStyle.columnActiveBtnStyle : tableStyle.columnDeActiveBtnStyle}>
            {val}
          </Text>
        </View>
      );
    } else if (index == 1) {
      let id_Index = this.state.variantTypeArray.findIndex(x => x.id === item);
      let values = this.state.variantTypeArray[id_Index]['values'];
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={tableStyle.columnViewTitleStyle}>{values.length}</Text>
          </View>
        </View>
      );
    } else if (index == 3) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{flexDirection: 'row'}}>
            {this.renderEditBtn(item)}
            <View style={{width: 10}} />
            {this.renderDeleteBtn(item)}
          </View>
        </View>
      );
    } else if (index == 4) {
      return (<View style={styles.columnTitleViewStyle}>
         <TouchableOpacity disabled={!this.state.writeP} onPress={() => this.addVariantTypeValueBtnAction(item)}>
          <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.writeP ? addIcon : disableIcon} />
        </TouchableOpacity>
        <View style={{width: 10}}/>
        <TouchableOpacity onPress={() => this.addVariantTypeValueBtnAction(item,2)}>
          <Image style={tableStyle.iconStyle}  resizeMode="center" source={viewIcon} />
        </TouchableOpacity>
      </View>)
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
        </View>
      );
    }
  }
  renderEditBtn = (item) => {
    return <TouchableOpacity disabled={!this.state.writeP} onPress={() => this.editButtonAction(item)}>
      <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.writeP ? edit_Icon : disableIcon} />
    </TouchableOpacity>
  }
  renderDeleteBtn = (item) => {
    return <TouchableOpacity disabled={!this.state.deleteP} onPress={() => this.deleteVariantAction(item)}>
      <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.deleteP ? deleteIcon : disableIcon} />
    </TouchableOpacity>
  }
  renderTranslateBtnView = () => {
    return (<View>
      <SecondryButton
        showBtn={true} btnTitle={'Translate'}
        btnAction={() => this.setState({translationBool:!this.state.translationBool})} />
    </View>)
  }
  renderHeaderView = () => {
    return (<View style={[tableStyle.headerViewstyle,{display:this.props.fromRootScreen ? 'flex' : 'none'}]}>
        <View style={[tableStyle.headerTitleContainerViewStyle,{width:'50%'}]}>
          <Text style={[tableStyle.titleTextStyle, { margin: 0 }]}>Variants Types</Text>
        </View>
        <View style={{ flexDirection: 'row', marginTop: itsMobileView ? 5 : 0 }}>
          <this.renderTranslateBtnView />
          <SaveButton  saveBtn={() => this.addVariantTypeBtnAction()} saveBtnTitle={'Add Variants Type'} />
        </View>
    </View>)
  }
  render() {
    if (this.state.addVariantTypeValueBool) {
      return (
        <ScrollView style={{flexDirection: 'column'}}>
          <AddVairantTypeValue
            type={this.props.type}
            isEdit={this.state.isEdit}
            variantName={this.state.selectedVariantName}
            variantID={this.state.selectedVariantID}
            variantTypeData={this.state.selectedVariantData}
            justShowVariants={this.state.justShowVariants}
            backBtnAction={() => this.backBtnAction()}/>
        </ScrollView>
      );
    } else if (this.state.addVariantBool) {
      return (
        <ScrollView style={{ flexDirection: 'column' }}>
          <ToastContainer />
          <AddVariantType
            type={this.props.type}
            isEdit={this.state.isEdit}
            variantTypeData={this.state.selectedData}
            backBtnAction={() => this.backBtnAction() }/>
        </ScrollView>
      );
    } else if (this.state.translationBool) {
      return (
        <View style={{ flexDirection: 'column' }}>
          <ToastContainer />
          <Translations
            titleString={this.props.type}
            translationTitle={'Variants Type'}
            translationRef={TranslateEnum.variantType}
            backBtnAction={() => this.setState({ translationBool: false })} />
        </View>
      )
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <this.renderHeaderView />
          <View style={this.props.fromRootScreen ? tableStyle.containerMainView : tableStyle.listContainerView}>
            <ToastContainer />
            <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
              <View>
                <View style={tableStyle.headerListContainer}>
                  <this.tableViewHeader />
                </View>
                <ScrollView >
                  <this.columnDataView />
                </ScrollView>
              </View>
            </ScrollView>
          </View>
          <Appload enable={this.state.dataLoad} />
        </SafeAreaView>
      );
      //}
    }
}
}
const divideWidth = 5;
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : windowWidth / divideWidth,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : windowWidth / divideWidth,
    borderBottomWidth: 1,
    borderBottomColor: colors.SimonGray,
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
});

const tableHeaderString = [
  'Name',
  'Values',
  'Status',
  'Action',
  'Add Value'
];
