import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Platform,
  Alert,
  ScrollView,
  Dimensions,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import Switch from "react-switch";
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import HelperVideoView from '../../Component/HelperVideoView';
import SaveButton from '../../Component/SaveButton';
import { ToastContainer } from 'react-toastify';

const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth  ?  true : false
const keyGroup = 'listings'

export default class ListingSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      maxQuantity: '',
      maxListingPrice: '',
      pictureCount: '',
      addressLabel: '',
      updateUI: false,
      dataLoad: false,
      statusArray: [false, false, false, false, false, false,false, false, false, false, false, false,false,false,true],
      regexp: /^[0-9\b]+$/,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    fromRootScreen:PropTypes.bool
  };
  componentDidMount() {
    addMixpanelEvent(MixpanelEvent.viewListingsConfigurations)
    this.loadConfigApi()
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config + `?key_group=${keyGroup}`,'get')
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs']
      this.setState({
        addressLabel: configs['listing_address_label'] || '',
        maxQuantity: configs['overridden_max_quantity'] || '',
        maxListingPrice: configs['listing_min_price'] || '',
        pictureCount: configs['listing_pictures_count'] || '',
      });
      this.state.statusArray[0] = configs['auto_approval'] || false;
      this.state.statusArray[1] = configs['hide_offer_percent'] || false;
      this.state.statusArray[2] = configs['show_max_quantity'] || false;
      this.state.statusArray[3] = configs['show_shipping_charges'] || false;
      this.state.statusArray[4] = configs['listing_map_location_selector_enabled'] || false;
      this.state.statusArray[5] = configs['listing_hide_tags'] || false;
      this.state.statusArray[6] = configs['enable_stock'] || false;
      this.state.statusArray[7] = configs['listing_address_enabled'] || false;
      this.state.statusArray[8] = configs['show_stock_out_listings'] || false;
      this.state.statusArray[9] = configs['enable_slug'] || false;
      this.state.statusArray[10] = configs['show_variants'] || false;
      this.state.statusArray[11] = configs['meta_title'] || false;
      this.state.statusArray[12] = configs['meta_description'] || false
      this.state.statusArray[13] = configs['meta_keyword'] || false
      this.state.statusArray[14] = configs['listing_enable_formatted_text_description'] ?? true
      this.state.statusArray[15] = configs['show_description'] ?? true
      this.state.statusArray[16] = configs['listing_qa_enabled'] ?? false
      if (AppConstants.appType) {
        this.state.statusArray.splice(0, 1);
      }
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateListingApi = async () => {
    var configs = [];
    if (this.state.addressLabel.length != 0) {
      let fDict = {
        key_group: 'listings',
        key: 'listing_address_label',
        value: this.state.addressLabel,
        secured: false,
      };
      configs.push(fDict);
    }
    if (this.state.maxListingPrice.length != 0) {
      let fDict = {
        key_group: 'listings',
        key: 'listing_min_price',
        value: Number(this.state.maxListingPrice),
        secured: false,
      };
      configs.push(fDict);
    }
    if (this.state.pictureCount.length != 0) {
      let fDict = {
        key_group: 'listings',
        key: 'listing_pictures_count',
        value: Number(this.state.pictureCount),
        secured: false,
      };
      configs.push(fDict);
    }
    if (this.state.maxQuantity.length != 0) {
      let fDict = {
        key_group: 'listings',
        key: 'overridden_max_quantity',
        value: Number(this.state.maxQuantity),
        secured: false,
      };
      configs.push(fDict);
    }
    var keyAry = [...keyParameterArray];
    if (AppConstants.appType) {
      keyAry.splice(0, 1);
    }
    for (let i = 0; i < keyAry.length; i++) {
      let fDict = {
        key_group: 'listings',
        key: keyAry[i],
        value: this.state.statusArray[i],
        secured: false,
      };
      configs.push(fDict);
    }
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config, 'post',  JSON.stringify({configs: configs}))
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true)
      this.deleteKeysAPI(configs)
    } else {
      this.mixpanelInfo(false)
      this.setState({dataLoad: true})
    }
  }
  mixpanelInfo(resp){
    let properties = { success:resp }
    addMixpanelEvent(MixpanelEvent.listingConfigurationSaved, properties)
  }
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    var keyAry = [...keyParameterArray];
    if (AppConstants.appType) {
      keyAry.splice(0, 1);
    }
    for (let q = 0; q < keyAry.length; q++) {
      deleteParms.push(keyAry[q]);
    }
    deleteParms.push('listing_address_label');
    deleteParms.push('overridden_max_quantity');

    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    let parms = deleteParms.toString();
    let urlParm = APPURL.URLPaths.config + '?key=' + parms;
    const responseJson = await networkService.networkCall(urlParm, 'delete', '', '');
    this.setState({dataLoad: true});
    this.alertView('Uploaded successfully');
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  helperVideoBtnAction(){
    addMixpanelEvent(MixpanelEvent.howItWorks,{'Action' : `${MixPannelEnum.configuration}`})
    window.open(AppConstants.approvalAndRejectionHelperVideoLink, '_blank')
  }
  saveButtonAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateListingApi();
  }
  switchSattusBtnAction(id) {
    this.state.statusArray[id] = !this.state.statusArray[id];
    this.setState({updateUI: !this.state.updateUI});
  }
  onHandleTextChange = (text, id) => {
    let value = text;
    if (text == '' || this.state.regexp.test(value)) {
      if (id == 1) {
        this.setState({maxQuantity: text});
      } else if (id == 3) {
        this.setState({maxListingPrice: text});
      } else {
        this.setState({pictureCount: text});
      }
    }
  }
    //MARK:-  UI 

  renderAllSwitches = ({i}) => {
    var views = [];
    var titleAry = [...switchTitleStrings];
      views.push(
        <View style={{ flexDirection: 'row', marginTop: 20, alignItems: 'center', justifyContent: 'space-between', flex:1 }}>
          <View>
            <Text style={CommonStyleSheet.titleNameStyle}>{titleAry[i]}</Text>
          </View>
          <View style={{width:'70%'}}>
            <Switch
              onColor={colors.AppNewGreen}
              onChange={() => this.switchSattusBtnAction(i)}
              checked={this.state.statusArray[i]}
              height={22}
              width={50}
            />
          </View>
        </View>,);
    return views;
  }
  renderApproveReject = ({i}) => {
    var views = [];
    var titleAry = [...switchTitleStrings];
      views.push(
        <View style={{ flexDirection: 'row', marginTop: 20, alignItems: 'center', justifyContent: 'space-between', flex:1 }}>
          <View>
            <Text style={CommonStyleSheet.titleNameStyle}>{titleAry[i]}</Text>
          </View>
          <View style={{width:'70%',flexDirection:'row', alignItems:'center'}}>
            <Switch
              onColor={colors.AppNewGreen}
              onChange={() => this.switchSattusBtnAction(i)}
              checked={this.state.statusArray[i]}
              height={22}
              width={50}
            />
            <View style={{width:10,height:10}}/>
            <HelperVideoView  helperVideoBtnAction={() => this.helperVideoBtnAction()}/>
          </View>
        </View>,)
    return views;
  }

  //MARK:-  UI 
  renderHeaderView = () => {
    return (<View style={[tableStyle.headerViewstyle, { display: this.props.fromRootScreen ? 'flex' : 'none' }]}>
      <View style={[tableStyle.headerTitleContainerViewStyle,{width:'90%'}]}>
        <Text style={[tableStyle.titleTextStyle, { margin: 0 }]}>Listing Configuration </Text>
      </View>
      <View style={{ flexDirection: 'row', marginTop: itsMobileView ? 5 : 0 }}>
        <SaveButton saveBtn={() => this.saveButtonAction()} saveBtnTitle={'Save'} />
      </View>
    </View>)
  }

  render() {
    return (
      <View style={CommonStyleSheet.Container}>
        <this.renderHeaderView />
        <View style={this.props.fromRootScreen ? tableStyle.containerMainView : tableStyle.listContainerView}>
          <ToastContainer />
          <ScrollView style={{backgroundColor:colors.AppWhite}}>
            <View style={CommonStyleSheet.configurationSubViewStyle}>
              <View style={{ flexDirection: 'column' }}>
                <Text style={[tableStyle.mainTitile, { marginTop: -10 }]}>RULES</Text>
                {/* <View style={{height:1, backgroundColor: colors.SimonGray, marginLeft:20, marginRight: 20}} /> */}
                {/* <View style={{height: 20}} /> */}
                <View style={CommonStyleSheet.subViewStyle}>
                  <Text style={CommonStyleSheet.titleNameStyle}>Maximum quantity per listing
                    <Text style={[CommonStyleSheet.subTitleStyle, { color: colors.Appgray }]}> (Example: 100)</Text>
                  </Text>
                  <TextInput
                    style={[CommonStyleSheet.txtFieldStyle, { outline: 0, width: itsMobileView ? '100%' : '70%' }]}
                    placeholder="Enter maximum quantity per listing"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={text => this.onHandleTextChange(text, 1)}
                    value={this.state.maxQuantity}
                  />
                </View>
                <View style={{ height: 20 }} />
                <View style={CommonStyleSheet.subViewStyle}>
                  <Text style={CommonStyleSheet.titleNameStyle}>Minimum listing price
                    <Text style={[CommonStyleSheet.subTitleStyle, { color: colors.Appgray }]}> (Example: $5)</Text>
                  </Text>
                  <TextInput
                    style={[CommonStyleSheet.txtFieldStyle, { outline: 0, width: itsMobileView ? '100%' : '70%' }]}
                    placeholder="Enter minimum listing price"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={text => this.onHandleTextChange(text, 3)}
                    value={this.state.maxListingPrice}
                  />
                </View>
                <View style={styles.switchContainerStyle}>
                  <this.renderAllSwitches i={2} />
                </View>
                <View style={{ height: 20 }} />
                <View style={CommonStyleSheet.subViewStyle}>
                  <Text style={CommonStyleSheet.titleNameStyle}>Number of uploadable pictures count</Text>
                  <TextInput
                    style={[CommonStyleSheet.txtFieldStyle, { outline: 0, width: itsMobileView ? '100%' : '70%' }]}
                    placeholder="Enter Pictures count"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={text => this.onHandleTextChange(text, 2)}
                    value={this.state.pictureCount}
                  />
                </View>
                <View style={styles.switchContainerStyle}>
                  <View style={{ flexDirection: 'row' }}>
                    <this.renderApproveReject i={0} />
                  </View>
                  <this.renderAllSwitches i={8} />
                </View>
                <View style={{ height: 40 }} />
                <Text style={tableStyle.mainTitile}>FEATURE ACTIVATION</Text>
                {/* <View style={{height:1, backgroundColor: colors.SimonGray, marginLeft:20, marginRight: 20}} /> */}
                <View style={{ paddingLeft: 20, paddingRight: 20, marginTop: -20 }}>
                  <this.renderAllSwitches i={15} />
                  <this.renderAllSwitches i={1} />
                  <this.renderAllSwitches i={3} />
                  <this.renderAllSwitches i={5} />
                  <this.renderAllSwitches i={6} />
                  <this.renderAllSwitches i={7} />
                  <this.renderAllSwitches i={16} />
                  <this.renderAllSwitches i={9} />
                  <this.renderAllSwitches i={10} />
                  <this.renderAllSwitches i={11} />
                  <this.renderAllSwitches i={12} />
                  <this.renderAllSwitches i={14} />
                </View>
                <View style={{ height: 40 }} />
                <Text style={tableStyle.mainTitile}>UI Customisation</Text>
                {/* <View style={{height:1, backgroundColor: colors.SimonGray, marginLeft:20, marginRight: 20}} /> */}
                <View style={{ height: 20 }} />
                <View style={CommonStyleSheet.subViewStyle}>
                  <Text style={CommonStyleSheet.titleNameStyle}>Address label
                    <Text style={CommonStyleSheet.helperTextStyle}> (Example: Landmark)</Text>
                  </Text>
                  <TextInput
                    style={[CommonStyleSheet.txtFieldStyle, { outline: 0, width: itsMobileView ? '100%' : '70%' }]}
                    placeholder="Enter address Label"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={text => this.setState({ addressLabel: text })}
                    value={this.state.addressLabel}
                  />
                </View>
              </View>
              <View style={styles.switchContainerStyle}>
                <this.renderAllSwitches i={4} />
              </View>
            </View>
          </ScrollView>
          <Appload enable={this.state.dataLoad} />
        </View>
      </View>
    )
  }
}
const styles = StyleSheet.create({

  switchContainerStyle:{
    paddingLeft:20, 
    paddingRight: 20
  }
});

const switchTitleStrings = [
  'Auto approve listings?',
  'Offer percent',
  'Maximum quantity restrictions on listing level',
  'Shipping charges',
  'Use map marker instead \nof searching address',
  'Tags',
  'Stocks',
  'Geo address',
  'Show out of stock listings in feed',
  'Slug',
  'Variants',
  'Meta title',
  'Meta description',
  'Meta keyword',
  'Enable Composer',
  'Show Description',
  'Questions & Answers',
];
let keyParameterArray = [
  'auto_approval',
  'hide_offer_percent',
  'show_max_quantity',
  'show_shipping_charges',
  'listing_map_location_selector_enabled',
  'listing_hide_tags',
  'enable_stock',
  'listing_address_enabled',
  'show_stock_out_listings',
  'enable_slug',
  'show_variants',
  'meta_title',
  'meta_description',
  'meta_keyword',
  'listing_enable_formatted_text_description',
  'show_description',
  'listing_qa_enabled',
];

