import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
  Platform,
  Image,
  SafeAreaView,
} from 'react-native';
import edit_Icon from '../../assets/editIcon.png';
import deleteIcon from '../../assets/delete_icon.png';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import PropTypes from 'prop-types';
import EmptyListUI from '../../Component/AppEmptyList';
import disableIcon from '../../assets/disableIcon.png';
import { checkUserRoleModule, getThumbnailImage } from '../../HelperClasses/SingletonClass';
import { MODULEKEYS } from '../../Models/Enum';
import AppConstants from '../../Constants/AppConstants';
import {AppToast} from '../../HelperClasses/AppToast';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import placeholder from '../../assets/pPlaceholder.png';
import {Gradient} from 'react-gradient';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';


const APPURL = require('./../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
let fixedWidth = 700;
var pageNo = 1;

export default class AddonsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addonsArray: [],
      dataLoad: false,
      isEdit: false,
      haveData: false,
      writeP:AppConstants.defaultWrite,
      deleteP:AppConstants.defaultDelete,
    };
  }
  static propTypes = {
    editBtn: PropTypes.func,
    type: PropTypes.string,
  }
  componentDidMount() {
    addMixpanelEvent(MixpanelEvent.viewAddons,{'type':MixPannelEnum.addonsView})
    this.getApi()
    this.checkModule()
  }
  checkModule = () => {
    // checkUserRoleModule(MODULEKEYS.LISTIINGSVARIANTTYPES, res => {
    //   this.setState({ writeP: res[1],deleteP :res[2]})
    // })
  }
  getApi() {
    pageNo = 1
    this.loadAddonsApi()
  }
  //MARK:-  APIS Method 
  loadAddonsApi = async () => {
    this.setState({dataLoad: false})
    const responseJson = await networkService.networkCall(APPURL.URLPaths.addons + `?page=${pageNo}`,'get')
    console.log('loadAddonsApi ',responseJson)
    this.setState({addonsArray: []});
    if (responseJson['status'] == true) {
      var vTypeD = responseJson['data']['addons']
      for (let objc of vTypeD) {
        this.state.addonsArray.push(objc)
      }
      this.state.haveData = this.state.addonsArray.length == 0 ? true : false
    }
    this.setState({dataLoad: true});
  };
  deleteAddonsApi = async id => {
    this.setState({dataLoad: false})
    const responseJson = await networkService.networkCall(APPURL.URLPaths.addons + '/' + id, 'delete')
    this.setState({dataLoad: true})
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true)
      this.loadAddonsApi()
      this.alertView('delete')
    }else {
      this.mixpanelInfo(false)
    }
  } 
  mixpanelInfo(resp){
    let properties = { success:resp }
    addMixpanelEvent(`${MixpanelEvent.deleteAddons}`,properties)
  }
  alertView(title) {
    AppToast(title)
  }
  
  editButtonAction = index => {
    let id_Index = this.state.addonsArray.findIndex(x => x.id === index);
    this.props.editBtn(this.state.addonsArray[id_Index])
  };

  deleteAddonsAction = index => {
    let msg =  'Do you want to go delete this addons type?';
    if (Platform.OS === 'web') {
      const r = window.confirm(msg)
      if (r == true) {
        this.deleteAddonsApi(index)
      }
    }
  }
  nextPreviousBtnAction(index) {
    if (index == 1) {
      if (!this.state.stopPage) {
        pageNo = pageNo + 1;
        // console.log('this.am ==', pageNo);
        this.loadAddonsApi();
      }
    } else if (index == 2) {
      if (pageNo == 1) {
      } else {
        // console.log('comig here');
        pageNo = pageNo - 1
        this.loadAddonsApi()
      }
    } else {
    }
    this.setState({ updateUI: !this.state.updateUI })
  }

  /*  UI   */
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={[styles.tableViewTitleViewStyle,{width:index == 0 ? dynamicWidth : staticWidth}]}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    if (this.state.addonsArray.length == 0) {
      if (this.state.haveData) {
        return <EmptyListUI />
      }else {
        return <View />
      }
    } else {
      var views = [];
      for (let i = 0; i < this.state.addonsArray.length; i++) {
        views.push(
          <FlatList
            data={[
              this.state.addonsArray[i]['title'],
              this.state.addonsArray[i]['images'][0] || '',
              this.state.addonsArray[i]['list_price'],
              this.state.addonsArray[i]['max_quantity'],
              this.state.addonsArray[i]['offer_percent'],
              this.state.addonsArray[i]['stock'],
              this.state.addonsArray[i]['id'],
              this.state.addonsArray[i]['active'],
            ]}
            style={{
              backgroundColor: 'white',
            }}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />,
        )
      }
      return views;
    }
  }
  columnCell = ({item, index}) => {
    if (index == 7) {
      let  val = item ? 'Active' : 'Inactive';
      return (
        <View style={styles.columnTitleViewStyle} >
          <Text style={item == true ? tableStyle.columnActiveBtnStyle : tableStyle.columnDeActiveBtnStyle}>
            {val}
          </Text>
        </View>
      )
    } else if (index == 1) {
      let fileURl =  getThumbnailImage(item)
      return (
        <View style={styles.columnTitleViewStyle}>
          <LazyLoadImage height={50} width={50}  resizeMode="cover"  effect="blur" src={item.length == 0 ? placeholder : fileURl}
            placeholderSrc={placeholder}
          />
        </View>
      );
    }  else if (index == 6) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{flexDirection: 'row'}}>
            {this.renderEditBtn(item)}
            <View style={{width: 10}} />
            {this.renderDeleteBtn(item)}
          </View>
        </View>
      )
    } else {
      return (
        <View style={[styles.columnTitleViewStyle,{width:index == 0 ? dynamicWidth : staticWidth}]}>
          <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
        </View>
      )
    }
  }
  renderEditBtn = (item) => {
    return <TouchableOpacity disabled={!this.state.writeP} onPress={() => this.editButtonAction(item)}>
      <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.writeP ? edit_Icon : disableIcon} />
    </TouchableOpacity>
  }
  renderDeleteBtn = (item) => {
    return <TouchableOpacity disabled={!this.state.deleteP} onPress={() => this.deleteAddonsAction(item)}>
      <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.deleteP ? deleteIcon : disableIcon} />
    </TouchableOpacity>
  }
  gradientButtons = name => {
    if (this.state.addonsArray.length != 0 || pageNo > 1) {
    if (name['id'] == 2 && pageNo == 1) {
      return <View />
    } else if (name['id'] == 1 && this.state.addonsArray.length == 0 && pageNo > 1) {
      return <View />
    }
    return (
      <View>
        <View style={tableStyle.grandientBGStyle}>
          <Gradient
            gradients={colors.GradientColors} // required
            property="background"
            gradientType={'linear'}
            duration={2333000}
            style={{height: 30, width: 100, borderRadius: 3, marginRight: 30}}
            angle="0deg">
            <TouchableOpacity
              style={tableStyle.nextPreviousViewStyle}
              onPress={() => this.nextPreviousBtnAction(name['id'])}>
              <Text style={CommonStyleSheet.NextBtnStyle}>{name['name']}</Text>
            </TouchableOpacity>
          </Gradient>
        </View>
      </View>
    );
    }else {
      return <View /> 
    }
  }
  renderBottomView = () => {
    return (<View style={tableStyle.bottomViewStyle}>
      <this.gradientButtons name={'Next'} id={1} />
      <this.gradientButtons name={'Previous'} id={2} />
    </View>)
  }
  render() {
      return (
        <SafeAreaView style={styles.Container}>
          <View style={tableStyle.listContainerView}>
          <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
              <View>
                <View style={tableStyle.headerListContainer}>
                  <this.tableViewHeader />
                </View>
                <ScrollView >
                  <this.columnDataView />
                </ScrollView>
                {this.renderBottomView()}
              </View>
            </ScrollView>
          </View>
          <Appload enable={this.state.dataLoad} />
        </SafeAreaView>
      )
    }
}
const dynamicWidth = windowWidth < fixedWidth ? 130 : windowWidth / 3.2
const staticWidth = 130

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: 130,
  },
  columnTitleViewStyle: {
    width: 130,
    borderBottomWidth: 1,
    borderBottomColor: colors.SimonGray,
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
});

const tableHeaderString = [
  'Title',
  'Image',
  'Price',
  'Max Quantity',
  'Offer',
  'Stock',
  'Action',
  'Status',
];
