export  var PaymentMethodEnum =  {
  stripe:'stripe',
  payulatam:'payulatam',
  billplz:'billplz',
  opp:'opp',
}


export  var ShipmentMethodEnum =  {
  stuart:'stuart',
  storageHub:'storage_hub',
}

export  var CommissionTypeEnum =  {
  supply:'Supply',
  demand:'Demand',
  cart:'Cart',
}

export  const TypeEnum =  {
  accounts:'Accounts',
  listings:'Listings',
}



