import React, {Component, useCallback} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  Platform,
  SafeAreaView,
  ScrollView,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import ReactFileReader from 'react-file-reader';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import AppConstants from '../../Constants/AppConstants';
import PreviewStyleSheet from '../../StyleSheets/PreviewStyleSheet';
import { previewStatusBarView } from '../../Component/CommonComponents';
import SettingStyleSheet from '../../StyleSheets/SettingStyleSheet';
const APPURL = require('../../Constants/URLConstants');

export default class SplashScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSplashImage: null,
      updateUI: false,
      dataLoad: false,
      selectedPrimaryColor:'',
    };
  }
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config + '?key_group=onboarding', 'get');
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        selectedPrimaryColor: configs['app_color_primary'] || '',
        selectedSplashImage: configs['splash_image'] || null,
      });
    }
    this.setState({dataLoad: true});
  };
  uploadFilesAPI = async () => {
    var imgParm = [];
    var uploadBase64 = [];
    var uploadDataParm = [];
    if (this.state.selectedSplashImage != null) {
      let fileName = this.state.selectedSplashImage.base64;

      if (fileName != null) {
        var splashDict = {
          name: this.state.selectedSplashImage['fileList'][0]['name'],
          type: this.state.selectedSplashImage['fileList'][0]['type'],
        };
        uploadBase64.push({
          file: this.state.selectedSplashImage.base64,
          key: 'splash_image',
        });
        imgParm.push(splashDict);
      } else {
        let fDict = {
          key_group: 'onboarding',
          key: 'splash_image',
          value: this.state.selectedSplashImage,
          secured: false,
        };
        uploadDataParm.push(fDict);
      }
    }
    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL,
        'POST',
        JSON.stringify({files: imgParm}),
        '',
      );
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        var uploadIncrement = 0;
        for (let i = 0; i < imgParm.length; i++) {
          fetch(uploadBase64[i]['file']).then(async res => {
            const file_upload_res = await networkService.uploadFileWithSignedURL(
              result[i]['signedUrl'],
              imgParm[i]['type'],
              await res.blob(),
            );
            uploadIncrement++;
            let fDict = {
              key_group: 'onboarding',
              key: uploadBase64[i]['key'],
              value: result[i]['fileUri'],
              secured: false,
            };
            uploadDataParm.push(fDict);
            if (uploadIncrement === uploadBase64.length) {
              this.addOrUpdateAppDetailApi(uploadDataParm);
            }
          });
        }
      } else {
        this.alertView(responseJson);
      }
    } else {
      var uploadDataParm = [];
      var keyAry = ['splash_image'];
      var valueAry = [this.state.selectedSplashImage || '']

      for (let i = 0; i < keyAry.length; i++) {
        if (valueAry[i] != null && valueAry[i].length != 0) {
          let fDict = {
            key_group: 'onboarding',
            key: keyAry[i],
            value: valueAry[i],
            secured: false,
          };
          uploadDataParm.push(fDict);
        }
      }
      this.addOrUpdateAppDetailApi(uploadDataParm);
    }
  };
  addOrUpdateAppDetailApi = async filesData => {
    var configs = [];
    if (filesData != undefined) {
      configs = filesData;
      const responseJson = await networkService.networkCall(APPURL.URLPaths.config, 'post', JSON.stringify({ configs: configs }), '');
      if (responseJson['status'] == true) {
        this.deleteKeysAPI(configs);
      }
    }
    this.setState({ dataLoad: true });
  }
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < parameterKeys.length; q++) {
      deleteParms.push(parameterKeys[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms;
      const responseJson = await networkService.networkCall(urlParm, 'delete',  '', '' );
      this.alertView('Uploaded successfully');
    } else {
      this.alertView('Uploaded successfully');
    }
    this.setState({dataLoad: true});
  };

  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast();
    }
  }

  //MARK:-  Buttons Action Method 
  saveBtnAction() {
    this.setState({dataLoad: false});
    this.uploadFilesAPI();
  }
  onPickSplash = image => {
    this.setState({selectedSplashImage: image});
  };
  /*  UI   */
  imageViewPicker = id => {
    var msgTxt = 'Choose photo'
    if (Platform.OS === 'web') {
      return (
        <View style={appConfigStyle.dottedViewStyle}>
          <ReactFileReader
            fileTypes={['.png', '.jpg','image/*']}
            base64={true}
            style={appConfigStyle.dottedViewStyle}
            handleFiles={file => this.onPickSplash(file)}>
            <View style={{height:5}} />
            <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
            <View style={{height:5}} />
          </ReactFileReader>
        </View>
      );
    } else {
      return (
        <TouchableOpacity style={appConfigStyle.dottedViewStyle}>
          <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
        </TouchableOpacity>
      );
    }
  };

  viewSelectedImages = id => {
    if (this.state.selectedSplashImage != null && id['id'] == 1) {
      let fileName = this.state.selectedSplashImage.base64;
      return (
        <View style={appConfigStyle.selectImagesViewStyle}>
          <Image source={{uri: fileName != null ? this.state.selectedSplashImage.base64 : this.state.selectedSplashImage}}
            style={appConfigStyle.SelectedImageStyle} />
        </View>
      );
    } else {
      return <View />;
    }
  }
  renderPreviewView = () => {
    var imageViews = []
    if (this.state.selectedSplashImage != null) {
      let fileName = this.state.selectedSplashImage.base64;
        imageViews.push(<Image source={{ uri: fileName != null ? this.state.selectedSplashImage.base64 : this.state.selectedSplashImage }}
          style={{width:160, height:160}} />)
    }
    return (<View style={{ width: 320, height: '100%', alignSelf: 'center'}}>
      <View style={{ alignSelf: 'center' }}>
        <Text style={tableStyle.listTitleText}>Preview</Text>
      </View>
      <View style={[PreviewStyleSheet.previewFrameStyle, { backgroundColor: this.state.selectedPrimaryColor }]}>
        <View>
          {previewStatusBarView()}
        </View>
        <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
          {imageViews}
        </View>
      </View>
      <View style={{height:40}}/>
    </View>
    )
  }
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={SettingStyleSheet.rightViewContainerStyle}>
          <View style={SettingStyleSheet.rightViewSTyle}>
            <ScrollView>
            <Text style={tableStyle.listTitleText}>Splash</Text>
            <Text style={appConfigStyle.subTitleStyle}>
              {AppConstants.plain2TextString}
            </Text>
            <View style={appConfigStyle.subViewStyle}>
              <View style={{ width: '100%' }}>
                <Text style={appConfigStyle.titleTxtStyle}> Splash Screen Image</Text>
                <this.imageViewPicker id={1} />
                <View style={{ marginTop: 16, flexDirection: 'row' }}>
                  <this.viewSelectedImages id={1} />
                </View>
              </View>
            </View>
            </ScrollView>
          </View>
          <View style={{width:1, backgroundColor:colors.SimonGray}} />
          <View style={{flex: 1, marginTop:-10 }}>
            <ScrollView>
              <this.renderPreviewView />
            </ScrollView>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
    //}
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

const parameterKeys = [

  'splash_image',
];
