import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Platform,
  Alert,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import { AppToast } from '../../HelperClasses/AppToast';
import Switch from "react-switch";
import { PaidFeatureTextView } from '../../Component/CommonComponents';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');
export default class ChatSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusValue: false,
      negotiateValue: false,
      dataLoad: false,
      negotiateLabel: '',
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
  };
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=chat', 'get', '', '');
    this.setState({ currencyArray: [] });
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        statusValue: configs['send_product_in_chat'] || false,
        negotiateValue: configs['enable_negotiate'] || false,
        negotiateLabel: configs['negotiation_label'] || '',
      });
    }
    this.setState({ dataLoad: true });
  };
  addOrUpdateChatApi = async () => {
    var configs = [];
    let fDict = {
      key_group: 'chat',
      key: 'send_product_in_chat',
      value: this.state.statusValue,
      secured: false,
    };
    let fDict2 = {
      key_group: 'chat',
      key: 'enable_negotiate',
      value: this.state.negotiateValue,
      secured: false,
    };
    if (this.state.negotiateLabel.length != 0) {
      let fDict3 = {
        key_group: 'chat',
        key: 'negotiation_label',
        value: this.state.negotiateLabel,
        secured: false,
      };
      configs.push(fDict3);
    }
    configs.push(fDict);
    configs.push(fDict2);

    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({ configs: configs }),
      '',
    );
    // console.log('configs', responseJson);
    this.setState({ dataLoad: true });
    if (responseJson['status'] == true) {
      this.alertView('uploaded');
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveBtnAction() {
    this.setState({ dataLoad: false });
    this.addOrUpdateChatApi();
  }
  //MARK:-  UI 
  render() {
    return (
      <View style={{ width: '70%', marginBottom: 130, marginLeft: 10 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={tableStyle.listTitleText}>Chat</Text>
          {PaidFeatureTextView()}
        </View>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plainTextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{ flexDirection: 'column', flex: 1.5 }}>
            <View style={{ flexDirection: 'row', marginTop: -30 }}>
              <Switch
                onColor={colors.AppNewGreen}
                onChange={() => this.setState({ statusValue: !this.state.statusValue })}
                checked={this.state.statusValue}
                height={22}
                width={50}
              />
              <Text style={appConfigStyle.statusTxtStyle}>{'Enable automatic send of listings when chat clicked ( Default shows preview on top)'}</Text>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 20 }}>
              <Switch
                onColor={colors.AppNewGreen}
                onChange={() => this.setState({ negotiateValue: !this.state.negotiateValue })}
                checked={this.state.negotiateValue}
                height={22}
                width={50}
              />
              <Text style={appConfigStyle.statusTxtStyle}>{'Make Offer / Negotiation'}</Text>
            </View>
            <View style={{ marginTop: 30 }}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Negotiation button name:
                <Text style={CommonStyleSheet.helperTextStyle}>
                  (example: Make Offer, Negotiate, Quote)
                </Text>
              </Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle, { outline: 0 }]}
                placeholder="Enter negotiation button name"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.setState({ negotiateLabel: text })}
                value={this.state.negotiateLabel}
              />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});
