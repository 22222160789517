
import React, {Component} from 'react';
import {StyleSheet,Text, View, Animated,
   Dimensions,Image, TouchableOpacity} from 'react-native';
import PropTypes from 'prop-types';
import ReaderView from "react-reader-view";
import colors from '../HelperClasses/AppColor';

import closeIcon from '../assets/closeBlackIcon.png';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let src = "https://tradly.app/docs/listings#approve-listing"

export default class AppReaderView extends Component {
  static propTypes = {
    showDocurl: PropTypes.string,
    showHide:PropTypes.bool
  }
  constructor(props) {
    super(props);
    this.state = {
      leftPosition: new Animated.Value(Dimensions.get('window').width),
      showHide:false,
    }
  }

  renderReaderView = () => {
    return (
      <View>
        <ReaderView url={this.props.showDocurl}  iframeProps={{height: windowHeight-150}} 
          css={`
          body {
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
              Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          }
        `} />
      </View>)
  }
  showHidebtnAction() {
    this.setState({showHide:!this.state.showHide})
  }
  render() {
    return (<View style={[styles.mainStyle, { left: windowWidth / 1.66, display: this.state.showHide ? 'flex' : 'none' }]}>
      <TouchableOpacity style={{alignSelf:'flex-end'}} onPress={() => this.showHidebtnAction()}>
        <Text style={{color:'black', marginTop:-10, marginBottom:10, fontWeight:'600'}}>Close </Text>
      </TouchableOpacity>
      <this.renderReaderView />
    </View>
    )
  }
}
const styles = StyleSheet.create({
  mainStyle: {
    height: windowHeight - 90,
    width: windowWidth/3,
    backgroundColor:colors.AppWhite,
    padding:20,
    zIndex: 89991,
    position:'absolute',
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    borderRadius:10,
    marginTop:10,
  }, 
});