const APPConstants = require('../Constants/AppConstants');

module.exports = {
  URLPaths: {
    BaseURL: APPConstants.BaseURL,
    sandboxBaseURL: 'https://api.sandbox.tradly.app/',
    productionBaseURL: 'https://api.tradly.app/',
    clients: 'skua/tenants/users_tenants', //TODO
    refreshToken: 'v1/users/token/refresh',
    createTenant: 'skua/tenants/', //TODO 
    sampleData: 'v1/tenants/sample_data',
    countryURl: 'v1/countries',
    currencies: 'v1/currencies',
    developerAPIKey: 'skua/tenants/',
    config: 'v1/configs',
    S3signedUploadURL: 'v1/utils/S3signedUploadURL',
    logout: 'v1/users/logout',
    paymentMethod: 'v1/payments/methods',
    tenantPaymentMethod: 'v1/tenants/payment_methods',
    shippingMethod: 'v1/shipping_methods',
    tenantShippingMethod: 'v1/tenants/shipping_methods',
    commissions: 'v1/commissions?type=',
    addCommissions: 'v1/commissions',
    categories: 'v1/categories?parent=0&type=',
    addCategories: 'v1/categories',
    collections: 'v1/collections',
    accountListings: 'v1/accounts?page=',
    listings: 'products/v1/listings?page=',
    listingsModerate: 'products/v1/listings',
    promo: 'v1/promos',
    transactions: 'v1/transactions?page=',
    attributes: 'v1/attributes',
    accounts: 'v1/accounts/',
    ordersList: 'products/v1/orders?page=',
    order: 'products/v1/orders/',
    user: 'v1/users',
//     paymentMethods: 'v1/tenants/payment_methods',
    shippingMethods: 'v1/shipping_methods',
    language: 'v1/languages',
    tanantslanguage: 'v1/tenants/languages',
    appTranslationGroup: 'v1/client_translations/groups',
    appTranslationValue: 'v1/client_translations/values?group=',
    addAppTranslations: 'v1/client_translations/',
    addVariant: 'products/v1/variant_types',
    appCountry:'v1/tenants/countries',
    translations: 'v1/translations',
    addresses:'v1/addresses',
    chart:'products/v1/admin/home',
    reviews:'v1/reviews',
    bulk:'/bulk',
    inviteMember: 'skua/sub_users',
    media:'v1/media',
    configsTranslations: 'v1/configs/translations',
    userRole:'skua/user_roles',
    skuaUser: 'skua/users/detail',
    schedules:'schedules',
    tax: 'v1/taxes',
    coupons: 'v1/coupons',
    subscriptionsPlans: 'skua/subscriptions/plans',
    categoriesExport:'v1/categories/export',
    importListings:'products/v1/listings/import',
    exportListings:'products/v1/listings/export',
    coupons: 'v1/coupons',
    addons:'products/v1/addons',
        /*  Delivery End points */
    deliveryOrder:'delivery/v1/orders/',
    deliveryBranches:'delivery/v1/branches/',
    deliveryFleets:'delivery/v1/fleets/',
    deliveryDriver:'delivery/v1/users/',
    delivertRegisterDriver:'register/driver',
    deliveryCountry:'delivery/v1/tenants/countries/',
    deliveryUnassignBranch:'driver/unassign_branch',
    deliveryUnassignFleet:'driver/unassign_fleet',
    deliveryResetPassword:'delivery/v1/users/password/reset',
    deliveryAuditLog:'delivery/v1/audit_logs',
    deliveryRoster:'delivery/v1/rosters',
    deliveryReport:'delivery/v1/reports',
    deliveryDriverCredit:'delivery/v1/driver_credits',
    deliveryDriverDashboard:'delivery/v1/dashboard/counts',
    deliveryDriverDashboardOrderCount:'delivery/v1/dashboard/order_counts',
    activateTenant: 'skua/tenants/activate_product'

  },
};

