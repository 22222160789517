import React, {Component, useRef} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Dimensions,
  SafeAreaView,
  Image,
  TouchableOpacity,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import commonStyle from '../StyleSheets/CommonStyleSheet';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import {dateConversionFromTimeStamp} from '../HelperClasses/SingletonClass';
import statusModel from '../Models/StatusModel';
import ListingDetails from '../MainListing/ListingDetail';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import {addMixpanelEvent, MixpanelEvent} from '../Models/MixPannelEnum';
import {Gradient} from 'react-gradient';
import ReactToPrint, {PrintContextConsumer} from 'react-to-print';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import UserDetail from '../Users/UserDetail';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Moment from 'moment';
import AccountDetail from '../Accounts/AccountDetail';
import closeIcon from '../assets/closeBlackIcon.png';
import radioCheckIcon from '../assets/radioCheck.png';
import radioUncheckIcon from '../assets/radioUncheck.png';
import DeliveryStatusEnum from '../Models/DeliveryStatusEnum';
import StatusModel from '../Models/StatusModel';
import {AppToast} from '../HelperClasses/AppToast';
import {PaymentMethodEnum} from '../Models/CommonEnum';
import BackBtnView from '../Component/BackBtnView';
import styles from '../StyleSheets/NewStyleSheet';
import AppConstants from '../Constants/AppConstants';

var ref = React.createRef(null);

var converter = require('number-to-words');

const APPURL = require('../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height - 120;

let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth ? true : false;
let dateFormat = 'yyyy-MM-DD hh:mm a';
let UCTFormat = 'YYYY-MM-DD HH:MM:SS';
export default class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      orderDetailData: {},
      orderDetailArray: [],
      createdDate: '',
      buyerData: {},
      buyerName: '',
      buyerEmail: '',
      buyerMobile: '',
      deliveryAddress: '',
      deliveryName: '',
      deliveryPhone: '',
      totalAmount: '',
      wordsAmount: '',
      sellerName: '',
      sellerEmail: '',
      sellerMobile: '',
      sellerData: '',
      statusArray: [],
      viewListingDetailBool: false,
      selectedID: '',
      showUserDetail: false,
      selectedUserData: {},
      userDetailData: {},
      enabledFeedback: false,
      selectedDate: '',
      changePayoutBool: false,
      showAccountDetail: false,
      accountID: '',
      updateStatusID: 0,
      showUpdateStatusViewBool: false,
      pickupAddress: '',
      pickupName: '',
      pickupPhone: '',
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    orderID: PropTypes.string,
  };

  componentDidMount() {
    addMixpanelEvent(MixpanelEvent.viewOrderDetail);
    this.loadOrderDetailApi();
    this.loadConfigApi();
  }
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=general',
      'get',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      let emailsString = configs['enable_feedback'] || false;
      this.setState({enabledFeedback: emailsString});
    }
    this.setState({dataLoad: true});
  };
  loadOrderDetailApi = async () => {
    ref = React.createRef(null);
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.order + `${this.props.orderID}`,
      'get',
    );
    console.log('orders order Detail', responseJson);
    if (responseJson['status'] == true) {
      this.setState({orderDetailData: responseJson['data']['order']});
      let dt = this.state.orderDetailData['created_at'];
      var formatted = dateConversionFromTimeStamp(dt);
      if (this.state.orderDetailData['order_details']) {
        for (let objc of this.state.orderDetailData['order_details']) {
          this.state.orderDetailArray.push(objc);
        }
      }

      this.state.statusArray = this.state.orderDetailData['status_history'];
      if (this.state.orderDetailData['user']) {
        this.state.buyerData = this.state.orderDetailData['user'];
        let mobile = `${
          this.state.orderDetailData['user']['dial_code'] ?? ''
        } ${this.state.orderDetailData['user']['mobile'] ?? ''}`;
        this.setState({
          createdDate: formatted,
          buyerEmail: this.state.orderDetailData['user']['email'],
          buyerMobile: mobile,
          buyerName:
            this.state.orderDetailData['user']['first_name'] +
            ` ${this.state.orderDetailData['user']['last_name']}`,
          totalAmount: this.state.orderDetailData['grand_total']['formatted'],
          wordsAmount: converter.toWords(
            this.state.orderDetailData['grand_total']['amount'],
          ),
        });
      }
      if (this.state.orderDetailData['account']) {
        let accountData = this.state.orderDetailData['account'];
        this.state.accountID = accountData['id'];
        if (accountData['user']) {
          this.state.sellerData = accountData['user'];
          let mobile = `${accountData['user']['dial_code'] ?? ''} ${
            accountData['user']['mobile'] ?? ''
          }`;
          this.setState({
            sellerEmail: accountData['user']['email'],
            sellerMobile: mobile,
            sellerName:
              accountData['user']['first_name'] +
              ` ${accountData['user']['last_name']}`,
          });
        }
      }
      let cpWrd = this.state.wordsAmount.replace(/\b\w/g, c => c.toUpperCase());
      this.state.wordsAmount = cpWrd;
      // if (this.state.orderDetailData['pickup_address'] != 0) {
      if (this.state.orderDetailData['pickup_address']) {
        this.setState({
          pickupAddress:
            this.state.orderDetailData['pickup_address']['formatted_address'] ||
            'N/A',
          pickupName:
            this.state.orderDetailData['pickup_address']['name'] || '',
          pickupPhone:
            this.state.orderDetailData['pickup_address']['phone_number'] ||
            'N/A',
        });
      }
      // } else {
      if (this.state.orderDetailData['shipping_address']) {
        this.setState({
          deliveryAddress:
            this.state.orderDetailData['shipping_address'][
              'formatted_address'
            ] || 'N/A',
          deliveryName:
            this.state.orderDetailData['shipping_address']['name'] || '',
          deliveryPhone:
            this.state.orderDetailData['shipping_address']['phone_number'] ||
            'N/A',
        });
      }
      // }
      this.setState({updateUI: !this.state.updateUI});
    }
    this.setState({dataLoad: true});
  };
  updateStatusApi = async statusValue => {
    this.setState({dataLoad: false});
    let path = `${APPURL.URLPaths.order}${this.props.orderID}/status`;
    var dict = {status: statusValue};
    let statusDic = JSON.stringify({order: dict});
    const responseJson = await networkService.networkCall(
      path,
      'PATCH',
      statusDic,
    );
    // console.log('responseJson', responseJson)
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.loadOrderDetailApi();
    } else {
      AppToast(responseJson);
    }
  };
  updatePayoutApi = async () => {
    this.setState({dataLoad: false});
    let path = `${APPURL.URLPaths.order}${this.props.orderID}`;
    let utcDate = Moment(this.state.selectedDate).utc(true);
    var dict = {operation: 'update_payout_at', order: {payout_at: utcDate}};
    const responseJson = await networkService.networkCall(
      path,
      'PATCH',
      JSON.stringify(dict),
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.loadOrderDetailApi();
    } else {
      AppToast(responseJson);
    }
  };

  //     Action Buttons    
  updatePayoutBtnAction() {
    this.updatePayoutApi();
    this.setState({changePayoutBool: false});
  }
  backBtnAction() {
    this.setState({viewListingDetailBool: false});
  }
  viewListingBtnAction = id => {
    this.setState({viewListingDetailBool: true, selectedID: id});
  };
  viewUserDetailBtn(id) {
    if (id == 1) {
      this.state.userDetailData = this.state.buyerData;
      this.setState({showUserDetail: true});
    } else if (id == 2) {
      this.state.userDetailData = this.state.sellerData;
      this.setState({showUserDetail: true});
    }
  }

  closeOrderStatusViewBtnAction() {
    this.state.showUpdateStatusViewBool = false;
    this.setState({updateUI: !this.state.updateUI});
  }
  didSelectOrderStatus(id) {
    if (this.state.updateStatusID == id) {
      this.setState({updateStatusID: 0});
    } else {
      this.setState({updateStatusID: id});
    }
  }
  updateBtnAction() {
    this.closeOrderStatusViewBtnAction();
    if (this.state.updateStatusID != 0) {
      this.updateStatusApi(this.state.updateStatusID);
    }
  }

  // UI Renders
  tableViewHeader = props => {
    var views = [];
    for (let obj of tableHeaderString) {
      views.push(this.tableHeaderCell(obj));
    }
    return <View style={{flexDirection: 'row'}}>{views}</View>;
  };
  tableHeaderCell = item => {
    return (
      <View style={styles.order_details_tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    var views = [];
    for (let i = 0; i < this.state.orderDetailArray.length; i++) {
      let tAmount = this.state.orderDetailArray[i]['list_price']['amount'];
      let toffer = this.state.orderDetailArray[i]['offer_price']['amount'];
      let discount = toffer - tAmount;
      let data = [
        this.state.orderDetailArray[i]['listing']['id'],
        ` ${this.state.orderDetailArray[i]['list_price']['formatted']}`,
        ` ${discount.toFixed(2)}`,
        ` ${this.state.orderDetailArray[i]['quantity']}`,
        ` ${this.state.orderDetailArray[i]['tax_charges']['formatted']}`,
        ` ${this.state.orderDetailArray[i]['offer_price']['formatted']}`,
      ];
      var subViews = [];
      for (let a = 0; a < data.length; a++) {
        subViews.push(this.columnCell(data[a], a));
      }
      views.push(<View style={{flexDirection: 'row'}}>{subViews}</View>);
    }
    return views;
  };
  columnCell = (item, index) => {
    if (index == 0) {
      let id_Index = this.state.orderDetailArray.findIndex(
        x => x['listing']['id'] === item,
      );
      let name = this.state.orderDetailArray[id_Index]['listing']['title'];
      return (
        <TouchableOpacity
          style={styles.order_details_columnTitleViewStyle}
          onPress={() => this.viewListingBtnAction(item)}>
          <View style={{height: 10}} />
          <Text style={tableStyle.mainTitleStyle}>{name}</Text>
          <View style={{height: 10}} />
        </TouchableOpacity>
      );
    } else {
      return (
        <View style={styles.order_details_columnTitleViewStyle}>
          <View style={{height: 10}} />
          <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
          <View style={{height: 10}} />
        </View>
      );
    }
  };
  titleLblRender = ({first, second}) => {
    return (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text style={appConfigStyle.titleTxtStyle}>{`${first} `}</Text>
        <Text style={{marginTop: 2}}>{second}</Text>
      </View>
    );
  };
  renderDetails = ({title, name, email, mobileNo, id}) => {
    return (
      <View>
        <Text style={appConfigStyle.titleTxtStyle}>{title}</Text>
        <View style={{height: 10}} />
        <TouchableOpacity onPress={() => this.viewUserDetailBtn(id)}>
          <Text
            style={[
              commonStyle.plainSubTextStyle,
              {
                color:
                  id == undefined ? colors.AppTitleBlack : colors.AppNewGreen,
              },
            ]}>
            {name}
          </Text>
        </TouchableOpacity>
        <View style={{height: 10}} />
        <Text style={commonStyle.plainSubTextStyle}>{email}</Text>
        <View style={{height: 10}} />
        <Text style={commonStyle.plainSubTextStyle}>{mobileNo}</Text>
      </View>
    );
  };
  sellerDetail = ({name, email, mobileNo, id}) => {
    let title = '';
    if (this.state.orderDetailData['account']) {
      let accountData = this.state.orderDetailData['account'];
      title = accountData['name'];
    }
    return (
      <View>
        <Text style={appConfigStyle.titleTxtStyle}>{'Seller Details:'}</Text>
        <View style={{height: 10}} />
        <TouchableOpacity
          onPress={() => this.setState({showAccountDetail: true})}>
          <Text
            style={[appConfigStyle.titleTxtStyle, {color: colors.AppNewGreen}]}>
            {title}
          </Text>
        </TouchableOpacity>
        <View style={{height: 10}} />
        <Text
          style={[
            commonStyle.plainSubTextStyle,
            {color: colors.AppTitleBlack},
          ]}>
          {name}
        </Text>
        <View style={{height: 10}} />
        <Text style={commonStyle.plainSubTextStyle}>{email}</Text>
        <View style={{height: 10}} />
        <Text style={commonStyle.plainSubTextStyle}>{mobileNo}</Text>
      </View>
    );
  };
  renderStatusOrderValue = () => {
    var views = [];
    for (let a = 0; a < this.state.statusArray.length; a++) {
      let date = dateConversionFromTimeStamp(
        this.state.statusArray[a]['created_at'],
      );
      let status = statusModel.statusHandle(
        this.state.statusArray[a]['status'],
      );
      views.push(
        <View style={{borderRadius: 10}}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '40%',
              marginLeft: 20,
            }}>
            <Text style={{marginTop: 10}}>{status}</Text>
            <Text style={{marginTop: 10, textAlign: 'left'}}>{date}</Text>
          </View>
          <View
            style={{
              backgroundColor: colors.SimonGray,
              height: 1,
              marginTop: 10,
            }}
          />
        </View>,
      );
    }
    return views;
  };
  renderMainView = () => {
    if (this.state.dataLoad) {
      var shipname = '';
      if (this.state.orderDetailData['shipping_method']) {
        shipname = this.state.orderDetailData['shipping_method']['name'];
      }
      return (
        <ScrollView style={{height: windowHeight}}>
          <View style={commonStyle.mainView} ref={ref}>
            <View
              style={{
                flexDirection: itsMobileView ? 'column' : 'row',
                justifyContent: 'space-between',
                margin: 20,
              }}>
              <View style={{margin: 5}}>
                <this.titleLblRender
                  first={'Order Number:'}
                  second={this.state.orderDetailData['reference_number']}
                />
                <View style={{height: 10}} />
                <this.titleLblRender
                  first={'Order Date:'}
                  second={this.state.createdDate}
                />
                <View style={{height: 10}} />
                <this.titleLblRender
                  first={'Order Status:'}
                  second={statusModel.statusHandle(
                    this.state.orderDetailData['order_status'],
                  )}
                />
                <View style={{height: 10}} />
                <View style={{display: shipname.length == 0 ? 'none' : 'flex'}}>
                  <this.titleLblRender
                    first={'Shipment Method:'}
                    second={shipname}
                  />
                </View>
                <this.renderPayoutDate />
              </View>
              <View>
                <View style={{margin: 5}}>
                  <this.titleLblRender
                    first={'Invoice Number:'}
                    second={'N/A'}
                  />
                  <View style={{height: 10}} />
                  <this.titleLblRender
                    first={'Invoice Details:'}
                    second={'N/A'}
                  />
                  <View style={{height: 10}} />
                  <this.titleLblRender first={'Invoice Date:'} second={'N/A'} />
                </View>
              </View>
            </View>
            <View
              style={{height: 1, backgroundColor: colors.Lightgray, margin: 20}}
            />
            <View
              style={{
                flex: 1,
                flexDirection: itsMobileView ? 'column' : 'row',
                marginRight: 20,
                marginLeft: 20,
              }}>
              <View style={{margin: 5, width: '20%'}}>
                <this.renderDetails
                  title={'Buyer Details:'}
                  name={this.state.buyerName}
                  email={this.state.buyerEmail}
                  mobileNo={this.state.buyerMobile}
                  id={1}
                />
              </View>
              <View style={styles.subViewStyle}>
                <this.sellerDetail
                  name={this.state.sellerName}
                  email={this.state.sellerEmail}
                  mobileNo={this.state.sellerMobile}
                  id={2}
                />
              </View>
              <View
                style={[
                  styles.subViewStyle,
                  {
                    display:
                      this.state.deliveryName.length != 0 ? 'flex' : 'none',
                  },
                ]}>
                <this.renderDetails
                  title={'Delivery Address:'}
                  name={this.state.deliveryName}
                  email={`${this.state.deliveryPhone}`}
                  mobileNo={this.state.deliveryAddress}
                />
              </View>
              <View
                style={[
                  styles.subViewStyle,
                  {
                    display:
                      this.state.pickupName.length != 0 ? 'flex' : 'flex',
                  },
                ]}>
                <this.renderDetails
                  title={'Pickup Address:'}
                  name={this.state.pickupName}
                  email={`${this.state.pickupPhone}`}
                  mobileNo={this.state.pickupAddress}
                />
              </View>
            </View>
            <View style={styles.subConatinerView}>
              <View
                style={{
                  height: 40,
                  backgroundColor: colors.AppLightGreen,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}>
                <this.tableViewHeader />
              </View>
              <View style={{marginTop: 0}}>
                <this.columnDataView />
              </View>
              <View
                style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <View style={styles.order_details_columnTitleViewStyle}>
                  <View style={{height: 10}} />
                  <Text style={appConfigStyle.titleTxtStyle}>
                    Total Amount:
                  </Text>
                  <View style={{height: 10}} />
                </View>
                <View style={styles.order_details_columnTitleViewStyle}>
                  <Text style={tableStyle.orderAmountTxtStyle}>
                    {this.state.totalAmount}
                  </Text>
                </View>
              </View>
              <View style={{height: 1, backgroundColor: colors.Lightgray}} />
              <View
                style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <View style={styles.order_details_columnTitleViewStyle}>
                  <View style={{height: 10}} />
                  <Text style={appConfigStyle.titleTxtStyle}>
                    Amount in Words:
                  </Text>
                  <View style={{height: 10}} />
                </View>
                <View
                  style={[
                    styles.order_details_columnTitleViewStyle,
                    {marginTop: 10, paddingLeft: 0},
                  ]}>
                  <Text
                    style={[
                      CommonStyleSheet.amountTxtStyle,
                      {
                        color: colors.AppTitleBlack,
                        paddingTop: 0,
                        paddingLeft: 0,
                      },
                    ]}>
                    {this.state.wordsAmount} Only
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.subConatinerView}>
              <View
                style={{
                  height: 40,
                  backgroundColor: colors.AppLightGreen,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '40%',
                    marginLeft: 20,
                  }}>
                  <Text
                    style={[
                      CommonStyleSheet.amountTxtStyle,
                      {color: colors.AppTitleBlack},
                    ]}>
                    {'Status'}
                  </Text>
                  <Text
                    style={[
                      CommonStyleSheet.amountTxtStyle,
                      {color: colors.AppTitleBlack, marginRight: 55},
                    ]}>
                    {'Date'}
                  </Text>
                </View>
              </View>
              <this.renderStatusOrderValue />
              <View style={{height: 20}} />
            </View>
          </View>
        </ScrollView>
      );
    } else {
      return <View />;
    }
  };
  renderHeaderView = () => {
    return (
      <View style={styles.headerContainer}>
        <View style={styles.headerListViewcontainer}>
          <TouchableOpacity
            style={tableStyle.backBtnBackViewStyle}
            onPress={this.props.backBtnAction}>
            <BackBtnView />
          </TouchableOpacity>
          <Text
            style={[
              tableStyle.titleTextStyle,
              {marginLeft: 16},
            ]}>{`Order Detail`}</Text>
        </View>
        <View
          style={{flexDirection: 'row', marginLeft: itsMobileView ? 90 : 0}}>
          {this.updateOrderStatusView()}
          <View style={tableStyle.gradientViewBGStyle}>
            <Gradient
              gradients={colors.GradientColors} // required
              property="background"
              gradientType={'linear'}
              duration={2333000}
              angle="0deg">
              <ReactToPrint
                content={() => ref.current}
                documentTitle={this.state.orderDetailData['reference_number']}>
                <PrintContextConsumer>
                  {({handlePrint}) => (
                    <TouchableOpacity onPress={handlePrint}>
                      <Text style={tableStyle.saveTxtStyle}>{'Generate'}</Text>
                    </TouchableOpacity>
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
            </Gradient>
          </View>
        </View>
      </View>
    );
  };
  renderPayoutDate = () => {
    let orderStatus = this.state.orderDetailData['order_status'];
    var showView = 'none';
    let dateTime =
      this.state.orderDetailData['payout_at'] != null
        ? dateConversionFromTimeStamp(this.state.orderDetailData['payout_at'])
        : '-';
    let paymentMethod = this.state.orderDetailData['payment_method'];
    if (orderStatus == 9 || orderStatus == 10) {
      if (paymentMethod['type'] == PaymentMethodEnum.opp) {
        showView = 'flex';
      }
    }
    return (
      <View
        style={{
          display: showView,
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 5,
        }}>
        <Text style={appConfigStyle.titleTxtStyle}>{`Payout Date:`}</Text>
        <Text style={{marginTop: 2, marginLeft: 2}}>{dateTime}</Text>
        <View>
          <TouchableOpacity
            onPress={() => this.setState({changePayoutBool: true})}>
            <Text style={tableStyle.secondBtnTextStyle}>Change</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  renderDatePickerView = () => {
    let check = this.state.changePayoutBool;
    let strtD =
      this.state.selectedDate.length == 0
        ? 'Select Date & Time'
        : Moment(this.state.selectedDate).format(dateFormat);
    return (
      <View
        style={[
          tableStyle.passwordViewStyle,
          {display: check ? 'flex' : 'none'},
        ]}>
        <View style={{height: windowHeight, width: windowWidth, zIndex: 1}} />
        <View style={styles.datePickerContainerStyle}>
          <Text style={commonStyle.plainSubTextStyle}>
            Change Payout Date and Time
          </Text>
          <View
            style={[
              tableStyle.dateTextField,
              {height: 36, marginLeft: 20, marginTop: 30},
            ]}>
            <DatePicker
              selected={this.state.selectedDate}
              onChange={date => this.setState({selectedDate: date})}
              dateFormat="MM/dd/yyyy h:mm:ss"
              showTimeInput={true}
              customInput={
                <View style={{width: 145, alignItems: 'center'}}>
                  <Text style={commonStyle.subTitleStyle}>{strtD}</Text>
                </View>
              }
            />
          </View>
          <View
            style={{
              marginTop: 20,
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
            }}>
            <View style={[tableStyle.secondButtonViewStyle, {marginRight: 0}]}>
              <TouchableOpacity
                onPress={() => this.setState({changePayoutBool: false})}>
                <Text style={tableStyle.secondBtnTextStyle}>{'Close'}</Text>
              </TouchableOpacity>
            </View>
            <View style={[tableStyle.secondButtonViewStyle, {marginRight: 0}]}>
              <TouchableOpacity onPress={() => this.updatePayoutBtnAction()}>
                <Text style={tableStyle.secondBtnTextStyle}>{'Update'}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  };
  updateOrderStatusView = () => {
    var showBtn;
    if (this.state.orderDetailData['next_status']) {
      showBtn =
        this.state.orderDetailData['next_status'].length == 0 ? false : true;
    }
    return (
      <View style={{display: showBtn ? 'flex' : 'none'}}>
        <View style={tableStyle.secondButtonViewStyle}>
          <TouchableOpacity
            onPress={() => this.setState({showUpdateStatusViewBool: true})}>
            <Text style={tableStyle.secondBtnTextStyle}>{'Update Status'}</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  renderChangeOrderSatatusView = () => {
    var views = [];
    if (this.state.orderDetailData['next_status']) {
      let nextStatus = this.state.orderDetailData['next_status'];
      for (let a = 0; a < nextStatus.length; a++) {
        let name = StatusModel.statusHandle(nextStatus[a]);
        let check =
          this.state.updateStatusID == nextStatus[a]
            ? radioCheckIcon
            : radioUncheckIcon;
        views.push(
          <View>
            <TouchableOpacity
              style={styles.statusItemViewStyle}
              onPress={() => this.didSelectOrderStatus(nextStatus[a])}>
              <Text style={commonStyle.plainSubTextStyle}>{name}</Text>
              <Image style={{height: 16, width: 16}} source={check} />
            </TouchableOpacity>
            <View style={{height: 10}} />
          </View>,
        );
      }
    }
    let title = 'Change Order Status';
    return (
      <View
        style={[
          tableStyle.passwordViewStyle,
          {display: this.state.showUpdateStatusViewBool ? 'flex' : 'none'},
        ]}>
        <View
          style={{
            backgroundColor: colors.AppWhite,
            paddingBottom: 10,
            width: 300,
            borderRadius: 10,
            alignSelf: 'center',
          }}>
          <View style={{margin: 16}}>
            <View
              style={{flexDirection: 'row', justifyContent: 'space-between'}}>
              <Text style={commonStyle.plainTextStyle}>{title}</Text>
              <TouchableOpacity
                onPress={() => this.closeOrderStatusViewBtnAction()}>
                <Image
                  style={{height: 20, width: 20, marginTop: 2, marginRight: 5}}
                  source={closeIcon}
                />
              </TouchableOpacity>
            </View>
            <View style={{marginTop: 30}}>{views}</View>
            <View
              style={{
                alignSelf: 'center',
                margin: 16,
                marginTop: 50,
                paddingLeft: 10,
                zIndex: 10,
              }}>
              {this.updateOrderStatusBtnView()}
            </View>
          </View>
        </View>
      </View>
    );
  };
  updateOrderStatusBtnView = () => {
    return (
      <View>
        <View style={tableStyle.secondButtonViewStyle}>
          <TouchableOpacity onPress={() => this.updateBtnAction()}>
            <Text style={tableStyle.secondBtnTextStyle}>{'Update Status'}</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  render() {
    if (this.state.viewListingDetailBool) {
      return (
        <ListingDetails
          backBtnAction={() => this.backBtnAction()}
          listingID={this.state.selectedID}
        />
      );
    } else if (this.state.showUserDetail) {
      return (
        <UserDetail
          backBtnAction={() => this.setState({showUserDetail: false})}
          userID={this.state.userDetailData['id']}
          userDetailData={this.state.userDetailData}
        />
      );
    } else if (this.state.showAccountDetail) {
      return (
        <AccountDetail
          backBtnAction={() => this.setState({showAccountDetail: false})}
          accountID={this.state.accountID}
        />
      );
    } else {
      return (
        <SafeAreaView style={commonStyle.Container}>
          {this.renderDatePickerView()}
          {this.renderChangeOrderSatatusView()}
          <View style={tableStyle.navigationViewStyle}>
            {this.renderHeaderView()}
          </View>
          <View style={{height: windowHeight - 20}}>
            <this.renderMainView />
            <Appload enable={this.state.dataLoad} />
          </View>
        </SafeAreaView>
      );
    }
  }
}

const tableHeaderString = [
  'Description',
  'Unit Price',
  'Discount',
  'Qty',
  'Tax Rate',
  'Total Amount',
];
