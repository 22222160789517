import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  FlatList,
  SafeAreaView,
  Dimensions,
  TextInput,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../../Settings/HeaderView';
import {AppToast} from '../../HelperClasses/AppToast';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
// import TranslationFields from './TranslationFields'

import SettingsStyleSheet from '../../StyleSheets/SettingStyleSheet';
import gTick_Icon from './../../assets/grayTickIcon.png';
import AppConfigStyleSheet from '../../StyleSheets/AppConfigStyleSheet';
import { TranslateEnum } from '../../Models/TranslateEnum';
import AppConstants from '../../Constants/AppConstants';

const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth  ?  true : false



export default class Translations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      name: '',
      dataLoad: false,
      id: '',
      selectedTabIndex:0,
      selectedLanguage:0,
      languageArray:[],
      selectedValue:{},
      valueIndex: 0,
      ListingArray:[],
      updateUI:false,
      translationValues: [],
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    data: PropTypes.any,
    isEdit: PropTypes.bool,
    titleString: PropTypes.string,
    translationTitle: PropTypes.string,
    translationRef:PropTypes.string,
  };
  componentDidMount() {
    this.loadLanguageApi();
    this.loadApis();
  }
  loadApis() {
    this.setState({dataLoad:false})
    if (this.props.translationRef == TranslateEnum.category) {
      this.loadCatgoryApi();
    } else if (this.props.translationRef == TranslateEnum.listingVariant) {
      this.loadListingVariantApi();
    } else if (this.props.translationRef == TranslateEnum.paymentMethod) {
      this.loadPaymentMethodsApi();
    } else if (this.props.translationRef == TranslateEnum.shippingMethod) {
      this.loadShippingMethodsApi();
    } else {
      this.loadAttributeApi();
    }
  }
    //MARK:-  APIs Method 
  loadLanguageApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.tanantslanguage, 'get', '',  '');
    if (responseJson['status'] == true) {
      var ppData = responseJson['data']['languages'];
      for (let obj of ppData) {
          this.state.languageArray.push(obj);
      }
      this.setState({updateUI: !this.state.updateUI, dataLoad: true});
    } else {
      this.setState({dataLoad: true});
    }
  };

  getTranslationsApi = async () => {
    this.setState({dataLoad: false});
    let path = this.props.translationRef;
    let params = this.state.selectedValue
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.translations + `?reference_id=${params['id']}&reference_type=${path}&translation_type=name`,'get','','');
    if (responseJson['status'] == true) {
      var tdata = responseJson['data']['translation'];
      this.state.translationValues = [];
      let values = tdata['values'];
      for (let a = 0; a < this.state.languageArray.length; a++){
        var dict = { 'locale' : this.state.languageArray[a]['code']}
        if (values[a]) {
          dict['value'] = values[a]['value'];
        }else {
          dict['value'] = '';
        }
        this.state.translationValues.push(dict);
      }
      this.setState({dataLoad: true});
    }
    this.setState({dataLoad: true});
  };
  loadCatgoryApi = async () => {
    let path = this.props.titleString == 'Listings' ? 'listings' : 'accounts';
    const responseJson = await networkService.networkCall(APPURL.URLPaths.categories + path, 'get')
    this.setState({dataLoad: true});
    this.setState({ListingArray: []});
    if (responseJson['status'] == true) {
      var listD = responseJson['data']['categories'];
      for (let i = 0; i < listD.length; i++) {
        this.manageData(listD[i], listD[i]['name']);
        let hierarchyD = listD[i]['sub_category'];
        if (hierarchyD.length != 0) {
          for (let j = 0; j < hierarchyD.length; j++) {
            this.manageData(hierarchyD[j], `${listD[i]['name']}-> ${hierarchyD[j]['name']}`);
            let hierarchy2 = hierarchyD[j]['sub_category'];
            if (hierarchy2.length != 0) {
              for (let k = 0; k < hierarchy2.length; k++) {
                this.manageData(hierarchy2[k], `${hierarchyD[j]['name']}--> ${hierarchy2[k]['name']}`);
                let hierarchy3 = hierarchy2[k]['sub_category'];
                if (hierarchy3.length != 0) {
                  for (let l = 0; l < hierarchy3.length; l++) {
                    this.manageData(hierarchy3[l], `${hierarchy2[k]['name']}---> ${hierarchy3[l]['name']}`);
                  }
                } 
              }
            } 
          }
        } 
      }
      if (this.state.ListingArray.length != 0){
        this.state.selectedValue = this.state.ListingArray[this.state.valueIndex];
        this.getTranslationsApi();
      }
      this.setState({dataLoad: true});
    }
    this.setState({dataLoad: true});
  };
  manageData(data, name) {
    var deleteEnable = true;
    if (data['sub_category']) {
      deleteEnable = data['sub_category'].length == 0 ? true : false;
      if (data['sub_category'].length !== 0) {
        deleteEnable = data['parent'] == 0 ? false : true;
      }
    }
    let hierarchyAry = data['hierarchy'];
    var hierarchyName = "";
    var level = 1;
    if (hierarchyAry.length > 0) {
      for (let a = 0; a < hierarchyAry.length; a++) {
        hierarchyName = `${hierarchyName}${hierarchyAry[a]['name']}`
        level = hierarchyAry[a]['level']
        if (a !== hierarchyAry.length - 1) {
          hierarchyName = hierarchyName + ' -> '
        }
      }
    }
    let catData = {
      id: data['id'],
      parent: data['parent'],
      name: data['name'],
      active:data['active'],
      image_path: data['image_path'],
      delete: deleteEnable,
      subCategory: data['name'],
      order_by: data['order_by'],
      hierarchyName: hierarchyName,
      level:level,
    };
    this.state.ListingArray.push(catData);
    this.setState({dataLoad: true});

  }
  loadAttributeApi = async () => {
    this.setState({dataLoad: false});
    let path = this.props.titleString == 'Listings' ? 'listings' : 'accounts'
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.attributes + `?type=${path}`,'get','','');
    this.setState({ListingArray: []});
    if (responseJson['status'] == true) {
      var attriD = responseJson['data']['attributes'];
      for (let i = 0; i < attriD.length; i++) {
        this.state.ListingArray.push(attriD[i]);
      }
      if (this.state.ListingArray.length != 0){
        this.state.selectedValue = this.state.ListingArray[this.state.valueIndex];
        this.getTranslationsApi();
      }
      this.setState({dataLoad: true});
    }
    this.setState({dataLoad: true});
  };
  loadListingVariantApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.addVariant,'get','','');
    this.setState({ListingArray: []});
    if (responseJson['status'] == true) {
      var vTypeD = responseJson['data']['variant_types'];
      for (let objc of vTypeD) {
        this.state.ListingArray.push(objc);
      }
      if (this.state.ListingArray.length != 0){
        this.state.selectedValue = this.state.ListingArray[this.state.valueIndex];
        this.getTranslationsApi();
      }
      this.setState({dataLoad: true});
    }
    this.setState({dataLoad: true});
  };
  loadPaymentMethodsApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.tenantPaymentMethod, 'get', '', '');
    this.setState({ListingArray: []});
    if (responseJson['status'] == true) {
      var objectD = responseJson['data']['payment_methods'];
      for (let i = 0; i < objectD.length; i++) {
        this.state.ListingArray.push(objectD[i]);
      }
      if (this.state.ListingArray.length != 0){
        this.state.selectedValue = this.state.ListingArray[this.state.valueIndex];
        this.getTranslationsApi();
      }
      this.setState({dataLoad: true});
    }
    this.setState({dataLoad: true});
  };
  loadShippingMethodsApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.tenantShippingMethod, 'get', '', '');
    this.setState({ListingArray: [] });
    if (responseJson['status'] == true) {
      var objectD = responseJson['data']['shipping_methods'];
      for (let i = 0; i < objectD.length; i++) {
        this.state.ListingArray.push(objectD[i]);
      }
      if (this.state.ListingArray.length != 0){
        this.state.selectedValue = this.state.ListingArray[this.state.valueIndex];
        this.getTranslationsApi();
      }
      this.setState({dataLoad: true});
    }
    this.setState({ dateLoad: true });
  };
  translateApi = async () => {
    this.setState({dataLoad: false});
    var values = [];
    for (let obj of this.state.translationValues) {
      let fDict = {
        value: obj['value'],
        locale: obj['locale'],
      };
      values.push(fDict)
    }
    let pDict = {
      reference_type : this.props.translationRef,
      translation_type: 'name',
      reference_id: this.state.selectedValue['id'],
      values:values,
    }
    var path = APPURL.URLPaths.translations;
    var method = 'PATCH';
    // console.log('params ==>', JSON.stringify({translation: pDict}))
    const responseJson = await networkService.networkCall( path,method, JSON.stringify({translation: pDict}),'');
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      console.log('responseJson ===>', responseJson)
      this.loadApis();
      this.customAlert();
    }
  }
  customAlert() {
    AppToast();
  }
  //MARK:-  Button Actions
  saveButtonAction = () => {
    this.translateApi()
  };
  didSelectMenuItem = index => {
    this.state.selectedLanguage = 0;
    this.state.selectedValue = this.state.ListingArray[index];
    this.state.valueIndex = index
    this.setState({dataLoad:false, updateUI: !this.state.updateUI});
    this.getTranslationsApi();
  };
  didChangeTextField(text,id){    
    let code = this.state.languageArray[id]['code'];
    let index = this.state.translationValues.findIndex(x => x.locale === code);
    let vDic = {'locale': code, value: text}
    this.state.translationValues[index] = vDic;
    this.setState({updateUI: !this.state.updateUI});
  }
  // UI Renders
  mainViewRender = () => {
    if (Object.keys(this.state.selectedValue).length != 0) {
      return (<ScrollView horizontal={true} style={{width:'100%'}}>
        <View style={{ width: itsMobileView ? '100%' : '75%' }}>
          <View style={{ flexDirection: itsMobileView ? 'column' : 'row' }}>
            <this.renderLanguageTypeView />
          </View>
        </View>
      </ScrollView>)
    } else {
      return <View />
    }
  }
  renderLanguageFields = (id) => {
    let index = id['id']
    var fieldsViews = []
    if (this.state.translationValues.length != 0 ) {
        let dictData = this.state.translationValues[index];
        for (let a = 0; a < 1 ; a++) {
          fieldsViews.push(
            <View style={{ width: itsMobileView ? windowWidth/1.4 : 200}}>
              <TextInput
                style={[AppConfigStyleSheet.txtFieldStyle,{outline:0}]}
                placeholder="Default text"
                value={dictData['value']}
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.didChangeTextField(text,index)}
              />
            </View>
          )
        }
    } else {
      fieldsViews.push(<View/>)
    }
    return fieldsViews
  }
  renderLanguageTypeView = () => {
    var fieldsViews = [];
      for (let a = 0; a < this.state.languageArray.length; a++) {
        fieldsViews.push(
          <View style={{width: 200, margin: 20}}>
            <View style={{ height: 24 }}>
              <Text style={AppConfigStyleSheet.titleTxtStyle}>
                {this.state.languageArray[a]['name']}
              </Text>
            </View>
              <this.renderLanguageFields id={a}/>
          </View>
        )
    }
    return fieldsViews
  }
  subMenuViewRender = () => {
    let subViews = [];
    for (let a = 0; a <= this.state.ListingArray.length - 1; a++) {
      subViews.push(
        <TouchableOpacity
          style={ this.state.selectedValue['id'] == this.state.ListingArray[a]['id']  ? SettingsStyleSheet.selectedSubMenuViewStyle
              : SettingsStyleSheet.subMenuViewStyle
          }
          onPress={() => this.didSelectMenuItem(a)}>
          <Image  style={SettingsStyleSheet.subMenuIconStyle} resizeMode="contain"  source={gTick_Icon} />
          <Text style={SettingsStyleSheet.subMenutextStyle}>
            {this.state.ListingArray[a]['name']}
          </Text>
        </TouchableOpacity>,
      );
    }
    return subViews;
  };
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <Header_View
            title={`${this.props.translationTitle} Translations`}
            backBtn={this.props.backBtnAction}
            saveBtn={() => this.saveButtonAction()}
          />
        <View style={{ marginTop: 10, zIndex: 120 }}>
          <Appload enable={this.state.dataLoad} />
        </View>
        <ScrollView style={{zIndex: 12, height:windowHeight - 120 }}>
          <View style={commonStyle.mainView}>
          <View style={{height: 20}} />
            <View style={{ marginTop: 0, marginLeft: 10 }}>
              <View style={{ flexDirection:itsMobileView ? 'column' : 'row' }}>
                <View style={SettingsStyleSheet.subMenuContainerStyle}>
                  <View>
                    <this.subMenuViewRender />
                  </View>
                </View>
                <View style={SettingsStyleSheet.horizontalLineView}></View>
                <this.mainViewRender />
              </View>
            </View>
            <View style={{height: 20, width: '100%'}} />
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

