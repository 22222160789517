import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  TextInput,
  Platform,
  SafeAreaView,
  Alert,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import Header_View from '../HeaderView';
import grayTick_Icon from './../../assets/grayTickIcon.png';
import PropTypes from 'prop-types';
import SettingsStyleSheet from '../../StyleSheets/SettingStyleSheet';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import StripeIntegrations from './StripeIntegration';
import PaymentU from './PayU';
import PaymentDunya from './PayDunya';
import {AppToast} from '../../HelperClasses/AppToast';
import { ToastContainer} from 'react-toastify';
import HelperLinkViews from '../../Component/HelperLinkView';
import Switch from "react-switch";
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

const AppConstants = require('../../Constants/AppConstants');
// const firebaseInt = new FirebaseIntegrations();
// const windowWidth = Dimensions.get('window').width;
// const windowHeight = Dimensions.get('window').height;
let subMenuTextArray = [
  'Stripe',
  'PayU Latam',
  'PayDunya'
];

export default class PaymentConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      switchArray: [true, true, true, true],
      saveBtnCallBack: false,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtn: PropTypes.func,
  };
  componentDidMount() {}
  //MARK:-  APIs Method 
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveBtnAction() {
    // console.log('main calling');
    if (this.state.subMenuSelectedIndex == 1) {
      this.child.saveButtonAction();
    }  else if (this.state.subMenuSelectedIndex == 0) {
      this.child.saveButtonAction();
    } else if (this.state.subMenuSelectedIndex == 2) {
      this.child.saveButtonAction();
    } 
  }
  didSelectMenuItem = index => {
    this.setState({subMenuSelectedIndex: index});
  };
  switchSattusBtnAction(id) {
    this.state.switchArray[id] = !this.state.switchArray[id];
    this.setState({updateUI: !this.state.updateUI});
  }
  dissmissSaveBtn() {
    this.setState({saveBtnCallBack: false});
  }
  /*  UI   */

  subMenuViewRender = () => {
    let subViews = [];
    for (let a = 0; a <= subMenuTextArray.length - 1; a++) {
      subViews.push(
        <TouchableOpacity
          style={
            this.state.subMenuSelectedIndex == a
              ? SettingsStyleSheet.selectedSubMenuViewStyle
              : SettingsStyleSheet.subMenuViewStyle
          }
          onPress={() => this.didSelectMenuItem(a)}>
          <Image
            style={SettingsStyleSheet.subMenuIconStyle}
            resizeMode="contain"
            source={grayTick_Icon}
          />
          <Text style={SettingsStyleSheet.subMenutextStyle}>
            {subMenuTextArray[a]}
          </Text>
        </TouchableOpacity>,
      );
    }
    return subViews;
  };

  mainViewRender = () => {
    let title = subMenuTextArray[this.state.subMenuSelectedIndex];
    let pp = 'Enter ' + title + ' ID';
    if (this.state.subMenuSelectedIndex == 0) {
      return (<StripeIntegrations ref={child => { this.child = child}} />);
    } else if (this.state.subMenuSelectedIndex == 1) {
      return ( <PaymentU ref={child => {this.child = child}} />);
    } else if (this.state.subMenuSelectedIndex == 2) {
      return (  <PaymentDunya ref={child => {this.child = child}} />);
    } else {
      return (
        <View style={{width: '70%', marginLeft: 10}}>
          <Text style={tableStyle.listTitleText}>{title}</Text>
          <Text style={appConfigStyle.subTitleStyle}>
            {AppConstants.plainTextString}
          </Text>
          <View style={appConfigStyle.horizontalLineViewStyle} />
          <View style={appConfigStyle.subViewStyle}>
            <View style={{flexDirection: 'column', flex: 1.5}}>
              <View style={{marginTop: -20}}>
                <Text style={appConfigStyle.titleTxtStyle}>
                  Enable {title} integration
                </Text>
                <View style={{ flexDirection: 'row', marginTop: 0 }}>
                  <Switch
                    onColor={colors.AppNewGreen}
                    onChange={() => this.switchSattusBtnAction(this.state.subMenuSelectedIndex)}
                    checked={this.state.switchArray[this.state.subMenuSelectedIndex]}
                    height={22}
                    width={50}
                  />
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <Text style={appConfigStyle.statusTxtStyle}>{title}</Text>
                    <Text style={[CommonStyleSheet.hintTitleStyle,{marginLeft: 10,marginRight: 30,}]}>
                      (Lorem ipsum dolor sit amet,)
                    </Text>
                  </View>
                </View>
                <TextInput
                  style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                  placeholder={pp}
                  placeholderTextColor={colors.placeholderColor}
                />
              </View>
            </View>
          </View>
        </View>
      );
    }
  };
  renderHelperView= () => {
    if (this.state.subMenuSelectedIndex == 0) {
      return  <HelperLinkViews  title={'Stripe'}/>
    } else {
      return <View/>;
    }
  }
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={{flexDirection: 'column', marginTop: -20}}>
          <ToastContainer />
          <ScrollView style={SettingsStyleSheet.mainView}>
            <View style={SettingsStyleSheet.mainContainerView}>
              <View style={SettingsStyleSheet.subMenuContainerStyle}>
                <View>
                  <this.subMenuViewRender />
                </View>
              </View>
              <View style={SettingsStyleSheet.horizontalLineView}></View>
              <View style={{flex: 2, backgroundColor: 'white'}}>
                <this.mainViewRender />
              </View>
            </View>
          </ScrollView>
        </View>
        <this.renderHelperView />
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
    //}
  }
}
const styles = StyleSheet.create({
  Container: {
    backgroundColor: 'white',
  },
});
