import React, {Component} from 'react';
import Storage from 'react-native-storage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AppConstants from '../Constants/AppConstants';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

var CryptoJS = require('crypto-js');

const storage = new Storage({
  size: 1000,
  storageBackend: AsyncStorage,
  defaultExpires: 100000 * 3600 * 24,
  enableCache: true,
  sync: {
    // we'll talk about the details later.
  },
});
export function clearStorage(value) {
  storage.clearMap()
}
export function getCurrentUser(callback) {
  storage.getAllDataForKey('adminKey').then(ids => {
    var bytes = CryptoJS.AES.decrypt(ids[0], AppConstants.privateKey);
    var originalText = bytes.toString(CryptoJS.enc.Utf8);
    AppConstants.BearerAuthorization = originalText;
    callback(originalText);
  });
}
export function getValue(key , callbackValue) {
  storage.getAllDataForKey(key).then(ids => {
    callbackValue(ids);
  });
}
export function saveUserData(key, data, id) {
  storage.save({key: key,id: id, data: data});
}
export function saveAdminData(key, data, id) {
  var ciphertext = CryptoJS.AES.encrypt(
    data,
    AppConstants.privateKey,
  ).toString();
  storage.save({
    key: key,
    id: id,
    data: ciphertext,
  });
}
export function saveCookies(key, data) {
    cookies.set(key, data, { path: '/', maxAge: 43200000, domain: ".tradly.app" })
  return true
}
export function clearCookies(key) {
  cookies.remove(key,{ path: '/', maxAge: 43200000, domain: ".tradly.app" })
return true
}
export function getCookiesValue(key) {
  if (AppConstants.isDevelopment) {
    return 'dev'
  } else {
    let value = cookies.get(key);
    if (value == undefined) {
      return 'production'
    } else {
      return value
    }
  }
}
export function getMemberCookiesValue(key) {
    let value = cookies.get(key);
    if (value == undefined) {
      return false
    } else {
      return value
    }
}
export function getValuesFromCookies(key) {
  let value = cookies.get(key);
  if (value == undefined) {
    return 'en'
  } else {
    return value
  }
}