'use strict';
import AppConstants from '../Constants/AppConstants';
import colors from '../HelperClasses/AppColor';
import { b2, h3, p1, t1 } from './TextTitleStyleSheet';
var React = require('react-native');
var {StyleSheet, Dimensions} = React;

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

export default StyleSheet.create({

  tableViewTitleViewStyle: {
    padding: 0,
    width: '100%',
    minHeight: 40,
  },
  tableViewTitleStyle: {
    ...t1,
    textAlign: 'flex-start',
    paddingTop: 10,
    paddingLeft: 10,
    paddingBottom: 10,
  },
  valueTitleStyle: {
    ...p1,
    textAlign: 'flex-start',
    padding: 10,
    color: colors.Appgray
  },
  subViewStyle: {
    margin: 5,
    borderLeftWidth: 1,
    borderLeftColor: colors.Lightgray,
    paddingLeft: 10,
    paddingBottom: 10,
  },
  columnActiveBtnStyle: {
    ...b2,
    textAlign: 'center',
    color: colors.Appgray,
    padding: 8,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.Appgreen,
    backgroundColor: colors.UltraLightGreen,
    alignSelf: 'flex-start',
    overflow: 'hidden',
    marginLeft: 10,
  },
  columnDeActiveBtnStyle: {
    ...b2,
    textAlign: 'center',
    color: colors.Appgray,
    padding: 8,
    borderRadius: 5,
    backgroundColor: colors.UltraLightRed,
    borderWidth: 1,
    borderColor: colors.AppButtonRed,    alignSelf: 'flex-start',
    overflow: 'hidden',
    marginLeft: 10,
  },
  containerView: {
    flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
    flexWrap: 'wrap',
  },
  imageViewContainerStyle:{
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    margin: 20,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    // padding:10,
  },
  imageViewsStyle: {
    height: 220,
    width: 200,
  },
});
