import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Platform,
  Alert,
  TouchableOpacity,
  SafeAreaView,
  Dimensions,
  Image,
  ScrollView,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import RightView from './TenantCommonClasses/RightCustomView';
import commonStyle from '../StyleSheets/CreateTenantCommonStyleSheet';
import HeaderView from './TenantCommonClasses/LogoHeader';
import bToc_Icon from '../assets/bTocIcon.png';
import cToc_Icon from '../assets/cTocIcon.png';
import enterprise_Icon from '../assets/enterpriseIcon.png';
import BottomView from './TenantCommonClasses/FooterView';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
// import {StackActions} from '@react-navigation/native';
import {getValue} from '../HelperClasses/UserPrefrences';
import AppConstants from '../Constants/AppConstants';

const APPURL = require('../Constants/URLConstants');

const windowHeight = Dimensions.get('window').height;
const contentFul = require('../Constants/ContentFullConstant');
let titleArray = [
  'Product',
  'Event/Bookings',
  'Partner Platform/Pages/Directory',
  'Others',
];
let imagesArray = [cToc_Icon, bToc_Icon, bToc_Icon, enterprise_Icon];

export default class SelectPlatform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBusinessIndex: 0,
      dataLoad: true,
    };
  }
  componentDidMount() {
    // console.log('route.params', this.props.route.params);
  }
  //MARK:-  APIs Method 
  addCurrencyApi = async () => {
    const {currencyData} = this.props.route.params;
    var dict = {
      name: currencyData['name'],
      default: true,
      active: true,
      order_by: 1,
      code: currencyData['code'],
      exchange_rate: 1,
      precision: currencyData['decimal_digits'],
      thousand_separator: ',',
      decimal_point: '.',
      format: `${currencyData['symbol_native']}{amount}`,
    };
    var path = APPURL.URLPaths.currencies;
    const responseJson = await networkService.networkCall(
      path,
      'post',
      JSON.stringify({currency: dict}),
      '',
    );
    if (responseJson['status'] == true) {
      const responseJson = await networkService.networkCall(
        path,
        'post',
        JSON.stringify({currency: dict}),
        '',
        '',
        '',
        true,
      );
      if (responseJson['status'] == true) {
        this.addAppLanguageApi();
      } else {
        this.setState({dataLoad: true});
        this.alertView(responseJson);
      }
    } else {
      this.setState({dataLoad: true});
      this.alertView(responseJson);
    }
  };
  addAppLanguageApi = async () => {
    const {languageData} = this.props.route.params;
    var dict = {
      language_id: languageData['id'],
      name: languageData['name'],
      active: true,
      default: true,
      order_by: 1,
    };
    var path = APPURL.URLPaths.tanantslanguage;
    const responseJson = await networkService.networkCall(
      path,
      'post',
      JSON.stringify({language: dict}),
      '',
    );
    if (responseJson['status'] == true) {
      const responseJson = await networkService.networkCall(
        path,
        'post',
        JSON.stringify({language: dict}),
        '',
        '',
        '',
        true,
      );
      if (responseJson['status'] == true) {
        getValue('id', resp => {
          this.createBusinessApi(resp[0]);
          this.sampleDataApi(resp[0]);
        });
      } else {
        this.setState({dataLoad: true});
      }
    } else {
      this.setState({dataLoad: true});
    }
  };
  createBusinessApi = async parameter => {
    let typeValue =
      this.state.selectedBusinessIndex == 3
        ? -1
        : this.state.selectedBusinessIndex + 1;
    const {businessData} = this.props.route.params;
    var dict = {
      type: typeValue,
      sub_type: businessData,
    };
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.createTenant + `${parameter}`,
      'PATCH',
      JSON.stringify({tenant: dict}),
      '',
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.addAuthApi();
    }
  };
  sampleDataApi = async parameter => {
    var dict = {
      template_name: 'general',
    };
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.sampleData,
      'POST',
      JSON.stringify(dict),
      '',
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.addAuthApi();
    }
  };
  addAuthApi = async () => {
    var configs = [];
    let fDict2 = {
      key_group: 'onboarding',
      key: 'onboarding_completed',
      value: true,
      secured: false,
    };
    configs.push(fDict2);
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
      '',
    );
    if (responseJson['status'] == true) {
      AppConstants.appType = false;
      this.setState({dataLoad: true});
      // this.props.navigation.navigate('FinalScreen');
      // this.props.navigation.dispatch(StackActions.replace('SuperAdmin'));
    } else {
      this.setState({dataLoad: true});
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  submitButtonAction() {}
  didSelectSetting = id => {
    // console.log('id', id);
    this.setState({selectedBusinessIndex: id});
  };
  nextBtnAction() {
    // this.setState({dataLoad: false});
    // this.addCurrencyApi();
    // this.props.navigation.navigate('SelectPaymentPlatform', {
    //   currencyData: currencyData,
    //   languageData:languageData,
    //   businessData: this.state.selectedBusinessIndex + 1,
    // });
  }
  //MARK:-  UI Method 

  renderBusinessView = () => {
    var view = [];
    for (let a = 0; a < titleArray.length; a++) {
      view.push(
        <TouchableOpacity
          style={
            this.state.selectedBusinessIndex == a
              ? commonStyle.highlightCustomViewStyle
              : commonStyle.customViewStyle
          }
          onPress={id => this.didSelectSetting(a)}>
          <Image
            style={commonStyle.iconImageViewStyle}
            resizeMode="contain"
            source={imagesArray[a]}
          />
          <Text style={commonStyle.customViewTitleStyle}>{titleArray[a]}</Text>
        </TouchableOpacity>,
      );
    }
    return view;
  };
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={styles.Container}>
          <View style={commonStyle.mainViewStyle}>
            <View style={commonStyle.leftViewStyle}>
              <HeaderView />
              <ScrollView style={{height: windowHeight - 100}}>
                <View style={commonStyle.contentViewStyle}>
                  <ScrollView showsVerticalScrollIndicator={false}>
                    <View>
                      <Text style={commonStyle.contentTitleStyle}>
                        Select your theme
                      </Text>
                      <View style={{marginTop: 30}} />
                      <ScrollView horizontal={true}>
                        <View style={commonStyle.customViewContainer}>
                          <this.renderBusinessView />
                        </View>
                      </ScrollView>
                    </View>
                  </ScrollView>
                  <View>
                    <BottomView
                      nextBtn={() => this.nextBtnAction()}
                      backBtn={() => this.props.navigation.pop()}
                      pageNumber={'4'}
                      progressValue={'100%'}
                      nextBtnTitle={'Finish'}
                    />
                  </View>
                </View>
              </ScrollView>
            </View>
            <View style={commonStyle.rightViewStyle}>
              <RightView
                title={contentFul.theme}
                description={contentFul.themeDescription}
              />
            </View>
          </View>
          <Appload enable={this.state.dataLoad} />;
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});
