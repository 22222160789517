export default class ErrorHandler {
  static errorHandle(errorCode,msg) {
    switch (errorCode) {
      case 401:
        return 'Unauthorized';
      // case 752:
      //   return 'invalid or missing parameters';
      case 109:
        return 'email already exists';
      default:
        return msg;
    }
  }
}