import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
  Alert,
  Platform,
  Image,
  SafeAreaView,
} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import PropTypes from 'prop-types';
import EmptyListUI from '../../Component/AppEmptyList';
import {AppToast} from '../../HelperClasses/AppToast';
import placeholder from '../../assets/pPlaceholder.png';
import HeaderView from '../HeaderView';
import { getThumbnailImage } from '../../HelperClasses/SingletonClass';
import AccountDetails from './../../Accounts/AccountDetail'
import AppConstants from '../../Constants/AppConstants';

const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;

export default class AccountsUnderCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountsArray: [],
      dataLoad: false,
      haveData: false,
      viewAccountDetailBool:false,
      selectedID:0,
    };
  }
  static propTypes = {
    CID: PropTypes.string,
    type: PropTypes.string,
    backBtnAction:PropTypes.any,
    categoryData:PropTypes.any,
  }
  componentDidMount() { 
      this.loadAccountsApi()
  }
  //MARK:-  APIS Method 

  loadAccountsApi = async () => {
    this.setState({dataLoad: false})
    const responseJson = await networkService.networkCall(APPURL.URLPaths.accountListings + `1&category_id=${this.props.CID.toString()}&per_page=100&type=accounts`,'get')
    console.log('responseJson', responseJson)
    this.setState({accountsArray: []})
    if (responseJson['status'] == true) {
      var vTypeD = responseJson['data']['accounts']
      for (let objc of vTypeD) {
        this.state.accountsArray.push(objc)
      }
      this.state.haveData = this.state.accountsArray.length == 0 ? true : false
    }
    this.setState({dataLoad: true})
  }
 
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast(title);
    } else {
      Alert.alert(title);
    }
  }
  
  editButtonAction = index => {
    let id_Index = this.state.variantTypeArray.findIndex(x => x.id === index)
    this.props.editBtn(this.state.variantTypeArray[id_Index])
  }
  viewAccountBtnAction(id) {
    this.setState({selectedID: id, viewAccountDetailBool: true})
  }
 
  /*  UI   */
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    if (this.state.accountsArray.length == 0) {
      if (this.state.haveData) {
        return <EmptyListUI />;
      }else {
        return <View />;
      }
    } else {
      var views = [];
      for (let i = 0; i < this.state.accountsArray.length; i++) {
        let item = this.state.accountsArray[i]
        views.push(
          <FlatList
            data={[
              item['id'],
              item['description'],
              item['images'][0] || '',
              item['active'],
              item['status'],
              item['location']['formatted_address'] ?? '',
            ]}
            style={{
              backgroundColor: 'white',
            }}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />,
        )
      }
      return views;
    }
  }

  columnCell = ({item, index}) => {
    if (index == 3) {
      let  val = item ? 'Active' : 'Inactive'
      return (
        <View style={styles.columnTitleViewStyle} >
          <Text style={item == true ? tableStyle.columnActiveBtnStyle : tableStyle.columnDeActiveBtnStyle}>
            {val}
          </Text>
        </View>
      );
    }else if (index == 4) {
      let val = 'Pending'
      if (item == 2) {
        val = 'Approved'
      } else if (item == 3){
        val = 'Rejected'
      }
      return (
        <View style={styles.columnTitleViewStyle} >
          <Text style={item == 2 ? tableStyle.columnActiveBtnStyle : tableStyle.columnDeActiveBtnStyle}>
            {val}
          </Text>
        </View>
      );
    }  else if (index == 0) {
      let id_Index = this.state.accountsArray.findIndex(x => x.id === item)
      let name = this.state.accountsArray[id_Index]['name']
      return (
        <TouchableOpacity style={styles.columnTitleViewStyle} onPress={() => this.viewAccountBtnAction(item)}>
          <Text style={tableStyle.themeTitleTxtStyle}> {name}  </Text>
        </TouchableOpacity>
      );
    }  else if (index == 2) {
      let fileURl = getThumbnailImage(item)
      return (
        <View style={styles.columnTitleViewStyle}>
          <Image style={tableStyle.itemImageViewStyle} source={item.length == 0 ? placeholder : fileURl} />
        </View>
      )
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.columnViewTitleStyle} numberOfLines={2}>{item}</Text>
        </View>
      );
    }
  }

  render() {
    if (this.state.viewAccountDetailBool) {
      return (<AccountDetails backBtnAction={() => this.setState({viewAccountDetailBool:false})} accountID={this.state.selectedID} />)
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <HeaderView
            title={`Accounts under ${this.props.categoryData['name']}`}
            backBtn={this.props.backBtnAction}
          />
          <View style={tableStyle.listContainerView}>
            <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
              <View>
                <View style={tableStyle.headerListContainer}>
                  <this.tableViewHeader />
                </View>
                <ScrollView >
                  <this.columnDataView />
                </ScrollView>
              </View>
            </ScrollView>
          </View>
          <Appload enable={this.state.dataLoad} />
        </SafeAreaView>
      )
    }
  }
}
const divideWidth = 6.3
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : windowWidth / divideWidth,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : windowWidth / divideWidth,
    borderBottomWidth: 1,
    borderBottomColor: colors.SimonGray,
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  iconStyle: {
    height: 15,
    width: 15,
  },
})

const tableHeaderString = [
  'Name',
  'Description',
  'Image',
  'Status',
  'Account Status',
  'Location',
]