import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  Image,
  Dimensions,
  TouchableOpacity,
} from 'react-native';

import colors from '../../HelperClasses/AppColor';
import nextIcon from '../../assets/nextArrowIcon.png';
import commonStyle from '../../StyleSheets/CreateTenantCommonStyleSheet'
import PropTypes from 'prop-types';
import TableCommonStyleSheet from '../../StyleSheets/TableCommonStyleSheet';

const windowWidth = Dimensions.get('window').width;
const windowheight = Dimensions.get('window').height;
export default class LogoHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static propTypes = {
    nextBtn: PropTypes.func,
    backBtn: PropTypes.func,
    pageNumber: PropTypes.string,
    progressValue: PropTypes.string,
    nextBtnTitle:PropTypes.string,
  };
  componentDidMount() {}
  render() {
    return (
      <View style={{flexDirection: 'column', marginTop: 50}}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <TouchableOpacity
            style={commonStyle.saveBtnStyle}
            onPress={this.props.backBtn}>
            <Image
              resizeMode={'center'}
              style={commonStyle.backIconStyle}
              source={nextIcon}
            />
            <Text style={TableCommonStyleSheet.saveTxtStyle}>Back</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={commonStyle.saveBtnStyle}
            onPress={this.props.nextBtn}>
            <Text style={TableCommonStyleSheet.saveTxtStyle}>{this.props.nextBtnTitle == undefined ? 'Next' : this.props.nextBtnTitle }</Text>
            <Image
              resizeMode={'center'}
              style={commonStyle.nextIconStyle}
              source={nextIcon}
            />
          </TouchableOpacity>
        </View>
        <View style={{height:40}}/>
        <View style={commonStyle.progressViewStyle}>
          <View
            style={{
              height: 8,
              width: this.props.progressValue,
              backgroundColor: colors.AppNewGreen,
              borderRadius: 5,
            }}></View>
        </View>
        <Text style={{marginTop: 10}}>Step {this.props.pageNumber} of 4</Text>
      </View>
    );
  }
}
