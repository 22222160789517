import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Platform,
  Alert,
  TouchableOpacity,
  SafeAreaView,
  Dimensions,
  Image,
  ScrollView,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import RightView from './TenantCommonClasses/RightCustomView';
import commonStyle from '../StyleSheets/CreateTenantCommonStyleSheet';
import HeaderView from './TenantCommonClasses/LogoHeader';
import free_Icon from '../assets/freeIcon.png';
import twilloOTP_Icon from '../assets/twilloOTPIcon.png';
import twilloPass_Icon from '../assets/twilloPassIcon.png';
import BottomView from './TenantCommonClasses/FooterView';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import {StackActions} from '@react-navigation/native';
import {getValue} from '../HelperClasses/UserPrefrences';
import AppConstants from '../Constants/AppConstants';

const APPURL = require('../Constants/URLConstants');
const windowHeight = Dimensions.get('window').height;
const contentFul = require('../Constants/ContentFullConstant');

let titleArray = ['Free', 'Buy Twilio', 'Buy Twilio',];
let imagesArray = [free_Icon, twilloOTP_Icon, twilloPass_Icon];
let subTitleArray = [
  '(Sign-up with email id)',
  '(Sign up using mobile number OTP + Sign in using OTP from mobile number)',
  '(Sign up using mobile number + Sign in using mobile number OTP)',
];

export default class RegistrationTypePlatform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRegistrationType: 1,
      dataLoad: true,
      count: 1,
      paymentCount: 1,
    };
  }
  componentDidMount() {
  }
  //MARK:-  APIs Method 
 
  addCurrencyApi = async () => {
    const {currencyData} = this.props.route.params;
    var dict = {
      name: currencyData['name'],
      default: true,
      active: true,
      order_by: 1,
      code: currencyData['code'],
      exchange_rate: 1,
      precision: currencyData['decimal_digits'],
      thousand_separator: ',',
      decimal_point: '.',
      format: `${currencyData['symbol_native']}{amount}`,
    };
    var path = APPURL.URLPaths.currencies;
    const responseJson = await networkService.networkCall(
      path, 'post', JSON.stringify({currency: dict}), '',  );
    if (responseJson['status'] == true) {
      this.addAppLanguageApi();
    } else {
      this.setState({dataLoad: true});
      this.alertView(responseJson);
    }
  };
  addAppLanguageApi = async () => {
    const {languageData} = this.props.route.params;
    var dict = {
      language_id: languageData['id'],
      name: languageData['name'],
      active: true,
      default: true,
      order_by: 1,
    };
    var path = APPURL.URLPaths.tanantslanguage;
    const responseJson = await networkService.networkCall(
      path, 'post',  JSON.stringify({language: dict}), '');
    if (responseJson['status'] == true) {
      const {shippingMethodsArray} = this.props.route.params;
      for (let obj of shippingMethodsArray){
        this.addShippingMethodApi(obj);
      }
     } else {
      this.setState({dataLoad: true});
     }
  };
  addShippingMethodApi = async (data) => {
    var dict = {
      shipping_method_id: data['id'],
      name: data['name'],
      active: true,
      default: true,
    };
    dict['order_by'] = 1;
    var path = APPURL.URLPaths.tenantShippingMethod;
    const responseJson = await networkService.networkCall(
      path, 'post',  JSON.stringify({shipping_method: dict}), '');
    if (responseJson['status'] == true) {
      const {shippingMethodsArray} = this.props.route.params;
      if (this.state.count == shippingMethodsArray.length) {
        const {paymentMethodsArray} = this.props.route.params;
        this.state.count = 1;
        for (let obj of paymentMethodsArray){
          this.addPaymentMethodApi(obj);
        }
      }
      this.state.count = this.state.count + 1;
    }else {
      this.setState({dataLoad: true});
    }
  };
  
  addPaymentMethodApi = async (data) => {
    var dict = {
      payment_method_id: data['id'],
      name: data['name'],
      active: true,
      default: true,
      min_amount : 1,
      max_amount : 100,
      order_by: 1,
    };
    var path = APPURL.URLPaths.tenantPaymentMethod;
    const responseJson = await networkService.networkCall(
      path, 'post',  JSON.stringify({payment_method: dict}), '');
    if (responseJson['status'] == true) {
      const {paymentMethodsArray} = this.props.route.params;
      if (this.state.paymentCount == paymentMethodsArray.length){
        // this.addAppLanguageApi();
        // this.addLocationMapApi();
        // this.addAuthApi()
        getValue('id', resp => {
          this.createBusinessApi(resp[0]);
        })
      }
      this.state.paymentCount = this.state.paymentCount + 1;;
    } else {
      this.setState({dataLoad: true});
    }
  };
 
  addLocationMapApi = async () => {
    // console.log('filesData =>', filesData);
    const {locationData} = this.props.route.params;
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config, 'post',
      JSON.stringify({configs: locationData}),
      '',
    );
    if (responseJson['status'] == true) {
      getValue('id', resp => {
        this.createBusinessApi(resp[0]);
      })
    } else {
      this.setState({dataLoad: true});
    }
  };
  createBusinessApi = async parameter => {
    const {businessData} = this.props.route.params;
    var dict = { 
      type:1,
      sub_type:businessData
    }
    console.log('createBusinessApi param', dict)
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.createTenant + `${parameter}`,'PATCH',  JSON.stringify({tenant: dict}), '');
    this.setState({dataLoad: true });
    if (responseJson['status'] == true) {
      this.addAuthApi()
    }
  };
  addAuthApi = async () => {
    var configs = [];
    let fDict2 = {
      key_group: 'onboarding',
      key: 'onboarding_completed',
      value: true,
      secured: false,
    };
    let fDict3 = {
      key_group: 'general',
      key: 'auth_type',
      value: 1,//this.state.selectedRegistrationType,
      secured: false,
    };
    configs.push(fDict2);
    configs.push(fDict3);
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config, 'post',
      JSON.stringify({configs: configs}),
      '',
    );
    if (responseJson['status'] == true) {
      AppConstants.appType = this.state.selectedRegistrationType == 1 ? false : true;
      this.setState({dataLoad: true});
      // this.props.navigation.navigate('FinalScreen');
      this.props.navigation.dispatch(StackActions.replace('SuperAdmin'));
    } else {
      this.setState({dataLoad: true});
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  nextBtnAction() {
    this.setState({dataLoad: false});
    this.addCurrencyApi();
  }
  didSelectSetting = id => {
    // console.log('id', id);
    this.setState({selectedRegistrationType: id});
  };
  //MARK:-  UI Method 

  renderBusinessView = () => {
    var view = [];
    for (let a = 1; a <= 3; a++) {
      view.push(
        <TouchableOpacity
          style={
            this.state.selectedRegistrationType == a
              ? commonStyle.highlightCustomViewStyle
              : commonStyle.customViewStyle
          }
          onPress={id => this.didSelectSetting(a)}>
          <Image
            style={commonStyle.iconImageViewStyle}
            resizeMode="contain"
            source={imagesArray[a - 1]}
          />
          <Text style={commonStyle.customViewTitleStyle}>{titleArray[a - 1]}</Text>
          <Text style={commonStyle.customViewsubTitleStyle}>
            {subTitleArray[a - 1]}
          </Text>
        </TouchableOpacity>,
      );
    }
    return view;
  };
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={styles.Container}>
          <View style={commonStyle.mainViewStyle}>
            <View style={commonStyle.leftViewStyle}>
              <HeaderView />
              <ScrollView style={{height: windowHeight - 100}}>
              <View style={commonStyle.contentViewStyle}>
                <ScrollView showsVerticalScrollIndicator={false}>
                  <View>
                    <Text style={commonStyle.contentTitleStyle}>
                      Select your platform registration type
                    </Text>
                    {/* <Text style={{color: colors.Appgray, marginTop: 10}}>
                      Lorem ipsum dolor sit amet, consectetur{'\n'}
                      adipiscing elit aenean massa.
                    </Text> */}
                    <View style={{marginTop: 30}} />
                    <View style={commonStyle.customViewContainer}>
                      <this.renderBusinessView />
                    </View>
                  </View>
                </ScrollView>
                <View>
                  <BottomView
                    nextBtn={() => this.nextBtnAction()}
                    backBtn={() => this.props.navigation.pop()}
                    pageNumber={'8'}
                    progressValue={'100%'}
                    nextBtnTitle={'Finish'}
                  />
                </View>
              </View>
              </ScrollView>
            </View>
            <View style={commonStyle.rightViewStyle}>
            <RightView title={contentFul.registerTitle} description={contentFul.registerDescription}/>
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />;
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});
