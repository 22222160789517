import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Image,
  Dimensions,
} from 'react-native';

import tlogo from '../../assets/tradlylogo.png';
import commonStyle from '../../StyleSheets/CreateTenantCommonStyleSheet'
const windowWidth = Dimensions.get('window').width;
const windowheight = Dimensions.get('window').height;
export default class LogoHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    return (
      <View style={commonStyle.headerViewStyle}>
        <View style={{margin: 20}} />
        <Image resizeMode={'contain'} style={styles.logoStyle} source={tlogo} />
        {/* <Text style={commonStyle.titleTextStyle}>Tradly</Text> */}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  logoStyle: {
    top: 2,
    width: 120,
    height: 80,
  },
});
