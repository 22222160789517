
import React,{Component} from 'react';
import {View,Text, SafeAreaView, StyleSheet} from 'react-native';

export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {  };
  }
  render() {
    return <View />
  }
}