import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Alert,
  Image,
  SafeAreaView,
  Dimensions,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import PropTypes from 'prop-types';
import {ReactComponent as TLogo} from './../assets/tradlyIconSVG.svg';
import {ReactComponent as VectorLine} from './../assets/vectorLine.svg';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default class TutorialView extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  static propTypes = {
    showTutorialView: PropTypes.any,
    closeBtnAction:PropTypes.func,
  }
  //MARK:-  Buttons Action Method 
  renderCloseBtnView = () => {
    return <TouchableOpacity  onPress={() => this.props.closeBtnAction() }>
      <Text style={CommonStyleSheet.commonBtnTxtStyle}>{'Close'}</Text>
    </TouchableOpacity>
  }
  render() {
    return (
      <View style={[styles.Container, { display: this.props.showTutorialView ? 'flex' : 'none' }]}>
        <View style={{backgroundColor:colors.AppWhite, height:500}}>
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 20 }}>
              <TLogo style={{ height: 26, width: 26 }} stroke={colors.AppWhite} />
              {this.renderCloseBtnView()}
            </View>
          </View>
          <View style={{ height: 100, width: '100%', alignSelf: 'flex-end' }}>
            <View style={{ width: '100%', height: 30 }}>
              <VectorLine style={{ width: windowWidth - 70, height: 30 }} />
            </View>
            <View style={{ backgroundColor: colors.cyanColor, height: 80, width: '100%', marginTop: -2 }} />
          </View>
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'red',
    zIndex:999999999999201,
    marginLeft:10,
    marginRight:140,
    position: 'absolute',
    zIndex: 9101991,
    height: windowHeight - 50,
    width: windowWidth - 70,
    backgroundColor: colors.transparentBlackColor,
    padding:50,
  },

});