import React, {Component, useCallback} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Dimensions,
  Platform,
  SafeAreaView,
  Alert,
} from 'react-native';

import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import AppConstants from '../../Constants/AppConstants';
import colors from '../../HelperClasses/AppColor';
import {AppToast} from '../../HelperClasses/AppToast';

const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
let KeyGroup = 'general';
// const uPref = new userPref();

export default class ForceToUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switchValue: false,
      androidVersion: '',
      iosVersion: '',
      dataLoad: false,
      updateUI:false,
    };
  }
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=general',
      'get','', '',
    );
    // console.log('configs', responseJson);
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      // console.log('this.state.configs =>', configs);
      this.setState({
        androidVersion: configs['app_android_version'] || '',
        iosVersion: configs['app_ios_version'] || '',
        // switchValue: configs['force_to_update'] || false ,
      });
      // console.log('this.state.configs =>', this.state.selectedImage);
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateAppDetailApi = async () => {
    var configs = [];
    let valueArray = [
      this.state.androidVersion,
      this.state.iosVersion,
      // this.state.switchValue,
    ];
    // var configs = [];
    for (let i = 0; i < keysArray.length; i++) {
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: false,
        };
        // console.log('fDict ==> ', fDict);
        configs.push(fDict);
      }
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
      '',
    );
    // console.log('configs', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.deleteKeysAPI(configs);
      // this.alertView('Uploaded successfully');
    } else {
      this.setState({dataLoad: true});
      this.alertView(responseJson);
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    // console.log('deleteParms', deleteParms);
    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms;
      const responseJson = await networkService.networkCall(
        urlParm,
        'delete',
        '',
        '',
      );
      // console.log('configs', responseJson);
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    } else {
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    }
  };

  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }

  //MARK:-  Buttons Action Method 
  saveBtnAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateAppDetailApi();
  }
  switchSattusBtnAction() {
    this.setState({switchValue: !this.state.switchValue});
  }

  render() {
    //this.props.navigation.closeDrawer();
    console.log('', this.state.updateUI);
    return (
      <SafeAreaView style={styles.Container}>
        <View style={{flexDirection: 'column'}}>
          <View style={appConfigStyle.mainView}>
            <Text style={tableStyle.listTitleText}> Force To Update </Text>
            <Text style={appConfigStyle.subTitleStyle}>
              {AppConstants.plainTextString}
            </Text>
            <View style={appConfigStyle.subViewStyle}>
              <View style={{flexDirection: 'column', flex: 1.5}}>
                <View style={{marginTop: 16}}>
                  <Text style={appConfigStyle.titleTxtStyle}>
                    Android app version
                  </Text>
                  <TextInput
                    value={this.state.androidVersion}
                    style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                    placeholder={'Enter android app version'}
                    onChangeText={name => this.setState({androidVersion: name})}
                  />
                </View>
                <View style={{marginTop: 16}}>
                  <Text style={appConfigStyle.titleTxtStyle}>
                    iOS app version
                  </Text>
                  <TextInput
                    value={this.state.iosVersion}
                    style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                    placeholder={'Enter iOS app version'}
                    onChangeText={name => this.setState({iosVersion: name})}
                  />
                </View>
              </View>
              <View style={appConfigStyle.lineViewStyle} />
              <View style={{flexDirection: 'column', flex: 1}} />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
    //}
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

const keysArray = ['app_android_version', 'app_ios_version'];
// const keysArray = ['app_android_version', 'app_ios_version', 'force_to_update'];
