import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
  Platform,
  SafeAreaView,
  Image,
  Animated,
  Easing,
  TextInput,
  Alert,
} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import edit_Icon from '../../assets/editIcon.png';
import deleteIcon from '../../assets/delete_icon.png';
import disableIcon from '../../assets/disableIcon.png';
import addIcon from '../../assets/add_icon.png';
import HelperLinkViews from '../../Component/HelperLinkView';
import PropTypes from 'prop-types';
import EmptyListUI from '../../Component/AppEmptyList';
import { ToastContainer } from 'react-toastify';
import { AppToast } from '../../HelperClasses/AppToast';
import AddShippingMedthods from './AddShippingMedthod';
import StorageHubLists from './StorageHubList';
import { Gradient } from 'react-gradient';
import Translations from '../Listings/Translation';
import { TranslateEnum } from '../../Models/TranslateEnum';
import { checkUserRoleModule } from '../../HelperClasses/SingletonClass';
import { MODULEKEYS } from '../../Models/Enum';
import AppConstants from '../../Constants/AppConstants';
import AppColor from '../../HelperClasses/AppColor';
import { addMixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import SettingStyleSheet from '../../StyleSheets/SettingStyleSheet';
import { ShipmentMethodEnum } from '../../Models/CommonEnum';
import settingGreenIcon from '../../assets/settingGreenIcon.png';
import StuartOnDemand from './StuartOnDemand';
import closeIcon from '../../assets/closeBlackIcon.png';
import Switch from "react-switch";
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import BackBtnView from '../../Component/BackBtnView';
import SaveButton from '../../Component/SaveButton';
import SidePanView from '../../Component/SidePanView';

const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;
var pageNo = 1;
let itsMobileView = windowWidth < fixedWidth ? true : false

const keyGroup = 'general'

export default class ShippingMethods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      selectedEntriesCount: 10,
      shippingMethodsArray: [],
      dateLoad: false,
      selectedShippingIndex: 0,
      isEdit: false,
      addShippingMethodBool: false,
      collectionID: 0,
      stopPage: false,
      haveData: false,
      selectedTabIndex: 0,
      addStorageHubBool: false,
      translationBool: false,
      translationTitle: '',
      translationRef: '',
      writeP: AppConstants.defaultWrite,
      deleteP: AppConstants.defaultDelete,
      stuartSettingShowBool: false,
      showConfig: false,
      showScheduleTimesConfig: false,
      showLoader:true,
      flatShippingFee:'',
    }
  }
  static propTypes = {
    backBtn: PropTypes.func,
  };
  componentDidMount() {
    this.loadShippingMethodsApi()
    this.loadConfigApi()
    this.checkModule()
    addMixpanelEvent(MixPannelEnum.shippingMethodsList, { 'type': MixPannelEnum.listView })
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.SHIPPINGMETHOD, res => {
      this.setState({ writeP: res[1], deleteP: res[2] })
    })
  }
  //MARK:-  APIS Method 
  loadShippingMethodsApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.tenantShippingMethod, 'get')
    this.setState({ shippingMethodsArray: [] });
    if (responseJson['status'] == true) {
      var objectD = responseJson['data']['shipping_methods']
      for (let i = 0; i < objectD.length; i++) {
        this.state.shippingMethodsArray.push(objectD[i])
      }
      this.state.haveData = this.state.shippingMethodsArray.length == 0 ? true : false
    }
    this.setState({ dateLoad: true })
  };
  deleteShippingMethodsApi = async id => {
    this.setState({ dateLoad: false })
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.tenantShippingMethod + '/' + id, 'delete')
    this.setState({ dateLoad: true });
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true)
      this.alertView('delete');
      this.loadShippingMethodsApi();
    } else {
      this.mixpanelInfo(false)
    }
  }
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + `?key_group=${keyGroup},shipping`,  'get')
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs']
      this.state.flatShippingFee = configs['flat_shipping_fee'] ?? ''
      this.setState({showScheduleTimesConfig: configs['schedule_time'] || false})
    }
    this.setState({dataLoad: true});
  }
  addOrUpdateConfigApi = async () => {
    this.setState({showLoader: false})
    var configs = [];
      let fDict = {
        key_group: keyGroup,
        key: keyStringArray[0],
        value: this.state.showScheduleTimesConfig,
        secured: false,
      }
      configs.push(fDict)
      if (this.state.flatShippingFee.length != 0) {
        let fDict2 = {
          key_group: 'shipping',
          key:  keyStringArray[1],
          value: this.state.flatShippingFee,
          secured: false,
        }
        configs.push(fDict2)
      }

      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.config,'post', JSON.stringify({configs: configs}) )
      this.setState({showLoader: true})
      if (responseJson['status'] == true) {
        this.deleteKeysAPI(configs)
      } else {
        this.setState({showLoader: true})
      }
  }
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keyStringArray.length; q++) {
      deleteParms.push(keyStringArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key']
      let index = deleteParms.indexOf(name)
      deleteParms.splice(index, 1)
    }

    let parms = deleteParms.toString();
    let urlParm = APPURL.URLPaths.config + '?key=' + parms;
    const responseJson = await networkService.networkCall(urlParm, 'delete')
    this.setState({showLoader: true});
    this.alertView()
  };
  mixpanelInfo(resp) {
    let properties = { success: resp }
    let event = `${MixPannelEnum.delete} ${MixPannelEnum.shippingMethods}`
    addMixpanelEvent(event, properties)
  }
  alertView(title) {
    AppToast(title)
  }
  //MARK:- Button Actions
  addShippingMethodsBtnAction() {
    this.setState({ addShippingMethodBool: !this.state.addShippingMethodBool})
    this.setState({ isEdit: false })
    this.loadShippingMethodsApi()
  }
  editButtonAction = index => {
    // console.log('index', index);
    let id_Index = this.state.shippingMethodsArray.findIndex(x => x.id === index);
    this.setState({ isEdit: true, selectedShippingIndex: id_Index });
    this.setState({ addShippingMethodBool: true });
  };
  dltShippingMethodBtnAction = index => {
    let msg = 'Do you want to go delete this Shipping Method';
    if (Platform.OS === 'web') {
      const r = window.confirm(msg);
      if (r == true) {
        this.deleteShippingMethodsApi(index);
      }
    }
  }
  saveConfigBtnAction() {
    this.addOrUpdateConfigApi()
  }
  flatFeeFieldsOnChange(value) {
    let reg = /^[0-9-.\b]+$/
    var txtValue = ''
    if (value == '' || reg.test(value)) {
      txtValue = value
    }
    this.state.flatShippingFee = txtValue
    this.setState({ updateUI: !this.state.updateUI })
  }
  /*  UI   */
  renderSwitch = () => {
    var views = []
    views.push(
      <View style={{ flexDirection: 'row', alignItems: 'center',justifyContent: 'space-between',paddingLeft:16,paddingRight:16,paddingTop:16}}>
        <View>
          <Text style={CommonStyleSheet.titleNameStyle}>{'Schedule Time'}</Text>
        </View>
        <View>
          <Switch
            onColor={colors.AppNewGreen}
            onChange={() => this.setState({ showScheduleTimesConfig: !this.state.showScheduleTimesConfig })}
            checked={this.state.showScheduleTimesConfig}
            height={22}
            width={50}
          />
        </View>
      </View>)
    return views
  }

  renderSturatConfigView = () => {
    return (<View style={{ marginTop: 30 }}>
      <StuartOnDemand />
    </View>)
  }

  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{ margin: 0 }}
      />
    );
  };
  tableHeaderCell = ({ item, index }) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    if (this.state.shippingMethodsArray.length == 0) {
      if (this.state.haveData) {
        return <EmptyListUI />
      } else {
        return <View />
      }
    } else {
      var views = [];
      for (let i = 0; i < this.state.shippingMethodsArray.length; i++) {
        views.push(
          <FlatList
            data={[
              this.state.shippingMethodsArray[i]['name'],
              this.state.shippingMethodsArray[i]['default'],
              this.state.shippingMethodsArray[i]['order_by'],
              this.state.shippingMethodsArray[i]['active'],
              this.state.shippingMethodsArray[i]['id'],
            ]}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            style={{ borderBottomWidth: 1, borderBottomColor: colors.SimonGray, backgroundColor: 'white' }}
          />,
        );
      }
      return views;
    }
  };
  columnCell = ({ item, index }) => {
    if (index == 3 || index == 1) {
      return (<View style={styles.columnTitleViewStyle}>
        <Text style={item == false ? tableStyle.columnDeActiveBtnStyle : tableStyle.columnActiveBtnStyle}>
          {index == 1 ? item ? 'Yes' : 'No' : item ? 'Active' : 'Inactive'}
        </Text>
      </View>)
    } else if (index == 4) {
      let id_Index = this.state.shippingMethodsArray.findIndex(x => x.id === item);
      var views = [];
      if (id_Index != -1) {
        if (ShipmentMethodEnum.storageHub == this.state.shippingMethodsArray[id_Index]['type']) {
          views.push(<View style={{ height: 20, width: 20 }}>
            <TouchableOpacity disabled={!this.state.writeP} onPress={() => this.setState({ addStorageHubBool: true })}>
              <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.writeP ? addIcon : disableIcon} />
            </TouchableOpacity> </View>)
        } else if (ShipmentMethodEnum.stuart == this.state.shippingMethodsArray[id_Index]['type']) {
          views.push(<View>
            {this.renderSettingBtn()}
          </View>)
        }
      }
      return (<View style={styles.columnTitleViewStyle}>
        <View style={{ flexDirection: 'row', justifyContent: 'flex-start', width: 80, marginLeft: 0 }}>
          {this.renderEditBtn(item)}
          <View style={{ width: 10 }} />
          {this.renderDeleteBtn(item)}
          <View style={{ width: 10 }} />
          {views}
        </View>
      </View>)
    } else if (index == 0) {
      return (<View style={styles.columnTitleViewStyle}>
        <Text style={tableStyle.mainTitleStyle}>{item}</Text>
      </View>)
    } else {
      return (<View style={styles.columnTitleViewStyle}>
        <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
      </View>)
    }
  }
  renderEditBtn = (item) => {
    return <TouchableOpacity disabled={!this.state.writeP} onPress={() => this.editButtonAction(item)}>
      <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.writeP ? edit_Icon : disableIcon} />
    </TouchableOpacity>
  }
  renderDeleteBtn = (item) => {
    return <TouchableOpacity disabled={!this.state.deleteP} onPress={() => this.dltShippingMethodBtnAction(item)}>
      <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.deleteP ? deleteIcon : disableIcon} />
    </TouchableOpacity>
  }
  renderSettingBtn = (item) => {
    return <TouchableOpacity disabled={!this.state.deleteP} onPress={() => this.setState({ stuartSettingShowBool: true })}>
      <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.deleteP ? settingGreenIcon : disableIcon} />
    </TouchableOpacity>
  }
  renderMainView = () => {
    return (<View style={tableStyle.listContainerView}>
      <ToastContainer />
      <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
        <View>
          <View style={tableStyle.headerListContainer}>
            <this.tableViewHeader />
          </View>
          <ScrollView style={styles.tableViewHeaderStyle}>
            <this.columnDataView />
          </ScrollView>
        </View>
      </ScrollView>
    </View>);
  };
  renderHelperView = () => {
    if (this.state.selectedTabIndex == 0) {
      return (<View>
        <HelperLinkViews title={'Shipping Methods'} />
      </View>)
    } else {
      return <View />;
    }
  }
  renderTranslationBtn = () => {
    return <View style={{ display: this.state.writeP ? 'flex' : 'none' }}>
      <View style={tableStyle.secondButtonViewStyle}>
        <TouchableOpacity onPress={() => this.setState({ translationBool: true })}>
          <Text style={tableStyle.secondBtnTextStyle}>{'Translate Shipping'}</Text>
        </TouchableOpacity>
      </View>
    </View>
  }
  renderConfigBtn = () => {
    return <View style={{ display: this.state.writeP ? 'flex' : 'none' }}>
      <View style={tableStyle.secondButtonViewStyle}>
        <TouchableOpacity onPress={() => this.setState({ showConfig: true })}>
          <Text style={tableStyle.secondBtnTextStyle}>{'Config'}</Text>
        </TouchableOpacity>
      </View>
    </View>
  }
  renderAddShippingBtn = () => {
    return <View style={{ display: this.state.writeP ? 'flex' : 'none' }}>
      <SaveButton saveBtn={() => this.addShippingMethodsBtnAction()} saveBtnTitle={'Add Shipping Method'} />
    </View>
  }
  renderSaveConfigBtn = () => {
    return <View style={{ display: this.state.writeP ? 'flex' : 'none' }}>
      <SaveButton saveBtn={() => this.saveConfigBtnAction()} saveBtnTitle={'Save'} />
    </View>
  }
  renderConfigsView = () => {
    if (this.state.addShippingMethodBool) {
      return <SidePanView
        dissmissView={() => this.setState({ addShippingMethodBool: !this.state.addShippingMethodBool })}
        showSidepanView={this.state.addShippingMethodBool}
        customView={<View>
          <AddShippingMedthods
            isEdit={this.state.isEdit}
            shppingData={this.state.shippingMethodsArray[this.state.selectedShippingIndex]}
            backBtnAction={() => this.addShippingMethodsBtnAction()}
          />
        </View>}
        title={this.state.isEdit ? "Edit Shipping Method" : 'Add Shipping Method'}
      />
    } else if (this.state.stuartSettingShowBool) {
      return <SidePanView
        dissmissView={() => this.setState({ stuartSettingShowBool: !this.state.stuartSettingShowBool })}
        showSidepanView={this.state.stuartSettingShowBool}
        customView={<View>
          <StuartOnDemand />
        </View>}
        title={'Stuart Configs'}
      />
    }  else if (this.state.showConfig) {
      return <SidePanView
        dissmissView={() => this.setState({ showConfig: !this.state.showConfig })}
        showSidepanView={this.state.showConfig}
        customView={<View>
          <View style={CommonStyleSheet.sidepanSaveBtnViewStyle}>
            {this.renderSaveConfigBtn()}
          </View>
          <View style={{flex:1}}>
            <View style={CommonStyleSheet.sidepanmViewStyle}>
              <View style={CommonStyleSheet.configContainerViewStyle}>
                <Text style={CommonStyleSheet.titleNameStyle}>Flat Shipping Fee</Text>
                <TextInput
                  style={[CommonStyleSheet.txtFieldStyle, { outline: 0, marginLeft: 0, width: '100%' }]}
                  value={this.state.flatShippingFee}
                  placeholder="Enter flat shipping fee"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.flatFeeFieldsOnChange(name)}
                />
              </View>
              {this.renderSwitch()}
            </View>
          </View>
          <Appload enable={this.state.showLoader} />
        </View>}
        title={'Service / Shipping Configs'}
      />
    } else { return <View /> }
  }
  render() {
    if (this.state.addStorageHubBool) {
      return (
        <View>
          <ToastContainer />
          <StorageHubLists backBtnAction={() => this.setState({ addStorageHubBool: false })} />
        </View>
      );
    } else if (this.state.translationBool) {
      return (
        <ScrollView style={{ flexDirection: 'column' }}>
          <ToastContainer />
          <Translations
            translationTitle={'Shipping Method'}
            translationRef={TranslateEnum.shippingMethod}
            backBtnAction={() => this.setState({ translationBool: false })} />
        </ScrollView>
      )
    } else {
      return (<SafeAreaView style={styles.Container}>
        <View style={{ zIndex: 101 }}>
          {this.renderConfigsView()}
        </View>
        <View style={{ zIndex: 1 }}>
          <View style={tableStyle.navigationViewStyle}>
            <View style={tableStyle.headerContainer}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <TouchableOpacity style={tableStyle.backBtnBackViewStyle} onPress={this.props.backBtn}>
                  <BackBtnView />
                </TouchableOpacity>
                <View style={SettingStyleSheet.headerViewStyle}  >
                  <Text style={tableStyle.titleTextStyle}>{`Service / Shipping Methods`}</Text>
                </View>
              </View>
              <View style={{ paddingLeft: 40, flexDirection: 'row' }}>
                {this.renderConfigBtn()}
                {this.renderTranslationBtn()}
                {this.renderAddShippingBtn()}
              </View>
            </View>
          </View>
          <View style={{flex:1}}>
            <this.renderMainView />
            <this.renderHelperView />
          </View>
          <Appload enable={this.state.dateLoad} />
        </View>
        {/* <this.renderHelperView /> */}
      </SafeAreaView>
      );
    }
  }
}
const customWidth = 5.0
const styles = StyleSheet.create({
  Container: {
    backgroundColor: AppColor.AppLightGreen,
    flex: 1,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: itsMobileView ? 130 : windowWidth / customWidth,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  columnTitleViewStyle: {
    width: itsMobileView ? 130 : windowWidth / customWidth,
    justifyContent: 'flex-start',
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  configViewContainerStyle:{
    borderRadius:10,
    borderColor:colors.SimonGray, 
    borderWidth:1,
    padding:10,
    marginTop:30,
    backgroundColor:colors.AppWhite
  }
});

const tableHeaderString = [
  'Name',
  'Default',
  'Order',
  'Status',
  'Action',
];
let keyStringArray = [
  'schedule_time',
  'flat_shipping_fee',
];