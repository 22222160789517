import React, { Component } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Platform,
  Alert,
  Dimensions,
  ScrollView,
  Image,
} from 'react-native';
import PropTypes from 'prop-types';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import { AppToast } from '../../HelperClasses/AppToast';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import colors from '../../HelperClasses/AppColor';
import hideIcon from '../../assets/hideEye.png';
import showIcon from '../../assets/showEye.png';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import { Gradient } from 'react-gradient';
import SaveButton from '../../Component/SaveButton';

const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'payments';

const windowHeight = Dimensions.get('window').height - 120;


export default class BillPlzIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      apiKey: '',
      signatureKey: '',
      hideApiKey:false,
      hideSecretKey:false,
    }
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi()
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config + '?key_group=payments', 'get')
    this.setState({ currencyArray: [] })
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs']
      this.setState({
        apiKey: configs['billplz_api_key'] || '',
        signatureKey: configs['billplz_signature_key'] || '',
      })

    }
    this.setState({ dataLoad: true })
  };

  addOrUpdateAppDetailApi = async () => {
    var configs = [];
    var valueArray = [this.state.apiKey,this.state.signatureKey]
    for (let i = 0; i < keysArray.length; i++) {
      let scValue = true
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: scValue,
        };
        configs.push(fDict)
      }
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config, 'post',  JSON.stringify({configs: configs}) )
    this.setState({ dataLoad: true });
    if (responseJson['status'] == true) {
      this.deleteKeysAPI(configs);
    } else {
      this.deleteKeysAPI(configs)
      this.setState({dataLoad: true});
      this.alertView(responseJson);
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    let parms = deleteParms.toString();
    let urlParm = APPURL.URLPaths.config + '?key=' + parms;
    const responseJson = await networkService.networkCall(urlParm, 'delete')
    this.setState({ dataLoad: true });
    this.alertView('Uploaded successfully');
  }
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  submitBtnAction() {
    this.setState({dataLoad: false})
    this.addOrUpdateAppDetailApi()
  }
  //MARK:-  UIs 
  renderSaveConfigBtn = () => {
    return <View>
      <SaveButton saveBtn={() => this.submitBtnAction()} saveBtnTitle={'Save'} />
    </View>
  }
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={commonStyle.sidepanSaveBtnViewStyle}>
          {this.renderSaveConfigBtn()}
        </View>
        <ScrollView style={{ height: windowHeight / 1.18 }}>
        <View style={commonStyle.sidepanmViewStyle}>
            <View style={{ flex: 1 }}>
              <View style={commonStyle.configContainerViewStyle}>
                <Text style={commonStyle.titleNameStyle}>API Key</Text>
                <TextInput
                  style={[commonStyle.txtFieldStyle, { outline: 0, width: '100%', marginLeft: 0 }]}
                  placeholder="API Key"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({ apiKey: name })}
                  value={this.state.apiKey}
                />
              </View>
              <View style={{ height: 20 }} />
              <View style={commonStyle.configContainerViewStyle}>
                <Text style={commonStyle.titleNameStyle}>X Signature Key</Text>
                <TextInput
                  style={[commonStyle.txtFieldStyle, { outline: 0, width: '100%', marginLeft: 0 }]}
                  placeholder="X Signature Key"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({ signatureKey: name })}
                  value={this.state.signatureKey}
                />
              </View>
            </View>
          </View>
        </ScrollView>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    )
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
  },
});

let keysArray = [
  'billplz_api_key',
  'billplz_signature_key',
];
