
import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  TextInput,
  Image,
  FlatList,
  SafeAreaView,
  Platform,
  Dimensions,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import ImagePicker from 'react-native-image-crop-picker';
import ReactFileReader from 'react-file-reader';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../../Settings/HeaderView';
import { AppToast } from '../../HelperClasses/AppToast';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import MediaManagerView from '../../Component/MediaManagerView';
import { onHandleDecimalNumberFieldChange, onHandleNumberFieldChange } from '../../HelperClasses/SingletonClass';
import Switch from "react-switch";
import jsonIcon from '../../assets/jsonIcon.png';
import pdfIcon from '../../assets/pdfICon.png';
import AppColor from '../../HelperClasses/AppColor';
import { addMixpanelEvent } from '../../Models/MixPannelEnum';



var slugify = require('slugify')
const APPURL = require('../../Constants/URLConstants');
var slugify = require('slugify')

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
var pageNo = 1;

let fixedWidth = 700;
let itsMobileView = windowWidth < fixedWidth ? true : false


export default class AddAddons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedImage: null,
      status: true,
      title: '',
      dataLoad: false,
      id: '',
      updatingBool: false,
      translationBool: false,
      updateUI: false,
      addAttributeBool: false,
      showMediaManager: false,
      mediaArray: [],
      showStock:false,
      numberFieldArray:['','','',''],
    }
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    addonsData: PropTypes.any,
    isEdit: PropTypes.bool,
    type: PropTypes.string,
    closeBtnAction: PropTypes.func,
  }
  componentDidMount() {
    this.loadMediaAPi()
    const addData = this.props.addonsData;
    const isEdit = this.props.isEdit;
    if (isEdit) {
      if (!this.state.updatingBool) {
        this.setState({
          title: addData['title'],
          status: addData['active'],
          id: addData['id'],
          selectedImage: addData['images'][0] ?? '',
          updatingBool: true,
        })
      }
      this.state.numberFieldArray[0] = addData['list_price'] ?? ''
      this.state.numberFieldArray[1] = addData['offer_percent'] ?? ''
      this.state.numberFieldArray[2] = addData['max_quantity'] ?? ''
      this.state.numberFieldArray[3] = addData['stock'] ?? ''
    }
    this.loadConfigApi()
  }
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config + '?key_group=listings', 'get')
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        showStock: configs['enable_stock'] ?? false,
      })
    }
    this.setState({ dataLoad: true })
  }
  loadMediaAPi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.media + `?page=${pageNo}&parent=0`, 'get');
    this.setState({ mediaArray: [] })
    if (responseJson['status'] == true) {
      var collectionD = responseJson['data']['media']
      if (collectionD.length != 0) {
        this.setState({ mediaArray: [] })
        this.setState({ stopPage: false })
        for (let obc of collectionD) {
          this.state.mediaArray.push(obc)
        }
      } else {
        pageNo = pageNo > 1 ? pageNo - 1 : 1
        this.setState({ stopPage: true })
      }
    }
    this.setState({ dataLoad: true })
  }

  addAddonsApi = async image => {
    var dict = {
      title: this.state.title,
      active: this.state.status,
    }
    if (image.length != 0) {
      dict['images'] = [image]
    }
    let keysAry = ['list_price','offer_percent','max_quantity','stock']
    for(let a = 0; a < this.state.numberFieldArray.length; a++){
      if(this.state.numberFieldArray[a].length != 0){
        dict[[keysAry[a]]] = this.state.numberFieldArray[a]
      }
    }
   
    var path = APPURL.URLPaths.addons

    if (this.state.id !== '') {
      path = APPURL.URLPaths.addons + '/' + this.state.id;
    }
    const responseJson = await networkService.networkCall(
      path, this.state.id !== '' ? 'PATCH' : 'POST', JSON.stringify({ addon: dict }))
    this.setState({ dataLoad: true })
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true)
      this.customAlert()
      this.props.backBtnAction(true)
    } else {
      this.mixpanelInfo(false)
      AppToast(responseJson)
    }
  }
  mixpanelInfo(resp) {
    let properties = { success: resp }
    addMixpanelEvent(`${this.state.id !== '' ? 'Edit' : 'Add'} Addons`, properties)
  }
  uploadImageAPI = async () => {
    var imgParm = [];
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      if (fileName != null) {
        var dict = {
          name: this.state.selectedImage['fileList'][0]['name'].replace(/ /g, ''),
          type: this.state.selectedImage['fileList'][0]['type'],
        };
        imgParm.push(dict);
      }
    }
    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL, 'POST', JSON.stringify({ files: imgParm }))
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        fetch(this.state.selectedImage.base64).then(async res => {
          const file_upload_res = await networkService.uploadFileWithSignedURL(
            result[0]['signedUrl'],
            imgParm[0]['type'],
            await res.blob(),
          )
          this.addAddonsApi(result[0]['fileUri']);
        })
      } else {
        this.setState({ dataLoad: true });
      }
    } else {
      this.addAddonsApi(this.state.selectedImage != null ? this.state.selectedImage : '')
    }
  }
  customAlert() {
    AppToast()
  }

  //MARK:-  Button Actions
  submitBtnAction = () => {
    if (this.state.title.length == 0) {
      AppToast('Please enter title')
    } else if (this.state.numberFieldArray[0].length == 0) {
      AppToast('Please enter price')
    } else {
      this.setState({ dataLoad: false })
      this.uploadImageAPI()
    }
  }
  onPick = image => {
    this.setState({ selectedImage: image });
  }
  showMediaBtnAction() {
    if (this.state.mediaArray != 0) {
      this.setState({ showMediaManager: true })
    } else {
      AppToast('You dont have media file.');
    }
  }

  textOnChange = (text,id) =>{
    let value = id == 0 ? onHandleDecimalNumberFieldChange(text)  : onHandleNumberFieldChange(text)
    if (value.length != 0 || text == 0) {
      this.state.numberFieldArray[id] = value
    }
    this.setState({ updateUI: !this.state.updateUI })
  }

  // UI Renders

  renderNumberFields = () => {
    var views = []
    var heading = ['Price', 'Offer Percentage', 'Maximum Quantity']
    var valueAry = [... this.state.numberFieldArray]
    if (this.state.showStock) {
      heading.push('Stock')
    }
    for (let a = 0; a < heading.length; a++) {
      var labels = []
      if (a == 0) {
        labels.push(<View><this.titleLblRender title={heading[a]} /></View>)
      } else { labels.push(<View> <Text style={commonStyle.titleNameStyle}>{heading[a]}</Text> </View>) }

      views.push(<View>
        <View style={{ height: 20 }} />
        <View style={commonStyle.subViewStyle}>
          {labels}
          <View style={{ width: itsMobileView ? '100%' : '80%' }}>
            <TextInput
              style={[commonStyle.fulltxtFieldStyle, { outline: 0 }]}
              value={valueAry[a]}
              placeholder={`Enter ${heading[a]}`}
              placeholderTextColor={colors.placeholderColor}
              onChangeText={txt => this.textOnChange(txt,a)}
            />
          </View>
        </View>
      </View>)
    }
    return views
  }
  imagePicker = props => {
    ImagePicker.openPicker({
      width: 300,
      height: 300,
      cropping: true,
      includeBase64: true,
    }).then(image => {
      // console.log('image -=== ', image);
      this.setState({ selectedImage: 'data:image/png;base64,' + image.data });
    });
  };
  imageView = props => {
    var view = [];
    view.push(<View style={{ flexDirection: itsMobileView ? 'column' : 'row' }}>
      <View style={commonStyle.addImageViewStyle}>
        <ReactFileReader
          fileTypes={['.png', '.jpg', '.gif']}
          base64={true}
          handleFiles={this.onPick}>
          <Text >Add Photo</Text>
        </ReactFileReader>
      </View>
      <View style={{ width: 20, height: 20 }} />
      <View style={commonStyle.addImageViewStyle}>
        <TouchableOpacity style={{ width: 100 }} onPress={() => this.showMediaBtnAction()}>
          <Text style={{ width: 100, textAlign: 'center' }}>Select from media</Text>
        </TouchableOpacity>
      </View>
      <View style={{ width: 20, height: 20 }} />
    </View>);
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      var showImage = true;
      var icon = jsonIcon
      var fileExt = ''
      if (fileName == undefined) {
        let name = this.state.selectedImage.substring(this.state.selectedImage.lastIndexOf('/') + 1)
        var fileExt = name.split('.').pop();
      }
      if (fileExt == 'json') {
        icon = jsonIcon
        showImage = false
      } else if (fileExt == 'pdf') {
        icon = pdfIcon
        showImage = false
      }
      view.push(<View>
        <Image
          source={showImage ? { uri: fileName != null ? this.state.selectedImage.base64 : this.state.selectedImage } : icon}
          style={commonStyle.ImageViewStyle}
        />
      </View>
      );
    }
    return (<View style={{ flexDirection: itsMobileView ? 'column' : 'row' }}>
      {view}
    </View>)
  }
  mediaManagerView = () => {
    if (this.state.showMediaManager) {
      return (<View style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 9920 }}>
        <MediaManagerView
          mediaData={this.state.mediaArray}
          closeBtn={() => this.setState({ showMediaManager: false })}
          onSelectBtn={path => this.setState({ showMediaManager: false, selectedImage: path })} />
      </View>)
    } else {
      return <View />
    }
  }
  titleLblRender = ({ title }) => {
    return (
      <View>
        <Text style={commonStyle.titleNameStyle}>
          {title}
          <Text style={{ color: 'red', paddingTop: 5 }}> *</Text>
        </Text>
      </View>
    )
  }


  render() {
    const isEdit = this.props.isEdit;
      return (
        <SafeAreaView style={styles.Container}>
          <Header_View
            title={isEdit ? 'Edit Addons' : 'Add Addons'}
            backBtn={this.props.backBtnAction}
            saveBtnTitle={'Save'}
            saveBtn={() => this.submitBtnAction()}
          />
          <View style={{ height: windowHeight / 1.16 }}>
            <ScrollView>
              <View style={commonStyle.mainView}>
                {this.mediaManagerView()}
                <View style={{ height: 20, width: '100%' }} />
                <View style={commonStyle.subViewStyle}>
                  <this.titleLblRender title={'Title'} />
                  <View style={{ width: itsMobileView ? '100%' : '80%' }}>
                    <TextInput
                      style={[commonStyle.fulltxtFieldStyle, { outline: 0 }]}
                      value={this.state.title}
                      placeholder="Enter title"
                      placeholderTextColor={colors.placeholderColor}
                      onChangeText={name => this.setState({ title: name })}
                    />
                  </View>
                </View>
               {this.renderNumberFields()}
                <View style={{ height: 20 }} />
                <View style={commonStyle.subViewStyle}>
                  <Text style={commonStyle.titleNameStyle}>Status(Not Active / Active) </Text>
                  <View style={{ width: itsMobileView ? '12%' : '80%' }}>
                    <Switch
                      onColor={colors.AppNewGreen}
                      onChange={() => this.setState({ status: !this.state.status })}
                      checked={this.state.status}
                      height={22}
                      width={50}
                    />
                  </View>
                </View>
                <View style={{ height: 20 }} />
                <View style={commonStyle.subViewStyle}>
                  <Text style={commonStyle.titleNameStyle}>Upload Media</Text>
                  <View style={{ width: '80%' }}>
                    <this.imageView />
                  </View>
                </View>
                <View style={{ height: 20}} />
              </View>
            </ScrollView>
          </View>
          <Appload enable={this.state.dataLoad} />
        </SafeAreaView>
      );
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: AppColor.AppLightGreen,
  },
  addAttributeGradientStyle: {
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 5,
    height: 40,
    width: 120,
    borderRadius: 10,
    justifyContent: 'center'
  },
  subViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginBottom: 5,
    zIndex: 10292,
  },
  dropDownViewStyle: {
    marginTop: 16,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    height: 40,
    borderRadius: 5,
    flexDirection: 'column',
    alignItems: 'center',
    borderColor: colors.AppGreenBorder,
    width: itsMobileView ? '100%' : '100%',
  },
  subViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginBottom: 5,
    zIndex: 10292,
  },
})
