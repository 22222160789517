
export var TranslateEnum = {
  category:'category',
  attribute:'attribute',
  listingVariant:'listing_variant',
  shippingMethod:'shipping_method',
  paymentMethod:'payment_method',
  attributeValue:'attribute_value',
  variantType:'variant_type',
  variantTypeValue:'variant_type_value',
}