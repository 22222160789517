import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  TextInput,
  Image,
  FlatList,
  SafeAreaView,
  Platform,
  Dimensions,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../Settings/HeaderView';
import {AppToast} from '../HelperClasses/AppToast';
import commonStyle from '../StyleSheets/CommonStyleSheet';
import downArrow_Icon from '../assets/downArrowIcon.png';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import ReactFileReader from 'react-file-reader';
import blackCrossIcon from '../assets/blackCrossIcon.png';
import upload_Icon from '../assets/uploadIcon.png';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import SelectSearchView from 'react-select';
import {ToastContainer} from 'react-toastify';
import MediaManagerView from '../Component/MediaManagerView';
import DescriptionView from '../Component/DescriptionView';
import Moment from 'moment';
// import DatePicker from 'react-custom-date-picker';
import {SketchPicker} from 'react-color';
import MapPicker from 'react-google-map-picker';
import DatePicker from 'react-datepicker';
import jsonIcon from '../assets/jsonIcon.png';
import pdfIcon from '../assets/pdfICon.png';
import closeIcon from '../assets/closeIcon.png';
import Switch from 'react-switch';
import calendarIcon from '../assets/calendarIcon.png';
import deleteIcon from '../assets/delete_icon.png';

import 'react-datepicker/dist/react-datepicker.css';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import previewStyleSheet from '../StyleSheets/PreviewStyleSheet';
import {addMixpanelEvent, MixPannelEnum} from '../Models/MixPannelEnum';
import {Gradient} from 'react-gradient';
import {
  changeDateFormat,
  convertTimeinto12Hrs,
  convertTimeinto24Hrs,
  getTimeArray,
  getTimeDifferenceArray,
} from '../HelperClasses/SingletonClass';
import AppConstants, {moduleType} from '../Constants/AppConstants';
import {ModuleType} from '../Models/ModuleType';
import ConstantArray from '../Constants/ConstantArray';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import TextTitleStyleSheet from '../StyleSheets/TextTitleStyleSheet';
import ToolTipViewBtn from '../Component/ToolTipViewBtn';
import FilePicker from '../Component/FilePicker';
import backIcon from '../assets/backIcon.png';
import styles from '../StyleSheets/NewStyleSheet';
import SaveButton from '../Component/SaveButton';

var slugify = require('slugify');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let fixedWidth = AppConstants.mobileMaxWidth;
let dateFormat = 'yyyy-MM-DD HH:mm:ss';
var dt = new Date();
let currentDate = Moment(dt).format(dateFormat);
const APPURL = require('../Constants/URLConstants');
var pageNo = 1;
const DefaultLocation = {lat: 10, lng: 106};
const DefaultZoom = 10;

let itsMobileView = windowWidth < fixedWidth ? true : false;

export default class AddListings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: [false, false],
      showAttributeDropDown: false,
      status: false,
      default: false,
      dataLoad: false,
      updateUI: false,
      id: '',
      rank: '',
      currencyArray: [],
      categoriesArray: [],
      updatingBool: false,
      selectedCurrency: '',
      selectedCurrencyCODE: '',
      selectedCurrencyID: '',
      selectedCategory: '',
      selectedCategoryID: '',
      descriptionString: '',
      titleString: '',
      code: '',
      regexp: /^[0-9.\b]+$/,
      listPriceValue: '',
      stockValue: '',
      offerPercentage: '',
      maxQuantity: '',
      imagesArray: [],
      tags: [],
      attributesArray: [],
      selectedAttributeArray: [],
      uploadAttributeImagesURL: [],
      pictureCount: 1,
      showStock: false,
      showMAxQty: false,
      showOffer: false,
      showTag: false,
      mediaArray: [],
      showMediaManager: false,
      descriptionHeight: 40,
      usedLines: 1,
      height: 40,
      showColorPicker: [],
      latitude: 0.0,
      longitude: 0.0,
      jsonFile: [],
      showMapView: false,
      startDate: new Date(),
      calenderOn: false,
      selectedCategoryArray: [],
      attributeImageIndex: -1,
      attributeImageID: 0,
      slugValue: '',
      showSlug: false,
      accountID: '',
      metaTitle: '',
      metaDescriptionString: '',
      showAddScheduleViewBool: false,
      scheduleStartDate: new Date(),
      scheduleEndDate: new Date(),
      showScheduleRepeatBool: false,
      selectedRepeatIndex: -1,
      selectedWeekDay: [],
      scheduleArray: [],
      showSlotBool: false,
      showTimeBool: [false, false],
      scheduleStartTime: '09:00 am',
      scheduleEndTime: '05:00 pm',
      selectedSlot: slotArray[3]['name'],
      showMetaTitle: false,
      showMetaDescription: false,
      showMetaKeyboard: false,
      saveAsDraft: false,
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    languageData: PropTypes.any,
    isEdit: PropTypes.bool,
    listingDetailData: PropTypes.any,
  };
  componentDidMount() {
    this.loadMediaAPi();
    if (this.props.isEdit) {
      this.getListingData();
    }
    this.loadCurrencyApi();
    this.loadCategoryApi();
    this.loadConfigApi();
  }
  loadMediaAPi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.media + `?page=${pageNo}&parent=0`,
      'get',
      '',
      '',
    );
    this.setState({mediaArray: []});
    if (responseJson['status'] == true) {
      var mediaD = responseJson['data']['media'];
      if (mediaD.length != 0) {
        this.setState({mediaArray: []});
        this.setState({stopPage: false});
        for (let obc of mediaD) {
          this.state.mediaArray.push(obc);
        }
      } else {
        pageNo = pageNo > 1 ? pageNo - 1 : 1;
        this.setState({stopPage: true});
      }
    }
    this.setState({dataLoad: true});
  };
  getListingData() {
    if (this.props.listingDetailData) {
      console.log('comming here ', this.props.listingDetailData);
      let listingDetailData = this.props.listingDetailData;
      this.state.id = listingDetailData['id'];
      this.state.titleString = listingDetailData['title'];
      this.state.descriptionString = listingDetailData['description'];
      this.state.slugValue = listingDetailData['slug'];

      if (listingDetailData['list_price']) {
        this.state.listPriceValue = listingDetailData['list_price']['amount'];
      }
      this.state.offerPercentage = listingDetailData['offer_percent'];
      this.state.stockValue = listingDetailData['stock'];
      this.state.maxQuantity = listingDetailData['max_quantity'];
      this.state.selectedCurrencyID = listingDetailData['currency_id'];
      this.state.metaTitle = listingDetailData['meta_title'];
      this.state.metaDescriptionString = listingDetailData['meta_description'];
      // this.state.scheduleArray =
      let schedules = listingDetailData['schedules'];
      console.log('schedules', schedules);
      for (let scObj of schedules) {
        let timeArray = [];
        let repeatClass = scObj['repeat_days'];
        var repeatIDs = '1,7';
        var repeatName = '';
        var repeatIndex = 0;
        repeatIDs = repeatClass.toString();
        let index = ConstantArray.repeatArray.findIndex(x => x.id == repeatIDs);
        if (index == -1) {
          repeatIndex = 3;
          var nameary = [];
          for (let objc of repeatClass) {
            let ind = ConstantArray.weekDays.findIndex(x => x.id == objc);
            nameary.push(ConstantArray.weekDays[ind].name);
          }
          if (nameary.length != 0) {
            repeatName = nameary.toString();
          }
        } else {
          repeatIndex = index;
          repeatName = ConstantArray.repeatArray[index].name;
        }
        let reactDic = {
          name: repeatName,
          ids: repeatIDs,
        };
        let timeDic = {
          start: convertTimeinto12Hrs(scObj['start_time']),
          end: convertTimeinto12Hrs(scObj['end_time']),
        };
        let dict = {
          startDate: scObj['start_date'],
          startTime: scObj['start_time'],
          endTime: scObj['end_time'],
          slot: '60',
          repeatClass: reactDic,
        };
        let dIndex = this.state.scheduleArray.findIndex(
          x => x['startDate'] == scObj['start_date'],
        );
        if (dIndex == -1) {
          timeArray.push(timeDic);
          dict['timeSlots'] = timeArray;
          this.state.scheduleArray.push(dict);
        } else {
          let getTimeAry = this.state.scheduleArray[dIndex];
          timeArray = [...getTimeAry['timeSlots']];
          timeArray.push(timeDic);
          this.state.scheduleArray[dIndex]['timeSlots'] = timeArray;
        }
      }

      for (let tag of listingDetailData['tags']) {
        this.state.tags.push(tag['name']);
      }
      if (listingDetailData['account']) {
        this.state.accountID = listingDetailData['account']['id'];
      }
      let images = listingDetailData['images'];
      for (let img of images) {
        // const dic = {
        //   image: img,
        //   name: 'backend',
        //   type: 'image/png',
        // };
        this.state.imagesArray.push(img);
      }
      if (listingDetailData['categories'][0]) {
        let ccData = listingDetailData['categories'][0]['hierarchy'];
        let caId = listingDetailData['category_id'][0];
        for (let cad of ccData) {
          if (cad['id'] == caId) {
            this.state.selectedCategory = cad['name'];
            this.state.selectedCategoryID = cad['id'];
            var dic = cad;
            dic['value'] = cad['id'];
            dic['label'] = cad['name'];
            this.state.selectedCategoryArray.push(cad);
            this.loadAttributesApi(this.state.selectedCategoryID);
          }
        }
      }
      if (listingDetailData['attributes'][0]) {
        let aData = listingDetailData['attributes'];
        // console.log('aData', aData);
        for (let ad of aData) {
          if (ad['field_type'] == 12) {
            let loc = ad['values'][0];
            var dic = {
              id: ad['id'],
              type: ad['field_type'],
              value: '',
            };
            this.state.latitude = loc['latitude'] ?? 0.0;
            this.state.longitude = loc['longitude'] ?? 0.0;
            this.state.selectedAttributeArray.push(dic);
          } else if (ad['field_type'] == 5) {
            var dic = {
              id: ad['id'],
              type: ad['field_type'],
              image: ad['values'][0],
            };
            this.state.selectedAttributeArray.push(dic);
          } else if (ad['field_type'] != 2) {
            var dic = {
              id: ad['id'],
              type: ad['field_type'],
            };
            if (ad['field_type'] == 3) {
              dic['value'] = ad['values'][0] || '';
            } else if (ad['field_type'] == 9) {
              dic['value'] = ad['values'][0] || false;
            } else if (ad['field_type'] == 4) {
              dic['tags'] = ad['values'];
            } else if (ad['field_type'] == 5) {
              dic['image'] = ad['values'];
            } else if (ad['field_type'] == 7) {
              let vdtm = Moment(ad['values'][0]);
              this.state.startDate = new Date(vdtm['_d']);
              let dtm = Moment(this.state.startDate).format(dateFormat);
              dic['value'] = dtm;
            } else if (ad['field_type'] == 11) {
              let va = ad['values'][0];
              dic['value'] = `${JSON.stringify(va)}`;
            } else if (ad['field_type'] == 12) {
              dic['value'] = ad['values'];
            } else if (ad['field_type'] == 1) {
              for (let avd of ad['values']) {
                dic['label'] = avd['name'];
                dic['value'] = avd['id'];
              }
            } else {
              dic['value'] = ad['values'][0] || '';
            }
            this.state.selectedAttributeArray.push(dic);
          } else {
            var type2Aray = [];
            for (let avd of ad['values']) {
              let dic = {
                id: ad['id'],
                label: avd['name'] || avd,
                type: ad['field_type'],
                value: avd['id'] || '',
              };
              type2Aray.push(dic);
            }
            if (type2Aray.length != 0) {
              let dict = {
                type: ad['field_type'],
                id: ad['id'],
                value: type2Aray,
              };
              this.state.selectedAttributeArray.push(dict);
            }
          }
        }
      }
      this.setState({updateUI: !this.state.updateUI});
    }
  }
  //MARK:- Api Methods
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=listings',
      'get',
      '',
      '',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        pictureCount: configs['listing_pictures_count'] || 1,
        showStock: configs['enable_stock'] || false,
        showMAxQty: configs['show_max_quantity'] || false,
        showOffer: configs['hide_offer_percent'] || false,
        showTag: configs['listing_hide_tags'] || false,
        showSlug: configs['enable_slug'] || false,
        showMetaTitle: configs['meta_title'] || false,
        showMetaDescription: configs['meta_description'] || false,
      });
    }
    this.setState({dataLoad: true});
  };
  loadCurrencyApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.currencies,
      'get',
      '',
      '',
    );
    if (responseJson['status'] == true) {
      this.setState({currencyArray: []});
      var currencyD = responseJson['data']['currencies'];
      for (let i = 0; i < currencyD.length; i++) {
        this.setState({
          selectedCurrency: currencyD[0]['name'],
          selectedCurrencyID: currencyD[0]['id'],
          selectedCurrencyCODE: currencyD[0]['code'],
        });
        if (currencyD[i]['id'] == this.state.selectedCurrencyID) {
          let item = currencyD[i];
          this.setState({
            selectedCurrency: item['name'],
            selectedCurrencyID: item['id'],
            selectedCurrencyCODE: item['code'],
          });
        }
        this.state.currencyArray.push(currencyD[i]);
      }
      this.setState({updateUI: this.state.updateUI});
    }
    this.setState({dataLoad: true});
  };
  loadCategoryApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.categories + 'listings',
      'get',
    );
    this.setState({categoriesArray: []});
    if (responseJson['status'] == true) {
      var listD = responseJson['data']['categories'];
      for (let i = 0; i < listD.length; i++) {
        this.manageData(listD[i], listD[i]['name']);
        let hierarchyD = listD[i]['sub_category'];
        if (hierarchyD.length != 0) {
          for (let j = 0; j < hierarchyD.length; j++) {
            this.manageData(
              hierarchyD[j],
              `${listD[i]['name']}-> ${hierarchyD[j]['name']}`,
            );
            let hierarchy2 = hierarchyD[j]['sub_category'];
            if (hierarchy2.length != 0) {
              for (let k = 0; k < hierarchy2.length; k++) {
                this.manageData(
                  hierarchy2[k],
                  `${hierarchyD[j]['name']}--> ${hierarchy2[k]['name']}`,
                );
                let hierarchy3 = hierarchy2[k]['sub_category'];
                if (hierarchy3.length != 0) {
                  for (let l = 0; l < hierarchy3.length; l++) {
                    this.manageData(
                      hierarchy3[l],
                      `${hierarchy2[k]['name']}---> ${hierarchy3[l]['name']}`,
                    );
                  }
                }
              }
            }
          }
        }
      }
    }
    this.setState({dateLoad: true});
  };
  manageData(data) {
    var deleteEnable = true;
    if (data['sub_category']) {
      deleteEnable = data['sub_category'].length == 0 ? true : false;
      if (data['sub_category'].length !== 0) {
        deleteEnable = data['parent'] == 0 ? false : true;
      }
    }
    let hierarchyAry = data['hierarchy'];
    var hierarchyName = '';
    if (hierarchyAry.length > 0) {
      for (let a = 0; a < hierarchyAry.length; a++) {
        hierarchyName = `${hierarchyName}${hierarchyAry[a]['name']}`;
        if (a !== hierarchyAry.length - 1) {
          hierarchyName = hierarchyName + ' -> ';
        }
      }
    }
    let catData = {
      id: data['id'],
      parent: data['parent'],
      name: data['name'],
      active: data['active'],
      image_path: data['image_path'],
      delete: deleteEnable,
      subCategory: data['name'],
      order_by: data['order_by'],
      hierarchyName: hierarchyName,
      label: hierarchyName,
      value: data['id'],
    };
    this.state.categoriesArray.push(catData);
  }
  loadAttributesApi = async id => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(
      `${APPURL.URLPaths.attributes}/?category_id=${id}&type=listings`,
      'get',
      '',
      '',
    );
    this.setState({attributesArray: []});
    var ssAray = [...this.state.selectedAttributeArray];

    this.setState({selectedAttributeArray: []});
    if (responseJson['status'] == true) {
      var objData = responseJson['data']['attributes'];
      for (let a = 0; a < objData.length; a++) {
        let objc = objData[a];
        if (this.props.isEdit) {
          if (ssAray.length != 0) {
            let id_Index = ssAray.findIndex(x => x.id === objc['id']);
            if (id_Index != -1) {
              this.state.selectedAttributeArray.push(ssAray[id_Index]);
            } else {
              if (objc['field_type'] == 5) {
                dic = {image: null, id: objc['id'], type: objc['field_type']};
              } else {
                dic = {value: '', id: objc['id'], type: objc['field_type']};
              }
              this.state.selectedAttributeArray.push(dic);
            }
          } else {
            if (objc['field_type'] == 5) {
              dic = {image: null, id: objc['id'], type: objc['field_type']};
            } else {
              dic = {value: '', id: objc['id'], type: objc['field_type']};
            }
            this.state.selectedAttributeArray.push(dic);
          }
        } else {
          var dic = {};
          if (objc['field_type'] == 5) {
            dic = {image: null, id: objc['id'], type: objc['field_type']};
          } else {
            dic = {value: '', id: objc['id'], type: objc['field_type']};
          }
          this.state.selectedAttributeArray.push(dic);
        }
        if (objc['field_type'] == 1 || objc['field_type'] == 2) {
          if (objc['values'].length != 0) {
            this.state.attributesArray.push(objc);
          }
        } else {
          this.state.attributesArray.push(objc);
        }
      }
    }
    this.setState({dataLoad: true});
  };
  uploadImageAPI = async () => {
    var imgParm = [];
    var uploadImageArray = [];
    var uploadDataParm = [];
    for (let i = 0; i < this.state.imagesArray.length; i++) {
      let fileName =
        this.state.imagesArray[i]['name'] ?? this.state.imagesArray[i];
      var strFirstThree = fileName.substring(0, 4);
      if (strFirstThree !== 'http') {
        var dict = {
          name: this.state.imagesArray[i]['name'].replace(/ /g, ''),
          type: this.state.imagesArray[i]['type'],
        };
        var dict2 = {
          image: this.state.imagesArray[i]['base64'],
          type: this.state.imagesArray[i]['type'],
        };
        uploadImageArray.push(dict2);
        imgParm.push(dict);
      } else {
        uploadDataParm.push(this.state.imagesArray[i]);
      }
    }
    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL,
        'POST',
        JSON.stringify({files: imgParm}),
        '',
      );
      var uploadIncrement = 0;
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        for (let i = 0; i < uploadImageArray.length; i++) {
          fetch(uploadImageArray[i]['image']).then(async res => {
            const file_upload_res =
              await networkService.uploadFileWithSignedURL(
                result[i]['signedUrl'],
                uploadImageArray[i]['type'],
                await res.blob(),
              );
            uploadIncrement = uploadIncrement + 1;
            uploadDataParm.push(result[i]['fileUri']);
            if (uploadIncrement === uploadImageArray.length) {
              this.addListingsApi(uploadDataParm);
            }
          });
        }
      } else {
      }
    } else {
      for (let a = 0; a < this.state.imagesArray.length; a++) {
        // uploadDataParm.push(this.state.imagesArray[a]);
      }
      this.addListingsApi(uploadDataParm);
    }
  };
  uploadAttributeImagesAPI = async () => {
    var imgParm = [];
    var uploadImageArray = [];
    var uploadDataParm = [];
    for (let a = 0; a < this.state.attributesArray.length; a++) {
      if (this.state.selectedAttributeArray[a]) {
        if (this.state.selectedAttributeArray[a]['image']) {
          let imgDict = this.state.selectedAttributeArray[a]['image'];
          if (imgDict['fileList']) {
            var dict = {
              name: imgDict['fileList'][0]['name'].replace(/ /g, ''),
              type: imgDict['fileList'][0]['type'],
            };
            var dict2 = {
              image: imgDict.base64,
              type: imgDict['fileList'][0]['type'],
            };
            uploadImageArray.push(dict2);
            imgParm.push(dict);
          } else {
            this.state.uploadAttributeImagesURL = [imgDict];
          }
        }
      }
    }
    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL,
        'POST',
        JSON.stringify({files: imgParm}),
      );
      var uploadIncrement = 0;
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        for (let i = 0; i < uploadImageArray.length; i++) {
          fetch(uploadImageArray[i]['image']).then(async res => {
            const file_upload_res =
              await networkService.uploadFileWithSignedURL(
                result[i]['signedUrl'],
                uploadImageArray[i]['type'],
                await res.blob(),
              );
            uploadIncrement = uploadIncrement + 1;
            uploadDataParm.push(result[i]['fileUri']);
            if (uploadIncrement === uploadImageArray.length) {
              this.state.uploadAttributeImagesURL = uploadDataParm;
              this.uploadImageAPI();
            }
          });
        }
      }
    } else {
      this.uploadImageAPI();
    }
  };
  addListingsApi = async parameter => {
    var dict = {
      list_price: Number(this.state.listPriceValue),
      currency_id: this.state.selectedCurrencyID,
      stock: Number(this.state.stockValue),
      title: this.state.titleString,
    };
    dict['active'] = this.state.saveAsDraft ? false : true;

    if (this.state.accountID.length != 0) {
      dict['account_id'] = this.state.accountID;
    }
    if (this.state.selectedCategoryID.length != 0) {
      dict['category_id'] = [this.state.selectedCategoryID];
    }
    if (this.state.offerPercentage.length != 0) {
      dict['offer_percent'] = Number(this.state.offerPercentage);
    }
    if (this.state.descriptionString.length != 0) {
      dict['description'] = this.state.descriptionString;
    }
    if (this.state.metaTitle.length != 0) {
      dict['meta_title'] = this.state.metaTitle;
    }
    if (this.state.metaDescriptionString.length != 0) {
      dict['meta_description'] = this.state.metaDescriptionString;
    }
    if (this.state.showSlug) {
      let slugValue =
        this.state.slugValue.length != 0
          ? this.state.slugValue
          : this.state.titleString;
      let vslug = slugify(slugValue, {
        remove: undefined,
        lower: true,
        strict: true,
      });
      dict['slug'] = vslug;
    }

    if (parameter.length != 0) {
      dict['images'] = parameter;
    }
    var attriArray = [];
    for (let a = 0; a < this.state.attributesArray.length; a++) {
      let objc = this.state.attributesArray[a];
      if (objc['field_type'] == 4) {
        if (objc['optional'] == false) {
          if (this.state.selectedAttributeArray[a]) {
            let sData = this.state.selectedAttributeArray[a];
            let valueary = sData['tags'];
            let dict = {
              id: objc['id'],
              values: valueary,
            };
            if (valueary === undefined || valueary.length == 0) {
              this.customAlert(objc['name']);
              return true;
            } else {
              attriArray.push(dict);
            }
          } else {
            this.customAlert(objc['name']);
            return true;
          }
        } else {
          if (this.state.selectedAttributeArray[a]) {
            let sData = this.state.selectedAttributeArray[a];
            let valueary = sData['tags'];
            let dict = {
              id: objc['id'],
              values: valueary,
            };
            if (valueary !== undefined || valueary.length !== 0) {
              attriArray.push(dict);
            }
          }
        }
      } else if (objc['field_type'] == 5) {
        if (objc['optional'] == false) {
          if (this.state.selectedAttributeArray[a]) {
            let dict = {
              id: objc['id'],
              values: this.state.uploadAttributeImagesURL,
            };
            if (this.state.uploadAttributeImagesURL != 0) {
              attriArray.push(dict);
            } else {
              this.customAlert(objc['name']);
              return true;
            }
          } else {
            this.customAlert(objc['name']);
            return true;
          }
        } else {
          if (this.state.selectedAttributeArray[a]) {
            let dict = {
              id: objc['id'],
              values: this.state.uploadAttributeImagesURL,
            };
            if (this.state.uploadAttributeImagesURL != 0) {
              attriArray.push(dict);
            }
          }
        }
      } else if (objc['field_type'] == 11) {
        if (objc['optional'] == false) {
          if (this.state.selectedAttributeArray[a]) {
            let sData = this.state.selectedAttributeArray[a];
            let valueary = [sData['value']];
            console.log('valueary', valueary);
            if (valueary[0] != undefined && valueary[0] != '') {
              let jsonObj = eval('(' + sData['value'] + ')');
              let dict = {
                id: objc['id'],
                values: [jsonObj],
              };
              attriArray.push(dict);
            } else {
              this.customAlert(objc['name']);
              return true;
            }
          } else {
            this.customAlert(objc['name']);
            return true;
          }
        } else {
          if (this.state.selectedAttributeArray[a]) {
            let sData = this.state.selectedAttributeArray[a];
            let valueary = [sData['value']];
            if (valueary[0] != undefined && valueary[0] !== '') {
              let jsonObj = eval('(' + sData['value'] + ')');
              let dict = {
                id: objc['id'],
                values: [jsonObj],
              };
              attriArray.push(dict);
            }
          }
        }
      } else if (objc['field_type'] == 12) {
        if (objc['optional'] == false) {
          if (this.state.selectedAttributeArray[a]) {
            if (this.state.latitude != 0.0) {
              let dict = {
                id: objc['id'],
                values: [
                  {
                    latitude: this.state.latitude,
                    longitude: this.state.longitude,
                  },
                ],
              };
              attriArray.push(dict);
            } else {
              this.customAlert(objc['name']);
              return true;
            }
          } else {
            this.customAlert(objc['name']);
            return true;
          }
        } else {
          if (this.state.latitude != 0.0) {
            let dict = {
              id: objc['id'],
              values: [
                {
                  latitude: this.state.latitude,
                  longitude: this.state.longitude,
                },
              ],
            };
            attriArray.push(dict);
          }
        }
      } else if (objc['field_type'] == 2) {
        if (objc['optional'] == false) {
          if (this.state.selectedAttributeArray[a]) {
            let sData = this.state.selectedAttributeArray[a];
            var valuesAry = [];
            let multiV = sData['value'];
            for (let j = 0; j < multiV.length; j++) {
              valuesAry.push(multiV[j]['value']);
            }
            let dict = {
              id: objc['id'],
              values: valuesAry,
            };
            if (valuesAry.length == 0) {
              this.customAlert(objc['name']);
              return true;
            } else {
              attriArray.push(dict);
            }
          } else {
            this.customAlert(objc['name']);
            return true;
          }
        } else {
          if (this.state.selectedAttributeArray[a]) {
            let sData = this.state.selectedAttributeArray[a];
            var valuesAry = [];
            let multiV = sData['value'];
            for (let j = 0; j < multiV.length; j++) {
              valuesAry.push(multiV[j]['value']);
            }
            let dict = {
              id: objc['id'],
              values: valuesAry,
            };
            if (valuesAry.length !== 0) {
              attriArray.push(dict);
            }
          }
        }
      } else {
        if (objc['optional'] == false) {
          if (this.state.selectedAttributeArray[a]) {
            let sData = this.state.selectedAttributeArray[a];
            let valueary = [sData['value']];
            let dict = {
              id: objc['id'],
              values: valueary,
            };
            if (valueary[0] == '') {
              this.customAlert(objc['name']);
              return true;
            } else {
              attriArray.push(dict);
            }
          } else {
            this.customAlert(objc['name']);
            return true;
          }
        } else {
          if (this.state.selectedAttributeArray[a]) {
            let sData = this.state.selectedAttributeArray[a];
            let valueary = [sData['value']];
            let dict = {
              id: objc['id'],
              values: valueary,
            };
            if (valueary[0] !== '') {
              attriArray.push(dict);
            }
          }
        }
      }
    }
    if (attriArray.length != 0) {
      dict['attributes'] = attriArray;
    }
    if (this.state.tags.length != 0) {
      dict['tags'] = this.state.tags;
    }
    if (this.state.maxQuantity.length != 0) {
      dict['max_quantity'] = this.state.maxQuantity;
    }
    var method = 'post';
    var path = APPURL.URLPaths.listingsModerate;
    if (this.state.id != '') {
      path = `${APPURL.URLPaths.listingsModerate}/${this.state.id}`;
      method = 'PATCH';
    }
    const responseJson = await networkService.networkCall(
      path,
      method,
      JSON.stringify({listing: dict}),
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      if (AppConstants.moduleType == ModuleType.event) {
        if (this.state.id != '') {
          this.scheduleAPI(this.state.id);
        } else {
          let dictData = responseJson['data']['listing'];
          this.scheduleAPI(dictData['id']);
        }
      } else {
        this.successResponse();
      }
    } else {
      this.mixpanelInfo(false);
      AppToast(responseJson);
    }
  };
  successResponse() {
    this.mixpanelInfo(true);
    this.props.backBtnAction(true);
    AppToast();
  }
  scheduleAPI = async id => {
    let uploadDic = [];
    for (let obj of this.state.scheduleArray) {
      let timeSlots = obj['timeSlots'];
      let scheduledate = changeDateFormat(obj['startDate'], 'yyyy-MM-DD');
      let repeat_days = obj['repeatClass']['ids'];
      for (let time of timeSlots) {
        let st = convertTimeinto24Hrs(time['start']);
        let et = convertTimeinto24Hrs(time['end']);
        var dic = {
          start_date: scheduledate,
          start_time: st,
          end_time: et,
          active: true,
          repeat_days: repeat_days.split(','),
          schedule_type: 2,
        };
        uploadDic.push(dic);
      }
    }

    var bodyPrm = '';
    if (uploadDic.length != 0) {
      bodyPrm = JSON.stringify({schedules: uploadDic});
    }
    let path = `${APPURL.URLPaths.listingsModerate}/${id}/${APPURL.URLPaths.schedules}`;
    const responseJson = await networkService.networkCall(path, 'PUT', bodyPrm);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.successResponse();
    } else {
      this.mixpanelInfo(false);
      AppToast(responseJson);
    }
  };

  mixpanelInfo(resp) {
    let properties = {success: resp};
    let event =
      this.state.id !== ''
        ? MixPannelEnum.editlistings
        : MixPannelEnum.addlistings;
    addMixpanelEvent(event, properties);
  }
  customAlert(msg) {
    this.setState({dataLoad: true});
    AppToast(`Please select ${msg}`);
  }
  //MARK:-  Button Actions
  submitBtnAction = id => {
    if (this.state.titleString.length == 0) {
      AppToast('Please enter title');
    } else if (this.state.imagesArray.length == 0) {
      AppToast('Please select atleast 1 image');
    } else {
      this.state.saveAsDraft = id == 1 ? false : true;
      this.setState({dataLoad: false});
      this.uploadAttributeImagesAPI();
    }
  };
  dropdownBtnAction(id) {
    this.state.showDropDown[id] = !this.state.showDropDown[id];
    this.setState({updateUI: !this.state.updateUI});
  }
  didSelectCurrencyDropDown = item => {
    this.state.showDropDown[0] = !this.state.showDropDown[0];
    this.setState({
      selectedCurrency: item['name'],
      selectedCurrencyID: item['id'],
      selectedCurrencyCODE: item['code'],
    });
  };
  handleSelectChange = selectedOption => {
    this.state.selectedAttributeArray = [];
    this.setState({
      selectedCategory: selectedOption['name'],
      selectedCategoryID: selectedOption['id'],
    });
    this.state.selectedCategoryArray = selectedOption;
    this.loadAttributesApi(selectedOption['id']);
    this.setState({updateUI: !this.state.updateUI});
  };
  didSelectAttributeDropDown = item => {
    this.setState({showAttributeDropDown: !this.state.showAttributeDropDown});
  };
  onHandleTextChange(text, id) {
    let value = text;
    if (id == 0) {
      if (text == '' || this.state.regexp.test(value)) {
        this.setState({listPriceValue: text});
      }
    } else if (id == 1) {
      if (text == '' || this.state.regexp.test(value)) {
        this.setState({offerPercentage: text});
      }
    } else if (id == 3) {
      if (text == '' || this.state.regexp.test(value)) {
        this.setState({maxQuantity: text});
      }
    } else if (id == 5) {
      if (text.length < 17) {
        this.setState({metaTitle: text});
      }
    } else if (id == 6) {
      if (text.length < 161) {
        this.setState({metaDescriptionString: text});
      }
    } else {
      if (text == '' || this.state.regexp.test(value)) {
        this.setState({stockValue: text});
      }
    }
  }

  offerPercentageEndEditing() {
    if (this.state.offerPercentage < 0 || this.state.offerPercentage > 100) {
      this.setState({offerPercentage: ''});
      AppToast('Offer Percentage must be between 0 to 100');
    }
  }
  onPick = acceptedFiles => {
    console.log('acceptedFiles', acceptedFiles);
    if (this.state.imagesArray.length < this.state.pictureCount) {
      acceptedFiles.forEach(image => {
        this.state.imagesArray.push(image);
      });
      this.setState({updateUI: !this.state.updateUI});
    }
    // else {
    //   AppToast('you cannot select more');
    // }
  };
  showMediaBtnAction() {
    if (this.state.mediaArray != 0) {
      this.setState({showMediaManager: true});
    } else {
      AppToast('You dont have media file.');
    }
  }
  deleteImageButtonAction(id) {
    this.state.imagesArray.splice(id, 1);
    this.setState({updateUI: !this.state.updateUI});
  }
  onTagsChanged(tags) {
    this.setState({tags});
  }
  onValuesTagChanged(tags, index, id, type) {
    this.state.selectedAttributeArray[index] = {tags: tags, id: id, type: type};
    this.setState({updateUI: this.state.updateUI});
  }
  handleAttributesChange = (selectedOption, index, type) => {
    if (type == 2) {
      console.log('selectedOption', selectedOption);
      if (selectedOption != undefined) {
        if (selectedOption[0]) {
          let dict = {
            type: type,
            id: selectedOption[0]['id'],
            value: selectedOption,
          };
          this.state.selectedAttributeArray[index] = dict;
        } else {
          let dict = {type: type, id: '', value: []};
          this.state.selectedAttributeArray[index] = dict;
        }
      }
    } else {
      this.state.selectedAttributeArray[index] = selectedOption;
    }
    this.setState({updateUI: this.state.updateUI});
  };
  valueFieldsChanged(text, index, id, type) {
    if (type == 7) {
      this.state.startDate = text;
      let dtm = Moment(this.state.startDate).format(dateFormat);
      this.state.selectedAttributeArray[index] = {
        value: dtm,
        id: id,
        type: type,
      };
    } else {
      this.state.selectedAttributeArray[index] = {
        value: text,
        id: id,
        type: type,
      };
    }
    this.setState({updateUI: this.state.updateUI});
  }
  onPickAttachment = (image, index, id, type) => {
    this.state.selectedAttributeArray[index] = {
      image: image,
      id: id,
      type: type,
    };
    this.setState({updateUI: this.state.updateUI});
  };
  onMediaAttachment = (index, id) => {
    if (this.state.mediaArray != 0) {
      this.state.attributeImageID = id;
      this.state.attributeImageIndex = index;
      this.setState({showMediaManager: true});
    } else {
      AppToast('You dont have media file.');
    }
  };
  didSelectMediaManager(path) {
    if (this.state.attributeImageIndex != -1) {
      this.state.selectedAttributeArray[this.state.attributeImageIndex] = {
        image: path,
        id: this.state.attributeImageID,
        type: 5,
      };
    } else {
      this.state.imagesArray.push(path);
    }
    this.setState({showMediaManager: false, attributeImageIndex: -1});
  }
  onHandleNumberFieldChange(text, index, id, type) {
    let value = text;
    if (text == '' || this.state.regexp.test(value)) {
      this.state.selectedAttributeArray[index] = {
        value: text,
        id: id,
        type: type,
      };
      this.setState({updateUI: this.state.updateUI});
    }
  }
  handleChangeComplete(color, index, id, type) {
    if (Platform.OS === 'web') {
      this.state.selectedAttributeArray[index] = {
        value: color['hex'],
        id: id,
        type: type,
      };
      this.state.showColorPicker = [];
      this.setState({updateUI: this.state.updateUI});
    }
  }
  handleChangeLocation(lat, lng) {
    let dic = {latitude: lat, longitude: lng};
    let id_Index = this.state.selectedAttributeArray.findIndex(
      x => x.type === 12,
    );
    if (id_Index != -1) {
      this.state.selectedAttributeArray[id_Index] = {
        value: dic,
        id: this.state.selectedAttributeArray[id_Index]['id'],
        type: 12,
      };
    }
    this.setState({latitude: lat, longitude: lng, showMapView: false});
    console.log('lat', this.state.latitude, 'lng', this.state.longitude);
  }
  jsonFilePickAction(json, index, id, type) {
    console.log('json', json);
    var b64 = json['base64'];
    b64 = b64.replace('data:application/json;base64,', '');
    var decodedStr = this.base64DecodeUnicode(b64);
    this.state.selectedAttributeArray[index] = {
      value: decodedStr,
      id: id,
      type: type,
    };
    this.setState({updateUI: this.state.updateUI});
  }
  base64DecodeUnicode(str) {
    let percentEncodedStr = atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('');

    return decodeURIComponent(percentEncodedStr);
  }
  showColorPickerBtnAction(id) {
    this.state.showColorPicker.push(id);
    this.setState({updateUI: this.state.updateUI});
  }
  didSelectRepeat = index => {
    this.setState({showScheduleRepeatBool: false, selectedRepeatIndex: index});
  };
  didSelectWeekdays(item) {
    let ind = this.state.selectedWeekDay.findIndex(x => x.id == item.id);
    if (ind == -1) {
      this.state.selectedWeekDay.push(item);
    } else {
      this.state.selectedWeekDay.splice(ind, 1);
    }
    this.setState({updateUI: !this.state.updateUI});
  }

  openTimePicker(index) {
    if (index == 0) {
      this.state.showTimeBool = [!this.state.showTimeBool[index], false];
    } else {
      this.state.showTimeBool = [false, !this.state.showTimeBool[index]];
    }
    this.setState({updateUI: !this.state.updateUI});
  }
  didSelectSlot = item => {
    this.setState({selectedSlot: item, showSlotBool: false});
  };
  didSelectDate(date, id) {
    // console.log('id', id)
    // if (id == 2) {
    //   if (this.state.scheduleStartDate <= date){
    //     this.setState({ scheduleEndDate: date })
    //   }else {
    //     AppToast('End date must be greater then start date')
    //   }
    // } else {
    //   if (this.state.scheduleEndDate >= date){
    this.setState({scheduleStartDate: date});
    // }else {
    //   AppToast('Start date must be less then end date')
    // }
    // }
  }
  didSelectStartTime(item) {
    var beginningTime = Moment(item, 'hh:mm a');
    var endTime = Moment(this.state.scheduleEndTime, 'hh:mm a');
    console.log(beginningTime.isBefore(endTime));
    if (beginningTime.isBefore(endTime)) {
      this.state.showTimeBool[0] = !this.state.showTimeBool[0];
      this.setState({scheduleStartTime: item});
    } else {
      AppToast('Start time should be less then end');
    }
  }
  didSelectEndTime(item) {
    var beginningTime = Moment(item, 'hh:mm a');
    var endTime = Moment(this.state.scheduleStartTime, 'hh:mm a');
    if (beginningTime.isAfter(endTime)) {
      this.state.showTimeBool[1] = !this.state.showTimeBool[1];
      this.setState({scheduleEndTime: item});
    } else {
      AppToast('End time should be greater then start time');
    }
  }
  saveScheduleBtnAction() {
    if (this.state.selectedRepeatIndex == -1) {
      AppToast('Select repeat');
    } else {
      var repeatValue =
        ConstantArray.repeatArray[this.state.selectedRepeatIndex]['name'];
      var repeatIds =
        ConstantArray.repeatArray[this.state.selectedRepeatIndex]['id'];
      if (repeatIds == '') {
        var idsary = [];
        var namesary = [];
        for (let obj of this.state.selectedWeekDay) {
          idsary.push(obj['id']);
          namesary.push(obj['name']);
        }
        repeatIds = idsary.toString();
        repeatValue = namesary.toString();
      }
      let reactDic = {
        name: repeatValue,
        ids: repeatIds,
      };
      let timeSlots = getTimeDifferenceArray(
        this.state.scheduleStartTime,
        this.state.scheduleEndTime,
        this.state.selectedSlot,
      );
      let dict = {
        startDate: this.state.scheduleStartDate,
        startTime: this.state.scheduleStartTime,
        endTime: this.state.scheduleEndTime,
        slot: this.state.selectedSlot,
        repeatClass: reactDic,
        timeSlots: timeSlots,
      };
      this.state.scheduleArray.push(dict);
      this.setState({showAddScheduleViewBool: false});
      // this.scheduleAPI(0)
    }
  }
  deleteScheduleItem(index) {
    this.state.scheduleArray.splice(index, 1);
    this.setState({updateUI: !this.state.updateUI});
  }
  addScheduleBtnAction() {
    this.state.scheduleStartDate = new Date();
    this.state.selectedRepeatIndex = -1;
    this.state.selectedWeekDay = [];
    this.state.scheduleStartTime = '09:00 am';
    this.state.scheduleEndTime = '05:00 pm';
    this.state.selectedSlot = slotArray[3]['name'];
    this.setState({showAddScheduleViewBool: true});
  }

  // UI Renders
  imageViewPicker = props => {
    let imageCount = this.state.imagesArray.length;
    var msgTxt = '';
    if (imageCount == 3) {
      msgTxt = '3 files added';
    } else {
      msgTxt =
        imageCount == 0
          ? 'Choose file or Drag and drop here'
          : imageCount + ' file added';
    }
    return (
      <View style={appConfigStyle.dottedViewStyle}>
        <FilePicker data={files => this.onPick(files)} />
      </View>
    );
  };
  renderListingImageView = () => {
    if (this.state.pictureCount > 0) {
      return (
        <View style={{width: '100%', alignItems: 'flex-start', marginTop: 5}}>
          <View style={{height: 20}} />
          <Text style={CommonStyleSheet.plainTextStyle}>Listing Images</Text>
          <View style={styles.firstViewStyle}>
            <ScrollView horizontal={true}>
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row-reverse',
                  margin: 10,
                }}>
                {this.viewSelectedImages()}
              </View>
            </ScrollView>
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };
  viewSelectedImages = () => {
    var views = [];
    for (let i = 0; i < this.state.imagesArray.length + 1; i++) {
      let imageObj = {};
      if (this.state.imagesArray[i]) {
        imageObj = this.state.imagesArray[i];
      }
      if (JSON.stringify(imageObj) !== '{}') {
        let fileName = imageObj['name'] ?? null;
        var showImage = true;
        var icon = jsonIcon;
        var fileExt = '';
        if (fileName == null) {
          let name = imageObj.substring(imageObj.lastIndexOf('/') + 1);
          var fileExt = name.split('.').pop();
        } else {
          let name = imageObj['name'].replace(/ /g, '');
          fileExt = name.split('.').pop();
        }
        if (fileExt == 'json') {
          icon = jsonIcon;
          showImage = false;
        } else if (fileExt == 'pdf') {
          icon = pdfIcon;
          showImage = false;
        }
        views.push(
          <View>
            <View style={styles.imageSelectedStyle}>
              <View>
                <Image
                  source={
                    showImage
                      ? {uri: fileName == null ? imageObj : imageObj['base64']}
                      : icon
                  }
                  style={styles.SelectedImageStyle}
                />
                <TouchableOpacity
                  style={styles.deleteViewStyle}
                  onPress={() => this.deleteImageButtonAction(i)}>
                  <Image
                    resizeMode={'center'}
                    style={styles.deleteImageStyle}
                    source={blackCrossIcon}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>,
        );
      } else if (this.state.imagesArray.length >= this.state.pictureCount) {
        views.push(<View />);
      } else {
        views.push(
          <View>
            <View style={{flexDirection: 'row'}}>
              <View style={styles.imagePickerPlaceholderStyle}>
                {/* <ReactFileReader
                  fileTypes={['.gif', '.json', '.pdf', 'image/*',]}
                  base64={true}
                  handleFiles={img => this.onPick(img, i)}>
                  <View style={{ justifyContent: 'center' }}>
                    <Image source={upload_Icon} style={{ width: 30, height: 30, alignSelf: 'center' }} />
                    <Text style={commonStyle.uploadHereTextStyle}>Upload here</Text>
                  </View>
                </ReactFileReader> */}
                <FilePicker
                  style={commonStyle.uploadHereTextStyle}
                  title={'Upload here'}
                  data={files => this.onPick(files, i)}
                />
              </View>
              <View style={{width: 20, height: 20}} />
              <View style={styles.imagePickerPlaceholderStyle}>
                <TouchableOpacity
                  style={{width: 100}}
                  onPress={() => this.showMediaBtnAction()}>
                  <Text
                    style={[commonStyle.uploadHereTextStyle, {marginTop: 0}]}>
                    Select from media
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={{width: 20, height: 20}} />
            </View>
          </View>,
        );
      }
    }
    return views;
  };

  customDropDown = id => {
    let index = id['id'];
    if (this.state.showDropDown[index] == true) {
      let dAray =
        index == 0 ? this.state.currencyArray : this.state.categoriesArray;
      return (
        <View style={commonStyle.showDropDownViewStyle}>
          <FlatList
            data={dAray}
            horizontal={false}
            renderItem={
              index == 0 ? this.renderDropDown : this.renderCategoryDropDown
            }
            extraData={this.state}
            showsVerticalScrollIndicator={true}
            scrollEnabled={true}
            keyExtractor={(item, index) => index + 912799}
            key={'D'}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectCurrencyDropDown(item, index)}
        style={{margin: 5, height: 30, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text style={CommonStyleSheet.plainSubTextStyle}>{item['name']}</Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderCategoryDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectCategoryDropDown(item, index)}
        style={{margin: 5, height: 30, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text style={CommonStyleSheet.plainSubTextStyle}>{item['name']}</Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderValueLabel = id => {
    let index = id['id'];
    let textValue =
      index == 0 ? this.state.selectedCurrency : this.state.selectedCategory;
    let placeholder = index == 0 ? 'Select Currency' : 'Select Category';
    var views = [];
    views.push(
      <Image
        style={
          this.state.showDropDown[index] == true
            ? appConfigStyle.upArraowIconStyle
            : appConfigStyle.downArraowIconStyle
        }
        resizeMode="contain"
        source={downArrow_Icon}
      />,
    );
    return (
      <View style={styles.dropdownLabelStyle}>
        <Text
          style={
            textValue == ''
              ? appConfigStyle.placeholderTxtStyle
              : appConfigStyle.txtFieldStyleDropDown
          }>
          {textValue == '' ? placeholder : textValue}
        </Text>
        {views}
      </View>
    );
  };
  renderTitleLbl = ({title}) => {
    return (
      <View style={{margin: -5}}>
        <Text style={CommonStyleSheet.plainSubTextStyle}>
          {' '}
          {title}
          <Text style={{color: colors.AppRed, paddingTop: 5}}> *</Text>
        </Text>
      </View>
    );
  };
  renderAttributeLableView = item => {
    var titleAray = [];
    var toolTipView = [];
    if (item['tooltip']) {
      if (item['tooltip'].length != 0) {
        toolTipView.push(
          <View style={{marginLeft: 10}}>
            <ToolTipViewBtn
              title={item['name']}
              description={item['tooltip']}
            />
          </View>,
        );
      }
    }
    if (item['optional'] == false) {
      titleAray.push(
        <View style={{flexDirection: 'row', alignItems: 'center', zIndex: 191}}>
          {this.renderTitleLbl({title: item['name']})}
          {toolTipView}
        </View>,
      );
    } else {
      titleAray.push(
        <View style={{flexDirection: 'row', zIndex: 191, alignItems: 'center'}}>
          <Text style={CommonStyleSheet.plainSubTextStyle}>{item['name']}</Text>
          {toolTipView}
        </View>,
      );
    }
    return titleAray;
  };
  renderArttributes = () => {
    var views = [];
    if (this.state.attributesArray.length != 0) {
      views.push(<Text style={appConfigStyle.titleTxtStyle}>Attributes</Text>);
    }
    for (let a = 0; a < this.state.attributesArray.length; a++) {
      let viewTag = 199304 - a;
      let objc = this.state.attributesArray[a];
      // console.log('objc a ==>>>', objc['field_type'])
      if (objc['field_type'] == 1 || objc['field_type'] == 2) {
        let valuesArray = [];
        for (let valuesDic of objc['values']) {
          if (valuesDic['active']) {
            let dict = {
              value: valuesDic['id'],
              label: valuesDic['name'],
              id: objc['id'],
              type: objc['field_type'],
            };
            valuesArray.push(dict);
          }
        }
        var values = [];
        // console.log(this.state.selectedAttributeArray, 'objc', objc)
        let id_Index = this.state.selectedAttributeArray.findIndex(
          x => x.id === objc['id'],
        );
        if (id_Index != -1) {
          values = this.state.selectedAttributeArray[id_Index];
        }
        var sValues = this.state.selectedAttributeArray[a];
        let multiValue = objc['field_type'] == 1 ? false : true;
        if (sValues != undefined) {
          if (multiValue) {
            if (sValues['value'] == '') {
              sValues = [];
            } else {
              if (this.state.selectedAttributeArray[a]) {
                sValues = this.state.selectedAttributeArray[a]['value'];
              }
            }
          } else {
            if (sValues['value'] == '') {
              sValues = [];
            }
          }
        }
        views.push(
          <View style={{zIndex: viewTag}}>
            <View style={{height: 20}} />
            {/* <Text style={CommonStyleSheet.plainSubTextStyle}>{objc['name']}</Text> */}
            {this.renderAttributeLableView(objc)}
            <View style={[styles.selectionViewStyle, {zIndex: 12}]}>
              <View style={{width: '100%'}}>
                <SelectSearchView
                  value={sValues}
                  onChange={itm =>
                    this.handleAttributesChange(itm, a, objc['field_type'])
                  }
                  options={valuesArray}
                  isMulti={multiValue}
                  placeholder={`Select Attributes`}
                  styles={colourStyles}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: colors.AppGreenBorder,
                      primary: colors.AppGreenBorder,
                    },
                  })}
                />
              </View>
            </View>
          </View>,
        );
      } else if (objc['field_type'] == 3) {
        var value = '';
        let id_Index = this.state.selectedAttributeArray.findIndex(
          x => x.id === objc['id'],
        );
        if (id_Index != -1) {
          value = this.state.selectedAttributeArray[id_Index]['value'];
        }
        views.push(
          <View style={{zIndex: viewTag}}>
            <View>
              <View style={{height: 20}} />
              {this.renderAttributeLableView(objc)}
              {/* <Text style={CommonStyleSheet.plainSubTextStyle}>
              {objc['name']}</Text> */}
              <TextInput
                style={[appConfigStyle.txtFieldStyle, {outline: 0, zIndex: 12}]}
                placeholder="Enter value"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={txt =>
                  this.valueFieldsChanged(
                    txt,
                    a,
                    objc['id'],
                    objc['field_type'],
                  )
                }
                value={value}
              />
            </View>
          </View>,
        );
      } else if (objc['field_type'] == 4) {
        var tags = [];
        let id_Index = this.state.selectedAttributeArray.findIndex(
          x => x.id === objc['id'],
        );
        if (id_Index != -1) {
          tags = this.state.selectedAttributeArray[id_Index]['tags'];
          tags = tags == undefined ? [] : tags;
        }
        views.push(
          <View style={{zIndex: viewTag}}>
            <View style={{height: 20}} />
            {this.renderAttributeLableView(objc)}
            <View style={{paddingTop: 10, zIndex: 1}}>
              <TagsInput
                value={tags}
                onChange={txt =>
                  this.onValuesTagChanged(
                    txt,
                    a,
                    objc['id'],
                    objc['field_type'],
                  )
                }
              />
            </View>
          </View>,
        );
      } else if (objc['field_type'] == 5) {
        var imageObj = {};
        var photo = null;
        let id_Index = this.state.selectedAttributeArray.findIndex(
          x => x.id === objc['id'],
        );
        if (id_Index != -1) {
          if (this.state.selectedAttributeArray[id_Index]['image'] != null) {
            imageObj = this.state.selectedAttributeArray[id_Index]['image'];
          } else {
            imageObj = this.state.selectedAttributeArray[id_Index]['image'];
          }
          photo = imageObj;
        }
        var imageView = [];
        var fileExt = '';
        if (photo != null) {
          if (imageObj.base64) {
            photo = imageObj.base64;
            if (imageObj['fileList'][0]) {
              fileExt = imageObj['fileList'][0]['type'];
            }
            if (fileExt == 'application/json') {
              icon = jsonIcon;
              showImage = false;
            } else if (fileExt == 'application/pdf') {
              icon = pdfIcon;
              showImage = false;
            } else if (fileExt == 'application/doc') {
              icon = jsonIcon;
              showImage = false;
            } else {
              showImage = true;
            }
          } else {
            let fileName = imageObj['image'] ?? null;
            var showImage = true;
            var icon = jsonIcon;
            if (fileName == null) {
              let name = imageObj.substring(imageObj.lastIndexOf('/') + 1);
              var fileExt = name.split('.').pop();
            } else {
              let name = imageObj['name'].replace(/ /g, '');
              fileExt = name.split('.').pop();
            }
          }
          if (fileExt == 'json') {
            icon = jsonIcon;
            showImage = false;
          } else if (fileExt == 'pdf') {
            icon = pdfIcon;
            showImage = false;
          }
          imageView.push(
            <View style={{zIndex: viewTag}}>
              <View style={[styles.imageSelectedStyle, {marginLeft: 0}]}>
                {/* <ReactFileReader
                fileTypes={['.png', '.jpg','image/*',]}
                base64={true}
                handleFiles={img => this.onPickAttachment(img, a, objc['id'], objc['field_type'])}> */}
                <View>
                  <Image
                    source={{uri: showImage ? photo : icon}}
                    style={appConfigStyle.SelectedImageStyle}
                  />
                </View>
                {/* </ReactFileReader> */}
              </View>
            </View>,
          );
        }
        // else {
        views.push(
          <View style={{zIndex: viewTag}}>
            <View style={{height: 20}} />
            {this.renderAttributeLableView(objc)}
            <View style={{zIndex: 11}}>
              <View style={{height: 10}} />
              <View style={{flexDirection: 'row'}}>
                <View style={styles.imagePickerPlaceholderStyle}>
                  <ReactFileReader
                    fileTypes={[
                      '.png',
                      '.jpg',
                      '.gif',
                      '.json',
                      '.pdf',
                      'image/*',
                    ]}
                    base64={true}
                    handleFiles={img =>
                      this.onPickAttachment(
                        img,
                        a,
                        objc['id'],
                        objc['field_type'],
                      )
                    }>
                    <View style={{justifyContent: 'center'}}>
                      <Image
                        source={upload_Icon}
                        style={{width: 30, height: 30, alignSelf: 'center'}}
                      />
                      <Text style={commonStyle.uploadHereTextStyle}>
                        Upload here
                      </Text>
                    </View>
                  </ReactFileReader>
                </View>
                <View style={{width: 20, height: 20}} />
                <View style={styles.imagePickerPlaceholderStyle}>
                  <TouchableOpacity
                    style={{width: 100}}
                    onPress={() => this.onMediaAttachment(a, objc['id'])}>
                    <Text style={{width: 100, textAlign: 'center'}}>
                      Select from media
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={{width: 20, height: 20}} />
              {imageView}
            </View>
          </View>,
        );
        // }
      } else if (objc['field_type'] == 6) {
        var value = '';
        let id_Index = this.state.selectedAttributeArray.findIndex(
          x => x.id === objc['id'],
        );
        if (id_Index != -1) {
          value = this.state.selectedAttributeArray[id_Index]['value'];
        }
        views.push(
          <View style={{zIndex: viewTag}}>
            <View>
              <View style={{height: 20}} />
              {this.renderAttributeLableView(objc)}
              <DescriptionView
                value={value}
                onChange={txt =>
                  this.valueFieldsChanged(
                    txt,
                    a,
                    objc['id'],
                    objc['field_type'],
                  )
                }
              />
            </View>
          </View>,
        );
      } else if (objc['field_type'] == 7) {
        var value = '';
        let id_Index = this.state.selectedAttributeArray.findIndex(
          x => x.id === objc['id'],
        );
        if (id_Index != -1) {
          value = this.state.selectedAttributeArray[id_Index]['value'];
        }
        let dtm = Moment(this.state.startDate).format(dateFormat);
        views.push(
          <View
            style={{zIndex: this.state.calenderOn ? viewTag + 98982 : viewTag}}>
            <View>
              <View style={{height: 20}} />
              {this.renderAttributeLableView(objc)}
              <View style={[appConfigStyle.txtFieldStyle, {zIndex: 19}]}>
                <View style={{zIndex: viewTag + 197266, position: 'absolute'}}>
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={date =>
                      this.valueFieldsChanged(
                        date,
                        a,
                        objc['id'],
                        objc['field_type'],
                      )
                    }
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm:ss"
                    showTimeInput={true}
                    onCalendarClose={() => this.setState({calenderOn: false})}
                    onCalendarOpen={() => this.setState({calenderOn: true})}
                    customInput={
                      <View style={{height: 20, width: 200}}>
                        {' '}
                        <Text>{dtm}</Text>
                      </View>
                    }
                  />
                </View>
              </View>
            </View>
          </View>,
        );
      } else if (objc['field_type'] == 8) {
        var value = '';
        let id_Index = this.state.selectedAttributeArray.findIndex(
          x => x.id === objc['id'],
        );
        if (id_Index != -1) {
          value = this.state.selectedAttributeArray[id_Index]['value'];
        }
        views.push(
          <View style={{zIndex: viewTag}}>
            <View>
              <View style={{height: 20}} />
              {this.renderAttributeLableView(objc)}
              <TextInput
                style={[appConfigStyle.txtFieldStyle, {outline: 0, zIndex: 1}]}
                placeholder="Enter value"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={txt =>
                  this.onHandleNumberFieldChange(
                    txt,
                    a,
                    objc['id'],
                    objc['field_type'],
                  )
                }
                value={value}
              />
            </View>
          </View>,
        );
      } else if (objc['field_type'] == 9) {
        var value = false;
        let id_Index = this.state.selectedAttributeArray.findIndex(
          x => x.id === objc['id'],
        );
        if (id_Index != -1) {
          value = this.state.selectedAttributeArray[id_Index]['value'];
        }
        views.push(
          <View style={{zIndex: viewTag}}>
            <View>
              <View style={{height: 20}} />
              {this.renderAttributeLableView(objc)}
              <View
                style={[
                  commonStyle.appColorFieldStyle,
                  {marginTop: 10, zIndex: 11},
                ]}>
                <Switch
                  onColor={colors.AppNewGreen}
                  onChange={val =>
                    this.valueFieldsChanged(
                      val,
                      a,
                      objc['id'],
                      objc['field_type'],
                    )
                  }
                  checked={value}
                  height={22}
                  width={50}
                />
              </View>
            </View>
          </View>,
        );
      } else if (objc['field_type'] == 10) {
        var value = '';
        let id_Index = this.state.selectedAttributeArray.findIndex(
          x => x.id === objc['id'],
        );
        if (id_Index != -1) {
          value = this.state.selectedAttributeArray[id_Index]['value'];
        }
        var colorView = [];
        let colorIndex = this.state.showColorPicker.findIndex(x => x === a);
        if (colorIndex != -1) {
          colorView.push(
            <View style={{zIndex: 12092 + id_Index}}>
              <SketchPicker
                color={value}
                onChangeComplete={color =>
                  this.handleChangeComplete(
                    color,
                    a,
                    objc['id'],
                    objc['field_type'],
                  )
                }
              />
            </View>,
          );
        } else {
          colorView.push(
            <TouchableOpacity
              style={commonStyle.appColorFieldStyle}
              onPress={() => this.showColorPickerBtnAction(a)}>
              <Text> {value.length == 0 ? 'Select Color' : value} </Text>
              <View
                style={{
                  height: 30,
                  width: 30,
                  borderRadius: 5,
                  backgroundColor: value,
                  borderWidth: 1,
                  borderColor: colors.Lightgray,
                }}
              />
            </TouchableOpacity>,
          );
        }
        views.push(
          <View style={{zIndex: viewTag}}>
            <View>
              <View style={{height: 20}} />
              <Text style={CommonStyleSheet.plainSubTextStyle}>
                {objc['name']}
              </Text>
              <View style={appConfigStyle.txtFieldStyle}>{colorView}</View>
            </View>
          </View>,
        );
      } else if (objc['field_type'] == 11) {
        var value = 'Choose file';
        let id_Index = this.state.selectedAttributeArray.findIndex(
          x => x.id === objc['id'],
        );
        if (id_Index != -1) {
          value = this.state.selectedAttributeArray[id_Index]['value'];
          value = value == '' ? 'Choose file' : value;
        }
        views.push(
          <View style={{zIndex: viewTag}}>
            <View>
              <View style={{height: 20}} />
              {this.renderAttributeLableView(objc)}
              <View style={[appConfigStyle.dottedViewStyle, {zIndex: 1}]}>
                <ReactFileReader
                  fileTypes={'.json'}
                  base64={true}
                  style={appConfigStyle.dottedViewStyle}
                  handleFiles={img =>
                    this.jsonFilePickAction(
                      img,
                      a,
                      objc['id'],
                      objc['field_type'],
                    )
                  }>
                  <View style={{height: 5}} />
                  <View
                    style={{
                      paddingLeft: 10,
                      paddingRight: 10,
                      width: windowWidth / 3.5,
                    }}>
                    <Text style={appConfigStyle.chooseFileTxtStyle}>
                      {value}
                    </Text>
                  </View>
                  <View style={{height: 5}} />
                </ReactFileReader>
              </View>
            </View>
          </View>,
        );
      } else if (objc['field_type'] == 12) {
        var value = '';
        let id_Index = this.state.selectedAttributeArray.findIndex(
          x => x.id === objc['id'],
        );
        if (id_Index != -1) {
          value = this.state.selectedAttributeArray[id_Index]['value'];
        }
        if (this.state.latitude != 0.0) {
          value = `Latitude: ${this.state.latitude} Longitude: ${this.state.longitude}`;
        }
        views.push(
          <View style={{zIndex: viewTag}}>
            <View>
              <View style={{height: 20}} />
              {this.renderAttributeLableView(objc)}
              <View style={[appConfigStyle.txtFieldStyle, {zIndex: 2}]}>
                <TouchableOpacity
                  style={commonStyle.appColorFieldStyle}
                  onPress={() =>
                    this.setState({showMapView: !this.state.showMapView})
                  }>
                  <Text> {value.length == 0 ? 'Select Location' : value}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>,
        );
      }
    }
    return views;
  };

  renderStockView = () => {
    if (this.state.showStock) {
      return (
        <View style={appConfigStyle.listingTxtFieldContainerStyle}>
          <Text style={appConfigStyle.titleTxtStyle}>Stock</Text>
          <TextInput
            style={[appConfigStyle.listingTextFieldStyle, {outline: 0}]}
            placeholder="Enter Stock"
            placeholderTextColor={colors.placeholderColor}
            onChangeText={text => this.onHandleTextChange(text, 2)}
            value={this.state.stockValue}
          />
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderOfferView = () => {
    if (this.state.showOffer) {
      return (
        <View style={appConfigStyle.listingTxtFieldContainerStyle}>
          <Text style={appConfigStyle.titleTxtStyle}>Offer Percentage</Text>
          <TextInput
            style={[appConfigStyle.listingTextFieldStyle, {outline: 0}]}
            placeholder="Enter offer"
            placeholderTextColor={colors.placeholderColor}
            onChangeText={text => this.onHandleTextChange(text, 1)}
            value={this.state.offerPercentage}
            onBlur={() => this.offerPercentageEndEditing()}
          />
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderMaxQntyView = () => {
    if (this.state.showMAxQty) {
      return (
        <View style={appConfigStyle.listingTxtFieldContainerStyle}>
          <Text style={appConfigStyle.titleTxtStyle}>
            Max Quantity (Optional)
          </Text>
          <TextInput
            style={[appConfigStyle.listingTextFieldStyle, {outline: 0}]}
            placeholder="Enter Max Quantity"
            placeholderTextColor={colors.placeholderColor}
            onChangeText={text => this.onHandleTextChange(text, 3)}
            value={this.state.maxQuantity}
          />
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderTagView = () => {
    if (this.state.showTag) {
      return (
        <View style={{width: '100%', alignItems: 'flex-start'}}>
          <Text style={CommonStyleSheet.plainTextStyle}>Tags</Text>
          <View style={styles.firstViewStyle}>
            <View style={{height: 150, padding: 16}}>
              <TagsInput
                value={this.state.tags}
                addKeys={[188, 9, 13]}
                addOnPaste={true}
                pasteSplit={data => {
                  const separators = [
                    ',',
                    ';',
                    '\\(',
                    '\\)',
                    '\\*',
                    '/',
                    ':',
                    '\\?',
                    '\n',
                    '\r',
                  ];
                  return data
                    .split(new RegExp(separators.join('|')))
                    .map(d => d.trim());
                }}
                onChange={txt => this.onTagsChanged(txt)}
              />
            </View>
          </View>
          <View style={{height: 20}} />
        </View>
      );
    } else {
      return <View />;
    }
  };
  onChangeDescription(txt) {
    this.setState({descriptionString: txt});
  }
  renderDescriptionView = () => {
    return (
      <View>
        {/* <Text style={appConfigStyle.titleTxtStyle}>Description </Text> */}
        <DescriptionView
          value={this.state.descriptionString}
          onChange={txt => this.onChangeDescription(txt)}
        />
      </View>
    );
  };
  mediaManagerView = () => {
    if (this.state.showMediaManager) {
      return (
        <View
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 9920,
          }}>
          <MediaManagerView
            mediaData={this.state.mediaArray}
            closeBtn={() => this.setState({showMediaManager: false})}
            onSelectBtn={path => this.didSelectMediaManager(path)}
          />
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderCurrencyView = () => {
    if (this.state.currencyArray.length > 1) {
      return (
        <View style={{margin: 16}}>
          <Text style={appConfigStyle.titleTxtStyle}>Currency</Text>
          <TouchableOpacity
            style={[styles.dropDownViewStyle, {borderWidth: 1}]}
            onPress={() => this.dropdownBtnAction(0)}>
            <this.renderValueLabel id={0} />
            <this.customDropDown id={0} />
          </TouchableOpacity>
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderMapView = () => {
    if (this.state.showMapView) {
      return (
        <View style={styles.mapViewContainerStyle}>
          <MapPicker
            defaultLocation={DefaultLocation}
            zoom={DefaultZoom}
            mapTypeId="roadmap"
            style={{height: '700px', width: '50%'}}
            onChangeLocation={(lat, lng) => this.handleChangeLocation(lat, lng)}
            apiKey={AppConstants.googleMapAPIKey}
          />
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderSlugField = () => {
    if (this.state.showSlug) {
      return (
        <View style={styles.marginStyleView}>
          {/* <Text style={appConfigStyle.titleTxtStyle}>Slug</Text> */}
          <TextInput
            style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
            placeholder="Enter slug "
            placeholderTextColor={colors.placeholderColor}
            onChangeText={txt => this.setState({slugValue: txt})}
            value={this.state.slugValue}
          />
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderMetaData = () => {
    return (
      <View
        style={{
          width: '100%',
          display: this.state.showMetaTitle ? 'flex' : 'none',
        }}>
        <Text style={CommonStyleSheet.plainTextStyle}>Custom SEO</Text>
        <View style={[styles.firstViewStyle]}>
          <View
            style={[
              styles.marginStyleView,
              {display: this.state.showMetaTitle ? 'flex' : 'none'},
            ]}>
            {/* <Text style={appConfigStyle.titleTxtStyle}>Meta Title</Text> */}
            <TextInput
              style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
              placeholder="Enter meta title "
              placeholderTextColor={colors.placeholderColor}
              onChangeText={txt => this.onHandleTextChange(txt, 5)}
              value={this.state.metaTitle}
            />
          </View>
          <View
            style={[
              styles.marginStyleView,
              {
                marginBottom: 16,
                display: this.state.showMetaDescription ? 'flex' : 'none',
              },
            ]}>
            {/* <Text style={appConfigStyle.titleTxtStyle}>Meta Description</Text> */}
            <DescriptionView
              value={this.state.metaDescriptionString}
              onChange={txt => this.onHandleTextChange(txt, 6)}
            />
          </View>
        </View>
      </View>
    );
  };
  renderPreviewView = () => {
    var imageViews = [];
    for (let i = 0; i < this.state.imagesArray.length + 1; i++) {
      let imageObj = {};
      if (this.state.imagesArray[i]) {
        imageObj = this.state.imagesArray[i];
      }
      if (JSON.stringify(imageObj) !== '{}') {
        let fileName = imageObj['image'] ?? null;
        var showImage = true;
        var icon = jsonIcon;
        var fileExt = '';
        if (fileName == null) {
          let name = imageObj.substring(imageObj.lastIndexOf('/') + 1);
          var fileExt = name.split('.').pop();
        } else {
          let name = imageObj['name'].replace(/ /g, '');
          fileExt = name.split('.').pop();
        }
        if (fileExt == 'json') {
          icon = jsonIcon;
          showImage = false;
        } else if (fileExt == 'pdf') {
          icon = pdfIcon;
          showImage = false;
        }
        imageViews.push(
          <View>
            <Image
              source={
                showImage
                  ? {uri: fileName == null ? imageObj : imageObj['image']}
                  : icon
              }
              style={previewStyleSheet.previewImageStyle}
            />
          </View>,
        );
      }
    }
    let ticket =
      this.state.stockValue.length == 0 ? '' : `Only ${this.state.stockValue}`;
    let price =
      this.state.listPriceValue.length == 0
        ? ''
        : `${this.state.selectedCurrencyCODE} ${this.state.listPriceValue}`;
    var descpView = [];
    if (this.state.descriptionString.length != 0) {
      descpView.push(
        <View style={previewStyleSheet.commonPreviewStyle}>
          <Text style={previewStyleSheet.previewsubTitleStyle}>
            {this.state.descriptionString}
          </Text>
        </View>,
      );
    }

    return (
      <View style={{width: 320, height: '70%'}}>
        <View style={{flexDirection: 'column'}}>
          <View style={{flexDirection: 'row', justifyContent: 'center'}}>
            <Text style={tableStyle.listTitleText}>Preview</Text>
          </View>
          <View style={previewStyleSheet.previewFrameStyle}>
            <View style={{justifyContent: 'center'}}>
              <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}>
                <View
                  style={{flexDirection: 'row', width: '100%', height: 260}}>
                  {imageViews}
                </View>
              </ScrollView>
              <View style={previewStyleSheet.commonPreviewStyle}>
                <Text style={previewStyleSheet.previewTitleStyle}>
                  {this.state.titleString}
                </Text>
                <View style={{height: 5}} />
                <Text style={previewStyleSheet.previewTitleStyle}>{price}</Text>
                <View style={{height: 5}} />
                <Text
                  style={[
                    CommonStyleSheet.helperTextStyle,
                    {color: colors.AppNewGreen},
                  ]}>
                  {ticket}
                </Text>
              </View>
              <View style={{height: 10}} />
              {descpView}
              <View style={{height: 10}} />
            </View>
          </View>
        </View>
      </View>
    );
  };
  renderScheduleView = () => {
    var scheduleView = [];
    var display = 'none';
    for (let a = 0; a < this.state.scheduleArray.length; a++) {
      display = 'flex';
      let item = this.state.scheduleArray[a];
      let date = Moment(item['startDate']).format('ddd MMM Do YYYY');
      let repeat = item['repeatClass']['name'];
      let timeSlots = item['timeSlots']; //getTimeDifferenceArray(item['startTime'],item['endTime'],item['slot'])
      var slotsView = [];
      for (let objc of timeSlots) {
        slotsView.push(
          <Text
            style={[
              CommonStyleSheet.subTitleStyle,
              {color: 'gray', marginBottom: 5},
            ]}>{`${objc['start']} ${objc['end']}`}</Text>,
        );
      }
      scheduleView.push(
        <View
          style={{
            flexDirection: 'row',
            borderRadius: 5,
            borderWidth: 1,
            borderColor: colors.SimonGray,
            margin: 16,
            padding: 5,
          }}>
          <View style={{width: 30, alignItems: 'center'}}>
            <Image style={{width: 30, height: 30}} source={calendarIcon} />
          </View>
          <View style={{flex: 1, marginLeft: 10}}>
            <Text style={commonStyle.plainSubTextStyle}>
              Starts From:{' '}
              <Text style={commonStyle.plainSubTextStyle}>{date}</Text>
            </Text>
            <View style={{height: 10}} />
            {slotsView}
            {/* <View style={{ height: 5 }} /> */}
            <Text style={commonStyle.plainSubTextStyle}>{repeat}</Text>
          </View>
          <View style={{width: 30, alignItems: 'center'}}>
            <TouchableOpacity onPress={() => this.deleteScheduleItem(a)}>
              <Image style={{width: 16, height: 20}} source={deleteIcon} />
            </TouchableOpacity>
          </View>
        </View>,
      );
    }
    return (
      <View style={[styles.firstViewStyle, {zIndex: 10, display: display}]}>
        {scheduleView}
      </View>
    );
  };
  renderAddScheduleBtn = () => {
    return (
      <View style={[styles.firstViewStyle, {borderWidth: 0}]}>
        <View style={tableStyle.secondButtonViewStyle}>
          <TouchableOpacity onPress={() => this.addScheduleBtnAction()}>
            <Text style={tableStyle.secondBtnTextStyle}>
              {'+ Add Schedule'}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  renderSaveScheduleBtn = () => {
    return (
      <View style={[tableStyle.gradientViewBGStyle, {marginRight: 0}]}>
        <Gradient
          gradients={colors.GradientColors} // required
          property="background"
          gradientType={'linear'}
          duration={2333000}
          angle="0deg">
          <TouchableOpacity onPress={() => this.saveScheduleBtnAction()}>
            <Text style={tableStyle.saveBtnTxtStyle}>Save</Text>
          </TouchableOpacity>
        </Gradient>
      </View>
    );
  };
  renderweekDays = () => {
    var weekView = [];
    for (let a = 0; a < ConstantArray.weekDays.length; a++) {
      let week = ConstantArray.weekDays[a];
      let ind = this.state.selectedWeekDay.findIndex(x => x.id == a + 1);
      let check = ind != -1 ? true : false;
      weekView.push(
        <TouchableOpacity
          style={check ? styles.weekdaysStyle : styles.selectedWeekdaysStyle}
          onPress={() => this.didSelectWeekdays(week)}>
          <Text
            style={[
              CommonStyleSheet.subTitleStyle,
              {color: colors.AppTitleBlack},
            ]}>
            {week['name']}
          </Text>
        </TouchableOpacity>,
      );
    }
    return <View style={styles.weekendViewStyle}>{weekView}</View>;
  };
  renderRepeatView = id => {
    let repVl = this.state.selectedRepeatIndex;
    return (
      <View>
        <View style={{zIndex: 1001}}>
          <Text style={commonStyle.scheduleTxtStyle}>{'Repeat'}</Text>
          <TouchableOpacity
            style={[styles.dropDownViewStyle, {marginTop: 5}]}
            onPress={() =>
              this.setState({
                showScheduleRepeatBool: !this.state.showScheduleRepeatBool,
              })
            }>
            <this.renderRepeatValueLabel />
            <this.customRepeatDropDown />
          </TouchableOpacity>
        </View>
        <View
          style={{
            display: repVl == 3 ? 'flex' : 'none',
            zIndex: 101,
            marginTop: 10,
          }}>
          {this.renderweekDays()}
        </View>
      </View>
    );
  };
  renderRepeatValueLabel = () => {
    let repeatValue =
      this.state.selectedRepeatIndex == -1
        ? 'Select Value'
        : ConstantArray.repeatArray[this.state.selectedRepeatIndex]['name'];
    return (
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <Text
          style={
            this.state.selectedRepeatIndex == -1
              ? [commonStyle.placeholderTxtStyle, {outline: 0}]
              : [
                  commonStyle.placeholderTxtStyle,
                  {outline: 0, color: colors.AppTitleBlack},
                ]
          }>
          {repeatValue}
        </Text>
        <Image
          style={
            this.state.showScheduleRepeatBool == true
              ? commonStyle.upArraowIconStyle
              : commonStyle.downArraowIconStyle
          }
          resizeMode="contain"
          source={downArrow_Icon}
        />
      </View>
    );
  };
  customRepeatDropDown = id => {
    if (this.state.showScheduleRepeatBool == true) {
      return (
        <View style={commonStyle.showDropDownViewStyle}>
          <FlatList
            data={ConstantArray.repeatArray}
            horizontal={false}
            renderItem={this.renderRepeatDropDownCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderRepeatDropDownCell = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectRepeat(index)}
        style={{margin: 5, height: 30, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text style={commonStyle.dropdownCellItemTextStyle}>
            {item['name']}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderSlotLable = id => {
    let placeholder = 'Slot';
    let textValue = this.state.selectedSlot;
    var views = [];
    views.push(
      <Image
        style={
          this.state.showSlotBool == true
            ? appConfigStyle.upArraowIconStyle
            : appConfigStyle.downArraowIconStyle
        }
        resizeMode="contain"
        source={downArrow_Icon}
      />,
    );
    return (
      <View style={styles.dropdownLabelStyle}>
        <Text
          style={
            textValue == ''
              ? [
                  appConfigStyle.placeholderTxtStyle,
                  CommonStyleSheet.subTitleStyle,
                  {marginLeft: 0},
                ]
              : commonStyle.scheduleTxtStyle
          }>
          {textValue == '' ? placeholder : textValue}
        </Text>
        {views}
      </View>
    );
  };
  renderSlotDropDown = id => {
    if (this.state.showSlotBool == true) {
      return (
        <View
          style={[
            commonStyle.showDropDownViewStyle,
            {marginLeft: -10, marginTop: 28, height: 200},
          ]}>
          <FlatList
            data={slotArray}
            horizontal={false}
            renderItem={this.renderSlotDropDownCell}
            extraData={this.state}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderSlotDropDownCell = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectSlot(item['name'])}
        style={{margin: 5, height: 30, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text style={commonStyle.dropdownCellItemTextStyle}>
            {item['name']}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderTimeDropDown = ({id}) => {
    let timeArray = getTimeArray();
    if (this.state.showTimeBool[id] == true) {
      return (
        <View
          style={[
            commonStyle.showDropDownViewStyle,
            {marginLeft: 0, marginTop: 0},
          ]}>
          <FlatList
            data={timeArray}
            horizontal={false}
            renderItem={this.renderTimeDropDownItem}
            extraData={this.state}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderTimeDropDownItem = ({item, index}) => {
    let check = this.state.showTimeBool.findIndex(x => x == true);
    return (
      <TouchableOpacity
        onPress={() =>
          check == 1
            ? this.didSelectEndTime(item)
            : this.didSelectStartTime(item)
        }
        style={{margin: 5, height: 30, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text style={commonStyle.dropdownCellItemTextStyle}>{item}</Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderAddScheduleView = () => {
    let scheduleDate = Moment(this.state.scheduleStartDate).format(
      'DD-MM-yyyy',
    );
    // let scheduleEndDate = Moment(this.state.scheduleEndDate).format('DD-MM-yyyy');

    return (
      <View
        style={[
          tableStyle.passwordViewStyle,
          {display: this.state.showAddScheduleViewBool ? 'flex' : 'none'},
        ]}>
        <View
          style={{
            backgroundColor: colors.AppWhite,
            width: 400,
            borderRadius: 5,
          }}>
          <TouchableOpacity
            style={styles.closeBtnViewStyle}
            onPress={() => this.setState({showAddScheduleViewBool: false})}>
            <Image style={tableStyle.backBtnStyle} source={closeIcon} />
          </TouchableOpacity>
          <Text style={[commonStyle.plainTextStyle, {extAlign: 'center'}]}>
            Add Schedule
          </Text>
          <View style={{marginTop: 2, padding: 20}}>
            <View
              style={{
                zIndex: 9002,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
              <View>
                <Text style={commonStyle.scheduleTxtStyle}>Start Date</Text>
                <View
                  style={[
                    appConfigStyle.txtFieldStyle,
                    {marginTop: 5, width: 100},
                  ]}>
                  <View style={{position: 'absolute'}}>
                    <DatePicker
                      selected={this.state.scheduleStartDate}
                      onChange={date => this.didSelectDate(date, 1)}
                      dateFormat="MM/dd/yyyy"
                      showTimeInput={false}
                      onCalendarClose={() => this.setState({calenderOn: false})}
                      onCalendarOpen={() => this.setState({calenderOn: true})}
                      customInput={
                        <View style={{height: 20, width: 100}}>
                          {' '}
                          <Text>{scheduleDate}</Text>
                        </View>
                      }
                    />
                  </View>
                </View>
              </View>
              {/* <View>
              <Text style={styles.scheduleTxtStyle}>End Date</Text>
              <View style={[appConfigStyle.txtFieldStyle, { marginTop: 5, width: 100 }]}>
                <View style={{ position: 'absolute' }}>
                  <DatePicker
                    selected={this.state.scheduleEndDate}
                    onChange={(date) => this.didSelectDate(date,2)}
                    dateFormat="MM/dd/yyyy"
                    showTimeInput={false}
                    onCalendarClose={() => this.setState({ calenderOn: false })}
                    onCalendarOpen={() => this.setState({ calenderOn: true })}
                    customInput={<View style={{ height: 20, width: 100 }}> <Text>{scheduleEndDate}</Text>
                    </View>}
                  />
                </View>
              </View>
            </View> */}
            </View>
            <View
              style={{
                flexDirection: 'row',
                zIndex: 8012,
                justifyContent: 'space-between',
              }}>
              <View style={{zIndex: 1, marginTop: 10}}>
                <Text style={commonStyle.scheduleTxtStyle}>{'Start Time'}</Text>
                <TouchableOpacity
                  style={[
                    appConfigStyle.txtFieldStyle,
                    {
                      marginTop: 5,
                      width: 100,
                      flexDirection: 'row',
                      alignItems: 'center',
                    },
                  ]}
                  onPress={() => this.openTimePicker(0)}>
                  <Text style={commonStyle.scheduleTxtStyle}>
                    {this.state.scheduleStartTime}
                  </Text>
                </TouchableOpacity>
                <View>
                  <this.renderTimeDropDown id={0} />
                </View>
              </View>
              <View style={{zIndex: 2, marginTop: 10, marginLeft: 25}}>
                <Text style={commonStyle.scheduleTxtStyle}>{'End Time'}</Text>
                <TouchableOpacity
                  style={[
                    appConfigStyle.txtFieldStyle,
                    {
                      marginTop: 5,
                      width: 100,
                      flexDirection: 'row',
                      alignItems: 'center',
                    },
                  ]}
                  onPress={() => this.openTimePicker(1)}>
                  <Text style={commonStyle.scheduleTxtStyle}>
                    {this.state.scheduleEndTime}
                  </Text>
                </TouchableOpacity>
                <View>
                  <this.renderTimeDropDown id={1} />
                </View>
              </View>
              <View style={{zIndex: 9812, marginTop: 10, marginLeft: 25}}>
                <Text style={commonStyle.scheduleTxtStyle}>{'Slot'}</Text>
                <TouchableOpacity
                  style={[
                    appConfigStyle.txtFieldStyle,
                    {marginTop: 5, width: 100, flexDirection: 'row'},
                  ]}
                  onPress={() =>
                    this.setState({showSlotBool: !this.state.showSlotBool})
                  }>
                  <this.renderSlotLable />
                  <this.renderSlotDropDown />
                </TouchableOpacity>
              </View>
            </View>
            <View style={{zIndex: 6, marginTop: 10}}>
              {this.renderRepeatView()}
            </View>
            <View style={{zIndex: 4, alignItems: 'center', marginTop: 20}}>
              {this.renderSaveScheduleBtn()}
            </View>
          </View>
        </View>
      </View>
    );
  }
  renderSaveBtnView = () => {
    const isEdit = this.props.isEdit;
    return <View>
      <SaveButton
        saveBtn={() => this.submitBtnAction()}
        showSaveBtn={true}
        saveBtnTitle={isEdit ? 'Update' : 'Save'} />
    </View>
  }
  render() {
    const isEdit = this.props.isEdit;
    return (
      <SafeAreaView style={styles.Container}>
        <ToastContainer />
        <this.renderAddScheduleView />
        {/* <Header_View
          title={isEdit ? 'Edit Listing' : 'Add Listing'}
          backBtn={this.props.backBtnAction}
          saveBtnTitle={isEdit ? 'Update' : 'Save'}
          saveBtn={() => this.submitBtnAction()}
        /> */}
        <View style={tableStyle.navigationViewStyle}>
          <ScrollView horizontal={true}>
            <View style={tableStyle.headerContainer}>
              <View style={tableStyle.headerListViewcontainer}>
                <TouchableOpacity
                  style={tableStyle.backBtnBackViewStyle}
                  onPress={this.props.backBtnAction}>
                  <Image
                    style={tableStyle.backBtnStyle}
                    resizeMode="center"
                    source={backIcon}
                  />
                </TouchableOpacity>
                <View style={{padding: 10}}>
                  <Text style={tableStyle.titleTextStyle}>
                    {isEdit ? 'Edit Listing' : 'Add Listing'}
                  </Text>
                </View>
              </View>
              <View
                style={{ flexDirection: 'row',  marginLeft: itsMobileView ? 90 : 0}}>
                {/* {this.renderDraftBtnView()} */}
                <View style={{width: 10}} />
                {this.renderSaveBtnView()}
              </View>
            </View>
          </ScrollView>
        </View>
        <View style={{height: windowHeight / 1.16}}>
          <ScrollView>
            <View style={{flexDirection: itsMobileView ? 'column' : 'row'}}>
              {this.mediaManagerView()}
              {this.renderMapView()}
              <View style={styles.leftMainView}>
                {/* <Text style={styles.titleStyle}>Listing Data</Text> */}
                <View style={styles.firstViewStyle}>
                  <View style={styles.marginStyleView}>
                    {/* <Text style={appConfigStyle.titleTxtStyle}>Title</Text> */}
                    <TextInput
                      style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
                      placeholder="Enter title "
                      placeholderTextColor={colors.placeholderColor}
                      onChangeText={txt => this.setState({titleString: txt})}
                      value={this.state.titleString}
                    />
                  </View>
                  {this.renderSlugField()}
                  <View style={{margin: 16, marginTop: 0}}>
                    {this.renderDescriptionView()}
                  </View>
                </View>
                <this.renderListingImageView />
                <View style={{height: 26}} />
                {this.renderTagView()}
                {this.renderMetaData()}
              </View>
              <View style={styles.mainView}>
                <View style={styles.firstViewStyle}>
                  <View style={{zIndex: 1234}}>
                    {this.renderCurrencyView()}
                  </View>
                  <View
                    style={[
                      appConfigStyle.listingTxtFieldContainerStyle,
                      {zIndex: 1},
                    ]}>
                    <Text style={appConfigStyle.titleTxtStyle}>List Price</Text>
                    <TextInput
                      style={[
                        appConfigStyle.listingTextFieldStyle,
                        {outline: 0},
                      ]}
                      placeholder="Enter List Price"
                      placeholderTextColor={colors.placeholderColor}
                      onChangeText={text => this.onHandleTextChange(text, 0)}
                      value={this.state.listPriceValue}
                    />
                  </View>
                  <View style={{zIndex: 2}}>{this.renderOfferView()}</View>
                  <View style={{zIndex: 2}}>{this.renderStockView()}</View>
                  <View style={{zIndex: 2}}>{this.renderMaxQntyView()}</View>
                </View>
                <View style={{height: 20}} />
                {/* <Text style={styles.titleStyle}>Listings Detail</Text> */}
                <View style={styles.firstViewStyle}>
                  <View style={{margin: 16, zIndex: 120}}>
                    <Text style={appConfigStyle.titleTxtStyle}>Category</Text>
                    <View style={styles.dropDownViewStyle}>
                      <View style={{width: '100%'}}>
                        <SelectSearchView
                          value={this.state.selectedCategoryArray}
                          onChange={itm => this.handleSelectChange(itm)}
                          options={this.state.categoriesArray}
                          isMulti={false}
                          placeholder={`Select category`}
                          styles={colourStyles}
                          theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: colors.AppGreenBorder,
                              primary: colors.AppGreenBorder,
                            },
                          })}
                        />
                      </View>
                    </View>
                  </View>
                  <View style={{margin: 16, zIndex: 110}}>
                    <this.renderArttributes />
                  </View>
                </View>
                <this.renderScheduleView />
                <View
                  style={{ zIndex: 10,display: AppConstants.moduleType == ModuleType.event ? 'flex' : 'none' }}>
                  <this.renderAddScheduleBtn />
                </View>
              </View>
              {/* <View style={{width: 1, height: '100%', backgroundColor: colors.SimonGray}} /> */}
              {/* <this.renderPreviewView /> */}
            </View>
          </ScrollView>
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}

const colourStyles = {
  control: styles => ({...styles, backgroundColor: 'white', borderRadius: 5}),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? colors.SimonGray
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? colors.SimonGray
          ? colors.AppRed
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : colors.Lightgray),
      },
    };
  },
  multiValueLabel: (styles, {data}) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, {data}) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: colors.AppRed,
    },
  }),
};

let slotArray = [
  {name: '15', id: 0},
  {name: '30', id: 1},
  {name: '45', id: 2},
  {name: '60', id: 3},
  // { 'name': 'Custom', 'id': 4 }
];
