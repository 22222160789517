module.exports = {
  businessDetailTitle: 'Business Details',
  businessDetaiDescription: 'The business informations will be used to provide invoices, legal, etc',
  localTitle: 'Locale',
  locaDescription: 'Enter your default languages and currency of the app / platform. You can configure multiple languages and multiple currency later in the settings. OR you can also change the default status as well.',
  businessModelTitle: 'Business Model',
  businessModelDescription: 'Choosing a business model provides with the right set of features in Admin Panel.\n\n• B2C business are platforms which sells directly to consumers.  Examples are H&M, Zara, Apple\n\n• C2C business are platforms which act as a platform to facilitate buy and sell. Examples: Etsy, Depop, Kickstarter, Udemy, etc\n\n•  Enterprises mostly have custom modelling where they have hybrid of B2C, C2C, B2B.',
  mapTitle: 'Map',
  mapDescription: 'User able to add location in different scenarios to help the selling and buying experiences.\n\n• Accounts able to add locations while their creating the accounts, this helps when buyers find nearby accounts (on collection widget) \n\n• Accounts able to add location while adding listings/products (They can opt to remove as well), so their items shows up in listing search.\n\nThere are few ways you can configure them to achieve your business\n\nMaps we support\n\n1. Google Maps (Places API)\n2. Openstreet map\n3. iOS use Native apple maps.\n\nPlaces currently available\n\n1. Account creation (Example: Store creation)\n2. Listing creation (Example: Product or a property having a location field)\n3. Address (shipping address, pickup address)',
  shippingTitle: 'Shipping / Delivery of Product & Services',
  shippingDescription: 'There are multiple shipping and delivery method for your platform to choose from. You can rename this names how your users later in the settings.\n\nHere are the most common methods\n\n- Pick up logic provides the seller to choose where the buyer need to come. Or say the service provider choose where the customer need to come. Example: Buy online and Pickup, Buy online and Pick up at restaurant, Buy online and pick up at warehouse \n\n- Delivery is the standard approach where the buyer specific the address the item to be delivered. Also the customer choose where and which mode the service is going to be delivered. Example: Door delivery, Spa at house, Drilling work at house \n3. Storage Hub is like warehouse and platform owner pickup points. In these points, your sellers can drop off at this point. And your buyers can pick up from the same point. Similarly, your logistic partners can pick up this as well. You may use this points for product auditing, packaging, etc. It is up to your business to use how they prefer. Other terms for Storage Hub (Parcel Lockers, Pickup Points, Collection Points)',
  paymentTitle: 'Payment Methods',
  paymentDescription: '• A platform can have only single payment system. Payment Gateways can provide single destination charge or multiple destination charges\n\n• A single destination means all the transactions happening in the platform will go to Platform bank account. And Platform owner need to do the payout manually. Scenarios where the payment gateway in your country does not provide payout option, then you can opt for single destination method. Most of the payment gateways are single destination providers. Example: PayU, PayDunya, Paypal \n\n• Multiple destination means when a buyer buy an item from a seller, there is 3 things can be possible with payment gateways like Stripe. The money can be routed to seller, The commission can be routed to platform owner, The delivery fee can be routed to delivery boy or service providers. This concept is called separate charges and transfers coined by Stripe. ',
  registerTitle: 'Registration Type',
  registerDescription: 'A user who wants to register inside your apps to start using your services. \n\nDepends on the business nature, you may want to have different registration system for your users to register. We have the below list of registrations available in the app\n\nNative email registration : An user can just any email which is not connected to 3rd party logins to Sign in/signup\n\nPhone number based registration: A user can sign in/signup using their phone number with OTP verification in their first registration. In the subsequent sign in, the verified phone number and password can be used to sign in.',
  theme: 'Theme',
  themeDescription: 'Modules are type of business >  Serving different needs (tickets, booking, physical products). \n\nIn a no-code environment, example in SuperAdmin or pre-built apps based on your tenant registration, we show the UI.\n\nFor example in web App:\n\n- If your tenant MODULE is event marketplace, butterflies will show event marketplace-based app.\n\n- If tenant MODULE is product marketplace, butterflies will show product marketplace\n\n- If tenant flavour is B2C, then the accounts(multivendor or multi store) won’t be shown there.\n\nNOTE: This is not applicable for API Solution. You can use as you like. ',
}