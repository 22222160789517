import React, {Component, useCallback} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  TextInput,
  Dimensions,
  Linking,
  Platform,
  SafeAreaView,
  Alert,
} from 'react-native';
import NaviHeader from '../../Component/NavigationHeader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import ReactFileReader from 'react-file-reader';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {SketchPicker} from 'react-color';
import {AppToast} from '../../HelperClasses/AppToast';
import AppConstants from '../../Constants/AppConstants';
import infoIcon from '../../assets/infoIcon.png';
import PreviewStyleSheet from '../../StyleSheets/PreviewStyleSheet';
import { previewStatusBarView } from '../../Component/CommonComponents';
import SettingsStyleSheet from '../../StyleSheets/SettingStyleSheet';
import { p1, t1 } from '../../StyleSheets/TextTitleStyleSheet';


const APPURL = require('../../Constants/URLConstants');


let url = 'https://tradly.app';
// const uPref = new userPref();

export default class AppDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAppIconAndroid: null,
      selectedAppIconIos: null,
      onBoardingTextArray: [{text: ''}, {text: ''}, {text: ''}],
      backendData: [],
      updateUI: false,
      pagerIndex: 0,
      showPrimaryColorPicker: false,
      showSecondaryColorPicker: false,
      dataLoad: false,
      selectedPrimaryColor: '',
      selectedSecondaryColor: '',
      appName: '',
    };
  }
  componentDidMount() {
    this.clientTanantsApi();
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  clientTanantsApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.clients, 'get', '',
      AppConstants.BearerForTantantsAndUserDetail,
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['tenants'];
      AppConstants.BearerAuthorization = configs[0]['admin_key'];
    }
    this.setState({dataLoad: true});
  };
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=onboarding', 'get', '', '');
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      // console.log('configs', configs);
      this.setState({
        appName: configs['app_name'] || '',
        selectedPrimaryColor: configs['app_color_primary'] || '',
        selectedSecondaryColor: configs['app_color_secondary'] || '',
        selectedAppIconAndroid: configs['app_icon_android'] || null,
        selectedAppIconIos: configs['app_icon_ios'] || null,
      });
    }
    this.setState({dataLoad: true});
  };
  uploadFilesAPI = async () => {
    var imgParm = [];
    var uploadBase64 = [];
    var uploadDataParm = [];
    if (this.state.selectedAppIconAndroid != null) {
      let fileName = this.state.selectedAppIconAndroid.base64;
      if (fileName != null) {
        var androidIconDict = {
          name: this.state.selectedAppIconAndroid['fileList'][0]['name'],
          type: this.state.selectedAppIconAndroid['fileList'][0]['type'],
          // image: this.state.selectedAppIconAndroid.base64,
        };
        uploadBase64.push({
          file: this.state.selectedAppIconAndroid.base64,
          key: 'app_icon_android',
        });
        imgParm.push(androidIconDict);
      } else {
        let fDict = {
          key_group: 'onboarding',
          key: 'app_icon_android',
          value: this.state.selectedAppIconAndroid,
          secured: false,
        };
        uploadDataParm.push(fDict);
      }
    }
    if (this.state.selectedAppIconIos != null) {
      let fileName = this.state.selectedAppIconIos.base64;
      if (fileName != null) {
        var iosIconDict = {
          name: this.state.selectedAppIconIos['fileList'][0]['name'].replace(/ /g,''),
          type: this.state.selectedAppIconIos['fileList'][0]['type'],
        };
        uploadBase64.push({
          file: this.state.selectedAppIconIos.base64,
          key: 'app_icon_ios',
        });
        imgParm.push(iosIconDict);
      }else {
        let fDict = {
          key_group: 'onboarding',
          key: 'app_icon_ios',
          value: this.state.selectedAppIconIos,
          secured: false,
        };
        uploadDataParm.push(fDict);
      }
    }
    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL,
        'POST',
        JSON.stringify({files: imgParm}),
        '',
      );
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        var uploadIncrement = 0;
        for (let i = 0; i < imgParm.length; i++) {
          fetch(uploadBase64[i]['file']).then(async res => {
            const file_upload_res = await networkService.uploadFileWithSignedURL(
              result[i]['signedUrl'],
              imgParm[i]['type'],
              await res.blob(),
            );
            uploadIncrement++;
            let fDict = {
              key_group: 'onboarding',
              key: uploadBase64[i]['key'],
              value: result[i]['fileUri'],
              secured: false,
            };
            uploadDataParm.push(fDict);
            if (uploadIncrement === uploadBase64.length) {
              this.addOrUpdateAppDetailApi(uploadDataParm);
            }
          });
        }
      } else {
        this.alertView(responseJson);
      }
    } else {
      var uploadDataParm = [];
      var keyAry = ['app_icon_android', 'app_icon_ios'];
      var valueAry = [
        this.state.selectedAppIconAndroid || '',
        this.state.selectedAppIconIos || '',
      ];

      for (let i = 0; i < keyAry.length; i++) {
        if (valueAry[i] != null && valueAry[i].length != 0) {
          let fDict = {
            key_group: 'onboarding',
            key: keyAry[i],
            value: valueAry[i],
            secured: false,
          };
          uploadDataParm.push(fDict);
        }
      }
      this.addOrUpdateAppDetailApi(uploadDataParm);
    }
  };
  addOrUpdateAppDetailApi = async filesData => {
    var configs = [];
    if (filesData != undefined){
      configs = filesData;
    }
    let valueArray = [
      this.state.appName,
      this.state.selectedPrimaryColor,
      this.state.selectedSecondaryColor,
    ];
    let keysArray = ['app_name', 'app_color_primary', 'app_color_secondary'];
    // var configs = [];
    for (let i = 0; i < keysArray.length; i++) {
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: 'onboarding',
          key: keysArray[i],
          value: valueArray[i],
          secured: false,
        };
        configs.push(fDict);
      }
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config, 'post', JSON.stringify({configs: configs}), '');
    if (responseJson['status'] == true) {
      this.deleteKeysAPI(configs);
    } 
    this.setState({dataLoad: true});
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < parameterKeys.length; q++) {
      deleteParms.push(parameterKeys[q])
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key']
      let index = deleteParms.indexOf(name)
      deleteParms.splice(index, 1)
    }
    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms
      const responseJson = await networkService.networkCall(urlParm, 'delete')
      this.alertView('Uploaded successfully')
    } else {
      this.alertView('Uploaded successfully')
    }
    this.setState({dataLoad: true});
  };

  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast();
    } else {
      Alert.alert(title);
    }
  }

  //MARK:-  Buttons Action Method 
  saveBtnAction() {
    // console.log('calling appdetail =>');
    this.setState({dataLoad: false});
    this.uploadFilesAPI();
  }
  onPickFile = (image, id) => {
    // console.log('onPick =>', image, id);
    if (id == 2) {
      this.setState({selectedAppIconIos: image});
    } else {
      this.setState({selectedAppIconAndroid: image});
    }
  };
  infoButtonAction = async () => {
    if (Platform.OS == 'web') {
      window.open(url, '_blank');
    } else {
      const supported = await Linking.canOpenURL(url);
      if (supported) {
        await Linking.openURL(url, '_blank');
      } else {
        Alert.alert(`Don't know how to open this URL: ${url}`);
      }
    }
  };
  downloadBtnAction = (pathURL) => {
    // console.log('pathURL', pathURL);
    if (Platform.OS == 'web') {
      window.open(pathURL, '_blank');
    }
  }
  cancelBtnAction = (id) => {
    if (id == 2) {
      this.setState({selectedAppIconIos: null});
    } else {
      this.setState({selectedAppIconAndroid: null});
    }
  }
  /*  UI   */
  imageViewPicker = id => {
    // console.log('id', id);
    var fileMsg = 'Choose zip file';
    var showDownload = false;
    var URLLink = ''
    if (id['id'] == 0) {
      if (this.state.selectedAppIconAndroid != null) {
        if (this.state.selectedAppIconAndroid.fileList){
            fileMsg =
              this.state.selectedAppIconAndroid != null
                ? this.state.selectedAppIconAndroid.fileList[0]['name']
                : fileMsg;
        } else {
          let uri = this.state.selectedAppIconAndroid;
          URLLink = uri
          fileMsg = uri.substring(uri.lastIndexOf('/') + 1)
          showDownload = true
        }
      }
    
    } else if (id['id'] == 2) {
      if (this.state.selectedAppIconIos != null) {
        if (this.state.selectedAppIconIos.fileList){
          fileMsg =
            this.state.selectedAppIconIos != null
              ? this.state.selectedAppIconIos.fileList[0]['name']
              : fileMsg;
        } else {
          let uri = this.state.selectedAppIconIos;
          URLLink = uri
          fileMsg = uri.substring(uri.lastIndexOf('/') + 1)
          showDownload = true
        }
      }
    }

    var msgTxt = id['id'] == 1 ? 'Choose photo' : fileMsg;
    if (Platform.OS === 'web') {
      return (
        <View style={appConfigStyle.dottedViewStyle}>
          <ReactFileReader
            fileTypes={id['id'] == 1 ? ['.png', '.jpg'] : ['.zip']}
            base64={true}
            style={appConfigStyle.dottedViewStyle}
            handleFiles={file =>
              id['id'] == 1
                ? this.onPickSplash(file)
                : this.onPickFile(file, id['id'])
            }>
            <View style={{height:5}} />
            <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
            <View style={{height:5}} />
          </ReactFileReader>
          <this.renderDownloadView bool={showDownload} url={URLLink} id={id['id']}/>
        </View>
      );
    } else {
      return (
        <TouchableOpacity style={appConfigStyle.dottedViewStyle}>
          <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
        </TouchableOpacity>
      );
    }
  };
  renderDownloadView = (data) => {
    if (data['bool']) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity style={{ margin: 5 }} onPress={() => this.downloadBtnAction(data['url'])}>
            <Text style={[tableStyle.saveBtnTxtStyle,{ color: colors.AppNewGreen}]}>Download</Text>
          </TouchableOpacity>
          <TouchableOpacity style={{ margin: 5 }} onPress={() => this.cancelBtnAction(data['id'])}>
            <Text style={[tableStyle.saveBtnTxtStyle,{ color: colors.AppRed }]}>Cancel</Text>
          </TouchableOpacity>
        </View>
      );
    }else {
      return <View />
    }
  }

  handlePrimaryChangeComplete = color => {
    // console.log('color = >', color);
    if (Platform.OS === 'web') {
      this.setState({selectedPrimaryColor: color['hex']});
    }
  };
  handleSecondaryChangeComplete = color => {
    if (Platform.OS === 'web') {
      this.setState({selectedSecondaryColor: color['hex']});
    }
  };
  renderPrimaryColorPicker = id => {
    if (this.state.showPrimaryColorPicker == true) {
      if (Platform.OS === 'web') {
        return (
          <View style={{flexDirection: 'row'}}>
            <SketchPicker
              color={'#fff'}
              onChangeComplete={this.handlePrimaryChangeComplete}
            />
            <View style={{margin: 40}}>
              <View
                style={{
                  width: 120,
                  height: 120,
                  backgroundColor: this.state.selectedPrimaryColor,
                  marginBottom: 20,
                  borderRadius: 5,
                  borderWidth: 1,
                  borderColor: colors.Lightgray,
                }}></View>
              <TouchableOpacity
                style={appConfigStyle.submitViewBtnStyle}
                onPress={() =>
                  this.setState({
                    showPrimaryColorPicker: !this.state.showPrimaryColorPicker,
                  })
                }>
                <Text style={{color: 'white'}}>{'Select'}</Text>
              </TouchableOpacity>
            </View>
          </View>
        );
      }
    } else {
      return (
        <TouchableOpacity
          style={styles.appColorFieldStyle}
          onPress={() =>
            this.setState({
              showPrimaryColorPicker: !this.state.showPrimaryColorPicker,
            })
          }>
          <Text style={appConfigStyle.titleTxtStyle}>
            {this.state.selectedPrimaryColor}
          </Text>
          <View
            style={{
              height: 30,
              width: 30,
              borderRadius: 5,
              backgroundColor: this.state.selectedPrimaryColor,
              borderWidth: 1,
              borderColor: colors.Lightgray,
            }}
          />
        </TouchableOpacity>
      );
    }
  };
  renderSecondaryColorPicker = () => {
    if (this.state.showSecondaryColorPicker == true) {
      if (Platform.OS === 'web') {
        return (
          <View style={{flexDirection: 'row'}}>
            <SketchPicker
              color={'#fff'}
              onChangeComplete={this.handleSecondaryChangeComplete}
            />
            <View style={{margin: 40}}>
              <View
                style={{
                  width: 120,
                  height: 120,
                  backgroundColor: this.state.selectedSecondaryColor,
                  marginBottom: 20,
                  borderRadius: 5,
                  borderWidth: 1,
                  borderColor: colors.Lightgray,
                }}></View>
              <TouchableOpacity
                style={appConfigStyle.submitViewBtnStyle}
                onPress={() =>
                  this.setState({
                    showSecondaryColorPicker: !this.state
                      .showSecondaryColorPicker,
                  })
                }>
                <Text style={{color: 'white'}}>{'Select'}</Text>
              </TouchableOpacity>
            </View>
          </View>
        );
      }
    } else {
      return (
        <TouchableOpacity
          style={styles.appColorFieldStyle}
          onPress={() =>this.setState({ showSecondaryColorPicker: !this.state.showSecondaryColorPicker})}>
          <Text style={appConfigStyle.titleTxtStyle}>
            {this.state.selectedSecondaryColor}
          </Text>
          <View
            style={{
              height: 30,
              width: 30,
              borderRadius: 5,
              backgroundColor: this.state.selectedSecondaryColor,
              borderWidth: 1,
              borderColor: colors.Lightgray,
            }}
          />
        </TouchableOpacity>
      );
    }
  }
  renderPreviewView = () => {
    var imageViews = []
    if (this.state.selectedSplashImage != null) {
      let fileName = this.state.selectedSplashImage.base64;
        imageViews.push(<Image source={{ uri: fileName != null ? this.state.selectedSplashImage.base64 : this.state.selectedSplashImage }}
          style={{width:160, height:160}} />)
    }
    return (<View style={{ width: 320, height: '100%', alignSelf: 'center'}}>
      <View style={{ alignSelf: 'center' }}>
        <Text style={tableStyle.listTitleText}>Preview</Text>
      </View>
      <View style={[PreviewStyleSheet.previewFrameStyle, { backgroundColor: this.state.selectedPrimaryColor }]}>
        <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
          {/* {previewStatusBarView()} */}
          {imageViews}
        </View>
      </View>
      <View style={{height:40}}/>
    </View>
    )
  }

  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={SettingsStyleSheet.rightViewContainerStyle}>
          <View style={SettingsStyleSheet.rightViewSTyle}>
            <ScrollView style={{ paddingRight: 10 }}>
              <View style={{ margin: 10 }}>
                <Text style={tableStyle.listTitleText}>App Details</Text>
                <Text style={appConfigStyle.subTitleStyle}>
                  {AppConstants.plain2TextString}
                </Text>
                <View style={appConfigStyle.subViewStyle}>
                  <View style={{ width: '100%' }}>
                    <View style={{ marginTop: 16 }}>
                      <Text style={appConfigStyle.titleTxtStyle}>App Name</Text>
                      <TextInput
                        value={this.state.appName}
                        style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                        onChangeText={name => this.setState({ appName: name })}
                      />
                    </View>
                    <View style={{ marginTop: 16 }}>
                      <Text style={appConfigStyle.titleTxtStyle}>
                        App Primary Colour
                      </Text>
                      <this.renderPrimaryColorPicker />
                    </View>
                    <View style={{ marginTop: 16 }}>
                      <Text style={appConfigStyle.titleTxtStyle}>
                        App Secondary Colour
                      </Text>
                      <this.renderSecondaryColorPicker />
                    </View>
                    <View style={{ marginTop: 16 }}>
                      <Text style={appConfigStyle.titleTxtStyle}>
                        App Icon Android
                        <TouchableOpacity onPress={() => this.infoButtonAction()}>
                          <Image style={styles.infoIcon} source={infoIcon} />
                        </TouchableOpacity>
                      </Text>
                      <this.imageViewPicker id={0} />
                    </View>
                    <View style={{ marginTop: 16 }}>
                      <Text style={appConfigStyle.titleTxtStyle}> App Icon iOS
                        <TouchableOpacity onPress={() => this.infoButtonAction()}>
                          <Image style={styles.infoIcon} source={infoIcon} />
                        </TouchableOpacity>
                      </Text>
                      <this.imageViewPicker id={2} />
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
          <View style={{width:1, backgroundColor:colors.SimonGray}} />
          <View style={{flex: 1, marginTop:-10 }}>
            <ScrollView>
              <this.renderPreviewView />
            </ScrollView>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
    //}
  }
}
const styles = StyleSheet.create({
  Container: {
    // flex: 1,
    backgroundColor: 'white',
  },
  appColorFieldStyle: {
    ...t1,
    textAlign: 'left',
    padding: 10,
    marginTop: 16,
    borderWidth: 1,
    borderColor: colors.AppGreenBorder,
    height: 40,
    borderRadius: 5,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoIcon: {
    width: 15,
    height: 15,
    marginLeft: 5,
    top: 2,
  },
});
const switchTitleStrings = [
  'Show Action Bar',
  'Show Category section',
  'Show Promo Banners',
  'Show Groups Section',
  'Show Invite friends',
  'Show Review reminders',
];
const parameterKeys = [
  'app_name',
  'app_color_primary',
  'app_color_secondary',
  'app_icon_android',
  'app_icon_ios',
];
