import mixpanel from 'mixpanel-browser'
import AppConstants from '../Constants/AppConstants'

export function addMixpanelEvent(eventName, properties) {
  let props = properties != undefined ? properties : {}
  if (AppConstants.tenantName.length != 0) {
    props['tenantName'] = AppConstants.tenantName
    // props['distinct_id'] = AppConstants.userEmail
  }
  if (props != undefined) {
    mixpanel.track(eventName, props)
  } else {
    mixpanel.track(eventName)
  }
}
export function setupUserPropertyOnMixpanel() {
  mixpanel.identify(AppConstants.userEmail)
  mixpanel.people.set({ '$email': AppConstants.userEmail, '$name': AppConstants.userFullName })
}

export function mobileIntegrationMixPanelEvent(value){
  addMixpanelEvent(MixpanelEvent.mobileIntegration,{'Integrations':value})
}
export function revenuModelMixPanelEvent(value){
  addMixpanelEvent(MixpanelEvent.revenuModel,{'Model':value})
}
export function seoMixPanelEvent(value){
  addMixpanelEvent(MixpanelEvent.seo,{'Content Type':value})
}
export function resetMixpanel(){
  addMixpanelEvent('Logout')
  mixpanel.reset()
}
export var MixpanelEvent = {
  viewAccountList:'View Accounts List',
  viewAccountDetail:'View Accounts Detail',
  viewListingsList:'View Listing List',
  viewListingsDetail:'View Listing Detail',
  viewCategory:'Category List',
  deleteCategory:'Delete Category',
  viewAttribute:'Attribute List',
  deleteAttribute:'Delete Attribute',
  viewVariantType:'Listing Variant Type',
  deleteVariantType:'Delete Variant',
  viewCollection:'Collection List',
  addCollection:'Add Collection',
  editCollection:'Edit Collection',
  viewCurrency:'Currency List',
  addCurrency:'Add Currency',
  editCurrency:'Edit Currency',
  viewAccountConfigurations: `View Account Configurations`,
  accountConfigurationSaved: `Account Configurations Saved`,
  viewListingsConfigurations: `View Listing Configurations`,
  listingConfigurationSaved: `Listing Configurations Saved`,
  GetStarted:'Get Started',
  headerMenu:'Header Menu',
  mapIntegration:'Map',
  firebaseIntegration:'Firebase',
  branchpIntegration:'Branch',
  twilioIntegration:'Twilio',
  appstoreIntegration:'Appstore',
  playstoreIntegration:'Playstore',
  facebookIntegration:'Facebook',
  googleIntegration:'Google',
  admobIntegration:'Admob',
  feedbackIntegration:'Feedback Form',
  emailNotification:'Email Notification',
  emailConfiguration:'Email Configuration',
  systemEmails:'System Emails',
  persistentNotification:'Persistent Notification',
  orderStatusNotification:'Order Status Notification',
  viewCommissionList:'View Commission List',
  viewLanguageList:'View Language List',
  viewCurrencyList:'View Currency List',
  specialFees:'Special Fees',
  accountSubscription:'Subscription',
  seoGeneral:'General',
  seoAccount:'Account',
  seoListing:'Listing',
  seoCollections:'Collections',
  seoPlaces:'Places',
  seoTags:'Tag',
  seoDomain:'Domain',
  webCustomisation:'Web Customisation',
  webIntegration:'Web Integration',
  setting:'Setting',
  apiIntegration:'API Integration',
  viewMedia:'View Media',
  addMedia:'Add Media',
  viewOrder:'View Order',
  viewOrderDetail:'Order Detail',
  viewTransaction:'View Transaction',
  viewUser:'View User',
  viewReview:'View Review',
  deleteBranch:'Delete Branch',
  deleteFleet:'Delete Fleet',
  deleteDriver:'Delete Driver',
  resetPasswordDriver:'Driver Reset Password',
  assignBranch:'Assign Branch',
  assignFleet:'Assign Fleet',
  assignDriver:'Assign Driver',
  unassignBranch:'Unassign Branch',
  unassignFleet:'Unassign Fleet',
  deliveryOrder:'Delivery Orders',
  deliveryOrderExport:'Export Delivery Orders',
  deliveryOrderDetail:'Delivery Order Detail',
  deliveryOrderUpdateStatus:'Update Delivery Order Status',
  fleetGetStarted:'FLEET GET STARTED',
  deletePromotion:'Delete Promotion',
  sideMenu:'Side Menu',
  viewPromotionList:'View Promotions List',
  viewReport:'View Report',
  mobileIntegration:'Added Mobile App Integrations',
  revenuModel:'REVENUE MODEL',
  seo:'SEO',
  deliveryGetStarted:'Delivery Get Started',
  learnMore:'Learn More',
  howItWorks:'How it Works',
  addUpdateShippingCharges:'Add or update Shipping charges',
  viewCouponsList:'View Coupons List',
  reportClick:'Report Click',
  reportWidget:'Report Widget',

  deleteAddons:'Delete Addons',

}

export var MixPannelEnum = {
    onboarding : 'Onboarding',
    view:'View',
    accounts:'Accounts',
    listView:'List view',
    mapView:'Map view',
    boardView:'Board view',
    gridView:'Grid view',
    kanbanView:'Board view',
    listView:'List view',
    calendarView:'Calendar view',
    configuration:'Configuration',
    approve:'Approve',
    reject:'Reject',
    delete:'Delete',
    category:'Category',
    attribute:'Attributes',
    addCategory:'Add Category',
    addAttribute:'Add attributes',
    listings:'Listings',
    addlistings:'Add Listings',
    editlistings:'Edit Listings',
    translate:'Translate',
    listingVariantType:'Listing Variant Types',
    addlistingVariantType:'Add Listing Variant Types',
    addPromotions:'Add Promotions',
    editPromotions:'Edit Promotions',
    addedSuccessfully:'Added Successfully',
    editSuccessfully:'Edited Successfully',
    paymentMethods:'Payment Methods',
    addPaymentMethod:'Add Payment Methods',
    editPaymentMethod:'Edit Payment Methods',
    shippingMethods:'Service / Shipping Methods',
    shippingMethodsList:'Shipping Methods list',
    addShippingMethods:'Add Shipping Methods',
    editShippingMethods:'Edit Shipping Methods',
    buyWebTemplate:'Buy Web Template',
    buyAppTemplate:'Buy App Template',
    youtubeTutorials:'Youtube Tutorials',
    changeDomain:'Change Domain',
    previewWebsite:'Preview Website',
    jsSDK:'JS SDK',
    apiDocs:'API Docs',
    cloneStartedKits:'Clone Starter Kits',
    changeWebTemplates:'Change Web Templates',
    clickResources :'Click Resources ',
    clickWhatsNew:'Click What’s New',
    appStore:'App Store',
    inviteTeam:'Invite Team',
    testMode:'Test Mode',
    billing:'Billing',
    userManagements:'User Managements',
    documentation:'Documentation',
    community:'Community',
    apiReference:'API Reference',
    upgrade:'Upgrade',
    commission:'Commission',
    language:'Language',
    currency:'Currency',
    publishableKey :'Publishable Key',
    secretKey :'Secret Key',
    branch:'Branches',
    fleetsOrder:'Fleets Orders',
    fleets:'Fleets',
    driver:'Drivers',
    roster:'Rosters',
    dashboard:'Delivery Dashboard',
    auditLog:'Audit Logs',
    credit:'Credit Detail',
    chartView:'Chart View',
    multiLanguage:'Multi Languages',
    mobileAppCustomisation:'Mobile App Customisation',
    onboardingscreen:'Onboarding screen',
    homePageCustomisation:'HomePage Customisation',
    approvalRejection:'Approval & Rejection',
    playStore:'Play Store',
    tax:'Taxes',
    communityURL:'Community URL',
    addCoupon:'Add Coupon',
    editCoupon:'Edit Coupon',
    card:'Card',
    viewAddons:'Listing Addons',
    addonsView:'Addons View',

}
export function generateRandomNo(){
  for (var s=''; s.length < 16; s += 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.charAt(Math.random()*62|0));
  return s;
}
