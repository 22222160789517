import React, {useEffect, useState} from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import RootView from './RootView';
import {Component} from 'react';
import {View, Text, SafeAreaView, StyleSheet, Linking} from 'react-native';
import {createStackNavigator, TransitionPresets} from '@react-navigation/stack';
import CreateApp from '../CreateTenantID/CreateYourApp';
import Business from '../CreateTenantID/BusinessFrom';
import SelectPlatformScreen from '../CreateTenantID/SelectPlatform';
import PGateWayScreen from '../CreateTenantID/SelectPaymentPlatform';
import ShipScreen from '../CreateTenantID/SelectShippingPlatform';
import ChooseMap from '../CreateTenantID/ChooseLocationMap';
import RegTypes from '../CreateTenantID/RegistrationTypePlatform';
import FScreen from '../CreateTenantID/FinalScreen';
import DashboardScreens from '../Dashboard/DashboardScreen';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import AppConstants from '../Constants/AppConstants';
import {
  getCurrentUser,
  saveAdminData,
  saveUserData,
  clearStorage,
  getMemberCookiesValue,
} from '../HelperClasses/UserPrefrences';
import LoginScreen from './LoginScreen';
import InviteMember from '../InviteMember/InviteMember';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import mixpanel from 'mixpanel-browser';

import Flavours from '../CreateTenantID/Flavour';
import {getProductAccess, getQueryParam} from '../HelperClasses/SingletonClass';
import {ProjectTypeModel} from '../Models/ProjectTypeModel';
import LandingPage from '../LandingPage/LandingPage';
import {setupUserPropertyOnMixpanel} from '../Models/MixPannelEnum';
import AppColor from '../HelperClasses/AppColor';
import Reports from '../Report/Report';
import MobileAppConfig from '../Settings/MobileAppConfig/MobileAppConfig';
import Orders from '../Orders/Orders';
import Transaction from '../Transactions/Transactions';

const APPURL = require('../Constants/URLConstants');

const AllRoutes = () => {
  const [root_state, setRoot_state] = useState({
    loggedIn: '',
    reload: false,
    dataLoad: false,
    isVisible: false,
    onboardingCompleted: false,
    inviteMember: false,
    moveToLandingPage: false,
    subscribed: false,
  });
  // states
  const {
    loggedIn,
    reload,
    dataLoad,
    isVisible,
    onboardingCompleted,
    inviteMember,
    moveToLandingPage,
    subscribed,
  } = root_state;

  // effects
  useEffect(() => {
    const fetch_data = async () => {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.clients,
        'get',
        '',
        AppConstants.BearerForTantantsAndUserDetail,
      );
      if (responseJson['status'] == true) {
        var configs = responseJson['data']['tenants'];
        Linking.getInitialURL().then(url => {
          const {hostname} = new URL(url);
          AppConstants.appMainURL = hostname;
          let productAccess = configs[0]['product_access'];
          // console.log('productAccess', productAccess)
          let rsp = getProductAccess(productAccess, url);
          // this.state.moveToLandingPage = rsp;
        });
        saveAdminData('adminKey', configs[0]['admin_key'], 2);
        saveUserData('id', configs[0]['id'], 4);
        getCurrentUser(resp => {
          loadConfigApi();
        });
      }
    };
    fetch_data();

    setRoot_state(prevState => ({
      ...prevState,
      dataLoad: true,
    }));
  }, [0]);

  //
  const initializeMixPanel = () => {
    mixpanel.init(AppConstants.mixpanelID, {debug: false});
    setupUserPropertyOnMixpanel();
  };

  const setupLogrocket = () => {
    LogRocket.init(AppConstants.logRocketKey);
    LogRocket.identify(AppConstants.userFullName, {
      name: AppConstants.userFullName,
      // email: AppConstants.userEmail ,
    });
    setupLogRocketReact(LogRocket);
  };

  //
  const loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=onboarding,general',
      'get',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      AppConstants.siteURL = configs['site_url'] ?? '';
      getLoggedInUserDetail();
      // this.setState({
      //   onboardingCompleted: configs['onboarding_completed'] || false,
      // });
      AppConstants.appType = configs['sub_type'] == 1 ? false : true;
      AppConstants.moduleType = configs['type'] || 1;

      getLoggedInUserDetail();
      // this.setState({reload: true });
    } else {
    }
    setRoot_state(prevState => ({
      ...prevState,
      dataLoad: true,
    }));
  };

  //
  const getLoggedInUserDetail = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.skuaUser,
      'get',
      '',
      '',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['user'];
      AppConstants.userId = configs['id'];
      AppConstants.userFullName = configs['name'] || '';
      AppConstants.userEmail = configs['email'] || '';
      AppConstants.userProfilePic = configs['profile_pic'] || '';
      getTenantApi();
      setupLogrocket();
      getUserRoleModule();
      getsubscriptionsPlans();
      initializeMixPanel();
    } else {
      setRoot_state(prevState => ({
        ...prevState,
        dataLoad: true,
      }));
    }
  };

  //
  const getUserRoleModule = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.userRole + `/${AppConstants.userId}/modules`,
      'get',
      '',
      '',
    );
    if (responseJson['status'] == true) {
      let modules = responseJson['data']['modules'];
      // console.log('modules',modules);
      if (AppConstants.isDebug) {
        setRoot_state(prevState => ({
          ...prevState,
          reload: true,
          isVisible: true,
        }));
      }
      saveUserData('modules', modules, 5);
    } else {
      if (AppConstants.isDebug) {
        setRoot_state(prevState => ({
          ...prevState,
          reload: true,
          isVisible: true,
        }));
      }
    }
  };

  //
  const getsubscriptionsPlans = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.subscriptionsPlans,
      'get',
    );
    // AppConstants.restrictedFeatureArray = ["social_auth", "languages","currencies","attributes","bulk_edit_categories","bulk_edit_listings"]
    if (responseJson['status'] == true) {
      console.log('responseJson', responseJson);
      let subData = responseJson['data'];
      let plans = subData['plans'];
      let oldPlan = subData['old_plan'];
      if (oldPlan != null) {
        checkingSubscription(oldPlan);
      } else {
        for (let plan of plans) {
          checkingSubscription(plan);
        }
      }
      setRoot_state(prevState => ({
        ...prevState,
        reload: true,
        isVisible: true,
      }));
    } else {
      setRoot_state(prevState => ({
        ...prevState,
        reload: true,
        isVisible: true,
      }));
    }
  };

  //
  const checkingSubscription = plan => {
    if (plan['subscribed']) {
      AppConstants.restrictedFeatureArray = plan['restricted_features'];
      // AppConstants.restrictedFeatureArray = [ "social_auth", "languages","currencies","attributes"]
    }
    if (plan['name'] != 'community_monthly') {
      if (plan['subscribed'] && !plan['expired']) {
        AppConstants.subscribed = true;
      }
    } else {
      if (plan['subscribed']) {
        AppConstants.subscribed = true;
      }
    }
  };

  //
  const getTenantApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.clients,
      'get',
      '',
      AppConstants.BearerForTantantsAndUserDetail,
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['tenants'];
      AppConstants.tenantName = `${configs[0]['id']}`;
    }
    setRoot_state(prevState => ({
      ...prevState,
      dataLoad: true,
    }));
  };

  //

  // Routers

  let router = createBrowserRouter([
    {
      path: '/',
      element: <RootView />,
      children: [
        {
          path: 'dashboard',
          element: <DashboardScreens />,
        },
        {
          path: 'reports',
          element: <Reports />,
        },
        {
          path: 'orders',
          element: <Orders />,
        },
        {
          path: 'transaction',
          element: <Transaction />,
        },
        {
          path: 'app-customization',
          element: <MobileAppConfig />,
        },
      ],
    },
  ]);

  console.log(root_state);

  return reload && <RouterProvider router={router} />;
};

export default AllRoutes;
