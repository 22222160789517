export default class TransactionEnum {
  static type(code) {
    switch (code) {
      case 1: return 'Sales Payout'
      case 2: return 'Sales (No Payout)'
      case 3: return 'Sales Payout Reversal'
      case 4: return 'Sales no payout reversal'
      case 5: return 'Seller Commission'
      case 6: return 'Seller Commission Reversal'
      case 7: return 'Seller Transfer'
      case 8: return 'Seller Transfer Reversal'
      case 9: return 'Seller Payout'
      case 10: return 'Seller Subscription'
      case 11: return 'Seller Payment processing fee'
      case 12: return 'Seller Payment processing fee reversal'
      case 13: return 'Sub Total'
      case 14: return 'Customer Commission'
      case 15: return 'Tax'
      case 16: return 'Shipping Charge'
      case 17: return 'Refund'
      case 18: return 'Special Fee'
      case 19: return 'Coupon'
      default: return ''
    }
  }
}

