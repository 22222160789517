import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import menu_Icon from '../assets/menuIcon.png'; //'../../assets/menuIcon.png';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';

const windowWidth = Dimensions.get('window').width;

export default class NavigationHeader extends Component {
  static propTypes = {
    title: PropTypes.string,
    menuBtn: PropTypes.func,
    // backBtn: PropTypes.func,
    // showBackBtn: PropTypes.bool,
  };

  menuBtnView = props => {
    if (windowWidth < 700) {
      if (this.props.showBackBtn == true) {
        return (
          <TouchableOpacity
            style={styles.backBtnStyle}
            onPress={this.props.backBtn}>
            <Text>back</Text>
          </TouchableOpacity>
        );
      } else {
        return (
          <TouchableOpacity
            style={styles.backBtnStyle}
            onPress={this.props.menuBtn}>
            <Text>Side bar</Text>
          </TouchableOpacity>
        );
      }
    } else {
      if (this.props.showBackBtn == true) {
        return (
          <TouchableOpacity
            style={styles.backBtnStyle}
            onPress={this.props.backBtn}>
            <Text>back</Text>
          </TouchableOpacity>
        );
      } else {
        return <View></View>;
      }
    }
  };

  render() {
    return (
      <View style={styles.headerView}>
        {/* <this.menuBtnView /> */}
        <Text style={CommonStyleSheet.naviheaderTitleStyle}>
          {this.props.title}
        </Text>
        <Image style={styles.imageStyle} resizeMode="contain" />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  headerView: {
    height: 75,
    width: '100%',
    backgroundColor: 'white',
    // shadowColor: colors.Appgray,
    // shadowOpacity: 0.5,
    // shadowOffset: {width: 0, height: 0},
    // shadowRadius: 10,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    // padding: 20,
  },
  imageStyle: {
    width: 30,
    height: 30,
    backgroundColor: 'brown',
    alignSelf: 'center',
    marginRight: 20,
    borderRadius: 15,
  },
  backBtnStyle: {
    marginLeft: 20,
  },
});
