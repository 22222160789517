'use strict';
import colors from '../HelperClasses/AppColor';
var React = require('react-native');
var { StyleSheet, Dimensions } = React;

export const h1 = {
  fontSize: 36,
  fontWeight: 'bold',
}
// export const h2 = {
//   fontSize: 24,
//   fontWeight: '400'
// }
// export const h3 = {
//   fontSize: 20,
//   fontWeight: '600'
// }

export const h2 = {
  fontSize: 24,
  fontWeight: '500'
}
export const h3 = {
  fontSize: 16,
  fontWeight: '500'
}
export const h4 = {
  fontSize: 14,
  fontWeight: '400'
}
export const p1 = {
  fontSize: 16,
  fontWeight: '400'
}
export const p2 = {
  fontSize: 14,
  fontWeight: '500'
}

export const t1 = {
  fontSize: 16,
  fontWeight: '400'
}
export const t2 = {
  fontSize: 14,
  fontWeight: '400'
}

export const b1 = {
  fontSize: 12,
  fontWeight: '500'
}
export const b2 = {
  fontSize: 12,
}

export const button1 = {
  fontSize: 14,
}
export const button2 = {
  fontSize: 14,
  fontWeight:'500'
}