const env_vars = require('../env');
module.exports = {
  APPROOT: 'https://dev.tradly.app/signin',
  BearerForTantantsAndUserDetail: env_vars.internal_bearer_token,
  APPLogout: 'https://tradly.app/signin', //`${env_vars.auth_app_url}logout`, //'https://tradly.app/signin', //
  BearerAuthorization: '',
  privateKey: env_vars.private_key,
  isDebug: env_vars.env == 'dev',
  env: env_vars.env,
  BaseURL: env_vars.api_base_url,
  isDevelopment: env_vars.env == 'dev' ? true : false,
  sentryDNS: env_vars.sentry_DNS,
  appType: false, // false means B2C; true == C2C
  onBoardingKeys: 'key_group=onboarding,general',
  docsURL: 'https://tradly.app/docs/setup',
  supportURL: 'https://tradly.atlassian.net/servicedesk/customer/portal/1/',
  communityURL: 'https://community.tradly.app',
  ckPrivateKey: env_vars.ck_private_key,
  appStoreLink: 'https://apps.apple.com/us/app/id1524190804',
  playStoreLink:
    'https://play.google.com/store/apps/details?id=tradly.platform',
  githubLink: 'https://github.com/TRADLY-PLATFORM/',
  oldAdminLink: 'https://admin.sandbox.tradly.app',
  apiRefrenceLink: 'https://developer.tradly.app/',
  listingsHelperLink: 'https://tradly.app/docs/listings',
  accountsHelperLink: 'https://tradly.app/docs/accounts',
  shippingMethodHelperLink: 'https://tradly.app/docs/delivery',
  paymentMethodHelperLink: 'https://tradly.app/docs/paymentgateways',
  commissionsHelperLink: 'https://tradly.app/docs/revenuemodel',
  collectionsHelperLink: 'https://tradly.app/docs/collection',
  subscriptionHelperLink: 'https://tradly.app/docs/subscription',
  promoBannersHelperLink: 'https://tradly.app/docs/promotion',
  languageHelperLink: 'https://tradly.app/docs/language',
  currencyHelperLink: 'https://tradly.app/docs/currency',
  appHelperLink: 'https://developer.tradly.app/',
  websiteHelperLink: 'https://tradly.app/docs/domain',
  locationHelperLink: 'https://tradly.app/docs/location',
  stripeHelperLink: 'https://tradly.app/docs/stripe',
  firebaseHelperLink: 'https://tradly.app/docs/firebase',
  branchHelperLink: 'https://tradly.app/docs/branch',
  appstoreHelperLink: 'https://tradly.app/docs/appstore',
  reviewRatingsHelperLink: 'https://tradly.app/docs/review-ratings',
  mediaHelperLink: 'https://tradly.app/docs/media',
  couponHelperLink: 'https://tradly.app/docs/coupon',
  taxHelperLink: 'https://tradly.app/docs/tax',
  youtubeLink:
    'https://www.youtube.com/playlist?list=PLiKQzSFReAziYUvOKfUPxglX-HOhWabVi',
  apiDocs: 'https://developer.tradly.app/',
  vercelLink:
    'https://vercel.com/new/clone?repository-url=https://github.com/TRADLY-PLATFORM/Butterflies',
  npmPackage: 'https://www.npmjs.com/package/tradly',
  plainTextString:
    'This below configs are applied on realtime. When you make the changes here and click Save, it will be reflected in apps and API response.',
  plain2TextString:
    'Note for Mobile App Channels: This below configs are applied only on product release and updates time. And will never be updated in realtime. When you make changes here and need an updated build, do a build request from Support Portal. (Applicable to Starter plan)',
  enableLogString:
    'Enable this logs only when you are requested by Tradly team. These API calls log that help us to identify when there is something goes wrong',
  languageMessage:
    'Destructive Action: Changing this language to default will migrate all existing default language data to this language.',
  upgardePuffins: 'https://tradly.app/workspace/plans',
  thumbnailImage: 'thumb_',
  tradlyEVN: 'tradly_evn',
  tenantName: '',
  webIntegration: 'https://tradly.app/workspace/integrations',
  customDomainLink: 'https://tradly.app/workspace/domain',
  userId: '',
  userFullName: '',
  userProfilePic: '',
  googleMapAPIKey: 'AIzaSyBr3Q8BS3eMq4dA0u0wrkUtW_VWgYLbtB0',
  changeWebTemplateURL: 'https://tradly.app/workspace/templates',
  mobileTemplateURL: 'https://tradly.app/workspace/templates',
  domainKey: '',
  userFullName: '',
  launchpadURL: 'https://tradly.app/launchpad',
  defaultRead: true,
  defaultWrite: true,
  defaultDelete: true,
  logRocketKey: 'dkuwsm/superadmintradly',
  userEmail: '',
  roleManagementLink: 'https://tradly.app/workspace/user-management',
  inviteMemberKey: 'invite_member',
  buyAppTemplateURL: 'https://tradly.app/workspace/templates',
  growingPlatformsURL: 'https://community.tradly.app/c/growing-platforms/5',
  whatsNewURL: 'https://community.tradly.app/c/product-updates/9',
  appLanguage: 'en',
  projectType: '',
  mixpanelID: '3775829859997b9911f4fa8d21ad3abb',
  webCustomisation: 'https://tradly.app/editor',
  moduleType: 1,
  deliveryRootURL: `login?to=superadmin?app=delivery`,
  marketPlaceRootURL: `login?to=superadmin?app=marketplace`,
  siteURL: '',
  stripeYoutubeLink: 'https://www.youtube.com/watch?v=s5miuwJRR-I',
  changeEmailConfigurationURL:
    'https://tradly.atlassian.net/servicedesk/customer/portal/1',
  webCustomisation: 'https://tradly.app/editor',
  appLanguage: 'ar',
  mixpanelID: '3775829859997b9911f4fa8d21ad3abb',
  productAccessArray: [],
  landingPage: 'LandingPage',
  termAndCondition: 'https://tradly.app/terms-conditions',
  privacyURL: 'https://tradly.app/privacy-policy',
  appMainURL: '',
  deliveryAppStoreLink:
    'https://apps.apple.com/us/app/superdelivery/id1630096450',
  deliveryPlayStoreLink:
    'https://play.google.com/store/apps/details?id=app.superdelivery.com',
  languageHelperVideoLink: 'https://www.youtube.com/watch?v=9iRtSBkOj-w',
  mobileAppHelperVideoLink: 'https://www.youtube.com/watch?v=mgGm3kWiaos',
  onboardingAppHelperVideoLink: 'https://www.youtube.com/watch?v=umdYJUCB-zM',
  homePageCustomisationHelperVideoLink:
    'https://www.youtube.com/watch?v=BNzXxmJ_gkk',
  approvalAndRejectionHelperVideoLink:
    'https://www.youtube.com/watch?v=xozP1Pmok3U',
  bulkAccountCategoryDownloadURL:
    'https://media.tradly.app/others/templates/accounts_categories.csv',
  bulkListingCategoryDownloadURL:
    'https://media.tradly.app/others/templates/listings_categories.csv',
  bulkListingsDownloadURL:
    'https://media.tradly.app/others/templates/listings.csv',
  subscriptionSelectPlan: 'https://tradly.app/info/selectplan',
  upgradePlan: 'https://tradly.app/info/upgrade',
  subscribed: env_vars.env == 'dev' ? true : false,
  restrictedFeatureArray: [],
  mobileMaxWidth: 700,
  addPageAndBlocksURL: 'https://tradly.app/workspace/p',
  templates_page: 'https://dev.tradly.app/embed/templates',
  add_pages: 'https://dev.tradly.app/embed/p',
  blogs: 'https://dev.tradly.app/embed/blog',
};
