import errorHandler from './ErrorHandler';
import Cookies from 'universal-cookie';
import {Linking, Platform} from 'react-native';
import userPref, {
  getCookiesValue,
  saveCookies,
} from '../HelperClasses/UserPrefrences';
import AppConstants from '../Constants/AppConstants';
import {ProjectTypeModel} from '../Models/ProjectTypeModel';
import {logout} from '../HelperClasses/SingletonClass';
var CryptoJS = require('crypto-js');
const cookies = new Cookies();
const APPURL = require('../Constants/URLConstants');
const APPConstants = require('../Constants/AppConstants');
const to = require('await-to-js').default;
let constantBBR = 'lkv294e1f1ac6j5a361b4516c5elkczjz';
//j20294e1f1ac6c12361bs4516c5e155d0 login
class NetworkManager {
  getAuthKey() {
    let evn = APPConstants.isDebug ? 'd' : 'p';
    let ckAuth = cookies.get(`ck_${evn}_a`);
    if (ckAuth != undefined) {
      const decrypted = CryptoJS.AES.decrypt(
        ckAuth.toString(),
        AppConstants.ckPrivateKey,
      );
      var aut_key = decrypted.toString(CryptoJS.enc.Utf8);
      return aut_key;
    } else {
      return undefined;
    }
  }
  getRefreshKey() {
    let evn = APPConstants.isDebug ? 'd' : 'p';
    let ckrefresh = cookies.get(`ck_${evn}_r`);
    if (ckrefresh != undefined) {
      const decrypted = CryptoJS.AES.decrypt(
        ckrefresh.toString(),
        AppConstants.ckPrivateKey,
      );
      var refresh_key = decrypted.toString(CryptoJS.enc.Utf8);
      return refresh_key;
    } else {
      return undefined;
    }
  }
  networkCall = async (
    path,
    method,
    param,
    token,
    auth,
    lang,
    onBoardingSanbox,
  ) => {
    var agent = 3;
    if (Platform.OS == 'ios') {
      agent = 2;
    } else if (Platform.OS == 'android') {
      agent = 1;
    }
    var BaseURL = APPURL.URLPaths.BaseURL;
    if (!APPConstants.isDevelopment) {
      BaseURL =
        getCookiesValue(AppConstants.tradlyEVN) == 'sandbox'
          ? APPURL.URLPaths.sandboxBaseURL
          : APPURL.URLPaths.productionBaseURL;
    }

    var url = BaseURL + path;
    var bearerAuth = APPConstants.BearerAuthorization;
    let existSkua = path.includes('skua');
    console.log('result', existSkua);
    // if (path == APPURL.URLPaths.clients || path == APPURL.URLPaths.developerAPIKey || path == APPURL.URLPaths.createTenant || path == APPURL.URLPaths.skuaUser) {
    if (existSkua) {
      if (!APPConstants.isDevelopment) {
        BaseURL = APPURL.URLPaths.productionBaseURL;
        url = BaseURL + path;
      }
      if (path == APPURL.URLPaths.developerAPIKey) {
        url = BaseURL + `${path}${token}`;
      }
      bearerAuth = APPConstants.BearerForTantantsAndUserDetail;
    }
    if (url.includes(AppConstants.onBoardingKeys)) {
      if (!APPConstants.isDevelopment) {
        BaseURL = APPURL.URLPaths.productionBaseURL;
        url = BaseURL + path;
      }
    }
    if (!APPConstants.isDevelopment) {
      if (onBoardingSanbox) {
        BaseURL = APPURL.URLPaths.sandboxBaseURL;
        url = BaseURL + path;
      }
    }
    var authKey = this.getAuthKey();
    if (AppConstants.isDebug) {
      console.log('url == ', url);
      console.log('param == ', param);
      console.log('bearerAuth == ', bearerAuth);
      console.log('auth == ', this.getAuthKey());
      // if (bearerAuth != undefined){
      //   bearerAuth = bearerAuth.length == 0 ? constantBBR : bearerAuth;
      // }
      authKey =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NzU0NjEzMDAsImRhdGEiOiJza3VhLi5kMzM5MDhkMzRjMGQzYWJjY2JlNmExZDI0MjJhNzljMzRiOWMzYzJiMDdkZmZiMDRlZGEyM2I0ZmVlZWYzMzliNmZiN2EzY2MyZTlkYjk4ZjE2MTgyNTg0NDdkNTVkN2IyNWFkZWNjMDFkMDI0ZWRhNDdiY2EyZjZiNTFkZTQ1Mi4uMTEuLmU2MDE0ZjNkLTA0MmItNDAyMy1hZDEyLWVjMmMyZWRkMjRlZS4uMTEuLnByb2R1Y3R0aGVtZUB0cmFkbHkuYXBwLi5Qcm9kdWN0IFRlbXBsYXRlIiwiaWF0IjoxNjc1NDE4MDk5fQ.56gYWDCY3XWjyvyYbbCMKyQ2D2xy2sCbma3kx6sADVk';
    }

    let err, response;
    var headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearerAuth,
      'x-agent': 1,
      'x-tradly-agent': agent,
      'x-auth-key': authKey,
    };
    if (lang != undefined) {
      headers['x-language'] = lang;
    }

    [err, response] = await to(
      fetch(url, {
        method: method,
        dataType: 'json',
        headers: headers,
        body: param == '' ? null : param,
      }),
    );
    if (err) {
      let error = err;
      return error;
    } else {
      const json = await response.json();
      if (json['status'] == true) {
        return json;
      } else {
        if (json['error']) {
          let error = errorHandler.errorHandle(
            json['error']['code'],
            json['error']['message'],
          );
          if (json['error']['code'] == 401) {
            // Linking.openURL(APPConstants.APPROOT)
            logout();
            return error;
          }
          console.log(JSON.stringify(json['error']));
          return error;
        } else {
          return json ?? 'Failed';
        }
      }
    }
  };
  refreshKeyCall = async (path, method, param, token, auth) => {
    var BaseURL = APPURL.URLPaths.BaseURL;
    let url = BaseURL + APPURL.URLPaths.refreshToken;
    var refreshKey = this.getRefreshKey();
    let err, response;
    [err, response] = await to(
      fetch(url, {
        method: 'GET',
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Refresh-Key': refreshKey,
          'x-agent': 1,
        },
      }),
    );
    if (err) {
      console.log('response error', err);
      return err;
    } else {
      const json = await response.json();
      if (json['error']) {
        if (json['error']['code'] == 401) {
          let error = errorHandler.errorHandle(
            json['error']['code'],
            json['error']['message'],
          );
          // if(AppConstants.projectType == ProjectTypeModel.delivery){
          //   let link = APPConstants.deliveryRootURL.replace('superadmin', AppConstants.appMainURL)
          //   Linking.openURL(link)
          // } else{
          //   let link = APPConstants.marketPlaceRootURL.replace('superadmin', AppConstants.appMainURL)
          //   Linking.openURL(link)
          // }
          // Linking.openURL(APPConstants.APPROOT)
          logout();

          return error;
        }
      } else {
        const auth_key = json['data']['user']['key']['auth_key'];
        const refresh_key = json['data']['user']['key']['refresh_key'];
        saveCookies(`ck_auth_${AppConstants.env}`, auth_key);
        saveCookies(`ck_refresh_${AppConstants.env}`, refresh_key);
        return this.networkCall(path, method, param, token, auth_key);
      }
    }
  };
  async uploadFileWithSignedURL(signed_url, mime, blob_body) {
    let err, response;
    [err, response] = await to(
      fetch(signed_url, {
        method: 'put',
        headers: {'Content-Type': mime},
        body: blob_body,
      }),
    );
    if (err) {
      // console.log('response error', err);
      return false;
    } else {
      // console.log('response uploadFileWithSignedURL', response);
      return true;
    }
  }
}

const network = new NetworkManager();
export default network;
