import React,{Component} from 'react';
import { Dimensions, View } from 'react-native';
import AppConstants from '../Constants/AppConstants';
import PropTypes from 'prop-types';


export default class SubscribePlan extends Component {
  static propTypes = {
    upgrade: PropTypes.bool,
  }
  render() {
    return (
      <View style={{width:'40%', height:'80%'}}>
        <iframe src={this.props.upgrade ? AppConstants.upgradePlan : AppConstants.subscriptionSelectPlan} height={'100%'} width={'100%'} />
      </View>
    )
  }
}
