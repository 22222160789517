import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Dimensions,
  Image,
  SafeAreaView,
  Platform,
  TouchableOpacity,
  FlatList,
  TextInput,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../Settings/HeaderView';
import commonStyle from '../StyleSheets/CommonStyleSheet';
import detailsStyle from '../StyleSheets/DetailStyleSheet';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { checkUserRoleModule } from '../HelperClasses/SingletonClass';
import { MODULEKEYS } from '../Models/Enum';
import AppConstants from '../Constants/AppConstants';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../Models/MixPannelEnum';
import TableCommonStyleSheet from '../StyleSheets/TableCommonStyleSheet';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import appConfigStyle from './../StyleSheets/AppConfigStyleSheet';
import downArrow_Icon from './../assets/downArrowIcon.png';
import { ToastContainer } from 'react-toastify';
import {AppToast} from '../HelperClasses/AppToast';
import ToolTipViewBtn from '../Component/ToolTipViewBtn';
import AppConstantsMessage from '../Constants/AppConstantsMessage';
import UserDetail from '../Users/UserDetail';


const APPURL = require('../Constants/URLConstants');
const windowHeight = Dimensions.get('window').height - 120;

const approveString = 'Approve';
const rejectString = 'Reject';

let shippmentChargesTypeArray = ['Fixed', 'Percentage']
let regexp = /^[0-9\b]+$/ 

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth

let itsMobileView = windowWidth < fixedWidth  ?  true : false

export default class AccountDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      titleDataArray: [],
      accountDetailData: {},
      imagesArray: [],
      attributeArray: [],
      status: '',
      writeP: AppConstants.defaultWrite,
      selectedChargesType: '',
      showDropDown: false,
      shippingChargesValue:'',
      showUserDetail: false,
      userDetailData:{},
    }
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    accountID: PropTypes.string,
  };
  componentDidMount() {
    this.loadAccountDetailApi()
    this.checkModule()
    addMixpanelEvent('Accounts Detail')
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.ACCOUNTSMODERATION, res => {
      console.log('res', res);
      this.setState({ writeP: res[1], deleteP: res[2] })
    })
  }
  loadAccountDetailApi = async () => {
    this.state.titleDataArray = [];
    this.state.imagesArray = [];
    this.state.attributeArray = [];
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.accounts + `${this.props.accountID}`, 'get', '', '');
    // console.log('accounts ', responseJson);
    if (responseJson['status'] == true) {
      let dataObjc = responseJson['data']['account']
      this.state.accountDetailData = dataObjc
      if (dataObjc['status'] != 1) {
        this.state.status = dataObjc['status'] == 2 ? rejectString : approveString;
      } else {
        this.state.status = ''
      }
      this.state.userDetailData = dataObjc['user']
      var categories = dataObjc['categories'];
      var catData = [];
      for (let a = 0; a < categories.length; a++) {
        let obj = categories[a]
        // catData.push(obj)
        var hame = ''
        for (let hierarchy of obj['hierarchy']){
          if (hame.length != 0){
            hame = `${hame} -> ${hierarchy['name']}`
          }else {
            hame = hierarchy['name']
          }
        }
        catData.push(hame)
      }
      var shippingMethods = dataObjc['shipping_methods'];
      var shippingData = [];
      for (let a = 0; a < shippingMethods.length; a++) {
        let obj = shippingMethods[a]['name'];
        shippingData.push(obj);
      }
      if (dataObjc['shipping_charge_spilt']){
        let shippmentCharge = dataObjc['shipping_charge_spilt']
        this.state.selectedChargesType = shippmentCharge['type'] == 1 ? shippmentChargesTypeArray[0] : shippmentChargesTypeArray[1] 
        this.state.shippingChargesValue = shippmentCharge['value']
      }
      var accountName = dataObjc['user'];
      this.state.imagesArray = dataObjc['images'];
      this.state.attributeArray = dataObjc['attributes'];
      this.state.titleDataArray.push(dataObjc['name'])
      this.state.titleDataArray.push(dataObjc['description'] || '   ')
      this.state.titleDataArray.push(dataObjc['total_followers'])
      this.state.titleDataArray.push(dataObjc['total_listings'])
      this.state.titleDataArray.push(catData)
      if (dataObjc['location']['formatted_address']) {
        this.state.titleDataArray.push(dataObjc['location']['formatted_address'] || 'N/A')
      } else {
        this.state.titleDataArray.push('N/A');
      }
      this.state.titleDataArray.push(dataObjc['status'])
      this.state.titleDataArray.push(dataObjc['active'])
      this.state.titleDataArray.push(shippingData)
      this.state.titleDataArray.push(`${accountName['first_name']} ${accountName['last_name']}`)

      this.setState({ updateUI: !this.state.updateUI });
    }
    this.setState({ dataLoad: true });
  };

  aproveRejectAccountApi = async (id, type) => {
    this.setState({ dataLoad: false })
    let fDict = {
      status: type,
    };
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.accounts + `${id}/moderate`, 'post', JSON.stringify({ account: fDict }), '');
    this.setState({ dataLoad: true });
    if (responseJson['status'] == true) {
      let text = type == 2 ? MixPannelEnum.approve : MixPannelEnum.reject
      let key = `${text} ${MixPannelEnum.accounts}`
      addMixpanelEvent(MixpanelEvent.viewAccountDetail, { [key]: true })
      this.state.status = this.state.status == approveString ? rejectString : approveString;
      this.setState({ updateUI: !this.state.updateUI });
      this.loadAccountDetailApi()
    }
  }
  shippingChargesAPI = async (id) => {
    this.setState({ dataLoad: false })
    let fDict = {
      type: this.state.selectedChargesType == shippmentChargesTypeArray[0] ? 1 : 2,
      value:Number(this.state.shippingChargesValue),
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.accounts + `${this.props.accountID}`, 'PATCH', JSON.stringify({ account: {'shipping_charge_spilt':fDict} }))
    this.setState({ dataLoad: true })
    if (responseJson['status'] == true) {
      let key = `${MixpanelEvent.addUpdateShippingCharges}`
      addMixpanelEvent(MixpanelEvent.viewAccountDetail, { [key]: true })
      this.setState({ updateUI: !this.state.updateUI })
      AppToast()

    }
  }
  // UI Button
  approveRejectBtnAction = () => {
    let type = this.state.status == approveString ? 2 : 3;
    let msg = `Do you want to ${type == 2 ? 'approve' : 'reject'} this account ?`;
    if (Platform.OS === 'web') {
      const r = window.confirm(msg);
      if (r == true) {
        this.aproveRejectAccountApi(this.props.accountID, type);
      }
    }
  }
  onChangeValue(value) {
    var txtValue = ''
    if (this.state.selectedChargesType == shippmentChargesTypeArray[0]) {
      let reg =  /^[0-9.\b]+$/
      if (value == '' || reg.test(value)) {
        txtValue = value
        this.state.shippingChargesValue = txtValue
      }
    } else {
      if (value == '' || regexp.test(value)) {
        txtValue = value
      }
      if (value < 101) {
        this.state.shippingChargesValue = txtValue
      }
    }
    this.setState({updateUI: !this.state.updateUI})
  }
  saveShippingChargesBtnAction = () => {
    this.shippingChargesAPI()
  }
  viewUserDetailBtn(id) {
      this.setState({ showUserDetail: true })
  }
  // UI Renders
  tableHeaderCell = (id) => {
    var view = [];
    var tableHeaderString = [];
    tableHeaderString = id['id'] == 1 ? tableStaticArrayString : this.state.titleDataArray;
    if (id['id'] == 1) {
      for (let a = 0; a < tableHeaderString.length; a++) {
        let length = tableHeaderString.length - 1;
        view.push(
          <View style={detailsStyle.tableViewTitleViewStyle}>
            <Text style={detailsStyle.tableViewTitleStyle}>{tableHeaderString[a]}</Text>
            <View style={{ height: a == length ? 0 : 1, backgroundColor: colors.Lightgray, marginTop: 10 }} />
          </View>
        );
      }
    } else {
      for (let a = 0; a < tableHeaderString.length; a++) {
        let length = tableHeaderString.length - 1;
        if (a == 6) {
          var value = ''
          if (tableHeaderString[a] == 1) {
            value = 'Pending';
          } else if (tableHeaderString[a] == 2) {
            value = 'Approved';
          } else if (tableHeaderString[a] == 3) {
            value = 'Rejected';
          }
          view.push(
            <View style={detailsStyle.tableViewTitleViewStyle}>
              <View style={{ height: 3 }} />
              <Text style={tableHeaderString[a] == 2 ? detailsStyle.columnActiveBtnStyle : detailsStyle.columnDeActiveBtnStyle}>
                {value}
              </Text>
              <View style={{ height: a == length ? 0 : 1, backgroundColor: colors.Lightgray, marginTop: 4 }} />
            </View>
          );
        } else if (a == 7) {
          var value = tableHeaderString[a] == true ? 'Active' : 'Inactive'
          view.push(
            <View style={detailsStyle.tableViewTitleViewStyle}>
              <View style={{ height: 3 }} />
              <Text style={tableHeaderString[a] == true ? detailsStyle.columnActiveBtnStyle : detailsStyle.columnDeActiveBtnStyle}>
                {value}
              </Text>
              <View style={{ height: a == length ? 0 : 1, backgroundColor: colors.Lightgray, marginTop: 4 }} />
            </View>
          );
        } else {
          view.push(
            <View style={detailsStyle.tableViewTitleViewStyle}>
              <Text numberOfLines={1} style={detailsStyle.valueTitleStyle}>{tableHeaderString[a]}</Text>
              <View style={{ height: a == length ? 0 : 1, backgroundColor: colors.Lightgray, marginTop: 10 }} />
            </View>
          );
        }
      }
    }
    return view;
  };
  renderImagesView = () => {
    var view = [];
    for (let a = 0; a < this.state.imagesArray.length; a++) {
      view.push(
        <View style={detailsStyle.imageViewContainerStyle}>
          <Zoom>
            <Image style={detailsStyle.imageViewsStyle} resizeMode="cover" source={this.state.imagesArray[a]} />
          </Zoom>
        </View>,
      );
    }
    return view;
  }
  renderAttributes = (id) => {
    var titleArray = ['Title']
    var valueArray = ['Value']

    for (let a = 0; a < this.state.attributeArray.length; a++) {
      titleArray.push(this.state.attributeArray[a]['name'])
      let valueObj = this.state.attributeArray[a]['values']
      var valueD = []
      for (let b = 0; b < valueObj.length; b++) {
        if (valueObj[b]['name']) {
          valueD.push(valueObj[b]['name'])
        } else {
          valueD.push(valueObj)
        }
      }
      valueArray.push(valueD)
    }
    var view = []
    var tableHeaderString = []
    tableHeaderString = id['id'] == 1 ? titleArray : valueArray
    let attStyle = id['id'] == 1 ? detailsStyle.tableViewTitleStyle : detailsStyle.valueTitleStyle

    for (let a = 0; a < tableHeaderString.length; a++) {
      let length = tableHeaderString.length - 1;
      view.push(
        <View style={detailsStyle.tableViewTitleViewStyle}>
          <Text style={attStyle}>{tableHeaderString[a]}</Text>
          <View style={{ height: a == length ? 0 : 1, backgroundColor: colors.Lightgray }} />
        </View>
      );
    }
    return view;
  }
  renderDetail = () => {
    let tableHeaderString = this.state.titleDataArray;
    var detailView = [];
    let length = tableHeaderString.length - 1;
    for (let a = 0; a < tableHeaderString.length; a++) {
      if (a == 6) {
        var value = ''
        if (tableHeaderString[a] == 1) {
          value = 'Pending';
        } else if (tableHeaderString[a] == 2) {
          value = 'Approved';
        } else if (tableHeaderString[a] == 3) {
          value = 'Rejected';
        }
        detailView.push(<View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 0 }}>
          <View style={{ flex: 1, borderColor: colors.Lightgray, borderWidth: 1, borderBottomWidth: length == a ? 1 : 0, borderRightWidth: 0 }}>
            <Text style={detailsStyle.tableViewTitleStyle}>{tableStaticArrayString[a]}</Text>
          </View>
          <View style={{ flex: 1, borderColor: colors.Lightgray, borderWidth: 1, borderBottomWidth: 0, borderTopWidth: 0 }} >
            <View style={detailsStyle.tableViewTitleViewStyle}>
              <View style={{ height: 1, backgroundColor: colors.Lightgray }} />
              <View style={{ height: 4 }} />
              <Text style={
                tableHeaderString[a] == 2
                  ? detailsStyle.columnActiveBtnStyle
                  : detailsStyle.columnDeActiveBtnStyle}>
                {value}
              </Text>
            </View>
          </View>
        </View>);
      } else if (a == 7) {
        var value = tableHeaderString[a] == true ? 'Active' : 'Inactive'
        detailView.push(<View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 0 }}>
          <View style={{ flex: 1, borderColor: colors.Lightgray, borderWidth: 1, borderBottomWidth: length == a ? 1 : 0, borderRightWidth: 0 }}>
            <Text style={detailsStyle.tableViewTitleStyle}>{tableStaticArrayString[a]}</Text>
          </View>
          <View style={{ flex: 1, borderColor: colors.Lightgray, borderWidth: 1, borderBottomWidth: length == a ? 1 : 0 }} >
            <View style={detailsStyle.tableViewTitleViewStyle}>
              <View style={{ height: 4 }} />
              <Text style={tableHeaderString[a] == true ? detailsStyle.columnActiveBtnStyle : detailsStyle.columnDeActiveBtnStyle}>
                {value}
              </Text>
            </View>
          </View>
        </View>);
      } else if (a == 9) {
        detailView.push(<View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 0 }}>
          <View style={{ flex: 1, borderColor: colors.Lightgray, borderWidth: 1, borderBottomWidth: length == a ? 1 : 0, borderRightWidth: 0 }}>
            <Text style={detailsStyle.tableViewTitleStyle}>{tableStaticArrayString[a]}</Text>
          </View>
          <View style={{ flex: 1, borderColor: colors.Lightgray, borderWidth: 1, borderBottomWidth: length == a ? 1 : 0 }} >
            <TouchableOpacity onPress={() => this.setState({ showUserDetail: true })}>
              <Text style={[detailsStyle.valueTitleStyle, { marginTop: 2, color: colors.AppNewGreen }]}>{tableHeaderString[a]}</Text>
            </TouchableOpacity>
          </View>
        </View>)
      }  else {
        detailView.push(<View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 0 }}>
          <View style={{ flex: 1, borderColor: colors.Lightgray, borderWidth: 1, borderBottomWidth: length == a ? 1 : 0, borderRightWidth: 0 }}>
            <Text style={detailsStyle.tableViewTitleStyle}>{tableStaticArrayString[a]}</Text>
          </View>
          <View style={{ flex: 1, borderColor: colors.Lightgray, borderWidth: 1, borderBottomWidth: length == a ? 1 : 0 }} >
            <Text style={detailsStyle.valueTitleStyle}>{tableHeaderString[a]}</Text>
          </View>
        </View>)
      }
    }
    return detailView
  }
  renderAttributesView = () => {
    var attView = []
    let name = this.state.accountDetailData['name'] ?? ''
    if (this.state.attributeArray.length != 0) {
      attView.push(<View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 20, marginTop: 0 }}>
        <View style={{ flex: 1, borderColor: colors.Lightgray, borderWidth: 1, borderRightWidth: 0 }}>
          <this.renderAttributes id={1} />
        </View>
        <View style={{ flex: 1, borderColor: colors.Lightgray, borderWidth: 1 }}>
          <this.renderAttributes id={2} />
        </View>
      </View>)
    } else {
      attView.push(<View style={{ flexDirection: 'row', justifyContent: 'center', margin: 20 }}>
        <Text style={CommonStyleSheet.plainSubTextStyle}>
          No Attributes Value Present in this {name}
        </Text>
      </View>)
    }
    return (<View style={[commonStyle.mainView, { display: name.length == 0 ? 'none' : 'flex' }]}>
      <Text style={TableCommonStyleSheet.mainTitile}>Attributes</Text>
      {attView}
    </View>)
  }
  customDropDown = id => {
    if (this.state.showDropDown == true) {
      return (
        <View style={appConfigStyle.showDropDownViewStyle}>
          <FlatList
            data={shippmentChargesTypeArray}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={true}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View />
  };
  renderDropDown = ({ item }) => {
    return (
      <TouchableOpacity onPress={() => this.setState({ selectedChargesType: item, showDropDown:false})} style={{ margin: 5, height: 30 }}>
        <View style={{ width: '100%' }}>
          <Text style={CommonStyleSheet.dropdownCellItemTextStyle}>
            {item}
          </Text>
        </View>
      </TouchableOpacity>
    )
  }
  renderValueLabel = id => {
    return (
      <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Text style={this.state.selectedChargesType == '' ? appConfigStyle.placeholderTxtStyle : appConfigStyle.txtFieldStyleDropDown}>
          {this.state.selectedChargesType == '' ? 'Select Value Type' : this.state.selectedChargesType}
        </Text>
        <Image
          style={this.state.showDropDown == true ? appConfigStyle.upArraowIconStyle : appConfigStyle.downArraowIconStyle}
          resizeMode="contain"
          source={downArrow_Icon}
        />
      </View>
    )
  }
  renderSaveShippingChargesBtnView = () => {
    return <View style={TableCommonStyleSheet.secondButtonViewStyle}>
      <TouchableOpacity onPress={() => this.saveShippingChargesBtnAction()}>
        <Text style={TableCommonStyleSheet.secondBtnTextStyle}>
          {'Save'}</Text>
      </TouchableOpacity>
    </View>
  }

  renderShippmentChargesSpitView = () => {
    return (<View style={commonStyle.mainView}>
      <View style={{flexDirection:'row', justifyContent:'space-between',alignItems:'center',zIndex:98}}>
        <View style={{flexDirection:'row',alignItems:'center'}}>
          <Text style={TableCommonStyleSheet.mainTitile}>Shipping Charge Spilt</Text>
          <ToolTipViewBtn title={AppConstantsMessage.shippingChargeTitleToopTipMsg} description={AppConstantsMessage.shippingChargeSubTitleToolTipMsg} />
        </View>
        <View>
          {this.renderSaveShippingChargesBtnView()}
        </View>
      </View>
      <View style={{ margin: 16,zIndex:90 }}>
        <View style={[styles.subViewStyle, { zIndex: 10,flexDirection:itsMobileView ? 'column' : 'row' }]}>
          <View style={{flexDirection:'row'}}>
            <Text style={detailsStyle.tableViewTitleStyle}>{'Value Type'}</Text>
            <TouchableOpacity style={[appConfigStyle.dropDownViewStyle, { marginTop: 0, width: 200,marginLeft:20  }]}
              onPress={() => this.setState({ showDropDown: !this.state.showDropDown })}>
              {this.renderValueLabel()}
              {this.customDropDown()}
            </TouchableOpacity>
          </View> 
          <View style={{flexDirection:'row',marginLeft:20}}>
            <Text style={detailsStyle.tableViewTitleStyle}>{'Value'}</Text>
            <TextInput
              style={[commonStyle.commissionValueTxtFieldStyle, { outline: 0,width:200,marginTop:0,marginLeft:20 }]}
              placeholder="Enter Value"
              value={this.state.shippingChargesValue}
              placeholderTextColor={colors.placeholderColor}
              onChangeText={value => this.onChangeValue(value)}
            />
          </View>         
        </View>
      </View>
    </View>)
  }
  renderUserDetailView = () => {
    let user = this.state.accountDetailData['user'] ?? ''
    let name = `${user['first_name'] ?? ''} ${user['last_name'] ?? ''}`
    return (<View style={commonStyle.mainView}>
      <Text style={TableCommonStyleSheet.mainTitile}>User Detail</Text>
      <View style={{ flexDirection: 'row', alignItems: 'center', paddingLeft: 20,marginTop:-16 }}>
        <Text style={appConfigStyle.titleTxtStyle}>{`Name:`}</Text>
        <TouchableOpacity onPress={() => this.setState({ showUserDetail: true })}>
          <Text style={[detailsStyle.valueTitleStyle, { marginTop: 2, color: colors.AppNewGreen }]}>{name}</Text>
        </TouchableOpacity>
      </View>
    </View>)
  }
  render() {
    if (this.state.showUserDetail) {
      return (<UserDetail backBtnAction={() => this.setState({ showUserDetail: false })} userID={this.state.userDetailData['id']}
        userDetailData={this.state.userDetailData} />)
    } else {
    return (
      <SafeAreaView style={styles.Container}>
        <ToastContainer />
        <Header_View
          title={'View Account'}
          backBtn={this.props.backBtnAction}
          saveBtn={() => this.approveRejectBtnAction()}
          saveBtnTitle={this.state.status}
          showSaveBtn={this.state.writeP ? this.state.status.length == 0 ? false : undefined : false}
        />
        <View style={{ height: windowHeight - 10 }}>
          <ScrollView style={{ flex:1 }}>
            <View style={commonStyle.mainView}>
              <Text style={TableCommonStyleSheet.mainTitile}>Images</Text>
              <ScrollView>
                <View style={detailsStyle.containerView}>
                  <this.renderImagesView />
                </View>
              </ScrollView>
            </View>
            <View style={commonStyle.mainView}>
              <Text style={TableCommonStyleSheet.mainTitile}>Detail</Text>
              <View style={{ margin: 16, marginTop: 0 }}>
                <this.renderDetail />
              </View>
            </View>
            <View style={{ zIndex: 101 }}>
              <this.renderShippmentChargesSpitView />
            </View>
            <View style={{ zIndex: 10 }}>
              {this.renderAttributesView()}
            </View>
            <View style={{ zIndex: 10 }}>
              <this.renderUserDetailView />
            </View>
            <View style={{ height: 50 }} />
          </ScrollView>
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    )}
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
});
const tableStaticArrayString = [
  'Title',
  'Descriptions',
  'Total Followers',
  'Total Listings',
  'Categories',
  'Location',
  'Account Status',
  'Status',
  'Shipping Method',
  'Account User',
];
