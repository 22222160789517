import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Dimensions,
  SafeAreaView,
  Image,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../Settings/HeaderView';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import {dateConversionFromTimeStamp, getThumbnailImage} from '../HelperClasses/SingletonClass';
import statusModel from '../Models/StatusModel';
import placeholder from '../assets/pPlaceholder.png';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import tableStyle from './../StyleSheets/TableCommonStyleSheet';
import OrderDetails from '../Orders/OrderDetails';
import AppConstants from '../Constants/AppConstants';

const APPURL = require('../Constants/URLConstants');
const windowHeight = Dimensions.get('window').height - 120;

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth  ?  true : false


export default class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      ordersArray:[],
      showOrderDetail:false,
      orderID:'',
      userData:'',
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    userID: PropTypes.string,
    userDetailData:PropTypes.any,
  };
  componentDidMount() {
    this.loadUserDetailApi()
    this.loadOrderDetailApi()
  }
  loadUserDetailApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.user + `/${this.props.userID}`, 'get')
    if (responseJson['status'] == true) {
      var serD = responseJson['data']['user']
      this.state.userData = serD
      this.setState({updateUI: !this.state.updateUI})
    }
    this.setState({dataLoad: true});
  };
  loadOrderDetailApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.ordersList + `1&per_page=50&user_id=${this.props.userID}`, 'get');
    if (responseJson['status'] == true) {
      var ordrD = responseJson['data']['orders'];
      for (let obj of ordrD) {
        this.state.ordersArray.push(obj);
      }
      this.setState({updateUI: !this.state.updateUI});
    }
    this.setState({dataLoad: true});
  };
  // Buttons 
  viewOrderBtnAction = id => {
    this.setState({showOrderDetail: true, orderID: id});
  };
  // UI Renders 
  titleLblRender = ({first, second}) => {
    return (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text style={appConfigStyle.titleTxtStyle}>{`${first} `}</Text>
        <Text style={{marginTop: 2}}>{second}</Text>
      </View>
    )
  }
  renderUserDetail = () => {
    let userDetailData = this.props.userDetailData;
    let item = userDetailData['profile_pic'] || '';
    let mobileNo = this.state.userData['mobile'] || ''
    let fileURl =  getThumbnailImage(item)
    return (<View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 20 }}>
        <View style={{ margin: 5,flex:1 }}>
          <View style={{height:100,width:100}}>
            <Image style={{borderRadius: 50, height:100,width:100}}  source={item.length == 0 ? placeholder : fileURl} />
          </View>
          <View style={{ height: 20 }} />
          <this.titleLblRender first={'Name:'} second={`${userDetailData['first_name']} ${userDetailData['last_name']}`} />
          <View style={{ height: 10 }} />
          <this.titleLblRender first={'Email:'} second={this.state.userData['email'] || ''} />
          <View style={{ height: 10 }} />
          <View style={{display: mobileNo.length == 0 ? 'none' : 'flex'}}>
          <this.titleLblRender first={'Mobile:'} second={this.state.userData['mobile'] || ''} />
          </View>
          <View style={{ height: 10 }} />
          {/* <this.titleLblRender first={'Join Date:'} second={dateConversionFromTimeStamp(userDetailData['created_at'])} /> */}
        </View>
      </View>
    </View>)
  }
  renderUserOrderList = () => {
    return (<View style={{height:'55%'}}>
        <FlatList
          data={this.state.ordersArray}
          horizontal={false}
          renderItem={this.renderUserOrderListCell}
          keyExtractor={(item, index) => index}
          extraData={this.state}
          showsVerticalScrollIndicator={false}
          scrollEnabled={true}
        />
      </View>)
  }
  renderUserOrderListCell = ({item, index}) => {
    let order_details = item['order_details'][0]['listing'];
    let grandTotal = item['grand_total']['formatted'];
    let status = statusModel.statusHandle(item['order_status']);
    let pstatus = item['payment_status'] == 2 ? 'Pay' : 'UnPaid';
    let image = order_details['images'][0] || '';
    let fileURl =  getThumbnailImage(image)
    let id = item['id'];
    return (<TouchableOpacity style={styles.horizontalCellItemStyle} onPress={() => this.viewOrderBtnAction(id)}>
      <View style={{width: 80, height:80}}>
        <LazyLoadImage height={80} width={80} effect="blur" src={item.length == 0 ? placeholder : fileURl}
          placeholderSrc={placeholder} />
      </View>
      <View style={{flexDirection:itsMobileView ? 'column' : 'row', width:itsMobileView ? '70%' : '93%'}}>
        <View style={{ marginLeft: 10, alignItems: 'flex-start', flex: 1 }}>
          <Text style={tableStyle.mainTitleStyle}>{order_details['title']}</Text>
          <Text style={[tableStyle.statusTxtStyle,{color:colors.AppNewGreen}]}>{grandTotal}</Text>
          <Text style={tableStyle.statusTxtStyle}>{status}</Text>
        </View>
        <View style={{ marginTop: 5, alignItems: 'flex-end',marginLeft :10, marginRight:20}}>
          <Text style={tableStyle.mainTitleStyle}>Order Reference: <Text>{item['reference_number']} </Text></Text>
          <View style={{ height: 10 }} />
          <View style={{ marginTop: 5, alignItems: 'flex-end' }}>
            <Text style={pstatus != 'Pay' ? tableStyle.columnOrangeBtnStyle : tableStyle.columnActiveBtnStyle}>
              {pstatus}
            </Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>);
  };

  render() {
    if (this.state.showOrderDetail) {
      return (<OrderDetails backBtnAction={() => this.setState({ showOrderDetail: false })} orderID={this.state.orderID}/>);
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <Header_View title={'User Detail'} backBtn={this.props.backBtnAction} showSaveBtn={false} />
            <View style={styles.mainView}>
              <this.renderUserDetail />
              <View style={{ height: 1, width: '100%', backgroundColor: colors.Lightgray }} />
              <View style={{ height: '100%', padding: 20}}>
                <View style={{ height: 30, marginLeft: 5,display:this.state.ordersArray.length == 0 ? 'none' :'flex' }}>
                  <Text style={appConfigStyle.titleTxtStyle}>User Orders List</Text>
                </View>
                <View style={{flex:1}}>
                  <this.renderUserOrderList />
                </View>
              </View>
            </View>
          <Appload enable={this.state.dataLoad} />
        </SafeAreaView>
      );
    }
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  mainView: {
    margin: 10,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    padding: 2,
    height: windowHeight - 20,
  },
  horizontalCellItemStyle: {
    width: '70%',
    margin: 5,
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    padding:10,
    flexDirection: 'row',
    // justifyContent: 'space-between'
  },
});
const tableHeaderString = [
  'Description',
  'Unit Price',
  'Discount',
  'Qty',
  'Tax Rate',
  'Total Amount',
];