
import React, {Component} from 'react';
import {StyleSheet, View, Text,Image, Dimensions} from 'react-native';
import PropTypes from 'prop-types';
import CommonStyle from '../StyleSheets/CommonStyleSheet';
import emptyMsgIcon from '../assets/emptyMsgIcon.png';
import colors from '../HelperClasses/AppColor';
import emptyAccont from '../assets/emptyAccont.png';
import emptyListing from '../assets/emptyListing.png';
import emptyPromo from '../assets/emptyPromo.png';
import emptyCollection from '../assets/emptyCollection.png';
import TextTitleStyleSheet from '../StyleSheets/TextTitleStyleSheet';
import emptyCoupon from '../assets/emptyCoupon.png';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default class AppEmptyList extends Component {
  static propTypes = {
    enable: PropTypes.bool,
    titleString: PropTypes.string,
    showImage: PropTypes.bool,
    subtitleString: PropTypes.string,
    messageIcon: PropTypes.number,
  };

  renderEmptyMsg = () => {
    var msg = 'No Record Found';
    var subtitle = '';
    if (this.props.titleString) {
      msg = this.props.titleString;
    }
    if (this.props.subtitleString) {
      subtitle = this.props.subtitleString;
    }
    var emptyIcon = emptyMsgIcon;
    if (this.props.messageIcon) {
      if (this.props.messageIcon == 1) {
        emptyIcon = emptyListing;
      } else if (this.props.messageIcon == 2) {
        emptyIcon = emptyCollection;
      } else if (this.props.messageIcon == 3) {
        emptyIcon = emptyAccont;
      } else if (this.props.messageIcon == 4) {
        emptyIcon = emptyPromo;
      }else if (this.props.messageIcon == 5) {
        emptyIcon = emptyCoupon;
      }
    }
    if (this.props.showImage) {
      return (
        <View style={styles.imageMainStyle}>
          <Image  style={{width: this.props.messageIcon ? windowWidth - 200 : 200, height: this.props.messageIcon ? '100%' : 150, marginTop: 100}} source={emptyIcon} resizeMode={'contain'}/>
          <View style={{height: 0}} />
          <Text style={[CommonStyle.plainSubTextStyle,{textAlign: 'center',paddingTop:2}]}>
            {msg}
          </Text>
          <View style={{height: 2}} />
          <Text style={[CommonStyle.subTitleStyle,{textAlign: 'center'}]}>
            {subtitle}
          </Text>
        </View>
      )
    } else {
      return (
        <View style={styles.mainStyle}>
          <View style={CommonStyle.loaderStyle}>
          <Text style={CommonStyle.plainSubTextStyle}>
              {msg}
            </Text>
          </View>
        </View>
      );
    }
  }
  render() {
    return (
      <View style={{height: '100%', alignItems: 'center'}}>
        <this.renderEmptyMsg />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  mainStyle: {
    position: 'absolute',
    top: 100,
    left: 0,
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  }, 
  imageMainStyle: {
    position: 'absolute',
    left: 0,
    height: '80%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  }
});