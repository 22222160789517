import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  Platform,
  Alert,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import Switch from "react-switch";

const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants')
const KeyGroup = 'address'
export default class AddressSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      geoCodeKey: '',
      updateUI: false,
      dataLoad: false,
      statusArray: [true, true,true,true,true,true],
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
  };
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config + '?key_group=' + KeyGroup,'get')
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs']
      this.state.statusArray[0] = configs[keyParameterArray[0]] ?? true
      this.state.statusArray[1] = configs[keyParameterArray[1]] ?? true
      this.state.statusArray[2] = configs[keyParameterArray[2]] ?? true
      this.state.statusArray[3] = configs[keyParameterArray[3]] ?? true
      this.state.statusArray[4] = configs[keyParameterArray[4]] ?? true
      this.state.statusArray[5] = configs[keyParameterArray[5]] ?? true

    }
    this.setState({dataLoad: true});
  };
  addOrUpdateAppDetailApi = async () => {
    var configs = []
    for (let i = 0; i < keyParameterArray.length; i++) {
      let fDict = {
        key_group: KeyGroup,
        key: keyParameterArray[i],
        value: this.state.statusArray[i],
        secured: false,
      };
      configs.push(fDict)
    }
    if (configs.length != 0) {
      const responseJson = await networkService.networkCall(APPURL.URLPaths.config, 'post', JSON.stringify({ configs: configs }));
      if (responseJson['status'] == true) {
        this.deleteKeysAPI(configs)
      } else {
        this.setState({ dataLoad: true })
      }
    } else {
      this.deleteKeysAPI(configs)
    }
  }
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keyParameterArray.length; q++) {
      deleteParms.push(keyParameterArray[q]);
    }

    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }

    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms;
      const responseJson = await networkService.networkCall(urlParm,'delete')
      console.log('responseJson', responseJson);
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    } else {
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    }
  };
  alertView(title) {
      AppToast()
  }
  //MARK:-  Buttons Action Method 
  saveBtnAction() {
    this.setState({dataLoad: false})
    this.addOrUpdateAppDetailApi()
  }
  switchSattusBtnAction(id) {
    this.state.statusArray[id] = !this.state.statusArray[id];
    this.setState({updateUI: !this.state.updateUI});
  }
  renderAllSwitches = props => {
    var views = [];
    for (let i = 0; i < switchTitleStrings.length; i++) {
      views.push(
        <View style={{ flexDirection: 'row', marginTop: 20 }}>
          <Switch
            onColor={colors.AppNewGreen}
            onChange={() => this.switchSattusBtnAction(i)}
            checked={this.state.statusArray[i]}
            height={22}
            width={50}
          />
          <Text style={appConfigStyle.statusTxtStyle}>{switchTitleStrings[i]}</Text>
        </View>,
      );
    }
    return views;
  };
  //MARK:-  UI 
  render() {
    return (
      <View style={{width: '70%', marginBottom: 130, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Address</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plainTextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            {/* <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Reverse Geocode Api
              </Text>
              <TextInput
                style={appConfigStyle.txtFieldStyle}
                placeholder="Enter Reverse Geocode Api"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.setState({geoCodeKey: text})}
                value={this.state.geoCodeKey}
              />
            </View> */}
            <View style={{marginTop: -50}}>
              <this.renderAllSwitches />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  verifyEmailStyle: {
    height: 30,
    width: 150,
    backgroundColor: colors.AppNewGreen,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
  },
});

const switchTitleStrings = [
  'Show Address 2',
  'Show Country',
  'Show Zipcode',
  'Show State',
  'Show Landmark',

]
let keyParameterArray = [
  'address_show_address2',
  'address_show_country',
  'address_show_zipcode',
  'address_show_state',
  'address_show_landmark',

]