import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Platform,
  Alert,
  TouchableOpacity,
  SafeAreaView,
  Dimensions,
  Image,
  Linking,
} from 'react-native';
import PropTypes from 'prop-types';

import colors from '../HelperClasses/AppColor';
import appStore_Icon from '../assets/appleLogo.png';
import playStore_Icon from '../assets/playStore.png';
import preview_Icon from '../assets/previewImageIcon.png';
import github_Icon from '../assets/githubIcon.png';
import domainIcon from '../assets/domainIcon.png';
import youtubeLogo from '../assets/youtubeLogo.png';
import logoName_Icon from '../assets/tradlylogo.png';
import butterFly from '../assets/butterFly.png';
import npmIcon from '../assets/npmIcon.png';
import templateIcon from '../assets/templateIcon.png';
import buyIcon from '../assets/buyIcon.png';
import tickIcon from '../assets/tickIcon.png';

import {StackActions} from '@react-navigation/native';
import networkService from '../Network/NetworkManager';
import AppConstants from '../Constants/AppConstants';
import apiIcon from '../assets/apiIcon.png';
import RightViews from '../MainRoots/RightView';
import MobAppConfig from '../Settings/MobileAppConfig/MobileAppConfig';
import Listing from '../Settings/Listings/Listings';
import ShippingMethod from '../Settings/ShippingMethod/ShippingMethods';
import PaymentMethod from '../Settings/PaymentMethod/PaymentMethods';
import inspirationIcon from '../assets/inspirationIcon.png';
import whatsnewIcon from '../assets/whatsnewIcon.png';
import {
  addMixpanelEvent,
  MixpanelEvent,
  MixPannelEnum,
} from '../Models/MixPannelEnum';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import CategoryList from '../Settings/Listings/CategoryList';
import {TypeEnum} from '../Models/CommonEnum';
import PreviewButton from '../Component/PreviewButton';
import {withRouter} from '../wrappers/withRouter';

const APPURL = require('../Constants/URLConstants');
const APPConstants = require('../Constants/AppConstants');
const windowWidth = Dimensions.get('window').width;

let fixedWidth = 800;
let itsMobileView = windowWidth < fixedWidth ? true : false;

class DashboardScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rightSelectedIndex: -1,
      dataLoad: false,
      tenantId: '',
      domainKey: '',
    };
  }
  static propTypes = {
    index: PropTypes.number,
    backBtn: PropTypes.func,
    showBackBtn: PropTypes.bool,
  };
  componentDidMount() {
    addMixpanelEvent(MixpanelEvent.GetStarted);
    this.clientTanantsApi();
  }
  mixpanelInfo(value) {
    addMixpanelEvent(MixpanelEvent.GetStarted, {Action: value});
  }
  //MARK:-  APIs Method 
  setupUseItFul() {
    (function (w, d, s) {
      var a = d.getElementsByTagName('head')[0];
      var r = d.createElement('script');
      r.async = 1;
      r.src = s;
      r.setAttribute('id', 'usetifulScript');
      r.dataset.token = '586f04a15ff071b5b5760615d649e664';
      a.appendChild(r);
    })(window, document, 'https://www.usetiful.com/dist/usetiful.js');
  }

  clientTanantsApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.clients,
      'get',
      '',
      APPConstants.BearerForTantantsAndUserDetail,
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['tenants'];
      AppConstants.tenantName = `${configs[0]['id']}`;
      AppConstants.domainKey = `https://${configs[0]['domain']}` ?? '';
      this.setState({
        tenantId: `${configs[0]['id']}` || '',
        domainKey: `${configs[0]['domain']}`,
      });
    }
    this.setState({dataLoad: true});
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  changeNavigation() {
    this.props.navigation.dispatch(StackActions.replace('SuperAdmin'));
  }
  //MARK:-  Buttons Action Method 
  linkBtnAction = async id => {
    this.setState({showHelpView: false});
    var pathURL =
      id == 1 ? APPConstants.appStoreLink : APPConstants.playStoreLink;
    pathURL = id == 3 ? AppConstants.githubLink : pathURL;
    if (id == 1) {
      this.mixpanelInfo(MixPannelEnum.appStore);
    }
    if (id == 2) {
      this.mixpanelInfo(MixPannelEnum.playStore);
    }
    if (id == 3) {
      this.mixpanelInfo(MixPannelEnum.cloneStartedKits);
    }
    if (id == 4) {
      this.mixpanelInfo(MixPannelEnum.youtubeTutorials);
      pathURL = AppConstants.youtubeLink;
    } else if (id == 5) {
      // this.mixpanelInfo(MixPannelEnum.apiDocs)
      pathURL = AppConstants.apiDocs;
    } else if (id == 6) {
      let vlink = `https://vercel.com/new/clone?repository-url=https://github.com/TRADLY-PLATFORM/Butterflies&project-name=${this.state.tenantId}&repository-name=${this.state.tenantId}&demo-title=DemoStorefront&demo-description=Tradly%20starter%20kit%20to%20launch%20your%20marketplace%20&demo-url=https://sneakershub.vercel.app&demo-image=https://user-images.githubusercontent.com/61427976/133940948-09361d1c-08b3-43e3-b588-ac1dd3ff3e0c.png&envLink=https://tradly.app/docs/vercel&integration-ids=oac_ZjlyX04ie0YNeck9UjeAMfba&redirect-url=https://superadmin.tradly.app&developer-id=oac_ZjlyX04ie0YNeck9UjeAMfba`;
      pathURL = vlink;
    } else if (id == 7) {
      // this.mixpanelInfo(MixPannelEnum.jsSDK)
      pathURL = AppConstants.npmPackage;
    } else if (id == 8) {
      this.mixpanelInfo(MixPannelEnum.changeWebTemplates);
      let path = AppConstants.changeWebTemplateURL;
      pathURL = path;
    } else if (id == 9) {
      if (this.state.domainKey.length != 0) {
        this.mixpanelInfo(MixPannelEnum.previewWebsite);
        let path = 'https://' + this.state.domainKey;
        pathURL = path;
      } else {
        pathURL = '';
      }
    } else if (id == 10) {
      this.mixpanelInfo(MixPannelEnum.changeDomain);
      pathURL = AppConstants.customDomainLink;
    } else if (id == 11) {
      this.mixpanelInfo(MixPannelEnum.buyAppTemplate);
      pathURL = AppConstants.mobileTemplateURL;
    } else if (id == 12) {
      // this.mixpanelInfo(MixPannelEnum.clickResources)
      pathURL = AppConstants.growingPlatformsURL;
    } else if (id == 13) {
      // this.mixpanelInfo(MixPannelEnum.clickWhatsNew)
      pathURL = AppConstants.whatsNewURL;
    }
    if (Platform.OS == 'web') {
      if (pathURL.length != 0) {
        window.open(pathURL, '_blank');
      } else {
        // Alert.alert(`No domain set yet`);
      }
    } else {
      const supported = await Linking.canOpenURL(pathURL);
      if (supported) {
        await Linking.openURL(pathURL, '_blank');
      } else {
        Alert.alert(`Don't know how to open this URL: ${pathURL}`);
      }
    }
  };
  didSelectRightBar = id => {
    this.props.navigate('/app-customization');
  };
  backBtnHandler() {
    this.setState({rightSelectedIndex: -1});
  }
  //MARK:-   * UI  */
  renderTenantView = () => {
    return (
      <View>
        <Text style={CommonStyleSheet.simpleParagraphStyle}>
          You can test app data through Tradly Platform Apps on Android and iOS
          using your tenant name
          <Text style={CommonStyleSheet.teanantNameStyle}>
            {` " ${this.state.tenantId} " `}
          </Text>
        </Text>
      </View>
    );
  };
  renderAppStoreView = () => {
    return (
      <View>
        <View style={{width: 20}} />
        <View>{this.renderTenantView()}</View>
        <View style={{width: 20, height: 20}} />
        <TouchableOpacity onPress={() => this.linkBtnAction(1)}>
          <View style={styles.githubBackViewStyle}>
            <Image style={styles.storeIconStyle} source={appStore_Icon} />
            <Text style={CommonStyleSheet.themeColorTextStyle}>
              Get on Appstore
            </Text>
          </View>
        </TouchableOpacity>
        <View style={{width: 20, height: 20}} />
        <TouchableOpacity onPress={() => this.linkBtnAction(2)}>
          <View style={styles.githubBackViewStyle}>
            <Image style={styles.playStoreIconStyle} source={playStore_Icon} />
            <Text style={CommonStyleSheet.themeColorTextStyle}>
              Get on Google Play
            </Text>
          </View>
        </TouchableOpacity>
        <View style={{width: 20, height: 20}} />
        <TouchableOpacity onPress={() => this.linkBtnAction(4)}>
          <View style={styles.githubBackViewStyle}>
            <Image style={styles.youtubeIconStyle} source={youtubeLogo} />
            <Text style={CommonStyleSheet.themeColorTextStyle}>
              Watch Tutorials on Youtube
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  };
  renderGitHUbView = () => {
    return (
      <View>
        {/* <Text style={styles.textSubTitleStyle}>Fork open source repositories </Text> */}
        <View style={styles.downloadBtnViewStyle}>
          <TouchableOpacity onPress={() => this.linkBtnAction(3)}>
            <View style={styles.githubBackViewStyle}>
              <Image
                style={styles.githubIconStyle}
                resizeMode="contain"
                source={github_Icon}
              />
              <Text style={CommonStyleSheet.themeColorTextStyle}>
                {' '}
                Fork Open Source Repository
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  renderPreview = id => {
    return (
      <View>
        <PreviewButton id={id} />
      </View>
    );
  };
  renderLinkButtons = () => {
    let view = [];
    if (AppConstants.appType) {
      view.push(<View>{this.renderTenantView()}</View>);
    } else {
      view.push(<View>{this.renderAppStoreView()}</View>);
    }
    return <View>{view}</View>;
  };
  renderAPILinkView = () => {
    return (
      <View style={{backgroundColor: 'green', marginTop: 40}}>
        {this.renderAPIRefView()}
      </View>
    );
  };
  renderAPIRefView = () => {
    return (
      <View>
        <Text style={CommonStyleSheet.simpleParagraphStyle}>
          Use API to build front end from Scratch
        </Text>
        <View style={{width: 10, height: 10}} />
        <View style={styles.downloadBtnViewStyle}>
          <TouchableOpacity onPress={() => this.linkBtnAction(5)}>
            <View style={styles.githubBackViewStyle}>
              <Image style={styles.githubIconStyle} source={apiIcon} />
              <Text style={CommonStyleSheet.themeColorTextStyle}>API Docs</Text>
            </View>
          </TouchableOpacity>
          <View style={{width: 20, height: 20}} />
          <TouchableOpacity onPress={() => this.linkBtnAction(7)}>
            <View style={styles.githubBackViewStyle}>
              <Image
                style={{width: 50, height: 30}}
                resizeMode={'center'}
                source={npmIcon}
              />
              <Text style={[CommonStyleSheet.themeColorTextStyle]}>JS SDK</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  renderGrowingPlatformView = id => {
    let descp =
      id == 1
        ? 'Read entrepreneur stories and tips to grow your business  '
        : 'Check out our monthly product updates, bug fixes and other releases.';

    return (
      <View style={styles.yellowContainerStyle}>
        <View style={styles.yelloSubContainerStyle}>
          <Image
            style={{height: 25, width: 25}}
            source={id == 1 ? inspirationIcon : whatsnewIcon}
          />
        </View>
        <View style={{marginLeft: 10, flex: 1}}>
          <Text style={[CommonStyleSheet.simpleParagraphStyle]}>
            {id == 1 ? 'Growing Platforms and Inspirations' : `What's New`}
          </Text>
          <Text
            style={[
              CommonStyleSheet.helperTextStyle,
              {marginTop: 5, color: colors.AppTitleBlack},
            ]}>
            {descp}
          </Text>
          <TouchableOpacity
            style={{marginTop: 10, alignSelf: 'flex-end'}}
            onPress={() => this.linkBtnAction(id == 1 ? 12 : 13)}>
            <Text
              style={[
                CommonStyleSheet.themeColorTextStyle,
                {color: colors.BGBlueColor, padding: 0},
              ]}>{`Read More ❯`}</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  renderRightView = () => {
    // if (AppConstants.appType) {
    //   return < View />
    // }
    // if (windowWidth > fixedWidth) {
    return (
      <View style={styles.rightViewStyle}>
        <RightViews didSelectIndex={id => this.didSelectRightBar(id)} />
      </View>
    );
    // } else {
    //   return <View />
    // }
  };
  renderLeftView = () => {
    return (
      <View style={styles.mainViewStyle}>
        <View style={styles.contentViewStyle}>
          <Text style={CommonStyleSheet.headerTitleStyle}>2. Preview</Text>
          <View
            style={{
              flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
              justifyContent: 'space-between',
            }}>
            <View style={styles.subContainerStyle}>
              <View style={styles.boxContainerStyle}>
                {this.renderPreview(2)}
                <View style={{height: 20}} />
                {this.renderPreview(1)}
                <View style={{height: 20}} />
                {this.renderLinkButtons()}
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };
  renderThirdCoulum = () => {
    return (
      <View style={styles.mainViewStyle}>
        <View style={styles.contentViewStyle}>
          <Text style={CommonStyleSheet.headerTitleStyle}>3. Launch</Text>
          <View style={styles.subContainerStyle}>
            <View style={styles.boxContainerStyle}>
              {this.renderPreview(3)}
              <View style={{height: 20}} />
              {this.renderPreview(4)}
              <View style={{height: 20}} />
              {this.renderGitHUbView()}
              <View style={{height: 20}} />
              {this.renderAPIRefView()}
            </View>
          </View>
        </View>
      </View>
    );
  };

  renderMainView = () => {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={styles.mainView}>
          <ScrollView>
            <View>
              <Text style={CommonStyleSheet.welcomeTitleStyle}>
                Welcome to Tradly Platform, {AppConstants.userFullName}!
              </Text>
              <Text style={CommonStyleSheet.welcomeSubTitleTextStyle}>
                Here is your onboarding checklist.{' '}
              </Text>
            </View>
            <View
              style={{
                flexDirection: itsMobileView ? 'column' : 'row',
                justifyContent: 'space-between',
                width: '99%',
                paddingTop: 10,
                marginTop: 20,
              }}>
              <View style={{width: itsMobileView ? '100%' : '34%'}}>
                <View style={{margin: 0}}>
                  <Text style={CommonStyleSheet.headerTitleStyle}>
                    1. Setup
                  </Text>
                </View>
                <this.renderRightView />
              </View>
              <View
                style={{
                  width: itsMobileView ? '100%' : '34%',
                  paddingLeft: itsMobileView ? 20 : 0,
                }}>
                <this.renderLeftView />
              </View>
              <View
                style={{
                  width: itsMobileView ? '100%' : '33%',
                  paddingLeft: itsMobileView ? 20 : 0,
                }}>
                <this.renderThirdCoulum />
              </View>
            </View>
            <View
              style={{
                flexDirection: itsMobileView ? 'column' : 'row',
                width: '100%',
                marginTop: itsMobileView ? 10 : -30,
              }}>
              <View
                style={{
                  width: itsMobileView ? '86%' : '34%',
                  padding: itsMobileView ? 16 : 16,
                }}>
                {this.renderGrowingPlatformView(1)}
              </View>
              <View
                style={{
                  width: itsMobileView ? '86%' : '33%',
                  padding: itsMobileView ? 16 : 16,
                }}>
                {this.renderGrowingPlatformView(2)}
              </View>
            </View>
          </ScrollView>
        </View>
        <View style={{height: 60}} />
      </SafeAreaView>
    );
  };

  render() {
    if (this.state.rightSelectedIndex == 0) {
      return <MobAppConfig backBtn={() => this.backBtnHandler()} />;
    } else if (this.state.rightSelectedIndex == 1) {
      return (
        <CategoryList
          type={TypeEnum.accounts}
          fromRootScreen={true}
          backBtnAction={() => this.backBtnHandler()}
        />
      );
    } else if (this.state.rightSelectedIndex == 2) {
      return (
        <CategoryList
          type={TypeEnum.listings}
          fromRootScreen={true}
          backBtnAction={() => this.backBtnHandler()}
        />
      );
    } else if (this.state.rightSelectedIndex == 3) {
      return <ShippingMethod backBtn={() => this.backBtnHandler()} />;
    } else if (this.state.rightSelectedIndex == 4) {
      return <PaymentMethod backBtn={() => this.backBtnHandler()} />;
    } else {
      return <this.renderMainView />;
    }
  }
}

export default withRouter(DashboardScreen);

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  contentViewStyle: {
    width: '100%',
  },
  mainView: {
    margin: 10,
    marginTop: 0,
    flexDirection: 'column',
    borderRadius: 5,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    flex: 1,
    padding: 1,
    backgroundColor: colors.AppWhite,
  },
  rightViewStyle: {
    padding: 10,
    margin: 10,
  },
  storeIconStyle: {
    width: 25,
    height: 25,
  },
  playStoreIconStyle: {
    width: 20,
    height: 22,
    marginLeft: 5,
  },
  youtubeIconStyle: {
    width: 40,
    height: 60,
    marginLeft: -5,
  },
  githubIconStyle: {
    width: 25,
    height: 25,
  },
  githubBackViewStyle: {
    height: 40,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    borderRadius: 5,
    borderColor: colors.AppNewGreen,
    borderWidth: 1,
  },
  npmViewStyle: {
    height: 40,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    borderRadius: 5,
    borderColor: colors.AppNewGreen,
    borderWidth: 2,
  },
  downloadBtnViewStyle: {
    flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginTop: 5,
  },
  mainViewStyle: {
    flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
    backgroundColor: 'white',
  },
  boxContainerStyle: {
    marginTop: 20,
    backgroundColor: 'white',
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    borderRadius: 5,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    borderWidth: 1,
    borderColor: colors.Lightgray,
  },
  subContainerStyle: {
    width: windowWidth < fixedWidth ? windowWidth / 1.4 : '100%',
    padding: windowWidth < fixedWidth ? 0 : 20,
    paddingTop: 0,
  },
  yellowContainerStyle: {
    marginTop: 20,
    flexDirection: 'row',
    backgroundColor: colors.lightYellow,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    borderRadius: 5,
    padding: 16,
    width: '100%',
    borderWidth: 1,
    borderColor: colors.AppYellow,
  },
  yelloSubContainerStyle: {
    backgroundColor: colors.AppYellow,
    height: 60,
    width: 50,
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'center',
  },
  lightBorderViewStyle: {
    height: 40,
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    borderRadius: 5,
    borderColor: colors.AppNewGreen,
    borderWidth: 1,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
  },
  tickViewStyle: {
    width: 24,
    height: 24,
    backgroundColor: colors.Appgreen,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
  },
});
