import React, { Component } from 'react';
import { Text,  View, Image,StyleSheet} from 'react-native';
import colors from '../HelperClasses/AppColor';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import batteryIcon from  '../assets/batteryIcon.png';

export function PaidFeatureTextView() {
  return (<View style={styles.containerStyle}>
    <Text style={CommonStyleSheet.staticTextStyle}>Paid Feature</Text>
  </View>)
}
export function previewStatusBarView() {
  return (<View style={{ padding: 5, paddingLeft: 16, paddingRight: 16,justifyContent: 'space-between', flexDirection: 'row' }}>
    <Text style={[CommonStyleSheet.applyNxtBtnStyle ,{color: 'white' }]}>7:00</Text>
    <View style={{ height: 20, width: 140, backgroundColor: colors.previewColor, marginTop: -5, borderBottomEndRadius: 10, borderBottomStartRadius: 10 }} />
    <Image source={batteryIcon} style={{ height: 15, width: 20 }} />
  </View>)
}

const styles = StyleSheet.create({
  containerStyle: {
    borderRadius: 5,
    marginTop: 4,
    padding: 7,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.PaidBGColor,//colors.lightYellow,
    flexDirection: 'row',
    borderColor: colors.PaidOutlineColor,
    borderWidth:1
  }
})