import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  TextInput,
  Dimensions,
  Alert,
  Platform,
  Image,
  SafeAreaView,
} from 'react-native';
import edit_Icon from '../../assets/editIcon.png';
import deleteIcon from '../../assets/delete_icon.png';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import PropTypes from 'prop-types';
import AddAttribute from './AddAttributeValues';
import EmptyListUI from '../../Component/AppEmptyList';
import addIcon from '../../assets/add_icon.png';
import dropIcon from '../../assets/dropIcon.png';
import addIconGry from '../../assets/addIconGray.png';
import disableIcon from '../../assets/disableIcon.png';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { checkUserRoleModule } from '../../HelperClasses/SingletonClass';
import { MODULEKEYS } from '../../Models/Enum';
import AppConstants from '../../Constants/AppConstants';
import { AppToast } from '../../HelperClasses/AppToast';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import { getThumbnailImage } from '../../HelperClasses/SingletonClass';
import SaveButton from '../../Component/SaveButton';
import SecondryButton from '../../Component/SecondryButton';
import AddAttributes from './AddAttributes';
import { ToastContainer } from 'react-toastify';
import EditBulkScreen from './EditBulk';
import { TranslateEnum } from '../../Models/TranslateEnum';
import Translations from './Translation';
import { RestrictedFeatureEnum } from '../../Models/RestrictedFeatureEnum';
import SubscribePlan from '../../Component/SubscribePlan';
import placeholder from '../../assets/pPlaceholder.png';
import { TypeEnum } from '../../Models/CommonEnum';

const APPURL = require('./../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth ? true : false

export default class AttributesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributeArray: [],
      dataLoad: false,
      updateUI: false,
      attributeData: [],
      isEdit: false,
      selectedAttributeID: 0,
      addAttributeBool: false,
      selectedAttributeName: '',
      haveData: false,
      writeP: AppConstants.defaultWrite,
      deleteP: AppConstants.defaultDelete,
      addAttributeValuesBool: false,
      editBulkBool: false,
      translationBool: false,
      selectedData: '',
      showUpgrade: false,
      currentType:'',
    }
    this.handleRLDDChange = this.handleRLDDChange.bind(this)
  }
  static propTypes = {
    editBtn: PropTypes.func,
    type: PropTypes.string,
    addAttibuteValues: PropTypes.func,
  };
  componentDidMount() {
    this.loadAttributeApi()
    this.checkModule()
    addMixpanelEvent(`${this.props.type} ${MixpanelEvent.viewAttribute}`, { 'type': MixPannelEnum.listView })
  }
  componentDidUpdate(){
    if(this.state.currentType.length != 0){
      if (this.state.currentType != this.props.type) {
        this.setState({showUpgrade:false,editBulkBool:false,dateLoad: false,translationBool:false, addAttributeValuesBool:false,addAttributeBool:false})
        this.loadAttributeApi()
      }
    }
  }
  checkModule = () => {
    let module = this.props.type == TypeEnum.listings  ? MODULEKEYS.LISTIINGSATTRIBUTE : MODULEKEYS.ACCOUNTSATTRIBUTE
    checkUserRoleModule(module, res => {
      console.log('ACCOUNTSATTRIBUTE', res)
      this.setState({ writeP: res[1], deleteP: res[2] })
    })
  }
  //MARK:-  APIS Method 
  loadAttributeApi = async () => {
    this.setState({ dataLoad: false })
    let path = this.props.type == TypeEnum.listings ? 'listings' : 'accounts'
    this.state.currentType = this.props.type

    const responseJson = await networkService.networkCall(APPURL.URLPaths.attributes + `?type=${path}`, 'get')
    this.setState({ attributeArray: [] });
    if (responseJson['status'] == true) {
      var attriD = responseJson['data']['attributes'];
      // console.log('attriD', attriD)
      for (let i = 0; i < attriD.length; i++) {
        this.state.attributeArray.push(attriD[i]);
      }
      this.state.haveData = this.state.attributeArray.length == 0 ? true : false
    } else {
      this.alertView(responseJson);
    }
    this.setState({ dataLoad: true });
  };
  deleteAtrrApi = async id => {
    this.setState({ dataLoad: false });
    const responseJson = await networkService.networkCall(APPURL.URLPaths.attributes + '/' + id, 'delete', '', '');
    this.setState({ dataLoad: true });
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true)
      this.loadAttributeApi();
      this.alertView('delete');
    } else {
      this.mixpanelInfo(false)
    }
  }
  mixpanelInfo(resp) {
    let properties = { success: resp }
    addMixpanelEvent(`${this.props.type} ${MixpanelEvent.deleteAttribute}`, properties)
  }
  bulkAttributeAPI = async data => {
    var dict = [];
    var i = 1;
    for (let objc of data) {
      var doc = {
        'order_by': i,
        'id': objc['id']
      }
      dict.push(doc);
      i++;
    }
    var path = APPURL.URLPaths.attributes + APPURL.URLPaths.bulk;
    const responseJson = await networkService.networkCall(
      path, 'PATCH', JSON.stringify({ attributes: dict }));
    // console.log('category', responseJson);
    if (responseJson['status'] == true) {
    } else {
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast(title);
    } else {
      Alert.alert(title);
    }
  }

  editButtonAction = index => {
    let id_Index = this.state.attributeArray.findIndex(x => x.id === index);
    if (this.props.fromRootScreen) {
      this.setState({ selectedData: this.state.attributeArray[id_Index], addAttributeBool: true, isEdit: true })
    } else {
      this.props.editBtn(this.state.attributeArray[id_Index]);
    }
  };

  addAttributeBtnAction() {
    this.setState({ addAttributeBool: !this.state.addAttributeBool });
    this.setState({ isEdit: false })
  }
  addAttributeValueBtnAction = index => {
    let id_Index = this.state.attributeArray.findIndex(x => x.id === index)
    if (this.props.fromRootScreen) {
      this.setState({
        selectedAttributeName: this.state.attributeArray[id_Index]['name'],
        selectedAttributeID: this.state.attributeArray[id_Index]['id'],
        addAttributeValuesBool: !this.state.addAttributeValuesBool,
      })
    } else {
      let dic = {
        attributeName: this.state.attributeArray[id_Index]['name'],
        attributeID: this.state.attributeArray[id_Index]['id'],
      }
      this.props.addAttibuteValues(dic)
    }

  }
  // addAttributeValueBtnAction = index => {
  //   let id_Index = this.state.attributeArray.findIndex(x => x.id === index);
  //   // console.log('selectedAttributeName', id_Index);
  //   this.setState({
  //     selectedAttributeName: this.state.attributeArray[id_Index]['name'],
  //     selectedAttributeID: this.state.attributeArray[id_Index]['id'],
  //     addAttributeBool: !this.state.addAttributeBool,
  //   });
  // }
  deleteAttributeAction = index => {
    let msg = 'Do you want to go delete this Attribute?';
    if (Platform.OS === 'web') {
      const r = window.confirm(msg);
      if (r == true) {
        this.deleteAtrrApi(index);
      }
    } 
  }
  backBtnAction() {
    this.loadAttributeApi()
    this.setState({ editBulkBool: false, addAttributeBool: false, addAttributeValuesBool: false, translationBool: false })
  }
  handleRLDDChange(reorderedItems) {
    this.state.attributeArray = reorderedItems;
    this.setState({ updateUI: !this.state.updateUI });
    this.bulkAttributeAPI(reorderedItems)
  }
  /*  UI   */
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
      />
    );
  };
  tableHeaderCell = ({ item, index }) => {
    return (
      <View style={index == 0 ? styles.firstCellTableViewTitleViewStyle : styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    if (this.state.attributeArray.length == 0) {
      if (this.state.haveData) {
        return (<View style={{ height: windowHeight / 2 }}>
          <EmptyListUI showImage={true} />
        </View>)
      } else {
        return <View />;
      }
    } else {
      var views = [];
      for (let i = 0; i < this.state.attributeArray.length; i++) {
        let categories = this.state.attributeArray[i]['categories']
        let catAry = [];
        for (let obj of categories) {
          catAry.push(obj['name']);
        }
        let fldType = this.state.attributeArray[i]['field_type'];
        let val = typesArray[fldType - 1];
        views.push(
          <FlatList
            data={[
              this.state.attributeArray[i]['name'],
              val,
              this.state.attributeArray[i]['optional'],
              this.state.attributeArray[i]['icon_path'] ?? '',
              catAry.toString(),
              this.state.attributeArray[i]['visible'],
              this.state.attributeArray[i]['id'],
              this.state.attributeArray[i]['active'],
              this.state.attributeArray[i]['id'],
              this.state.attributeArray[i]['id'],
            ]}
            style={{ backgroundColor: 'white' }}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />,
        );
      }
      return (<View style={{ flexDirection: 'row', flex: 1 }}>
        <View style={{ display: this.state.writeP ? 'flex' : 'none' }}>
          <RLDD
            items={this.state.attributeArray}
            itemRenderer={this.renderDragDropView}
            onChange={this.handleRLDDChange} />
        </View>
        <View style={{ display: !this.state.writeP ? 'flex' : 'none', width: 22 }} />
        <View style={{ flexDirection: 'column' }}>
          {views}
        </View>
      </View>)
    }
  };
  renderDragDropView = (item, index) => {
    let categories = item['categories']
    let catAry = [];
    for (let obj of categories) {
      catAry.push(obj['name']);
    }
    return (<View>
      <FlatList
        data={[catAry.toString()]}
        style={{ backgroundColor: 'white' }}
        horizontal={true}
        renderItem={this.renderDropdownCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
      />
    </View>)
  }
  renderDropdownCell = ({ item }) => {
    return (<View style={tableStyle.dragdropViewStyle}>
      <Image style={tableStyle.dragIconStyle} resizeMode="center" source={dropIcon} />
    </View>)
  }
  columnCell = ({ item, index }) => {
    if (index == 2 || index == 7 || index == 5) {
      var val = index == 5 ? item == false ? 'Yes' : 'No' : item == true ? 'Yes' : 'No';
      let check = index == 5 ? item == false ? true : false : item
      if (index == 6) {
        val = item ? 'Active' : 'Inactive';
      }
      return (
        <View style={styles.columnTitleViewStyle} >
          <Text style={check == true ? tableStyle.columnActiveBtnStyle : tableStyle.columnDeActiveBtnStyle}>
            {val}
          </Text>
        </View>
      );
    } else if (index == 9) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{ flexDirection: 'row' }}>
            {this.renderEditBtn(item)}
            <View style={{ width: 10 }} />
            {this.renderDeleteBtn(item)}
          </View>
        </View>
      );
    } else if (index == 3) {
      let fileUrl = getThumbnailImage(item)
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{ marginLeft:0}}>
            <Image style={tableStyle.itemImageViewStyle}
              source={item.length == 0 ? placeholder : fileUrl}
            />
          </View>
        </View>
      )
    } else if (index == 6) {
      let id_Index = this.state.attributeArray.findIndex(x => x.id === item);
      let fldType = this.state.attributeArray[id_Index]['field_type'];
      if (fldType == 1 || fldType == 2) {
        let values = this.state.attributeArray[id_Index]['values'];
        return (
          <View style={styles.columnTitleViewStyle}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={CommonStyleSheet.plainTextStyle}>{values.length}</Text>
            </View>
          </View>
        );
      } else {
        return (
          <View style={styles.columnTitleViewStyle}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={CommonStyleSheet.plainTextStyle}>0</Text>
            </View>
          </View>
        )
      }
    } else if (index == 8) {
      let id_Index = this.state.attributeArray.findIndex(x => x.id === item);
      let fldType = this.state.attributeArray[id_Index]['field_type'];
      if (fldType == 1 || fldType == 2) {
        return (<View style={styles.columnTitleViewStyle}>
          <TouchableOpacity disabled={!this.state.writeP} onPress={() => this.addAttributeValueBtnAction(item)}>
            <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.writeP ? addIcon : disableIcon} />
          </TouchableOpacity>
        </View>)
      } else {
        return (<View style={styles.columnTitleViewStyle}>
          <View>
            <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.writeP ? addIconGry : disableIcon} />
          </View>
        </View>)
      }
    } else if (index == 0) {
      return (<View style={styles.firstCellViewStyle}>
        <View style={{ flexDirection: 'row', justifyContent: 'center', flex: 1 }}>
          {/* <Image style={styles.iconStyle} resizeMode="center" source={dropIcon} /> */}
          <Text style={tableStyle.columnViewTitleStyle} numberOfLines={2}>{item}</Text>
        </View>
      </View>)
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.columnViewTitleStyle} numberOfLines={3}>{item}</Text>
        </View>
      )
    }
  }
  renderEditBtn = (item) => {
    return <TouchableOpacity disabled={!this.state.writeP} onPress={() => this.editButtonAction(item)}>
      <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.writeP ? edit_Icon : disableIcon} />
    </TouchableOpacity>
  }
  renderDeleteBtn = (item) => {
    return <TouchableOpacity disabled={!this.state.deleteP} onPress={() => this.deleteAttributeAction(item)}>
      <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.deleteP ? deleteIcon : disableIcon} />
    </TouchableOpacity>
  }
  checkRestriction() {
    let check = AppConstants.restrictedFeatureArray.includes(RestrictedFeatureEnum.attributes)
    return check
  }
  renderSecondryBtnView = ({ id }) => {
    return (<View>
      <SecondryButton
        btnDeActive={this.checkRestriction()}
        showBtn={true} btnTitle={id == 2 ? 'Edit Bulk' : 'Translate'}
        deActiveBtnAction={() => this.setState({ showUpgrade: true })}
        btnAction={() => id == 2 ? this.setState({ editBulkBool: true }) : this.setState({ translationBool: true })} />
    </View>)
  }
  renderHeaderView = () => {
    return (<View style={[tableStyle.headerViewstyle, { display: this.props.fromRootScreen ? 'flex' : 'none' }]}>
      <View style={tableStyle.headerTitleContainerViewStyle}>
        <Text style={[tableStyle.titleTextStyle, { margin: 0 }]}>Attributes </Text>
      </View>
      <View style={{ flexDirection: 'row', marginTop: itsMobileView ? 5 : 0 }}>
        <this.renderSecondryBtnView id={2} />
        <this.renderSecondryBtnView id={1} />
        <SaveButton btnDeActive={this.checkRestriction()}
          deActiveBtnAction={() => this.setState({ showUpgrade: true })}
          saveBtn={() => this.addAttributeBtnAction()} saveBtnTitle={'Add Attribute'} />
      </View>
    </View>)
  }
  renderUpgradePlanView = () => {
    return <TouchableOpacity style={[tableStyle.passwordViewStyle, { display: this.state.showUpgrade ? 'flex' : 'none' }]}
      onPress={() => this.setState({ showUpgrade: false })}>
      <SubscribePlan upgrade={true} />
    </TouchableOpacity>
  }
  render() {
    if (this.state.addAttributeValuesBool) {
      return (
        <ScrollView style={{ flexDirection: 'column' }}>
          <AddAttribute
            type={this.props.type}
            isEdit={this.state.isEdit}
            attributeName={this.state.selectedAttributeName}
            attributeID={this.state.selectedAttributeID}
            backBtnAction={() => this.backBtnAction()} />
        </ScrollView>
      )
    } else if (this.state.addAttributeBool) {
      return (
        <View style={{ flexDirection: 'column' }}>
          <ToastContainer />
          <AddAttributes
            type={this.props.type}
            isEdit={this.state.isEdit}
            attributeData={this.state.selectedData}
            closeBtnAction={() => this.setState({ addAttributeBool: false })}
            backBtnAction={() => this.backBtnAction()} />
        </View>
      );
    } else if (this.state.editBulkBool) {
      return (
        <View style={{ flexDirection: 'column' }}>
          <ToastContainer />
          <EditBulkScreen
            titleString={this.props.type}
            bulkTitle={'Attributes'}
            bulkRef={TranslateEnum.attribute}
            backBtnAction={() => this.backBtnAction()} />
        </View>
      )
    } else if (this.state.translationBool) {
      return (
        <View style={{ flexDirection: 'column' }}>
          <ToastContainer />
          <Translations
            titleString={this.props.type}
            translationTitle={'Attributes'}
            translationRef={TranslateEnum.attribute}
            backBtnAction={() => this.setState({ translationBool: false })} />
        </View>
      )
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <ToastContainer />
          <View style={{ zIndex: 101 }}>
            <this.renderUpgradePlanView />
          </View>
          <View style={{ zIndex: 1 }}>
            <this.renderHeaderView />
            <View style={this.props.fromRootScreen ? tableStyle.containerMainView : tableStyle.listContainerView}>
              <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
                <View>
                  <View style={{ height: 45, backgroundColor: colors.AppLightGreen, borderTopStartRadius: 5, borderTopEndRadius: 5 }}>
                    <this.tableViewHeader />
                  </View>
                  <ScrollView style={styles.tableViewHeaderStyle}>
                    <this.columnDataView />
                  </ScrollView>
                </View>
              </ScrollView>
            </View>
            <Appload enable={this.state.dataLoad} />
          </View>
        </SafeAreaView>
      );
      //}
    }
  }
}
const divideWidth = 9.8;
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : (windowWidth / divideWidth),
    paddingLeft: 35,
  },
  firstCellTableViewTitleViewStyle: {
    padding: 0,
    width: 150,
    paddingLeft: 35,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : windowWidth / divideWidth,
    borderBottomWidth: 1,
    borderBottomColor: colors.SimonGray,
    padding: 20,
    paddingLeft: 10,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: 70,
  },
  firstCellViewStyle: {
    width: 150,
    paddingLeft: 10,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    justifyContent: 'flex-start',
    borderBottomWidth: 1,
    borderBottomColor: colors.SimonGray,
    // padding: 20,
    height: 70,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
});

const tableHeaderString = [
  'Attribute Name',
  'Type',
  'Optional',
  'Icon',
  'Categories',
  'Hidden Field',
  'Values',
  'Status',
  'Manage Attribute Values',
  'Action',
];

let typesArray = [
  'Single Select',
  'Multi Select',
  'Open (Single Value)',
  'Open (Multiple Value)',
  'Attachment',
  'Text Area',
  'Date Time',
  'Number', 'Boolean',
  'Color',
  'Json',
  'Location',
];