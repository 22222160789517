import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Platform,
  Alert,
  TouchableOpacity,
  SafeAreaView,
  Dimensions,
  Image,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import RightView from './TenantCommonClasses/RightCustomView';
import commonStyle from '../StyleSheets/CreateTenantCommonStyleSheet';
import HeaderView from './TenantCommonClasses/LogoHeader';
import storePickup_Icon from '../assets/storePickupIcon.png';
import doorDelivery_Icon from '../assets/doorDeliveryIcon.png';
import intDelivery_Icon from '../assets/integrationDeliveryIcon.png';
import checked_Icon from '../assets/checkedIcon.png';
import unchecked_Icon from '../assets/uncheckedIcon.png';
import shippo_Icon from '../assets/shippoIcon.png';
import easyPost_Icon from '../assets/easyPostIcon.png';
import BottomView from './TenantCommonClasses/FooterView';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../HelperClasses/AppToast';
import {ToastContainer} from 'react-toastify';
import {getValue} from '../HelperClasses/UserPrefrences';
import AppConstants from '../Constants/AppConstants';

const windowHeight = Dimensions.get('window').height;
const APPURL = require('../Constants/URLConstants');
const contentFul = require('../Constants/ContentFullConstant');

let imagesArray = [
  storePickup_Icon,
  doorDelivery_Icon,
  intDelivery_Icon,
  doorDelivery_Icon,
  storePickup_Icon,
];

export default class SelectShippingPlatform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedShippingIndex: ['', '', '', ''],
      selectedShippingMethods: [],
      selectedDeliveryOption: 1,
      tenantShippingArray: [],
      updateUI: false,
      dataLoad: false,
      count: 1,
      paymentCount: 1,
    };
  }
  componentDidMount() {
    this.loadTenantShippingMethodApi();
  }
  //MARK:-  APIs Method 
  loadTenantShippingMethodApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.shippingMethod,
      'get',
      '',
      '',
    );
    // console.log('country => ', responseJson);
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var ppData = responseJson['data']['shipping_methods'];
      for (let obj of ppData) {
        if (obj['channel'] != 'cod') {
          this.state.tenantShippingArray.push(obj);
        }
      }
    }
    this.setState({dataLoad: true});
  };
  addCurrencyApi = async () => {
    const {currencyData} = this.props.route.params;
    var dict = {
      name: currencyData['name'],
      default: true,
      active: true,
      order_by: 1,
      code: currencyData['code'],
      exchange_rate: 1,
      precision: currencyData['decimal_digits'],
      thousand_separator: ',',
      decimal_point: '.',
      format: `${currencyData['symbol_native']}{amount}`,
    };
    var path = APPURL.URLPaths.currencies;
    const responseJson = await networkService.networkCall(
      path,
      'post',
      JSON.stringify({currency: dict}),
      '',
    );
    if (responseJson['status'] == true) {
      this.addAppLanguageApi();
    } else {
      this.setState({dataLoad: true});
      this.alertView(responseJson);
    }
  };
  addAppLanguageApi = async () => {
    const {languageData} = this.props.route.params;
    var dict = {
      language_id: languageData['id'],
      name: languageData['name'],
      active: true,
      default: true,
      order_by: 1,
    };
    var path = APPURL.URLPaths.tanantslanguage;
    const responseJson = await networkService.networkCall(
      path,
      'post',
      JSON.stringify({language: dict}),
      '',
    );
    if (responseJson['status'] == true) {
      const shippingMethodsArray = this.state.selectedShippingMethods;
      for (let obj of shippingMethodsArray) {
        this.addShippingMethodApi(obj);
      }
    } else {
      this.setState({dataLoad: true});
    }
  };
  addShippingMethodApi = async data => {
    var dict = {
      shipping_method_id: data['id'],
      name: data['name'],
      active: true,
      default: true,
    };
    dict['order_by'] = 1;
    var path = APPURL.URLPaths.tenantShippingMethod;
    const responseJson = await networkService.networkCall(
      path,
      'post',
      JSON.stringify({shipping_method: dict}),
      '',
    );
    console.log('addShippingMethodApi', responseJson);
    if (responseJson['status'] == true) {
      const shippingMethodsArray = this.state.selectedShippingMethods;
      if (this.state.count == shippingMethodsArray.length) {
        const {paymentMethodsArray} = this.props.route.params;
        this.state.count = 1;
        for (let obj of paymentMethodsArray) {
          this.addPaymentMethodApi(obj);
        }
      }
      this.state.count = this.state.count + 1;
    } else {
      this.setState({dataLoad: true});
    }
  };

  addPaymentMethodApi = async data => {
    var dict = {
      payment_method_id: data['id'],
      name: data['name'],
      active: true,
      default: true,
      min_amount: 1,
      max_amount: 100,
      order_by: 1,
    };
    var path = APPURL.URLPaths.tenantPaymentMethod;
    const responseJson = await networkService.networkCall(
      path,
      'post',
      JSON.stringify({payment_method: dict}),
      '',
    );
    if (responseJson['status'] == true) {
      const {paymentMethodsArray} = this.props.route.params;
      if (this.state.paymentCount == paymentMethodsArray.length) {
        // this.addAppLanguageApi();
        // this.addLocationMapApi();
        // this.addAuthApi()
        getValue('id', resp => {
          this.createBusinessApi(resp[0]);
        });
      }
      this.state.paymentCount = this.state.paymentCount + 1;
    } else {
      this.setState({dataLoad: true});
    }
  };

  addLocationMapApi = async () => {
    // console.log('filesData =>', filesData);
    const {locationData} = this.props.route.params;
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: locationData}),
      '',
    );
    if (responseJson['status'] == true) {
      getValue('id', resp => {
        this.createBusinessApi(resp[0]);
      });
    } else {
      this.setState({dataLoad: true});
    }
  };
  createBusinessApi = async parameter => {
    const {businessData} = this.props.route.params;
    var dict = {
      type: 1,
      sub_type: businessData,
    };
    console.log('createBusinessApi param', dict);
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.createTenant + `${parameter}`,
      'PATCH',
      JSON.stringify({tenant: dict}),
      '',
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.addAuthApi();
    }
  };
  addAuthApi = async () => {
    var configs = [];
    let fDict2 = {
      key_group: 'onboarding',
      key: 'onboarding_completed',
      value: true,
      secured: false,
    };
    let fDict3 = {
      key_group: 'general',
      key: 'auth_type',
      value: 1, //this.state.selectedRegistrationType,
      secured: false,
    };
    configs.push(fDict2);
    configs.push(fDict3);
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
      '',
    );
    if (responseJson['status'] == true) {
      AppConstants.appType = false;
      this.setState({dataLoad: true});
      // this.props.navigation.navigate('FinalScreen');
      // this.props.navigation.dispatch(StackActions.replace('SuperAdmin'));
    } else {
      this.setState({dataLoad: true});
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  submitButtonAction() {}
  didSelectSetting = id => {
    if (this.state.selectedShippingIndex[id] == '') {
      this.state.selectedShippingIndex[id] = id;
    } else {
      this.state.selectedShippingIndex[id] = '';
    }
    let sid = this.state.tenantShippingArray[id - 1];
    let index = this.state.selectedShippingMethods.findIndex(
      x => x.id == sid['id'],
    );
    if (index == -1) {
      this.state.selectedShippingMethods.push(sid);
    } else {
      this.state.selectedShippingMethods.splice(index, 1);
    }
    this.setState({updateUI: !this.state.updateUI});
  };
  nextBtnAction() {
    if (this.state.selectedShippingMethods != 0) {
      this.setState({dataLoad: false});
      this.addCurrencyApi();
      // const {currencyData,paymentMethodsArray, languageData,businessData} = this.props.route.params;
      // this.props.navigation.navigate('RegistrationTypePlatform', {
      //   currencyData: currencyData,
      //   shippingMethodsArray: this.state.selectedShippingMethods,
      //   paymentMethodsArray: paymentMethodsArray,
      //   languageData:languageData,
      //   businessData:businessData,
      // });
    } else {
      AppToast('Select atleast one Shipping method');
    }
  }
  //MARK:-  UI Method 
  renderDeliveryView = () => {
    var view = [];
    for (let a = 1; a < this.state.tenantShippingArray.length + 1; a++) {
      view.push(
        <TouchableOpacity
          style={
            this.state.selectedShippingIndex[a] != ''
              ? styles.selectedShippingViewsStyle
              : styles.shippingViewsStyle
          }
          onPress={id => this.didSelectSetting(a)}>
          <View style={{flex: 1, width: '100%'}}>
            <Image
              style={styles.checkBoxViewStyle}
              resizeMode="contain"
              source={
                this.state.selectedShippingIndex[a] != ''
                  ? checked_Icon
                  : unchecked_Icon
              }
            />
          </View>
          <View style={{alignItems: 'center', flex: 4, width: '100%'}}>
            <Image
              style={commonStyle.iconImageViewStyle}
              resizeMode="contain"
              source={imagesArray[a - 1]}
            />
            <Text style={commonStyle.customViewTitleStyle}>
              {this.state.tenantShippingArray[a - 1]['name']}
            </Text>
            {/* <Text style={commonStyle.customViewsubTitleStyle}>
              (on all purchase)
            </Text> */}
          </View>
        </TouchableOpacity>,
      );
    }
    return view;
  };

  deliveryOptionsView = () => {
    if (this.state.selectedShippingIndex == 231) {
      return (
        <View style={{marginTop: 20}}>
          <Text style={appConfigStyle.titleTxtStyle}>
            {'   Delivery integrating options'}
          </Text>
          <View style={{flexDirection: 'row', marginTop: 20}}>
            <View>
              <TouchableOpacity
                style={
                  this.state.selectedDeliveryOption == 1
                    ? commonStyle.selectedTypeViewStyle
                    : commonStyle.typeViewStyle
                }
                onPress={() => this.setState({selectedDeliveryOption: 1})}>
                <Image
                  style={styles.deliveryTypeIconStyle}
                  resizeMode="contain"
                  source={shippo_Icon}
                />
              </TouchableOpacity>
              <Text
                style={
                  this.state.selectedDeliveryOption == 1
                    ? commonStyle.selectedTypeTextStyle
                    : commonStyle.typeTextStyle
                }>
                {'Shippo'}
              </Text>
            </View>
            <View>
              <TouchableOpacity
                style={
                  this.state.selectedDeliveryOption == 2
                    ? commonStyle.selectedTypeViewStyle
                    : commonStyle.typeViewStyle
                }
                onPress={() => this.setState({selectedDeliveryOption: 2})}>
                <Image
                  style={styles.deliveryTypeIconStyle}
                  resizeMode="contain"
                  source={easyPost_Icon}
                />
              </TouchableOpacity>
              <Text
                style={
                  this.state.selectedDeliveryOption == 2
                    ? commonStyle.selectedTypeTextStyle
                    : commonStyle.typeTextStyle
                }>
                {'Easypost'}
              </Text>
            </View>
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };

  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={styles.Container}>
          <ToastContainer />
          <View style={commonStyle.mainViewStyle}>
            <View style={commonStyle.leftViewStyle}>
              <HeaderView />
              <ScrollView style={{height: windowHeight - 100}}>
                <View style={commonStyle.contentViewStyle}>
                  <ScrollView showsVerticalScrollIndicator={false}>
                    <View>
                      <Text style={commonStyle.contentTitleStyle}>
                        Shipping / Services Option
                      </Text>
                      {/* <Text style={{color: colors.Appgray, marginTop: 10}}>
                      Lorem ipsum dolor sit amet, consectetur{'\n'}
                      adipiscing elit aenean massa.
                    </Text> */}
                      <View style={{marginTop: 20}} />
                      <ScrollView horizontal={true}>
                        <View style={commonStyle.customViewContainer}>
                          <this.renderDeliveryView />
                        </View>
                      </ScrollView>
                      <this.deliveryOptionsView />
                    </View>
                    <View style={{marginTop: -10}}>
                      <BottomView
                        nextBtn={() => this.nextBtnAction()}
                        backBtn={() => this.props.navigation.pop()}
                        pageNumber={'5'}
                        progressValue={'100%'}
                        nextBtnTitle={'Finish'}
                      />
                    </View>
                  </ScrollView>
                </View>
              </ScrollView>
            </View>
            <View style={commonStyle.rightViewStyle}>
              <RightView
                title={contentFul.shippingTitle}
                description={contentFul.shippingDescription}
              />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />;
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  shippingViewsStyle: {
    margin: 10,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: 180,
    width: 160,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedShippingViewsStyle: {
    margin: 12,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderWidth: 2,
    height: 180,
    width: 160,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.AppNewGreen,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
  },
  checkBoxViewStyle: {
    width: 20,
    height: 20,
    margin: 10,
  },
  deliveryTypeIconStyle: {
    width: 50,
    height: 50,
  },
});
