import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  TextInput,
  Image,
  FlatList,
  SafeAreaView,
  Dimensions,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../HeaderView';
import {AppToast} from '../../HelperClasses/AppToast';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import downArrow_Icon from '../../assets/downArrowIcon.png';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import { onHandleNumberFieldChange } from '../../HelperClasses/SingletonClass';
import StripeIntegration from './StripeIntegration';
import PayU from './PayU';
import appTopBar from '../../assets/appTopBar.png';
import PreviewStyleSheet from '../../StyleSheets/PreviewStyleSheet';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import radioUncheckIcon from '../../assets/radioUncheck.png';
import Switch from "react-switch";
import AppColor from '../../HelperClasses/AppColor';
import { addMixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import { Gradient } from 'react-gradient';
import SaveButton from '../../Component/SaveButton';
import AppConstants from '../../Constants/AppConstants';


const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight =  Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth  ?  true : false

export default class AddPaymentMenthod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      status: true,
      default: true,
      name: '',
      dataLoad: false,
      id: '',
      paymentMethodsArray: [],
      updatingBool: false,
      rank:'',
      regexp: /^[0-9.\b]+$/,
      maximumAmount: '',
      minimumAmount: '',
      selectedPaymentMethod: '',
      selectedPaymentMethodID: '',
      type: '',
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    paymentMethodData: PropTypes.any,
    isEdit: PropTypes.bool,
    type: PropTypes.string,
  };
  componentDidMount() {
    const catData = this.props.paymentMethodData;
    const isEdit = this.props.isEdit;
    // console.log('isEdit =>', isEdit);
    if (isEdit) {
      if (!this.state.updatingBool) {
        this.setState({
          name: catData['name'],
          status: catData['active'],
          default: catData['default'],
          id: catData['id'],
          maximumAmount: catData['max_amount'],
          minimumAmount: catData['min_amount'],
          rank: catData['order_by'],
          type: catData['type'],
          updatingBool: true,
        });
      }
    }
    this.loadPaymentMethodsApi();
  }
  //MARK:- Api Methods
  loadPaymentMethodsApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.paymentMethod, 'get', '', '');
    this.setState({paymentMethodsArray: []});
    if (responseJson['status'] == true) {
      var objectD = responseJson['data']['payment_methods'];
      for (let obj of objectD) {
        this.state.paymentMethodsArray.push(obj);
      }
      if (this.state.paymentMethodsArray.length != 0) {
        if (this.state.id !== '') {
        let index = this.state.paymentMethodsArray.findIndex(x => x.type == this.state.type)
          if (index != -1) {
            let item = this.state.paymentMethodsArray[index];
            this.setState({ selectedPaymentMethod: item['name'],  selectedPaymentMethodID: item['id']});
          }
        } else {
          let item = this.state.paymentMethodsArray[0];
          this.setState({ selectedPaymentMethod: item['name'],  selectedPaymentMethodID: item['id']});
        }
      } 
    }
    this.setState({dataLoad: true});
  };
  addPaymentMethodApi = async image => {
    var dict = {
      payment_method_id: this.state.selectedPaymentMethodID,
      name: this.state.name,
      active: this.state.status,
      default: this.state.default,
      min_amount: this.state.minimumAmount,
      max_amount: this.state.maximumAmount,
    };
    if (this.state.rank != 0) {
      dict['order_by'] = this.state.rank;
    }
    var path = APPURL.URLPaths.tenantPaymentMethod;

    if (this.state.id !== '') {
      path = APPURL.URLPaths.tenantPaymentMethod + '/' + this.state.id;
    }
    const responseJson = await networkService.networkCall(
      path,this.state.id !== '' ? 'put' : 'post', JSON.stringify({payment_method: dict}))
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true)
      this.props.backBtnAction(true)
      this.customAlert()
    } else {
      this.mixpanelInfo(false)
      AppToast(responseJson)
    }
  }
  mixpanelInfo(resp){
    let properties = { success:resp }
    let event = this.state.id !== '' ? MixPannelEnum.editPaymentMethod : MixPannelEnum.addPaymentMethod
    addMixpanelEvent(event,properties)
  }
  customAlert() {
    AppToast();
  }
  //MARK:-  Button Actions
  submitBtnAction = () => {
    this.setState({dataLoad: false});
    this.addPaymentMethodApi()
  }
  didSelectDropDown = item => {
    // console.log('index', item);
    this.setState({
      showDropDown: !this.state.showDropDown,
      selectedPaymentMethod: item['name'],
      selectedPaymentMethodID: item['id'],
    });
  };
  onHandleTextChange = (id,text) => {
    let value = text;
    if (text == '' || this.state.regexp.test(value)) {
      if (id == 1){
        this.setState({maximumAmount: text});
      } else {
        this.setState({minimumAmount: text});
      }
    }
  };
  // UI Renders
  renderSaveConfigBtn = () => {
    return <View>
      <SaveButton saveBtn={() => this.submitBtnAction()} saveBtnTitle={'Save'} />
    </View>
  }
  customDropDown = id => {
    if (this.state.showDropDown == true) {
      return (
        <View style={commonStyle.showDropDownViewStyle}>
          <FlatList
            data={this.state.paymentMethodsArray}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={true}
            alwaysBounceVertical={true}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDropDown(item, index)}
        style={{margin: 5, height: 30, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text style={CommonStyleSheet.dropdownCellItemTextStyle}>
            {item['name']}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderValueLabel = id => {
    return (
      <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between',alignItems: 'center'}}>
        <Text style={this.state.selectedPaymentMethod == '' ? appConfigStyle.placeholderTxtStyle : appConfigStyle.txtFieldStyleDropDown}>
          {this.state.selectedPaymentMethod == '' ? 'Select payment method' : this.state.selectedPaymentMethod}
        </Text>
        <Image 
          style={this.state.showDropDown == true ? appConfigStyle.upArraowIconStyle : appConfigStyle.downArraowIconStyle}
          resizeMode="contain"
          source={downArrow_Icon}
        />
      </View>
    );
  };
  titleLblRender = ({title}) => {
    return (
      <View>
        <Text style={commonStyle.titleNameStyle}>
          {title}
          <Text style={{color: 'red', paddingTop: 5}}> *</Text>
        </Text>
      </View>
    )
  }
  render() {
    const isEdit = this.props.isEdit;
    return (
      <SafeAreaView style={styles.Container}>
        <View style={commonStyle.sidepanSaveBtnViewStyle}>
          {this.renderSaveConfigBtn()}
        </View>
        <ScrollView style={{ height: windowHeight / 1.20 }}>
          <View style={commonStyle.sidepanmViewStyle}>
            <View style={[commonStyle.configContainerViewStyle, { zIndex: 10010 }]}>
              <this.titleLblRender title={'Select type'} />
              <TouchableOpacity
                style={[commonStyle.dropDownViewStyle, { width: '100%' }]}
                onPress={() => this.setState({ showDropDown: !this.state.showDropDown })}>
                <this.renderValueLabel id={2} />
                <this.customDropDown id={2} />
              </TouchableOpacity>
            </View>
            <View style={{ height: 0, width: '100%' }} />
            <View style={commonStyle.configContainerViewStyle}>
              <this.titleLblRender title={'Name'} />
              <TextInput
                style={[commonStyle.txtFieldStyle, { outline: 0, marginLeft: 0, width: '100%' }]}
                value={this.state.name}
                placeholder="Enter name"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({ name: name })}
              />
            </View>
            <View style={{ height: 0 }} />
            <View style={commonStyle.configContainerViewStyle}>
              <this.titleLblRender title={'Maximum amount'} />
              <TextInput
                style={[commonStyle.txtFieldStyle, { outline: 0, marginLeft: 0, width: '100%' }]}
                value={this.state.maximumAmount}
                placeholder="Enter maximum amount"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.onHandleTextChange(1, text)}
              />
            </View>
            <View style={{ height: 0 }} />
            <View style={commonStyle.configContainerViewStyle}>
              <this.titleLblRender title={'Minimum amount'} />
              <TextInput
                style={[commonStyle.txtFieldStyle, { outline: 0, marginLeft: 0, width: '100%' }]}
                value={this.state.minimumAmount}
                placeholder="Enter minimum amount"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.onHandleTextChange(2, text)}
              />
            </View>
            <View style={{ height: 0 }} />
            <View style={commonStyle.configContainerViewStyle}>
              <Text style={commonStyle.titleNameStyle}>Order</Text>
              <TextInput
                style={[commonStyle.txtFieldStyle, { outline: 0, marginLeft: 0, width: '100%' }]}
                value={this.state.rank}
                placeholder="Enter order"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={txt => this.setState({ rank: onHandleNumberFieldChange(txt) })}
              />
            </View>
            <View style={{ height: 10 }} />
            <View style={commonStyle.switchViewStyle}>
              <Text style={commonStyle.titleNameStyle}>Status(Not Active / Active) </Text>
              <View style={{ width: itsMobileView ? '12%' : '20%' }}>
                <Switch
                  onColor={colors.AppNewGreen}
                  onChange={() => this.setState({ status: !this.state.status })}
                  checked={this.state.status}
                  height={22}
                  width={50}
                />
              </View>
            </View>
            <View style={{ height: 0 }} />
            <View style={commonStyle.switchViewStyle}>
              <Text style={commonStyle.titleNameStyle}>Default</Text>
              <View style={{ width: itsMobileView ? '12%' : '20%' }}>
                <Switch
                  onColor={colors.AppNewGreen}
                  onChange={() => this.setState({ default: !this.state.default })}
                  checked={this.state.default}
                  height={22}
                  width={50}
                />
              </View>
            </View>
            <View style={{ height: 20 }} />
          </View>
        </ScrollView>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: AppColor.AppLightGreen,
  },
});

