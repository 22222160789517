import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
  SafeAreaView,
  Image,
} from 'react-native';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import colors from './../HelperClasses/AppColor';
import networkService from './../Network/NetworkManager';
import tableStyle from './../StyleSheets/TableCommonStyleSheet';
import {Gradient} from 'react-gradient';
import PropTypes from 'prop-types';
import Moment from 'moment';
import EmptyListUI from '../Component/AppEmptyList';
import { ToastContainer} from 'react-toastify';
import downGreenIcon from '../assets/downGreenArrow.png';
import upRedIcon from '../assets/upRedArrow.png';
import OrderDetail from '../Orders/OrderDetails';
import {dateConversionFromTimeStamp} from '../HelperClasses/SingletonClass';
import appMessage from '../Constants/AppConstantsMessage';
import whiteTick from '../assets/whiteTick.png';
import downArrow_Icon from '../assets/downArrowIcon.png';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../Models/MixPannelEnum';
import TransactionEnum from '../Models/TransactionEnum';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import checked_Icon from '../assets/checkedIcon.png';
import unchecked_Icon from '../assets/uncheckedIcon.png';
import StatusModel from './../Models/StatusModel';
import DatePicker,{CalendarContainer} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AppConstants from '../Constants/AppConstants';


const APPURL = require('./../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;;
let watchAnimationData = require('./../cat.json');
var pageNo = 1;
let itsMobileView = windowWidth < fixedWidth  ?  true : false
let laneHeight = windowHeight/1.55
let PerPage = 25
let dateFormat = 'yyyy-MM-DD'

export default class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      transactionArray: [],
      dataLoad: false,
      stopPage: false,
      showOrderDetail: false,
      orderID: 0,
      haveData:false,
      showDropDown:false,
      perPage:PerPage,
      totalRecords: 0,
      showFilterView:false,
      selectedFilterID:-1,
      filterStartDate: '',//new Date(),
      filterEndDate: '',// new Date(),
      filterPriceValue: [0,1000],
      filterStatusValue:-1,
      appliedDate:false,
      appliedStatus:false,
      appliedPrice:false,
      showDropDown:false,
      perPage:PerPage,
      totalRecords: 0,
      selectedTransactionStatusArray:[],
    }
  }
  static propTypes = {
    saveBtnAction: PropTypes.func,
  };
  componentDidMount() {
    this.mixPannelInfo()
    addMixpanelEvent(MixpanelEvent.viewTransaction,{'type':MixPannelEnum.listView})

    pageNo = 1
    this.loadTransactionsAPi()
  }
  mixPannelInfo(){
    addMixpanelEvent(MixpanelEvent.viewTransaction,{'page':pageNo})
  }
  //MARK:-  APIS Method 
  loadTransactionsAPi = async () => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(APPURL.URLPaths.transactions + `${pageNo}&per_page=${this.state.perPage}`, 'get')
    this.setState({transactionArray: []});
    if (responseJson['status'] == true) {
      var collectionD = responseJson['data']['transactions']
      this.state.totalRecords =  responseJson['data']['total_records']
      if (collectionD.length != 0) {
        this.setState({transactionArray: []});
        this.setState({stopPage: false});
        for (let i = 0; i < collectionD.length; i++) {
          this.state.transactionArray.push(collectionD[i]);
        }
      } else {
        pageNo = pageNo <= 1 ? 1 : pageNo - 1 
        this.setState({stopPage: true});
      }
      this.state.haveData = this.state.transactionArray.length == 0 ? true : false
    }
    this.setState({dataLoad: true});
  }
  statusdata(data) {
    this.state.statusArray = []
    let ssArray = []
    var commonArray = []
    var a = 1;
    while (a < 19) {
      let objc = []
      commonArray.push(objc)
      a++
    }
    for (let i = 0; i < data.length; i++) {
      let item = data[i]
      let status = item['order_status'] - 1
      let dic = {
        id: item['id'],
        title: item['reference_number'],
        description: ['payment_status'] == 2 ? 'Pay' : 'UnPaid',
        draggable: false,
      }
      let dicAry = commonArray[status]
      dicAry.push(dic)
      commonArray[status] = dicAry
    }
    var k = 1;
    while (k < 19) {
      let status = 1
      let randomColor = Math.floor(Math.random()*16777215).toString(16);
      let pendingDic = {
          id: 'lane' + k,
          title: status,
          label: '',
          cards: commonArray[k],
          style:{backgroundColor :"#" + randomColor, maxHeight:laneHeight,color: 'white'}
      }
      ssArray.push(pendingDic)
      k++
    }
    this.state.statusArray.push({lanes : ssArray})
    this.setState({updateUI: !this.state.updateUI})
    // console.log('statusArray', this.state.statusArray)
  }
  //MARK:- Button Actions
  backBtnHandler() {
    this.setState({showOrderDetail: false})
  }
  viewOrderBtnAction = id => {
    this.setState({showOrderDetail: true, orderID: id});
  };
  nextPreviousBtnAction(index) {
    if (index == 1) {
      if (!this.state.stopPage) {
        pageNo = pageNo + 1;
        this.loadTransactionsAPi();
      }
    } else {
      if (pageNo == 1) {
      } else {
        pageNo = pageNo - 1;
        this.loadTransactionsAPi();
      }
    }
    this.mixPannelInfo()
    this.setState({updateUI: !this.state.updateUI});
  }
  didSelectPerPage = (item, index) => {
    this.state.perPage = item
    this.setState({showDropDown: false})
    pageNo = 1
    this.loadTransactionsAPi()
  }
  didSelectFilter(id) {
    this.setState({ selectedFilterID: this.state.selectedFilterID == id ? -1 : id })
  }
  fiterBtnAction() {
    this.setState({ showFilterView: !this.state.showFilterView, selectedFilterID: -1 })
  }
  didSelectOrderStatus= (item) => {
    let ind = this.state.selectedTransactionStatusArray.findIndex( x => x == item) 
    if (ind != -1) {
      this.state.selectedTransactionStatusArray.splice(ind,1)
    } else {
      this.state.selectedTransactionStatusArray.push(item)
    }
    this.setState({updateUI: !this.state.updateUI});
  }
  didSelectFilter(id) {
    this.setState({ selectedFilterID: this.state.selectedFilterID == id ? -1 : id })
    if (id == 3){
      this.clearBtnAction(5)
    } 
  }
  statusBtnAction(id){
    this.setState({filterStatusValue : this.state.filterStatusValue == id ? -1 : id})
  }
  applyBtnAction(id) {
    if (id == 1) {
      this.state.appliedDate = true
    }
    if (id == 2){
      this.state.appliedStatus = true
    }
    if (!this.state.appliedDate) {
      this.state.filterStartDate = ''
      this.state.filterEndDate = ''
    } 
    if (!this.state.appliedStatus) {
      this.state.filterStatusValue = -1
    }
    if (!this.state.appliedDate) {
      this.state.filterPriceValue = [0,1000]
    }

    this.setState({showFilterView: false, selectedFilterID: -1 })
  }
  clearBtnAction(id){
    if (id == 1){
      this.state.filterStartDate = ''
      this.state.filterEndDate = ''
      this.state.appliedDate = false
    } if (id == 2){
      this.state.filterStatusValue = -1
      this.state.appliedStatus = false
    } if (id == 4) {
      this.state.filterPriceValue = [0,1000]
      this.state.appliedPrice = false
    } if (id == 5) {
      this.state.filterStatusValue = -1
      this.state.selectedTransactionStatusArray = []
    }
    this.setState({showFilterView: false,selectedFilterID:-1})
  }
  fiterBtnAction() {
    if (!this.state.appliedDate) {
      this.state.filterStartDate = ''
      this.state.filterEndDate = ''
    } 
    if (!this.state.appliedStatus) {
      this.state.filterStatusValue = -1
    }
    if (!this.state.appliedDate) {
      this.state.filterPriceValue = [0,1000]
    }
    this.setState({ showFilterView: !this.state.showFilterView, selectedFilterID: -1 })
  }
  /*  UI   */
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{margin: 0}}
        keyExtractor={(item, index) => index + 9989}
        key={'H'}
        />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    )
  }
  columnDataView = props => {
      var views = [];
      for (let i = 0; i < this.state.transactionArray.length; i++) {
        var formatted = dateConversionFromTimeStamp(this.state.transactionArray[i]['created_at']);
        let type = TransactionEnum.type(this.state.transactionArray[i]['type'])
        views.push(
          <FlatList
            data={[
              this.state.transactionArray[i]['transaction_number'],
              `Order#${this.state.transactionArray[i]['reference_id']}`,
              this.state.transactionArray[i]['amount'],
              this.state.transactionArray[i]['super_type'] == 2 ? 'Seller' : 'Client',
              type,
              formatted,
            ]}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            keyExtractor={(item, index) => index + 9789}
            key={'C'}
            style={{borderBottomWidth: 1, borderBottomColor: colors.SimonGray,backgroundColor: 'white'}}
          />,
        )
      }
      return views;

  }
  columnCell = ({item, index}) => {
    if (index == 0) {
      let id_Index = this.state.transactionArray.findIndex(x => x.transaction_number == item)
      let id =  this.state.transactionArray[id_Index]['order']['id'];
      return (
        <TouchableOpacity 
          style={styles.columnTitleViewStyle}
          onPress={() => this.viewOrderBtnAction(id)}>
          <Text numberOfLines={2} style={tableStyle.mainTitleStyle}>{item}</Text>
        </TouchableOpacity>
      )
    } else if (index == 2) {
      let amnt = item['formatted'];
      let val = item['amount'];
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text >{amnt}</Text>
          <Image style={styles.iconStyle}  resizeMode="cover" source={val < 0 ? upRedIcon : downGreenIcon}/>
        </View>
      )
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
        </View>
      )
    }
  }
  gradientButtons = name => {
    if (this.state.transactionArray.length != 0 || pageNo > 1) {
    if (name['id'] == 2 && pageNo == 1) {
      return <View />
    } else if (name['id'] == 1 && this.state.transactionArray.length == 0 && pageNo > 1) {
      return <View />
    }
    return (
      <View>
        <View style={tableStyle.grandientBGStyle}>
          <Gradient
            gradients={colors.GradientColors} // required
            property="background"
            gradientType={'linear'}
            duration={2333000}
            style={{height: 30, width: 100, borderRadius: 3, marginRight: 30}}
            angle="0deg">
            <TouchableOpacity
              style={tableStyle.nextPreviousViewStyle}
              onPress={() => this.nextPreviousBtnAction(name['id'])}>
              <Text style={{color: 'white'}}>{name['name']}</Text>
            </TouchableOpacity>
          </Gradient>
        </View>
      </View>
    );
    }else {
      return <View />
    }
  };
  EmptyListRender = () => {
    if (this.state.transactionArray.length == 0) {
      if (this.state.haveData) {
        return <View style={{ height: '87%',justifyContent:'center'}}>
          <EmptyListUI showImage={true} titleString={appMessage.emptyTransactionTitleString} subtitleString={appMessage.transactionMsgTextString} />
        </View>
      }else {
        return <View />
      }
    } else {
      return <View />
    }
  }


  renderListView = () => {
    return <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
      <View>
        <View style={tableStyle.headerListContainer}>
          <this.tableViewHeader />
        </View>
        <ScrollView style={styles.tableViewHeaderStyle}>
          <this.columnDataView />
        </ScrollView>
      </View>
    </ScrollView>
  }
  entriesDropDown = props => {
    if (this.state.showDropDown == true) {
      return (
        <View style={tableStyle.perPageContainerViewStyle}>
          <View style={{height:4}} />
          <FlatList
            data={['25', '50', '100']}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            keyExtractor={(item, index) => index + 989}
            key={'E'}
          />
        </View>
      );
    }
    return <View></View>;
  }
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity  onPress={() => this.didSelectPerPage(item, index)} style={{margin:3}}>
        <View style={[tableStyle.perPageDropDownViewStyle,{backgroundColor:item == this.state.perPage ? colors.BGBlueColor : ''}]}>
          <Image source={whiteTick} style={{height:16, width:item == this.state.perPage ? 16 : 0}}/>
          <Text style={CommonStyleSheet.dropdownCellWhiteTextStyle}>{item}</Text>
        </View>
      </TouchableOpacity>
    );
  }
  renderPerView = () => {
    return <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', marginRight:20}}>
      <Text>Show rows</Text>
      <View style={{ flexDirection: 'column', zIndex: 9999}}>
        <TouchableOpacity
          onPress={() => this.setState({showDropDown: !this.state.showDropDown})}
          style={tableStyle.perPagedropDownView}>
          <Text> {this.state.perPage} </Text>
          <Image style={this.state.showDropDown == true ? appConfigStyle.upArraowIconStyle : appConfigStyle.downArraowIconStyle}
            resizeMode="contain"
            source={downArrow_Icon}
          />
        </TouchableOpacity>
        <this.entriesDropDown />
      </View>
    </View>
  }
  renderBottomView = () => {
    if (this.state.totalRecords > this.state.perPage) {
      return (<View style={tableStyle.bottomViewStyle}>
        <this.gradientButtons name={'Next'} id={1} />
        <this.gradientButtons name={'Previous'} id={2} />
        <this.renderPerView />
      </View>)
    } else {
      return <View />
    }
  }
  renderMainView = () => {
    return <this.renderListView />
  }
  renderClearApplyButton = (id) => {
    return <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <TouchableOpacity onPress={() => this.clearBtnAction(id)}>
        <Text style={tableStyle.clearTxtStyle}>Clear</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => this.applyBtnAction(id)}>
        <Text style={tableStyle.applyTxtStyle}>Apply</Text>
      </TouchableOpacity>
    </View>
  }
  renderFilterView = () => {
    return <View style={[tableStyle.filterContainerViewStyle,{marginTop:5}]}>
      <View style={{ display: this.state.showFilterView ? 'flex' : 'none', flexDirection: 'row'}}>
        <View style={[tableStyle.filterViewStyle,{paddingTop:10,flexDirection:'row-reverse'}]}>
          <FlatList
            data={FilterStaticArray}
            renderItem={this.renderFilterCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item, index) => index + 9989}
            key={'FL'}
          />
          <View style={{height:'100%', width:this.state.selectedFilterID == -1 ? 0 : 1, backgroundColor: colors.SimonGray}}/>
          {this.renderStatusView()}
          {this.renderTransactionStatusView()}
          {this.renderDateView()}
        </View>
      </View>
    </View>
  }
  renderFilterCell = ({ item, index }) => {
    return (
      <TouchableOpacity onPress={() => this.didSelectFilter(index)}
        style={{margin:10, marginLeft: 8, marginTop: 0, marginRight: 8, backgroundColor: this.state.selectedFilterID == index ? colors.SimonGray : colors.TransparentColor, padding: 5, borderRadius: 2 }}>
        <Text>{item}</Text>
      </TouchableOpacity>
    )
  }
  renderStatusView = () => {
    return <View style={{ width: 170, display: this.state.selectedFilterID == 0 ? 'flex' : 'none'}}>
      <View style={{ margin: 10 }}>
        <Text style={[CommonStyleSheet.plainSubTextStyle, { marginTop: 0 }]}>Super Types</Text>
        <View style={{ flexDirection: 'row', marginTop: 20, marginLeft: -5 }}>
          <TouchableOpacity style={this.state.filterStatusValue == 1 ? tableStyle.selectedStatusViewStyle : tableStyle.unSelectedStatusViewStyle}
            onPress={() => this.statusBtnAction(1)}>
            <Text>{'Seller'}</Text>
          </TouchableOpacity>
          <View style={{width:20}}/>
          <TouchableOpacity style={this.state.filterStatusValue == 2 ? tableStyle.selectedStatusViewStyle : tableStyle.unSelectedStatusViewStyle}
            onPress={() => this.statusBtnAction(2)}>
            <Text>{'Client'}</Text>
          </TouchableOpacity>
        </View>
        <View style={{ zIndex: 12, marginTop: 90 }}>
          {this.renderClearApplyButton(2)}
        </View>
      </View>
    </View>
  }
  renderTransactionStatusView = () => {
    var views = []
    for (let a = 1; a<12; a++) {
      let orderName = TransactionEnum.type(a)
      let ind = this.state.selectedTransactionStatusArray.findIndex(x => x == orderName)
      views.push(<View style={{borderBottomColor:colors.SimonGray, borderBottomWidth:1,marginLeft:10}}>
        <TouchableOpacity style={{ flexDirection: 'row', justifyContent: 'space-between',marginTop:4 }}
          onPress={() =>this.didSelectOrderStatus(orderName) }>
          <Text>{orderName}</Text>
          <Image style={{ width: 20, height: 20 }} source={ind != -1 ? checked_Icon : unchecked_Icon} />
        </TouchableOpacity>
        <View style={{ height: 5, width: 5 }} />
      </View>)
    }

    return <View style={{ width: 220, display: this.state.selectedFilterID == 1 ? 'flex' : 'none' }}>
      <View style={{ margin: 10,marginRight:2 }}>
        <Text style={[CommonStyleSheet.plainSubTextStyle, { marginTop: 0 }]}>Transaction Status</Text>
        <View style={{ flexDirection: 'row', marginTop: 20, marginLeft: -5 }}>
          <ScrollView style={{height:200,paddingRight:10,}}>
            {views}
          </ScrollView>
        </View>
        <View style={{ zIndex: 12, marginTop: 20 }}>
          {this.renderClearApplyButton(2)}
        </View>
      </View>
    </View>
  }
  renderCalendarContainerView = ({children,className}) => {
    return (<View style={{padding:20, backgroundColor:'red',flexDirection:'row-reverse'}}>
      <View style={{width:340, height:400,position:'relative',backgroundColor:'pink',padding:10}} >
        <CalendarContainer className={className}>
          <View>{children}</View>
        </CalendarContainer>
      </View>
    </View>)
    
  }
  renderDateView = () => {
    let strtD = this.state.filterStartDate.length == 0 ? 'Select Date' : Moment(this.state.filterStartDate).format(dateFormat)
    let endD = this.state.filterEndDate.length == 0 ? 'Select Date' : Moment(this.state.filterEndDate).format(dateFormat)
    return <View style={{ width: 160, display: this.state.selectedFilterID == 2 ? 'flex' : 'none' }}>
      <View style={{ zIndex: 126 }}>
        <Text style={CommonStyleSheet.dateTitleStyle}>Transaction Date</Text>
        <View style={[tableStyle.dateTextField,{height:35}]}>
          <DatePicker
            popperPlacement="left-start"
            selected={this.state.filterStartDate}
            onChange={(date) => this.setState({ filterStartDate: date })}
            dateFormat="MM/dd/yyyy h:mm:ss"
            showTimeInput={false}
            customInput={<View style={{ height: 20, width: 100 }}> <Text>{strtD}</Text>
            </View>}
            
          />
        </View>
      </View>
      <View style={{ zIndex: 12,marginLeft:10, marginTop: 20, marginRight: 20, marginBottom:20 }}>
        {this.renderClearApplyButton(1)}
      </View>
    </View>
  }
  renderHeaderRightBtn = () => {
    return (<View style={{ flexDirection: 'row', marginRight: 0, zIndex: 100, marginTop: itsMobileView ? 10 : 0}}>
      <View style={tableStyle.configurationBarViewStyle}>
        <View style={{ position: 'relative' }}>
          <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
            onPress={() => this.fiterBtnAction()}>
            <Text style={[CommonStyleSheet.tabBtnViewStyle,{color: this.state.showFilterView ? colors.AppNewGreen : colors.Appgray }]}>Filter</Text>
          </TouchableOpacity>
          <View style={{ backgroundColor: this.state.showFilterView ? colors.AppNewGreen : colors.TransparentColor, height: 2, width: '100%', marginTop: 5 }} />
          <View style={{ flexDirection: 'row-reverse'}}>
            {this.renderFilterView()}
          </View>
        </View>
      </View>
    </View>)
  }
  renderHeaderView = () => {
    return (<View style={tableStyle.headerViewstyle}>
      <View style={tableStyle.headerSubContainer}>
        <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 4 }}>
          <Text style={tableStyle.titleTextStyle}>{'Transactions '}</Text>
          {/* <ToolTipViewBtn title={AppConstantsMessage.accountsTitleToolTipMsg} description={AppConstantsMessage.accountsSubTitleToolTipMsg} /> */}
        </View>
        {/* <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 4}}>
          <this.renderHeaderRightBtn />
        </View> */}
      </View>
    </View>)
  }
  render() {
    if (this.state.showOrderDetail) {
      return (<OrderDetail backBtnAction={() => this.setState({showOrderDetail: false})} orderID={this.state.orderID}/>);
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <this.renderHeaderView />
          <View style={tableStyle.containerMainView}>
            <ToastContainer />
            {this.renderMainView()}
            <this.EmptyListRender />
            <this.renderBottomView />
          </View>
          <Appload enable={this.state.dataLoad} />
        </SafeAreaView>
      );
    }
  }
}
const commonWidth = 6.20

const styles = StyleSheet.create({
  Container: {
    backgroundColor: colors.AppLightGreen,
    flex: 1,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : windowWidth / commonWidth,
    height: 40,
    margin: 1,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : windowWidth  / commonWidth,
    justifyContent: "flex-start",
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft:16,
  },
  scrollViewStyle: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  iconStyle: {
    height: 30,
    width: 30,
  },
});

const tableHeaderString = [
  'Transaction No',
  'Reference',
  'Amount',
  'Super Type',
  'Transaction Type',
  'Transaction Date',
]
const FilterStaticArray = [
  'Super Type',
  'Transaction Type',
  'Date',
  'Clear All',
]
