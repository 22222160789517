import React, { Component } from 'react'
import {
  Text,
  View,
  Animated,
  Easing,
  TouchableOpacity,
  Image,
} from 'react-native'
import PropTypes from 'prop-types'
import SettingStyleSheet from '../StyleSheets/SettingStyleSheet';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import closeIcon from '../assets/closeBlackIcon.png';

const configViewRightMargin = -420

export default class SidePanView extends Component {
  constructor(props) {
    super(props)  
    this.state = {
      rightPosition: new Animated.Value(configViewRightMargin),
      addViewBool:this.props.showSidepanView
    }
  }
  static propTypes = {
    showSidepanView: PropTypes.bool,
    customView:PropTypes.any,
    title:PropTypes.string,
    dissmissView:PropTypes.func,
  }
  stopAnimation = () => {
    this.setState({ addViewBool: false })
    this.props.dissmissView()
    Animated.timing(
      this.state.rightPosition,
      {
        toValue: configViewRightMargin,
        duration: 300,
        easing: Easing.linear,
      }
    ).start()
  }
  mooveRL() {
    Animated.timing(
      this.state.rightPosition,
      {
        toValue: 0,
        duration: 300,
        easing: Easing.linear,
      }
    ).start()
  }

  renderSidePanView = () => {
    var views = []
    var showSideView = false
    var title = ''
    if (this.state.addViewBool == true) {
      showSideView = true
      title = this.props.title
      this.mooveRL()
      views.push(<View>
        {this.props.customView}
      </View>)
    }
    return <View style={[SettingStyleSheet.blackContainerViewStyle, { display: showSideView ? 'flex' : 'none' }]}>
      <TouchableOpacity style={SettingStyleSheet.transparentBtnViewStyle} onPress={() => this.stopAnimation()} />
      <View>
        <Animated.View style={[CommonStyleSheet.animationViewStyle, { right: this.state.rightPosition }]}>
          <View style={{ flexDirection: 'row', alignItems: 'center',width:250,zIndex:101}}>
             <TouchableOpacity style={{marginRight:10}} onPress={() => this.stopAnimation()}>
              <Image source={closeIcon} style={SettingStyleSheet.closeBtnStyle} />
            </TouchableOpacity>
            <Text style={CommonStyleSheet.plainTextStyle}>{title}</Text>
          </View>
          <View style={{ marginTop: 0,zIndex:10 }}>
            {views}
          </View>
        </Animated.View>
      </View>
    </View>
  }

  render() {
    return (
      <View>
        {this.renderSidePanView()}
      </View>
    )
  }
}