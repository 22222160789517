import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Platform,
  Alert,
  Dimensions,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import {Gradient} from 'react-gradient';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import HeaderView from '../HeaderView';
import SaveButton from '../../Component/SaveButton';
import AppConstants from '../../Constants/AppConstants';


const APPURL = require('../../Constants/URLConstants')
let KeyGroup = 'payments'
const windowWidth = Dimensions.get('window').width
const windowHeight =  Dimensions.get('window').height
let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth  ?  true : false


// let AccountTypesArray = ['Standard', 'Express'];
export default class PayU extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      showDropDown: false,
      hideShowPublickey: false,
      hideShowSecretkey: false,
      accountId: '',
      apiLoginKey: '',
      apiKey: '',
      merchantId: '',
      masterKey: '',
      publicKey: '',
      privateKey: '',
      token: '',
      subscriptionMsg: '',
      switchValue: true,
    }
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=payments', 'get','','');
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      // console.log('this.state.configs =>', configs);
      this.setState({
        accountId: configs['payulatam_account_id'] || '',
        apiLoginKey: configs['payulatam_api_login_key'] || '',
        apiKey: configs['payulatam_api_key'] || '',
        merchantId: configs['payulatam_merchant_id'] || '',
      });
    }
    this.setState({dataLoad: true});
  };

  addOrUpdateAppDetailApi = async filesData => {
    // console.log('filesData =>', filesData);
    var configs = [];
    let valueArray = [
      this.state.accountId,
      this.state.apiLoginKey,
      this.state.apiKey,
      this.state.merchantId,
    ];
    // var configs = [];
    for (let i = 0; i < keysArray.length; i++) {
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: true,
        };
        // console.log('fDict ==> ', fDict);
        configs.push(fDict);
      }
    }
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config,  'post',  JSON.stringify({configs: configs}))

    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.deleteKeysAPI(configs);
      // this.alertView('Uploaded successfully');
    } else {
      this.deleteKeysAPI(configs)
      this.setState({dataLoad: true});
      this.alertView(responseJson);
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    let parms = deleteParms.toString();
    let urlParm = APPURL.URLPaths.config + '?key=' + parms;
    const responseJson = await networkService.networkCall(urlParm, 'delete', '', '');
    this.setState({dataLoad: true});
    this.alertView('Uploaded successfully');
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast()
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  submitBtnAction() {
    this.setState({dataLoad: false})
    this.addOrUpdateAppDetailApi()
  }
  //MARK:-  UIs 
  renderSaveConfigBtn = () => {
    return <View>
      <SaveButton saveBtn={() => this.submitBtnAction()} saveBtnTitle={'Save'} />
    </View>
  }

  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={commonStyle.sidepanSaveBtnViewStyle}>
          {this.renderSaveConfigBtn()}
        </View>
        <View style={{ height: windowHeight / 1.16 }}>
          <ScrollView>
            <View style={commonStyle.sidepanmViewStyle}>
              <View style={{ flex: 1,padding:10}}>
                <View style={commonStyle.configContainerViewStyle}>
                  <Text style={commonStyle.titleNameStyle}>Account id</Text>
                  <TextInput
                    style={[commonStyle.txtFieldStyle, { outline: 0, width: '100%', marginLeft: 0 }]}
                    placeholder="Enter account id"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={name => this.setState({ accountId: name })}
                    value={this.state.accountId}
                  />
                </View>
                <View style={{ height: 0 }} />
                <View style={commonStyle.configContainerViewStyle}>
                  <Text style={commonStyle.titleNameStyle}>Login api key</Text>
                  <TextInput
                    style={[commonStyle.txtFieldStyle, { outline: 0, width: '100%', marginLeft: 0 }]}
                    placeholder="Enter login api key"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={name => this.setState({ apiLoginKey: name })}
                    value={this.state.apiLoginKey}
                  />
                </View>
                <View style={{ height: 0 }} />
                <View style={commonStyle.configContainerViewStyle}>
                  <Text style={commonStyle.titleNameStyle}>API key</Text>
                  <TextInput
                    style={[commonStyle.txtFieldStyle, { outline: 0, width: '100%', marginLeft: 0 }]}
                    placeholder="Enter api key"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={name => this.setState({ apiKey: name })}
                    value={this.state.apiKey}
                  />
                </View>
                <View style={{ height: 0 }} />
                <View style={commonStyle.configContainerViewStyle}>
                  <Text style={commonStyle.titleNameStyle}>Merchant ID</Text>
                  <TextInput
                    style={[commonStyle.txtFieldStyle, { outline: 0, width: '100%', marginLeft: 0 }]}
                    placeholder="Enter merchant id"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={name => this.setState({ merchantId: name })}
                    value={this.state.merchantId}
                  />
                </View>
              </View>
            </View>
          </ScrollView>
          <Appload enable={this.state.dataLoad} />
        </View>
      </SafeAreaView>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
  },
});

let keysArray = [
  'payulatam_account_id',
  'payulatam_api_login_key',
  'payulatam_api_key',
  'payulatam_merchant_id',
];

