import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Platform,
  Alert,
  TouchableOpacity,
  SafeAreaView,
  Dimensions,
  Image,
  ScrollView,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import RightView from './TenantCommonClasses/RightCustomView';
import commonStyle from '../StyleSheets/CreateTenantCommonStyleSheet';
import HeaderView from './TenantCommonClasses/LogoHeader';
import bToc_Icon from '../assets/bTocIcon.png';
import cToc_Icon from '../assets/cTocIcon.png';
import enterprise_Icon from '../assets/enterpriseIcon.png';
import BottomView from './TenantCommonClasses/FooterView';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import {getValue} from '../HelperClasses/UserPrefrences';
import AppConstants from '../Constants/AppConstants';

const APPURL = require('../Constants/URLConstants');

const windowHeight = Dimensions.get('window').height;
const contentFul = require('../Constants/ContentFullConstant');
let titleArray = ['C2C - P2P', 'B to C', 'Hybrid'];
let imagesArray = [cToc_Icon, bToc_Icon, enterprise_Icon];
let subTitleArray = ['(Many to Many)', '(One to Many)', '(Custom Solution)'];

export default class SelectPlatform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBusinessIndex: 0,
      dataLoad: true,
    };
  }
  componentDidMount() {
    // console.log('route.params', this.props.route.params);
  }
  //MARK:-  APIs Method 

  //MARK:-  Buttons Action Method 
  submitButtonAction() {}
  didSelectSetting = id => {
    // console.log('id', id);
    this.setState({selectedBusinessIndex: id});
  };
  nextBtnAction() {
    // this.setState({dataLoad: false});
    // this.addCurrencyApi();
    const {currencyData} = this.props.route.params;
    const {languageData} = this.props.route.params;

    this.props.navigation.navigate('Flavour');
    this.props.navigation.navigate('Flavour', {
      currencyData: currencyData,
      languageData: languageData,
      businessData:
        this.state.selectedBusinessIndex == 2
          ? -1
          : this.state.selectedBusinessIndex + 1,
    });
  }
  //MARK:-  UI Method 

  renderBusinessView = () => {
    var view = [];
    for (let a = 0; a < 3; a++) {
      view.push(
        <TouchableOpacity
          style={
            this.state.selectedBusinessIndex == a
              ? commonStyle.highlightCustomViewStyle
              : commonStyle.customViewStyle
          }
          onPress={id => this.didSelectSetting(a)}>
          <Image
            style={commonStyle.iconImageViewStyle}
            resizeMode="contain"
            source={imagesArray[a]}
          />
          <Text style={commonStyle.customViewTitleStyle}>{titleArray[a]}</Text>
          <Text style={commonStyle.customViewsubTitleStyle}>
            {subTitleArray[a]}
          </Text>
        </TouchableOpacity>,
      );
    }
    return view;
  };
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={styles.Container}>
          <View style={commonStyle.mainViewStyle}>
            <View style={commonStyle.leftViewStyle}>
              <HeaderView />
              <ScrollView style={{height: windowHeight - 100}}>
                <View style={commonStyle.contentViewStyle}>
                  <ScrollView showsVerticalScrollIndicator={false}>
                    <View>
                      <Text style={commonStyle.contentTitleStyle}>
                        Select your platform business model
                      </Text>
                      <View style={{marginTop: 30}} />
                      <ScrollView horizontal={true}>
                        <View style={commonStyle.customViewContainer}>
                          <this.renderBusinessView />
                        </View>
                      </ScrollView>
                    </View>
                  </ScrollView>
                  <View>
                    <BottomView
                      nextBtn={() => this.nextBtnAction()}
                      backBtn={() => this.props.navigation.pop()}
                      pageNumber={'3'}
                      progressValue={'100%'}
                      nextBtnTitle={'Next'}
                    />
                  </View>
                </View>
              </ScrollView>
            </View>
            <View style={commonStyle.rightViewStyle}>
              <RightView
                title={contentFul.businessModelTitle}
                description={contentFul.businessModelDescription}
              />
            </View>
          </View>
          <Appload enable={this.state.dataLoad} />;
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});
