import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TextInput,
  SafeAreaView,
  Dimensions
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../../Settings/HeaderView';
import { AppToast } from '../../HelperClasses/AppToast';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import Switch from "react-switch";
import AppConstants from '../../Constants/AppConstants';

const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth  ?  true : false

export default class AddVariantType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      showDropDown: false,
      activeStatus: true,
      name: '',
      dataLoad: true,
      id: '',
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    attributeData: PropTypes.any,
    isEdit: PropTypes.bool,
    type: PropTypes.string,
  };
  componentDidMount() {
    const variantTypeData = this.props.variantTypeData;
    const isEdit = this.props.isEdit;
    if (isEdit) {
      this.setState({
        name: variantTypeData['name'],
        activeStatus: variantTypeData['active'],
        id: variantTypeData['id'],
      });
    }
  } 
  addVariantApi = async () => {
    let fDict = {
      name: this.state.name,
      active: this.state.activeStatus,
    };
    var path = APPURL.URLPaths.addVariant;
    var method = 'post';
    if (this.state.id !== '') {
      path = APPURL.URLPaths.addVariant + '/' + this.state.id;
      method = 'put';
    }
    const responseJson = await networkService.networkCall( path,method, JSON.stringify({variant_type: fDict}));
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.props.backBtnAction(true);
      this.customAlert();
    }
  }
  customAlert() {
    AppToast();
  }
  //MARK:-  Button Actions
  submitBtnAction = () => {
    this.setState({ dataLoad: false });
    this.addVariantApi();
  };
  // UI Renders
  loaderView = () => {
    return <Appload enable={this.state.dataLoad} />;
  };
  renderSwitches = ({name, id}) => {
    return <View style={commonStyle.switchViewStyle}>
        <Text style={commonStyle.titleNameStyle}>{name}</Text>
        <View style={{ width:itsMobileView ?  '12%' : '80%' }}>
           <Switch
          onColor={colors.AppNewGreen}
          onChange={() => this.setState({ activeStatus: !this.state.activeStatus })}
          checked={this.state.activeStatus}
          height={22}
          width={50}
        />
        </View>
      </View>;
  };
  titleLblRender = ({ title }) => {
    return (
      <View>
        <Text style={commonStyle.titleNameStyle}>
          {title}
          <Text style={{ color: 'red', paddingTop: 5 }}> *</Text>
        </Text>
      </View>
    );
  };
  render() {
    const isEdit = this.props.isEdit;
    return (
      <SafeAreaView style={commonStyle.Container}>
        <Header_View
          title={isEdit ? 'Edit Variant Types' : 'Add Variant Types'}
          backBtn={this.props.backBtnAction}
          saveBtn={() => this.submitBtnAction()}
        />
        <ScrollView>
          <View style={commonStyle.mainView}>
            <View style={{ height: 20}} />
            <View style={commonStyle.subViewStyle}>
              <this.titleLblRender title={'Name'} />
              <TextInput
                style={[commonStyle.txtFieldStyle,{outline:0}]}
                value={this.state.name}
                placeholder="Enter name"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({ name: name })}
              />
            </View>
            <View style={{ height: 30 }} />
            <this.renderSwitches name={'Status(Not Active / Active) '} id={0}/>
            <View style={{ height: 40 }} />
          </View>
        </ScrollView>
        <this.loaderView />
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  selectCategoryViewStyle: {
    flexDirection: 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
    zIndex: 20,
  },
});
