import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Platform,
  Alert,
  TouchableOpacity,
  SafeAreaView,
  Dimensions,
  Image,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import RightView from './TenantCommonClasses/RightCustomView';
import commonStyle from '../StyleSheets/CreateTenantCommonStyleSheet';
import HeaderView from './TenantCommonClasses/LogoHeader';

import googleMap_Icon from '../assets/googleMapIcon.png';
import openStreetMap_Icon from '../assets/openStreetMapIcon.png';

import BottomView from './TenantCommonClasses/FooterView';
import Switch from "react-switch";

const windowheight = Dimensions.get('window').height;
const contentFul = require('../Constants/ContentFullConstant');

let titleArray = ['Google Maps', 'Openstreet map'];
let imagesArray = [googleMap_Icon, openStreetMap_Icon];
let KeyGroup = 'general';

export default class ChooseLocationMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMapType: 0,
      switchArray: [true, true, true],
    };
  }
  componentDidMount() {}
  //MARK:-  APIs Method 
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  submitButtonAction() {}
  didSelectSetting = id => {
    // console.log('id', id);
    this.setState({selectedMapType: id});
  };
  switchSattusBtnAction(id) {
    this.state.switchArray[id] = !this.state.switchArray[id];
    this.setState({updateUI: !this.state.updateUI});
  }
  nextBtnAction() {
    var configs = [];
    for (let i = 0; i < keysArray.length; i++) {
      let fDict2 = {
        key_group: KeyGroup,
        key: keysArray[i],
        value: this.state.switchArray[i],
        secured: false,
      };
      configs.push(fDict2);
    }
    const {languageData,currencyData, shippingMethodsArray,paymentMethodsArray} = this.props.route.params;
    this.props.navigation.navigate('RegistrationTypePlatform', {
      currencyData: currencyData,
      shippingMethodsArray:shippingMethodsArray,
      locationData:configs,
      languageData:languageData,
      paymentMethodsArray:paymentMethodsArray,
    });
  }
  //MARK:-  UI Method 
  renderDeliveryView = () => {
    var view = [];
    for (let a = 0; a < 2; a++) {
      view.push(
        <TouchableOpacity
          style={
            this.state.selectedMapType == a
              ? commonStyle.highlightCustomViewStyle
              : commonStyle.customViewStyle
          }
          onPress={id => this.didSelectSetting(a)}>
          <Image
            style={commonStyle.iconImageViewStyle}
            resizeMode="contain"
            source={imagesArray[a]}
          />
          <Text style={commonStyle.customViewTitleStyle}>{titleArray[a]}</Text>
        </TouchableOpacity>,
      );
    }
    return view;
  };
  renderSwitchView = () => {
    return (
      <View style={{marginTop: 20}}>
        <Text style={appConfigStyle.titleTxtStyle}>
          Choose Places available options
        </Text>
        <View style={{flexDirection: 'row', marginTop: 20}}>
          <View style={{flexDirection: 'row', marginTop: 20}}>
            <Switch
              onColor={colors.AppNewGreen}
              onChange={() => this.switchSattusBtnAction(0)}
              checked={this.state.switchArray[0]}
              height={22}
              width={50}
            />
            <Text style={appConfigStyle.statusTxtStyle}>{switchTitleStrings[0]}</Text>
          </View>
        </View>
      </View>
    );
  };
  renderAllSwitches = props => {
    var views = [];
    for (let i = 0; i < switchTitleStrings.length; i++) {
      views.push(
        <View style={{flexDirection: 'row', marginTop: 20}}>
           <Switch
              onColor={colors.AppNewGreen}
              onChange={() => this.switchSattusBtnAction(i)}
              checked={this.state.switchArray[i]}
              height={22}
              width={50}
            />
          <Text style={appConfigStyle.statusTxtStyle}>{switchTitleStrings[i]}</Text>
        </View>,
      );
    }
    return views;
  };

  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={styles.Container}>
          <View style={commonStyle.mainViewStyle}>
            <View style={commonStyle.leftViewStyle}>
              <HeaderView />
              <ScrollView style={{height: windowheight - 100}}>
              <View style={commonStyle.contentViewStyle}>
                <View style={{height: '76%'}}>
                  <ScrollView showsVerticalScrollIndicator={true}>
                    <View style={{margin: 5}}>
                      <Text style={commonStyle.contentTitleStyle}>
                        Choose Location & Map
                      </Text>
                      {/* <Text style={{color: colors.Appgray, marginTop: 10}}>
                        Lorem ipsum dolor sit amet, consectetur{'\n'}
                        adipiscing elit aenean massa.
                      </Text> */}
                      <View style={{marginTop: 30}} />
                      <View style={commonStyle.customViewContainer}>
                        <this.renderDeliveryView />
                      </View>
                      <View style={{marginTop: 20}}>
                        <Text style={appConfigStyle.titleTxtStyle}>
                          Choose Places available options
                        </Text>
                        <this.renderAllSwitches />
                      </View>
                    </View>
                  </ScrollView>
                </View>
                <View style={{marginTop: 0}}>
                  <BottomView
                    nextBtn={() => this.nextBtnAction()}
                    backBtn={() => this.props.navigation.pop()}
                    pageNumber={'7'}
                    progressValue={'70%'}
                  />
                </View>
              </View>
              </ScrollView>
            </View>
            <View style={commonStyle.rightViewStyle}>
              <RightView title={contentFul.mapTitle} description={contentFul.mapDescription}/>
            </View>
          </View>
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

const switchTitleStrings = [
  'Activate nearby accounts in home page',
  'Activate location in listing submission',
  'Activate location in account submission ',
];
let keysArray = [
  'near_by_stores_collection_enabled',
  'listing_map_location_selector_enabled',
  'account_map_location_selector_enabled',
];