import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  ScrollView,
} from 'react-native';
import PropTypes from 'prop-types';
import closeIcon from '../assets/closeWhiteIcon.png' //'../../assets/menuIcon.png';
import colors from '../HelperClasses/AppColor';
import jsonIcon from '../assets/jsonIcon.png';
import pdfIcon from '../assets/pdfICon.png';
import AppConstants from '../Constants/AppConstants';

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

export default class MediaManagerView extends Component {

  static propTypes = {
    closeBtn: PropTypes.func,
    onSelectBtn: PropTypes.func,
    mediaData:PropTypes.any,
  }
  constructor(props) {
    super(props);
    this.state = {
      hoverV:'',
    }
  }
  onMouseLeave = () => {
    this.setState({hoverV:''})
  }
  onMouseEnter= (item) =>{
    this.setState({hoverV:item})
  }
  renderMediaView = () => {
    let mediaArray = this.props.mediaData
    if (mediaArray != undefined) {
      var view = [];
      for (let a = 0; a <= mediaArray.length - 1; a++) {
        let media = mediaArray[a];
        let uri = media['url'];
        let name = uri.substring(uri.lastIndexOf('/') + 1)
        var fileExt = name.split('.').pop();
        var showImage = true;
        var icon = jsonIcon
        if (fileExt == 'json') {
          icon = jsonIcon
          showImage = false
        } else if (fileExt == 'pdf') {
          icon = pdfIcon
          showImage = false
        } 
        let check = this.state.hoverV == name
        view.push(
          <View onMouseLeave={() => this.onMouseLeave(name)} onMouseEnter={() => this.onMouseEnter(name)} >
            <TouchableOpacity style={[styles.elementViewsStyle, { shadowOpacity: check ? 0.5 : 0.1 }]} onPress={() => this.props.onSelectBtn(uri)}>
              <Image style={styles.imageViewStyle} resizeMode={'center'} source={showImage ? { uri: uri } : icon} />
            </TouchableOpacity>
          </View>,
        );
      }
    }
    return view;
  }

  render() {
    return (
      <View style={styles.ViewStyle}>
        <View style={{alignItems: 'flex-end'}}>
          <TouchableOpacity onPress={this.props.closeBtn}>
            <Image source={closeIcon} style={{ width: 30, height: 30 }} />
          </TouchableOpacity>
        </View>
        <View style={{flex:1, height: '100%', backgroundColor:'white', marginTop:10, borderRadius: 5}}>
          <ScrollView>
            <View style={styles.containerView}>
              {this.renderMediaView()}
            </View>
          </ScrollView>
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  ViewStyle: {
    height: '95%',
    width: '99%',
    margin: 5, backgroundColor: colors.transparentBlackColor,
    padding: 20,
    borderRadius: 5,
  },
  imageViewStyle: {
    height: 120,
    width: 120,
    borderRadius: 10,
  },
  containerView: {
    flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: windowWidth < fixedWidth ? windowWidth - 50 : windowWidth < fixedWidth ,
    height: '100%'
  },
  elementViewsStyle: {
    margin: 20,
    padding:5,
    backgroundColor: colors.AppWhite,
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 0,
    width: 135,
    borderColor:colors.Lightgray,
    borderWidth: 1,
    shadowOpacity: 0.1,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 10,
  },
});
