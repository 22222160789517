import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  Image,
  Dimensions,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import cIcon from '../../assets/circularIcon.png';
import PropTypes from 'prop-types';


export default class RightCustomView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
  };
  componentDidMount() {}
  renderSubTitle = () => {
    var subView = [];
    for (let a = 0; a < 5; a++) {
      subView.push(
        <View style={styles.bulletTextViewStyle}>
          <Text style={styles.bulletTextStyle}>{'•   '}</Text>
          <Text style={styles.subTextStyle}>Lorem ipsum dolor sit amet</Text>
        </View>,
      );
    }
    return subView;
  };
  render() {
    return (
      <View style={styles.rightViewStyle}>
        <View style={styles.innerContainerStyle}>
          <Text style={styles.titleTextStyle}>{this.props.title}</Text>
          {/* <this.renderSubTitle /> */}
          <View style={{flexDirection: 'row', marginTop: 16}}>
            <Text style={styles.subTextStyle}>
              {this.props.description}
            </Text>
          </View>
        </View>
        <Image resizeMode={'cover'} style={styles.logoStyle} source={cIcon} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  rightViewStyle: {
    flexDirection: 'column',
    backgroundColor: colors.AppCGreen,
    justifyContent: 'space-between',
    height: '100%',
    margin: 20,
    borderRadius: 10,
  },
  innerContainerStyle: {
    padding: 20,
    paddingTop: 20,
    alignItems: 'center',
  },
  // titleTextStyle: {
  //   fontSize: 18,
  //   fontWeight: '700',
  //   color: '#01322F',
  //   alignSelf: 'center',
  // },
  // bulletTextViewStyle: {
  //   flexDirection: 'row',
  //   marginTop: 16,
  //   alignSelf: 'flex-start',
  // },
  // bulletTextStyle: {
  //   fontSize: 18,
  //   fontWeight: 'bold',
  //   color: '#99A09F',
  //   alignSelf: 'center',
  // },
  // subTextStyle: {
  //   fontSize: 14,
  //   color: colors.Appgray,
  //   alignSelf: 'center',
  // },
  logoStyle: {
    width: '70%',
    height: '40%',
    marginTop: -70
  },
});
