import React, {Component} from 'react';
import {View, Text, StyleSheet, Dimensions} from 'react-native';
import AppConstants from '../Constants/AppConstants';
import SideBar from '../MainRoots/SideBar';

const windowWidth = Dimensions.get('window').width;
const windowheight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;

export default class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateValue: false,
      selectIndex: 0,
      showMenu: false,
      dataLoad: true,
      showQuickStart: false,
      changeEnv: true,
      reloadUI: false,
      reloadIndex: 0,
    };
  }

  render() {
    return (
      <View style={styles.sidebarViewStyle}>
        <SideBar
          selectIndex={this.state.selectIndex}
          changeEnvBtn={this.state.changeEnv}
          didSelectIndex={id => this.didSelectSideBarCell(id)}
        />
      </View>
    );
  }
}

const commonWidth = 50;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'row',
  },
  headerView: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    // height: 70,
    alignItems: 'center',
    width: '100%',
    zIndex: 10000,
  },
  headerSubView: {
    margin: 10,
    alignItems: 'center',
  },
  sidebarViewStyle: {
    width: commonWidth,
    height: windowheight,
    zIndex: 999,
  },
  sideMenuViewStyle: {
    width: commonWidth,
    height: windowheight,
    zIndex: 999,
    position: 'absolute',
    marginTop: 50,
  },
  mainViewStyle: {
    // flex: 1,
    zIndex: 1,
    height: windowheight - 70,
    width: windowWidth > fixedWidth ? windowWidth - 385 : windowWidth,
    backgroundColor: 'pink',
  },
  logoStyle: {
    width: 30,
    height: 30,
    marginTop: 6,
  },
  menuBtnStyle: {
    width: 30,
    height: 30,
    // margin: 10,
    // marginLeft: 10,
  },
});
