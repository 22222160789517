import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  TextInput,
  Image,
  FlatList,
  SafeAreaView,
  Dimensions,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import ImagePicker from 'react-native-image-crop-picker';
import ReactFileReader from 'react-file-reader';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../Settings/HeaderView';
import {AppToast} from '../HelperClasses/AppToast';
import commonStyle from '../StyleSheets/CommonStyleSheet';
import downArrow_Icon from '../assets/downArrowIcon.png';
import MediaManagerView from '../Component/MediaManagerView';
import DescriptionView from '../Component/DescriptionView';
import SelectSearchView from 'react-select';
import Switch from "react-switch";
import { addMixpanelEvent } from '../Models/MixPannelEnum';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import AppConfigStyleSheet from '../StyleSheets/AppConfigStyleSheet';
import AppConstants from '../Constants/AppConstants';


const APPURL = require('../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth  ?  true : false
var pageNo = 1;


export default class AddVariants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      showDropDown: [false, false],
      selectedImage: null,
      status: true,
      name: '',
      listPice: '',
      description: '',
      offerPercent: '',
      stock: '',
      dataLoad: true,
      id: '',
      variantTypeArray:[],
      selectedVariantType:{},
      selectedVariantTypeValues:{},
      variantValuesArray:[],
      showMediaManager:false,
      mediaArray: [],
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    isEdit: PropTypes.bool,
    listingId: PropTypes.string,
    vairantData: PropTypes.any,
  };
  componentDidMount() {
    this.loadMediaAPi();
    this.loadVariantsAPI();
    if (this.props.isEdit) {
      const vairantData = this.props.vairantData;
      this.state.name = vairantData['title'] || '';
      this.state.description = vairantData['description'] || '';
      this.state.stock = vairantData['stock'] || '';
      this.state.listPice = vairantData['list_price']['amount'] || '';
      this.state.offerPercent = vairantData['offer_percent'] || '';
      this.state.status = vairantData['active'] || false;
      this.state.selectedImage = vairantData['images'][0] || null;
    }
  }
  loadMediaAPi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.media + `?page=${pageNo}&parent=0`,'get', '', '', );
    this.setState({mediaArray: []});
    if (responseJson['status'] == true) {
      var mediaD = responseJson['data']['media'];
      if (mediaD.length != 0) {
        this.setState({mediaArray: []});
        this.setState({stopPage: false});
        for (let obc of mediaD) {
          this.state.mediaArray.push(obc);
        }
      } else {
        
        pageNo = pageNo > 1 ? pageNo - 1 : 1;
        this.setState({stopPage: true});
      }
    }
    this.setState({dataLoad: true})
  };
  loadVariantsAPI = async () => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(APPURL.URLPaths.addVariant,'get','', '');
    if (responseJson['status'] == true) {
      let vData = responseJson['data']['variant_types'];
      for (let objc of vData){
        let catData = {
          value: objc['id'],
          id: objc['id'],
          label: objc['name'],
          name:objc['name'],
          values:objc['values']
        }
        this.state.variantTypeArray.push(catData)
      }
      // this.state.variantTypeArray = vData
      if (this.props.isEdit) {
        const vairantData = this.props.vairantData;
        if (vairantData['variant_values'][0]) {
          let vType = vairantData['variant_values'][0];
          for (let obj of vData) {
            if (vType['variant_type_id'] == obj['id']){
              let catData = {
                value: obj['id'],
                id: obj['id'],
                label: obj['name'],
                name:obj['name'],
              };
              this.state.selectedVariantType = catData;
              for (let vobjc of obj['values']) {
                if (vType['variant_type_value_id'] == vobjc['id']){
                  let vData = {
                    value: vobjc['id'],
                    id: vobjc['id'],
                    label: vobjc['name'],
                    name:vobjc['name'],
                  };
                  this.state.selectedVariantTypeValues = vData;
                }
              }
            }
          }
        }
      }
      this.setState({updateUI: !this.state.updateUI});
    }
    this.setState({dataLoad: true});
  };
  addVariantApi = async image => {
    var dict = {active: this.state.status};
    if (this.state.name.length != 0) {
      dict['title'] = this.state.name;
    } if (this.state.description.length != 0) {
      dict['description'] = this.state.description;
    } if (this.state.stock.length != 0) {
      dict['stock'] = this.state.stock;
    } if (this.state.listPice.length != 0) {
      dict['list_price'] = this.state.listPice;
    } if (this.state.offerPercent.length != 0) {
      dict['offer_percent'] = this.state.offerPercent;
    } 
    if (image.length != 0) {
      dict['images'] = [image];
    }
    if (this.state.selectedVariantType['id']) {
      if (this.state.selectedVariantTypeValues['id']) {
        dict['variant_values'] = [{
          variant_type_id:this.state.selectedVariantType['id'],
          variant_type_value_id:this.state.selectedVariantTypeValues['id'],
        }]
      }
    }
    var path = APPURL.URLPaths.listingsModerate + '/' + this.props.listingId + '/variants';
    if (this.props.isEdit) {
      path = path + `/${this.props.vairantData['id']}`;
    }
    const responseJson = await networkService.networkCall(path,this.props.isEdit ? 'put' : 'post', JSON.stringify({variant: dict}),'');
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      let properties = { success:true }
      addMixpanelEvent(this.props.isEdit ? 'Edit Variants' : 'Add Variants',properties )
      this.props.backBtnAction(true);
      this.customAlert();
    } else {
    }
  };
  uploadImageAPI = async () => {
    var imgParm = [];
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      if (fileName != null) {
        var dict = {
          name: this.state.selectedImage['fileList'][0]['name'].replace(/ /g,''),
          type: this.state.selectedImage['fileList'][0]['type'],
        };
        imgParm.push(dict);
      }
    }
    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL,'POST',
        JSON.stringify({files: imgParm}),
        'this.state.bToken',
      );
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        fetch(this.state.selectedImage.base64).then(async res => {
          const file_upload_res = await networkService.uploadFileWithSignedURL(
            result[0]['signedUrl'],
            imgParm[0]['type'],
            await res.blob(),
          );
          this.addVariantApi(result[0]['fileUri']);
        });
      } else {
        this.setState({dataLoad: true});
      }
    } else {
      this.addVariantApi(
        this.state.selectedImage != null ? this.state.selectedImage : '',
      );
    }
  };
  customAlert() {
    AppToast();
  }
  //MARK:-  Button Actions
  submitBtnAction = () => {
    this.setState({dataLoad: false});
    this.uploadImageAPI();
  };
  statusToggle = () => {
    this.setState({status: !this.state.status});
  };
  defaultToggle = () => {
    this.setState({default: !this.state.default});
  };
  onPick = image => {
    // console.log('web image -=== ', image);
    this.setState({selectedImage: image});
  };
  openDropDown = id => {
    this.state.showDropDown[id] = !this.state.showDropDown[id];
    this.setState({updateUI: !this.state.updateUI});
  };
  didSelectDropDown = item => {
    this.state.showDropDown = [false,false];
    this.state.selectedVariantTypeValues = {};
    this.state.selectedVariantType = item;
    this.state.variantValuesArray = item['values'];
    this.setState({updateUI: !this.state.updateUI});
  };
  didSelectValuesDropDown = item => {
    this.state.showDropDown = [false,false];
    this.state.selectedVariantTypeValues = item;
    this.setState({updateUI: !this.state.updateUI});
  };
  showMediaBtnAction() {
    if (this.state.mediaArray != 0) {
      this.setState({ showMediaManager: true })
    } else {
      AppToast('You dont have media file.');
    }
  }
  handleSelectChange = (selectedOption, index) => {
    console.log(index,'selectedOption', selectedOption);
    if (index['id'] == 0) {
      this.state.selectedVariantTypeValues = {};
      this.state.selectedVariantType = selectedOption;
      this.state.variantValuesArray = [];
      let obj =  selectedOption['values'];
      for (let objc of obj) {
        console.log('objc', objc);
        let catData = {
          value: objc['id'],
          id: objc['id'],
          label: objc['name'],
          name: objc['name'],
        }
        this.state.variantValuesArray.push(catData)
      }

    } else {
      this.state.selectedVariantTypeValues = selectedOption;
      this.setState({updateUI: !this.state.updateUI});
    }
    // this.state.variantValuesArray = item['values'];

    this.setState({ updateUI: !this.state.updateUI });
  }
  // UI Renders
  imagePicker = props => {
    ImagePicker.openPicker({
      width: 300,
      height: 300,
      cropping: true,
      includeBase64: true,
    }).then(image => {
      this.setState({selectedImage: 'data:image/png;base64,' + image.data});
    });
  };
  imageView = props => {
    var view = [];
    view.push(<View style={{ flexDirection: itsMobileView ? 'column' : 'row' }}>
      <View style={commonStyle.addImageViewStyle}>
        <ReactFileReader
          fileTypes={['.png', '.jpg', '.gif']}
          base64={true}
          handleFiles={this.onPick}>
          <Text style={AppConfigStyleSheet.chooseFileTxtStyle}>Add Photo</Text>
        </ReactFileReader>
      </View>
      <View style={{ width: 20, height: 20 }} />
      <View style={commonStyle.addImageViewStyle}>
        <TouchableOpacity style={{ width: 100 }} onPress={() => this.showMediaBtnAction()}>
          <Text style={AppConfigStyleSheet.chooseFileTxtStyle}>Select from media</Text>
        </TouchableOpacity>
      </View>
      <View style={{ width: 20, height: 20 }} />
    </View>);
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      view.push(<View>
        <Image
          source={{ uri: fileName != null ? this.state.selectedImage.base64 : this.state.selectedImage }}
          style={commonStyle.ImageViewStyle}
        />
      </View>
      );
    }
    return (<View style={{ flexDirection: itsMobileView ? 'column' : 'row' }}>
        {view}
    </View>)
  };
  customDropDown = id => {
    let index = id['id'];
    if (this.state.showDropDown[index] == true) {
      let dAray = index == 0 ? this.state.variantTypeArray : this.state.variantValuesArray;
      return (
        <View style={commonStyle.showDropDownViewStyle}>
          <FlatList
            data={dAray}
            horizontal={false}
            renderItem={index == 0 ?this.renderDropDown : this.renderValuesDropDown} 
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity onPress={() => this.didSelectDropDown(item, index)}
        style={{margin: 5, height: 30, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text style={CommonStyleSheet.dropdownCellItemTextStyle}>
            {item['name']}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderValuesDropDown = ({item, index}) => {
    return (
      <TouchableOpacity onPress={() => this.didSelectValuesDropDown(item, index)}
        style={{margin: 5, height: 30, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text style={CommonStyleSheet.dropdownCellItemTextStyle}>
            {item['name']}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderValueLabel = id => {
    let index = id['id'];
    var values = [];
    var ssArry = []
    let placeholder = index == 0 ? 'Select Variant Type' : 'Select Variant Type Values'
    if (index == 0) {
      ssArry = this.state.variantTypeArray;
      if (this.state.selectedVariantType['id']) {
        values = this.state.selectedVariantType
      }
    } else {
      if (this.state.selectedVariantTypeValues['id']) {
        values = this.state.selectedVariantTypeValues
      }
      ssArry = this.state.variantValuesArray;
    }
      return (
        <View style={{ width: '100%' }}>
          <SelectSearchView
            onChange={itm => this.handleSelectChange(itm, id)}
            options={ssArry}
            value={values}
            isMulti={false}
            placeholder={placeholder}
            styles={colourStyles}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: colors.AppGreenBorder,
                primary: colors.AppGreenBorder,
              },
            })}
          />
        </View>
      );
    
  };
  titleLblRender = ({title}) => {
    return (
      <View>
        <Text style={commonStyle.titleNameStyle}>
          {title}
          <Text style={{color: 'red', paddingTop: 5}}> *</Text>
        </Text>
      </View>
    );
  };
  loaderView = () => {
    return <Appload enable={this.state.dataLoad} />;
  }
  mediaManagerView = () => {
    if (this.state.showMediaManager) {
      return (<View style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 9920 }}>
        <MediaManagerView
          mediaData={this.state.mediaArray}
          closeBtn={() => this.setState({ showMediaManager: false })}
          onSelectBtn={path => this.setState({ showMediaManager: false, selectedImage: path })} />
      </View>)
    } else {
      return <View />
    }
  }
  onChangeDescription(txt) {
    this.setState({ description: txt })
  }
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <Header_View
          title={this.props.isEdit ? 'Edit Variant' : 'Add Variant'}
          backBtn={this.props.backBtnAction}
          saveBtn={() => this.submitBtnAction()}
        />
        <ScrollView>
          <View style={commonStyle.mainView}>
          {this.mediaManagerView()}
            <View style={commonStyle.listItemView}>
              <View style={{height: 20}} />
              <View style={{zIndex: 999}}>
              <View style={commonStyle.firstSubViewStyle}>
                <this.titleLblRender title={'Variant Type'} />
                <TouchableOpacity style={commonStyle.dropDownViewStyle}>
                  <this.renderValueLabel id={0} />
                  {/* <this.customDropDown id={0} /> */}
                </TouchableOpacity>
              </View>
              </View>
              <View style={{zIndex: 2}}>
              <View style={commonStyle.firstSubViewStyle}>
                <this.titleLblRender title={'Variant Type Value'} />
                <TouchableOpacity style={commonStyle.dropDownViewStyle}>
                  <this.renderValueLabel id={1} />
                  {/* <this.customDropDown id={1} /> */}
                </TouchableOpacity>
              </View>
              </View>
              <View style={{height: 20}} />
              <View style={commonStyle.subViewStyle}>
                <this.titleLblRender title={'Variant Name'} />
                <TextInput
                  style={[commonStyle.txtFieldStyle,{outline:0}]}
                  value={this.state.name}
                  placeholder="Enter name"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({name: name})}
                />
              </View>
              <View style={{height: 20}} />
              <View style={commonStyle.subViewStyle}>
                <Text style={commonStyle.titleNameStyle}>Description</Text>
                <View style={{width: itsMobileView  ? '100%' : '80%',}}>
                  <DescriptionView value={this.state.description} onChange={txt => this.onChangeDescription(txt)} />
                </View>
                {/* <TextInput
                  style={styles.txtViewStyle}
                  value={this.state.description}
                  placeholder="Enter description"
                  placeholderTextColor={colors.placeholderColor}
                  multiline={true}
                  onChangeText={description => this.setState({description: description})}
                /> */}
              </View>
              <View style={{height: 20}} />
              <View style={commonStyle.subViewStyle}>
                <this.titleLblRender title={'List Price'} />
                <TextInput
                  style={[commonStyle.txtFieldStyle,{outline:0}]}
                  value={this.state.listPice}
                  placeholder="Enter list price"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({listPice: name})}
                />
              </View>
              <View style={{height: 20}} />
              <View style={commonStyle.subViewStyle}>
                <Text style={commonStyle.titleNameStyle}>Offer Percent</Text>
                <TextInput
                  style={[commonStyle.txtFieldStyle,{outline:0}]}
                  value={this.state.offerPercent}
                  placeholder="Enter Offer Percent"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({offerPercent: name})}
                />
              </View>
              <View style={{height: 20}} />
              <View style={commonStyle.subViewStyle}>
                <Text style={commonStyle.titleNameStyle}>Stock</Text>
                <TextInput
                  style={[commonStyle.txtFieldStyle,{outline:0}]}
                  value={this.state.stock}
                  placeholder="Enter stock"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({stock: name})}
                />
              </View>
              <View style={{height: 20}} />
              <View style={commonStyle.subViewStyle}>
                <Text style={commonStyle.titleNameStyle}>Status(Not Active / Active) </Text>
                <View style={{ width: itsMobileView ? '12%' : '80%', flexDirection: 'row' }}>
                  <Switch
                    onColor={colors.AppNewGreen}
                    onChange={() => this.setState({ status: !this.state.status })}
                    checked={this.state.status}
                    height={22}
                    width={50}
                  />
                </View>
              </View>
              <View style={{height: 20}} />
              <View style={commonStyle.subViewStyle}>
                <Text style={commonStyle.titleNameStyle}>Upload Media</Text>
                <View style={{width: '80%'}}>
                    <this.imageView />
                </View>
              </View>
            </View>
            <View style={{height: 20, width: '100%'}} />
          </View>
        </ScrollView>
        <this.loaderView />
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
});

const colourStyles = {
  control: styles => ({...styles, backgroundColor: 'white', borderRadius: 5}),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? colors.SimonGray
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? colors.SimonGray
          ? colors.AppNewGreen
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : colors.Lightgray),
      },
    };
  },
  multiValueLabel: (styles, {data}) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, {data}) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: colors.AppRed,
    },
  }),
};
