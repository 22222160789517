import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import colors from '../HelperClasses/AppColor';
import toolTip from './../assets/toolTip.png';

import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import {Gradient} from 'react-gradient';
import HelperVideoView from '../Component/HelperVideoView';
import TableCommonStyleSheet from '../StyleSheets/TableCommonStyleSheet';
import BackBtnView from '../Component/BackBtnView';
import SaveButton from '../Component/SaveButton';


export default class HeaderView extends Component {
  static propTypes = {
    title: PropTypes.string,
    backBtn: PropTypes.func,
    showBackBtn: PropTypes.bool,
    saveBtn: PropTypes.func,
    toolTipBtn: PropTypes.func,
    saveBtnTitle: PropTypes.string,
    showSaveBtn: PropTypes.bool,
    showToolTipBtn: PropTypes.bool,
    toolTipBtn: PropTypes.func,
    showHelperVideoBtn: PropTypes.bool,
    helperVideoBtnAction: PropTypes.func,
    btnDeActive: PropTypes.bool,
    deActiveBtnAction:PropTypes.func,

  }
  
  showSaveBtnRender = () => {
    return <SaveButton 
      saveBtn={this.props.saveBtn}
      showSaveBtn={this.props.showSaveBtn}
      deActiveBtnAction={this.props.deActiveBtnAction}
      btnDeActive={this.props.btnDeActive}
      saveBtnTitle={this.props.saveBtnTitle} />
  }
  renderToolTip = () => {
   return <TouchableOpacity style={{ display: this.props.showToolTipBtn ? 'flex' : 'none',width:20,height:20, marginRight:10}}
      onPress={this.props.toolTipBtn}>
      <Image style={{ height: 20, width: 20,marginTop:2 }} resizeMode="center" source={toolTip} />
    </TouchableOpacity>
  }
  renderHelperVideoBtn = () => {
    return <View style={{display:this.props.showHelperVideoBtn ? 'flex' : 'none'}}>
       <HelperVideoView helperVideoBtnAction={() => this.props.helperVideoBtnAction()}/>
    </View>
   }
  render() {

    return (
      <View style={styles.headerView}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <TouchableOpacity style={[styles.backBtnBackViewStyle,{display:this.props.showBackBtn ? 'none' : 'flex'}]}
            onPress={this.props.backBtn}>
              <BackBtnView />
          </TouchableOpacity>
          <Text style={TableCommonStyleSheet.listTitleText}>{this.props.title}</Text>
          {this.renderToolTip()}
          {this.renderHelperVideoBtn()}
        </View>
        <this.showSaveBtnRender />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  headerView: {
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: 'white',
    flexDirection:  'row',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height:  60,
    alignItems: 'center',
    justifyContent:  'space-between',
    paddingLeft:  0,
  },
  backBtnBackViewStyle: {
    height: 40,
    width: 40,
    marginLeft: 20,
    backgroundColor: colors.UltraGreen,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
  },
  backBtnStyle: {
    height: 15,
    width: 15,
  },
  saveBtnStyle: {
    backgroundColor: colors.AppNewGreen,
    height: 40,
    width: 140,
    borderRadius: 5,
    marginRight: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
