export default {
  Appgreen: 'rgba(19, 181, 140, 1)',
  LightGreen: 'rgba(223, 251, 244, 1)',
  Lightgray: 'rgba(220, 220, 220, 1)',
  Appgray: '#4D5858',
  UltraGray: 'rgba(247, 247, 247, 1)',
  SimonGray: '#E6E7E7',
  AppRed: 'rgba(250, 92, 92, 1)',
  AppLightGreen: '#FAFBFB',
  UltraGreen:'#F2F6F6',
  AppNewGreen: '#01544E',
  AppCGreen: '#EEFEFC',
  UltraLightGreen: '#DDF3F2',
  SwitchColor: '#CCDDDC',
  AppYellow: '#FFCA1E',
  TransparentColor: 'rgba(76, 175, 80, 0.0)',
  BackgroundBlueColor: 'rgba(246, 249, 255, 1)',
  BGBlueColor:'#33a9db',
  AppGreenBorder: '#CCCFCF',
  AppButtonRed: '#FFC9C9',
  UltraLightRed: '#FCEFEF',
  AppButtonGreen: '#AEEAD1',
  orangeColor: '#FFA500',
  AppLightOrange: '#fce6bd',
  blueColor:'#054df5',
  GradientColors: [
    ['#01544E', '#077B73'],
    ['#01544E', '#077B73'],
  ],
  GradientRedColors: [
    ['#A52A2A', '#A52A2A'],
    ['#A52A2A', '#A52A2A'],
  ],
  GradientGrayColors: [
    ['#565656', '#565656'],
    ['#565656', '#565656'],
  ],
  GradientWhiteColors: [
    ['#000000', '#000000'],
    ['#000000', '#000000'],
  ],
  blackColor: 'black',
  transparentBlackColor: 'rgba(0, 0, 0, 0.5)',
  darkRed:'#A52A2A',
  lightRed: '#fecdd3',
  CGreen: '#a7f3d0',
  AppWhite:'white',
  AppTitleBlack:'#1A2928',
  lightYellow: 'rgba(255,199,0,0.15)',
  lightOrange:'#ef8162',
  cyanColor:'#E8FFFA',
  toolTipBGColor:'#31cd87',
  previewColor: '#364935',
  PaidBGColor:'#B4F0E5',
  PaidOutlineColor:'#0FB5A9',
  placeholderColor:'#99A09F',
  LightDarkGray:'#bdbbbb',
  darkGray:'#565656'

};
