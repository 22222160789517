import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  TouchableOpacity,
  Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import colors from '../HelperClasses/AppColor';
import { p2, t1 } from '../StyleSheets/TextTitleStyleSheet';

const windowWidth = Dimensions.get('window').width;
let minHeight = 250
export default class DescriptionView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descriptionHeight:minHeight,
      usedLines:1,
      txtValue:'',
    }
  }
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
  };

  _onLayout(e) {
    this.state.descriptionHeight = e.nativeEvent.contentSize.height;
    if (this.state.txtValue.length == 0) {
      this.state.descriptionHeight = minHeight;
    }
    // console.log(this.state.descriptionHeight,'descriptionHeight');
  }
  onChangeDescription(txt){
    if (txt.length == 0) {
      this.state.descriptionHeight = minHeight;
    }
    this.state.txtValue = txt;
    this.props.onChange(txt)
    // this.setState({ descriptionString: txt })
  }
  renderDescriptionView = () => {
    this.state.txtValue = this.props.value;
    return (<View>
      <TextInput
        style={{
          textAlign: 'left',
          padding: 10,
          marginTop: 10,
          borderWidth: 1,
          borderColor: colors.AppGreenBorder,
          borderRadius: 5,
          height:this.state.descriptionHeight,
          outline:0,
          ...t1,
          padding: 10,
        }}
        placeholder="Enter description"
        placeholderTextColor={colors.placeholderColor}
        onChangeText={txt => this.onChangeDescription(txt)}
        value={this.state.txtValue}
        multiline={true}
        editable={true}
        onContentSizeChange={(e)=> this._onLayout(e)}
      />
    </View>)
  }
  render() {
    return (<View>
      {this.renderDescriptionView()}
    </View>)
  }
}