import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import colors from '../HelperClasses/AppColor';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';

export default class SegmentBtnView extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  static propTypes = {
    tapAction: PropTypes.func,
    icon: PropTypes.any,
    isSelected: PropTypes.bool,
    title: PropTypes.string,

  };
  renderTapBtn = () => {
    return <View>
      <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
        onPress={() => this.props.tapAction()}>
        <Image resizeMode={'cover'} style={{height:16,width:16}} source={this.props.icon} />
        <Text style={[CommonStyleSheet.tabBtnViewStyle,{color: this.props.isSelected ? colors.AppNewGreen : colors.Appgray }]}>
        {this.props.title}</Text>
      </TouchableOpacity>
      <View style={{ backgroundColor:  this.props.isSelected  ? colors.AppNewGreen : colors.TransparentColor, height: 2, width: '100%', marginTop: 5 }} />
    </View>
  }
  render() {
    return <View>
      {this.renderTapBtn()}
    </View>
  }
}

const styles = StyleSheet.create({
  containerStyle: {
    borderRadius: 5,
    marginTop: 4,
    width: 150,
    padding: 5,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.lightYellow,
    flexDirection: 'row',
    borderColor: colors.AppYellow,
    borderWidth:1
  }
})
