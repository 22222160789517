export default class DeliveryStatusEnum {

  static status(status) {
    switch (status) {
      case 1: return 'On Hold'
      case 2: return 'Ready for pickup'
      case 3: return 'On the way to pickup'
      case 4: return 'Picked up'
      case 5: return 'Out for delivery'
      case 6: return 'Arrived at customer location'
      case 7: return 'Customer unreachable'
      case 8: return 'Delivered'
      case 9: return 'Canceled by account'
      case 10: return 'Canceled by customer'
      case 11: return 'Rescheduled'
      default: return 'Loading....'
    }
  }
  static paymentType(type) {
    switch (type) {
      case 1: return 'Prepaid'
      case 2: return 'Cash On Delivery'
    }
  }
  static deliveryType(type) {
    switch (type) {
      case 1: return 'On Demand'
      case 2: return 'Scheduled'
      case 3: return 'Click & Collect'
    }
  }
  static rosterType(type) {
    switch (type) {
      case 0: return 'Unassigned'
      case 1: return 'Assigned'
      case 2: return 'Started'
      case 3: return 'Completed'
    }
  }
  static dutyStatusType(type) {
    switch (type) {
      case 0: return 'Offline'
      case 1: return 'Online'
      case 2: return 'Busy'
    }
  }
  static depositeStatus(type) {
    switch (type) {
      case 1: return 'Collected from customer'
      case 2: return 'Deposited'
    }
  }
}
