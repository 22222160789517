export var ROLEMODULE = {
  READ :'read',
  WRITE :'write',
  DELETE :'delete'
};

export var MODULEKEYS = {
  LISTIINGS : 'listings',
  LISTIINGSCATEGORY : 'listings.category',
  LISTIINGSATTRIBUTE : 'listings.attributes',
  LISTIINGSMODERATION : 'listings.moderation',
  LISTIINGSCONFIGURATION : 'listings.configuration',
  LISTIINGSVARIANTTYPES : 'listings.variant_types',

  COLLECTIONS:'collections',
  PROMOS: 'promos',
  ORDER: 'orders',
  TRANSACTIONS: 'transactions',
  USERS: 'users',
  REPORTS: 'reports',
  MEDIA: 'media',
  REVIEWS: 'reviews',
  
  ACCOUNTS: 'accounts',
  ACCOUNTSCATEGORY : 'accounts.category',
  ACCOUNTSATTRIBUTE : 'accounts.attributes',
  ACCOUNTSMODERATION : 'accounts.moderation',
  ACCOUNTSCONFIGURATION : 'accounts.configuration',

  PAYMENTMETHOD:'payment_methods',
  SHIPPINGMETHOD:'shipping_methods',
  COMMISSIONS:'commissions',

  CURRENCY:'currency.manage',
  LANGUAGE:'language.manage',
  SUBSCRIPTION:'subscription.manage',

  SPECIALFEES:'specialfees.manage',
  NOTIFICATIONS:'notifications.manage',
  NATIVEINTEGRATIONS:'nativeintegrations.manage',

  TRANSLATIONMANAGE:'app_translation.manage',
  SEO:'seo.manage',

  APIVIEW:'api.view',
  APPCONFIG:'appconfig.manage',
  WEBCONFIG:'webconfig.manage',

  CHECKOUT:'checkout.manage',
  GLOBAL:'global.manage',

  TAXVIEW:'tax.view',
  COUNTRY:'country.manage',
  
  COUPONS: 'coupons',

}

