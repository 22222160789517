import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  TextInput,
  Image,
  FlatList,
  SafeAreaView,
  Dimensions,
  Platform,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../../Settings/HeaderView';
import { AppToast } from '../../HelperClasses/AppToast';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import edit_Icon from '../../assets/editIcon.png';
import deleteIcon from '../../assets/delete_icon.png';
import {Gradient} from 'react-gradient';
import SettingStyleSheet from '../../StyleSheets/SettingStyleSheet';
import gTick_Icon from './../../assets/grayTickIcon.png';
import AppConfigStyleSheet from '../../StyleSheets/AppConfigStyleSheet';
import { TranslateEnum } from '../../Models/TranslateEnum';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import Switch from "react-switch";
import AppColor from '../../HelperClasses/AppColor';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import AppConstants from '../../Constants/AppConstants';

const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth  ?  true : false

var varianListHeight = windowHeight/2.6;
 
export default class AddVairantTypeValues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      statusSwitchValue: true,
      name: '',
      dataLoad: false,
      variantTypeValueArray: [],
      isEdit: false,
      selectedID: '',
      translationBool: false,
      selectedLanguage:0,
      languageArray:[],
      selectedValue:{},
      valueIndex: 0,
      translationValues: [],
      valuesArray:[],
      progressTitle:'',
      showProgress:false,
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    isEdit: PropTypes.bool,
    type: PropTypes.string,
    variantName: PropTypes.string,
    variantID: PropTypes.string,
    variantTypeData:PropTypes.any,
    justShowVariants:PropTypes.bool,
  };
  componentDidMount() {
    this.loadVariantValuesApi();
    this.loadLanguageApi();
  }
  loadLanguageApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.tanantslanguage, 'get', '',  '');
    if (responseJson['status'] == true) {
      var ppData = responseJson['data']['languages'];
      for (let obj of ppData) {
          this.state.languageArray.push(obj);
      }
      this.setState({updateUI: !this.state.updateUI, dataLoad: true});
    } else {
      this.setState({dataLoad: true});
    }
  };
  loadVariantValuesApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.addVariant + `/${this.props.variantID}/values`,  'get','','');
    this.setState({variantTypeValueArray: []});
    if (responseJson['status'] == true) {
      var variantV = responseJson['data']['variant_type_values'];
      for (let objc of variantV) {
        this.state.variantTypeValueArray.push(objc);
      }
      if (this.state.variantTypeValueArray.length != 0){
        this.state.selectedValue = this.state.variantTypeValueArray[this.state.valueIndex];
        this.getTranslationsApi();
      }
    }
    this.setState({dataLoad: true,isEdit:false});
  };
  addVariantValueApi = async () => {
    var a = 0;
    for (let obj of this.state.valuesArray) {
      a++
      var perc = ((a / this.state.valuesArray.length) * 100).toFixed(0);
      this.setState({ progressTitle: `${perc}%` })
      var dict = {
        name: obj,
        active: this.state.statusSwitchValue,
      };
      let value = { value: dict };
      let path = `${APPURL.URLPaths.addVariant}/${this.props.variantID}/values`
      var method = 'post';
      if (this.state.selectedID !== '') {
        path = APPURL.URLPaths.addVariant + `/${this.props.variantID}/values/${this.state.selectedID}`;
        method = 'put'
      }
      const responseJson = await networkService.networkCall(path, method, JSON.stringify({ variant_type: value }), '');
      if (a == this.state.valuesArray.length) {
        this.setState({ dataLoad: true });
        if (responseJson['status'] == true) {
          this.state.valuesArray = [];
          this.setState({ name: '' });
          this.setState({ statusSwitchValue: true });
          this.loadVariantValuesApi();
          this.setState({ dataLoad: true, isEdit: false, selectedID: '',showProgress:false });
        }
      }
    }
    if (this.state.valuesArray.length == 0) {
      this.setState({dataLoad: true});
    }
  }
  deleteVariantValuesApi = async id => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.addVariant + `/${this.props.variantID}/values/${id}`,'delete','','');
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.loadVariantValuesApi();
      this.setState({updateUI: !this.state.updateUI});
    }
  };
  getTranslationsApi = async () => {
    this.setState({dataLoad: false});
    let path = TranslateEnum.variantTypeValue;
    let params = this.state.selectedValue
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.translations + `?reference_id=${params['id']}&reference_type=${path}&translation_type=name`,'get','','');
    if (responseJson['status'] == true) {
      var tdata = responseJson['data']['translation'];
      // console.log('tdata ==> ',tdata)
      this.state.translationValues = [];
      let values = tdata['values'];
      for (let a = 0; a < this.state.languageArray.length; a++){
        var dict = { 'locale' : this.state.languageArray[a]['code']}
        if (values[a]) {
          dict['value'] = values[a]['value'];
        }else {
          dict['value'] = '';
        }
        this.state.translationValues.push(dict);
      }
      // console.log('tdata',this.state.translationValues)
      this.setState({dataLoad: true});
    }
    this.setState({dataLoad: true});
  };
  translateApi = async () => {
    this.setState({dataLoad: false});
    var values = [];
    for (let obj of this.state.translationValues) {
      let fDict = {
        value: obj['value'],
        locale: obj['locale'],
      };
      values.push(fDict)
    }
    let pDict = {
      reference_type : TranslateEnum.variantTypeValue,
      translation_type: 'name',
      reference_id: this.state.selectedValue['id'],
      values:values,
    }
    var path = APPURL.URLPaths.translations;
    var method = 'PATCH';
    const responseJson = await networkService.networkCall( path,method, JSON.stringify({translation: pDict}),'');
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      console.log('responseJson ===>', responseJson)
      this.loadVariantValuesApi();
      this.customAlert();
    }
  }
  customAlert() {
    AppToast();
  }
  //MARK:-  Button Actions
  saveTranslateBtnAction(index) {
    if (index == 1) {
      this.setState({translationBool: !this.state.translationBool});
    }else {
      this.translateApi();
    }
  }
  submitBtnAction(index) {
    if (index == 1) {
      this.props.backBtnAction();
    } else {
      if (this.state.valuesArray.length != 0) {
        this.setState({ dataLoad: false,showProgress:true  })
        this.addVariantValueApi()
      }
    }
  }
  editButtonAction = index => {
    let id_Index = this.state.variantTypeValueArray.findIndex(x => x.id === index);
    // console.log('id_Index', id_Index);
    this.state.valuesArray = [];
    this.state.valuesArray.push(this.state.variantTypeValueArray[id_Index]['name']);
    this.setState({
      name: this.state.variantTypeValueArray[id_Index]['name'],
      statusSwitchValue: this.state.variantTypeValueArray[id_Index]['active'],
      selectedID:this.state.variantTypeValueArray[id_Index]['id']
    })
    this.setState({isEdit: true});
  };
  deleteVariantValueAction = index => {
    let msg =  'Do you want to go delete this variant type value?';
    if (Platform.OS === 'web') {
      const r = window.confirm(msg);
      if (r == true) {
        this.deleteVariantValuesApi(index);
      }
    }
  };
  didSelectMenuItem = index => {
    this.state.selectedLanguage = 0;
    this.state.selectedValue = this.state.variantTypeValueArray[index];
    this.state.valueIndex = index
    this.setState({dataLoad:false, updateUI: !this.state.updateUI});
    this.getTranslationsApi();
  };
  didChangeTextField(text,id){    
    let code = this.state.languageArray[id]['code'];
    let index = this.state.translationValues.findIndex(x => x.locale === code);
    let vDic = {'locale': code, value: text}
    this.state.translationValues[index] = vDic;
    this.setState({updateUI: !this.state.updateUI});
  }
  onValuesTagChanged(value){
    console.log('value',value);
    this.state.valuesArray = value
    this.setState({updateUI: !this.state.updateUI});
  }
  clearAllBtnAction() {
    this.state.valuesArray = []
    this.setState({updateUI: !this.state.updateUI});
  }
  cancelEditingBtnAction() {
    this.state.valuesArray = []
    this.state.selectedID = ''
    this.state.valuesArray = []
    this.setState({ isEdit: false})
  }
  onEditChangeText(text){
    this.state.valuesArray = []
    this.state.valuesArray.push(text)
    this.setState({updateUI:!this.state.updateUI})
  }

  // UI Renders
  renderClearAll = () => {
    var views = [];
    if (this.state.valuesArray.length > 1){
      views.push(<TouchableOpacity onPress={() => this.clearAllBtnAction()}>
        <Text style={[tableStyle.saveTxtStyle,{color:colors.AppRed}]}>Clear All</Text>
      </TouchableOpacity>)
    }
    return (<View style={[commonStyle.subViewStyle, { display: this.state.isEdit ? 'none' : 'flex' }]}>
      <Text></Text>
      <View style={{flexDirection: 'row', justifyContent: 'space-between',width: '80%'}}>
        <Text>You can upload multiple values by pasting with comma separate, type multiple values with comma or pasting values from excel column</Text>
        {views}
      </View>
    </View>)
  }
  titleLblRender = ({ title }) => {
    return (
      <View>
        <Text style={commonStyle.titleNameStyle}>
          {title}
          <Text style={{ color: 'red', paddingTop: 5 }}> *</Text>
        </Text>
      </View>
    );
  };
  renderSwitches = ({name, id}) => {
    return <View style={commonStyle.switchViewStyle}>
      <Text style={commonStyle.titleNameStyle}>{name}</Text>
      <View style={{ width: itsMobileView ? '12%' : '80%' }}>
        <Switch
          onColor={colors.AppNewGreen}
          onChange={() => this.setState({ statusSwitchValue: !this.state.statusSwitchValue })}
          checked={this.state.statusSwitchValue}
          height={22}
          width={50}
        />
        </View>
      </View>;
  };
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    var views = [];
    for (let i = 0; i < this.state.variantTypeValueArray.length; i++) {
      views.push(
        <FlatList
          data={[
            this.state.variantTypeValueArray[i]['name'],
            this.state.variantTypeValueArray[i]['active'],
            this.state.variantTypeValueArray[i]['id'],
          ]}
          style={{
            backgroundColor: 'white',
          }}
          horizontal={true}
          renderItem={this.columnCell}
          extraData={this.state}
          showsVerticalScrollIndicator={false}
          scrollEnabled={true}
        />,
      );
    }
    return views;
  };
  columnCell = ({item, index}) => {
    if (index == 1) {
      var val = item ? 'Active' : 'Inactive';
      return (
        <View style={styles.columnTitleViewStyle} >
          <Text style={item == true ? tableStyle.columnActiveBtnStyle : tableStyle.columnDeActiveBtnStyle}>
            {val}
          </Text>
        </View>
      );
    } else if (index == 2) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{flexDirection: 'row'}}>
            <TouchableOpacity onPress={() => this.editButtonAction(item)}>
              <Image style={tableStyle.iconStyle} resizeMode="center" source={edit_Icon}/>
            </TouchableOpacity>
            <View style={{width: 10}} />
            <TouchableOpacity onPress={() => this.deleteVariantValueAction(item)}>
              <Image style={tableStyle.iconStyle} resizeMode="center" source={deleteIcon}/>
            </TouchableOpacity>
          </View>
        </View>
      );
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
        </View>
      );
    }
  };
  renderRejectView = (id) => {
    if (id['type'] == 3 || id['type'] == 4) {
      return (
        <View style={[tableStyle.secondButtonViewStyle, {marginRight: 0}]}>
          <TouchableOpacity onPress={() => this.saveTranslateBtnAction(id['type'] == 4 ? 2 : 1)}>
            <Text style={tableStyle.secondBtnTextStyle}>{id['type'] == 4 ? 'Save' : this.state.translationBool ? 'Close' : 'Translate'}</Text>
          </TouchableOpacity>
        </View>
      );
    }
    return (
      <View style={styles.saveGradientStyle}>
        <Gradient
          gradients={id['type'] == 1 ? colors.GradientColors : colors.GradientRedColors} // required
          property="background"
          gradientType={'linear'}
          duration={2333000}
          style={{shadowColor: colors.Appgray, shadowOpacity: 0.5, shadowOffset: {width: 0, height: 0}, shadowRadius: 5,
            height: 30, width: 80,borderRadius: 3}}
          angle="0deg">
          <TouchableOpacity
            style={tableStyle.nextPreviousViewStyle}
            onPress={() => this.saveCancelBtnAction(id['id'])}>
            <Text style={{color: 'white'}}>{id['type'] == 1 ? this.state.isEdit ? 'Update' : 'Save' : 'Close'}</Text>
          </TouchableOpacity>
        </Gradient>
      </View>
    );
  }
  mainViewRender = () => {
    if (Object.keys(this.state.selectedValue).length != 0) {
      return (<ScrollView horizontal={true} style={{ width: '100%' }}>
        <View style={{ width: itsMobileView ? '100%' : '75%' }}>
          <View style={{ flexDirection: itsMobileView ? 'column' : 'row' }}>
            <this.renderLanguageTypeView />
          </View>
        </View>
      </ScrollView>)
    } else {
      return <View />
    }
  }
  renderLanguageFields = (id) => {
    let index = id['id'];
    var fieldsViews = [];
    if (this.state.translationValues.length != 0 ) {
        let dictData = this.state.translationValues[index];
        for (let a = 0; a < 1 ; a++) {
          fieldsViews.push(
            <View style={{ width: itsMobileView ? windowWidth/1.4 : 200}}>
              <TextInput
                style={[AppConfigStyleSheet.txtFieldStyle,{outline:0}]}
                placeholder="Default text"
                value={dictData['value']}
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.didChangeTextField(text,index)}
              />
            </View>
          )
        }
    } else {
      fieldsViews.push(<View/>)
    }
    return fieldsViews;
  };
  renderLanguageTypeView = () => {
    var fieldsViews = [];
      for (let a = 0; a < this.state.languageArray.length; a++) {
        fieldsViews.push(
          <View style={{width: 200, margin: 20}}>
            <View style={{ height: 24 }}>
              <Text style={AppConfigStyleSheet.titleTxtStyle}>
                {this.state.languageArray[a]['name']}
              </Text>
            </View>
              <this.renderLanguageFields id={a}/>
          </View>
        )
    }
    return fieldsViews;
  }
  subMenuViewRender = () => {
    let subViews = [];
    for (let a = 0; a <= this.state.variantTypeValueArray.length - 1; a++) {
      subViews.push(
        <TouchableOpacity
          style={ this.state.selectedValue['id'] == this.state.variantTypeValueArray[a]['id']  ? SettingStyleSheet.selectedSubMenuViewStyle
              : SettingStyleSheet.subMenuViewStyle
          }
          onPress={() => this.didSelectMenuItem(a)}>
          <Image  style={SettingStyleSheet.subMenuIconStyle} resizeMode="contain"  source={gTick_Icon} />
          <Text style={SettingStyleSheet.subMenutextStyle}>
            {this.state.variantTypeValueArray[a]['name']}
          </Text>
        </TouchableOpacity>,
      );
    }
    return subViews;
  };
  renderTranslateView = () => {
    return (<View style={{ zIndex: 12 }}>
      <View style={[commonStyle.mainView, { height: windowHeight - 100 }]}>
        <View style={{ height: 10 }} />
        <View style={{ marginTop: 0, marginLeft: 10, height:'92%' }}>
          <ScrollView >
            <View style={{ flexDirection: itsMobileView ? 'column' : 'row' }}>
              <View style={SettingStyleSheet.subMenuContainerStyle}>
                <View>
                  <this.subMenuViewRender />
                </View>
              </View>
              <View style={SettingStyleSheet.horizontalLineView}></View>
              <this.mainViewRender />
            </View>
          </ScrollView>
        </View>
        <View style={{ justifyContent: 'flex-end', marginRight: 16, flexDirection: 'row'}}>
          <this.renderRejectView id={4} type={4} />
          <View style={{ width: 20 }} />
          <this.renderRejectView id={3} type={3} />
        </View>
        <View style={{ height: 20, width: '100%' }} />
      </View>
      <Appload enable={this.state.dataLoad} />
    </View>)
  }

  renderTextFieldView = () => {
    if (this.state.isEdit) {
      return <View style={{ width: itsMobileView ? '100%' : '100%' }}>
        <TextInput
          style={[commonStyle.fulltxtFieldStyle, { outline: 0 , borderWidth:0}]}
          value={this.state.valuesArray[0]}
          placeholder="Enter attribute value"
          placeholderTextColor={colors.placeholderColor}
          onChangeText={txt => this.onEditChangeText(txt)}
        />
      </View>
    } else {
      return (<TagsInput
        inputProps={{ placeholder: 'Add Values', style:{width: "95%"}}}
        value={this.state.valuesArray}
        addOnPaste={true}
        maxTags={this.state.selectedID == '' ? -1 : 1}
        addKeys={[188, 9, 13]}
        pasteSplit={data => {
          const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r'];
          return data.split(new RegExp(separators.join('|'))).map(d => d.trim());
        }}
        style={{
          container: { backgroundColor: 'red' },
          input: { backgroundColor: 'red' },
          tag: { backgroundColor: 'red' }
        }}
        onChange={txt => this.onValuesTagChanged(txt)} />)
    }
  }
  renderFieldView = () => {
    return <View style={{display: this.props.justShowVariants ? 'none' : 'flex'}}>
      <View style={commonStyle.mainView}>
        <View style={{ height: 20 }} />
        {/* <this.renderEditView /> */}
        <View style={commonStyle.tagViewContainerStyle}>
          <this.titleLblRender title={'Add Variant Type Values'} />
          <View style={commonStyle.tagViewStyle}>
            {this.renderTextFieldView()}
          </View>
        </View>
        <this.renderClearAll />
        <View style={{ height: 30 }} />
        <this.renderSwitches name={'Status(Not Active / Active)'} id={1} />
        <this.renderSaveBtn />
        <View style={{ height: 30 }} />
      </View>
    </View>
  }
  renderVariantListTitle = () => {
    return <View style={{display: this.props.justShowVariants ? 'none' : 'flex'}}>
      <View style={{ flexDirection: 'row-reverse', justifyContent: 'space-between', alignItems: 'center' }}>
        <View style={{ flexDirection: 'row-reverse', marginLeft: 20, margin: 20 }}>
          <this.renderRejectView id={3} type={3} />
        </View>
        <Text style={CommonStyleSheet.plainTextStyle}>
          {'  Variant Type Values List'}
          <Text style={[CommonStyleSheet.plainTextStyle, { color: colors.AppNewGreen }]}>
            {`(${this.props.variantName})`}
          </Text>
        </Text>
      </View>
    </View>
  }
  renderCancelEditingView = () => {
    return <View style={{ display: this.state.isEdit ? 'flex' : 'none'}}>
      <View style={commonStyle.subViewStyle}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '80%' }}>
          <View />
          <View style={tableStyle.secondButtonViewStyle}>
            <TouchableOpacity onPress={() => this.cancelEditingBtnAction()}>
              <Text style={{ color: colors.AppNewGreen,padding:5 }}>Cancel Editing</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  }
  renderSaveBtn = () => {
    return (
      <View style={{flexDirection:'row-reverse', marginRight:20}}>
        <View style={styles.saveGradientStyle}>
          <Gradient
            gradients={colors.GradientColors} // required
            property="background"
            gradientType={'linear'}
            duration={2333000}
            style={{
              shadowColor: colors.Appgray, shadowOpacity: 0.5, shadowOffset: { width: 0, height: 0 }, shadowRadius: 5,
              height: 30, width: 80, borderRadius: 3
            }}
            angle="0deg">
            <TouchableOpacity
              style={tableStyle.nextPreviousViewStyle}
              onPress={() => this.submitBtnAction()}>
              <Text style={{ color: 'white' }}>{this.state.isEdit ? 'Update' : 'Save'}</Text>
            </TouchableOpacity>
          </Gradient>
        </View>
        {this.renderCancelEditingView()}
      </View>
    )
  }
  render() {
    if (this.state.translationBool) {
      return <this.renderTranslateView />
    } else {
    return (
      <SafeAreaView style={commonStyle.Container}>
        <Header_View
          title={this.props.justShowVariants ? `${this.props.variantName}` : 'Add Variant Values'}
          backBtn={this.props.backBtnAction}
          showSaveBtn={false}
        />
        <View style={{ height: windowHeight / 1.2 }}>
          <ScrollView>
           {this.renderFieldView()}
            <View style={commonStyle.mainView}>
              {this.renderVariantListTitle()}
              <View style={this.props.justShowVariants ? styles.mainListView : styles.mainView}>
                <View style={{ height: itsMobileView ? '85%' : '100%' }}>
                  <ScrollView horizontal={true}>
                    <View style={{ width: '100%' }}>
                      <View style={tableStyle.headerListContainer}>
                        <ScrollView horizontal={true}>
                          <this.tableViewHeader />
                        </ScrollView>
                      </View>
                      <View style={{ height: '90%' }}>
                        <ScrollView>
                          <View style={{width: '100%' }}>
                            <this.columnDataView />
                          </View>
                        </ScrollView>
                      </View>
                    </View>
                  </ScrollView>
                </View>
              </View>
              </View>
          </ScrollView>
        </View>
        <Appload enable={this.state.dataLoad} showProgress={this.state.showProgress} progressTitle={this.state.progressTitle}/>
      </SafeAreaView>
    );
    }
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: AppColor.AppLightGreen,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : windowWidth / 3.0,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : windowWidth / 3.0,
    borderBottomWidth: 1,
    borderBottomColor: colors.SimonGray,
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  subViewStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  saveGradientStyle: {
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    height: 30,
    width: 80,
    borderRadius: 10, 
  },
  mainView: {
    margin: 10,
    flexDirection: 'column',
    borderRadius: 5,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height:windowHeight/2.6,
  },
  mainListView: {
    margin: 20,
    flexDirection: 'column',
    borderRadius: 5,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height:windowHeight/1.4,
  },
});
const tableHeaderString = [
  'Variant Type Value',
  'Status',
  'Action',
];
