/* eslint-disable react-hooks/rules-of-hooks */
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  FlatList,
  Linking,
} from 'react-native';
import PropTypes from 'prop-types';
import home_Icon from './../assets/homeIcon.png'; // Tell webpack this JS file uses this image
import colors from './../HelperClasses/AppColor';
import account_Icon from './../assets/accountIcon.png';
import adBanner_Icon from './../assets/adBannerIcon.png';
import listing_Icon from './../assets/listingIcon.png';
import collectionsIcon from './../assets/collections.png';
import revenue_Icon from './../assets/revenueIcon.png';
import settingIcon from './../assets/settingIcon.png';
import transactionIcon from './../assets/transaction.png';
import usersIcon from './../assets/users.png';
import orderIcon from './../assets/orderIcon.png';
import mediaIcon from './../assets/mediaIcon.png';
import reviewIcon from './../assets/reviewIcon.png';
import reportIcon from './../assets/reportIcon.png';
import channelIcon from './../assets/channelIcon.png';
import moreMenuIcon from './../assets/moreMenuIcon.png';
import webIcon from './../assets/webIcon.png';
import appIcon from './../assets/appIcon.png';
import nativeintegrationIcon from './../assets/nativeintegration.png';
import mobileIcon from './../assets/mobileConfigIcon.png';
import appTranslateIcon from './../assets/appTranslate.png';
import seoIcon from './../assets/seoIcon.png';
import iconGlobal from './../assets/global.png';
import moreIcon from './../assets/moreIcon.png';
import openLinkIcon from './../assets/whiteOpenLinkIcon.png';
import communicationIcon from './../assets/communicationIcon.png';
import deliveryIcon from './../assets/deliveryIcon.png';
import marketplaceIcon from './../assets/marketplaceIcon.png';

import branchesIcon from './../assets/branchesIcon.png';
import auditIcon from './../assets/auditIcon.png';
import driver from './../assets/driver.png';
import fleetsIcon from './../assets/fleetsIcon.png';
import rosterIcon from './../assets/rosterIcon.png';
import starIcon from './../assets/starIcon.png';

import AppConstants, {projectType} from '../Constants/AppConstants';
import {getCookiesValue} from '../HelperClasses/UserPrefrences';
import {
  DeliverySideMenuArray,
  SideAppSubMenuArray,
  SideMenuAccountsArray,
  SideMenuArray,
  SideMenuListingArray,
  SidePromotionsMenuArray,
  SideWebSubMenuArray,
} from '../Constants/ConstantArray';
import {checkUserRoleModule} from '../HelperClasses/SingletonClass';
import {MODULEKEYS} from '../Models/Enum';
import {ProjectTypeModel} from '../Models/ProjectTypeModel';
import {addMixpanelEvent, MixpanelEvent} from '../Models/MixPannelEnum';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import {TypeEnum} from '../Models/CommonEnum';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').width;

let webIconsAry = [
  iconGlobal,
  mobileIcon,
  seoIcon,
  iconGlobal,
  iconGlobal,
  appTranslateIcon,
];
let appIconsAry = [
  mobileIcon,
  nativeintegrationIcon,
  appTranslateIcon,
  iconGlobal,
];
let listingsIconsAry = [
  iconGlobal,
  mobileIcon,
  seoIcon,
  iconGlobal,
  nativeintegrationIcon,
];

var menuArray = [
  {name: SideMenuArray[0], image: home_Icon},
  {name: SideMenuArray[11], image: appIcon},
  {name: SideMenuArray[12], image: webIcon},
  {name: SideMenuArray[13], image: communicationIcon},
  {name: SideMenuArray[1], image: account_Icon},
  {name: SideMenuArray[2], image: listing_Icon},
  {name: SideMenuArray[3], image: collectionsIcon},
  {name: SideMenuArray[4], image: adBanner_Icon},
  {name: SideMenuArray[5], image: orderIcon},
  {name: SideMenuArray[6], image: transactionIcon},
  {name: SideMenuArray[7], image: usersIcon},
  {name: SideMenuArray[8], image: reportIcon},
  {name: SideMenuArray[9], image: mediaIcon},
  {name: SideMenuArray[10], image: reviewIcon},
];

var driveryMenuArray = [
  {name: DeliverySideMenuArray[0], image: starIcon},
  {name: DeliverySideMenuArray[1], image: orderIcon},
  {name: DeliverySideMenuArray[2], image: branchesIcon},
  {name: DeliverySideMenuArray[3], image: fleetsIcon},
  {name: DeliverySideMenuArray[4], image: driver},
  {name: DeliverySideMenuArray[5], image: rosterIcon},
  {name: DeliverySideMenuArray[6], image: reportIcon},
  {name: DeliverySideMenuArray[7], image: auditIcon},
];

var sidemenuBGColor = colors.AppNewGreen;

const optionsCursorTrueWithMargin = {
  followCursor: true,
  shiftX: 20,
  shiftY: -0,
};
let fixedWidth = AppConstants.mobileMaxWidth;

let itsMobileView = windowWidth < fixedWidth ? true : false;
export default class SideBar extends Component {
  constructor() {
    super();
    this.state = {
      updateValue: false,
      selectIndex: SideMenuArray[0],
      menuMainArray: [],
      showSubMenu: 0,
      hoverView: '',
      showMultipleAppView: false,
      hoverIndex: 0,
    };
  }
  static propTypes = {
    title: PropTypes.string,
    didSelectIndex: PropTypes.func,
    selectedIndex: PropTypes.number,
    showBackBtn: PropTypes.bool,
    changeEnvBtn: PropTypes.bool,
  };
  componentDidMount() {
    this.init();
  }
  init() {
    if (AppConstants.projectType == ProjectTypeModel.delivery) {
      this.state.menuMainArray = driveryMenuArray;
      this.setState({updateValue: !this.state.updateValue});
    } else {
      this.checkModuleRule();
    }
  }

  changeEnvBtnAction(value) {
    sidemenuBGColor = value ? colors.AppNewGreen : colors.blackColor;
    this.setState({updateValue: !this.state.updateValue});
  }
  checkEnvironment() {
    var checkEnv = AppConstants.isDevelopment ? true : !this.props.changeEnvBtn;
    if (!AppConstants.isDevelopment) {
      let evn = getCookiesValue(AppConstants.tradlyEVN);
      checkEnv = evn == 'sandbox' ? false : true;
    }
    return checkEnv;
  }
  checkModuleRule = () => {
    var titleAry = [...menuArray];
    if (AppConstants.appType) {
      let ind = titleAry.findIndex(x => x.name == SideMenuArray[1]);
      titleAry.splice(ind, 1);
    }
    this.state.menuMainArray = titleAry;
    checkUserRoleModule(MODULEKEYS.ACCOUNTS, res => {
      let ind = titleAry.findIndex(x => x.name == SideMenuArray[1]);
      if (!res[0]) {
        titleAry.splice(ind, 1);
      }
      this.state.menuMainArray = titleAry;
    });
    checkUserRoleModule(MODULEKEYS.LISTIINGS, res => {
      let ind = titleAry.findIndex(x => x.name == SideMenuArray[2]);
      if (!res[0]) {
        titleAry.splice(ind, 1);
      }
      this.state.menuMainArray = titleAry;
    });
    checkUserRoleModule(MODULEKEYS.COLLECTIONS, res => {
      let ind = titleAry.findIndex(x => x.name == SideMenuArray[3]);
      if (!res[0]) {
        titleAry.splice(ind, 1);
      }
      this.state.menuMainArray = titleAry;
    });
    checkUserRoleModule(MODULEKEYS.PROMOS, res => {
      let ind = titleAry.findIndex(x => x.name == SideMenuArray[4]);
      if (!res[0]) {
        titleAry.splice(ind, 1);
      }
      this.state.menuMainArray = titleAry;
    });
    checkUserRoleModule(MODULEKEYS.ORDER, res => {
      let ind = titleAry.findIndex(x => x.name == SideMenuArray[5]);
      if (!res[0]) {
        titleAry.splice(ind, 1);
      }
      this.state.menuMainArray = titleAry;
    });
    checkUserRoleModule(MODULEKEYS.TRANSACTIONS, res => {
      let ind = titleAry.findIndex(x => x.name == SideMenuArray[6]);
      if (!res[0]) {
        titleAry.splice(ind, 1);
      }
      this.state.menuMainArray = titleAry;
    });
    checkUserRoleModule(MODULEKEYS.USERS, res => {
      let ind = titleAry.findIndex(x => x.name == SideMenuArray[7]);
      if (!res[0]) {
        titleAry.splice(ind, 1);
      }
      this.state.menuMainArray = titleAry;
    });
    checkUserRoleModule(MODULEKEYS.REPORTS, res => {
      let ind = titleAry.findIndex(x => x.name == SideMenuArray[8]);
      if (!res[0]) {
        titleAry.splice(ind, 1);
      }
      this.state.menuMainArray = titleAry;
    });
    checkUserRoleModule(MODULEKEYS.MEDIA, res => {
      let ind = titleAry.findIndex(x => x.name == SideMenuArray[9]);
      if (!res[0]) {
        titleAry.splice(ind, 1);
      }
      this.state.menuMainArray = titleAry;
    });
    checkUserRoleModule(MODULEKEYS.REVIEWS, res => {
      let ind = titleAry.findIndex(x => x.name == SideMenuArray[10]);
      if (!res[0]) {
        titleAry.splice(ind, 1);
      }
      this.state.menuMainArray = titleAry;
    });
    this.setState({updateValue: !this.state.updateValue});
  };
  mixpannelInfo(value) {
    addMixpanelEvent(MixpanelEvent.sideMenu, {action: value});
  }
  //MARK:-  Buttons  

  didSelectSideMenu(item) {
    console.log('item', item);
    // this.mixpannelInfo(item['name']);
  }

  onMouseEnter(index, item) {
    this.setState({
      showSubMenu: item['name'],
      hoverView: item['name'],
      hoverIndex: index,
    });
  }
  onMouseLeave(index, item) {
    let name = item['name'];
    if (
      name == SideMenuArray[12] ||
      name == SideMenuArray[11] ||
      name == SideMenuArray[1] ||
      name == SideMenuArray[2] ||
      name == SideMenuArray[4]
    ) {
      if (AppConstants.projectType == ProjectTypeModel.delivery) {
        this.setState({showSubMenu: 0, hoverView: '', hoverIndex: 0});
      } else {
        this.setState({showSubMenu: item['name'], hoverView: item['name']});
      }
    } else {
      this.setState({showSubMenu: 0, hoverView: '', hoverIndex: 0});
    }
  }
  onMouseLeaveFromSubMenu() {
    this.setState({showSubMenu: 0, hoverView: ''});
  }
  selectDeliveryAPPBtnAction(name) {
    this.setState({showMultipleAppView: false});
    let checkAccess = AppConstants.productAccessArray.includes(name);
    if (checkAccess) {
      AppConstants.projectType = name;
      if (AppConstants.projectType == ProjectTypeModel.delivery) {
        let host = `/?app=delivery`;
        Linking.openURL(host);
      } else {
        let host = `/?app=marketplace`;
        Linking.openURL(host);
      }
      this.setState({showSubMenu: 0, hoverView: '', hoverIndex: 0});
    } else {
      this.props.didSelectIndex(AppConstants.landingPage);
    }
  }
  //MARK:-  UI  
  multipleAppView = () => {
    let name =
      AppConstants.projectType == ProjectTypeModel.marketPlace
        ? 'Delivery'
        : 'Marketplace';
    let title =
      AppConstants.projectType == ProjectTypeModel.marketPlace
        ? 'Tradly Fleet'
        : 'Tradly Platform ';
    let img =
      AppConstants.projectType == ProjectTypeModel.marketPlace
        ? deliveryIcon
        : marketplaceIcon;
    return (
      <View
        style={[
          styles.multipleAppViewStyle,
          {display: this.state.showMultipleAppView ? 'flex' : 'none'},
        ]}>
        <View style={{marginTop: 0}}>
          <Text
            style={[
              CommonStyleSheet.plainTextStyle,
              {color: colors.SimonGray},
            ]}>
            Switch to
          </Text>
        </View>
        <TouchableOpacity
          style={{marginTop: 20, flexDirection: 'row', alignItems: 'center'}}
          onPress={() => this.selectDeliveryAPPBtnAction(name.toLowerCase())}>
          <Image source={img} style={{width: 24, height: 24}} />
          <Text
            style={[
              CommonStyleSheet.plainSubTextStyle,
              {color: colors.SimonGray, marginLeft: 10},
            ]}>
            {title}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  renderSubMenuView = () => {
    var subView = [];
    var showView = false;
    var array =
      this.state.showSubMenu == SideMenuArray[11]
        ? SideAppSubMenuArray
        : SideWebSubMenuArray;
    let title =
      this.state.showSubMenu == SideMenuArray[11]
        ? 'APP SETTINGS'
        : 'WEB SETTINGS';
    let imageary =
      this.state.showSubMenu == SideMenuArray[11] ? appIconsAry : webIconsAry;
    if (this.state.showSubMenu == SideMenuArray[2]) {
      array = SideMenuListingArray;
      showView = true;
      title = TypeEnum.listings;
      imageary = listingsIconsAry;
    } else if (this.state.showSubMenu == SideMenuArray[1]) {
      array = SideMenuAccountsArray;
      title = TypeEnum.accounts;
      showView = true;
    } else if (this.state.showSubMenu == SideMenuArray[4]) {
      array = SidePromotionsMenuArray;
      title = 'Marketing';
      showView = true;
    }
    if (
      this.state.showSubMenu == SideMenuArray[11] ||
      this.state.showSubMenu == SideMenuArray[12]
    ) {
      showView = true;
    }
    if (AppConstants.projectType == ProjectTypeModel.delivery) {
      showView = false;
    }
    let marginTop =
      this.state.hoverIndex == 1
        ? 55
        : this.state.showSubMenu == SideMenuArray[4]
        ? this.state.hoverIndex * 38
        : 100;

    for (let a = 0; a < array.length; a++) {
      var sub = array[a];
      let dic = {name: sub};
      let checkWebContain = SideWebSubMenuArray.findIndex(
        x => x == dic['name'],
      );
      let checkContain = SideAppSubMenuArray.findIndex(x => x == dic['name']);
      var showOpenlinkIcon = false;
      if (checkContain != -1) {
        if (dic['name'] == SideAppSubMenuArray[3]) {
          showOpenlinkIcon = true;
        }
      }
      if (checkWebContain != -1) {
        if (dic['name'] == SideWebSubMenuArray[0]) {
          showOpenlinkIcon = true;
        } else if (dic['name'] == SideWebSubMenuArray[1]) {
          showOpenlinkIcon = true;
        } else if (dic['name'] == SideWebSubMenuArray[3]) {
          // showOpenlinkIcon = true;
        } else if (dic['name'] == SideWebSubMenuArray[4]) {
          // showOpenlinkIcon = true;
        }
      }
      if (title === TypeEnum.accounts) {
        sub = a > 0 ? sub.replace('Account ', '') : sub;
      }
      subView.push(
        <View style={{margin: 10, marginBottom: 0, marginLeft: 16}}>
          <TouchableOpacity
            style={{flexDirection: 'row', alignItems: 'center'}}
            onPress={() => this.didSelectSideMenu(dic)}>
            <Image style={{width: 30, height: 30}} source={imageary[a]} />
            <Text style={CommonStyleSheet.subMenuTextStyle}>{sub}</Text>
            <Image
              style={{
                display: showOpenlinkIcon ? 'flex' : 'none',
                width: 16,
                height: 16,
                tintColor: 'white',
              }}
              source={openLinkIcon}
            />
          </TouchableOpacity>
        </View>,
      );
    }
    if (showView) {
      return (
        <View
          style={[
            styles.submenuViewContainerStyle,
            {display: showView ? 'flex' : 'none', marginTop: marginTop},
          ]}
          onMouseLeave={() => this.onMouseLeaveFromSubMenu()}>
          <View
            style={{
              padding: 10,
              borderBottomWidth: 1,
              borderBottomColor: colors.SimonGray,
            }}>
            <Text style={{color: colors.SimonGray, paddingLeft: 10}}>
              {title}
            </Text>
          </View>
          {subView}
        </View>
      );
    } else {
      var showHover = false;
      if (this.state.hoverView) {
        showHover = this.state.hoverView.length == 0 ? false : true;
        showHover =
          this.state.hoverView == this.state.selectIndex ? false : true;
      }
      if (this.state.hoverView == SideMenuArray[SideMenuArray.length - 1]) {
        showHover = false;
      }
      let ind = this.state.hoverIndex;
      var mmTo = ind == 0 ? 20 : ind * 38 + 20;
      mmTo = ind == 14 ? windowHeight / 1.35 : mmTo;
      return (
        <View
          style={[
            styles.hoverViewContainerStyle,
            {display: showHover ? 'flex' : 'none', marginTop: mmTo},
          ]}
          onMouseLeave={() => this.onMouseLeaveFromSubMenu()}>
          <View>
            <Text
              numberOfLines={1}
              style={[
                CommonStyleSheet.plainTextStyle,
                {color: colors.SimonGray, flex: 1},
              ]}>
              {this.state.hoverView}
            </Text>
          </View>
        </View>
      );
    }
  };
  ListView = props => {
    return (
      <FlatList
        data={this.state.menuMainArray}
        horizontal={false}
        renderItem={itsMobileView ? this.onMobileListCell : this.ListCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
      />
    );
  };
  ListCell = ({item, index}) => {
    let checkEnv = this.checkEnvironment();
    var check =
      this.state.selectIndex == 0 ? SideMenuArray[0] : this.state.selectIndex;
    if (AppConstants.projectType == ProjectTypeModel.delivery) {
      if (this.state.selectIndex == SideMenuArray[0]) {
        check = DeliverySideMenuArray[0];
      }
    }
    let hover = this.state.hoverView == item['name'];
    var didTouch = false;
    if (
      item['name'] == SideMenuArray[11] ||
      item['name'] == SideMenuArray[12] ||
      item['name'] == SideMenuArray[4] ||
      item['name'] == SideMenuArray[2] ||
      item['name'] == SideMenuArray[1]
    ) {
      didTouch = true;
    }
    return (
      <TouchableOpacity
        disabled={didTouch}
        onMouseLeave={() => this.onMouseLeave(index, item)}
        onMouseEnter={() => this.onMouseEnter(index, item)}
        style={
          check == item['name'] || hover
            ? styles.selectedListViewStyle
            : styles.listViewStyle
        }
        onPress={() => this.didSelectSideMenu(item)}>
        <View
          style={
            check == item['name'] || hover
              ? styles.leftSelectView
              : checkEnv
              ? styles.leftUnSelectView
              : styles.leftUnSelectBlackView
          }
        />
        <View style={{width: 40}}>
          <Image
            style={styles.imageStyle}
            resizeMode="contain"
            source={item['image']}
          />
        </View>
      </TouchableOpacity>
    );
  };
  onMobileListCell = ({item, index}) => {
    let checkEnv = this.checkEnvironment();
    var check =
      this.state.selectIndex == 0 ? SideMenuArray[0] : this.state.selectIndex;
    if (AppConstants.projectType == ProjectTypeModel.delivery) {
      if (this.state.selectIndex == SideMenuArray[0]) {
        check = DeliverySideMenuArray[0];
      }
    }
    let hover = this.state.hoverView == item['name'];
    var didTouch = false;
    if (
      item['name'] == SideMenuArray[11] ||
      item['name'] == SideMenuArray[12] ||
      item['name'] == SideMenuArray[4] ||
      item['name'] == SideMenuArray[2] ||
      item['name'] == SideMenuArray[1]
    ) {
      didTouch = true;
    }
    return (
      <TouchableOpacity
        disabled={didTouch}
        onTouchEnd={() => this.onMouseLeave(index, item)}
        onTouchStart={() => this.onMouseEnter(index, item)}
        style={
          check == item['name'] || hover
            ? styles.selectedListViewStyle
            : styles.listViewStyle
        }
        onPress={() => this.didSelectSideMenu(item)}>
        <View
          style={
            check == item['name'] || hover
              ? styles.leftSelectView
              : checkEnv
              ? styles.leftUnSelectView
              : styles.leftUnSelectBlackView
          }
        />
        <View style={{width: 40}}>
          <Image
            style={styles.imageStyle}
            resizeMode="contain"
            source={item['image']}
          />
        </View>
      </TouchableOpacity>
    );
  };
  render() {
    let checkEnv = this.checkEnvironment();
    var settingView = [];
    if (AppConstants.projectType == ProjectTypeModel.delivery) {
      let lstDex = DeliverySideMenuArray.length - 1;
      // settingView.push(<View style={{ height: 40 }}>
      //   <this.ListCell item={{ name: DeliverySideMenuArray[lstDex], image: moreIcon }} index={AppConstants.appType ? lstDex - 1 : lstDex} />
      // </View>)
    } else {
      let lastIndex = SideMenuArray.length - 1;
      settingView.push(
        <View style={{height: 0}}>
          <this.ListCell
            item={{
              name: SideMenuArray[SideMenuArray.length - 2],
              image: settingIcon,
            }}
            index={
              AppConstants.appType
                ? SideMenuArray.length - 3
                : SideMenuArray.length - 2
            }
          />
        </View>,
      );
      // settingView.push(<View style={{ height: 40 }}>
      //   <this.ListCell item={{ name: SideMenuArray[lastIndex], image: moreIcon }} index={AppConstants.appType ? lastIndex - 1 : lastIndex} />
      // </View>)
    }
    return (
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          backgroundColor: checkEnv ? colors.AppNewGreen : colors.blackColor,
        }}>
        <View style={checkEnv ? styles.container : styles.blackContainer}>
          <View style={{height: 10}} />
          <View
            style={{
              height:
                AppConstants.projectType == ProjectTypeModel.delivery
                  ? '85%'
                  : '85%',
            }}>
            <this.ListView />
          </View>
          {settingView}
        </View>
        <this.renderSubMenuView />
        {/* <this.multipleAppView /> */}
      </View>
    );
  }
}

const commonHeight = 30;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.AppNewGreen,
    marginBottom: 0,
  },
  blackContainer: {
    flex: 1,
    backgroundColor: colors.blackColor,
    marginBottom: 100,
  },
  listViewStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    height: commonHeight,
    flex: 1,
    marginTop: 5,
    marginBottom: 5,
  },
  selectedListViewStyle: {
    backgroundColor: colors.lightYellow,
    flexDirection: 'row',
    alignItems: 'center',
    height: commonHeight,
    flex: 1,
    marginTop: 5,
    marginBottom: 5,
  },
  leftSelectView: {
    marginLeft: 0,
    width: 4,
    height: commonHeight,
    backgroundColor: colors.AppYellow,
    marginRight: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  leftUnSelectView: {
    marginLeft: 0,
    width: 4,
    height: commonHeight,
    backgroundColor: colors.AppNewGreen,
    marginRight: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  leftUnSelectBlackView: {
    marginLeft: 0,
    width: 4,
    height: commonHeight,
    backgroundColor: colors.blackColor,
    marginRight: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  imageStyle: {
    width: 20,
    height: 20,
    alignSelf: 'center',
  },
  submenuViewContainerStyle: {
    position: 'absolute',
    backgroundColor: colors.AppNewGreen,
    minHeight: 140,
    width: 240,
    marginLeft: 60,
    borderRadius: 10,
    paddingBottom: 10,
  },
  hoverViewContainerStyle: {
    position: 'absolute',
    backgroundColor: colors.AppNewGreen,
    // height: 40,
    padding: 10,
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 60,
    borderRadius: 10,
  },
  multipleAppViewStyle: {
    position: 'absolute',
    backgroundColor: colors.AppNewGreen,
    minHeight: 100,
    padding: 16,
    minWidth: 200,
    borderRadius: 5,
    marginLeft: 60,
    alignSelf: 'flex-end',
    bottom: 75,
    justifyContent: 'center',
  },
});
