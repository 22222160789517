import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  TextInput,
  Image,
  FlatList,
  SafeAreaView,
  Dimensions,
  Platform,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../../Settings/HeaderView';
import { AppToast } from '../../HelperClasses/AppToast';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import edit_Icon from '../../assets/editIcon.png';
import deleteIcon from '../../assets/delete_icon.png';
import {Gradient} from 'react-gradient';
import SettingStyleSheet from '../../StyleSheets/SettingStyleSheet';
import gTick_Icon from './../../assets/grayTickIcon.png';
import AppConfigStyleSheet from '../../StyleSheets/AppConfigStyleSheet';
import { TranslateEnum } from '../../Models/TranslateEnum';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import Switch from "react-switch";
import AppColor from '../../HelperClasses/AppColor';
import { addMixpanelEvent } from '../../Models/MixPannelEnum';
import CustomAlertView from '../../Component/CustomAlertView';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import dropIcon from '../../assets/dropIcon.png';
import AppConstants from '../../Constants/AppConstants';

const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false


export default class AddAttributeValues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      statusSwitchValue: true,
      name: '',
      dataLoad: false,
      attributeArray: [],
      isEdit: false,
      selectedID: '',
      translationBool: false,
      selectedLanguage:0,
      languageArray:[],
      selectedValue:{},
      valueIndex: 0,
      translationValues: [],
      valuesArray:[],
      progressTitle:'',
      showProgress:false,
      showNextViewBool:false,
    };
    this.handleRLDDChange = this.handleRLDDChange.bind(this)
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    isEdit: PropTypes.bool,
    type: PropTypes.string,
    attributeName: PropTypes.string,
    attributeID: PropTypes.string,
    closeBtnAction:PropTypes.func,
  };
  componentDidMount() {
    this.loadLanguageApi()
     this.loadAttributesValuesApi();
    const isEdit = this.props.isEdit;
  }
  loadLanguageApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.tanantslanguage, 'get', '',  '');
    if (responseJson['status'] == true) {
      var ppData = responseJson['data']['languages'];
      for (let obj of ppData) {
          this.state.languageArray.push(obj);
      }
      this.setState({updateUI: !this.state.updateUI, dataLoad: true});
    } else {
      this.setState({dataLoad: true});
    }
  };
  loadAttributesValuesApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.attributes + `/${this.props.attributeID}/values`,  'get')
    // console.log('attributes values', responseJson);
    this.setState({attributeArray: []});
    if (responseJson['status'] == true) {
      var listD = responseJson['data']['attribute'];
      if (listD['values']) {
        for (let objc of listD['values']) {
          this.state.attributeArray.push(objc);
        }
        if (this.state.attributeArray.length != 0){
          this.state.selectedValue = this.state.attributeArray[this.state.valueIndex];
          this.getTranslationsApi();
        }
      }
      // console.log('attributes arry', this.state.attributeArray);
    }
    this.setState({dataLoad: true,isEdit:false});
  }
  addAttributeValueApi = async () => {
    var a = 0;
    for (let obj of this.state.valuesArray) {
      a++
      var perc = ((a/this.state.valuesArray.length) * 100).toFixed(0);
      this.setState({progressTitle:`${perc}%`})
      var dict = {
        name: obj,
        active: this.state.statusSwitchValue,
      }

      let path = `${APPURL.URLPaths.attributes}/${this.props.attributeID}/values`
      if (this.state.selectedID !== '') {
        path = APPURL.URLPaths.attributes + `/${this.props.attributeID}/values/${this.state.selectedID}`;
      }
      let value = { value: dict }
      const responseJson = await networkService.networkCall(
        path, this.state.selectedID !== '' ? 'put' : 'post', JSON.stringify({ attribute: value }))
      if (a == this.state.valuesArray.length) {
        this.setState({ dataLoad: true })
        if (responseJson['status'] == true) {
          this.mixpanelInfo(true)
          this.state.selectedID = ''
          this.state.valuesArray = []
          this.setState({ name: '' })
          this.setState({ statusSwitchValue: true,showProgress:false,isEdit:false });
          this.loadAttributesValuesApi()
          if (this.state.selectedID !== '') {
            this.setState({ showNextViewBool: true});
          }
        } else {
          this.mixpanelInfo(false)
          // this.alertView(responseJson);
        }
      }
    }
  }
  mixpanelInfo(resp){
    let properties = { success:resp }
    addMixpanelEvent(`${this.state.selectedID !== '' ? 'Edit' : 'Add'} ${this.props.type} Attributes Values`, properties)
  }
  deleteAttributeApi = async id => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.attributes + `/${this.props.attributeID}/values/${id}`,'delete');
    // console.log('attribute', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.loadAttributesValuesApi();
      // this.alertView('Deleted');
    }
  }
  getTranslationsApi = async () => {
    this.setState({dataLoad: false});
    let path = TranslateEnum.attributeValue;
    let params = this.state.selectedValue
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.translations + `?reference_id=${params['id']}&reference_type=${path}&translation_type=name`,'get','','');
    if (responseJson['status'] == true) {
      var tdata = responseJson['data']['translation'];
      this.state.translationValues = [];
      let values = tdata['values'];
      for (let a = 0; a < this.state.languageArray.length; a++){
        var dict = { 'locale' : this.state.languageArray[a]['code']}
        if (values[a]) {
          dict['value'] = values[a]['value'];
        }else {
          dict['value'] = '';
        }
        this.state.translationValues.push(dict);
      }
      this.setState({dataLoad: true});
    }
    this.setState({dataLoad: true});
  };
  translateApi = async () => {
    this.setState({dataLoad: false});
    var values = [];
    for (let obj of this.state.translationValues) {
      let fDict = {
        value: obj['value'],
        locale: obj['locale'],
      };
      values.push(fDict)
    }
    let pDict = {
      reference_type : TranslateEnum.attributeValue,
      translation_type: 'name',
      reference_id: this.state.selectedValue['id'],
      values:values,
    }
    var path = APPURL.URLPaths.translations;
    var method = 'PATCH';
    const responseJson = await networkService.networkCall( path,method, JSON.stringify({translation: pDict}));
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.loadAttributesValuesApi()
      this.customAlert()
    }
  }
  updateOrderByAttributeValuesAPI = async (data,showLoader) => {
    if (showLoader){
      this.setState({dataLoad: false})
    }
    for (let a = 0; a < data.length; a++) {
      let objc = data[a]
      let dic = {
        'order_by': a+1,
        'name': objc['name'],
        'active':objc['active'],
      }
      let value = { value: dic }
      console.log(value,'i',a+1)
      let path = APPURL.URLPaths.attributes + `/${this.props.attributeID}/values/${objc['id']}`
      const responseJson = await networkService.networkCall(path, 'PUT', JSON.stringify({ attribute: value }))
      if (data.length - 1 == a){
        this.setState({dataLoad: true})
      }
    }
  }

  customAlert() {
    AppToast()
  }
  
  //MARK:-  Button Actions
  handleRLDDChange(reorderedItems) {
    this.state.attributeArray = reorderedItems
    this.setState({updateUI: !this.state.updateUI});
    this.updateOrderByAttributeValuesAPI(reorderedItems)
  }
  submitBtnAction = () => {
    if (this.state.valuesArray.length != 0) {
      this.setState({ dataLoad: false, showProgress: true })
      this.addAttributeValueApi()
    }
  }
  saveCancelBtnAction(index) {
    // console.log('index', index);
    if (index == 1) {
      this.props.backBtnAction();
    } else {
      this.setState({ dataLoad: false,showProgress:true });
      this.addAttributeValueApi();
    }
  }
  saveTranslateBtnAction(index) {
    if (index == 1) {
      this.setState({translationBool: !this.state.translationBool});
    }else {
      this.translateApi();
    }
  }
  editButtonAction = index => {
    let id_Index = this.state.attributeArray.findIndex(x => x.id === index);
    // console.log('id_Index', id_Index);
    this.state.valuesArray = [];
    this.state.valuesArray.push(this.state.attributeArray[id_Index]['name']);
    this.setState({
      name: this.state.attributeArray[id_Index]['name'],
      statusSwitchValue: this.state.attributeArray[id_Index]['active'],
      selectedID:this.state.attributeArray[id_Index]['id']

    })
    this.setState({isEdit: true});
  };
  deleteAttributeAction = index => {
    let msg =  'Do you want to go delete this attribute value?';
    if (Platform.OS === 'web') {
      const r = window.confirm(msg);
      if (r == true) {
        this.deleteAttributeApi(index);
      }
    }
  }
  didSelectMenuItem = index => {
    this.state.selectedLanguage = 0;
    this.state.selectedValue = this.state.attributeArray[index];
    this.state.valueIndex = index
    this.setState({dataLoad:false, updateUI: !this.state.updateUI});
    this.getTranslationsApi();
  };
  didChangeTextField(text,id){    
    let code = this.state.languageArray[id]['code'];
    let index = this.state.translationValues.findIndex(x => x.locale === code);
    let vDic = {'locale': code, value: text}
    this.state.translationValues[index] = vDic;
    this.setState({updateUI: !this.state.updateUI});
  }
  onValuesTagChanged(value){
    console.log('value',value);
    this.state.valuesArray = value
    this.setState({updateUI: !this.state.updateUI});
  }
  clearAllBtnAction() {
    this.state.valuesArray = []
    this.setState({updateUI: !this.state.updateUI});
  }
  cancelEditingBtnAction() {
    this.state.valuesArray = []
    this.state.selectedID = ''
    this.state.valuesArray = []
    this.setState({ isEdit: false})
  }
  closeBtnAction(){
    this.props.closeBtnAction(true)
    this.setState({showNextViewBool:false})
  }
  onEditChangeText(text){
    this.state.valuesArray = []
    this.state.valuesArray.push(text)
    this.setState({updateUI:!this.state.updateUI})
  }
  
  sortAlphabeticallyBtnAction() {
    this.state.attributeArray = this.state.attributeArray.sort((a, b) => {
      return a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base'
      })
    })
    this.setState({ updateUI: !this.state.updateUI });
    this.updateOrderByAttributeValuesAPI(this.state.attributeArray,true)
  }
  sortAlphaNumber = (a, b) => {
    var reA = /[^a-zA-Z]/g
    var reN = /[^0-9]/g
    var aA = a.name.replace(reA, "")
    var bA = b.name.replace(reA, "")
    console.log(bA, 'aA', aA)
    if (aA === bA) {
      var aN = parseInt(a.name.replace(reN, ""), 10)
      var bN = parseInt(b.name.replace(reN, ""), 10)
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1
    }
  }
  // UI Renders
  renderClearAll = () => {
    var views = [];
    if (this.state.valuesArray.length > 1) {
      views.push(<TouchableOpacity onPress={() => this.clearAllBtnAction()}>
        <Text style={[tableStyle.saveBtnTxtStyle,{ color: colors.AppRed}]}>Clear All</Text>
      </TouchableOpacity>)
    }
    return (<View style={[commonStyle.subViewStyle, { display: this.state.isEdit ? 'none' : 'flex' }]}>
      <Text></Text>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '80%' }}>
        <Text>You can upload multiple values by pasting with comma separate, type multiple values with comma or pasting values from excel column</Text>
        {views}
      </View>
    </View>)
  }
  titleLblRender = ({ title }) => {
    return (
      <View>
        <Text style={commonStyle.titleNameStyle}>
          {title}
          <Text style={{ color: 'red', paddingTop: 5 }}> *</Text>
        </Text>
      </View>
    );
  };
  renderSwitches = ({name, id}) => {
    return <View style={commonStyle.switchViewStyle}>
        <Text style={commonStyle.titleNameStyle}>{name}</Text>
      <View style={{ width: itsMobileView ? '12%' : '80%' }}>
        <Switch
          onColor={colors.AppNewGreen}
          onChange={() => this.setState({ statusSwitchValue: !this.state.statusSwitchValue })}
          checked={this.state.statusSwitchValue}
          height={22}
          width={50}
        />
        </View>
      </View>
  }
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
      />
    )
  }
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={[styles.tableViewTitleViewStyle,{width: index == 0 ? commonWidth + 20 : 130, marginLeft:index == 0 ? 16 : 0}]}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    )
  }
  columnDataView = props => {
    var views = []
    if (this.state.attributeArray.length != 0) {
      for (let i = 0; i < this.state.attributeArray.length; i++) {
        views.push(
          <FlatList
            data={[
              this.state.attributeArray[i]['name'],
              this.state.attributeArray[i]['active'],
              this.state.attributeArray[i]['id'],
            ]}
            style={{ backgroundColor: colors.AppWhite }}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />,
        )
      }
      return (<View style={{ flexDirection: 'row', flex: 1}}>
        <View>
          <RLDD
            items={this.state.attributeArray}
            itemRenderer={this.renderDragDropView}
            onChange={this.handleRLDDChange} />
        </View>
        <View style={{ backgroundColor:colors.AppWhite, width: 10 }} />
        <View style={{ flexDirection: 'column' }}>
          {views}
        </View>
      </View>)
    } else {
      return <View />
    }
  }
  renderDragDropView = (item, index) => {
    let vName = item['name']
    return (<View>
      <FlatList
        data={[vName]}
        style={{backgroundColor: 'white'}}
        horizontal={true}
        renderItem={this.renderDropdownCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
      />
    </View>)
  }
  renderDropdownCell = ({item}) => { 
    return (<View style={styles.dragdropViewStyle}>
      <Image style={{width:15,height:15}} resizeMode="center" source={dropIcon} />
    </View>)
  }
  columnCell = ({item, index}) => {
    if (index == 1) {
      var val = item ? 'Active' : 'Inactive';
      return (
        <View style={styles.columnTitleViewStyle} >
          <Text style={ item == true ? tableStyle.columnActiveBtnStyle : tableStyle.columnDeActiveBtnStyle}>
            {val}
          </Text>
        </View>
      );
    } else if (index == 2) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{flexDirection: 'row'}}>
            <TouchableOpacity onPress={() => this.editButtonAction(item)}>
              <Image style={tableStyle.iconStyle} resizeMode="center" source={edit_Icon} />
            </TouchableOpacity>
            <View style={{width: 10}} />
            <TouchableOpacity onPress={() => this.deleteAttributeAction(item)}>
              <Image style={tableStyle.iconStyle} resizeMode="center" source={deleteIcon}  />
            </TouchableOpacity>
          </View>
        </View>
      );
    } else {
      return (
        <View style={[styles.columnTitleViewStyle,{width:commonWidth}]}>
          <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
        </View>
      );
    }
  };
  renderRejectView = (id) => {
    if (id['type'] == 3 || id['type'] == 4) {
      return (
        <View style={tableStyle.secondButtonViewStyle}>
            <TouchableOpacity
              style={tableStyle.nextPreviousViewStyle}
              onPress={() => this.saveTranslateBtnAction(id['type'] == 4 ? 2 : 1)}>
              <Text style={tableStyle.secondBtnTextStyle}>{id['type'] == 4 ? 'Save' : this.state.translationBool ? 'Close' : 'Translate'}</Text>
            </TouchableOpacity>
        </View>
      );
    }
    return (
      <View style={tableStyle.gradientViewBGStyle}>
        <Gradient
          gradients={id['type'] == 1 ? colors.GradientColors : colors.GradientRedColors} // required
          property="background"
          gradientType={'linear'}
          duration={2333000}
          style={{shadowColor: colors.Appgray, shadowOpacity: 0.5, shadowOffset: {width: 0, height: 0}, shadowRadius: 5,
            height: 30, width: 80,borderRadius: 3}}
          angle="0deg">
          <TouchableOpacity
            style={tableStyle.nextPreviousViewStyle}
            onPress={() => this.saveCancelBtnAction(id['id'])}>
            <Text style={{color: 'white'}}>{id['type'] == 1 ? this.state.isEdit ? 'Update' : 'Save' : 'Close'}</Text>
          </TouchableOpacity>
        </Gradient>
      </View>
    )
  }
  renderAlphabetSortBtnView = () => {
    let display = this.state.attributeArray.length == 0 ? 'none' : 'flex'
    return(<View style={[tableStyle.secondButtonViewStyle,{display:display}]}>
      <TouchableOpacity style={tableStyle.nextPreviousViewStyle}
        onPress={() => this.sortAlphabeticallyBtnAction()}>
        <Text style={tableStyle.secondBtnTextStyle}>{'Sort Alphabetically'}</Text>
      </TouchableOpacity>
  </View>)
  }
  mainViewRender = () => {
    if (Object.keys(this.state.selectedValue).length != 0){
      return (<View style={{ width: itsMobileView ? '100%' : '75%' }}>
        <View style={{ flexDirection: itsMobileView ? 'column' : 'row' }}>
          <this.renderLanguageTypeView />
        </View>
      </View>)
    } else {
      return <View />
    }
  };
  renderLanguageFields = (id) => {
    let index = id['id'];
    var fieldsViews = [];
    if (this.state.translationValues.length != 0 ) {
        let dictData = this.state.translationValues[index];
        for (let a = 0; a < 1 ; a++) {
          fieldsViews.push(
            <View style={{ width: 200}}>
              <TextInput
                style={[AppConfigStyleSheet.txtFieldStyle,{outline:0}]}
                placeholder="Default text"
                value={dictData['value']}
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.didChangeTextField(text,index)}
              />
            </View>
          )
        }
    } else {
      fieldsViews.push(<View/>)
    }
    return fieldsViews;
  };
  renderLanguageTypeView = () => {
    var fieldsViews = [];
      for (let a = 0; a < this.state.languageArray.length; a++) {
        fieldsViews.push(
          <View style={{width: 200, margin: 20}}>
            <View style={{ height: 24 }}>
              <Text style={AppConfigStyleSheet.titleTxtStyle}>
                {this.state.languageArray[a]['name']}
              </Text>
            </View>
              <this.renderLanguageFields id={a}/>
          </View>
        )
    }
    return fieldsViews;
  }
  subMenuViewRender = () => {
    let subViews = [];
    for (let a = 0; a <= this.state.attributeArray.length - 1; a++) {
      subViews.push(
        <TouchableOpacity
          style={ this.state.selectedValue['id'] == this.state.attributeArray[a]['id']  ? SettingStyleSheet.selectedSubMenuViewStyle
              : SettingStyleSheet.subMenuViewStyle
          }
          onPress={() => this.didSelectMenuItem(a)}>
          <Image  style={SettingStyleSheet.subMenuIconStyle} resizeMode="contain"  source={gTick_Icon} />
          <Text style={SettingStyleSheet.subMenutextStyle}>
            {this.state.attributeArray[a]['name']}
          </Text>
        </TouchableOpacity>,
      );
    }
    return subViews;
  };
  renderTranslateView = () => {
    return (<ScrollView style={{zIndex: 12 }}>
      <View style={commonStyle.mainView}>
      <View style={{height: 20}} />
        <View style={{ marginTop: 0, marginLeft: 10 }}>
          <View style={{ flexDirection: itsMobileView ? 'column' : 'row' }}>
            <View style={SettingStyleSheet.subMenuContainerStyle}>
              <View>
                <this.subMenuViewRender />
              </View>
            </View>
            <View style={SettingStyleSheet.horizontalLineView}></View>
            <this.mainViewRender />
          </View>
          <View style={{justifyContent: 'flex-end', marginRight: 16, flexDirection: 'row',}}>
          <this.renderRejectView id={4} type={4} />
          <View style={{width: 20}}/>
          <this.renderRejectView id={3} type={3} />
          </View>
        </View>
        <View style={{height: 20, width: '100%'}} />
      </View>
      <Appload enable={this.state.dataLoad} />
    </ScrollView>)
  }
  renderSaveBtn = () => {
    return (
      <View style={{flexDirection:'row-reverse', marginRight:20, alignItems:'center'}}>
        <View style={tableStyle.gradientViewBGStyle}>
          <Gradient
            gradients={colors.GradientColors} // required
            property="background"
            gradientType={'linear'}
            duration={2333000}
            style={{
              shadowColor: colors.Appgray, shadowOpacity: 0.5, shadowOffset: { width: 0, height: 0 }, shadowRadius: 5,
              height: 30, width: 80, borderRadius: 3
            }}
            angle="0deg">
            <TouchableOpacity
              style={tableStyle.nextPreviousViewStyle}
              onPress={() => this.submitBtnAction()}>
              <Text style={{ color: 'white' }}>{this.state.isEdit ? 'Update' : 'Save'}</Text>
            </TouchableOpacity>
          </Gradient>
        </View>
        {this.renderCancelEditingView()}
      </View>
    )
  }
  renderCancelEditingView = () => {
    return <View style={{ display: this.state.isEdit ? 'flex' : 'none', marginTop:5}}>
      <View style={commonStyle.subViewStyle}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '80%' }}>
          <View />
          <View style={tableStyle.secondButtonViewStyle}>
            <TouchableOpacity onPress={() => this.cancelEditingBtnAction()}>
              <Text style={{ color: colors.AppNewGreen,padding:5 }}>Cancel Editing</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  }
  renderAlertView = () => {
   return <CustomAlertView
     showNextViewBool={this.state.showNextViewBool}
     titleValue={'Great 🎉, You will be able to find the attribute field(custom field) in "Add Listing" page'}
     nextBtnTitle={'Add Attribute Values'}
     closeBtnAction={() =>  this.closeBtnAction()}
     showNextButton={false}
   />
 }
  renderFieldsView = () => {
    if (this.state.isEdit) {
      return <View style={{ width: itsMobileView ? '100%' : '100%' }}>
        <TextInput
          style={[commonStyle.fulltxtFieldStyle, { outline: 0 , borderWidth:0}]}
          value={this.state.valuesArray[0]}
          placeholder="Enter attribute value"
          placeholderTextColor={colors.placeholderColor}
          onChangeText={txt => this.onEditChangeText(txt)}
        />
      </View>
    } else {
      return (<TagsInput
        inputProps={{ placeholder: 'Add Values', style:{width: "95%"}}}
        value={this.state.valuesArray}
        addOnPaste={true}
        maxTags={this.state.selectedID == '' ? -1 : 1}
        addKeys={[188, 9, 13]}
        pasteSplit={data => {
          const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r'];
          return data.split(new RegExp(separators.join('|'))).map(d => d.trim());
        }}
        onChange={txt => this.onValuesTagChanged(txt)} />)
    }
  }

  render() {
    if (this.state.translationBool) {
      return <this.renderTranslateView />
    } else {
      return (
        <SafeAreaView style={commonStyle.Container}>
          {this.renderAlertView()}
           <Header_View
            title={'Add Attribute Values'}
            backBtn={this.props.backBtnAction}
            showSaveBtn={false}
          />
          <View style={{ height: windowHeight / 1.1 }}>
            <ScrollView style={{ flex: 1 }}>
              <View style={commonStyle.mainView}>
                <View style={{ height: 20 }} />
                <View style={commonStyle.tagViewContainerStyle}>
                  <this.titleLblRender title={'Add Listing Attribute Values'} />
                  <View style={commonStyle.tagViewStyle}>
                   {this.renderFieldsView()}
                  </View>
                </View>
                <this.renderClearAll />
                <View style={{ height: 30 }} />
                <this.renderSwitches name={'Status(Not Active / Active)'} id={1} />
                <this.renderSaveBtn />
                <View style={{ height: 30 }} />
              </View>
              <View style={styles.mainView}>
                <View style={{flexDirection: 'row-reverse', justifyContent: 'space-between', alignItems: 'center'}}>
                  <View style={{ flexDirection: 'row-reverse', marginRight: 8, margin: 20 }}>
                    <this.renderRejectView id={3} type={3} />
                    <this.renderAlphabetSortBtnView />
                  </View>
                  <View>
                    <Text style={tableStyle.mainTitile}> {'Attribute Values List'}
                      <Text style={[tableStyle.mainTitile, { marginLeft: 5, color: colors.AppNewGreen }]}>
                        {`(${this.props.attributeName})`}
                      </Text>
                    </Text>
                  </View>
                </View>
                <View style={{ height: itsMobileView ? '85%' : '100%' }}>
                  <ScrollView horizontal={true}>
                    <View style={{ width: '100%' }}>
                      <View style={tableStyle.headerListContainer}>
                        <ScrollView horizontal={true}>
                          <this.tableViewHeader />
                        </ScrollView>
                      </View>
                      <View style={{ height: '70%' }}>
                        <ScrollView>
                          <View style={{ width: '100%' }}>
                            <this.columnDataView />
                          </View>
                        </ScrollView>
                      </View>
                    </View>
                  </ScrollView>
                </View>
              </View>
          </ScrollView>
          </View>
          <Appload enable={this.state.dataLoad} showProgress={this.state.showProgress} progressTitle={this.state.progressTitle}/>
        </SafeAreaView>
      );
    }
  }
}

const commonWidth = itsMobileView ? 130 : windowWidth - 370
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: AppColor.AppLightGreen,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: 130,
  },
  columnTitleViewStyle: {
    width: 130,
    borderBottomWidth: 1,
    borderBottomColor: colors.SimonGray,
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  mainView: {
    margin: 10,
    flexDirection: 'column',
    borderRadius: 5,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height:windowHeight/1.7,
    padding: 0,
  },
  tagViewStyle:{
    backgroundColor: 'red',
  },
  dragdropViewStyle: {
    width:25,
    height:70,
    paddingLeft: 10,
    justifyContent: 'center', 
    alignItems: 'center',
    flex: 1,
  },
});
const tableHeaderString = [
  'Attribute Value',
  'Status',
  'Action',
];

let  tagViewStyl = {
  backgroundColor: 'red',
}