import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Platform,
  Alert,
  TouchableOpacity,
  SafeAreaView,
  Dimensions,
  Image,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import RightView from './TenantCommonClasses/RightCustomView';
import commonStyle from '../StyleSheets/CreateTenantCommonStyleSheet';
import HeaderView from './TenantCommonClasses/LogoHeader';
import cod_Icon from '../assets/codIcon.png';
import pGateway_Icon from '../assets/paymentGatewayIcon.png';
import skip4Now_Icon from '../assets/skip4NowIcon.png';
import checked_Icon from '../assets/checkedIcon.png';
import unchecked_Icon from '../assets/uncheckedIcon.png';
import paypal_Icon from '../assets/paypalIcon.png';
import rPay_Icon from '../assets/razorPayIcon.png';
import sPay_Icon from '../assets/stripeIcon.png';
import networkService from '../Network/NetworkManager';
import BottomView from './TenantCommonClasses/FooterView';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../HelperClasses/AppToast';
import { ToastContainer} from 'react-toastify';

const APPURL = require('../Constants/URLConstants');
const windowHeight = Dimensions.get('window').height;
const contentFul = require('../Constants/ContentFullConstant');

let titleArray = [
  'Cash',
  'Payment gateway integration',
  'Skip for now',
];
let imagesArray = [cod_Icon, pGateway_Icon, skip4Now_Icon, pGateway_Icon,skip4Now_Icon,cod_Icon];

export default class SelectPaymentPlatform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBusinessIndex: ['','','','','','','','','','','','','','','',''],
      selectedPaymentTypeMethod: [],
      dataLoad: false,
      paymentTypeArray: [],
      updateUI:false,
    };
  }
  componentDidMount() {
    this.loadPaymentMethodApi();
  }
  //MARK:-  APIs Method 
  loadPaymentMethodApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.paymentMethod, 'get', '', '');
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var ppData = responseJson['data']['payment_methods'];
      for (let obj of ppData) {
        // if (obj['channel'] != 'cod') {
          this.state.paymentTypeArray.push(obj);
        // }
      }
    }
    this.setState({dataLoad: true});
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  submitButtonAction() {}
  didSelectSetting = id => {
    // console.log('id', id);
    if (this.state.selectedBusinessIndex[id] == ''){
      this.state.selectedBusinessIndex[id] = id;
    } else {
      this.state.selectedBusinessIndex[id] = '';
    }
    let sid = this.state.paymentTypeArray[id -1]
    let index = this.state.selectedPaymentTypeMethod.findIndex(x => x.id == sid['id'])
    if (index == -1) {
      this.state.selectedPaymentTypeMethod.push(sid);
    } else {
      this.state.selectedPaymentTypeMethod.splice(index, 1);
    }
    this.setState({updateUI: !this.state.updateUI});
  };
  nextBtnAction() {
    if (this.state.selectedPaymentTypeMethod.length != 0) {
      const {currencyData,languageData,businessData} = this.props.route.params;
      this.props.navigation.navigate('SelectShippingPlatform', {
        currencyData,currencyData,
        paymentMethodsArray: this.state.selectedPaymentTypeMethod,
        languageData:languageData,
        businessData:businessData,
      });
    } else {
      AppToast('Select atleast one Payment method');
    }
  }
  //MARK:-  UI Method 
  renderBusinessView = () => {
    var view = [];
    for (let a = 1; a < this.state.paymentTypeArray.length + 1; a++) {
      view.push(
        <TouchableOpacity
          style={this.state.selectedBusinessIndex[a] != '' ? styles.selectedBusinessViewsStyle : styles.businessViewsStyle}
          onPress={id => this.didSelectSetting(a)}>
          <View style={{flex: 1, width: '100%'}}>
            <Image
              style={styles.checkBoxViewStyle}
              resizeMode="contain"
              source={  this.state.selectedBusinessIndex[a] != '' ?  checked_Icon :  unchecked_Icon}
            />
          </View>
          <View style={{alignItems: 'center', flex: 4, width: '100%'}}>
            <Image
              style={commonStyle.iconImageViewStyle}
              resizeMode="contain"
              source={imagesArray[a - 1]}
            />
            <Text style={commonStyle.customViewTitleStyle}>
              {this.state.paymentTypeArray[a - 1]['name']}
            </Text>
          </View>
        </TouchableOpacity>,
      );
    }
    return view;
  };

  paymentTypeView = () => {
    if (this.state.selectedBusinessIndex == 1221) {
      return (
        <View style={{marginTop: 20}}>
          <Text style={appConfigStyle.titleTxtStyle}>
            {'   Payment gateway Options'}
          </Text>
        </View>
      );
    } else {
      return <View />;
    }
  };
  paymentMethodRender = () => {
    if (this.state.selectedBusinessIndex == 1221) {
      var paymentViews = [];
      for (let q = 0; q < this.state.paymentTypeArray.length; q++) {
        paymentViews.push(
          <View>
            <TouchableOpacity
              style={
                this.state.selectedPaymentType == q
                  ? commonStyle.selectedTypeViewStyle
                  : commonStyle.typeViewStyle
              }
              onPress={() => this.setState({selectedPaymentType: q})}>
              <Image
                style={commonStyle.typeIconStyle}
                resizeMode="contain"
                source={paypal_Icon}
              />
            </TouchableOpacity>
            <Text
              style={
                this.state.selectedPaymentType == q
                  ? commonStyle.selectedTypeTextStyle
                  : commonStyle.typeTextStyle
              }>
              {this.state.paymentTypeArray[q]['name']}
            </Text>
          </View>,
        );
      }
      return paymentViews;
    } else {
      return <View />;
    }
  };
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={styles.Container}>
          <ToastContainer />
          <View style={commonStyle.mainViewStyle}>
            <View style={commonStyle.leftViewStyle}>
              <HeaderView />
              <ScrollView style={{height: windowHeight - 100}}>
              <View style={commonStyle.contentViewStyle}>
                <ScrollView showsVerticalScrollIndicator={false}>
                  <View>
                    <Text style={commonStyle.contentTitleStyle}>
                      Select platform payment method
                    </Text>
                    {/* <Text style={{color: colors.Appgray, marginTop: 10}}>
                      Lorem ipsum dolor sit amet, consectetur{'\n'}
                      adipiscing elit aenean massa.
                    </Text> */}
                    <View style={{marginTop: 20}} />
                    <ScrollView horizontal={true}>
                      <View style={commonStyle.customViewContainer}>
                        <this.renderBusinessView />
                      </View>
                    </ScrollView>
                    <this.paymentTypeView />
                    <View style={{flexDirection: 'row', marginTop: 20}}>
                      <this.paymentMethodRender />
                    </View>
                  </View>
                  <View style={{marginTop: -10}}>
                    <BottomView
                      nextBtn={() => this.nextBtnAction()}
                      backBtn={() => this.props.navigation.pop()}
                      pageNumber={'4'}
                      progressValue={'80%'}
                    />
                  </View>
                </ScrollView>
              </View>
              </ScrollView>
            </View>
            <View style={commonStyle.rightViewStyle}>
              <RightView title={contentFul.paymentTitle} description={contentFul.paymentDescription}/>
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />;
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  businessViewsStyle: {
    margin: 10,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: 180,
    width: 160,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedBusinessViewsStyle: {
    margin: 12,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderWidth: 2,
    height: 180,
    width: 160,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.AppNewGreen,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
  },
  checkBoxViewStyle: {
    width: 20,
    height: 20,
    margin: 10,
  },
});
