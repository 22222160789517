import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Image,
  Dimensions,
  Alert,
  Platform,
  SafeAreaView,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import PropTypes from 'prop-types';
import CategoryLists from './CategoryList';
import AddCategories from './AddCategory';
import AttributesLists from './AttributesList';
import AddAttribute from './AddAttributes';
import ListingVariantTypes from './ListingVariantType';
import AddVariantTypes from './AddVariantType';
import Translations from './Translation';
import HelperLinkViews from '../../Component/HelperLinkView';
import ListingsSettings from '../GlobalSettings/ListingSetting';
import AccountSettings from '../GlobalSettings/AccountSetting';
import EditBulkScreen from './EditBulk';
import AddAttributeValue from './AddAttributeValues';

import { Gradient } from 'react-gradient';
import { ToastContainer } from 'react-toastify';
import { TranslateEnum } from '../../Models/TranslateEnum';
import AddVairantTypeValues from './AddVairantTypeValues';
import { MODULEKEYS } from '../../Models/Enum';
import { checkUserRoleModule } from '../../HelperClasses/SingletonClass';
import AppConstants from '../../Constants/AppConstants';
import AppColor from '../../HelperClasses/AppColor';
import { addMixpanelEvent } from '../../Models/MixPannelEnum';
import ListingListUnderCategory from './ListingListUnderCategory';
import AccountsUnderCategory from './AccountsUnderCategory';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import ImportCategories from './ImportCategories';
import networkService from '../../Network/NetworkManager';
import AddonsList from './AddonsList';
import AddAddons from './AddAddons';
import BackBtnView from '../../Component/BackBtnView';
import SaveButton from '../../Component/SaveButton';
import SecondryButton from '../../Component/SecondryButton';
import { RestrictedFeatureEnum } from '../../Models/RestrictedFeatureEnum';
import SubscribePlan from '../../Component/SubscribePlan';
import SidePanView from '../../Component/SidePanView';
import { TypeEnum } from '../../Models/CommonEnum';


const APPURL = require('./../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth ? true : false



export default class Listings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      updateUI: false,
      selectedEntriesCount: 10,
      dateLoad: false,
      selectedTabIndex: 0,
      addCategoryBool: false,
      addAttributeBool: false,
      addVariantBool: false,
      translationBool: false,
      isEdit: false,
      selectedData: {},
      translationTitle: '',
      translationRef: '',
      editBulkBool: false,
      addListingAttributeValueBool: false,
      attributeName: '',
      attributeID: '',
      selectedVariantID: 0,
      selectedVariantName: '',
      selectedVariantData: {},
      addListingVariantTypeValueBool: false,
      justShowVariants: false,
      listingHeaderArray: [],
      writeAdCat: false,
      accountAry: ['Category', `Attributes`, 'Configuration'],
      listingAry: ['Category', `Attributes`, 'Listing Variant Types', 'Configuration'],
      categoryWrite: AppConstants.defaultWrite,
      categoryRead: AppConstants.defaultRead,
      categoryDelete: AppConstants.defaultDelete,
      attributeWrite: AppConstants.defaultWrite,
      attributeRead: AppConstants.defaultRead,
      attributeDelete: AppConstants.defaultDelete,
      configurationWrite: AppConstants.defaultWrite,
      configurationRead: AppConstants.defaultRead,
      variantTypeWrite: AppConstants.defaultWrite,
      variantTypeRead: AppConstants.defaultRead,
      variantTypeDelete: AppConstants.defaultDelete,
      showListingsViews: false,
      showAccountsViews: false,
      translationBtnHover: false,
      addCatBtnHover: false,
      editBulkBtnHover: false,
      exportBtnHover: false,
      showImportCategory: false,
      showUpgrade: false,
      addAddons:false,

    }
  }

  // attributeName={this.state.selectedAttributeName}
  // attributeID={this.state.selectedAttributeID}
  static propTypes = {
    addSaveBtnAction: PropTypes.func,
    backBtnAction: PropTypes.func,
    titleString: PropTypes.string,
  };
  componentDidMount() {
    this.checkModuleRole()
    addMixpanelEvent(`${this.props.titleString ==  TypeEnum.listings ? 'Listing' : 'Account'} Settings`)
  }

  checkModuleRole() {
    let listings = this.props.titleString ==  TypeEnum.listings ? true : false
    var tableHeaderString = listings ? this.state.listingAry : this.state.accountAry
    this.state.listingHeaderArray = tableHeaderString
    checkUserRoleModule(listings ? MODULEKEYS.LISTIINGSCATEGORY : MODULEKEYS.ACCOUNTSCATEGORY, res => {
      // if (res[0]) {
      this.setState({ categoryRead: res[0], categoryWrite: res[1], categoryDelete: res[2] })
      // }
    })
    checkUserRoleModule(listings ? MODULEKEYS.LISTIINGSATTRIBUTE : MODULEKEYS.ACCOUNTSATTRIBUTE, res => {

      // console.log(MODULEKEYS.ACCOUNTSATTRIBUTE,'MODULEKEYS.ACCOUNTSATTRIBUTE ', res);

      // if (res[0]) {
      this.setState({ attributeRead: res[0], attributeWrite: res[1], attributeDelete: res[2] })
      // }
    })
    checkUserRoleModule(MODULEKEYS.LISTIINGSVARIANTTYPES, res => {
      // if (res[0]) {
      this.setState({ variantTypeRead: res[0], variantTypeWrite: res[1], variantTypeDelete: res[2] })
      // }
    })
    checkUserRoleModule(listings ? MODULEKEYS.LISTIINGSCONFIGURATION : MODULEKEYS.ACCOUNTSCONFIGURATION, res => {
      // if (res[0]) {
      this.setState({ configurationRead: res[0], configurationWrite: res[1] })
      // }
    })

    this.setState({ updateUI: !this.state.updateUI });
  }
  exportApi = async () => {
    let dict = { 'type': this.props.titleString ==  TypeEnum.listings ? 'listings' : 'accounts' }
    const responseJson = await networkService.networkCall(APPURL.URLPaths.categoriesExport, 'post', JSON.stringify(dict))
    if (responseJson['status'] == true) {
      let downloadLink = responseJson['data']['download_url']
      window.open(downloadLink)
    }
  }
  //MARK:-  Buttons Method 
  editBtnAction(value) {
    if (this.state.selectedTabIndex == 1) {
      this.setState({ addAttributeBool: !this.state.addAttributeBool, isEdit: true, selectedData: value });
    } else if (this.state.selectedTabIndex == 2) {
      this.setState({ addVariantBool: !this.state.addVariantBool, isEdit: true, selectedData: value });
    } else {
      this.setState({ addCategoryBool: !this.state.addCategoryBool, isEdit: true, selectedData: value }); 
    // else if (this.state.selectedTabIndex == 3) {
    //   this.setState({addAddons: !this.state.addAddons, isEdit: true, selectedData: value});
    // } 

    }
  }
  addSubCategory(value) {
    this.setState({ addCategoryBool: !this.state.addCategoryBool, isEdit: false, selectedData: value });
  }
  viewListingsBtnAction(value) {
    if (this.props.titleString ==  TypeEnum.listings) {
      this.setState({ showListingsViews: !this.state.showListingsViews, selectedData: value });
    } else {
      this.setState({ showAccountsViews: !this.state.showAccountsViews, selectedData: value });
    }
  }
  addBtnAction() {
    if (this.state.selectedTabIndex == 1) {
      this.setState({ isEdit: false, addAttributeBool: !this.state.addAttributeBool });
    } else if (this.state.selectedTabIndex == 0) {
      this.setState({ isEdit: false, addCategoryBool: !this.state.addCategoryBool });
    } else {
      if (this.props.titleString == TypeEnum.listings) {
        if (this.state.selectedTabIndex == 2) {
          this.setState({ isEdit: false, addVariantBool: !this.state.addVariantBool });
        } else {
          this.child.saveButtonAction();
        }
      } else {
        this.child.saveButtonAction();
      }
    }
  }
  addAttributeValuesBtnAction(value) {
    this.state.attributeID = value['attributeID'];
    this.state.attributeName = value['attributeName'];
    this.setState({ addListingAttributeValueBool: true })
  }
  addListingVariantTypeData(data) {
    this.state.selectedVariantName = data['selectedVariantName']
    this.state.selectedVariantID = data['selectedVariantID']
    this.state.selectedVariantData = data['selectedVariantData']
    this.state.justShowVariants = data['justShowVariants']
    this.setState({ addListingVariantTypeValueBool: true })
  }
  translationBtnAction(id) {
    if (id == 0) {
      this.state.translationTitle = 'Category';
      this.state.translationRef = TranslateEnum.category;
    } else if (id == 1) {
      this.state.translationTitle = `${this.props.titleString} Attributes`;
      this.state.translationRef = TranslateEnum.attribute;
    } else if (id == 2) {
      this.state.translationTitle = 'Listing Variant Types'
      this.state.translationRef = TranslateEnum.listingVariant;
    }
    this.setState({ translationBool: true });
  }
  bulkBtnAction(id) {
    if (id == 0) {
      this.state.translationTitle = 'Category';
      this.state.translationRef = TranslateEnum.category;
    } else if (id == 1) {
      this.state.translationTitle = `${this.props.titleString} Attributes`;
      this.state.translationRef = TranslateEnum.attribute;
    } else if (id == 2) {
      this.state.translationTitle = 'Listing Variant Types'
      this.state.translationRef = TranslateEnum.listingVariant;
    }
    this.setState({ editBulkBool: true, editBulkBtnHover: false })
  }
  importBtnAction() {
    this.setState({ showImportCategory: true })
  }

  onMouseAction(id) {
    if (id == 1) {
      this.setState({ translationBtnHover: !this.state.translationBtnHover })
    } else if (id == 11) {
      this.setState({ editBulkBtnHover: !this.state.editBulkBtnHover })
    } else if (id == 12) {
      this.setState({ exportBtnHover: !this.state.exportBtnHover })
    } else {
      this.setState({ addCatBtnHover: !this.state.addCatBtnHover })
    }
  }
  exportBtnAction() {
    this.exportApi()
  }
  checkRestriction() {
    let check = AppConstants.restrictedFeatureArray.includes(RestrictedFeatureEnum.attributes)
    return check
  }
  checkImportCategoryRestriction() {
    let check = AppConstants.restrictedFeatureArray.includes(RestrictedFeatureEnum.bulkEditCategories)
    return check
  }
  /*  UI   */
  tableViewHeader = props => {
    // var tableHeaderString = ['Category',`${this.props.titleString} Attributes`, 'Configuration'];
    // if (this.props.titleString == 'Listings') {
    //   tableHeaderString = ['Category',`${this.props.titleString} Attributes` , 'Listing Variant Types' ,'Configuration'];
    // }
    return (
      <FlatList
        data={this.state.listingHeaderArray}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        style={{ marginLeft: 20 }}
        scrollEnabled={true}
      />
    );
  };
  tableHeaderCell = ({ item, index }) => {
    return (
      <TouchableOpacity
        style={this.state.selectedTabIndex == index ? styles.headerViewStyle : styles.selectedHeaderViewStyle}
        onPress={() => this.setState({ selectedTabIndex: index })}>
        <Text style={[CommonStyleSheet.tabBtnViewStyle, { color: this.state.selectedTabIndex == index ? colors.AppNewGreen : colors.Appgray }]}>
          {`${item}`}
        </Text>
      </TouchableOpacity>
    )
  }
  renderMessageView = () => {
    return (<View style={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Text style={CommonStyleSheet.plainTextStyle}>You Don't have access</Text>
    </View>)
  }
  renderMainComponent = () => {
    if (this.state.selectedTabIndex == 0) {
      if (this.state.categoryRead) {
        return <CategoryLists
          editBtn={value => this.editBtnAction(value)}
          type={this.props.titleString}
          addSubCatBtn={value => this.addSubCategory(value)}
          viewListingsBtn={value => this.viewListingsBtnAction(value)} />
      } else {
        return <this.renderMessageView />
      }
    } else if (this.state.selectedTabIndex == 1) {
      if (this.state.attributeRead) {
        return <AttributesLists editBtn={value => this.editBtnAction(value)} type={this.props.titleString} addAttibuteValues={value => this.addAttributeValuesBtnAction(value)} />
      } else {
        return <this.renderMessageView />
      }
    } else if (this.state.selectedTabIndex == 2) {
      if (this.props.titleString ==  TypeEnum.listings) {
        if (this.state.variantTypeRead) {
          return <ListingVariantTypes editBtn={value => this.editBtnAction(value)} addListingVariantTypeData={value => this.addListingVariantTypeData(value)} />;
        } else {
          return <this.renderMessageView />
        }
      } else {
        if (this.state.configurationRead) {
          return <AccountSettings ref={child => (this.child = child)} />
        } else {
          return <this.renderMessageView />
        }
      }
    } else if (this.state.selectedTabIndex == 23) {
      if (this.props.titleString == 'Listings') {
        if (this.state.addonsRead) {
        return <AddonsList editBtn={value => this.editBtnAction(value)}  ref={child => (this.child = child)} />
        } else {
          return <this.renderMessageView />
        }
      }
    } else if (this.state.selectedTabIndex == 3) {
      if (this.props.titleString ==  TypeEnum.listings) {
        if (this.state.configurationRead) {
          return <ListingsSettings ref={child => (this.child = child)} />
        } else {
          return <this.renderMessageView />
        }
      }
    } else {
      return <View />;
    }
  };
  renderTranslationBtnView = () => {
    var transaltionTitle = this.state.selectedTabIndex == 1 ? 'Translate' : 'Translate';
    if (this.state.selectedTabIndex == 3) {
      transaltionTitle = 'Edit Order';
    }
    if (this.props.titleString ==  TypeEnum.listings) {
      if (this.state.selectedTabIndex == 2) {
        transaltionTitle = 'Translate'
      }
    }
    if (this.state.selectedTabIndex == 1 || this.state.selectedTabIndex == 0) {
      let write = this.state.selectedTabIndex == 1 ? this.state.attributeWrite : this.state.categoryWrite
      let check = this.state.selectedTabIndex == 0 ? false : this.checkRestriction()
      return <View style={{ display: write ? 'flex' : 'none' }}>
        <SecondryButton
          btnDeActive={check}
          showBtn={true} btnTitle={transaltionTitle}
          deActiveBtnAction={() => this.setState({ showUpgrade: true })}
          btnAction={() => this.translationBtnAction(this.state.selectedTabIndex)} />
      </View>
    } else if (this.props.titleString == TypeEnum.listings) {
      if (this.state.selectedTabIndex == 2) {
        return <View style={{ display: this.state.variantTypeWrite ? 'flex' : 'none' }} >
          <SecondryButton
            showBtn={true}
            btnTitle={transaltionTitle}
            btnAction={() => this.translationBtnAction(this.state.selectedTabIndex)}
            deActiveBtnAction={() => this.setState({ showUpgrade: true })} />
        </View>
      } else { return <View /> }
    } else {
      return <View />
    }
  }
  renderBulkBtnView = () => {
    var bulkTitle = this.state.selectedTabIndex == 1 ? 'Edit Bulk' : 'Import';
    if (this.state.selectedTabIndex == 1 || this.state.selectedTabIndex == 0) {
      let check = this.state.selectedTabIndex == 0 ? this.checkImportCategoryRestriction() : this.checkRestriction()
      return <SecondryButton
        btnDeActive={check}
        showBtn={true}
        btnTitle={bulkTitle}
        deActiveBtnAction={() => this.setState({ showUpgrade: true })}
        btnAction={() => this.state.selectedTabIndex == 0 ? this.importBtnAction(this.state.selectedTabIndex) : this.bulkBtnAction(this.state.selectedTabIndex)} />
    } else {
      return <View />
    }
  }

  renderAddBtn = () => {
    var addTitile = this.state.selectedTabIndex == 1 ? 'Add Attribute' : 'Add Category';
    var write = this.state.selectedTabIndex == 1 ? this.state.attributeWrite : this.state.categoryWrite
    if (this.state.selectedTabIndex == 2) {
      addTitile = this.props.titleString ==  TypeEnum.listings ? 'Add Variant Types' : 'Save';
      write = this.props.titleString ==  TypeEnum.listings ? this.state.variantTypeWrite : this.state.configurationWrite
    }
    // if (this.state.selectedTabIndex == 3) {
    //   addTitile = 'Add Addons'
    //   write =  this.state.addonsWrite
    // }
    if (this.state.selectedTabIndex == 3) {
      addTitile = 'Save';
      write = this.state.configurationWrite
    }
    var addBtnenable = false
    if (this.state.selectedTabIndex == 1) {
      addBtnenable = this.checkRestriction()
    }
    return <SaveButton
      saveBtn={() => this.addBtnAction()}
      btnDeActive={addBtnenable}
      deActiveBtnAction={() => this.setState({ showUpgrade: true })}
      showSaveBtn={this.props.showSaveBtn}
      saveBtnTitle={addTitile} />
  }
  renderExportBtnView = () => {
    return <SecondryButton
      showBtn={this.state.selectedTabIndex == 0 ? true : false}
      btnTitle={'Export'}
      btnAction={() => this.exportBtnAction()} />
  }
  renderUpgradePlanView() {
    return <TouchableOpacity style={[tableStyle.passwordViewStyle, { display: this.state.showUpgrade ? 'flex' : 'none' }]}
      onPress={() => this.setState({ showUpgrade: false })}>
      <SubscribePlan upgrade={true} />
    </TouchableOpacity>
  }
  renderSidePanView = () => {
    if (this.state.showImportCategory) {
      return <SidePanView
        dissmissView={() => this.setState({ showImportCategory: !this.state.showImportCategory })}
        showSidepanView={this.state.showImportCategory}
        title={'Import Categories'}
        customView={<View>
          <ToastContainer />
          <ImportCategories />
        </View>}
      />
    } else { return <View /> }
  }
  render() {
    if (this.state.addCategoryBool) {
      return (
        <View style={{ flexDirection: 'column' }}>
          <ToastContainer />
          <AddCategories
            type={this.props.titleString}
            isEdit={this.state.isEdit}
            categoryData={this.state.selectedData}
            closeBtnAction={() => this.setState({ addCategoryBool: false })}
            backBtnAction={() => this.setState({ addCategoryBool: !this.state.addCategoryBool })} />
        </View>
      )
    } else if (this.state.addAttributeBool) {
      return (
        <View style={{ flexDirection: 'column' }}>
          <ToastContainer />
          <AddAttribute
            type={this.props.titleString}
            isEdit={this.state.isEdit}
            attributeData={this.state.selectedData}
            closeBtnAction={() => this.setState({ addAttributeBool: false })}
            backBtnAction={() => this.setState({ addAttributeBool: !this.state.addAttributeBool })} />
        </View>
      );
    } else if (this.state.addVariantBool) {
      return (
        <ScrollView style={{ flexDirection: 'column' }}>
          <ToastContainer />
          <AddVariantTypes type={this.props.titleString} isEdit={this.state.isEdit}
            variantTypeData={this.state.selectedData}
            backBtnAction={() => this.setState({ addVariantBool: !this.state.addVariantBool })} />
        </ScrollView>
      );
    } else if (this.state.showListingsViews) {
      return <View style={{ flexDirection: 'column' }}>
        <ListingListUnderCategory
          categoryData={this.state.selectedData}
          type={this.props.titleString}
          CID={this.state.selectedData['id']}
          backBtnAction={() => this.setState({ showListingsViews: false })} />
      </View>
    } else if (this.state.showAccountsViews) {
      return <View style={{ flexDirection: 'column' }}>
        <AccountsUnderCategory
          type={this.props.titleString}
          CID={this.state.selectedData['id']}
          categoryData={this.state.selectedData}
          backBtnAction={() => this.setState({ showAccountsViews: false })} />
      </View>
    } else if (this.state.translationBool) {
      return (
        <View style={{ flexDirection: 'column' }}>
          <ToastContainer />
          <Translations
            titleString={this.props.titleString}
            translationTitle={this.state.translationTitle}
            translationRef={this.state.translationRef}
            backBtnAction={() => this.setState({ translationBool: false })} />
        </View>
      )
    } else if (this.state.editBulkBool) {
      return (
        <View style={{ flexDirection: 'column' }}>
          <ToastContainer />
          <EditBulkScreen
            titleString={this.props.titleString}
            bulkTitle={this.state.translationTitle}
            bulkRef={this.state.translationRef}
            backBtnAction={() => this.setState({ editBulkBool: false })} />
        </View>
      )
    } else if (this.state.addListingAttributeValueBool) {
      return (
        <View style={{ flexDirection: 'column' }}>
          <AddAttributeValue
            type={this.props.titleString}
            attributeName={this.state.attributeName}
            attributeID={this.state.attributeID}
            closeBtnAction={() => this.setState({ addAttributeBool: false, addListingAttributeValueBool: false })}
            backBtnAction={() => this.setState({ addListingAttributeValueBool: false })} />
        </View>
      )
    } else if (this.state.addListingVariantTypeValueBool) {
      return (
        <View style={{ flexDirection: 'column' }}>
          <AddVairantTypeValues
            type={this.props.titleString}
            isEdit={false}
            variantName={this.state.selectedVariantName}
            variantID={this.state.selectedVariantID}
            variantTypeData={this.state.selectedVariantData}
            justShowVariants={this.state.justShowVariants}
            backBtnAction={() => this.setState({ addListingVariantTypeValueBool: false })} />
        </View>)
    } else if (this.state.addAddons) {
      return (
        <View style={{ flexDirection: 'column' }}>
          <ToastContainer />
          <AddAddons
            isEdit={this.state.isEdit}
            addonsData={this.state.selectedData}
            backBtnAction={() => this.setState({ addAddons: !this.state.addAddons })} />
        </View>)
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <View style={{zIndex:101}}>
            {this.renderSidePanView()}
            {this.renderUpgradePlanView()}
          </View>
          <View style={{zIndex:1}}>
            <View style={tableStyle.navigationViewStyle}>
              <View style={tableStyle.headerContainer}>
                <View style={[tableStyle.headerListViewcontainer, { width: itsMobileView ? '100%' : this.state.selectedTabIndex == 0 ? '65%' : '72%' }]}>
                  <TouchableOpacity style={tableStyle.backBtnBackViewStyle} onPress={this.props.backBtnAction}>
                    <BackBtnView />
                  </TouchableOpacity>
                  <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                    <this.tableViewHeader />
                  </ScrollView>
                </View>
                <View style={{ flexDirection: 'row', marginLeft: itsMobileView ? 80 : 0 }}>
                  <this.renderExportBtnView />
                  <this.renderBulkBtnView />
                  <this.renderTranslationBtnView />
                  {this.renderAddBtn()}
                </View>
              </View>
            </View>
            <View style={styles.MainContainerViewStyle}>
              <ToastContainer />
              <this.renderMainComponent />
            </View>
            <View style={{ marginTop: -5, zIndex: 12 }}>
              <HelperLinkViews title={`${this.props.titleString}`} />
            </View>
          </View>
        </SafeAreaView>
      );
    }
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: AppColor.AppLightGreen,
  },
  headerViewStyle: {
    padding: 10,
    height: 40,
    margin: 10,
    borderBottomColor: colors.AppNewGreen,
    borderBottomWidth: 2,
  },
  selectedHeaderViewStyle: {
    padding: 10,
    height: 40,
    borderBottomColor: colors.TransparentColor,
    borderBottomWidth: 2,
    margin: 10,
  },
  MainContainerViewStyle: {
    // flex: 1,
    height: windowHeight - 150,
    zIndex: 5
  },
  columnTitleViewStyle: {
    width: itsMobileView ? 130 : windowWidth / 4.5,
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: colors.SimonGray,
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  saveBtnStyle: {
    backgroundColor: colors.AppNewGreen,
    height: 40,
    width: 140,
    borderRadius: 5,
    marginRight: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
