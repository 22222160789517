import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  ScrollView,
  Dimensions,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import Switch from "react-switch";
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import SaveButton from '../../Component/SaveButton';
import { ToastContainer } from 'react-toastify';

const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth  ?  true : false

export default class AccountSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      geoCodeKey: '',
      updateUI: false,
      dataLoad: false,
      accountDetailNote: '',
      addressLabel: '',
      categoryLabel: '',
      regexp: /^[0-9\b]+$/,
      nearRadiusKm: '',
      statusArray: [false, false, false, false,false,false],
      shippingPreferenceNote:'',
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    fromRootScreen:PropTypes.bool
  };
  componentDidMount() {
    this.loadConfigApi()
    addMixpanelEvent(MixpanelEvent.viewAccountConfigurations)

  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config + '?key_group=accounts', 'get' );
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      // console.log('this.state.configs =>', configs);
      this.setState({
        accountDetailNote: configs['account_detail_note'] || '',
        addressLabel: configs['account_address_label'] || '',
        categoryLabel: configs['account_category_label'] || '',
        nearRadiusKm: configs['near_by_accounts_radius_km'] || '',
        shippingPreferenceNote:configs['shipping_methods_preference_note'] ?? '',
      })
      this.state.statusArray[0] = configs['account_auto_approval'] || false
      this.state.statusArray[1] = configs['enable_shipping_methods_preference'] || false
      this.state.statusArray[2] = configs['account_map_location_selector_enabled'] || false
      // this.state.statusArray[3] = configs['enable_branch_unique_url'] || false;
      this.state.statusArray[3] = configs['account_address_enabled'] || false
      this.state.statusArray[4] = configs['enable_account_slug'] || false
      this.state.statusArray[5] = configs['show_description'] ?? true
      this.state.statusArray[6] = configs['account_enable_formatted_text_description'] ?? true
      this.state.statusArray[7] = configs['hide_address_from_account_detail'] ?? false

    }
    this.setState({dataLoad: true});
  };
  addOrUpdateApi = async () => {
    var configs = [];
    var valueArray = [
      this.state.accountDetailNote,
      this.state.addressLabel,
      this.state.categoryLabel,
      this.state.nearRadiusKm,
      this.state.shippingPreferenceNote,
    ]
    for (let a = 0; a < 5; a++) {
      if (valueArray[a].length != 0) {
        let fDict = {
          key_group: 'accounts',
          key: keyStringArray[a],
          value: valueArray[a],
          secured: false,
        }
        configs.push(fDict)
      }
    }

    for (let i = 0; i < keyParameterArray.length; i++) {
      let fDict = {
        key_group: 'accounts',
        key: keyParameterArray[i],
        value: this.state.statusArray[i],
        secured: false,
      };
      configs.push(fDict);
    }
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config,'post', JSON.stringify({configs: configs}))
    // console.log('configs', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true)
      this.deleteKeysAPI(configs);
      //this.alertView('Uploaded successfully');
    } else {
      this.mixpanelInfo(false)
      this.setState({dataLoad: true})
    }
  }
  mixpanelInfo(resp){
    let properties = { success:resp }
    addMixpanelEvent(MixpanelEvent.accountConfigurationSaved, properties)
  }
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    var deleteIndex = [];
    for (let q = 0; q < commonKeyStringArray.length; q++) {
      deleteParms.push(commonKeyStringArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
      //deleteIndex.push(index);
    }
    // for (let i = deleteIndex.length - 1; i >= 0; i--) {
    //   deleteParms.splice(deleteIndex[i], 1);
    // }
    let parms = deleteParms.toString();
    let urlParm = APPURL.URLPaths.config + '?key=' + parms;
    const responseJson = await networkService.networkCall(
      urlParm,
      'delete',
      '',
      '',
    );
    // console.log('configs', responseJson);
    this.setState({dataLoad: true});
    this.alertView('Uploaded successfully');
  };
  alertView(title) {
    // if (Platform.OS === 'web') {
    //   alert(title);
    // } else {
    //   Alert.alert(title);
    // }
    AppToast();
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateApi();
  }
  switchSattusBtnAction(id) {
    this.state.statusArray[id] = !this.state.statusArray[id];
    this.setState({updateUI: !this.state.updateUI});
  }
  onHandleTextChange = text => {
    let value = text;
    if (text == '' || this.state.regexp.test(value)) {
      this.setState({nearRadiusKm: text});
    }
  }
  //MARK:-  UI 
  renderAllSwitches = props => {
    var views = [];
    for (let i = 0; i < switchTitleStrings.length; i++) {
      views.push(
        <View style={{ flexDirection: 'row', marginTop: 20,alignItems: 'center', justifyContent: 'space-between'  }}>
          <View>
            <Text style={CommonStyleSheet.titleNameStyle}>{switchTitleStrings[i]}</Text>
          </View>
          <View style={{width:'70%'}}>
            <Switch
              onColor={colors.AppNewGreen}
              onChange={() => this.switchSattusBtnAction(i)}
              checked={this.state.statusArray[i]}
              height={22}
              width={50}
            />
          </View>
        </View>,
      );
    }
    return views;
  };
  renderHeaderView = () => {
    return (<View style={[tableStyle.headerViewstyle, { display: this.props.fromRootScreen ? 'flex' : 'none' }]}>
      <View style={[tableStyle.headerTitleContainerViewStyle,{width:'90%'}]}>
        <Text style={[tableStyle.titleTextStyle, { margin: 0 }]}>Account Configuration </Text>
      </View>
      <View style={{ flexDirection: 'row', marginTop: itsMobileView ? 5 : 0 }}>
        <SaveButton saveBtn={() => this.saveButtonAction()} saveBtnTitle={'Save'} />
      </View>
    </View>)
  }
  render() {
    return (
      <View style={CommonStyleSheet.Container}>
        <this.renderHeaderView />
        <View style={this.props.fromRootScreen ? tableStyle.containerMainView : tableStyle.listContainerView}>
          <ToastContainer />
          <ScrollView style={{ backgroundColor: colors.AppWhite }}>
            <View style={CommonStyleSheet.configurationSubViewStyle}>
              <View style={{ flexDirection: 'column', flex: 1 }}>
                <View style={CommonStyleSheet.subViewStyle}>
                  <Text style={CommonStyleSheet.titleNameStyle}>Account detail note </Text>
                  <TextInput
                    style={[CommonStyleSheet.txtFieldStyle, { outline: 0, width: itsMobileView ? '100%' : '70%' }]}
                    placeholder="Enter account detail note"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={text => this.setState({ accountDetailNote: text })}
                    value={this.state.accountDetailNote}
                  />
                </View>
                <View style={{ height: 20 }} />
                <View style={CommonStyleSheet.subViewStyle}>
                  <Text style={CommonStyleSheet.titleNameStyle}>Address label </Text>
                  <TextInput
                    style={[CommonStyleSheet.txtFieldStyle, { outline: 0, width: itsMobileView ? '100%' : '70%' }]}
                    placeholder="Enter address label"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={text => this.setState({ addressLabel: text })}
                    value={this.state.addressLabel}
                  />
                </View>
                <View style={{ height: 20 }} />
                <View style={CommonStyleSheet.subViewStyle}>
                  <Text style={CommonStyleSheet.titleNameStyle}> Category label </Text>
                  <TextInput
                    style={[CommonStyleSheet.txtFieldStyle, { outline: 0, width: itsMobileView ? '100%' : '70%' }]}
                    placeholder="Enter category label"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={text => this.setState({ categoryLabel: text })}
                    value={this.state.categoryLabel}
                  />
                </View>
                <View style={{ height: 20 }} />
                <View style={CommonStyleSheet.subViewStyle}>
                  <Text style={CommonStyleSheet.titleNameStyle}> Near by accounts radius km </Text>
                  <TextInput
                    style={[CommonStyleSheet.txtFieldStyle, { outline: 0, width: itsMobileView ? '100%' : '70%' }]}
                    placeholder="Account near by accounts radius km"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={text => this.onHandleTextChange(text)}
                    value={this.state.nearRadiusKm}
                  />
                </View>
                <View style={{ height: 20 }} />
                <View style={[CommonStyleSheet.subViewStyle]}>
                  <Text style={CommonStyleSheet.titleNameStyle}>Shipping method preference note </Text>
                  <TextInput
                    style={[CommonStyleSheet.txtFieldStyle, { outline: 0, width: itsMobileView ? '100%' : '70%', minHeight: 120, paddingTop: 10 }]}
                    placeholder="Shipping method preference note"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={text => this.setState({ shippingPreferenceNote: text })}
                    value={this.state.shippingPreferenceNote}
                    multiline={true}
                  />
                </View>
                <View style={{ paddingLeft: 20, paddingRight: 20 }}>
                  <this.renderAllSwitches />
                </View>
              </View>
            </View>
            <Appload enable={this.state.dataLoad} />
          </ScrollView>
        </View>
      </View>
    );
  }
}

const switchTitleStrings = [
  'Accounts auto approved',
  'Shipping methods preference for accounts',
  'Account map location selector',
  // 'Enable branch unique url',
  'Account Geo Location',
  'Slug',
  'Show Description',
  'Formatted Text Description',
  'Hide Address from Account Detail',
];
let keyParameterArray = [
  'account_auto_approval',
  'enable_shipping_methods_preference',
  'account_map_location_selector_enabled',
  // 'enable_branch_unique_url',
  'account_address_enabled',
  'enable_account_slug',
  'show_description',
  'account_enable_formatted_text_description',
  'hide_address_from_account_detail'
];

let commonKeyStringArray = [
  'account_detail_note',
  'account_auto_approval',
  'enable_shipping_methods_preference',
  'account_map_location_selector_enabled',
  // 'enable_branch_unique_url',
  'account_address_label',
  'account_category_label',
  'near_by_accounts_radius_km',
  'shipping_methods_preference_note',
  'account_address_enabled',
  'enable_account_slug',
  'show_description',
  'account_enable_formatted_text_description',
  'hide_address_from_account_detail',
];
let keyStringArray = [
  'account_detail_note',
  'account_address_label',
  'account_category_label',
  'near_by_accounts_radius_km',
  'shipping_methods_preference_note',
];
