import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Platform,
  Alert,
  Image,
  SafeAreaView,
  Dimensions,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import downArrow_Icon from '../assets/downArrowIcon.png';
import RightView from './TenantCommonClasses/RightCustomView';
import commonStyle from '../StyleSheets/CreateTenantCommonStyleSheet';
import HeaderView from './TenantCommonClasses/LogoHeader';
import BottomView from './TenantCommonClasses/FooterView';
import networkService from '../Network/NetworkManager';
import PropTypes from 'prop-types';
import CurrencyList from '../Models/CurrencyCodeModel'
import {AppToast} from '../HelperClasses/AppToast';
import { ToastContainer} from 'react-toastify';
import SelectSearchView from 'react-select';

const APPURL = require('../Constants/URLConstants');
const contentFul = require('../Constants/ContentFullConstant');
const windowHeight = Dimensions.get('window').height;

const placeholderArray = [
  'Default Currency',
  'Default Language',
];

export default class BusinessFrom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      countriesArray: [],
      showDropDown: [false, false, false, false],
      selectedDataArray: ['', '', '', ''],
      selectedCountry: '',
      currencyArray : [],
      languageArray: [],
      upDateUI: false,
      selectedCurrencyData: {},
      selectedLanguage:{},
      searchLanguageArry: [],
    };
    this.customDropDown = this.customDropDown.bind(this);
  }
  static propTypes = {
    createAppData: PropTypes.any,
  };
  componentDidMount() {
    let ccData = CurrencyList.getCurrencyList()
    for (let obj of ccData) {
      let dict  = {
        value: obj['code'],
        label: obj['name'],
      }
      this.state.currencyArray.push(dict);
    }
      this.loadLanguageApi();
  }
  //MARK:-  APIs Method 
 
  loadLanguageApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.language,  'get', '',  '');
    if (responseJson['status'] == true) {
      var ppData = responseJson['data']['languages'];
      for (let obj of ppData) {
          let dict  = {
            value: obj['id'],
            label: obj['name'],
          }
          this.state.searchLanguageArry.push(dict);
          this.state.languageArray.push(obj);
      }
      this.state.searchLanguageArry = this.state.searchLanguageArry.sort(function (a, b) {
        var textA = a.label.toUpperCase();
        var textB = b.label.toUpperCase();
        return textA.localeCompare(textB);
      });
      this.state.languageArray = this.state.languageArray.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA.localeCompare(textB);
      });
    }
    this.setState({dataLoad: true});
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  submitButtonAction() {
  }
  nextBtnAction() {

    if (Object.keys(this.state.selectedCurrencyData).length == 0){
      AppToast('Select Currency');
    }  else if (Object.keys(this.state.selectedLanguage).length == 0 ){
      AppToast('Select Language');
    }else {
      let ccData = CurrencyList.getCurrencyList();
      let indx = ccData.findIndex(x => x.code == this.state.selectedCurrencyData['value']);
      let sendCurrencyData = ccData[indx];

      let indxL = this.state.languageArray.findIndex(x => x.id == this.state.selectedLanguage['value']);
      let sendLanguageData = this.state.languageArray[indxL];

      this.props.navigation.navigate('SelectPlatform', {
        currencyData: sendCurrencyData,
        languageData: sendLanguageData,
      });
    }
  }
  handleSelectChange = (selectedOption, index) => {
    if (index == 0) {
      this.setState({selectedCurrencyData: selectedOption});
    } else {
      this.setState({selectedLanguage: selectedOption});
    }
    // console.log('cc =>', this.state.selectedCategoryItemsArray);
  };
  //MARK:-  UI Method 

  customDropDown = id => {
    let cid = id['id'];
    let sArray = cid == 0 ? this.state.currencyArray : this.state.searchLanguageArry;
    return (
      <View style={{width: '100%'}}>
        <SelectSearchView
          onChange={itm => this.handleSelectChange(itm, cid)}
          options={sArray}
          isMulti={false}
          placeholder={cid == 0 ? `Default Currency` : 'Default Langauge'}
          styles={colourStyles}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: colors.AppGreenBorder,
              primary: colors.AppGreenBorder,
            },
          })}
        />
      </View>
    );
        
  };

  renderValueLabel = id => {
    let index = id['id'];
    return (
      <View style={{width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text style={this.state.selectedDataArray[index] == '' ? appConfigStyle.placeholderTxtStyle : appConfigStyle.txtFieldStyleDropDown}>
          {this.state.selectedDataArray[index] == '' ? placeholderArray[index] : this.state.selectedDataArray[index]}
        </Text>
        <Image 
          style={this.state.showDropDown[index] == true ? styles.upArraowIconStyle : styles.downArraowIconStyle}
          resizeMode="contain"
          source={downArrow_Icon}
        />
      </View>
    );
  };

  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={styles.Container}>
        <ToastContainer />
          <View style={commonStyle.mainViewStyle}>
            <View style={commonStyle.leftViewStyle}>
              <HeaderView />
              <View style={commonStyle.contentViewStyle}>
                <View style={{height: 420,zIndex: 120}}>
                  <ScrollView showsVerticalScrollIndicator={true}>
                    <View style={{zIndex: 200}}>
                      <Text style={commonStyle.contentTitleStyle}>
                        Where is your business from?
                      </Text>
                      <View style={{marginTop: 30, zIndex: 998}}>
                        <Text style={appConfigStyle.titleTxtStyle}>
                          Currency
                        </Text>
                        <View style={styles.dropDownViewStyle}>
                          {/* <this.renderValueLabel id={0} />
                          <this.customDropDown id={0} /> */}
                          <View style={{width: '100%', flex: 1}}>
                            <this.customDropDown id={0} /> 
                          </View>
                        </View>
                      </View>
                      <View style={{marginTop: 30, zIndex: 997}}>
                        <Text style={appConfigStyle.titleTxtStyle}>
                          Language
                        </Text>
                        <View style={styles.dropDownViewStyle}>
                          <View style={{width: '100%'}}>
                            <this.customDropDown id={1} /> 
                          </View>
                        </View>
                      </View>
                      {/* <View style={{height: 30, zIndex: 993}} /> */}
                    </View>
                    <View style={{height:140}}/>
                  </ScrollView>
                </View>
                <View style={{zIndex: 90, marginTop: -20,zIndex:100}}>
                  <BottomView
                    nextBtn={() => this.nextBtnAction()}
                    backBtn={() => this.props.navigation.pop()}
                    pageNumber={'2'}
                    progressValue={'50%'}
                  />
                </View>
              </View>
            </View>
            <View style={commonStyle.rightViewStyle}>
              <RightView title={contentFul.localTitle} description={contentFul.locaDescription}/>
            </View>
          </View>
        </View>
      </SafeAreaView>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  progressSelectedStyle: {
    height: 8,
    width: '20%',
    backgroundColor: colors.AppNewGreen,
    borderRadius: 5,
  },
  dropdownContainer: {
    width: '100%',
    paddingLeft: 10,
  },
  dropDownCellViewStyle: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: colors.Lightgray,
  },
  dropDownViewStyle: {
    marginTop: 16,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    height: 40,
    borderRadius: 5,
    flexDirection: 'column',
    alignItems: 'center',
  },
  showDropDownViewStyle: {
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: 'rgba(230, 230, 230, 1)',
    width: '100%',
    marginTop: 38,
    marginLeft: 0,
    zIndex: 9000,
    height: 150,
  },
  upArraowIconStyle: {
    width: 15,
    height: 15,
    marginRight: 20,
    alignSelf: 'center',
    transform: [{rotate: '180deg'}],
  },
  downArraowIconStyle: {
    width: 15,
    height: 15,
    alignSelf: 'center',
    marginRight: 20,
  },
  labelContainerStyle: {
    width: '100%',
    color: colors.placeholderColor,
    height: 40,
    justifyContent: 'center',
    paddingLeft: -10,
  },
});
const colourStyles = {
  control: styles => ({...styles, backgroundColor: 'white', borderRadius: 5}),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? colors.SimonGray
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? colors.SimonGray
          ? colors.AppNewGreen
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : colors.Lightgray),
      },
    };
  },
  multiValueLabel: (styles, {data}) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, {data}) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: colors.AppRed,
    },
  }),
};