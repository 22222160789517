export default class StatusModel {
  static statusHandle(code) {
    switch (code) {
      case 1:
        return 'Incomplete';
      case 2:
        return 'Confirmed';
      case 3:
        return 'In progress';
      case 4:
        return 'Shipped';
      case 5:
        return 'Customer unreachable';
      case 6:
        return 'Out for delivery';
      case 7:
        return 'Undelivered - Returned';
      case 8:
        return 'Undelivered - Return confirmed';
      case 9:
        return 'Delivered';
      case 10:
        return 'Delivery confirmed';
      case 11:
        return 'Return initiated';
      case 12:
        return 'Return picked';
      case 13:
        return 'Return confirmed';
      case 14:
        return 'Disputed';
      case 15:
        return 'Cancelled by seller';
      case 16:
        return 'Cancelled by customer';
      case 17:
        return 'Ready for pickup';
      case 18:
        return 'Completed';
      case 19: 
        return 'Order Confirmed'
      case 20: 
        return 'Arrived at customer location'
      case 21: 
        return 'Canceled By Admin'
      default:
        return 'loading...';
    }
  }
}

