import AppConstants from '../Constants/AppConstants';
import colors from './../HelperClasses/AppColor';
import { b2, h2, h3, t2 } from './TextTitleStyleSheet';
// 'use strict';
var React = require('react-native');
var {StyleSheet, Dimensions} = React;
const windowheight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;
let perHeight = (windowheight * 20) / 100;

let itsMobileView = windowWidth < fixedWidth  ?  true : false

export default StyleSheet.create({
  settingTitleText: {
    fontSize: 20,
    marginLeft:16,
    marginTop:16,
    marginBottom:10,
    fontWeight: '500',
    color:colors.AppTitleBlack,
  },
  titleStyle: {
    ...t2,
    textAlign: 'left',
    color:colors.AppTitleBlack,
  },
  settingSubTitleStyle: {
    ...b2,
    textAlign: 'left',
    color: colors.Appgray,
    padding:2,
  },
  containerView: {
    flexDirection: itsMobileView ? 'column' : 'row',
  },
  mainView: {
    padding: itsMobileView ? 2 :  20,
    margin: 10,
    backgroundColor: colors.AppWhite,
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: windowheight/ 1.27,
  },
  mobilemMainView: {
    padding: itsMobileView ? 2 :  20,
    margin: 10,
    backgroundColor: colors.AppWhite,
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: windowheight/ 1.24,
  },
  mainContainerView: {
    flexDirection: itsMobileView ? 'column' : 'row',
  },
  contentViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
  },
  subMenuContainerStyle: {
    width: 250,
    margin: 20,
  },
  horizontalLineView: {
    backgroundColor: colors.SimonGray,
    width: 1,
    marginTop: 20,
  },
  settingViewsStyle: {
    margin: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: 180,
    width: 160,
    alignItems: 'center',
    justifyContent: 'center',
  },
  subTitleStyle: {
    ...b2,
    textAlign: 'center',
    marginTop: 10,
    color: colors.Appgray,
    margin: 5,
  },
  subMenuViewStyle: {
    width: 220,
    height: 40,
    alignItems: 'center',
    margin: 10,
    flexDirection: 'row',
  },
  selectedSubMenuViewStyle: {
    width: 220,
    height: 40,
    backgroundColor: colors.UltraGreen,
    alignItems: 'center',
    margin: 5,
    borderLeftWidth: 4,
    borderLeftColor: colors.AppNewGreen,
    flexDirection: 'row',
  },
  subMenuIconStyle: {
    height: 20,
    width: 20,
    marginLeft: 10,
  },
  subMenutextStyle: {
    ...h3,
    color: colors.Appgray,
    marginLeft: 10,
  },
  subMenuSelectedtextStyle: {
    ...h3,
    color: colors.AppNewGreen,
    marginLeft: 10,
  },
  sideMenuStyle:{
    height: windowheight/ 1.27,
    backgroundColor: 'white',
    width: 250,
  },
  rightViewContainerStyle: {
    flexDirection: 'row-reverse',
    height: windowheight / 1.24,
  },
  rightViewSTyle:{
    margin: 10,
    marginTop:-10,
    width: '50%',
  },
  navigationViewStyle: {
    marginLeft: 10,
    marginRight:10,
    marginTop:-10,
    flexDirection: itsMobileView ? 'column' : 'row',
    padding: itsMobileView ? 10 : 0,
    alignItems: itsMobileView ? 'flex-start' : 'center',
    height:itsMobileView ? 120 : 70,
    justifyContent: 'space-between'
  },
  backBtnBackViewStyle: {
    height: 40,
    width: 40,
    marginLeft:itsMobileView ? 10 : 0,
    backgroundColor: colors.UltraGreen,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
  },
  backBtnStyle: {
    height: 15,
    width: 15,
  },
  headerViewStyle: {
    padding: 10,
    margin: 10,
    alignItems:'center'
  },
  // titleTextStyle: {
  //   textAlign: 'center',
  // },
  blackContainerViewStyle: {
    width: '100%',
    height: windowheight,
    backgroundColor: colors.transparentBlackColor,
    position: 'absolute',
    zIndex: 10,
    flexDirection:'row'
    // alignItems: 'flex-end'
  },
  closeBtnStyle:{
    width:20,
    height:20,
  },
  transparentBtnViewStyle: {
    width: windowWidth - 460,
    height: windowheight
  },
  cardViewStyle: {
    margin: 16,
    backgroundColor: 'red',
    flexDirection: 'row',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    minHeight: 50,
    // width: 180,
    alignItems: 'center',
    // justifyContent: 'center',
    padding:5,
    paddingRight:10,
  },
})
