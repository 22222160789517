import { 
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  Image,
  TextInput,
 } from 'react-native'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import searchIcon from '../assets/search.png';
import colors from '../HelperClasses/AppColor';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import closeIcon from '../assets/closeIcon.png';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import AppConstants from '../Constants/AppConstants';


const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false

export default class SearchView extends Component {
  constructor(props) {
    super(props)  
    this.state = {
      showSearchView:false,
      searchKey:'',
    }
  }
  static propTypes = {
    onSearchTextChange:PropTypes.func,
    searchKey:PropTypes.string,
    onCancelText:PropTypes.func
  }
  
  searchViewRender = () => {
    var sView = []
    // if(this.state.showSearchView) {
      sView.push(<View style={{width:itsMobileView ? '100%' : '86%'}}>
        <TextInput
          style={{width: '100%', marginLeft: 5, height: 25, paddingLeft:5, paddingRight:18,outline:'none'}}
          placeholder="Type to search..."
          placeholderTextColor={colors.placeholderColor}
          value={this.props.searchKey}
          onChangeText={this.props.onSearchTextChange}
        />
        <TouchableOpacity style={[tableStyle.textBoxButton,{display:this.props.searchKey.length != 0 ? 'flex' : 'none'}]} 
          onPress={this.props.onCancelText}>
          <Image style={{ width: 16, height: 16}} source={closeIcon} />          
        </TouchableOpacity>
      </View>)
    // }
    return <View style={CommonStyleSheet.searchBarViewStyle}>
      <TouchableOpacity onPress={() => this.setState({ showSearchView: true })}>
        <Image style={{ width: 16, height: 16,marginRight: 5, }} source={searchIcon} />
      </TouchableOpacity>
      {sView}
    </View>
  }
  render() {
    return (
      <View>
       <this.searchViewRender />
      </View>
    )
  }
}