import React, {Component} from 'react';
import {View, Text, SafeAreaView, StyleSheet, Linking} from 'react-native';
import {createStackNavigator, TransitionPresets} from '@react-navigation/stack';
import CreateApp from '../CreateTenantID/CreateYourApp';
import Business from '../CreateTenantID/BusinessFrom';
import SelectPlatformScreen from '../CreateTenantID/SelectPlatform';
import PGateWayScreen from '../CreateTenantID/SelectPaymentPlatform';
import ShipScreen from '../CreateTenantID/SelectShippingPlatform';
import ChooseMap from '../CreateTenantID/ChooseLocationMap';
import RegTypes from '../CreateTenantID/RegistrationTypePlatform';
import FScreen from '../CreateTenantID/FinalScreen';
import DashboardScreens from '../Dashboard/DashboardScreen';
import RootView from './RootView';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import AppConstants from '../Constants/AppConstants';
import {
  getCurrentUser,
  saveAdminData,
  saveUserData,
  clearStorage,
  getMemberCookiesValue,
  saveCookies,
} from '../HelperClasses/UserPrefrences';
import LoginScreen from './LoginScreen';
import InviteMember from '../InviteMember/InviteMember';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import mixpanel from 'mixpanel-browser';

import Flavours from '../CreateTenantID/Flavour';
import {getProductAccess, getQueryParam} from '../HelperClasses/SingletonClass';
import {ProjectTypeModel} from '../Models/ProjectTypeModel';
import LandingPage from '../LandingPage/LandingPage';
import {setupUserPropertyOnMixpanel} from '../Models/MixPannelEnum';
import AppColor from '../HelperClasses/AppColor';

const APPURL = require('../Constants/URLConstants');
// const uPref = new userPref();
const Stack = createStackNavigator();

export default class MyStack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: '',
      reload: false,
      dataLoad: false,
      isVisible: false,
      onboardingCompleted: false,
      inviteMember: false,
      moveToLandingPage: false,
      subscribed: false,
    };
  }
  componentDidMount() {
    this.clientTanantsApi();
    clearStorage('modules');
  }
  getExitingUser() {
    let value = getMemberCookiesValue(AppConstants.getStartedKey);
    AppConstants.isExistingUser = value;
    if (!value) {
      saveCookies(AppConstants.getStartedKey, true);
    }
  }
  //MARK:-  APIs Method 
  initializeMixPanel() {
    mixpanel.init(AppConstants.mixpanelID, {debug: false});
    setupUserPropertyOnMixpanel();
  }

  setupLogrocket() {
    LogRocket.init(AppConstants.logRocketKey);
    LogRocket.identify(AppConstants.userFullName, {
      name: AppConstants.userFullName,
      // email: AppConstants.userEmail ,
    });
    setupLogRocketReact(LogRocket);
  }
  clientTanantsApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.clients,
      'get',
      '',
      AppConstants.BearerForTantantsAndUserDetail,
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['tenants'];
      this.getExitingUser();
      Linking.getInitialURL().then(url => {
        const {hostname} = new URL(url);
        AppConstants.appMainURL = hostname;
        let productAccess = configs[0]['product_access'];
        // console.log('productAccess', productAccess)
        let rsp = getProductAccess(productAccess, url);
        this.state.moveToLandingPage = rsp;
      });
      saveAdminData('adminKey', configs[0]['admin_key'], 2);
      saveUserData('id', configs[0]['id'], 4);
      getCurrentUser(resp => {
        this.loadConfigApi();
      });
    }
    this.setState({dataLoad: true});
  };
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=onboarding,general',
      'get',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      AppConstants.siteURL = configs['site_url'] ?? '';
      this.getLoggedInUserDetail();
      this.setState({
        onboardingCompleted: configs['onboarding_completed'] || false,
      });
      AppConstants.appType = configs['sub_type'] == 1 ? false : true;
      AppConstants.moduleType = configs['type'] || 1;
      this.tenantsApi();
      this.getLoggedInUserDetail();
      // this.setState({reload: true });
    } else {
    }
    this.setState({dataLoad: true});
  };
  tenantsApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.clients,
      'get',
      '',
      AppConstants.BearerForTantantsAndUserDetail,
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['tenants'];
      AppConstants.tenantName = `${configs[0]['id']}`;
      AppConstants.domainKey = `https://${configs[0]['domain']}` ?? '';
    }
  };
  getLoggedInUserDetail = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.skuaUser,
      'get',
      '',
      '',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['user'];
      AppConstants.userId = configs['id'];
      AppConstants.userFullName = configs['name'] || '';
      AppConstants.userEmail = configs['email'] || '';
      AppConstants.userProfilePic = configs['profile_pic'] || '';
      this.getTenantApi();
      this.setupLogrocket();
      this.getUserRoleModule();
      this.getsubscriptionsPlans();
      this.initializeMixPanel();
    } else {
      this.setState({dataLoad: true});
    }
  };
  getUserRoleModule = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.userRole + `/${AppConstants.userId}/modules`,
      'get',
      '',
      '',
    );
    if (responseJson['status'] == true) {
      let modules = responseJson['data']['modules'];
      // console.log('modules',modules);
      if (AppConstants.isDebug) {
        this.setState({reload: true, isVisible: true});
      }
      saveUserData('modules', modules, 5);
    } else {
      if (AppConstants.isDebug) {
        this.setState({reload: true, isVisible: true});
      }
    }
  };
  getsubscriptionsPlans = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.subscriptionsPlans,
      'get',
    );
    // AppConstants.restrictedFeatureArray = ["social_auth", "languages","currencies","attributes","bulk_edit_categories","bulk_edit_listings"]
    if (responseJson['status'] == true) {
      console.log('responseJson', responseJson);
      let subData = responseJson['data'];
      let plans = subData['plans'];
      let oldPlan = subData['old_plan'];
      if (oldPlan != null) {
        this.checkingSubscription(oldPlan);
      } else {
        for (let plan of plans) {
          this.checkingSubscription(plan);
        }
      }
      this.setState({reload: true, isVisible: true});
    } else {
      this.setState({reload: true, isVisible: true});
    }
  };
  checkingSubscription(plan) {
    if (plan['subscribed']) {
      AppConstants.restrictedFeatureArray = plan['restricted_features'];
      // AppConstants.restrictedFeatureArray = [ "social_auth", "languages","currencies","attributes"]
    }
    if (plan['name'] != 'community_monthly') {
      if (plan['subscribed'] && !plan['expired']) {
        AppConstants.subscribed = true;
      }
    } else {
      if (plan['subscribed']) {
        AppConstants.subscribed = true;
      }
    }
  }
  getTenantApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.clients,
      'get',
      '',
      AppConstants.BearerForTantantsAndUserDetail,
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['tenants'];
      AppConstants.tenantName = `${configs[0]['id']}`;
    }
    this.setState({dataLoad: true});
  };
  navigationReturn = () => {
    let invMeb = getMemberCookiesValue(AppConstants.inviteMemberKey);
    const route = invMeb
      ? this.state.moveToLandingPage
        ? 'SuperAdmin'
        : 'LandingPage'
      : 'InviteMember';
    return (
      <Stack.Navigator
        initialRouteName={route}
        screenOptions={{headerShown: false}}>
        <Stack.Screen
          name="SuperAdmin"
          component={RootView}
          options={{
            gestureEnabled: false,
            ...TransitionPresets.FadeFromBottomAndroid,
          }}
        />
        <Stack.Screen name="LandingPage" component={LandingPage} />
        <Stack.Screen name="InviteMember" component={InviteMember} />
        <Stack.Screen name="BusinessFrom" component={Business} />
        <Stack.Screen name="SelectPlatform" component={SelectPlatformScreen} />
        <Stack.Screen name="SelectPaymentPlatform" component={PGateWayScreen} />
        <Stack.Screen name="SelectShippingPlatform" component={ShipScreen} />
        {/* <Stack.Screen name="ChooseLocationMap" component={ChooseMap} /> */}
        <Stack.Screen name="RegistrationTypePlatform" component={RegTypes} />
        <Stack.Screen name="DashboardScreen" component={DashboardScreens} />
        <Stack.Screen name="Flavour" component={Flavours} />
      </Stack.Navigator>
    );
  };
  withoutLoginnavigationReturn = () => {
    const route = 'Login';
    return (
      <Stack.Navigator
        initialRouteName={route}
        screenOptions={{headerShown: false}}>
        <Stack.Screen name="Login" component={LoginScreen} />
      </Stack.Navigator>
    );
  };
  render() {
    if (this.state.reload == false) {
      return (
        <SafeAreaView style={styles.container}>
          <Appload enable={this.state.isVisible} />
          <this.withoutLoginnavigationReturn />
        </SafeAreaView>
      );
    } else {
      return <this.navigationReturn />;
    }
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppColor.AppLightGreen,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
