import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  TextInput,
  Dimensions,
  Image,
  Platform,
  SafeAreaView,
  Alert,
} from 'react-native';
import NaviHeader from '../../Component/NavigationHeader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import ReactFileReader from 'react-file-reader';
import {AppToast} from '../../HelperClasses/AppToast';

import nextIcon from '../../assets/nextArrow.png';
import previousIcon from '../../assets/previousArrow.png';
import blackCrossIcon from '../../assets/blackCrossIcon.png';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import Cookies from 'universal-cookie';
import AppConstants from '../../Constants/AppConstants';
import upload_Icon from '../../assets/uploadIcon.png';
import PreviewStyleSheet from '../../StyleSheets/PreviewStyleSheet';
import SettingStyleSheet from '../../StyleSheets/SettingStyleSheet';

import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import HelperVideoView from '../../Component/HelperVideoView';
import { previewStatusBarView } from '../../Component/CommonComponents';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';


const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
let fixedWidth = 800;

export default class OnBoardingSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesArray: [],
      imageScreen1: {},
      imageScreen2: {},
      imageScreen3: {},
      onBoardingTextArray: [{text: ''}, {text: ''}, {text: ''}],
      backendData: [],
      updateUI: false,
      pagerIndex: 0,
      flatListRef: null,
      dataLoad: false,
      selectedPrimaryColor:'',
    };
  }
  componentDidMount() {
    this.clientTanantsApi();
    this.loadConfigApi();
  }
  clientTanantsApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.clients, 'get',  '',
      AppConstants.BearerForTantantsAndUserDetail,
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['tenants'];
      AppConstants.BearerAuthorization = configs[0]['admin_key'];
    }
    this.setState({dataLoad: true});
  };
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=onboarding,','get','','');
    if (responseJson['status'] == true) {
      this.state.onBoardingTextArray = [{text: ''}, {text: ''}, {text: ''}];
      var configs = responseJson['data']['configs'];
      this.setState({
        selectedPrimaryColor: configs['app_color_primary'] || '',
      });
      if (configs['intro_screens']) {
        this.state.backendData = configs['intro_screens'];
        for (let i = 0; i < this.state.backendData.length; i++) {
          if (this.state.backendData[i]['image']){
          let filePath = this.state.backendData[i]['image'];
          var filename = filePath.replace(/^.*[\\\/]/, '');
          const dic = {
            image: this.state.backendData[i]['image'],
            name: filename,
            type: 'image/' + filename.split('.').pop(),
          };
          if (i == 0) {
            this.state.imageScreen1 = dic;
          } else if (i == 1) {
            this.state.imageScreen2 = dic;
          } else if (i == 2) {
            this.state.imageScreen3 = dic;
          }
          this.state.imagesArray.push(dic);
         }
          const dic2 = {text: this.state.backendData[i]['text'] || ''};
          this.state.onBoardingTextArray[i] = dic2;
      }
      }
      this.setState({dataLoad: true});
    } else {
      this.alertView(responseJson);
    }
    this.setState({dataLoad: true});
  };

  uploadImageOnboarding = async () => {
    var imgParm = [];
    var uploadImageArray = [];
    var uploadDataParm = [];
    for (let i = 0; i < this.state.imagesArray.length; i++) {
      let fileName = this.state.imagesArray[i]['image'];
      var strFirstThree = fileName.substring(0, 4);
      if (strFirstThree !== 'http') {
        var dict = {
          name: this.state.imagesArray[i]['name'].replace(/ /g,''),
          type: this.state.imagesArray[i]['type'],
        };
        var dict2 = {
          text: this.state.onBoardingTextArray[i]['text'],
          image: this.state.imagesArray[i]['image'],
          type: this.state.imagesArray[i]['type'],
        };
        uploadImageArray.push(dict2);
        imgParm.push(dict);
      } else {
        let uploadDict = {
          image:fileName,
          text: '',
        };
        uploadDataParm.push(uploadDict);
      }
    }
    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL, 'POST',
        JSON.stringify({files: imgParm}),
        '',
      );
      var uploadIncrement = 0;
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        for (let i = 0; i < uploadImageArray.length; i++) {
          fetch(uploadImageArray[i]['image']).then(async res => {
            const file_upload_res = await networkService.uploadFileWithSignedURL(
              result[i]['signedUrl'],
              uploadImageArray[i]['type'],
              await res.blob(),
            );
            uploadIncrement = uploadIncrement + 1;
            let uploadDict = {
              image: result[i]['fileUri'],
              text: uploadImageArray[i]['text'],
            };
            uploadDataParm.push(uploadDict);
            if (uploadIncrement === uploadImageArray.length) {
              // for (let a = 0; a < 3 - uploadImageArray.length; a++) {
              //   let uploadDict = {
              //     image: this.state.imagesArray[i]['image'],
              //     text: this.state.onBoardingTextArray[i]['text'],
              //   };
              //   uploadDataParm.push(uploadDict);
              // }
              var upParam = [];
              for (let a = 0; a < 3; a++) {
                var ppDict = {};
                if (uploadDataParm[a]) {
                  ppDict['image'] = uploadDataParm[a]['image'];
                }
                if (this.state.onBoardingTextArray[a]) {
                  ppDict['text'] = this.state.onBoardingTextArray[a]['text'];
                }
                upParam.push(ppDict);
              }
              let fDict = {
                key_group: 'onboarding',
                key: 'intro_screens',
                value: upParam,
                secured: false,
              };
              this.addOrUpdateOnboardingApi(fDict);
            }
          });
        }
      } else {
        this.alertView(responseJson);
      }
    } else {
      console.log('this.state.onBoardingTextArray ==> ', this.state.onBoardingTextArray);
      uploadDataParm = [];
      for (let a = 0; a < 3; a++) {
        var uploadDict = {}
        if (this.state.imagesArray[a]) {
          uploadDict['image'] = this.state.imagesArray[a]['image'];
        }
        if (this.state.onBoardingTextArray[a]) {
          uploadDict['text'] = this.state.onBoardingTextArray[a]['text'];
        }
        // let uploadDict = {
        //   image: this.state.imagesArray[a]['image'],
        //   text: this.state.onBoardingTextArray[a]['text'],
        // };
        if (Object.keys(uploadDict).length != 0) {
          uploadDataParm.push(uploadDict);
        }
      }
      let fDict = {
        key_group: 'onboarding',
        key: 'intro_screens',
        value: uploadDataParm,
        secured: false,
      };
      console.log('uploadDataParm===>', uploadDataParm)
      this.addOrUpdateOnboardingApi(fDict);
    }
  };
  addOrUpdateOnboardingApi = async parameter => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config, 'post', JSON.stringify({configs: [parameter]}),'');
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.alertView('Uploaded successfully');
    } else {
      this.alertView(responseJson);
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast();
    } else {
      Alert.alert(title);
    }
  }

  //MARK:-  Buttons Action Method 
  saveBtnAction() {
    // if (this.state.imagesArray.length != 3) {
    //   AppToast('add 3 images');
    // } else if (this.state.onBoardingTextArray[0]['text'].length == 0) {
    //   AppToast('Onboarding text 1');
    //   // this.alertView('Onboarding text 1');
    // } else if (this.state.onBoardingTextArray[1]['text'].length == 0) {
    //   // this.alertView('Onboarding text 2');
    //   AppToast('Onboarding text 2');
    // } else if (this.state.onBoardingTextArray[2]['text'].length == 0) {
    //   // this.alertView('Onboarding text 3');
    //   AppToast('Onboarding text 3');
    // } else {
      this.setState({dataLoad: false});
      this.uploadImageOnboarding();
    // }
  }
  nextButtonAction() {
    let index = this.state.pagerIndex + 1;
    if (index < this.state.imagesArray.length) {
      this.flatListRef.scrollToIndex({animated: true, index: index});
      this.setState({pagerIndex: index});
    }
  }
  previousButtonAction() {
    let index = this.state.pagerIndex - 1;
    // console.log('pagerIndex', index);
    if (index >= 0) {
      this.flatListRef.scrollToIndex({animated: true, index: index});
      this.setState({pagerIndex: index});
    }
  }

  onPick = (image, id) => {
    if (this.state.imagesArray.length < 3) {
      const dic = {
        image: image.base64,
        name: image['fileList'][0]['name'],
        type: image['fileList'][0]['type'],
      };
      if (id == 0) {
        this.state.imageScreen1 = dic;
      } else if (id == 1) {
        this.state.imageScreen2 = dic;
      } else if (id == 2) {
        this.state.imageScreen3 = dic;
      }
      this.state.imagesArray = [];
      if (this.state.imageScreen1['image']) {
        this.state.imagesArray.push(this.state.imageScreen1);
      }
      if (this.state.imageScreen2['image']) {
        this.state.imagesArray.push(this.state.imageScreen2);
      }
      if (this.state.imageScreen3['image']) {
        this.state.imagesArray.push(this.state.imageScreen3);
      }
      this.setState({updateUI: !this.state.updateUI});
    } else {
      this.alertView('you can not select more');
    }
  };
  deleteImageButtonAction(id) {
    if (id == 0) {
      this.state.imageScreen1 = {};
    } else if (id == 1) {
      this.state.imageScreen2 = {};
    } else if (id == 2) {
      this.state.imageScreen3 = {};
    }

    this.state.imagesArray = [];
    if (this.state.imageScreen1['image']) {
      this.state.imagesArray.push(this.state.imageScreen1);
    }
    if (this.state.imageScreen2['image']) {
      this.state.imagesArray.push(this.state.imageScreen2);
    }
    if (this.state.imageScreen3['image']) {
      this.state.imagesArray.push(this.state.imageScreen3);
    }
    this.setState({updateUI: !this.state.updateUI});
  }
  helperVideoBtnAction(){
    addMixpanelEvent(MixpanelEvent.howItWorks,{'Action' : MixPannelEnum.onboardingscreen})
    window.open(AppConstants.onboardingAppHelperVideoLink, '_blank')
  }
  /*  UI   */
  imageViewPicker = props => {
    let imageCount = this.state.imagesArray.length;
    var msgTxt = '';
    if (imageCount == 3) {
      msgTxt = '3 files added';
    } else {
      msgTxt =
        imageCount == 0
          ? 'Choose file or Drag and drop here'
          : imageCount + ' file added';
    }
    if (Platform.OS === 'web') {
      return (
        <View style={appConfigStyle.dottedViewStyle}>
          <ReactFileReader
            fileTypes={['.png', '.jpg']}
            base64={true}
            style={appConfigStyle.dottedViewStyle}
            handleFiles={this.onPick}>
            <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
          </ReactFileReader>
        </View>
      );
    } else {
      return (
        <TouchableOpacity style={appConfigStyle.dottedViewStyle}>
          <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
        </TouchableOpacity>
      );
    }
  }

  viewSelectedImages = props => {
    var views = [];
    for (let i = 0; i < 3; i++) {
      let imageObj = {};
      if (i == 0) {
        imageObj = this.state.imageScreen1;
      } else if (i == 1) {
        imageObj = this.state.imageScreen2;
      } else if (i == 2) {
        imageObj = this.state.imageScreen3;
      }
      if (Platform.OS === 'web') {
        if (imageObj['image']) {
          views.push(
            <View>
              <View style={styles.imageSelectedStyle}>
                <ReactFileReader
                  fileTypes={['.png', '.jpg']}
                  base64={true}
                  handleFiles={img => this.onPick(img, i)}>
                  <View>
                    <Image source={{uri: imageObj['image']}}  style={appConfigStyle.SelectedImageStyle}/>
                    <TouchableOpacity
                      style={appConfigStyle.deleteViewStyle}
                      onPress={() => this.deleteImageButtonAction(i)}>
                      <Image style={appConfigStyle.deleteImageStyle} source={blackCrossIcon}  />
                    </TouchableOpacity>
                  </View>
                </ReactFileReader>
              </View>
              <Text style={{textAlign: 'center'}}>Secreen {i + 1}</Text>
            </View>,
          );
        } else {
          views.push(
            <View>
              <View style={styles.imagePickerPlaceholderStyle}>
                <ReactFileReader
                  fileTypes={['.png', '.jpg']}
                  base64={true}
                  handleFiles={img => this.onPick(img, i)}>
                  <View style={{justifyContent: 'center'}}>
                    <Image source={upload_Icon} style={{width: 30, height: 30, alignSelf: 'center'}} />
                    <Text style={CommonStyleSheet.uploadHereTextStyle}>Upload here</Text>
                  </View>
                </ReactFileReader>
              </View>
              <Text style={{textAlign: 'center'}}>Secreen {i + 1}</Text>
            </View>,
          );
        }
      } else {
        views.push(
          <TouchableOpacity style={appConfigStyle.dottedViewStyle}>
            <Image source={upload_Icon}  style={{width: 30, height: 30, alignSelf: 'center'}} />
            <Text style={CommonStyleSheet.uploadHereTextStyle}>Upload here</Text>
          </TouchableOpacity>,
        );
      }
    }
    return views;
  };

  previewImages = props => {
    return (
      <View style={{marginTop:20}}>
        <FlatList
          getItemLayout={(data, index) => {
            return {length: 180, index, offset: 180 * index};
          }}
          data={this.state.imagesArray}
          renderItem={this.renderPreviewImageItem}
          ref={ref => (this.flatListRef = ref)}
          horizontal={true}
          pagingEnabled={true}
          extraData={this.state}
          showsHorizontalScrollIndicator={false}
          scrollEnabled={false}
          key={'I'}

        />
      </View>
    );
  };
  renderPreviewImageItem = ({item, index}) => {
    let txtValue = this.state.onBoardingTextArray[index]['text']
    return (
      <View style={{ width: 285, justifyContent:'space-between', height:470 }}>
        <View style={appConfigStyle.onBoardingViewStyle}>
          <Image source={{ uri: item['image'] }} resizeMode={'contain'} style={appConfigStyle.preViewImageStyle} />
          <View style={appConfigStyle.onBoardingTxtStyle}>
            <Text style={CommonStyleSheet.onBoardingStyle}> {txtValue}  </Text>
          </View>
        </View>
        <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingLeft: 16}}>
          <View style={{ alignItems: 'center', alignSelf: 'center' }}>
            <this.pageIndicator />
          </View>
          <View style={PreviewStyleSheet.applyBtnViewStyle}>
            <Text style={CommonStyleSheet.applyNxtBtnStyle}>{'Next'}</Text>
          </View>
        </View>
      </View>
    );
  }
  renderPreview = () => {
    return (<View>
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <Text style={tableStyle.listTitleText}>Preview</Text>
      </View>
      <View style={PreviewStyleSheet.previewFrameStyle}>

        <View style={{ justifyContent: 'space-between', height: '100%', backgroundColor: 'white' }}>
          <View style={{ height: '50%', width: '100%', backgroundColor: this.state.selectedPrimaryColor }}>
            <View style={{ flexDirection: 'row-reverse', margin: 10 }}>
              <Text style={CommonStyleSheet.applyNxtBtnStyle}>  Get Start </Text>
            </View>
          </View>
          <View style={{ height: '50%', width: '100%', backgroundColor: 'white' }}>
            <View style={{ marginTop: '-95%' }}>
              <this.previewImages />
            </View>
          </View>
        </View>
      </View>
      <this.nextPreviousButtonView />
    </View>)
  }

  pageIndicator = () => {
    return (
      <View style={appConfigStyle.pageViewstyle}>
        <View style={this.state.pagerIndex == 0 ? appConfigStyle.greenDotViewStyle : appConfigStyle.grayDotViewStyle }>
          </View>
        <View style={this.state.pagerIndex == 1 ? appConfigStyle.greenDotViewStyle : appConfigStyle.grayDotViewStyle}>
          </View>
        <View style={this.state.pagerIndex == 2 ? appConfigStyle.greenDotViewStyle : appConfigStyle.grayDotViewStyle}>
          </View>
      </View>
    );
  };
  obBoardingTextValue = (value, index) => {
    let addValue = {text: value};
    this.state.onBoardingTextArray[index] = addValue;
    this.setState({updateUI: !this.state.updateUI});
  };
  nextPreviousButtonView = () => {
    if (this.state.imagesArray.length != 0) {
      return (
        <View style={appConfigStyle.pageViewBottomViewStyle}>
          <Text style={appConfigStyle.onBoardingtitleStyle}>
            Onboarding Images
          </Text>
          <View style={appConfigStyle.nextPreviousViewStyle}>
            <TouchableOpacity onPress={() => this.previousButtonAction()}>
              <Image resizeMode={'contain'} style={appConfigStyle.iconImage}  source={previousIcon}  />
            </TouchableOpacity>
            <this.pageIndicator />
            <TouchableOpacity onPress={() => this.nextButtonAction()}>
              <Image resizeMode={'contain'} style={appConfigStyle.iconImage}  source={nextIcon}  />
            </TouchableOpacity>
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={SettingStyleSheet.rightViewContainerStyle}>
          <View style={SettingStyleSheet.rightViewSTyle}>
            <ScrollView style={{ paddingRight: 10 }}>
              <Text style={tableStyle.listTitleText}>Onboarding Settings</Text>
              <Text style={[appConfigStyle.subTitleStyle,{alignContent:'center'}]}>
                {`${AppConstants.plainTextString}  `}
                <HelperVideoView  helperVideoBtnAction={() => this.helperVideoBtnAction()}/>
              </Text>
              <View style={appConfigStyle.subViewStyle}>
                <View style={{ flexDirection: 'column', flex: 1.5 }}>
                  <View>
                    <Text style={appConfigStyle.onBoardingtitleStyle}> Onboarding Images  </Text>
                  </View>
                  <View style={{ marginTop: 16, flexDirection: windowWidth < fixedWidth ? 'column' : 'row' }}>
                    <this.viewSelectedImages />
                  </View>
                  <View style={{ marginTop: 50 }}>
                    <Text style={appConfigStyle.titleTxtStyle}>
                      Onboarding text 1
                    </Text>
                    <TextInput
                      style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                      placeholder="Onboarding text 1"
                      placeholderTextColor={colors.placeholderColor}
                      value={this.state.onBoardingTextArray[0]['text'] || ''}
                      onChangeText={name => this.obBoardingTextValue(name, 0)}
                    />
                  </View>
                  <View style={{ marginTop: 16 }}>
                    <Text style={appConfigStyle.titleTxtStyle}>
                      Onboarding text 2
                    </Text>
                    <TextInput
                      style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                      value={this.state.onBoardingTextArray[1]['text'] || ''}
                      placeholder="Onboarding text 2"
                      placeholderTextColor={colors.placeholderColor}
                      onChangeText={name1 => this.obBoardingTextValue(name1, 1)}
                    />
                  </View>
                  <View style={{ marginTop: 16 }}>
                    <Text style={appConfigStyle.titleTxtStyle}>
                      Onboarding text 3
                    </Text>
                    <TextInput
                      style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                      placeholder="Onboarding text 3"
                      placeholderTextColor={colors.placeholderColor}
                      value={this.state.onBoardingTextArray[2]['text'] || ''}
                      onChangeText={name2 => this.obBoardingTextValue(name2, 2)}
                    />
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
          <View style={{width:1, backgroundColor:colors.SimonGray}} />
          <View style={{flex: 1, marginTop:-10 }}>
            <ScrollView>
              <this.renderPreview />
            </ScrollView>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
    //}
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  imagePickerPlaceholderStyle: {
    width: 128,
    height: 136,
    margin: 10,
    justifyContent: 'center',
    borderColor: colors.SimonGray,
    borderStyle: 'dashed',
    borderWidth: 2,
    borderRadius: 10,
  },
  imageSelectedStyle: {
    width: 124,
    height: 132,
    margin: 14,
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteViewStyle: {
    height: 20,
    width: 20,
    marginLeft: 80,
  },
  deleteImageStyle: {
    height: 20,
    width: 20,
    borderColor: 'black',
    borderRadius: 10,
    borderWidth: 1,
    backgroundColor: 'white',
  },
});
const tableHeaderString = ['Title', 'Default Value', 'Status', 'Action'];
