module.exports = {
  emptyCollectionTitleString:'Add and control your collections',
  collectionMsgTextString:  'Here you can add collections and manage collections.\nCollections are simply a collection of accounts or listings.\nCollection are used as widget in front-end apps.',
  emptyListingTitleString:'Add and control your listings',
  listingsMsgTextString:  'This is where you’ll find listings when your users upload listing from the apps.\nYou can see the listing details, delete, approve, reject, etc. ',
  emptyAccountTitleString:'Manage accounts',
  accountsMsgTextString:  'This is where you’ll find accounts when any new profile is created from the apps.\nYou can see the account details, approve, reject accounts, etc. ',
  emptyPromoBannerTitleString:'Add and Manage promo banner',
  promoMsgTextString:  'This is where you’ll add a promo banner and able to use this media section\nto show any sort of communication on this prime placement.',
  emptyOrdersTitleString:'Your orders will be shown here.',
  ordersMsgTextString:  'Here, you can keep track of the orders, bookings, etc made with \nother accounts and follow the status updates.',
  emptyTransactionTitleString:'Manage your Transactions',
  transactionMsgTextString:  'Here you can track all the transactions between users.\nTransactions such as commissions, Order price, payment charges, special fees, etc.',
  emptyUserTitleString:'Viewing Registered Users',
  usersMsgTextString:  'This is where you can view all the registered \nusers and their details.',
  emptyMediaTitleString:'Add media',
  mediaMsgTextString:  'Upload images, PDF, videos to be re-used in different cases like promo, article, etc',
  emptyReviewRatingTitleString:'Viewing Rate & Reviews',
  reviewRatingMsgTextString:  'You will find your users ratings and reviews here',
  emptyBranchTitleString:'No branch found, add and manage branch',

  unassignBranchMsg:'Are you sure you want to unassign the branch for this driver? Doing this will remove fleet associated with this driver.',
  unassignFleetMsg:'Are you sure you want to unassign the fleet for this driver?',
  
  accountsTitleToolTipMsg:'Accounts are like shops.',
  accountsSubTitleToolTipMsg:'In the same shopping mall, you have merchants. Similar to how they have a reserved space for them to run their business, Users can open a space on your platform to run their business. This is called "accounts.\n\nShops = Accounts\n\nAdditional Information about Shops = Account Attributes\n\nNot every user should have a shop (account). Accounts have capability to connect escrow accounts to receive payouts',

  listingTitleToopTipMsg:'Listings are like products, services, or apps.',
  listingsSubTitleToolTipMsg:'Your accounts (merchants) can have a variety of listings under their business accounts.\n\nProducts = Listings\n\nAdditional Product Information = Listing Attributes\n\nProduct varieties that share the same attributes but different pricing and stock limit =  Listing Variants',
 
  usersTitleToopTipMsg:'Every individual is a unique user',
  usersSubTitleToolTipMsg:'Imagine, for a moment, that you own a shopping mall.\n\nEvery user who walks into a shop is considered a user.\n\nEvery merchant who runs a shop is considered a user.\n\nIn Tradly, all different(merchant or customer) users are called “Users”, and their unique details are stored in the user section. You can find their purchase details or the accounts they own.',

  promotionsTitleToopTipMsg:'Promotions',
  promotionsSubTitleToolTipMsg:'Promotions are the banners and videos placed in the shopping mall. Promotions can be as simple as a message inviting you to go somewhere, or as complex as promoting a store or a product.\n\nYou can upload promotional materials and direct them to anything you like.\n\nCreate Promotions with Destination to Store, Account or External Links or Static Message.',

  collectionsTitleToopTipMsg:'Collections',
  collectionsSubTitleToolTipMsg:'Imagine you want to run a promotion in a shopping mall where you want to bring different accounts(shops) and listings(products) who can be promoted for a particular festival.\n\nYou can create a collection of listings or a collection of accounts.',  

  noteTitleToopTipMsg:'Note',
  noteSubTitleToolTipMsg:'This note will appear to the user when this shipping method is selected.',

  shippingChargeTitleToopTipMsg:'Shipping charges',
  shippingChargeSubTitleToolTipMsg:`With this option you can split your shipping charge between accounts and buyers if you're using external last mile solutions with Tradly.\nAs of now 'Stuart' is only supported solution.`,

  shortCodeTitleToopTipMsg:'Short code',
  shortCodeSubTitleToolTipMsg:'Enter a unique short code to identify this commission in your transactions list. Only Alphabets. Six letters',
  
  
  viewListingVariantTypesMsg:`You don't have any values. You have to add first`,
  taxinclusiveMsg:`States whether your listings are tax inclusive or not`,

  uploadMediaTextHintMsg: 'Height and Weight is flexible on front end. It shows based on image source. No other restrictions applied, hence you can provide any experience you like',

  emptyCouponsTitleString:'Add and Manage Coupons',
  couponsMsgTextString:  'Coupons can be used for a variety of purposes, including activating new customers,\nrewarding loyal customers, or acquiring new customers through promotional campaigns',
  couponNoteMsg: 'This will be displayed to customer upon applying the coupon successfully.',
  couponNoteHintMsg: 'You may include {amount} to show the discount amount in the text displayed to the user.',

  couponMinimumAmountMsg: 'Minimum cart value to apply to this coupon. Enter 0 for no restrictions.',
  couponMaximumAmountMsg: 'Maximum cart value to apply this coupon. Enter 0 for no restrictions.',
  couponMaximumDiscountAmountMsg: 'Maximum amount to be given as discount.',
  couponMaximumUsesMsg: 'Maximum number of times coupons can be used by all users.',
  couponMaximumUsesPerUserMsg: 'Maximum number of times coupons can be used by single user.',
  couponNewUserMsg: 'Whether this coupon can be applied to only new user. New users are the ones who have not created any orders yet.  ',


}
 