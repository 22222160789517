import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  FlatList,
  Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
// import Domain from '../AppAcess/Domain/Domain';
// import SetupInformation from '../AppAcess/SetupInformation/SetupInformation';
// import Notifications from '../AppAcess/Notifications/Notifications';
// import advancedSettings from '../AppAcess/AdvancedSettings/AdvancedSettings';

import colors from './../HelperClasses/AppColor'
import AppConstants from '../Constants/AppConstants'
import tickIcon from './../assets/tickIcon.png'
import networkService from '../Network/NetworkManager'
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
const APPURL = require('./../Constants/URLConstants')

const windowWidth = Dimensions.get('window').width;

let fixedWidth = 800;
let itsMobileView = windowWidth < fixedWidth ? true : false

let listArray = [
  '1. Branding Customisation',
  '2. Configure account category',
  '3. Listing category',
  '4. Shipping / Service Methods',
  '5. Payment Methods',
]
let b2cArray = [
  '1. Branding Customisation',
  '2. Listing category & Attributes',
  '3. Shipping / Service Methods',
  '4. Payment Methods',
];
export default class RightView extends Component {
  static propTypes = {
    index: PropTypes.number,
    backBtn: PropTypes.func,
    didSelectIndex: PropTypes.func,
  }
  constructor(props) {
    super(props);
    this.state = {
      updateUI:false,
      completeArray:[false,false,false,false,false]
    }
  }
  componentDidMount(){
    this.loadConfigApi()
    this.loadCatApi(1)
    this.loadCatApi(2)
    this.loadShippingApi()
    this.loadPaymentApi()
  }
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config + '?key_group=onboarding', 'get')
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs']
      if (configs['app_name']) {
        this.state.completeArray[0] = configs['app_name'].length == 0 ? false : true
      }
      this.setState({updateUI:!this.state.updateUI})
    }  
  }
  loadCatApi = async (type) => {
    let path = type == 2 ? 'listings' : 'accounts'
    const responseJson = await networkService.networkCall(  APPURL.URLPaths.categories + path, 'get')
    if (responseJson['status'] == true) {
      var listD = responseJson['data']['categories']
      if (listD.length != 0){
        if (type == 1){
          this.state.completeArray[1] =  true
        }else {
          this.state.completeArray[2] =  true
        }
        this.setState({updateUI:!this.state.updateUI})
      }
    }  
  }
  loadShippingApi = async () => {
    const responseJson = await networkService.networkCall(  APPURL.URLPaths.tenantShippingMethod, 'get')
    if (responseJson['status'] == true) {
      var listD = responseJson['data']['shipping_methods']
      if (listD.length != 0){
         this.state.completeArray[3] = true
      }
      this.setState({updateUI:!this.state.updateUI})
    }  
  }
  loadPaymentApi = async () => {
    const responseJson = await networkService.networkCall(  APPURL.URLPaths.tenantPaymentMethod, 'get')
    if (responseJson['status'] == true) {
      var listD = responseJson['data']['payment_methods']
      if (listD.length != 0){
        this.state.completeArray[4] = true
      }
      this.setState({updateUI:!this.state.updateUI})
    }  
  }

  ListView = props => {
    return (
      <FlatList
        data={AppConstants.appType? b2cArray :listArray }
        horizontal={false}
        renderItem={this.renderDropDown}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
      />
    )
  }
  renderDropDown = ({item, index}) => {
    let isComplete = this.state.completeArray[index]
    return (
      <TouchableOpacity style={styles.viewStyle} onPress={() => this.props.didSelectIndex(index)}>
        <View style={{ flexDirection: 'row', justifyContent:'space-between' }}>
          <Text style={[CommonStyleSheet.themeColorTextStyle,{textAlign: 'left'}]}>{item}</Text>
          <View style={isComplete ? styles.tickViewStyle : styles.unCompleteStatusViewStyle}>
            <Image style={{ width: 12, height: 12, display: isComplete ? 'flex' : 'none'}} source={tickIcon} />
          </View>
        </View>
      </TouchableOpacity>
    )
  }
  render() {
    return (
      <View style={styles.container}>
        <View style={{marginTop: 5, marginBottom:5}} >
          <this.ListView />
        </View>
      </View>
    )
  }
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.AppWhite,
    width: itsMobileView ? '90%' : '100%',
    flex:1,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.Lightgray
  },
  viewStyle: {
    margin: 10,
    height: 40,
    marginBottom: 5,
    backgroundColor: colors.AppWhite,
    justifyContent: 'center',
    padding: 10,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 2},
    shadowRadius: 5,
    borderRadius: 5,
  },
  tickViewStyle: {
    width: 24, 
    height: 24, 
    backgroundColor: colors.Appgreen, 
    justifyContent: 'center', 
    alignItems: 'center',
    borderRadius:12 ,
  },
  unCompleteStatusViewStyle: {
     width: 24, 
    height: 24, 
    justifyContent: 'center', 
    alignItems: 'center',
    borderRadius:12 ,
    borderColor: colors.SimonGray,
    borderWidth:2,
  },
  
});
