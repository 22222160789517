import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Platform,
  Alert,
  TouchableOpacity,
  SafeAreaView,
  Dimensions,
  Image,
  TextInput,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import commonStyle from '../StyleSheets/CreateTenantCommonStyleSheet';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import {ToastContainer} from 'react-toastify';
import tlogo from '../assets/tradlyicon.png';
import userGrayIcon from '../assets/userGrayIcon.png';
import emailIcon from '../assets/emailIcon.png';
import {AppToast} from '../HelperClasses/AppToast';
import {
  saveUserData,
  getValue,
  saveCookies,
  getCookiesValue,
} from '../HelperClasses/UserPrefrences';
import AppConstants from '../Constants/AppConstants';
import TableCommonStyleSheet from '../StyleSheets/TableCommonStyleSheet';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';

const APPURL = require('../Constants/URLConstants');
const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;

let itsMobileView = windowWidth < fixedWidth ? true : false;
let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

export default class InviteMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoad: true,
      nameArray: ['', '', '', '', ''],
      emailArray: ['', '', '', '', ''],
      updateUI: false,
    };
  }
  componentDidMount() {
    // this.loadPaymentMethodApi();
  }
  //MARK:-  APIs Method 

  inviteMemberApi = async () => {
    var dicArray = [];
    for (let a = 0; a < this.state.nameArray.length; a++) {
      let name = this.state.nameArray[a];
      let email = this.state.emailArray[a];
      if (name.length !== 0 && email.length !== 0) {
        let dict = {
          name: name,
          email: email,
        };
        if (reg.test(email) === false) {
          AppToast('Invalid email');
          return true;
        } else {
          dicArray.push(dict);
        }
      }
    }
    for (let a = 0; a < dicArray.length; a++) {
      this.setState({dataLoad: false});
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.inviteMember,
        'post',
        JSON.stringify({sub_user: dicArray[a]}),
      );
      console.log(responseJson, 'dicArray.length == a', dicArray.length - 1, a);
      if (dicArray.length - 1 == a) {
        this.setState({dataLoad: true});
        this.skipBtnAction();
      }
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  submitButtonAction() {
    this.inviteMemberApi();
  }
  skipBtnAction() {
    saveCookies(AppConstants.inviteMemberKey, true);
    // this.props.navigation.dispatch(StackActions.replace('SuperAdmin'))
  }
  //MARK:-  UI Method 

  onChangeText(text, id, type) {
    if (type == 1) {
      this.state.nameArray[id] = text;
    } else {
      this.state.emailArray[id] = text;
    }
    this.setState({updateUI: !this.state.updateUI});
  }
  renderBottomButton = () => {
    return (
      <View
        style={{
          flexDirection: itsMobileView ? 'column' : 'row',
          justifyContent: 'space-between',
        }}>
        <TouchableOpacity
          style={{marginRight: -10}}
          onPress={() => this.skipBtnAction()}>
          <Text
            style={[
              TableCommonStyleSheet.saveTxtStyle,
              {color: colors.AppNewGreen, textDecorationLine: 'underline'},
            ]}>
            {'Skip'}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            commonStyle.saveBtnStyle,
            {justifyContent: 'center', marginRight: -10},
          ]}
          onPress={() => this.submitButtonAction()}>
          <Text style={TableCommonStyleSheet.saveTxtStyle}>{'Invite'}</Text>
        </TouchableOpacity>
      </View>
    );
  };

  renderFields = () => {
    var fields = [];
    for (let a = 0; a < 5; a++) {
      let name = this.state.nameArray[a];
      let email = this.state.emailArray[a];
      fields.push(
        <View
          style={{
            flexDirection: itsMobileView ? 'column' : 'row',
            paddingTop: 10,
          }}>
          <View style={styles.customTxtFieldViewStyle}>
            <Image style={{height: 16, width: 16}} source={userGrayIcon} />
            <TextInput
              style={[CommonStyleSheet.inviteTxtFieldStyle, {outline: 0}]}
              placeholder="Name"
              placeholderTextColor={colors.placeholderColor}
              value={name}
              onChangeText={text => this.onChangeText(text, a, 1)}
            />
          </View>
          <View style={{width: 10}} />
          <View style={styles.customTxtFieldViewStyle}>
            <Image style={{height: 16, width: 16}} source={emailIcon} />
            <TextInput
              style={[CommonStyleSheet.inviteTxtFieldStyle, {outline: 0}]}
              placeholder="Email"
              placeholderTextColor={colors.placeholderColor}
              value={email}
              onChangeText={text => this.onChangeText(text, a, 2)}
            />
          </View>
        </View>,
      );
    }
    return <View>{fields}</View>;
  };

  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View>
          <ToastContainer />
          <View style={commonStyle.mainViewStyle}>
            <View
              style={[
                {
                  flexDirection: itsMobileView ? 'column' : 'row',
                  height: windowHeight,
                  width: windowWidth,
                },
              ]}>
              <View style={commonStyle.headerViewStyle}>
                <View style={{margin: 20}} />
                <Image
                  resizeMode={'contain'}
                  style={styles.logoStyle}
                  source={tlogo}
                />
              </View>
              <ScrollView>
                <View
                  style={[
                    commonStyle.contentViewStyle,
                    {
                      margin: 20,
                      marginLeft: 20,
                      marginRight: 20,
                      marginTop: 80,
                      width: '90%',
                      height: windowHeight,
                    },
                  ]}>
                  <Text style={commonStyle.contentTitleStyle}>
                    Invite Your Team Member
                  </Text>
                  <View style={{marginTop: 10}}>
                    <Text
                      style={
                        CommonStyleSheet.textLabelH3ViewStyle
                      }>{`Invite your team members. You can control their access on roles management `}</Text>
                    {this.renderFields()}
                  </View>
                  <View style={{marginTop: 20}} />
                  {this.renderBottomButton()}
                </View>
              </ScrollView>
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  logoStyle: {
    width: 50,
    height: 50,
  },
  inviteBtnStyle: {
    marginTop: 10,
    backgroundColor: colors.AppNewGreen,
    height: 30,
    width: 70,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  inviteIconStyle: {
    width: 16,
    height: 16,
    marginLeft: 5,
  },
  customTxtFieldViewStyle: {
    padding: 10,
    marginTop: 16,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: colors.AppGreenBorder,
    height: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: itsMobileView ? '90%' : '50%',
    alignItems: 'center',
  },
});
