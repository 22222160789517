// import React, {Component} from 'react';
import React, {Component} from 'react';

import {StyleSheet, View,Text,Animated} from 'react-native';
import PropTypes from 'prop-types';
import Lottie from 'lottie-web-react';
import CommonStyle from '../../StyleSheets/CommonStyleSheet';
import colors from './../AppColor';

let watchAnimationData = require('./../../loader.json');

export default class AppLoader extends Component {
  static propTypes = {
    enable: PropTypes.bool,
    showProgress:PropTypes.bool,
    progressTitle:PropTypes.string
  };

  render() {
    if (this.props.enable == false) {
      var ppView = []
      if (this.props.showProgress) {
        ppView.push( <Text style={[CommonStyle.plainSubTextStyle,{color:colors.AppNewGreen}]}>
          Uploading {this.props.progressTitle}</Text>)
      } else if (this.props.titleMessage) {
        ppView.push( <Text>{this.props.titleMessage}</Text>)
      } else {
        ppView.push( <Text style={[CommonStyle.plainTextStyle,{color:colors.AppNewGreen}]}>Please wait</Text>)
      }
      return (
        <View style={CommonStyle.mainStyle}>
          <View style={CommonStyle.loaderStyle}>
            {ppView}
            <View style={{height:100, width:100}}>
              <Lottie
                options={{
                  renderer: 'svg',
                  loop: true,
                  autoplay: true,
                  animationData: watchAnimationData,
                  rendererSettings: rendererSettings,
                }}
                playingState={'play'}
                speed={1}
                direction={1}
              />
            </View>
          </View>
        </View>
      );
    }
    return <View></View>;
  }
}
const rendererSettings = {
  preserveAspectRatio: 'xMinYMin slice',
};

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  square: {
    width: 70,
    height: 70,
    backgroundColor: 'red'
}
});