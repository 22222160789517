import AppConstants from '../Constants/AppConstants';
import colors from '../HelperClasses/AppColor';
import { b2, t2 } from './TextTitleStyleSheet';
// 'use strict';
var React = require('react-native');
var {StyleSheet, Dimensions} = React;
const windowheight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

export default StyleSheet.create({
  mainViewStyle: {
    flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
    flex: 1,
    backgroundColor: 'white',
  },
  leftViewStyle: {
    flex: 1,
    backgroundColor: 'white',
    height: windowheight,
  },
  rightViewStyle: {
    width: windowWidth < fixedWidth ? '60%' : '25%',
    backgroundColor: 'white',
    height: windowWidth < fixedWidth ? 0 : windowheight,
    alignSelf: 'center',
  },
  headerViewStyle: {
    height: 100,
    backgroundColor: 'white',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
  },
  logoStyle: {
    top: 2,
    width: 25,
    height: 25,
  },
  titleTextStyle: {
    fontSize: 30,
    fontWeight: '800',
    color: '#01544E',
    alignSelf: 'center',
    marginLeft: 10,
  },
  nextIconStyle: {
    width: 15,
    height: 15,
  },
  backIconStyle: {
    width: 15,
    height: 15,
    transform: [{rotate: '180deg'}],
  },
  contentViewStyle: {
    marginRight: '13%',
    marginLeft: '13%',
    flex: 1,
    marginBottom: '2%',
    padding: 10,
    // backgroundColor: 'green',
    // justifyContent: 'space-between',
    // justifyContent: 'center',
  },
  contentTitleStyle: {
    fontSize: 26,
    fontWeight: '700',
    textAlign: 'left',
  },
  txtFieldViewStyle: {
    padding: 0,
    marginTop: 16,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    height: 40,
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
    outlineWidth: 0,
  },
  saveBtnStyle: {
    backgroundColor: colors.AppNewGreen,
    height: 40,
    width: 120,
    borderRadius: 5,
    marginRight: 20,
    alignItems: 'center',
    alignSelf: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
  },
  saveTxtStyle: {
    color: 'white',
  },
  progressViewStyle: {
    marginTop: 20,
    height: 8,
    width: '100%',
    backgroundColor: colors.Lightgray,
    borderRadius: 5,
  },
  dropDownViewStyle: {
    marginTop: 16,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    height: 40,
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  customViewContainer: {
    flexDirection: windowWidth < 800 ? 'column' : 'row',
    alignItems: 'center',
  },
  customViewStyle: {
    margin: 10,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: 180,
    width: 160,
    alignItems: 'center',
    justifyContent: 'center',
  },
  highlightCustomViewStyle: {
    margin: 12,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 2,
    height: 180,
    width: 160,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.AppNewGreen,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
  },
  iconImageViewStyle: {
    height: 80,
    width: 80,
  },
  customViewTitleStyle: {
    textAlign: 'center',
    marginTop: 16,
    color: colors.AppNewGreen,
    width:'85%',
  },
  customViewsubTitleStyle: {
    ...b2,
    textAlign: 'center',
    marginTop: 5,
    color: colors.Appgray,
    margin: 5,
  },
  typeViewStyle: {
    margin: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.SimonGray,
    borderWidth: 2,
    height: 70,
    width: 70,
    borderRadius: 10,
    backgroundColor: colors.AppLightGreen,
  },
  selectedTypeViewStyle: {
    margin: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.AppNewGreen,
    borderWidth: 2,
    shadowColor: colors.AppNewGreen,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    height: 70,
    width: 70,
    borderRadius: 10,
  },
  typeIconStyle: {
    width: 20,
    height: 20,
  },
  typeTextStyle: {
    ...t2,
    textAlign: 'center',
    marginTop: 0,
    color: colors.placeholderColor,
  },
  selectedTypeTextStyle: {
    ...t2,
    textAlign: 'center',
    marginTop: 0,
    color: colors.AppNewGreen,
  },
});
