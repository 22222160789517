import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Dimensions,
  Platform,
  Alert,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import Header_View from '../HeaderView';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import AppColor from '../../HelperClasses/AppColor';
import { Gradient } from 'react-gradient';
import SaveButton from '../../Component/SaveButton';
import AppConstants from '../../Constants/AppConstants';


const windowHeight = Dimensions.get('window').height;

const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'payments';

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth  ?  true : false



// let AccountTypesArray = ['Standard', 'Express'];
export default class StorageHub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatingBool: false,
      dataLoad: true,
      name: '',
      phoneNumber: '',
      addressLine1: '',
      addressLine2: '',
      landmark: '',
      state: '',
      postCode: '',
      country: '',
      id:''
    };
    this.saveButtonAction = this.saveButtonAction.bind(this);
  }

  static propTypes = {
    backBtnAction: PropTypes.func,
    storageData: PropTypes.any,
    isEdit: PropTypes.bool,
  };
  componentDidMount() {
    const catData = this.props.storageData;
    const isEdit = this.props.isEdit;
    // console.log('isEdit =>', isEdit);
    if (isEdit) {
        this.setState({
          name: catData['name'],
          phoneNumber: catData['phone_number'],
          addressLine1: catData['address_line_1'],
          addressLine2: catData['address_line_2'],
          landmark: catData['landmark'],
          postCode: catData['post_code'],
          country: catData['country'],
          state: catData['state'], 
          id:catData['id'],
        });
    }
  }

  //MARK:-  APIs Method 


  addOrUpdateStorageHubApi = async filesData => {
    var dict = {'type':'storage_hub'};
    if (this.state.name.length != 0){
      dict['name'] = this.state.name;
    } if (this.state.phoneNumber.length != 0){
      dict['phone_number'] = this.state.phoneNumber;
    } if (this.state.addressLine1.length != 0){
      dict['address_line_1'] = this.state.addressLine1;
    } if (this.state.addressLine2.length != 0){
      dict['address_line_2'] = this.state.addressLine2;
    } if (this.state.landmark.length != 0){
      dict['landmark'] = this.state.landmark;
    } if (this.state.state.length != 0){
      dict['state'] = this.state.state;
    } if (this.state.country.length != 0){
      dict['country'] = this.state.country;
    } if (this.state.postCode.length != 0){
      dict['post_code'] = this.state.postCode;
    }
    var path = APPURL.URLPaths.addresses;
    if (this.state.id !== '') {
      path = APPURL.URLPaths.addresses + '/' + this.state.id;
    }
    const responseJson = await networkService.networkCall(
      path,this.state.id !== '' ? 'put' : 'post', JSON.stringify({address: dict}));
    // console.log('configs', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.props.backBtnAction(true)
      this.alertView('Uploaded successfully');
    } else {
      this.setState({dataLoad: true});
      AppToast('Please fill the required information');
    }
  };
 
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    this.setState({dataLoad: false})
    this.addOrUpdateStorageHubApi()
  }
  //MARK:-  UIs 
  titleLblRender = ({title}) => {
    return (
      <View>
        <Text style={CommonStyleSheet.titleNameStyle}>
          {title}
          <Text style={{color: 'red', paddingTop: 5}}> *</Text>
        </Text>
      </View>
    )
  }
  renderSaveConfigBtn = () => {
    return <View>
      <SaveButton saveBtn={() => this.saveButtonAction()} saveBtnTitle={'Save'} />
    </View>
  }
  render() {
    const isEdit = this.props.isEdit;
    return (
      <SafeAreaView style={styles.Container}>
         <View style={CommonStyleSheet.sidepanSaveBtnViewStyle}>
          {this.renderSaveConfigBtn()}
        </View>
        <ScrollView style={{height: windowHeight/1.12, marginTop:10}}>
          <View style={CommonStyleSheet.sidepanmViewStyle}>
            <View style={styles.listItemView}>
              <View style={{ marginTop: 0 }}>
                <this.titleLblRender title={'Name'} />
                <TextInput
                  style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                  placeholder="Enter name"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({ name: name })}
                  value={this.state.name}
                />
              </View>
              <View style={{ marginTop: 20 }}>
                <this.titleLblRender title={'Phone number'} />
                <TextInput
                  style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                  placeholder="Enter phone number"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={ph => this.setState({ phoneNumber: ph })}
                  value={this.state.phoneNumber}
                />
              </View>
              <View style={{ marginTop: 20 }}>
                <this.titleLblRender title={'Address line 1'} />
                <TextInput
                  style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                  placeholder="Enter address line 1"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({ addressLine1: name })}
                  value={this.state.addressLine1}
                />
              </View>
              <View style={{ marginTop: 20 }}>
                <Text style={appConfigStyle.titleTxtStyle}>Address line 2</Text>
                <TextInput
                  style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                  placeholder="Enter address line 2"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({ addressLine2: name })}
                  value={this.state.addressLine2}
                />
              </View>
              <View style={{ marginTop: 20 }}>
                <this.titleLblRender title={'Landmark'} />
                <TextInput
                  style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                  placeholder="Enter landmark"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({ landmark: name })}
                  value={this.state.landmark}
                />
              </View>
              <View style={{ marginTop: 20 }}>
                <this.titleLblRender title={'State'} />
                <TextInput
                  style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                  placeholder="Enter state"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({ state: name })}
                  value={this.state.state}
                />
              </View>
              <View style={{ marginTop: 20 }}>
                <this.titleLblRender title={'Post code'} />
                <TextInput
                  style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                  placeholder="Enter post code"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({ postCode: name })}
                  value={this.state.postCode}
                />
              </View>
              
              <View style={{ marginTop: 20 }}>
                <this.titleLblRender title={'Country'} />
                <TextInput
                  style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                  placeholder="Enter country"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({ country: name })}
                  value={this.state.country}
                />
              </View>
            </View>
          </View>
          <View style={{height: 50, width: '100%'}} />
        </ScrollView>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: AppColor.AppLightGreen,
  },
  listItemView: {
    backgroundColor: 'white',
    padding: 20,
    width: itsMobileView ? '100%' : '100%',
  },
});

