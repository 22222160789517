import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Platform,
  Alert,
  TouchableOpacity,
  SafeAreaView,
  Dimensions,
  Image,
  ScrollView,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import tlogo from '../assets/tradlylogo.png';
import nextIcon from '../assets/nextArrowIcon.png';
import RightView from './TenantCommonClasses/RightCustomView';
import commonStyle from '../StyleSheets/CreateTenantCommonStyleSheet';
import HeaderView from './TenantCommonClasses/LogoHeader';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import {getValue} from '../HelperClasses/UserPrefrences';

const APPURL = require('../Constants/URLConstants');
const contentFul = require('../Constants/ContentFullConstant');

export default class CreateYourApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      appName: '',
      contactName: '',
      contactNo: '',
      billingAddress: '',
      dataLoad:true,
      taxNumber: '',
      regexp: /^[0-9-+ \b]+$/,
    };
  }
  componentDidMount() {

  }
  //MARK:-  APIs Method 
  createTenantApi = async parameter => {
    var dict = {};
    if (this.state.contactNo != '') {
      dict['contact_phone'] = this.state.contactNo
    }
    if (this.state.billingAddress != '') {
      dict['business_address'] = this.state.billingAddress
    }
    if (this.state.taxNumber != '') {
      dict['tax_number'] = this.state.taxNumber
    }
    if (Object.keys(dict).length != 0) {
      this.setState({dataLoad: false});
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.createTenant + `${parameter}`,'PATCH',
        JSON.stringify({tenant: dict}),
        '',
      );
      this.setState({dataLoad: true });
      if (responseJson['status'] == true) {
        this.props.navigation.navigate('BusinessFrom');
      }
    } else {
      this.props.navigation.navigate('BusinessFrom');
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  nextBtnAction() {
    // this.props.navigation.navigate('BusinessFrom');
    getValue('id', resp => {
      this.createTenantApi(resp[0]);
    })
  }
  onHandleTextChange = text => {
    let value = text;
    if (text == '' || this.state.regexp.test(value)) {
      this.setState({contactNo: value});
    }
  };
  //MARK:-  Buttons Action Method 
  submitButtonAction() {}
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={styles.Container}>
          <View style={commonStyle.mainViewStyle}>
            <View style={commonStyle.leftViewStyle}>
              <HeaderView />
              <ScrollView style={commonStyle.contentViewStyle}>
                <View>
                  <Text style={commonStyle.contentTitleStyle}>
                    Enter your business details
                  </Text>
                  {/* <Text style={{color: colors.Appgray, marginTop: 10}}>
                    Lorem ipsum dolor sit amet, consectetur{'\n'}
                    adipiscing elit aenean massa.
                  </Text> */}
                  {/* <View style={{marginTop: 30}}>
                    <Text style={appConfigStyle.titleTxtStyle}>
                      App name (Tenant ID)
                    </Text>
                    <View style={commonStyle.txtFieldViewStyle}>
                      <TextInput
                        style={commonStyle.txtFieldStyle}
                        placeholder={'Enter app name'}
                        onChangeText={txt => this.setState({appName: txt})}
                      />
                    </View>
                    <Text style={styles.hintStyle}>
                      This app name will be used to create an unique tenantID.
                      This is not for public use. You can always change your app
                      name but not the unique ID.
                    </Text>
                  </View>
                  <View style={{marginTop: 20}}>
                    <Text style={appConfigStyle.titleTxtStyle}>
                      Contact Name
                    </Text>
                    <View style={commonStyle.txtFieldViewStyle}>
                      <TextInput
                        style={commonStyle.txtFieldStyle}
                        placeholder={'Enter Contact Name'}
                        onChangeText={txt => this.setState({contactName: txt})}
                      />
                    </View>
                  </View> */}
                  <View style={{marginTop: 30}}>
                    <Text style={appConfigStyle.titleTxtStyle}>
                      Contact Number
                    </Text>
                    <View style={commonStyle.txtFieldViewStyle}>
                      <TextInput
                        style={[commonStyle.txtFieldStyle,{outline:0}]}
                        placeholder={'Enter Contact Number'}
                        onChangeText={text => this.onHandleTextChange(text, 1)}
                        value={this.state.contactNo}
                      />
                    </View>
                  </View>
                  <View style={{marginTop: 20}}>
                    <Text style={appConfigStyle.titleTxtStyle}>
                      Billing Address with pincode
                    </Text>
                    <View style={commonStyle.txtFieldViewStyle}>
                      <TextInput
                        style={[commonStyle.txtFieldStyle,{outline:0}]}
                        placeholder={'Enter Billing Address with pincode'}
                        onChangeText={txt =>
                          this.setState({billingAddress: txt})
                        }
                      />
                    </View>
                  </View>
                  <View style={{marginTop: 20}}>
                    <Text style={appConfigStyle.titleTxtStyle}>Tax Number</Text>
                    <View style={commonStyle.txtFieldViewStyle}>
                      <TextInput
                        style={[commonStyle.txtFieldStyle,{outline:0}]}
                        placeholder={'Enter Tax Number (optional)'}
                        onChangeText={txt => this.setState({taxNumber: txt})}
                      />
                    </View>
                  </View>
                </View>
                <View>
                  <View style={{flexDirection: 'column', marginTop: 30}}>
                    <TouchableOpacity
                      style={commonStyle.saveBtnStyle}
                      onPress={() => this.nextBtnAction()}>
                      <Text style={tableStyle.saveTxtStyle}>Next</Text>
                      <Image
                        resizeMode={'center'}
                        style={commonStyle.nextIconStyle}
                        source={nextIcon}
                      />
                    </TouchableOpacity>
                    <View style={{height:40}}/>
                    <View style={commonStyle.progressViewStyle}>
                      <View style={styles.progressSelectedStyle}></View>
                    </View>
                    <Text style={{marginTop: 10}}>Step 1 of 4</Text>
                  </View>
                </View>
              </ScrollView>
            </View>
            <View style={commonStyle.rightViewStyle}>
              <RightView title={contentFul.businessDetailTitle} description={contentFul.businessDetaiDescription}/>
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />;
      </SafeAreaView>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  progressSelectedStyle: {
    height: 8,
    width: '10%',
    backgroundColor: colors.AppNewGreen,
    borderRadius: 5,
  },
});
