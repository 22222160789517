import LocalizedStrings from 'react-localization';
// export function addMixpanelEvent(eventName,properties){
//   if (properties != undefined) {
//     mixpanel.track(eventName,properties)
//   } else {
//     mixpanel.track(eventName)
//   }
// }
export let LocalStrings = {
  en:{
    language: 'Language',
    welcome:'Welcome to',
    getStarted:"Get Started",
    deliveryOrders:"Delivery Orders",
    branches:"Branches",
    fleets:"Fleets",
    drivers:"Drivers",
    rosters:"Rosters",
    dashboard:"Dashboard",
    auditLogs:"Audit Logs",
    whatwouldyoulike:'What would you like to do?',
    createFirstOrder:'Create first order',
    ordercanbecreatedbyAPI:'Order can be created by API',
    apiDocumentation:'API Documentation',
    addDriver:'Add driver',
    driverwilldeliveryour:'Driver will deliver your`s order',
    addYourDriver:'Add your driver',
    addyourfleet:'Add your fleet',
    afleetcanbeassigneddriver:'A fleet can be assigned \nto a driver',
    addFleet:'Add fleet',
    addyourbranch:'Add your branch',
    aBranchcanhavemanyfleetsanddrivers:'A branch can have many fleets and drivers',
    addbranch:'Add branch',
  },
  ar: {
    whatwouldyoulike:'ماذا تريد ان تفعل؟',
    language:'لغة',
    welcome:'مرحبا بك في',
    getStarted:"البدء",
    deliveryOrders:"أوامر التسليم",
    branches:"الفروع",
    fleets:"أساطيل",
    drivers:"السائقين",
    rosters:"القوائم",
    dashboard:"لوحة القيادة",
    auditLogs:"سجلات التدقيق",
    createFirstOrder:'إنشاء أول طلب',
    ordercanbecreatedbyAPI:'يمكن إنشاء الطلب بواسطة API',
    apiDocumentation:'وثائق API',
    addDriver:'إضافة سائق',
    driverwilldeliveryour:'السائق سوف يسلم طلبك',
    addYourDriver:'أضف سائقك',
    addyourfleet:'أضف أسطولك',
    afleetcanbeassigneddriver:'يمكن تخصيص أسطول \nللسائق',
    addFleet:'إضافة أسطول',
    addyourbranch:'أضف فرعك',
    aBranchcanhavemanyfleetsanddrivers:'يمكن أن يحتوي الفرع على العديد من \nالأساطيل والسائقين',
    addbranch:'اضافة فرع',
  }
 }

//  DeliverySideMenuArray:['Get Started','Delivery Orders','Branches','Fleets','Drivers','Rosters','Dashboard','Audit Logs','More'],
